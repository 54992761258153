import { Box, TextField } from '@mui/material'
import React from 'react'
import { ContainerOfInputFields } from '../../Admin/RegisteredCustomers/ThemDesign'
import CloseIcon from '@mui/icons-material/Close';
import { useSelector } from 'react-redux'

function CustomTextField({
    label,error,message,value,onChange,onClearClick,readOnly, type,haswidth,hasMultipleLine, customWidth,customePadding,
    focused,paddingHorizontal
}) {
    const maintheme=useSelector(state=>state.themeData.maintheme);
    const inputRef = React.useRef(null);

    // React.useEffect(() => {
    //    if(inputRef){
    //     inputRef.current.focus()
    //    }
    // }, [inputRef.current]);
    return (
        <ContainerOfInputFields
            mainTheme={maintheme}
            customeWidth={customWidth?customWidth:"100%"}
            isForm={true}
            haswidth={haswidth?true:false}
            hasMultiLine={hasMultipleLine?true:false}
            paddingHorizontal={paddingHorizontal}
        >
            <Box
                sx={{
                    position: "relative",
                    // margin: "5px 0",
                    width: "100%",
                    backgroundColor: "#fff",
                    padding:customePadding?customePadding:'auto'
                }}
                className="orderdata"
            >
                <TextField
                    id="standard-basic"
                    variant="filled"
                    label={label}
                    value={value}
                    onChange={(e) => onChange && onChange(e)}
                    type={type? type:'text'}
                    focused={focused?focused:true}
                    // ref={inputRef}

                    autoComplete="new-password"
                    multiline={hasMultipleLine?true:false}
                    InputProps={{
                        readOnly:readOnly,
                    }}
                    className={`${error ? 'errors' : ''}`}
                />
                {(value && !readOnly) ? (
                    <CloseIcon
                        className="closeIcon"
                        onClick={() => onClearClick && onClearClick()}
                    />
                ) : null}
            </Box>
            {
                error && message?.length ?
                    <Box sx={{
                        height: 'fit-content',
                        padding: '5px',
                        display: 'flex',
                        flexDirection: 'column',
                        flexWrap: 'wrap',
                        width: '95%',
                    }}>
                        {
                            message && message?.length > 0 ? message?.map((messg) => (
                                <span style={{ fontFamily: 'Cairo-Bold', fontSize: '14px', color: 'red', height: 'auto' }}>{messg}</span>
                            )) : null
                        }
                    </Box> : null}
        </ContainerOfInputFields>
    )
}

export default CustomTextField