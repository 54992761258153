import React from "react";
import './LoginAnimationBg.css'

export default function LoginAnimationBg() {
  return (
    <div className="LoginAnimationBg">
        <div className="container">
            
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
        </div>
    </div>
  );
}
