import { Box } from "@mui/material";
import React ,{useState,useEffect} from "react";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Movements from "./Movements";
import Financial from "./Financial";
import { useTranslation } from "react-i18next";
import FinancialDetailsTab from "../../RegisteredCustomers/Tabs/FinancialDetailsTab";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}
export default function UnitTabs(props) {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const [t]=useTranslation('common')


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };
 
  return (
    <Box sx={{ bgcolor: "background.paper", width: '99%' ,marginRight:'5px !important'}} >
      <AppBar position="static" 
      sx={{
        width:'fit-content !important',
        minWidth:'300px !important',
        backgroundColor:'#1e6a99 !important',
        '& .MuiTabs-indicator':{
          backgroundColor:'#fff !important'
        }
        }}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="secondary"
          textColor="inherit"
          variant="fullWidth"
          aria-label="full width tabs example"
          sx={{
            '& .MuiButtonBase-root':{
              fontFamily: 'Cairo-Medium !important',
            }
          }}
        >
          <Tab label={t('UNIT.movements')} {...a11yProps(0)} />
          <Tab label={t('UNIT.financial')} {...a11yProps(1)} />
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={value} index={0} dir={theme.direction}>
          <Movements data={props?.ListOfLogs}/>
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          <FinancialDetailsTab fromViewUnitPage={true} notHasHeader={true} row={props?.UnitData} loadDataOfUnit={props?.loadDataOfUnit} />
        
        </TabPanel>
      </SwipeableViews>
    </Box>
  );
}
