import callAPI from '../ApiConfig'
import store from '../../../app/store'

const units = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"units",
        method:"get",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    
    })
    
}

const unitsList = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"units_list",
        method:"get",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    
    })
    
}

const addUnit = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"units",
        method:"post",
        signal:props?.signal,
        params:props?.params,
        data:props?.data,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    
    })
    
}
const updateUnit = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"units/"+props?.id,
        method:"post",
        signal:props?.signal,
        params:props?.params,
        data:props?.data,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    
    })
    
}
const deleteUnit = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"units/"+props?.data?.id,
        method:"delete",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    
    })
    
}

const unit = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"unit",
        method:"get",
        signal:props?.signal,
        params:props?.params,
        data:props?.data,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    
    })
    
}
const updateUnitStatus = async (props) => {
    const storeState = store.getState();
    return await callAPI({
        route:"unit/change_status/"+props?.id,
        method:"patch",
        signal:props?.signal,
        params:props?.params,
        data:props?.data,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    
    })
    
}

const unitAPI={
    units:units,
    unitsList:unitsList,
    addUnit:addUnit,
    updateUnit:updateUnit,
    deleteUnit:deleteUnit,
    unit:unit,
    updateUnitStatus:updateUnitStatus,

}
export default unitAPI