import callAPI from '../ApiConfig'
import store from '../../../app/store'

const OrderStatuses = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"order_statuses",
        method:"get",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
    
}
const OrderStatusList = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"order_statuses_list",
        method:"get",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
    
}
const addOrderStatus = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"order_statuses",
        method:"post",
        signal:props?.signal,
        params:props?.params,
        data:props?.data,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
    
}
const updateOrderStatus = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"order_statuses/"+props?.data?.id,
        method:"put",
        signal:props?.signal,
        params:props?.params,
        data:props?.data,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
    
}
const deleteOrderStatus = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"order_statuses/"+props?.data?.id,
        method:"delete",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
    
}



const OrderStatusAPI={
    OrderStatus:OrderStatuses,
    OrderStatusList:OrderStatusList,
    addOrderStatus:addOrderStatus,
    updateOrderStatus:updateOrderStatus,
    deleteOrderStatus:deleteOrderStatus,

}
export default OrderStatusAPI