import React from 'react'
import Cookies from 'universal-cookie'
import {useNavigate} from 'react-router-dom'
const cookies = new Cookies()
export default function ProtectionClient(props) {
   const token =cookies.get('token')
   const access_type =cookies.get('access_type')
   const navigate=useNavigate()
   
// if(access_type==='client'&&token){
  return (
    props?.children
  )
// }else navigate('/')

}
