import { Box, TextField, Button, FormControlLabel, Checkbox, Switch } from '@mui/material'
import React, { useState, useEffect } from 'react'
import PopupForm from '../../Components/PopupForm'
import { useTranslation } from 'react-i18next'
import { ContainerOfInput, ContainerOfInputFields, ContainerOfSelectField } from '../../RegisteredCustomers/ThemDesign'
import { useSelector } from 'react-redux'
import ObjectOfErrors, { checkErrors, resetErrors, restCustomeObject } from '../../../Global/Errors/Unit/Unit'
import CloseIcon from '@mui/icons-material/Close';
import MaritalStatusAPI from '../../../Global/Network/MaritalStatus/index'
import { toast } from 'react-toastify'
import unitAPI from '../../../Global/Network/Unit'
import Images from '../../Components/Images'
import CustomTextField from '../../../General/CustomComponents/CustomTextField'
import CustomeSelectField from '../../../General/CustomComponents/CustomeSelectField'
import CustomDateField from '../../../General/CustomComponents/CustomDateField'
import helpers from '../../../assets/js/helper'
import UnitLocationsAPI from '../../../Global/Network/UnitLocation'
import UnitTypesAPI from '../../../Global/Network/UnitType'
import UnitStatusAPI from '../../../Global/Network/UnitStatuses'
import customerAPI from '../../../Global/Network/Customer'
import CompoundPhaseAPI from '../../../Global/Network/compoundPhase'
import { formatDate } from 'devextreme/localization'

import compoundAPI from '../../../Global/Network/Compound';
import blockAPI from '../../../Global/Network/Block';
import buildingAPI from '../../../Global/Network/Building';
import floorAPI from '../../../Global/Network/Floor';
import generalAPI from '../../../Global/Network/General'

const RenderContent = (props) => {
    const [t] = useTranslation('common')
    const maintheme = useSelector(state => state.themeData.maintheme);
    const [ErrorsObject, setErrorsObject] = useState(ObjectOfErrors)
    const handleChange = (e) => {
        props.setName(e.target.value)
        setErrorsObject(restCustomeObject(ErrorsObject, 'name'))
    }
    const clearInput = () => {
        props.setName('')
        setErrorsObject(restCustomeObject(ErrorsObject, 'name'))

    }

    return (
        <Box className='Container-fluid my-3'>
            <Box className="row">
                <Box className="col-12 col-sm-12 col-md-6 col-lg-4">

                    <CustomTextField
                        label={t('UNITS.add_name')}
                        value={props?.name}
                        error={ErrorsObject?.name?.error}
                        message={ErrorsObject?.name?.message}
                        haswidth={true}
                        readOnly={false}
                        onChange={(e) => {
                            props?.setName(e?.target?.value)
                            setErrorsObject(restCustomeObject(ErrorsObject, 'name'))
                        }}
                        onClearClick={() => {
                            props?.setName('')
                            setErrorsObject(restCustomeObject(ErrorsObject, 'name'))
                        }}
                    />
                    <Box sx={{ margin: '10px 0' }}></Box>
                    <CustomeSelectField
                        label={t('UNITS.building')}
                        haswidth={true}
                        value={props?.selectedBuilding}
                        list={props?.buildings ? props?.buildings : []}
                        error={false}
                        message={""}
                        readOnly={false}
                        onChange={(e, newValue) => {
                            props?.setSelectedBuilding(newValue)
                        }}
                        onClearClick={() => {
                            props?.setSelectedBuilding(null)
                        }}
                    />
                    <Box sx={{ margin: '10px 0' }}></Box>
                    <CustomTextField
                        label={t('UNITS.add_description')}
                        value={props?.description}
                        error={ErrorsObject?.description?.error}
                        message={ErrorsObject?.description?.message}
                        hasMultipleLine={true}
                        haswidth={true}
                        readOnly={false}
                        onChange={(e) => {
                            props?.setDescription(e?.target?.value)
                            setErrorsObject(restCustomeObject(ErrorsObject, 'description'))
                        }}
                        onClearClick={() => {
                            props?.setDescription('')
                            setErrorsObject(restCustomeObject(ErrorsObject, 'description'))
                        }}
                    />
                    <Box sx={{ margin: '10px 0' }}></Box>
                    <CustomeSelectField
                        label={t('UNITS.add_compoundPhase')}
                        haswidth={true}
                        value={props?.selectedCompoundPhase}
                        list={props?.compoundPhases ? props?.compoundPhases : []}
                        customGetOptionLabel={(option) => option?.phase?.name}
                        error={ErrorsObject?.compound_phase_id?.error}
                        message={ErrorsObject?.compound_phase_id?.message}
                        readOnly={false}
                        onChange={(e, newValue) => {
                            console.log('asdssss', newValue);
                            props?.setSelectedCompoundPhase(newValue)
                            setErrorsObject(restCustomeObject(ErrorsObject, 'compound_phase_id'))
                        }}
                        onClearClick={() => {
                            props?.setSelectedCompoundPhase(null)
                            setErrorsObject(restCustomeObject(ErrorsObject, 'compound_phase_id'))
                        }}
                    />
                    <Box sx={{ margin: '16px 0' }}></Box>
                    <CustomTextField
                        label={t('UNITS.add_area')}
                        value={props?.area}
                        error={ErrorsObject?.area?.error}
                        message={ErrorsObject?.area?.message}
                        haswidth={true}
                        readOnly={false}
                        onChange={(e) => {
                            if (!isNaN(e?.target.value))
                                props?.setArea(e?.target?.value)
                            setErrorsObject(restCustomeObject(ErrorsObject, 'area'))
                        }}
                        onClearClick={() => {
                            props?.setArea('')
                            setErrorsObject(restCustomeObject(ErrorsObject, 'area'))
                        }}
                    />
                    <Box sx={{ margin: '12px 0' }}></Box>
                </Box>
                <Box className="col-12 col-sm-12 col-md-6 col-lg-4">
                    <CustomeSelectField
                        label={t('UNITS.compound')}
                        haswidth={true}
                        value={props?.selectedCompound}
                        list={props?.compounds ? props?.compounds : []}
                        error={false}
                        message={""}
                        readOnly={false}
                        focused={true}
                        onChange={(e, newValue) => {
                            props?.setSelectedCompound(newValue)
                        }}
                        onClearClick={() => {
                            props?.setSelectedCompound(null)
                        }}
                    />
                    <Box sx={{ margin: '10px 0' }}></Box>
                    <CustomeSelectField
                        label={t('UNITS.add_floor')}
                        haswidth={true}
                        value={props?.selectedFloor}
                        list={props?.floors ? props?.floors : []}
                        error={ErrorsObject?.floor_id?.error}
                        message={ErrorsObject?.floor_id?.message}
                        readOnly={false}
                        onChange={(e, newValue) => {
                            console.log('asdssss', newValue);
                            props?.setSelectedFloor(newValue)
                            setErrorsObject(restCustomeObject(ErrorsObject, 'floor_id'))
                        }}
                        onClearClick={() => {
                            props?.setSelectedFloor(null)
                            setErrorsObject(restCustomeObject(ErrorsObject, 'floor_id'))
                        }}
                    />
                    <Box sx={{ margin: '12px 0' }}></Box>
                    <CustomeSelectField
                        label={t('UNITS.add_unitLocation')}
                        haswidth={true}
                        value={props?.selectedUnitLocation}
                        list={props?.unitLocations ? props?.unitLocations : []}
                        error={ErrorsObject?.unit_location_id?.error}
                        message={ErrorsObject?.unit_location_id?.message}
                        readOnly={false}
                        onChange={(e, newValue) => {
                            console.log('asdssss', newValue);
                            props?.setSelectedUnitLocation(newValue)
                            setErrorsObject(restCustomeObject(ErrorsObject, 'unit_location_id'))
                        }}
                        onClearClick={() => {
                            props?.setSelectedUnitLocation(null)
                            setErrorsObject(restCustomeObject(ErrorsObject, 'unit_location_id'))
                        }}
                    />
                    <Box sx={{ margin: '12px 0' }}></Box>
                    <CustomTextField
                        label={t('UNITS.add_price')}
                        value={props?.localPrice}
                        error={ErrorsObject?.price?.error}
                        message={ErrorsObject?.price?.message}
                        haswidth={true}
                        readOnly={false}
                        onChange={(e) => {
                            let pattern = /^[0-9,.]+$/;
                            if (pattern.test(e?.target?.value)){
                                console.log('price=>1',e?.target?.value)
                                props?.setLocalPrice(e?.target?.value)
                            }
                            console.log('price=>2',e?.target?.value?.match(/\d+/g).join(''))
                            props?.setPrice(e?.target?.value?.match(/\d+/g).join(''))
                            setErrorsObject(restCustomeObject(ErrorsObject, 'price'))
                        }}
                        onClearClick={() => {
                            props?.setPrice('')
                            setErrorsObject(restCustomeObject(ErrorsObject, 'price'))
                        }}
                    />
                    <Box sx={{ margin: '16px 0' }}></Box>

                    <CustomDateField
                        label={t('UNITS.add_deliveryDate')}
                        value={props?.deliveryDate}
                        error={ErrorsObject?.delivery_date?.error}
                        message={ErrorsObject?.delivery_date?.message}
                        haswidth={true}
                        readOnly={false}
                        onChange={(e) => {
                            props?.setDeliveryDate(e)
                            setErrorsObject(restCustomeObject(ErrorsObject, 'delivery_date'))
                        }}
                        onClearClick={() => {
                            props?.setDeliveryDate('')
                            setErrorsObject(restCustomeObject(ErrorsObject, 'delivery_date'))
                        }}
                    />
                    <Box sx={{ margin: '12px 0' }}></Box>

                </Box>
                <Box className="col-12 col-sm-12 col-md-6 col-lg-4">
                    <CustomeSelectField
                        label={t('UNITS.block')}
                        haswidth={true}
                        value={props?.selectedBlock}
                        list={props?.blocks ? props?.blocks : []}
                        error={false}
                        message={""}
                        readOnly={false}
                        onChange={(e, newValue) => {
                            props?.setSelectedBlock(newValue)
                        }}
                        onClearClick={() => {
                            props?.setSelectedBlock(null)
                        }}
                    />
                    <Box sx={{ margin: '10px 0' }}></Box>
                    <CustomeSelectField
                        label={t('UNITS.add_unitType')}
                        haswidth={true}
                        value={props?.selectedUnitType}
                        list={props?.unitTypes ? props?.unitTypes : []}
                        error={ErrorsObject?.unit_type_id?.error}
                        message={ErrorsObject?.unit_type_id?.message}
                        readOnly={false}
                        onChange={(e, newValue) => {
                            console.log('asdssss', newValue);
                            props?.setSelectedUnitType(newValue)
                            setErrorsObject(restCustomeObject(ErrorsObject, 'unit_type_id'))
                        }}
                        onClearClick={() => {
                            props?.setSelectedUnitType(null)
                            setErrorsObject(restCustomeObject(ErrorsObject, 'unit_type_id'))
                        }}
                    />
                    <Box sx={{ margin: '12px 0' }}></Box>
                    <CustomeSelectField
                        label={t('UNITS.add_unitStatus')}
                        haswidth={true}
                        value={props?.selectedUnitStatus}
                        list={props?.unitStatuses ? props?.unitStatuses : []}
                        error={ErrorsObject?.unit_status_id?.error}
                        message={ErrorsObject?.unit_status_id?.message}
                        readOnly={false}
                        onChange={(e, newValue) => {
                            console.log('asdssss', newValue);
                            props?.setSelectedUnitStatus(newValue)
                            setErrorsObject(restCustomeObject(ErrorsObject, 'unit_status_id'))
                        }}
                        onClearClick={() => {
                            props?.setSelectedUnitStatus(null)
                            setErrorsObject(restCustomeObject(ErrorsObject, 'unit_status_id'))
                        }}
                    />
                    <Box sx={{ margin: '14px 0' }}></Box>

                    <Box
                        sx={{
                            backgroundColor: '#fff',
                            boxShadow: '0 0 15px -1px rgba(0,0,0,0.2)',
                            padding: '9px 10px',
                            margin: '2px 10px',
                            // height:'55px',
                            '& .MuiTypography-root ': {
                                fontFamily: 'Cairo-Medium',
                            }
                        }}
                    >
                        <FormControlLabel
                            sx={{
                                width: '100%',
                                fontFamily: 'Cairo-Medium',
                            }}
                            control={<Switch
                                checked={props?.iqdUSD}
                                onChange={(e) => {
                                    props?.setIqdUsd(e.target.checked)
                                }}
                            />} label={props?.iqdUSD ? 'USD' : 'IQD'} />

                    </Box>
                    <Box sx={{ margin: '20px 0' }}></Box>
                    <CustomeSelectField
                        label={t('UNITS.add_customer')}
                        haswidth={true}
                        value={props?.selectedCustomer}
                        list={props?.customers ? props?.customers : []}
                        customGetOptionLabel={(option) => option?.user?.full_name || ''}
                        error={ErrorsObject?.customer_id?.error}
                        message={ErrorsObject?.customer_id?.message}
                        readOnly={false}
                        onChange={(e, newValue) => {
                            console.log('asdssss', newValue);
                            props?.setSelectedCustomer(newValue)
                            setErrorsObject(restCustomeObject(ErrorsObject, 'customer_id'))
                        }}
                        onClearClick={() => {
                            props?.setSelectedCustomer(null)
                            setErrorsObject(restCustomeObject(ErrorsObject, 'customer_id'))
                        }}
                    />

                    <Box sx={{ margin: '12px 0' }}></Box>

                </Box>
                <Box className="col-12 col-sm-12 col-md-12 col-lg-12 my-3">

                    {
                        <Images
                            imageList={props?.imageList}
                            setImageList={props.setImageList}
                            isUpdateImage={props?.isUpdateImage}
                            setIsUpdatedImage={props?.setIsUpdatedImage}
                            editMode={props?.editMode}
                            loading={props?.loading}
                            setLoading={props?.setLoading}
                            type={props?.type}
                            type_id={props?.type_id}
                            notHasSlice={props?.notHasSlice}
                            userRolesHere={props?.userRolesHere}
                            hideImageType={props?.hideImageType}
                        />
                        // :null
                    }
                </Box>

            </Box>
        </Box>
    )
}

const RenderFooter = (props) => {
    const [t] = useTranslation('common')
    const gridtheme = useSelector(state => state.themeData.gridtheme)

    return (
        <Box sx={{
            display: 'flex',
            alignItems: 'center',
        }}>


            <Box sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center', width: '100%' }}>
                <Box display="flex" >

                    <Button variant="outlined"
                        // spacing={2}
                        sx={{
                            width: '50% !important',
                            // flexGrow: 1, 
                            minWidth: '80px !important',
                            maxWidth: '80px !important',
                            margin: 1,
                            backgroundColor: '#f7f7f7',
                            borderColor: gridtheme?.colorWhite,
                            color: gridtheme?.colorblack,
                            boxShadow: '0 0 7px -2px white',
                            //   color:SearchButtonTheme?.clear_button_text_color,
                            '&:hover': {
                                backgroundColor: '#f7f7f7',
                                borderColor: gridtheme?.colorWhite,
                                color: gridtheme?.colorblack,
                                boxShadow: '0 0 7px 1px white',
                                boxShadow: '0',


                            },
                            height: '35px',
                            fontFamily: 'Cairo-Bold'

                        }}
                        className="iconeFilterClear"
                        color="secondary"
                        onClick={() => {
                            props.setOpen(false)
                        }}
                    >
                        {t('GLOBAL.close')}
                    </Button>
                    <Button
                        variant="contained"
                        // spacing={2} 
                        sx={{
                            // flexGrow: 1,
                            margin: 1,
                            width: '80px !important',
                            minWidth: '80px !important',
                            maxWidth: '80px !important',
                            '&:hover': {
                                //   backgroundColor:SearchButtonTheme?.search_button_color+'88',
                            },
                            height: '35px',
                            fontFamily: 'Cairo-Bold'
                        }}
                        className="iconeFilterSearch"
                        onClick={() => {
                            props.submit()
                        }}
                    >
                        {t('GLOBAL.Save')}
                    </Button>
                </Box>
            </Box>
        </Box>
    )
}


export default function AddFormDailog(props) {
    const [t] = useTranslation('common');

    const controller = new AbortController();

    const [loading, setLoading] = useState(false)
    const [ErrorsObject, setErrorsObject] = useState(ObjectOfErrors)
    const [imageList, setImageList] = useState([])
    const [isUpdateImage, setIsUpdatedImage] = useState(false)
    const [name, setName] = useState('')
    const [description, setDescription] = useState('')
    const [localPrice,setLocalPrice]=useState('')

    const [selectedUnitLocation, setSelectedUnitLocation] = useState(null)
    const [unitLocations, setUnitLocations] = useState([])

    const [selectedUnitType, setSelectedUnitType] = useState(null)
    const [unitTypes, setUnitTypes] = useState([])

    const [selectedUnitStatus, setSelectedUnitStatus] = useState(null)
    const [unitStatuses, setUnitStatuses] = useState([])

    const [selectedCompoundPhase, setSelectedCompoundPhase] = useState(null)
    const [compoundPhases, setCompoundPhases] = useState([])

    const [price, setPrice] = useState(null)
    const [iqdUSD, setIqdUsd] = useState(false)
    const [area, setArea] = useState(null)
    const [deliveryDate, setDeliveryDate] = useState(null)

    const [customers, setCustomers] = useState(null)
    const [selectedCustomer, setSelectedCustomer] = useState(null);

    const [compounds, setCompounds] = useState([])
    const [blocks, setBlocks] = useState([])
    const [buildings, setBuildings] = useState([])
    const [floors, setFloors] = useState()

    const [selectedCompound, setSelectedCompound] = useState(null)
    const [selectedBlock, setSelectedBlock] = useState(null)
    const [selectedBuilding, setSelectedBuilding] = useState(null)
    const [selectedFloor, setSelectedFloor] = useState(null)





    useEffect(() => {
        loadBlocks()
        loadDataOfCompoundPhase()
        return () => {

        }
    }, [selectedCompound])

    useEffect(() => {
        loadBuildings()

        return () => {

        }
    }, [selectedBlock])

    useEffect(() => {
        loadFloors()

        return () => {

        }
    }, [selectedBuilding])


    const loadBlocks = async () => {
        if (!selectedCompound || !selectedCompound?.id) {
            setFloors([])
            setBuildings([])
            setBlocks([])
            return
        }
        setLoading(true)


        try {
            const result = await blockAPI.blocksList({
                params: {
                    compound_id: selectedCompound?.id,

                },
                signal: controller?.signal
            })
            if (result.status) {
                setBlocks(result?.data)
            }
        } catch (err) {
            console.log(err?.message)
            toast.error(err?.message)
        }
        setLoading(false)
    }
    const loadBuildings = async () => {
        if (!selectedBlock || !selectedBlock?.id) {
            setBuildings([])
            setFloors([])
            return
        }
        setLoading(true)
        try {
            const result = await buildingAPI.buildingsList({
                params: {
                    block_id: selectedBlock?.id,

                },
                signal: controller?.signal
            })
            if (result.status) {
                setBuildings(result?.data)
            }
        } catch (err) {
            console.log(err?.message)
            toast.error(err?.message)
        }
        setLoading(false)
    }
    const loadFloors = async () => {
        if (!selectedBuilding || !selectedBuilding?.id) {
            setFloors([])
            return
        }
        setLoading(true)
        try {
            const result = await floorAPI.floorsList({
                params: {
                    building_id: selectedBuilding?.id,

                },
                signal: controller?.signal
            })
            if (result.status) {
                setFloors(result?.data)
            }
        } catch (err) {
            console.log(err?.message)
            toast.error(err?.message)
        }
        setLoading(false)
    }

    useEffect(() => {
        if (props?.object && props?.object?.id) {
            let dumData = resetErrors(ErrorsObject)
            setName(props?.object?.name)
            setDescription(props?.object?.description)
            setSelectedFloor(props?.object?.floor)
            setSelectedBuilding(props?.object?.building)
            setSelectedBlock(props?.object?.block)
            setSelectedCompound(props?.object?.compound)
            setSelectedUnitLocation(props?.object?.unit_location)
            setSelectedUnitType(props?.object?.unit_type)
            setSelectedUnitStatus(props?.object?.unit_status)
            setSelectedCompoundPhase(props?.object?.compound_phase)
            setSelectedCustomer(props?.object?.customer)

            setPrice(props?.object?.price)
            setLocalPrice(helpers?.getFormatedPriceV2(props?.object?.price,props?.object?.iqd_usd))

            setIqdUsd(props?.object?.iqd_usd == 1 ? true : false)
            setArea(props?.object?.area)
            setDeliveryDate(props?.object?.delivery_date ? helpers.formatDate(props?.object?.delivery_date) : null)
            let images = []
            props?.object?.images && props?.object?.images?.length > 0 && props?.object?.images?.map((image, index) => {
                // let checkImage = getExtension(image?.file_path)
                images.push({
                    url: process.env.REACT_APP_API_URL_image + image?.image_url,
                    file: null,
                    index: index,
                    id: image?.id,
                    type: 'image',
                    name: image?.name,
                    description: image?.description,
                    image_type: image?.image_type,
                })
            })
            setImageList(images)

        } else {
            let dumData = resetErrors(ErrorsObject)
            clearForm()
            if (props?.compound) {
                setSelectedCompound(props?.compound)
            }
            else {
                setSelectedCompound(null)
            }
            if (props?.block) {
                setSelectedBlock(props?.block)
            }
            else {
                setSelectedBlock(null)
            }
            if (props?.building) {
                setSelectedBuilding(props?.building)
            }
            else {
                setSelectedBuilding(null)
            }
            if (props?.floor) {
                console.log("props?.floor (2)", props?.floor);
                setSelectedFloor(props?.floor)
            }
            else {
                setSelectedFloor(null)
            }
        }
    }, [props?.object, props?.floor, props?.building, props?.block, props?.compound])

    const submit = async () => {
        try {
            setLoading(true)
            let dumData = resetErrors(ErrorsObject)
            let data = new FormData()
            data.append('name', name)
            data.append('description', description ? description : '')
            if (selectedUnitLocation)
                data.append('unit_location_id', selectedUnitLocation?.id)
            if (selectedFloor)
                data.append('floor_id', selectedFloor?.id)
            if (selectedUnitType)
                data.append('unit_type_id', selectedUnitType?.id)
            if (selectedUnitStatus)
                data.append('unit_status_id', selectedUnitStatus?.id)
            if (selectedCompoundPhase)
                data.append('compound_phase_id', selectedCompoundPhase?.id)
            if (selectedCustomer)
                data.append('customer_id', selectedCustomer?.id)
            if(price){
                // var newStr = price.replaceAll(",", ""); 
                data.append('price', price)
            }
            data.append('iqd_usd', iqdUSD ? 1 : 0)
            data.append('area', area)
            if (deliveryDate)
                data.append('delivery_date', helpers.formatDate(deliveryDate))

            let result = null;
            if (props?.object && props?.object?.id) {
                // data={
                //     data:data,
                //     id:props?.object?.id,
                //     _method:'put'
                // }
                data.append("_method", "put")
                result = await unitAPI.updateUnit({
                    data: data,
                    id: props?.object?.id,
                });
            } else {
                console.log('arr=>submit:',imageList)
                imageList && imageList.forEach((image, index) => {
                    console.log("image_file_"+index,image?.file);
                    data.append('images[' + index + '][image]', image?.file);
                    data.append('images[' + index + '][image_type_id]', 1);
                    data.append('images[' + index + '][name]', image?.name);
                    data.append('images[' + index + '][description]', image?.description);
                });
                // console.log('arr=>submit:',imageList)

                result = await unitAPI.addUnit({
                    data: data
                });
            }

            if (result?.status) {
                setLoading(false)
                toast.success(t('NETWORKMESSAGE.addCustomerMessageSuccess'))
                clearForm()
                props.loadData()
                props.setAddForm(false)
            } else {

                if (typeof result.errorMessage === "object") {
                    let updatedObject = resetErrors(ErrorsObject)
                    setErrorsObject(checkErrors(result.errorMessage, updatedObject))
                }
                setLoading(false)
                toast.error(t('NETWORKMESSAGE.messageError'))
            }
            setLoading(false)
        } catch (error) {
            setLoading(false)
            toast.error(error?.message)
        }

    }

    const clearForm = () => {
        setName('')
        setDescription('')

        setSelectedUnitLocation(null)
        setSelectedUnitType(null)
        setSelectedUnitStatus(null)
        setSelectedCompoundPhase(null)
        setSelectedCustomer(null)
        setPrice('')
        setLocalPrice('')
        setIqdUsd(false)
        setArea('')
        setDeliveryDate(null)
        setImageList(null)

    }

    useEffect(() => {
        const abortController = new AbortController()
        if (!props?.object)
            clearForm()

        loadMasterData()
        
       
        return () => {
            abortController.abort()
        }
    }, [props?.addForm, props?.object])


    const loadMasterData = async () => {
        let data = null;
        try {

            setLoading(true)
            let masters = [
                {
                    type: 'unit_locations',
                    // params:{

                    // }
                },
                {
                    type: 'unit_types',
                    // params:{
                    //   building_id:20
                    // }
                },
                {
                    type: 'unit_statuses'
                },
                // {
                //     type: 'floors'
                // },
                {
                    type: 'customers'
                },
                // {
                //     type: 'compound_phases',
                //     params:{
                //       compound_id:selected
                //     }
                // },
                // {
                //     type: 'employees'
                // },
                {
                    type: 'compounds'
                }

            ]


            data = await generalAPI.getMasters(
                {
                    params: {
                        masters: masters
                    }
                }
            );
            if (data && data?.status) {

                setUnitLocations(Array.isArray(data?.data?.unit_locations) ? data?.data?.unit_locations : [])
                setUnitTypes(Array.isArray(data?.data?.unit_types) ? data?.data?.unit_types : [])
                setUnitStatuses(Array.isArray(data?.data?.unit_statuses) ? data?.data?.unit_statuses : [])
                // setFloors(Array.isArray(data?.data?.floors) ? data?.data?.floors : [])
                // setCompoundPhases(Array.isArray(data?.data?.compound_phases) ? data?.data?.compound_phases : [])
                // setUsers(Array.isArray(data?.data?.employees) ? data?.data?.employees : [])
                setCompounds(Array.isArray(data?.data?.compounds) ? data?.data?.compounds : [])

                setCustomers(Array.isArray(data?.data?.customers) ? data?.data?.customers : [])



            } else {
                toast.error(t('NETWORKMESSAGE.messageError'))
            }
        } catch (err) {
            console.log(err?.message);
        }
        setLoading(false)
    }

    const loadDataOfCompoundPhase = async () => {
        if (!selectedCompound || !selectedCompound?.id) {
            setCompoundPhases([])
            return
        }
        setLoading(true)
        try {
            const result = await CompoundPhaseAPI.CompoundPhaseList({
                params: {
                    compound_id: selectedCompound?.id,
                },
                signal: controller?.signal
            })
            if (result.status) {
                setCompoundPhases(result?.data)
            }
        } catch (err) {
            console.log(err?.message)
            toast.error(err?.message)
        }
        setLoading(false)
    }



    return (
        <PopupForm
            open={props.addForm}
            setOpen={props.setAddForm}
            title={props?.object ? t('UNITS.updateTitle') : t('UNITS.addTitle')}
            content={<RenderContent
                name={name}
                setName={setName}
                description={description}
                setDescription={setDescription}

                compounds={compounds}
                selectedCompound={selectedCompound}
                setSelectedCompound={setSelectedCompound}

                blocks={blocks}
                selectedBlock={selectedBlock}
                setSelectedBlock={setSelectedBlock}

                buildings={buildings}
                selectedBuilding={selectedBuilding}
                setSelectedBuilding={setSelectedBuilding}

                floors={floors}
                selectedFloor={selectedFloor}
                setSelectedFloor={setSelectedFloor}

                selectedUnitLocation={selectedUnitLocation}
                setSelectedUnitLocation={setSelectedUnitLocation}
                unitLocations={unitLocations}
                setUnitLocations={setUnitLocations}
                setSelectedUnitType={setSelectedUnitType}
                selectedUnitType={selectedUnitType}
                unitTypes={unitTypes}
                setSelectedUnitStatus={setSelectedUnitStatus}
                selectedUnitStatus={selectedUnitStatus}
                setPrice={setPrice}
                price={price}
                localPrice={localPrice}
                setLocalPrice={setLocalPrice}
                setIqdUsd={setIqdUsd}
                iqdUSD={iqdUSD}
                setArea={setArea}
                area={area}
                setDeliveryDate={setDeliveryDate}
                deliveryDate={deliveryDate}
                unitStatuses={unitStatuses}
                setSelectedCompoundPhase={setSelectedCompoundPhase}
                selectedCompoundPhase={selectedCompoundPhase}
                compoundPhases={compoundPhases}
                setCustomers={setCustomers}
                customers={customers}
                setSelectedCustomer={setSelectedCustomer}
                selectedCustomer={selectedCustomer}
                imageList={imageList}
                setImageList={setImageList}
                type={'unit'}
                notHasSlice={true}
                type_id={props?.object?.id}
                hideImageType={true}
                userRolesHere={props?.userRolesHere}
                editMode={props?.object?.id ? true : false}
                loading={loading}
                setLoading={setLoading}

                open={props.addForm}
                setOpen={props.setAddForm}
            />}
            footer={<RenderFooter
                open={props.addForm}
                setOpen={props.setAddForm}
                submit={submit}
            />}
        />
    )
}
