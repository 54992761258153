import { Box, Divider, Typography } from '@mui/material'
import React from 'react'
import CustomButton from '../../../General/CustomComponents/CustomButton'
import CustomTable from '../CustomTable/CustomTable'
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Person2Icon from '@mui/icons-material/Person2';
import DescriptionIcon from '@mui/icons-material/Description';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useEffect } from 'react';
import helpers from '../../../assets/js/helper';
import AddFormDailog from '../../Masters/Orders/AddFormDailog';


const addButtonSX = {
  width: 'max-content',
  fontFamily: 'Cairo',
  fontSize: '13px',
  backgroundColor: '#FFFFFF66',
  color: '#994D1E',
  paddingLeft: '0px',
  paddingRight: '0px',
  border: 'none'

}
const addButtonHoverStyle = {
  backgroundColor: '#FFFFFF',
  color: '#994D1EA0',
  border: 'none'
}

const columns = [
  { title: 'رقم العقد' },
  { title: 'اسم المجمع',alignItems:'flex-start' },
  { title: 'تفاصيل الوحدة السكنية' },
  // { title: 'تاريخ العقد' },
  { title: 'قيمة العقد' },
]
const columnsStyle = {
  name: {
    BoxSX: {
      display: 'flex',
      alignItems: 'flex-start',
    },
    TypographySX: {
      paddingLeft: '10px'
    }
  }
}
const rows = [

  {
    id: 1,
    contactNumber: "#123456",
    name: "مجمع بوابة العراق السكني",
    unitDetails: "عمارة ٤٠ شقة ١٣ الطابق الاول",
    contractDate: "2022-10-18 : 03:03",
    contactValue: "300,000$"
  },
  {
    id: 2,
    contactNumber: "#123456",
    name: "مجمع بوابة العراق السكني",
    unitDetails: "عمارة ٤٠ شقة ١٣ الطابق الاول",
    contractDate: "2022-10-18 : 03:03",
    contactValue: "300,000$"
  },
  {
    id: 3,
    contactNumber: "#123456",
    name: "مجمع بوابة العراق السكني",
    unitDetails: "عمارة ٤٠ شقة ١٣ الطابق الاول",
    contractDate: "2022-10-18 : 03:03",
    contactValue: "300,000$"
  },
  {
    id: 4,
    contactNumber: "#123456",
    name: "مجمع بوابة العراق السكني",
    unitDetails: "عمارة ٤٠ شقة ١٣ الطابق الاول",
    contractDate: "2022-10-18 : 03:03",
    contactValue: "300,000$"
  }

]
const rowsStyle = {
  name: {
    BoxSX: {
      display: 'flex',
      alignItems: 'flex-start',
    },
    TypographySX: {
      paddingLeft: '10px',
      textAlign:'left'
    }
  }
}
function ContractsAndUnitsTab(props) {
  const [rows, setRows] = useState([])
  const [showForm, setShowForm] = useState(false)
  const [selectedObject, setSelectedObject] = useState(null)
  const [loading, setLoading] = useState(false)
  const [openAddOrder, setOpenAddOrder] = useState(false)
  const [t] = useTranslation('common')
  useEffect(() => {
      setRows(props?.row?.orders?.map(order=>{

          return {
              id:order?.id,
              contactNumber:order?.contract_number,
              name:order?.unit?.compound?.name,
              unitDetails:getUnitAddress(order?.unit),
              // contractDate:order?.contract_printed_date,
              discounted_price:helpers.getFormatedPrice(order?.discounted_price,order?.iqd_usd)
          }
      }))
    return () => {
      
    }
  }, [props?.row])

  const getUnitAddress = (unit) => { 
    console.log(unit);
    let text = " عمارة" + unit?.building?.name+" "
    text += " شقة" + unit?.name+" "
    text += " طابق" + unit?.floor?.name+" "
    return text
  }

  return (
    <Box
      sx={{
        flex: 1
      }}
    >
      {openAddOrder?
      <AddFormDailog customer={props?.row} object={null} addForm={openAddOrder} setAddForm={setOpenAddOrder} loadData={props?.loadData} />
      :null}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          padding:'10px 0'
        }}
      >
        {
        props?.userRolesHere[7]?.addContract?
        <Box
          sx={{
            flex: 1,
            marginLeft: '40px',
            display: 'flex',

          }}

        >
          <CustomButton
            text={"اضافة عقد جديد"}
            sx={addButtonSX}
            buttonHoverStyle={addButtonHoverStyle}
            startIcon={<AddCircleIcon />}
            onClick={() => {
              
              setOpenAddOrder(true)
            }}

          />

        </Box>:null
        }
        <Box
          sx={{
            flex: 1,
            justifyContent: 'center',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column'
          }}
        >
          <Box
            sx={{
              justifyContent: 'center',
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column'
            }}
          >
            <Typography
              sx={{
                color: '#994D1F',
                fontSize: '16px',
                fontFamily: 'Cairo',
                fontWeight: 'bold',
              }}
            >
              العقود والوحدات
            </Typography>
            <Divider sx={{ width: '100%', opacity: 1, height: '2px', color: '#994D1E' }} />
          </Box>
        </Box>
        <Box
          sx={{
            flex: 1
          }}
        >

        </Box>
      </Box>
      <CustomTable
        sx={{
          marginLeft: '16px',
          marginRight: '16px',
          borderWidth: '1px',
          borderColor: '#994D1E',
          borderStyle: 'solid',
          marginBottom: '5px',
          backgroundColor: '#994D1E'
        }}
        columns={columns}
        userRolesHere={props?.userRolesHere}
        rows={rows}
        type={"order"}
        rowsStyle={rowsStyle}
        firstCellIcon={<DescriptionIcon sx={{
          color: '#349CDD',
          alignSelf: 'center'
        }} />}
      />
    </Box>
  )
}

export default ContractsAndUnitsTab