import React,{useState,useEffect,useMemo} from 'react'
import List from '@mui/material/List';

import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import DashboardIcon from '@mui/icons-material/Dashboard';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { useTranslation } from "react-i18next";
import TuneIcon from '@mui/icons-material/Tune';
import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion';
import { useSelector,useDispatch } from 'react-redux';
import DonutSmallIcon from '@mui/icons-material/DonutSmall';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import * as FaIcons from 'react-icons/fa'
import * as BsIcons from 'react-icons/bs'
import {Box} from '@mui/material'
import CreditCardIcon from '@mui/icons-material/CreditCard';
import {NavLink,useLocation} from 'react-router-dom'
import {setCurrentPath} from '../../reduxStore/SettingsReducer'
import {Collapse} from 'react-collapse';
import * as RiIcons from 'react-icons/ri'
import {
  setIsOpenSideMenu,
  setCompanyProfileFullScreen,
  setMenuList,
  setOpenCollapseOfOpen,
  setOpenChildOfOpen,
  setcurrentOpenMenuCollapse
} from '../../reduxStore/SettingsReducer'
import RolesOfUserAPI from '../../Global/Network/RoleUser'
import helpers from '../../assets/js/helper';

export default function SideMenuItemArray({open,setOpen,pathsList,setPathList}) {
  const [t] = useTranslation("common");
  const location=useLocation()
  const sidemenutheme=useSelector(state=>state.themeData.sidemenutheme)
  const currentpath=useSelector(state=>state.settingsData.currentpath)
  const company_profile_full_screen=useSelector(state=>state.settingsData.company_profile_full_screen)
  const openChildOfOpen=useSelector(state=>state.settingsData.openChildOfOpen)
  const openCollapseOfOpen=useSelector(state=>state.settingsData.openCollapseOfOpen)
  const currentOpenMenuCollapse=useSelector(state=>state.settingsData.currentOpenMenuCollapse)



  const [openCollapse,setOpenCollapse]=useState(false)
  const [userRoles,setUserRoles]=useState([])
  const [hasAccessToThisRoute,setHasAccessToThisRoute]=useState(false)
  const [code,setCode]=useState(null)
  const [loading,setLoading]=useState(false)
  const [openCollapse2,setOpenCollapse2]=useState(false)
  const [selectedId,setSelectedId]=useState(null)
  const dispatch=useDispatch()
  const openCollapseToggle=(obj)=>{
    setOpenCollapse(!openCollapse)
    dispatch(setIsOpenSideMenu(true))
    setSelectedId(obj?.id)
    dispatch(setcurrentOpenMenuCollapse(obj?.id))
    dispatch(setOpenCollapseOfOpen(obj?.id))
  }
  const openCollapseToggle2=(obj)=>{
    setOpenCollapse2(!openCollapse2)
    dispatch(setIsOpenSideMenu(true))
    setSelectedId(obj?.id)
    dispatch(setcurrentOpenMenuCollapse(obj?.id))
    dispatch(setOpenChildOfOpen(obj?.id))

  }

  const getUserRoles=async()=>{
    setLoading(true)
   try{ 
    let data=await RolesOfUserAPI.GetRolesOfUser();
    if(data&&data?.status){
      
      let arr=[]
      if(data?.data){
        arr=[...data?.data]
        arr.push(2000)
      }
     
      setUserRoles(arr);
    }
    setLoading(false)
   }catch(err){
     setLoading(false)
     
     
   }
  }
  useEffect(()=>{
    let abortController = new AbortController();

    getUserRoles()
    return () => {
      abortController.abort();
    }
  },[])

  
  const renderShowMenuItem=(openParam)=>{
   
   let ListArray=[]

    pathsList&&pathsList?.length>0&&pathsList?.map((obj, index) => {
      let check=false
      
      if(userRoles&&userRoles?.length>0){
        if(obj&&obj?.subLink&&obj?.subLink?.length>0){//has sub
            ListArray.push(
              <Box  key={obj?.name+"_main_"+obj?.id} sx={{display:'flex',justifyContent:'space-between',alignItems:'center',width:'100%',margin:open?0:'5px 0',cursor:'pointer'}} className="activeDropDown" onClick={()=>openCollapseToggle(obj)}>
                  <Box sx={{display:'flex',justifyContent:'start',alignItems:'center'}}>
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 6 : 'auto',
                        justifyContent: 'center',
                        position:'relative',
                        left:open?'20px':'6.5px'
                      }}
                      className="itmdropdown"
                    >
                       {obj?.icon && obj?.icon(openParam)}
                    </ListItemIcon>
                    <ListItemText className='itmdropdown' primary={obj?.name} sx={{ opacity: open ? 1 : 0 }} />
                  </Box>
                  {
                  !obj?.show?
                  <RiIcons.RiArrowDropDownLine style={{fontSize:'30px',color:'#1E6A99'}} className='itmdropdown'/>:
                  <RiIcons.RiArrowDropUpLine style={{fontSize:'30px',color:'#1E6A99'}} className='itmdropdown'/>
                  }
              </Box>
            )

            ListArray.push(
              <Collapse  key={obj?.name+"_collapse_"+obj?.id} isOpened={obj?.show&&open} className="activeDropDown">
                {obj?.subLink?.map((subItm)=>{
                      // if(!check){
                        
                          if(subItm&&subItm?.subLink&&subItm?.subLink?.length>0){//has sub and has sub
                                // here will be the condition of sub sub...
                                
                               return<React.Fragment  key={subItm?.name+"_"+subItm?.id}>
                                  <Box sx={{display:'flex',justifyContent:'space-between',alignItems:'center',width:'100%',backgroundColor:'transparent !important',cursor:'pointer'}} className="activeDropDown activeDropDown2"  onClick={()=>openCollapseToggle2(subItm)}>
                                      <Box sx={{display:'flex',justifyContent:'start',alignItems:'center'}}>
                                        <ListItemIcon
                                          sx={{
                                            minWidth: 0,
                                            marginLeft:'30px',
                                            mr: open ? 6 : 'auto',
                                            justifyContent: 'center',
                                            position:'relative',
                                            left:open?'20px':'6.5px'
                                          }}
                                          className="itmdropdown"
                                        >
                                          {subItm?.icon && subItm?.icon(openParam)}
                                        </ListItemIcon>
                                        <ListItemText className='itmdropdown' primary={subItm?.name} sx={{ opacity: open ? 1 : 0 }} />
                                      </Box>
                                      {
                                      !subItm?.show?
                                      <RiIcons.RiArrowDropDownLine style={{fontSize:'30px',color:'#1E6A99'}} className='itmdropdown'/>:
                                      <RiIcons.RiArrowDropUpLine style={{fontSize:'30px',color:'#1E6A99'}} className='itmdropdown'/>
                                      }
                                  </Box>
                            
                                  <Collapse isOpened={subItm?.show&&open} className="activeDropDown">
                                      {
                                        subItm?.subLink?.map((subSubItem)=>{
                                            let checkIsInRole=false
                                            subSubItem&&subSubItem?.roles&&subSubItem?.roles?.map((role)=>{
                                        
                                              if(!checkIsInRole)
                                                checkIsInRole=userRoles.includes(role)
                                            })
                                            if(checkIsInRole){
                                              // check=true;
                                              return <NavLink id={`${helpers.removeForwordSplash('admin'+subSubItem?.to)}`} to={subSubItem?.to} key={subSubItem?.name+"_"+subSubItem?.id} className='itmContainerdropdown itmdropdown'>
                                              <ListItem disablePadding sx={{ display: 'block',mt:0, }} disableRipple className='itmContainerdropdown2 itmdropdown pe-5'>
                                                  <ListItemButton
                                                  disableRipple
                                                    sx={{
                                                      minHeight: 48,
                                                      justifyContent: open ? 'initial' : 'center',
                                                      px: 2.5,
                                                    }}
                                                  >
                                                    <ListItemIcon
                                                      sx={{
                                                        minWidth: 0,
                                                        mr: open ? 3 : 'auto',
                                                        justifyContent: 'center',
                                                      }}
                                                    >
                                                      
                                                      {subSubItem?.icon && subSubItem?.icon(openParam)}
                                                    </ListItemIcon>
                                                    <ListItemText primary={subSubItem?.name} sx={{ opacity: open ? 1 : 0 }} />
                                                  </ListItemButton>
                                              </ListItem>
                                            </NavLink>
                                            }else{
                                              check=false;
                                            }
                                        })
                                      }
                                  </Collapse>
                               </React.Fragment>
                               

                          }else{
                              let checkIsInRole=false
                              subItm&&subItm?.roles&&subItm?.roles?.map((role)=>{
                             
                                if(!checkIsInRole)
                                  checkIsInRole=userRoles.includes(role)
                              })
                              if(checkIsInRole){
                                check=true;
                                
                              }else{
                                check=false;
                              }
                          }
                      // }
                    if (check)
                    return<NavLink id={`${helpers.removeForwordSplash('admin'+subItm?.to)}`} to={subItm?.to} key={subItm?.name+"_"+subItm?.id} className='itmContainerdropdown itmdropdown'>
                      <ListItem disablePadding sx={{ display: 'block',mt:0 }} disableRipple className='itmContainerdropdown itmdropdown '>
                          <ListItemButton
                          disableRipple
                            sx={{
                              minHeight: 48,
                              justifyContent: open ? 'initial' : 'center',
                              px: 2.5,
                            }}
                          >
                            <ListItemIcon
                              sx={{
                                minWidth: 0,
                                marginLeft:'30px',
                                mr: open ? 3 : 'auto',
                                justifyContent: 'center',
                              }}
                            >
                              {subItm?.icon && subItm?.icon(openParam)}
                            
                            </ListItemIcon>
                            <ListItemText primary={subItm?.name} sx={{ opacity: open ? 1 : 0 }} />
                          </ListItemButton>
                      </ListItem>
                    </NavLink>
                  

                })}
              </Collapse>
            )
        
        }else{
           
            let checkIsInRole=false
            obj&&obj?.roles&&obj?.roles?.map((subItm)=>{//
             
              if(!checkIsInRole)
                checkIsInRole=userRoles.includes(subItm)
            })
            if(checkIsInRole){
              check=true;
              ListArray.push(<NavLink id={`${helpers.removeForwordSplash('admin'+obj?.to)}`} to={obj?.to} key={obj?.name+"_"+obj?.id} style={{margin:open?0:'5px 0',cursor:'pointer'}} >  
                    <ListItem disablePadding sx={{ display: 'block',mt:0 }} disableRipple>
                      <ListItemButton
                      disableRipple
                        sx={{
                          minHeight: 48,
                          justifyContent: open ? 'initial' : 'center',
                          px: 2.5,
                        }}
                      >
                        {
                          obj?.id===7&& open?
                            <Box sx={{display:'flex',justifyContent:'space-between',alignItems:'center',width:'100%'}}>
                              <Box sx={{display:'flex',justifyContent:'start',alignItems:'center'}}>
                                <ListItemIcon
                                  sx={{
                                    minWidth: 0,
                                    mr: open ? 3 : 'auto',
                                    justifyContent: 'center',
                                  }}
                                >
                                  
                                  {obj?.icon && obj?.icon(openParam)}
                                  
                                </ListItemIcon>
                                <ListItemText primary={obj?.name} sx={{ opacity: open ? 1 : 0 }} />
                              </Box>
                              <Box sx={{
                                  display:'flex',justifyContent:'center',alignItems:'center',
                                  backgroundColor:'#000',
                                  color:'#ffffff',
                                  width: "30px", 
                                  height: "16px", 
                                  textAlign: "center", 
                                  font: "normal normal bold 14px/18px Rajdhani", 
                                  letterSpacing: "0px", 
                                  color: "#FFFFFF",
                                  opacity: "1",
                                  borderRadius:'50%',
                                  padding:'15px 10px'
                              }}>
                                  8
                              </Box>
                            </Box>:<>
                              <ListItemIcon
                                sx={{
                                  minWidth: 0,
                                  mr: open ? 3 : 'auto',
                                  justifyContent: 'center',
                                }}
                              >
                                 {obj?.icon && obj?.icon(openParam)}
                              </ListItemIcon>
                              <ListItemText primary={obj?.name} sx={{ opacity: open ? 1 : 0 }} />
                            </>
                        }
                      </ListItemButton>
                    </ListItem>
                  </NavLink>
              )
            }else{
              check=false;
            }
       

        }
      }
      
    })
    return ListArray

}
  
 const handleOpenOrClose=(id,dataList)=>{
      var arr=[]
      dataList&&dataList?.length>0&&dataList?.map((itm)=>{
          if(itm?.subLink?.length>0){
           
           if(itm?.id==id){
              arr.push({
                 ...itm,
                 show:itm?.show==true?false:true,
              })
           }
           else{
            let object=itm
            let subArr=[]
            itm?.subLink&&itm?.subLink?.length>0&&itm?.subLink?.map((itm2)=>{
                
              if(itm2?.subLink?.length>0){
                if(itm2?.id==id){
                  subArr.push({
                    ...itm2,
                    show:itm2?.show==true?false:true,
                  })
                }else{
                  subArr.push(itm2)
                }
              }else{
                subArr.push(itm2)
              }
             

            })
            if(subArr?.length){
              object={
                ...itm,
                subLink:subArr,
              }
              arr.push(object);
            }
            //  handleOpenOrClose(id,itm?.subLink,itm?.id)
           }
          }else{
         
            arr.push(itm)
          }
      })
      
     setSelectedId(null)
    dispatch(setcurrentOpenMenuCollapse(null))
     return arr
 }

 useEffect(()=>{
  
  if(currentOpenMenuCollapse&&pathsList?.length){
    let data=handleOpenOrClose(currentOpenMenuCollapse,pathsList)
    if(data&&data?.length>0){
      setPathList(data)
    }
  }
  // else if(!currentOpenMenuCollapse&&pathsList?.length){


  //   var data=[]
    
  //   if(openCollapseOfOpen>0){
  //     data=handleOpenOrClose(openCollapseOfOpen,pathsList)
  //     if(openChildOfOpen>0){
  //       if(data&&data?.length)
  //       data=handleOpenOrClose(openChildOfOpen,data)
  //       else
  //       data=handleOpenOrClose(openChildOfOpen,pathsList)
  //     }
  //   }
  //     if(data&&data?.length>0)
  //      setPathList(data)
  // }
  // return()=>{

  // }
 },[currentOpenMenuCollapse,openChildOfOpen,openCollapseOfOpen,open])




  return (
    <List
      sx={{
        // height:'70% !important',
        flex:1,
        // maxHeight:'100%',
        // overflowX: 'hidden',
        // overflowY: 'scroll !important',
        width:'100%',
        display:'flex',flexDirection:'column',alignItems:open?'space-between':'center',justifyContent:'start',
        alignSelf:'center',
        '& .iconSideMenu':{
            color: `${sidemenutheme?.iconColor} `,

            fontSize:'28px'
        },
        '& .MuiListItemText-root .MuiTypography-root':{
            textAlign: "left", 
            font: "normal normal 15px/30px Cairo-Medium !important", 
            letterSpacing: "0px", 
            color: sidemenutheme?.textitemcolor, 
            opacity: "1",
        },
        '& .MuiButtonBase-root':{
          backgroundColor:'transparent !important',
          padding:'2px 20px !important'
        },
        '& .MuiListItem-root':{
          borderRadius:open?'0px':'50%',
          width:open?'100%':'40px',
          height:open?'48px':'40px',
          display:'flex',
          justifyContent: 'center',
          alignItems: 'center',
          alignContent: 'center',
          // background:location?.pathname==currentpath?`${sidemenutheme.itmeshover} !important`:`transparent !important`
        }, 
        '& .MuiListItem-root:hover':{
          backgroundColor:`${sidemenutheme.itmeshover} !important`,
        },
        '& .MuiListItem-root:hover .iconSideMenu':{
          color:`${sidemenutheme?.secondaryColor } !important`
        },
        '& .MuiListItem-root:hover .MuiListItemText-root .MuiTypography-root':{
          color:`${sidemenutheme?.secondaryColor } !important`
        },
        '& .active .MuiListItem-root':{
          backgroundColor:`${sidemenutheme.itmeshover} !important`
        },
        '& .active .iconSideMenu':{
          color: `${sidemenutheme?.secondaryColor} !important`,
        },
        '& .active .MuiListItemText-root .MuiTypography-root':{
          color:sidemenutheme?.secondaryColor+' !important',
        },
        "& .activeDropDown":{
          backgroundColor: `#a3bbccbd !important`,
          borderRadius:open?'0px':'50%',
          width:open?'100%':'40px',
          height:open?'48px':'40px',
        },
        "& .activeDropDown":{
          backgroundColor: `#a3bbccbd !important`,
          borderRadius:open?'0px':'50%',
          width:open?'100%':'40px',
          height:open?'48px':'40px',
        },
        "& .activeDropDown2":{
          backgroundColor: `#cbcbcbc2 !important`,
        },
        "& .activeDropDown .itmdropdown svg,& .activeDropDown .itmdropdown .MuiTypography-root,& .itmContainerdropdown .MuiTypography-root,& .itmContainerdropdown .iconSideMenu ,& .itmContainerdropdown2 .MuiTypography-root,& .itmContainerdropdown2 .iconSideMenu ":{
          color:sidemenutheme?.secondaryColor+' !important',
          cursor:'pointer !important',
        },
        "& .itmContainerdropdown":{
          backgroundColor: `#e8e8e8 !important`,
        },
        "& .itmContainerdropdown2":{
          backgroundColor: `#cbcbcbc2 !important`,
        },
        '& .tooltipeSideMenu':{
          display:open?'none !important':'block !important',
        }
      }}
    >
        
        {
          
         renderShowMenuItem(open)
        }
    </List>
  )
}
