import callAPI from '../ApiConfig'
import store from '../../../app/store'

const MasterUnitTypes = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"master_unit_types",
        method:"get",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
    
}
const MasterUnitTypeList = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"master_unit_types_list",
        method:"get",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
    
}
const addMasterUnitType = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"master_unit_types",
        method:"post",
        signal:props?.signal,
        params:props?.params,
        data:props?.data,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
    
}
const updateMasterUnitType = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"master_unit_types/"+props?.data?.id,
        method:"put",
        signal:props?.signal,
        params:props?.params,
        data:props?.data,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
    
}
const deleteMasterUnitType = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"master_unit_types/"+props?.data?.id,
        method:"delete",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
    
}



const MasterUnitTypeAPI={
    MasterUnitTypes:MasterUnitTypes,
    MasterUnitTypeList:MasterUnitTypeList,
    addMasterUnitType:addMasterUnitType,
    updateMasterUnitType:updateMasterUnitType,
    deleteMasterUnitType:deleteMasterUnitType,

}
export default MasterUnitTypeAPI