import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  translation_en: {
    GLOBAL: {},
    LOGIN:{
      login_title:'Login'
    }
  },
  translation_ar: {
    GLOBAL:{
      cancel_button:'الغاء',
      save_button:'حفظ',
      GRID_NO_DATA:'لا توجد بيانات',
      GRID_NO_RESULT_DATA:'لا توجد بيانات',
      action_edit:'تعديل',
      action_delete:"حذف",
      action_view:'عرض',
      SEARCH_RESULT_OF_SEARCH:"النتيجة",
      SEARCH_SEARCH_BUTTON:'بحث',
      SEARCH_CLEAR_BUTTON:"مسح",
      Save:'حفظ',
      close:'اغلاق',
      clear:'مسح',
      emptyPoPup:'فارغ',
      noData:'لا توجد بيانات',
      Choose:"اختر",
      puse:'ايقاف التشغيل التلقائي',
      play:'بدء التشغيل التلقائي',
      update:'تحديث',
      print:"طباعة",
      notAssignedYet:'لم يتم الاسناد بعد',
      notAssingedDateYet:'لم يتم التعيين',
      comingSoon:'قريبا',
      openAdvanceSearchGrid:'اضهار البحث ',
      closeAdvanceSearchGrid:'اغلاق البحث',
      timeAvailableUntilCancelBooking:'الوقت المتبقي لألغاء الحجز',
      cancelBooking:'الغاء الحجز',
      create_order:'انشاء طلب',
      create_invoice:'أنشاء فاتورة',
      create_contract:'أنشاء عقد',
      Cancel_order:'الغاء الطلب',
      noDataImage:'لا توجد صور',
      imageType:'نوع الصورة',
      exitFullScreenMode:"الخروج من وضع الشاشة الكاملة",
      fullScreenMode:'وضع الشاشة الكاملة',
      copyLink:'نسخ الرابط',
      print_contract:"طبع العقد",
      Active:'فعال',
      unActive:'غير فعال',
      customerPohone:'رقم الهاتف للزبون',
      freeUnit:'الوحدات المتاحة',
      reservedUnit:'الوحدات المحجوزة',
      soldUnit:'الوحدات المباعة',
      allUnit:'عدد الوحدات',
      are_you_sure:'هل انت متأكد',
      yes:'نعم',
      no:'كلا، ارجو الغاء العملية'
    },
    COMPANY:{
      projectsTitle:'قائمة المشاريع',
      callCenter:'مركز خدمة الدعم',
      employee:'الموظفون',
      customers:'الزبائن',
      units:'الوحدات السكنية',
      available_units:'الوحدات السكنية المتاحة',
      compounds:'المجمعات السكنية',

    },
    PROFILE:{
      USER_TITLE:'معلومات المستخدم',
      USERNAME:'اسم المستخدم',
      FULL_NAME:'اسم المستخدم كامل',
      PHONE:'رقم الهاتف',
      email:'البريد الالكتروني',
      UPDATE_BUTTON:'تحديث المعلومات',
      ADD_BUTTON:'اضافة معلومات'
    },
    LOGIN:{
      login_title:'تسجيل دخول',
      company_name:'نظام Milestone',
      company_title:'لادارة المجمعات السكنية والعقارات',
      wellcome_message:'مرحبا بك في نظام Milestone',
      Introductory_message:'هذا نظام آمن وستحتاج إلى تقديم تفاصيل تسجيل الدخول الخاصة بك للوصول إلى الموقع.',
      user_name:'اسم المستخدم',
      user_password:'كلمة المرور',
      checkbox_remember_me:'تذكرني',
      login:'تسجيل دخول',
      forget_password:'هل نسية كلمة المرور؟',
      footer_message:'مقدم من شركة ركن الحلول المتكاملة لتقنية المعلومات',
      reset_password_title:'أكتب رقم الهاتف الخاص بك لاستعادة كلمة المرور',
      phone_number:'رقم الهاتف',
      Submit:'أرسال',
      recived_sms_code_title:'أدخل الرمز المرسل اليك برسالة قصيرة',
      not_recivied_message:'لم يصلك الرمز ؟',
      reSend_message:'أعادة ارسال',
      new_password_form_title:'يرجى كتابة كلمة المرور الجديدة الان',
      confirm_user_password:'تأكيد كلمة المرور',
      save_changes_passwords:'حفظ التغييرات',
    },
    ERROR:{
      login_username_error:'اسم المستخدم مطلوب',
      login_userpassword_error:'يجب ادخال كلمة المرور',
      userName_or_password_error:'اسم المستخدم او كلمة المرور غير صحيحة'
    },
    SIDEMENU:{
      dashboard:'لوحة المعلومات',
      Available_compounds:"استعراض الوحدات",
      Contracts_and_documents:'العقود و المستندات',
      logout:'تسجيل خروج',
      Department:'ادوار الموظفين',
      analytics:'الاحصائيات',
      Registered_customers:'الزبائن المسجلين',
      alert_and_notification:'التبليغات و الاشعارات',
      Payments_and_reimbursement:'المدفوعات و السداد',
      Reservations_and_appointments:'الحجوزات  و المواعيد',
      mapper:'تحديد الصور',
      Masters:'الرئيسية',
      WorkType:'انواع الوظائف',
      Employee:'الموظفين',
      PurposeOfPurchase:"اسباب الشراء",
      MaritalStatus:'الحالة الزوجية',
      Compound:"المجمعات",
      customertype:'أنواع الزبائن',
      contactmethod:'طرق الاتصال',
      nationalIdTypes:'انواع الوثائق التعريفية',
      realestates:'الانواع الثانوية للوحدات',
      calls:'الاتصالات',
      call_types:'انواع الاتصال',
      call_status:'حالة الاتصال',
      call_destinations:'جهات الاتصال',
      call_reasons:'اسباب الاتصال',
      unitLocations:'مواقع الوحدات',
      roomtypes:'انواع الغرف',
      imageTypes:"انواع الصور",
      roles:"الصلاحيات",
      Calls:'سجل الاتصالات',
      orders:'طلبات الشراء',
      unitStatuses:'حالات الوحدات',
      invoices:'الفواتير',
      unitTypes:'انواع الوحدات',
      rooms:'الغرف',
      units:'الوحدات',
      payment_type_details:"التسلسل الزمني لانواع الدفع",
      payment_types:"طرق الدفع",
      phases:"مراحل العمل",
      companies:'الشركات',
      companyProfile:'ملف  الشركة',
      userProfile:'ملف  المستخدم',
      Discount:'التخفيضات',
      MasterUnitTypes:'انواع الوحدات الرئيسية',
      mainSettings:'اعدادات رئيسية',
      realStateSettings:'الاعدادات',
      realStateMaster:'العقارات',
      customerMaster:"الزبائن",
      customerSettings:'الاعدادات',
      Processes:'العمليات',
      bulk_insert_floors:'ادخال مجموعة طوابق',
      bulk_insert_buildings:'ادخال مجموعة بنايات',
      bulk_delete_floors:'حذف مجموعة طوابق',
      bulk_delete_buildings:'حذف مجموعة بنايات'
    },
    DASHBOARD:{
      statistics:'الاحصائيات',
      number_of_customers:'عدد العملاء',
      available_unit:'الوحدات المتوفرة',
      units_sold:'الوحدات المباعة',
      complex_sales:'مبيعات المجمعات',
      Sales_Statistics:'إحصائيات المبيعات',
      Latest_clients:'أحدث العملاء',
      salse:'المبيعات',
      Finance:"المالية",
      compounds:'أختر المجمعات السكني',
      filters:'بحث',
      sold:'مباعة',
      status_sale:'نسبة الوحدات للبيع',
      status_reverse:'نسبة الوحدات المحجوزة',
      status_saleBycustomer:'نسبة الوحدات  المباعة بواسطة الزبون',
      status_sold:'نسبة الوحدات المباعة',
      Total_status:'مجموع الحالات',
      statusesCompound:'الحالات الخاصة بمجمع ',
      typeOfSoldeUnits:'انواع الوحدات المباعة',
      salesManSoldUnit:'مجموع المبيعات للموظف',
      salesMansSoldUnitUSD:'الوحدات المباعة لكل مندوب بالدولار الامريكي',
      salesMansSoldUnitIQD:'الوحدات المباعة لكل مندوب بالدينار العراقي',

      salesManSoldUnitIQD:'المجموع بالدينار العراقي',
      salesManSoldUnitUSD:'المجموع بالدولار الامريكي',

      outstanding_IQD:'الرصيد القائم للزبائن',
      outstanding_USD:'الرصيد القائم للزبائن',
      paymentOverDue_IQD:' الدفعات المتأخرة للزبائن',
      paymentOverDue_USD:'الدفعات المتأخرة للزبائن',
      customer_outstanding_id:'#',
      customer_outstanding_name:'اسم الزبون',
      customer_outstanding_customer:'اسم الزبون',
      customer_outstanding_mobile:'الهاتف',
      customer_outstanding_compound:'المجمع السكني',
      customer_outstanding_unit:'الوحدة',
      customer_outstanding_amount:'القيمة',
      customer_outstanding_currency:'العملة',
      customer_outstanding_first_month:'30 يوم',
      customer_outstanding_second_month:'60 يوم',
      customer_outstanding_third_month:'90 يوم',
      customer_outstanding_fourth_month:'180 يوم',
      customer_outstanding_fivth_month:'360 يوم',
      customer_outstanding_as_of_month:'باقي الفترة',

      customer_delinquent_id:'#',
      customer_delinquent_name:'اسم الزبون',
      customer_delinquent_customer:'اسم الزبون',
      customer_delinquent_mobile:'الهاتف',
      customer_delinquent_compound:'المجمع السكني',
      customer_delinquent_unit:'الوحدة',
      customer_delinquent_dueAmount:'المبلغ المطلوب',
      customer_delinquent_paidAmount:'المبلغ المدفوع',
      customer_delinquent_balancingAmount:'المبلغ المتبقي',
      customer_delinquent_overDueAmount:'المبلغ المتأخر',
      customer_delinquent_totalAmount:"مجموع المبالغ",

      customer_delinquent_currency:'العملة',

      customer_delinquent_as_of_month:'باقي الفترة',
      
      month:'الشهر',
      fromDate:'من تاريخ',
      toDate:'الى تاريخ',
      daydate:'اختر تاريخ اليوم',
      balancingAmount:'الرصيد المتبقي',
      paidAmount:'الرصيد المدفوع',
      DUEAMOUNT:'الرصيد المطلوب',
      customer_outstanding_totalAmount:'مجموع المبالغ',
      CustomerOutStandingGrid:'الزبائن المستحقين للدفع',
      Customerdelinquent:'الزبائن المتأخرين بالدفع',

      customer_outstanding_dat_30:'30 يوم',
      customer_outstanding_dat_60:'60 يوم',
      customer_outstanding_dat_90:'90 يوم',
      customer_outstanding_dat_180:'180 يوم',
      customer_outstanding_dat_360:'360 يوم',
      customer_outstanding_asMonth:'باقي الفترة'

    },
    ADVANCESEARCH:{
      title:'البحث عن عملاء',
      customer_name:'أسم العميل',
      customer_phone_number:'رقم الهاتف',
      Name_ofCumpany:"تاريخ التسجيل",
      fromDate:'من ',
      toDate:'الى',
      no_cutomerTypeData:'لا توجد بيانات',
      SelectCustomerWorkType:'نوع العميل',
      salesEmployee:'موظف المبيعات',
      selectedReason:'الغرض',
      no_resones:'لا توجد بيانات',
      advancesearch:'البحث المتقدم',
      close_advancesearch:'الغاء البحث المتقدم',
      applaySearch:'تطبيق البحث',
      sideOfWork:'جهة العمل',
      no_workside:'لا توجد بيانات',
      companyname:'اسم الشركة',
      clearSearch:'مسح',
      customerStatus:'حالة العميل',
      active:'فعال',
      unActive:'غير فعال'

    },
    CUSTOMER:{
      register_customer:'الزبائن المسجلين',
      add_ccustomer:'إضافة زبون جديد',
      company_information:'معلومات أخرى',
      address_and_connection_information:'العنوان ومعلومات الاتصال',
      personal_information:'المعلومات الشخصية',
      customer_name:'الأسم الثلاثي',
      AcademicAchievement:'التحصيل الدراسي',
      maritalStatus:'الحالة الاجتماعية',
      workType:'نوع العمل',
      workPlace:'مكان العمل',
      callsType:'الطريقة المثلى للتواصل',
      country:'البلد',
      governorate:'المحافظة',
      region:'المنطقة',
      nearPoint:'أقرب نقطة دالة',
      firstPhone:'رقم الهاتف الاول',
      secondPhone:'رقم الهاتف الثاني',
      thirdPhone:'رقم الهاتف الثالثة',
      selectedAccountProvider:'المندوب المسؤول عن الحساب',
      selectedPurposeOfSell:'الغرض من الشراء',
      purpose_of_purchase_note:'سبب اخر للشراء',
      selectedOwnUnitWithCompany:'هل يملك وحدات مع الشركة',
      selectedFavoritePaymentMethods:'طريقة الدفع المفضلة',
      selectedWorkType:'نوع العمل',
      SelectedCustomerType:'نوع العميل',
      selectedTypeOfInterest:'نوع الاهتمام',
      selectedTypeOfIterestRealEstate:'نوع الاهتمام (عقار)',
      selectedTypeOfInterestCompound:'نوع الاهتمام (مجمع سكني)',
      rating_message:'جدية الاهتمام',
      add_relative_persone:'أضافة مرافقين للعميل',
      relative_persone_name:'الاسم الثلاثي',
      relative_persone_governorate:'المحافظة',
      relative_persone_phone:'رقم الهاتف',
      relative_persone:'نوع العلاقة',
      saveInfo:'حفظ المعلومات',
      clearData:'مسح المعلومات',
      purposeOfSellNote:'ملاحظة',
      relative_persone_address:'عنوان',
      grid_customer_name:'اسم العميل',
      grid_customer_phone:'رقم الهاتف',
      grid_customer_address:'العنوان',
      grid_customer_relative:'موظف المبيعات',
      call_log:'سجل المكالمات',
      schedule_new_event:'جدولة موعد جديد',
      edit_informations:'تعديل المعلومات',
      delete_customer:'حذف العميل',
      FinancialDetails:'التفاصيل المالية',
      select_compound:'يرجى أختيار المجمع السكني في اي محافظة',
      no_unit:'لا يوجد وحدات متاحة للتعامل',
      has_unit:'يوجد وحدات متاحة للتعامل',
      select_compound_building:'يرجى اختيار الطابق',
      invoices:'الفواتير',
      receipt:'سندات القبض',
      financial_summary_in_iqd:'الملخص المالي للفواتير المصدرة بالدينار العراقي',
      financial_summary_in_usd:'الملخص المالي للفواتير المصدرة بالدولار الامريكي',
      total_invoices_in_iqd:'اجمالي مبلغ الفواتير المصدرة ',
      total_invoices_in_usd:'اجمالي مبلغ الفواتير المصدرة ',
      due_date_invoices_in_iqd:'اجمالي مبلغ الفواتير المستحقة ',
      due_date_invoices_in_usd:'اجمالي مبلغ الفواتير المستحقة ',
      total_payments_in_iqd:'اجمالي مبلغ السندات ',
      total_payments_in_usd:'اجمالي مبلغ السندات ',
      balance_in_iqd:"الرصيد",
      balance_in_usd:"الرصيد",
      
      Totalamountpaid:'اجمالي المبلغ المدفوع',
      lastPaymentAmount:'مبلغ اخر دفعة',
      dateForLastPayment:'تاريخ اخر دفعة',
      phone:"الهاتف",
      address:"العنوان",
      relation_type:"العلاقة",
      name:"الاسم",
      update_contact_title:"تعديل معلومات المرافق",
      add_contact_title:"اضافة مرافق جديد للزبون",
      addNewCalls:'اضافة اتصال جديد',
      ShowAllCalls:'عرض جميع الاتصالات',
      callOptions:'الخيارات',
      user_info:'معلومات المستخدم',
      user_phone:"الهاتف",
      password:"كلمة المرور",
      confirm_password:"تاكيد كلمة المرور",
      username:"اسم المستخدم",
      gender:"الجنس",
      delete_contact:'حذف المرافق',
      male:'ذكر',
      FEMALE:'أنثى',
      created_at:'تارخ الانشاء',
      id:'#'

    },
    NETWORKMESSAGE:{
      addCustomerMessageSuccess:'تم اضافة المعلومات بنجاح',
      addCustomerMessageError:'خطء غير معروف',
      messageError:'خطء غير معروف',
      deleteErrorr:'خطء غير معروف',
      deleteSuccess:'تم الحذف بنجاح',
      UpdateCustomerMessageSuccess:'تم تحديث المعلومات بنجاح',
      saveError:"حصل خطأ في حفظ البيانات",
      invoiceGeneratedSuccessfully:"تم انشاء الفواتير بنجاح",
      operationFailed:"فشلت العملية",
      canceledSuccess:'تم الغاء الطلب بنجاح'
    },
    WORKTYPE:{
      id:'#',
      name:'الاسم',
      actions:'الوظائف',
      title:'انواع الوظائف',
      addTitle:'اضافة نوع عمل',
      updateTitle:"تحديث بيانات نوع العمل",
      editTitle:"تعديل بيانات نوع العمل",
      add_name:"الاسم",
      work_name_search:'الاسم',
      work_toDate_search:'الى تاريخ',
      work_fromDate_search:"من تاريخ",
      SEARCH_USERS:'المستخدم',
      hide_Columns:'أخفاء العواميد من جدول البحث',
      created_at:'تاريخ الانشاء'
    },
    COMPOUNDS:{
      id:'#',
      name:'الاسم',
      actions:'الوظائف',
      title:'تفاصيل المجمعات السكنية',
      addTitle:'اضافة مجمع سكني',
      updateTitle:"تحديث بيانات المجمع السكني",
      editTitle:"تعديل بيانات المجمع السكني",
      add_name:"الاسم",
      name_search:'الاسم',
      toDate_search:'الى تاريخ',
      fromDate_search:"من تاريخ",
      SEARCH_USERS:'المستخدم',
      hide_Columns:'أخفاء العواميد من جدول البحث',
      created_at:'تاريخ الانشاء',
      imageType:'نوع الصورة',
      description:'الوصف',
      skip:"تخطي",
      action_edit:'تعديل المجمع',
      action_delete:"حذف المجمع",
      action_add:"اضافة قاطع " ,
      addAddresses:'اضافة عناوين',
      SelectMark:'اختار موقع',
      phase:'مرحلة البناء',
      imageDetailsDescription:"وصف الصورة",
      imageDetailsName:"اسم الصورة",
      imageDetailsTitle:'اضافة تفاصيل للصورة او الملف',

       compound_name:'اسم المجمع',
       compound_description:'وصف المجمع',
       block_name:'اسم القاطع',
       block_description:'وصف القاطع',

       build_name:'اسم البناية',
       build_description:'وصف البناية',
       floor_name:'اسم الطابق',
       floor_description:'وصف الطابق',
       action_view:'عرض'

    },
    BLOCKS:{
      id:'#',
      name:'الاسم',
      actions:'الوظائف',
      title:'تفاصيل القاطع',
      addTitle:'اضافة القاطع',
      updateTitle:"تحديث بيانات القاطع",
      editTitle:"تعديل بيانات القاطع",
      add_name:"الاسم",
      name_search:'الاسم',
      toDate_search:'الى تاريخ',
      fromDate_search:"من تاريخ",
      SEARCH_USERS:'المستخدم',
      hide_Columns:'أخفاء العواميد من جدول البحث',
      created_at:'تاريخ الانشاء',
      imageType:'نوع الصورة',
      description:'الوصف',
      skip:"تخطي",
      action_edit:'تعديل القاطع',
      action_delete:"حذف القاطع",
      action_add:"اضافة بناية " ,
      phase:'مرحلة البناء',
      action_view:'عرض'


    },
    BUILDINGS:{
      id:'#',
      name:'الاسم',
      actions:'الوظائف',
      title:'تفاصيل البناية',
      addTitle:'اضافة البناية',
      updateTitle:"تحديث بيانات البناية",
      editTitle:"تعديل بيانات البناية",
      add_name:"الاسم",
      name_search:'الاسم',
      toDate_search:'الى تاريخ',
      fromDate_search:"من تاريخ",
      SEARCH_USERS:'المستخدم',
      hide_Columns:'أخفاء العواميد من جدول البحث',
      created_at:'تاريخ الانشاء',
      imageType:'نوع الصورة',
      description:'الوصف',
      skip:"تخطي",
      action_edit:'تعديل البناية',
      action_delete:"حذف البناية",
      action_add:"اضافة طابق " ,
      phase:'مرحلة البناء',
      action_view:'عرض'


    },
    FLOORS:{
      id:'#',
      name:'الاسم',
      floor_number:'رقم الطابق',
      actions:'الوظائف',
      title:'تفاصيل الطابق',
      addTitle:'اضافة الطابق',
      updateTitle:"تحديث بيانات الطابق",
      editTitle:"تعديل بيانات الطابق",
      add_name:"الاسم",
      name_search:'الاسم',
      toDate_search:'الى تاريخ',
      fromDate_search:"من تاريخ",
      SEARCH_USERS:'المستخدم',
      hide_Columns:'أخفاء العواميد من جدول البحث',
      created_at:'تاريخ الانشاء',
      imageType:'نوع الصورة',
      description:'الوصف',
      skip:"تخطي",
      action_edit:'تعديل الطابق',
      action_delete:"حذف الطابق",
      action_add:"اضافة وحدة سكنية " ,
      phase:'مرحلة البناء',
      action_view:'عرض'

    },
    TOOLTIP:{
      EXPORT:'تصدير',
      IMPORT:'استيراد',
      CREATE:'انشاء',
      MAP:'خريطة',
      TELEGRAM:'تليكرام',
    },
    EMPLOYEE:{
      ID:'#',
      username:'أسم المستخدم',
      full_name:'الاسم كامل',
      phone:'الهاتف',
      email:'الايميل',
      last_login_datetime:'اخر  دخول',
      last_logout_datetime:'اخر  خروج',
      company:'الشركة',
      active:'نشط',
      unActive:'غير نشط',
      actions:'الوظائف',
      title:"الموظفين",
      addTitle:'أضافة موظف جديد',
      updateTitle:'تحديث بيانات الموظف',
      fullName:'الاسم كامل',
      password:'كلمة المرور',
      confirmPassword:'تأكيد كلمة المرور',
      images:"الصور",
      created_at:'تاريخ الانشاء',
      fromDate:'من تاريخ',
      toDate:'الى تاريخ',
      status:'الحالة',
      SEARCH_USERS:'المستخدم',
      AssignDepartmentsToUser:'أسناد ادوار للموظف',
      role_name:'الدور',
      role_name_search:'اسم الدور',
      role_selected:'المحددة',
      role_unselected:'الغير محددة',
      role_id:'#',
      enable:'فعال',
      disable:'غير فعال',
    },
    PURPOSEOFPURCHASES:{
      id:'#',
      name:'الاسم',
      actions:'الوظائف',
      title:'الغرض من الشراء',
      addTitle:'اضافة غرض شراء',
      updateTitle:"تحديث بيانات غرض الشراء",
      editTitle:"تعديل بيانات نوع العمل",
      add_name:"الاسم",
      work_name_search:'الاسم',
      work_toDate_search:'الى تاريخ',
      work_fromDate_search:"من تاريخ",
      SEARCH_USERS:'المستخدم',
      hide_Columns:'أخفاء العواميد من جدول البحث',
      created_at:'تاريخ الانشاء'
    },
    MARITALSTATUS:{
      id:'#',
      name:'الاسم',
      actions:'الوظائف',
      title:'الحالة الزوجية',
      addTitle:'اضافة حالة زوجية',
      updateTitle:"تحديث بيانات الحالة الزوجية",
      editTitle:"تحديث بيانات الحالة الزوجية",
      add_name:"الاسم",
      work_name_search:'الاسم',
      work_toDate_search:'الى تاريخ',
      work_fromDate_search:"من تاريخ",
      SEARCH_USERS:'المستخدم',
      hide_Columns:'أخفاء العواميد من جدول البحث',
      created_at:'تاريخ الانشاء'
    },
    CUSTOMERTYPE:{
      id:'#',
      name:'الاسم',
      actions:'الوظائف',
      title:'أنواع الزبائن',
      addTitle:'اضافة نوع زبون',
      updateTitle:"تحديث بيانات نوع الزبون",
      editTitle:"تحديث بيانات نوع الزبون",
      add_name:"الاسم",
      work_name_search:'الاسم',
      work_toDate_search:'الى تاريخ',
      work_fromDate_search:"من تاريخ",
      SEARCH_USERS:'المستخدم',
      hide_Columns:'أخفاء العواميد من جدول البحث',
      created_at:'تاريخ الانشاء'
    },
    CONTACTMETHOD:{
      id:'#',
      name:'الاسم',
      actions:'الوظائف',
      title:'طرق الاتصال',
      addTitle:'اضافة طريقة اتصال',
      updateTitle:"تحديث بيانات  الاتصال",
      editTitle:"تحديث بيانات الاتصال",
      add_name:"الاسم",
      work_name_search:'الاسم',
      work_toDate_search:'الى تاريخ',
      work_fromDate_search:"من تاريخ",
      SEARCH_USERS:'المستخدم',
      hide_Columns:'أخفاء العواميد من جدول البحث',
      created_at:'تاريخ الانشاء'
    },
    NATIONALIDTYPES:{
      id:'#',
      name:'الاسم',
      actions:'الوظائف',
      title:'انواع الوثائق التعريفية',
      addTitle:'اضافة نوع هوية وطنية',
      updateTitle:"تحديث بيانات الهوية  الوطنية",
      editTitle:"تحديث بيانات الهوية الوطنية",
      add_name:"الاسم",
      work_name_search:'الاسم',
      work_toDate_search:'الى تاريخ',
      work_fromDate_search:"من تاريخ",
      SEARCH_USERS:'المستخدم',
      hide_Columns:'أخفاء العواميد من جدول البحث',
      created_at:'تاريخ الانشاء'
    },
    ERRORS:{
      reason_404:'لا يمكنك الوصول الى هذه الصفحة',
      message_404:'نحن نتأسف هذه الصفحة غير متاحة حاليا',
      goTo_404:'الذهاب للصفحة الرئيسية',
      
      reason_501:'لا يمكنك الوصول الى هذه الصفحة',
      message_501:'نحن نتأسف لا تمتلك صلاحية الوصول لهذه الصفحة',
      goTo_501:'الذهاب للصفحة الرئيسية',

      reason_500:'لا يمكنك الوصول الى هذه الصفحة',
      message_500:'نحن نتأسف  هنالك مشكلة بالسرفر يرجا المحاولة لاحقا ',
      goTo_500:'الذهاب للصفحة الرئيسية',

      reason_503:'لا يمكنك الوصول الى هذه الصفحة',
      message_503:'نحن نتأسف  هنالك مشكلة بالسرفر يرجا المحاولة لاحقا ',
      goTo_503:'اعادة تحميل الصفحة',
    },
    REALESTATES:{
      id:'#',
      name:'الاسم',
      actions:'الوظائف',
      title:'الانواع الثانوية للوحدات',
      addTitle:'اضافة  عقار',
      updateTitle:"تحديث بيانات  العقار",
      editTitle:"تحديث بيانات  العقار",
      add_name:"الاسم",
      work_name_search:'الاسم',
      work_toDate_search:'الى تاريخ',
      work_fromDate_search:"من تاريخ",
      SEARCH_USERS:'المستخدم',
      hide_Columns:'أخفاء العواميد من جدول البحث',
      created_at:'تاريخ الانشاء'
    },
    DEPARTMENTS:{
      id:'#',
      name:'الاسم',
      company_id:'الشركة',
      actions:'الوظائف',
      title:'ادوار الموظفين',
      addTitle:'اضافة  دور',
      updateTitle:"تحديث بيانات الدور",
      editTitle:"تحديث بيانات الدور",
      add_name:"الاسم",
      work_name_search:'الاسم',
      work_toDate_search:'الى تاريخ',
      work_fromDate_search:"من تاريخ",
      SEARCH_USERS:'المستخدم',
      hide_Columns:'أخفاء العواميد من جدول البحث',
      created_at:'تاريخ الانشاء',
      assign_role:'اسناد صلاحية للدور'
    },
    DEPARTMENT:{
      ID:'#',
      
     
      created_at:'تاريخ الانشاء',
      fromDate:'من تاريخ',
      toDate:'الى تاريخ',
      status:'الحالة',
      SEARCH_USERS:'المستخدم',
      AssignRoleForDepartment:'أسناد صلاحيات للدور',
      role_name:'الصلاحية',
      role_name_search:'اسم الصلاحية',
      role_selected:'المحددة',
      role_unselected:'الغير محددة',
      role_id:'#',
      enable:'فعال',
      disable:'غير فعال',
    },
    CALLTYPE:{
      id:'#',
      name:'الاسم',
      actions:'الوظائف',
      title:'أنواع الاتصال',
      addTitle:'اضافة  نوع اتصال',
      updateTitle:"تحديث بيانات الاتصال",
      editTitle:"تحديث بيانات الاتصال",
      add_name:"الاسم",
      work_name_search:'الاسم',
      work_toDate_search:'الى تاريخ',
      work_fromDate_search:"من تاريخ",
      SEARCH_USERS:'المستخدم',
      hide_Columns:'أخفاء العواميد من جدول البحث',
      created_at:'تاريخ الانشاء'
    },
    CALLSTATUS:{
      id:'#',
      name:'الاسم',
      actions:'الوظائف',
      title:'حالات الاتصال',
      addTitle:'اضافة  حالة اتصال',
      updateTitle:"تحديث بيانات حالة الاتصال",
      editTitle:"تحديث بيانات حالة الاتصال",
      add_name:"الاسم",
      work_name_search:'الاسم',
      work_toDate_search:'الى تاريخ',
      work_fromDate_search:"من تاريخ",
      SEARCH_USERS:'المستخدم',
      hide_Columns:'أخفاء العواميد من جدول البحث',
      created_at:'تاريخ الانشاء'
    },
    CALLDESTINATIONS:{
      id:'#',
      name:'الاسم',
      actions:'الوظائف',
      title:'جهات الاتصال',
      addTitle:'اضافة  جهة اتصال',
      updateTitle:"تحديث بيانات جهة الاتصال",
      editTitle:"تحديث بيانات جهة الاتصال",
      add_name:"الاسم",
      work_name_search:'الاسم',
      work_toDate_search:'الى تاريخ',
      work_fromDate_search:"من تاريخ",
      SEARCH_USERS:'المستخدم',
      hide_Columns:'أخفاء العواميد من جدول البحث',
      created_at:'تاريخ الانشاء',
      governorate:'جهة الاتصال',
      destinationName:'جهة الاتصال',
      fromEmployeeToCustomer:'من موظف الى زبون',
      fromCustomerToEmployee:'من زبون الى موظف',

    },
    CALLREASONS:{
      id:'#',
      name:'الاسم',
      actions:'الوظائف',
      title:'جهات الاتصال',
      addTitle:'اضافة  سبب اتصال',
      updateTitle:"تحديث بيانات سبب الاتصال",
      editTitle:"تحديث بيانات سبب الاتصال",
      add_name:"الاسم",
      work_name_search:'الاسم',
      work_toDate_search:'الى تاريخ',
      work_fromDate_search:"من تاريخ",
      SEARCH_USERS:'المستخدم',
      hide_Columns:'أخفاء العواميد من جدول البحث',
      created_at:'تاريخ الانشاء',
      destinationName:'جهة الاتصال',
      other:'اخرى',
    },
    PHASES:{
      id:'#',
      name:'الاسم',
      actions:'الوظائف',
      title:'مراحل العمل',
      add_description:'الوصف',
      compound_missing:'يجب اختيار المجمع لكل التفاصيل',
      add_phase_details:'اضافة تفاصيل المرحلة',
      addTitle:'اضافة  مرحلة عمل',
      updateTitle:"تحديث بيانات مرحلة عمل",
      editTitle:"تحديث بيانات مرحلة علم",
      add_name:"الاسم",
      work_name_search:'الاسم',
      work_toDate_search:'الى تاريخ',
      work_fromDate_search:"من تاريخ",
      SEARCH_USERS:'المستخدم',
      hide_Columns:'أخفاء العواميد من جدول البحث',
      created_at:'تاريخ الانشاء',
      select_compounds:"المجمع(المجمعات)",
      name:"المرحلة",
      you_have_to_select_at_least_one_compound:"يجب اختيار مجمع واحد على الاقل",
      end_date:"تاريخ الانتهاء",
      start_date:"تاريخ البدء",
      start_date_cannot_be_greater_than_end_date:"تاريخ البدء لايمكن ان يكون اكثر من تاريخ الانتهاء",
      description:'الوصف',
      compound_name:'اسم المجمع'
    },
    UNITLOCATION:{
      id:'#',
      name:'الاسم',
      actions:'الوظائف',
      title:'موقع الوحدات',
      addTitle:'اضافة  موقع وحدة',
      updateTitle:"تحديث بيانات الوحدة",
      editTitle:"تحديث بيانات الوحدة",
      add_name:"الاسم",
      work_name_search:'الاسم',
      work_toDate_search:'الى تاريخ',
      work_fromDate_search:"من تاريخ",
      SEARCH_USERS:'المستخدم',
      hide_Columns:'أخفاء العواميد من جدول البحث',
      created_at:'تاريخ الانشاء'
    },
    ROOMTYPE:{
      id:'#',
      name:'الاسم',
      actions:'الوظائف',
      title:'انواع الغرف',
      addTitle:'اضافة  نوع غرفة',
      updateTitle:"تحديث بيانات الغرفة",
      editTitle:"تحديث بيانات الغرفة",
      add_name:"الاسم",
      work_name_search:'الاسم',
      work_toDate_search:'الى تاريخ',
      work_fromDate_search:"من تاريخ",
      SEARCH_USERS:'المستخدم',
      hide_Columns:'أخفاء العواميد من جدول البحث',
      created_at:'تاريخ الانشاء'
    },
    PAYMENT_TYPE:{
      id:'#',
      name:'الاسم',
      actions:'الوظائف',
      title:' طرق الدفع',
      addTitle:'اضافة طريقة دفع',
      updateTitle:"تحديث بيانات طريقة الدفع",
      editTitle:"تحديث بيانات طريقة الدفع",
      add_name:"الاسم",
      work_name_search:'الاسم',
      work_toDate_search:'الى تاريخ',
      work_fromDate_search:"من تاريخ",
      SEARCH_USERS:'المستخدم',
      hide_Columns:'أخفاء العواميد من جدول البحث',
      created_at:'تاريخ الانشاء',
      enable:"فعال",
      disable:"غير فعال",
      description:"وصف",
      expiry_date:"تاريخ انتهاء الصلاحية",
      percentage:"نسبة مئوية",
      absolute:"قيمة ثابتة",
      add_payment_type_details:"اضافة تفاصيل نوع الدفع",
      value:"القيمة",
      days_duration_from_contract:"فترة الاستحقاق بعد توقيع العقد بالايام",
      no_payment_type_details:"لايوجد تفاصيل طريقة الدفع",
      no_value_for_payment_type_detail:"احدى تفاصيل طرق الدفع لاتحتوي على قيمة",
      no_days_duration_for_payment_type_detail:"احدى تفاصيل طرق الدفع لا تحتوي على عدد الايام من توقيع العقد",
      percentage_absolute:'نوع المبلغ',
      status:"الحالة",
      isNotPercentage:'قيمة ثابتة',
      isPercentage:'نسبة مئوية',
      totalPaymentNumber:'عدد الدفعات',
      percentage_greater_than_100:"لايمكن ان تكون قيمة النسبة المئوية اكبر من 100",
      percentage_should_be_100:"يجب ان يكون مجموع النسب المئوية يساوي 100",
      in_active:'غير فعال',
      active:'فعال'
    },
    IMAGETYPE:{
      id:'#',
      name:'الاسم',
      actions:'الوظائف',
      title:'انواع الصور',
      addTitle:'اضافة  نوع صورة',
      updateTitle:"تحديث بيانات الصورة",
      editTitle:"تحديث بيانات الصورة",
      add_name:"الاسم",
      work_name_search:'الاسم',
      work_toDate_search:'الى تاريخ',
      work_fromDate_search:"من تاريخ",
      SEARCH_USERS:'المستخدم',
      hide_Columns:'أخفاء العواميد من جدول البحث',
      created_at:'تاريخ الانشاء'
    },
    ROLE:{
      id:'#',
      name:'الاسم',
      actions:'الوظائف',
      title:'الصلاحيات',
      addTitle:'اضافة صلاحية',
      updateTitle:"تحديث بيانات الصلاحية",
      editTitle:"تحديث بيانات الصلاحية",
      add_name:"الاسم",
      work_name_search:'الاسم',
      work_toDate_search:'الى تاريخ',
      work_fromDate_search:"من تاريخ",
      SEARCH_USERS:'المستخدم',
      hide_Columns:'أخفاء العواميد من جدول البحث',
      created_at:'تاريخ الانشاء'
    },
    CALLS:{
      id:'#',
      name:'الاسم',
      actions:'الوظائف',
      title:'سجل الاتصالات',
      addTitle:'اضافة اتصال',
      updateTitle:"تحديث بيانات الاتصال",
      editTitle:"تحديث بيانات الاتصال",
      add_name:"الاسم",
      work_name_search:'الاسم',
      work_toDate_search:'الى تاريخ',
      work_fromDate_search:"من تاريخ",
      SEARCH_USERS:'المستخدم',
      hide_Columns:'أخفاء العواميد من جدول البحث',
      created_at:'تاريخ الانشاء',
      USERS:"المستخدم",
      CUSTOMERS:"الزبون",
      types:"نوع الاتصال",
      STATUS:"حالة الاتصال",
      reasons:"سبب الاتصال",
      neededFollowUp:"يحتاج متابعة",
      edit:'تعديل',
      delete:'حذف',
      customerFinancial:'الأمور المالية',
      customerUnits:'الوحدات',
      customerMaster:"بيانات الزبون",
      importance:'اتصال مهم',
      followups:'يحتاج متابعة',
      destination:'جهة الاتصال',
      doneFollowUp:'تمة المتابعة',
      neededFollowUps:'يحتاج متابعة',
      destinations:"جهة الاتصال",
      callReason:"الاسباب",
      neededFollowupDate:"وقت تاريخ المتابعة",
      other_call_reasons:"سبب اتصال اخر",
      callType:'نوع الاتصال',
      customers:'الزبون',
      date_time:"الوقت و التاريخ",
      callStatus:'حالة الاتصال',
      Importance:'الاتصال مهم',
      neededFollowUps:'يحتاج متابعة',
      Reply:'الرد',
      mustContatDateBeLessOrEqualCurrentDate:'يجب ان يكون وقت الاتصال اقل او يساوي الوقت الحالي',
      user:'المستخدم'

    },
    ORDERS:{
      id:'#',
      name:'الاسم',
      actions:'الوظائف',
      title:'طلبات الشراء',
      addTitle:'اضافة طلب',
      updateTitle:"تحديث بيانات الطلب",
      editTitle:"تحديث بيانات الطلب",
      add_name:"الاسم",
      work_name_search:'الاسم',
      work_toDate_search:'الى تاريخ',
      work_fromDate_search:"من تاريخ",
      SEARCH_USERS:'المستخدم',
      hide_Columns:'أخفاء العواميد من جدول البحث',
      created_at:'تاريخ الانشاء',
      USERS:"المستخدم",
      CUSTOMERS:"الزبون",
      types:"نوع الاتصال",
      STATUS:"حالة الاتصال",
      reasons:"سبب الاتصال",
      neededFollowUp:"يحتاج متابعة",
      edit:'تعديل',
      delete:'حذف',
      customerFinancial:'الأمور المالية',
      customerUnits:'الوحدات',
      customerMaster:"بيانات الزبون",
      importance:'اتصال مهم',
      followups:'يحتاج متابعة',
      destination:'جهة الاتصال',
      doneFollowUp:'تمة المتابعة',
      neededFollowUps:'يحتاج متابعة',
      destinations:"جهة الاتصال",
      callReason:"الاسباب",
      neededFollowupDate:"وقت تاريخ المتابعة",
      other_call_reasons:"سبب اتصال اخر",
      callType:'نوع الاتصال',
      customers:'الزبون',
      employee:'الموظف',
      employees:'الموظفين',
      date_time:"الوقت و التاريخ",
      callStatus:'حالة الاتصال',
      Importance:'الاتصال مهم',
      neededFollowUps:'يحتاج متابعة',
      Reply:'الرد',
      payment_type:"نوع التسديد",
      ordered_date:"تاريخ الطلب",
      contract_template:"نموذج العقد",
      customers:"الزبون",
      unit:"الوحدة (بيت،شقة...الخ)",
      discounts:"التخفيضات",
      unit_name:"اسم الوحدة",
      customer_name:"اسم الزبون",
      total_price:"السعر",
      created_at:"تاريخ الانشاء",
      calculate_after_discount:"احسب السعر بعد التخفيض",
      discounted_price:"السعر بعد التخفيض",
      contract:"العقد",
      contract_number:"رقم العقد",
      bank_name:"اسم المصرف",
      bank_branch:"اسم الفرع",
      generate_invoices:"انشاء فواتير",
      invoices:"معاينة الفواتير",
      order_status:'الحالة',
      customer:'الزبون',
      contract_printed_date:'تاريخ طباعة العقد',
      iqd_usd:'نوع العملة',
      invoicePayments:'عرض الدفعات',
      orderNumber:'رقم الطلب',
      mainPrice:'المبلغ الكلي',
      balance:'الرصيد'
    },
    INVOICES:{
      id:'#',
      name:'الاسم',
      actions:'الوظائف',
      title:'الفواتير',
      addTitle:'اضافة طلب',
      updateTitle:"تحديث بيانات الطلب",
      editTitle:"تحديث بيانات الطلب",
      add_name:"الاسم",
      work_name_search:'الاسم',
      work_toDate_search:'الى تاريخ',
      work_fromDate_search:"من تاريخ",
      SEARCH_USERS:'المستخدم',
      hide_Columns:'أخفاء العواميد من جدول البحث',
      created_at:'تاريخ الانشاء',
      USERS:"المستخدم",
      CUSTOMERS:"الزبون",
      types:"نوع الاتصال",
      STATUS:"حالة الاتصال",
      reasons:"سبب الاتصال",
      neededFollowUp:"يحتاج متابعة",
      edit:'تعديل',
      delete:'حذف',
      customerFinancial:'الأمور المالية',
      customerUnits:'الوحدات',
      customerMaster:"بيانات الزبون",
      importance:'اتصال مهم',
      followups:'يحتاج متابعة',
      destination:'جهة الاتصال',
      doneFollowUp:'تمة المتابعة',
      neededFollowUps:'يحتاج متابعة',
      destinations:"جهة الاتصال",
      callReason:"الاسباب",
      neededFollowupDate:"وقت تاريخ المتابعة",
      other_call_reasons:"سبب اتصال اخر",
      callType:'نوع الاتصال',
      customers:'الزبون',
      date_time:"الوقت و التاريخ",
      callStatus:'حالة الاتصال',
      Importance:'الاتصال مهم',
      neededFollowUps:'يحتاج متابعة',
      Reply:'الرد',
      payment_type:"نوع التسديد",
      ordered_date:"تاريخ الطلب",
      contract_template:"نموذج العقد",
      customers:"الزبون",
      unit:"الوحدة (بيت،شقة...الخ)",
      discounts:"التخفيضات",
      unit_name:"اسم الوحدة",
      customer_name:"اسم الزبون",
      total_price:"السعر",
      created_at:"تاريخ الانشاء",
      calculate_after_discount:"احسب السعر بعد التخفيض",
      discounted_price:"السعر بعد التخفيض",
      contract:"العقد",
      contract_number:"رقم العقد",
      bank_name:"اسم المصرف",
      bank_branch:"اسم الفرع",
      generate_invoices:"انشاء فواتير",
      preview:" معاينة",
      order_number:"رقم الطلب",
      due_date:"تاريخ الاستحقاق",
      print:"طباعة",
      make_payment:"تسديد دفعة",
      print_invoice:"طباعة فاتورة",
      paid_amount:"المبلغ المدفوع",
      add_payment:"اضافة دفعة",
      number:"رقم الفاتورة",
      print_payment:"طبع سند قبض",
      customer:'الزبون',
      iqd_usd:'نوع العملة',
      to_due_date:'الى تاريخ الاستحقاق',
      from_due_date:'من تاريخ الاستحقاق',
      order_id:'رقم الطلب',
      paid_status:'مسدد بالكامل',
      partially_paid_status:'مسدد بشكل جزئي',
      unpaid_status:'لم يتم التسديد',
      paid_status_title:'حالة التسديد',
      balnace:'الرصيد',
      contract:'العقد',
      total_paid_amount:"المبلغ المسدد"
    },
    ADDRESSES:{
      address_name:'العنوان',
      first_phone:'رقم الهاتف الاول',
      second_phone:'رقم الهاتف الثاني',
      third_phone:'رقم الهاتف الثالث',
      latitdue:'خط العرض',
      latitude:'خط العرض',
      longitude:"خط الطول",
      name:'الاسم',
      governorates:'المحافظة',
      saveADDRESS:'اضافة',
      options:'خيارات',
      addNewAddressTitle:'اضافة عناوين',
      updateTitle:'تحديث عنوان',
      addTitle:'اضافة عنوان',
      other_phones:'هواتف اخرى',
      main_phone:'الهاتف الرئيسي',
      search:'بحث',
    },
    UNITSTATUSESE:{
      id:'#',
      name:'الاسم',
      actions:'الوظائف',
      title:'حالات الوحدات',
      addTitle:'اضافة  حالة وحدة',
      updateTitle:"تحديث بيانات حالة وحدة",
      editTitle:"تحديث بيانات حالة الوحدة",
      add_name:"الاسم",
      work_name_search:'الاسم',
      work_toDate_search:'الى تاريخ',
      work_fromDate_search:"من تاريخ",
      SEARCH_USERS:'المستخدم',
      hide_Columns:'أخفاء العواميد من جدول البحث',
      created_at:'تاريخ الانشاء',
      durations:'الساعات',
      color:'اللون',
      add_days:"اليوم",
      colorRequired:'يجب اختيار لون للحالة'

    },
    UNITTYPE:{
      id:'#',
      name:'الاسم',
      actions:'الوظائف',
      title:'انواع الوحدات',
      addTitle:'اضافة  نوع وحدة',
      updateTitle:"تحديث بيانات نوع الوحدة",
      editTitle:"تحديث بيانات نوع الوحدة",
      add_name:"الاسم",
      building:"البناية",
      masterUnitType:"أسم الوحدة الرئيسي",
      add_building:"البناية",
      add_masterUnitName:"أسم الوحدة الرئيسي",
      work_name_search:'الاسم',
      work_toDate_search:'الى تاريخ',
      work_fromDate_search:"من تاريخ",
      SEARCH_USERS:'المستخدم',
      hide_Columns:'أخفاء العواميد من جدول البحث',
      created_at:'تاريخ الانشاء',
      durations:'الفترة',
      color:'اللون',
      compound:'المجمع',
      blocks:"القاطع",
      add_compounds:'المجمع',
      add_blocks:'القاطع',
      unitInfo:'تفاصيل الوحدة',
      block:'القاطع',

    },
    UNIT:{
      unitsImage:'صور الوحدة',
      unitDetails:'تفاصيل الوحدة',
      unitsInfo:'معلومات الوحدة',
      showActionsMenu:'تغيير حالة الوحدة',
      name:'الاسم',
      description:'الوصف',
      floor:'الطابق',
      compound:'مجمع',
      block:'قاطع',
      building:'بناية',
      unitLocation:"موقع الوحدة",
      unitType:'نوع الوحدة',
      unitStatus:'حالة الوحدة',
      customer:'الزبون',
      compoundPhase:'مرحلة البناء',
      price:'السعر',
      iqd_usd:'نوع العملة',
      area:'المساحة',
      width:'العرض',
      length:'الطول',
      deliveryDate:'تاريخ التسليم',
      movements:'الحركات',
      financial:"تفاصيل المالية",
      assingCustomerFormTitle:'أسناد زبون للوحدة',
      temp_customer:'الزبون المؤقت',
      ShowUnitMenu:'عرض الادوات',
      imageType:'نوع الصورة',
      created_at:'تاريخ الانشاء',
      
    },
    ROOMS:{
      id:'#',
      name:'الاسم',
      length:'الطول',
      actions:'الوظائف',
      title:'الغرف',
      addTitle:'اضافة غرفة',
      updateTitle:"تحديث بيانات الغرفة",
      editTitle:"تحديث بيانات الغرفة",
      add_name:"الاسم",
      width:"العرض",
      add_width:"العرض",
      add_length:"الطول",
      unitType:'نوع الوحدة',
      roomType:'نوع الغرفة',
      addUnitType:'نوع الوحدة',
      addRoomType:'نوع الغرفة',
      work_name_search:'الاسم',
      work_toDate_search:'الى تاريخ',
      work_fromDate_search:"من تاريخ",
      SEARCH_USERS:'المستخدم',
      hide_Columns:'أخفاء العواميد من جدول البحث',
      created_at:'تاريخ الانشاء',
      durations:'الفترة',
      color:'اللون'
    },
    UNITS:{
      id:'#',
      name:'الاسم',
      description:'الوصف',
      floor:'الطابق',
      unitLocation:"موقع الوحدة",
      unitType:'نوع الوحدة',
      unitStatus:'حالة الوحدة',
      customer:'الزبون',
      compoundPhase:'مرحلة البناء',
      price:'السعر',
      iqd_usd:'نوع العملة',
      area:'المساحة',
      width:'العرض',
      length:'الطول',
      deliveryDate:'تاريخ التسليم',
      actions:'الوظائف',
      title:'الوحدات',
      addTitle:'اضافة وحدة',
      updateTitle:"تحديث بيانات الوحدة",
      editTitle:"تحديث بيانات الوحدة",
      add_name:'الاسم',
      add_description:'الوصف',
      add_floor:'الطابق',
      add_unitLocation:"موقع الوحدة",
      add_unitType:'نوع الوحدة',
      add_unitStatus:'حالة الوحدة',
      add_customer:'الزبون',
      add_compoundPhase:'مرحلة البناء',
      add_price:'السعر',
      add_iqd_usd:'نوع العملة',
      add_area:'المساحة',
      add_width:'العرض',
      add_length:'الطول',
      add_deliveryDate:'تاريخ التسليم',
      work_name_search:'الاسم',
      work_toDate_search:'الى تاريخ',
      work_fromDate_search:"من تاريخ",
      SEARCH_USERS:'المستخدم',
      hide_Columns:'أخفاء العواميد من جدول البحث',
      created_at:'تاريخ الانشاء',
      durations:'الفترة',
      color:'اللون',
      action_view:'عرض',
      imageType:'نوع الصورة',
      block:"القاطع",
      compound:"المجمع",
      building:"البناية",
      reserved:'المحجوزة',
      view_units:"معاينة الوحدات",
      iqd:"IQD",
      usd:"USD",
      all:"الكل",
      unitInfo:'تفاصيل الوحدة'

    },
    COMPANIES:{
      id:'#',
      name:'الاسم',
      description:'الوصف',
      email:"الايمل",
      active_status:'الحالة',
      actions:'الوظائف',
      title:'الشركات',
      addTitle:'اضافة شركة',
      updateTitle:"تحديث بيانات  شركة",
      editTitle:"تحديث بيانات شركة",
      add_name:"الاسم",
      add_description:'الوصف',
      add_email:"الايمل",
      add_active_status:'الحالة',
      work_name_search:'الاسم',
      work_toDate_search:'الى تاريخ',
      work_fromDate_search:"من تاريخ",
      SEARCH_USERS:'المستخدم',
      hide_Columns:'أخفاء العواميد من جدول البحث',
      created_at:'تاريخ الانشاء',
      unActive:'غير فعال',
      active:" فعال",
      imageType:'نوع الصورة',
    },
    DISCOUNTS:{
      id:'#',
      name:'الاسم',
      description:'الوصف',
      percentage_absolute:'قيمة نسبية',
      percentage_stabel:'قيمة ثابتة',
      value	:'القيمة',
      active_from	:'صالحة من تاريخ',
      active_to:'صالحة الى تاريخ',
      iqd_usd:'نوع العملة',
      actions:'الوظائف',
      title:'التخفيضات',
      addTitle:'اضافة  تخفيض',
      updateTitle:"تحديث بيانات التخفيض",
      editTitle:"تحديث بيانات التخفيض",
      add_name:"الاسم",
      add_description:'الوصف',
      add_percentage_absolute:'قيمة نسبية',
      add_percentage_stabel:'قيمة ثابتة',
      add_value	:'القيمة',
      add_active_from	:'صالحة من تاريخ',
      add_active_to:'صالحة الى تاريخ',
      add_iqd_usd:'نوع العملة',
      work_name_search:'الاسم',
      work_toDate_search:'الى تاريخ',
      work_fromDate_search:"من تاريخ",
      SEARCH_USERS:'المستخدم',
      hide_Columns:'أخفاء العواميد من جدول البحث',
      created_at:'تاريخ الانشاء',
      durations:'الفترة',
      color:'اللون',
    },
    MASTERUNITTYPES:{
      id:'#',
      name:'الاسم',
      compound_id:'المجمع',
      actions:'الوظائف',
      title:'انواع الوحدات الرئيسية',
      addTitle:'اضافة  نوع وحدة رئيسي',
      updateTitle:"تحديث بيانات نوع الوحدة الرئيسي",
      editTitle:"تحديث بيانات نوع الوحدة الرئيسي",
      add_name:"الاسم",
      add_comound_id:'المجمع',
      work_name_search:'الاسم',
      work_toDate_search:'الى تاريخ',
      work_fromDate_search:"من تاريخ",
      SEARCH_USERS:'المستخدم',
      hide_Columns:'أخفاء العواميد من جدول البحث',
      created_at:'تاريخ الانشاء',
      durations:'الفترة',
      color:'اللون'
    },
    BULK_INSERT_FLOORS:{
      select_floor_to_copy:"اختر الطابق المراد نسخه",
      copy_process_will_be_started:"سوف تبدأ عملية نسخ الطابق الى جميع مجموعة الطوابق المختارة",
      select_compound:"اختر المجمع السكني",
      select_block:"اختر القاطع",
      select_building:"اختر البناية",
      select_floor:"اختر الطابق",
      selected_floor:"تم اختيار الطابق",
      select_floor_range:"اختر مجموعة الطوابق المراد استنساخها من الطابق اعلاه",
      from:"من",
      to:"الى",
      please_select_valid_range:"الرجاء اختيار قيم صحيحة لمجموعة الطوابق",
      inserted_successfully:"تمت عملية الاستنساخ بنجاح",
      inserted_failed:"فشلت عملية الاستنساخ"
    },
    BULK_DELETE_FLOORS:{
      select_building_to_delete_floors_from:"اختر البناية المراد مسح طوابق منها",
      delete_process_will_be_started:"سوف تبدأ عملية مسح الطوابق ضمن النطاق المحدد",
      select_compound:"اختر المجمع السكني",
      select_block:"اختر القاطع",
      select_building:"اختر البناية",
      select_floor:"اختر الطابق",
      selected_building:"تم اختيار البناية",
      select_floor_range:"اختر مجموعة الطوابق المراد مسحها",
      from:"من",
      to:"الى",
      please_select_valid_range:"الرجاء اختيار قيم صحيحة لمجموعة الطوابق",
      deleted_successfully:"تمت عملية المسح بنجاح",
      deleted_failed:"فشلت عملية المسح",
      clear:"الغاء (حذف القيم)"
    },
    BULK_INSERT_BUILDINGS:{
      select_building_to_copy:"اختر البناية المراد نسخه",
      copy_process_will_be_started:"سوف تبدأ عملية نسخ البناية الى جميع مجموعة البنايات المختارة",
      select_compound:"اختر المجمع السكني",
      select_block:"اختر القاطع",
      select_building:"اختر البناية",
      selected_building:"تم اختيار البناية",
      select_building_range:"اختر مجموعة البنايات المراد استنساخها من البناية اعلاه",
      from:"من",
      to:"الى",
      please_select_valid_range:"الرجاء اختيار قيم صحيحة لمجموعة البنايات",
      inserted_successfully:"تمت عملية الاستنساخ بنجاح",
      inserted_failed:"فشلت عملية الاستنساخ",
      please_select_building:"رجاءا، اختر بناية واحدة على الاقل"
    },
    BULK_DELETE_BUILDING:{
      select_block_to_delete_buildings_from:"اختر القاطع المراد مسح بنايات منه",
      delete_process_will_be_started:"سوف تبدأ عملية مسح البنايات ضمن النطاق المحدد",
      select_compound:"اختر المجمع السكني",
      select_block:"اختر القاطع",
      select_building:"اختر البناية",
      select_floor:"اختر الطابق",
      selected_block:"تم اختيار القاطع",
      select_building_range:"اختر مجموعة البنايات المراد مسحها",
      from:"من",
      to:"الى",
      please_select_valid_range:"الرجاء اختيار قيم صحيحة لمجموعة البنايات",
      deleted_successfully:"تمت عملية المسح بنجاح",
      deleted_failed:"فشلت عملية المسح",
      clear:"الغاء (حذف القيم)",
      please_select_building:"رجاءا، اختر بناية واحدة على الاقل"
    },
    BUILDING_SELECTION:{
      building_id:"الرمز",
      building_name:"الاسم",
      building_name_search:"بحث عن طريق الاسم",
      building_selected:"البنايات المحددة",
      building_unselected:"البنايات الغير محددة"
    },
    IMAGE_MAP_DRAW:{
      delete_polygon:"سوف يتم حذف المضلع"
    },
    IMAGE_MAP_VIEW:{
      compound:"المجمع",
      block:"القاطع",
      building:"البناية",
      floor:"الطابق",
      show_all_floors:'عرض الجميع حسب الطوابق'
    }
  },

};
export const TranslateSlice = createSlice({
  name: "translateData",
  initialState: initialState,
  reducers: {
    setTranslateEnglish:(state,action)=>{
      state.translation_en=action.payload
    },
    setTranslateArabic:(state,action)=>{
      state.translation_ar=action.payload
    },
  },
});

export const {
  setTranslateEnglish,
  setTranslateArabic,
} = TranslateSlice.actions;

// export const sideMenuMode = state => state.settingsData.sideMenuMode;

export default TranslateSlice.reducer;
