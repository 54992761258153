import React, { useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify';
import { Box, Typography, Divider, List, ListItem, ListItemText, IconButton } from '@mui/material'
import orderAPI from '../../../Global/Network/Orders/index';
import { useLocation, useNavigate } from 'react-router-dom'
import InvoiceRow from './Components/InvoiceRow';
import { useReactToPrint } from 'react-to-print';
import { BsPrinter } from 'react-icons/bs'
import { height } from '@mui/system';
import helpers from '../../../assets/js/helper';
import CustomTextField from '../../../General/CustomComponents/CustomTextField';
import logo from '../../../assets/img/milestone_logo.png'
import swal from 'sweetalert';
export default function InvoicePrint({ invoice,print,setPrint }) {

  const printRef = useRef();
  const handlePrint = useReactToPrint({

    content: () => {
      const tableStat = printRef.current.cloneNode(true);
      const PrintElem = document.createElement('div');
      const header =
        `<img src="${logo}" alt="" class="watermark"/>`
       +
      `<div class="pageFooter">I'm The Footer</div>` +
      `<div class="page-header">I'm The Header</div>`;
      PrintElem.innerHTML = header;
      PrintElem.appendChild(tableStat);
      return PrintElem;
    },
  });
  useEffect(() => {
    if(print)
    {
      handlePrint()
      setPrint(false)
    }
  
    return () => {
      
    }
  }, [print])
  


  const getName = () => {
    let customer = invoice?.order?.customer
    let title = "السيد"
    if (customer?.user?.gender) {
      title = "السيدة"
      if (customer?.marital_status_id == 2) {
        title = "الانسة"
      }
    }
    return title + " " + (customer?.user?.full_name ? customer?.user?.full_name : "")
  }
  const getAddress = (lineNumber) => {
    let customerAddress = invoice?.order?.customer?.addresses?.length > 0 ? invoice?.order?.customer?.addresses[0] : null
    if (!customerAddress) {
      return
    }
    if (lineNumber == 1) {
      return customerAddress?.name ? customerAddress?.name : ""
    }
    else if (lineNumber == 2) {
      return (customerAddress?.address ? "\n" + customerAddress?.address : "")
    }
    else if (lineNumber == 3) {
      return (customerAddress?.first_phone ? "" + customerAddress?.first_phone : "")
    }

    return ""

  }

  const getInvoicePriceFormated = () => {
    let invoicePrice = invoice?.total_price
    let unitCurrencyCode = invoice?.order?.unit?.iqd_usd == 0 ? "IQD" : "USD"

    return "(" + invoicePrice?.format(invoice?.order?.unit?.iqd_usd == 1 ? 2 : 0, 3, ',', '.') + ") " + helpers?.tafqeet(invoicePrice, unitCurrencyCode)
  }

  const getNoteText = () => {
    let text = "فاتورة صادرة بناء على العقد الموقع بين الطرفين لقاء بيع الوحدة السكنية (المساحة : "
    text += invoice?.order?.unit?.area + " متر مربع, النوع : " + invoice?.order?.unit?.unit_type?.name + " ) "
    text += "من قبل الطرف الأول (" + invoice?.order?.unit?.company?.name + ") "
    text += "الى الطرف الثاني (" + getName() + ")"
    if (invoice?.payment_type_detail?.days_duration_from_contract == 0) {
      text += "تتضمن الفاتورة الدفعة الأولية للشقة."
    }
    return text
  }
  return (

    <Box sx={{
      display: 'flex',
      flexDirection: 'column',

      width: '100%',
      justifyContent: 'center',
      alignItems: 'stretch',
      overflowX: 'hidden',

    }}
      className=" pt-3 mt-1"
    >
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          height: 'calc(100vh - 100px)',
          backgroundColor: "#FFFFFF",
          justifyContent: 'center',
          alignItems: 'center',
          overflow: 'scroll'
        }}
      >
        <Box
          ref={printRef}
          style={{
            backgroundColor: "#FFFFFF",
            width: '100%',
            padding: '20px',
            direction: 'rtl',
            height: '100%'
            // height: '842px',
          }}>
          <InvoiceRow
            title={"رقم الفاتورة:"}

            titleSX={{
              flex: 3,
              fontSize: '16px',
              fontWight: 800
            }}
            value={invoice?.id + ""}
            paddingRight={"20px"}

          />
          <InvoiceRow
            title={"تاريخ الفاتورة:"}
            titleSX={{
              flex: 3,
              fontSize: '16px',
              fontWight: 800
            }}
            value={invoice?.due_date + ""}
            paddingRight={"20px"}
          />
          <InvoiceRow
            title={"اسم الزبون الثلاثي واللقب:"}
            titleSX={{
              flex: 5,
              fontSize: '16px',
              fontWight: 800
            }}
            value={getName()}
            paddingRight={"20px"}
          />
          <InvoiceRow
            title={"هاتف الزبون:"}
            titleSX={{
              flex: 3,
              fontSize: '16px',
              fontWight: 800
            }}
            value={getAddress(3)}
            valueSX={{
              direction: 'ltr',
              textAlign: 'right'
            }}
            paddingRight={"20px"}
          />
          <InvoiceRow
            title={"عنوان الزبون:"}
            titleSX={{
              flex: 3,
              fontSize: '16px',
              fontWight: 800
            }}
            value={getAddress(1) + " " + getAddress(2)}
            paddingRight={"20px"}
          />
          <InvoiceRow
            title={"رقم العقد:"}
            titleSX={{
              flex: 3,
              fontSize: '16px',
              fontWight: 800
            }}
            value={invoice?.order?.contract_number}
            paddingRight={"20px"}
          />
          <InvoiceRow
            title={"المبلغ المطلوب:"}
            titleSX={{
              flex: 3,
              fontSize: '16px',
              fontWight: 800
            }}
            value={getInvoicePriceFormated()}
            paddingRight={"20px"}
          />
          <InvoiceRow
            title={"الملاحظات:"}
            titleSX={{
              flex: 3,
              fontSize: '16px',
              fontWight: 800
            }}
            value={getNoteText()}
            paddingRight={"20px"}
          />
        </Box>
      </Box>
    </Box>
  )
}
