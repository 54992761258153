import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { Box, Typography, Autocomplete, TextField, Checkbox, FormControlLabel } from "@mui/material";

import compoundAPI from "../../../Global/Network/Compound";
import blockAPI from "../../../Global/Network/Block";
import buildingAPI from "../../../Global/Network/Building";
import floorAPI from "../../../Global/Network/Floor";
import UnitTypesAPI from "../../../Global/Network/UnitType";
import UnitLocationsAPI from "../../../Global/Network/UnitLocation";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function LinkImagePoly(props) {
  const [t] = useTranslation("common");
  const controller = new AbortController();
  const [loading, setLoading] = useState(true);

  const [compounds, setCompounds] = useState([]);
  const [blocks, setBlocks] = useState([]);
  const [buildings, setBuildings] = useState([]);
  const [floors, setFloors] = useState([]);
  const [unitTypes, setUnitTypes] = useState([]);
  const [unitLocations, setUnitLocations] = useState([]);

  const [selectedCompound, setSelectedCompound] = useState(null);
  const [selectedBlock, setSelectedBlock] = useState(null);
  const [selectedBuilding, setSelectedBuilding] = useState(null);
  const [selectedFloor, setSelectedFloor] = useState(null);

  const [selectedUnitType, setSelectedUnitType] = useState(null);
  const [selectedUnitLocation, setSelectedUnitLocation] = useState(null);
  const [selectAllFloor,setSelectAllFloor]=useState(false)
 

  const [hideAutoComplete, setHideAutoComplete] = useState({
    hideBlocks: false,
    hideBuildings: false,
    hideFloors: false,
    hideUnitTypes: false,
  });

  useEffect(() => {
    const type = props?.selectedEntity?.sourceEntity?.type;

    if (type == "compounds") {
      setSelectedCompound(props?.selectedEntity?.sourceEntity?.entity);
      setHideAutoComplete({
        hideBlocks: false,
        hideBuildings: false,
        hideFloors: false,
        hideUnitTypes: false,
      });
    } else if (type == "blocks") {
      setSelectedBlock(props?.selectedEntity?.sourceEntity?.entity);
      setHideAutoComplete({
        hideBlocks: true,
        hideBuildings: false,
        hideFloors: false,
        hideUnitTypes: false,
      });
    } else if (type == "buildings") {
      setSelectedBuilding(props?.selectedEntity?.sourceEntity?.entity);
      setHideAutoComplete({
        hideBlocks: true,
        hideBuildings: true,
        hideFloors: false,
        hideUnitTypes: false,
      });
    } else if (type == "floors") {
      setSelectedFloor(props?.selectedEntity?.sourceEntity?.entity);
      setHideAutoComplete({
        hideBlocks: true,
        hideBuildings: true,
        hideFloors: true,
        hideUnitTypes: false,
      });
    }

    return () => {};
  }, [props?.open]);

  useEffect(() => {
    loadBlocks();

    return () => {};
  }, [selectedCompound]);

  useEffect(() => {
    loadBuildings();

    return () => {};
  }, [selectedBlock]);

  useEffect(() => {
   
    loadFloors();
    return () => {};
  }, [selectedBuilding]);

  useEffect(() => {
    loadUnitType();
    loadUnitLocation();
    
    
    if(selectedFloor?.length==0){
     
      setSelectAllFloor(false)
    }
    return () => {};
  }, [selectedFloor]);


  useEffect(()=>{
    
    if(props?.selectedBuilding){
      loadUnitType();
      loadUnitLocation();
    }
  },[props?.selectedBuilding])

  const loadBlocks = async () => {
    if (!selectedCompound || !selectedCompound?.id) {
      setFloors([]);
      setBuildings([]);
      setBlocks([]);
      setUnitTypes([]);
      setUnitLocations([]);
      return;
    }
    setLoading(true);

    try {
      const result = await blockAPI.blocksList({
        params: {
          compound_id: selectedCompound?.id,
        },
        signal: controller?.signal,
      });
      if (result.status) {
        let list = [];
        result?.data &&
          result?.data?.length > 0 &&
          result?.data?.map((item) => {
            list.push({
              label: item?.name,
              id: item?.id,
              images: item?.images,
            });
          });
        setBlocks(list);
        if (list?.length == 1) {
          setSelectedBlock(list[0]);
        }
      }
    } catch (err) {
   
      toast.error(err?.message);
    }
    setLoading(false);
  };
  const loadBuildings = async () => {
    if (!selectedBlock || !selectedBlock?.id) {
      setBuildings([]);
      setFloors([]);
      setUnitTypes([]);
      setUnitLocations([]);
      return;
    }
    setLoading(true);
    try {
      const result = await buildingAPI.buildingsList({
        params: {
          block_id: selectedBlock?.id,
        },
        signal: controller?.signal,
      });
      if (result.status) {
        let list = [];
        result?.data &&
          result?.data?.length > 0 &&
          result?.data?.map((item) => {
            list.push({
              label: item?.name,
              id: item?.id,
              images: item?.images,
            });
          });
        setBuildings(list);
        if (list?.length == 1) {
          setSelectedBuilding(list[0]);
        }
      }
    } catch (err) {
     
      toast.error(err?.message);
    }
    setLoading(false);
  };
  const loadFloors = async () => {
    if (!selectedBuilding || !selectedBuilding?.id) {
      setFloors([]);
      setUnitTypes([]);
      setUnitLocations([]);
      return;
    }
    props?.setSelectedBuilding(selectedBuilding)
    setLoading(true);
    try {
      const result = await floorAPI.floorsList({
        params: {
          building_id: selectedBuilding?.id,
        },
        signal: controller?.signal,
      });
      if (result.status) {
        let list = [];
        if(result?.data &&
          result?.data?.length > 0){
          result?.data?.map((item) => {
            list.push({
              label: item?.name,
              id: item?.id,
              images: item?.images,
            });
          });
        }
        setFloors(list);
        if (list?.length == 1) {
          setSelectedFloor(list[0]);
        }
      }
    } catch (err) {
     
      toast.error(err?.message);
    }
    setLoading(false);
  };
  const loadUnitType = async () => {
     
    if ((!selectedBuilding || !selectedBuilding?.id) && !props?.selectedBuilding) {
      setUnitTypes([]);
      
      return;
    }
    setLoading(true);
    try {
      const result = await UnitTypesAPI.unitTypesList({
        params: {
          building_id: selectedBuilding?selectedBuilding?.id:props?.selectedBuilding?.id,
        },
        signal: controller?.signal,
      });
      if (result.status) {
        let list = [];
        result?.data &&
          result?.data?.length > 0 &&
          result?.data?.map((item) => {
            list.push({
              label: item?.name,
              id: item?.id,
              images: item?.images,
            });
          });
        setUnitTypes(list);
        if (list?.length == 1) {
          setSelectedUnitType(list[0]);
        }
      }
    } catch (err) {
   
      toast.error(err?.message);
    }
    setLoading(false);
  };
  const loadUnitLocation = async () => {
    if ((!selectedBuilding || !selectedBuilding?.id) && !props?.selectedBuilding) {
      setUnitLocations([]);
      return;
    }
    setLoading(true);
    try {
      const result = await UnitLocationsAPI.unitLocationsList({
        signal: controller?.signal,
      });
      if (result.status) {
        let list = [];
        result?.data &&
          result?.data?.length > 0 &&
          result?.data?.map((item) => {
            list.push({
              label: item?.name,
              id: item?.id,
              images: item?.images,
            });
          });
        setUnitLocations(list);
        if (list?.length == 1) {
          setSelectedUnitLocation(list[0]);
        }
      }
    } catch (err) {
    
      toast.error(err?.message);
    }
    setLoading(false);
  };
  const handleSelectAllField=(check)=>{
     if(check){
        //  if(fl)
         setSelectedFloor(floors&&floors?.length>0?floors:[])
     }else{
      setSelectedFloor([])
     }
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        width: "100%",
        justifyContent: "center",
        alignItems: "start",
        overflowX: "hidden",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          width: "100% !important",
        }}
      >
        {!hideAutoComplete?.hideBlocks && (
          <Autocomplete
            sx={{
              ml: 1.5,
              mt: 1,
              mb: 1,
              width: "95%",
              "& .MuiFormLabel-root,& .MuiInputBase-input": {
                fontFamily: "Cairo-Medium",
              },
            }}
            options={blocks ? blocks : []}
            value={selectedBlock}
            onChange={(e, v, r) => {
              if (!v) {
                setSelectedBlock(null);
                setSelectedBuilding(null);
                setSelectedFloor(null);
                setSelectedUnitType(null);
                setSelectedUnitLocation(null);

                props?.setSelectedEntity({
                  ...props?.selectedEntity,
                  linkedToEntity: {
                    type: "compounds",
                    entity: selectedCompound,
                    label: "المجمع",
                  },
                });
                //props?.setSelectedImage(selectedCompound?.images?.filter(image=>image?.image_type_id==4)[0])
              } else {
                setSelectedBlock(v);
                setSelectedBuilding(null);
                setSelectedFloor(null);
                setSelectedUnitType(null);
                setSelectedUnitLocation(null);

                props?.setSelectedEntity({
                  ...props?.selectedEntity,
                  linkedToEntity: {
                    type: "blocks",
                    entity: v,
                    label: "القاطع",
                  },
                });
                //props?.setSelectedImage(v?.images?.filter(image=>image?.image_type_id==4)[0])
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder={"اختر قاطع"}
                variant="standard"
                sx={{ fontFamily: "Cairo-Medium" }}
              />
            )}
          />
        )}
        {!hideAutoComplete?.hideBuildings && (
          <Autocomplete
            sx={{
              ml: 1.5,
              mt: 1,
              mb: 1,
              width: "95%",
              "& .MuiFormLabel-root,& .MuiInputBase-input": {
                fontFamily: "Cairo-Medium",
              },
            }}
            options={buildings ? buildings : []}
            value={selectedBuilding}
            onChange={(e, v, r) => {
              if (!v) {
                setSelectedBuilding(null);
                setSelectedFloor(null);
                setSelectedUnitType(null);
                setSelectedUnitLocation(null);
                props?.setSelectedEntity({
                  ...props?.selectedEntity,
                  linkedToEntity: {
                    type: "blocks",
                    entity: selectedBlock,
                    label: "القاطع",
                  },
                });
                //props?.setSelectedImage(selectedBlock?.images?.filter(image=>image?.image_type_id==4)[0])
              } else {
                setSelectedBuilding(v);
                setSelectedFloor(null);
                setSelectedUnitType(null);
                setSelectedUnitLocation(null);

                props?.setSelectedEntity({
                  ...props?.selectedEntity,
                  linkedToEntity: {
                    type: "buildings",
                    entity: v,
                    label: "البناية",
                  },
                });
                //props?.setSelectedImage(v?.images?.filter(image=>image?.image_type_id==4)[0])
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder={"اختر البناية"}
                variant="standard"
                sx={{ fontFamily: "Cairo-Medium" }}
              />
            )}
          />
        )}
        {!hideAutoComplete?.hideFloors && (
          <>
            {props?.selectedImage?.image_type_id == 5 ? (
              <Autocomplete
                sx={{
                  ml: 1.5,
                  mt: 1,
                  mb: 1,
                  width: "95%",
                  "& .MuiFormLabel-root,& .MuiInputBase-input": {
                    fontFamily: "Cairo-Medium",
                  },
                }}
                id="checkboxes-tags-demo"
                multiple={true}
                disableCloseOnSelect
                options={floors ? floors : []}
                // getOptionLabel={(option) => option.name}
                value={Array.isArray(selectedFloor) ? selectedFloor : []}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.label}
                  </li>
                )}
                disableClearable
                onChange={(e, v, r) => {
                  if (!v) {
                    setSelectedFloor(null);
                    setSelectedUnitType(null);
                    setSelectedUnitLocation(null);
                    props?.setSelectedEntity({
                      ...props?.selectedEntity,
                      linkedToEntity: {
                        type: "buildings",
                        entity: selectedBuilding,
                        label: "البناية",
                      },
                    });
                    //props?.setSelectedImage(selectedBuilding?.images?.filter(image=>image?.image_type_id==4)[0])
                  } else {
                    setSelectedFloor(v);
                    setSelectedUnitType(null);
                    setSelectedUnitLocation(null);
                    props?.setSelectedEntity({
                      ...props?.selectedEntity,
                      linkedToEntity: {
                        type: "floors",
                        entity: v,
                        label: "الطابق",
                      },
                    });
                    //props?.setSelectedImage(v?.images?.filter(image=>image?.image_type_id==4)[0])
                  }
                }}
                renderInput={(params) => (
                  <Box
                    sx={{
                      display:'block',
                      // justifyContent:'stretch',
                      // alignItems:'center',
                      position:'relative',
                      width:'100%',
                      '& .MuiFormControlLabel-root':{
                        position:'absolute',
                        right:'14px',
                        top:'-4px',
                      },
                      '& .MuiInputBase-root':{
                        paddingRight:'80px !important'
                      },
                      '& .MuiTypography-root':{
                        fontFamily:'Cairo !important' 
                      }
                    }}
                  >
                    <TextField
                      {...params}
                      placeholder={"اختر الطوابق"}
                      variant="standard"
                      sx={{ fontFamily: "Cairo-Medium",width:'100%' }}
                    />
                    <FormControlLabel control={<Checkbox checked={selectAllFloor}
                          indeterminate={selectedFloor?.length>0&&selectedFloor?.length<floors?.length}
                    onChange={(e)=>{
                       handleSelectAllField(e?.target?.checked)
                       setSelectAllFloor(e?.target?.checked)
                    }} 
                      sx={{
                        fontFamily:'Cairo',
                        // position:'absolute',
                        // flex:0.2
                      }}
                    />} label="اختر الكل" />
                  </Box>
                )}
              />
            ) : (
              <Autocomplete
                sx={{
                  ml: 1.5,
                  mt: 1,
                  mb: 1,
                  width: "95%",
                  "& .MuiFormLabel-root,& .MuiInputBase-input": {
                    fontFamily: "Cairo-Medium",
                  },
                }}
                options={floors ? floors : []}
                value={selectedFloor}
                onChange={(e, v, r) => {
                  if (!v) {
                    setSelectedFloor(null);
                    setSelectedUnitType(null);
                    setSelectedUnitLocation(null);
                    props?.setSelectedEntity({
                      ...props?.selectedEntity,
                      linkedToEntity: {
                        type: "buildings",
                        entity: selectedBuilding,
                        label: "البناية",
                      },
                    });
                    //props?.setSelectedImage(selectedBuilding?.images?.filter(image=>image?.image_type_id==4)[0])
                  } else {
                    setSelectedFloor(v);
                    setSelectedUnitType(null);
                    setSelectedUnitLocation(null);
                    props?.setSelectedEntity({
                      ...props?.selectedEntity,
                      linkedToEntity: {
                        type: "floors",
                        entity: v,
                        label: "الطابق",
                      },
                    });
                    //props?.setSelectedImage(v?.images?.filter(image=>image?.image_type_id==4)[0])
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder={"اختر الطابق"}
                    variant="standard"
                    sx={{ fontFamily: "Cairo-Medium" }}
                  />
                )}
              />
            )}
          </>
        )}

        <Autocomplete
          sx={{
            ml: 1.5,
            mt: 1,
            mb: 1,
            width: "95%",
            "& .MuiFormLabel-root,& .MuiInputBase-input": {
              fontFamily: "Cairo-Medium",
            },
          }}
          options={unitTypes ? unitTypes : []}
          value={selectedUnitType}
          onChange={(e, v, r) => {
            if (!v) {
              setSelectedUnitType(null);
              setSelectedUnitLocation(null);
              props?.setSelectedEntity({
                ...props?.selectedEntity,
                linkedToEntity: {
                  type: "floors",
                  entity: selectedFloor,
                  label: "الطابق",
                },
              });
              //props?.setSelectedImage(selectedFloor?.images?.filter(image=>image?.image_type_id==4)[0])
            } else {
              setSelectedUnitType(v);

              setSelectedUnitLocation(null);
              props?.setSelectedEntity({
                ...props?.selectedEntity,
                linkedToEntity: {
                  ...props?.selectedEntity?.linkedToEntity,
                  type: "unit_types",
                  entity: v,
                  label: "نوع الوحدة",
                },
              });
              //props?.setSelectedImage(v?.images?.filter(image=>image?.image_type_id==4)[0])
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder={"اختر نوع الوحده"}
              variant="standard"
              sx={{ fontFamily: "Cairo-Medium" }}
            />
          )}
        />
        {selectedUnitType?.id && (
          <Autocomplete
            sx={{
              ml: 1.5,
              mt: 1,
              mb: 1,
              width: "95%",
              "& .MuiFormLabel-root,& .MuiInputBase-input": {
                fontFamily: "Cairo-Medium",
              },
            }}
            options={unitLocations ? unitLocations : []}
            value={selectedUnitLocation}
            onChange={(e, v, r) => {
              if (!v) {
                setSelectedUnitLocation(null);
                props?.setSelectedEntity({
                  ...props?.selectedEntity,
                  linkedToEntity: {
                    type: "floors",
                    entity: selectedFloor,
                    label: "الطابق",
                  },
                });
                //props?.setSelectedImage(selectedFloor?.images?.filter(image=>image?.image_type_id==4)[0])
              } else {
                setSelectedUnitLocation(v);

                props?.setSelectedEntity({
                  ...props?.selectedEntity,
                  linkedToEntity: {
                    ...props?.selectedEntity?.linkedToEntity,
                    unitLocation: {
                      type: "unit_locations",
                      entity: v,
                      label: "موقع الوحدة",
                    },
                    floor: selectedFloor,
                  },
                });
                //props?.setSelectedImage(v?.images?.filter(image=>image?.image_type_id==4)[0])
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder={"اختر موقع الوحده"}
                variant="standard"
                sx={{ fontFamily: "Cairo-Medium" }}
              />
            )}
          />
        )}

        <Typography sx={{ fontFamily: "Cairo-Medium" }}>
          {props?.selectedEntity?.linkedToEntity?.type == "unit_types"
            ? "تم اختيار " +
              props?.selectedEntity?.linkedToEntity?.label +
              " : " +
              props?.selectedEntity?.linkedToEntity?.entity?.label +
              " في الموقع : " +
              (props?.selectedEntity?.linkedToEntity?.unitLocation?.entity
                ?.label
                ? props?.selectedEntity?.linkedToEntity?.unitLocation?.entity
                    ?.label
                : "غير محدد")
            : "تم اختيار " +
              props?.selectedEntity?.linkedToEntity?.label +
              " : " +
              (props?.selectedEntity?.linkedToEntity?.entity?.label
                ? props?.selectedEntity?.linkedToEntity?.entity?.label
                : "غير محدد")}
        </Typography>
      </Box>
    </Box>
  );
}
