import callAPI from '../ApiConfig'
import store from '../../../app/store'

const blocks = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"blocks",
        method:"get",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    
    })
    
}

const blocksList = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"blocks_list",
        method:"get",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    
    })
    
}
const block = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"block",
        method:"get",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    
    })
    
}

const blockAPI={
    blocks:blocks,
    blocksList:blocksList

}
export default blockAPI