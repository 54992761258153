import { Box, Divider, IconButton, List, ListItem, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from "react-i18next";
import CustomTableHeader from '../CustomTable/CustomTableHeader';
import * as FaIcons from 'react-icons/fa'
import * as CgIcons from 'react-icons/cg'
import { useSelector } from 'react-redux'
import helpers from '../../../assets/js/helper';
import InvoicePrintPopup from '../../Masters/Invoices/InvoicePrintPopup';
import PaymentPrintPopup from '../../Masters/Invoices/PaymentPrintPopup';
import AddFormDailog from '../../Masters/Invoices/AddFormDailog'
import RolesOfUserAPI from '../../../Global/Network/RoleUser';


function FinancialDetailsTab(props) {
  const [t] = useTranslation("common");
  const screenwidth = useSelector(state => state.settingsData.screenwidth)
  const [openInvoicePrint, setOpenInvoicePrint] = useState(false)
  const [invoice, setInvoice] = useState(null)
  const [openPaymentPrint, setOpenPaymentPrint] = useState(false)
  const [payment, setPayment] = useState(null)
  const [showAddPaymentForm, setShowAddPaymentForm] = useState(false)
  const [loading, setLoading] = React.useState(false)
  const [userRolesHere, setUserRolesHere] = useState([
    {
      view: true,
      id: 170
    },
    {
      add: false,
      id: 171,
    },
    {
      update: false,
      id: 172,
    },
    {
      delete: false,
      id: 173,
    }, {
      export: false,
      id: 174
    }, {
      print: false,
      id: 175
    },
    {
      addContract: false,
      id: 177
    },
  ])

  const getUnitAddress = (unit) => {
    console.log(unit);
    let text = " عمارة" + unit?.building?.name + " "
    text += " شقة" + unit?.name + " "
    text += " طابق" + unit?.floor?.name + " "
    return text
  }

  const getInvoiceClicked = (obj) => {
    setInvoice(obj)
    setShowAddPaymentForm(true)
  }
  React.useEffect(() => {
    const abortController = new AbortController()

    getUserRoles()
    return () => {
      abortController.abort()
    };
  }, [props?.row]);

  const getUserRoles = async () => {
    // setLoading(true)
    console.log('saldkjaskd', 2);
    try {
      let data = await RolesOfUserAPI.GetRolesOfUser();
      if (data && data?.status) {
        if (data?.data && data?.data?.length > 0) {
          let arr = [];

          let checkHasRole = data?.data?.includes(userRolesHere[0]?.id)
          if (checkHasRole) {
            arr.push(
              {
                view: true,
                id: userRolesHere[0]?.id,
              }
            )
          } else {
            arr.push(
              {
                view: false,
                id: userRolesHere[0]?.id,
              }
            )
          }
          console.log('sassssss', userRolesHere[1]?.id);
          checkHasRole = data?.data?.includes(userRolesHere[1]?.id)
          if (checkHasRole) {
            arr.push(
              {
                add: true,
                id: userRolesHere[1]?.id,
              }
            )
          } else {
            arr.push(
              {
                add: false,
                id: userRolesHere[1]?.id,
              }
            )
          }
          checkHasRole = data?.data?.includes(userRolesHere[2]?.id)
          if (checkHasRole) {
            arr.push(
              {
                update: true,
                id: userRolesHere[2]?.id,
              }
            )
          } else {
            arr.push(
              {
                update: false,
                id: userRolesHere[2]?.id,
              }
            )
          }
          checkHasRole = data?.data?.includes(userRolesHere[3]?.id)
          if (checkHasRole) {
            arr.push(
              {
                delete: true,
                id: userRolesHere[3]?.id,
              }
            )
          } else {
            arr.push(
              {
                delete: false,
                id: userRolesHere[3]?.id,
              }
            )
          }
          checkHasRole = data?.data?.includes(userRolesHere[4]?.id)
          if (checkHasRole) {
            arr.push(
              {
                export: true,
                id: userRolesHere[4]?.id,
              }
            )
          } else {
            arr.push(
              {
                export: false,
                id: userRolesHere[4]?.id,
              }
            )
          }

          checkHasRole = data?.data?.includes(userRolesHere[5]?.id)
          if (checkHasRole) {
            arr.push(
              {
                print: true,
                id: userRolesHere[5]?.id,
              }
            )
          } else {
            arr.push(
              {
                print: false,
                id: userRolesHere[5]?.id,
              }
            )
          }

          checkHasRole = data?.data?.includes(userRolesHere[6]?.id)
          if (checkHasRole) {
            arr.push(
              {
                addContract: true,
                id: userRolesHere[6]?.id,
              }
            )
          } else {
            arr.push(
              {
                addContract: false,
                id: userRolesHere[6]?.id,
              }
            )
          }

          setUserRolesHere(arr)
        }
      }
      setLoading(false)
    } catch (err) {
      setLoading(false)
      console.log(err?.message);

    }
  }

  const getBalance = (total, paid, currency) => {
    let balance = 0
    try {
      balance = total ? parseFloat(total) : 0
      balance -= paid ? parseFloat(paid) : 0
    } catch (error) {
      
    }

    return helpers.getFormatedPrice(balance,currency)
  }

  return (
    <Box
      sx={{
        flex: 1
      }}
    >
      <InvoicePrintPopup
        invoice={invoice}
        open={openInvoicePrint}
        setOpen={setOpenInvoicePrint}
      />
      <PaymentPrintPopup
        payment={payment}
        open={openPaymentPrint}
        setOpen={setOpenPaymentPrint}
      />

      {
        showAddPaymentForm ?
          <AddFormDailog object={invoice} addForm={showAddPaymentForm} setAddForm={setShowAddPaymentForm} loadData={props?.loadDataOfUnit} /> : null
      }

      {!props?.notHasHeader ?
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            padding: '10px 0',
          }}
        >
          <Box
            sx={{
              flex: 1
            }}
          >

          </Box>

          <Box
            sx={{
              flex: 1,
              justifyContent: 'center',
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column'
            }}
          >
            <Box
              sx={{
                justifyContent: 'center',
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column'
              }}
            >
              <Typography
                sx={{
                  color: '#994D1F',
                  fontSize: '16px',
                  fontFamily: 'Cairo',
                  fontWeight: 'bold',
                }}
              >
                {t('CUSTOMER.FinancialDetails')}
              </Typography>
              <Divider sx={{ width: '100%', opacity: 1, height: '2px', color: '#994D1E' }} />
            </Box>
          </Box>
          <Box
            sx={{
              flex: 1
            }}
          >

          </Box>
        </Box> : null}

      <Box className="container-fluid">
        <Box className="row my-2 d-flex justify-content-between">
          <Box className={props?.fromViewUnitPage ? 'col-12 my-2' : 'col-12 col-md-6 col-lg-6 col-xlg-4 my-2'}>
            <CustomTableHeader columns={[{
              title: t('CUSTOMER.invoices')
            }]} />
            <List sx={{ height: '180px', width: '100%', overflowY: 'scroll' }} className="CustomerFinancialSummaryTab">
              {
                props?.row?.invoices?.length > 0 ? props?.row?.invoices?.map((invoice) => <ListItem sx={{ width: '100%' }}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-evenly',
                      alignItems: 'start',
                      padding: '5px',
                      marginLeft: '5px',
                      width: '100%',
                      "&:hover": {
                        boxShadow: '0 0 5px -2px #000000A0',
                        borderRadius: '10px'
                      }
                    }}


                  >
                    <FaIcons.FaFileAlt style={{ flex: 1, color: '#349CDD', fontSize: '22px' }} className="mx-1" />
                    <Box sx={{
                      display: 'flex',
                      justifyContent: 'start',
                      alignItems: 'start',
                      flexDirection: 'column',
                      flex: userRolesHere[6]?.addContract ? 3 : 4
                    }}
                      className="mx-1"
                    >
                      <span
                        onClick={() => {
                          if (userRolesHere[5]?.print) {
                            setOpenInvoicePrint(true)
                            setInvoice(invoice)
                          }
                        }}
                        style={{
                          textAlign: "right", textDecoration: "underline", font: "normal normal bold 14px/26px Cairo", letterSpacing: "0px", color: "#349CDD", opacity: "1", cursor: 'pointer',
                        }}>{"الفاتورة رقم (" + invoice?.id + ") لدفع قسط للوحدة السكنية"}</span>
                      <span style={{
                        textAlign: "right", font: "normal normal 300 12px/22px Cairo", letterSpacing: "0px", color: "#7D8185", opacity: "1"
                      }}>{getUnitAddress(invoice?.unit)}</span>
                      <span style={{
                        textAlign: "right", font: "normal normal 300 12px/22px Cairo", letterSpacing: "0px", color: "#7D8185", opacity: "1"
                      }}>{" مبلغ الفاتورة " + helpers.getFormatedPrice(invoice?.total_price, invoice?.unit?.iqd_usd)}</span>
                    </Box>
                    {
                      userRolesHere[6]?.addContract ?
                        <IconButton
                          onClick={() => {
                            getInvoiceClicked(invoice)
                          }}
                        >
                          <CgIcons.CgMenuRight
                            style={{ flex: 1, color: '#1E6A99', fontSize: '22px', cursor: 'pointer', }}
                            className="mx-1"

                          />
                        </IconButton>
                        : null}

                  </Box>
                </ListItem>) :
                  <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'justify',
                    width: '100%',
                    height: '100%',
                    fontFamily: 'Cairo'
                  }}>
                    {t('GLOBAL.noData')}
                  </Box>
              }
            </List>
          </Box>

          <Box className={props?.fromViewUnitPage ? 'col-12 my-2' : 'col-12 col-md-6 col-lg-6 col-xlg-4 my-2'}>
            <CustomTableHeader columns={[{
              title: t('CUSTOMER.receipt')
            }]} />
            <List sx={{ height: '180px', width: '100%', overflowY: 'scroll' }} className="CustomerFinancialSummaryTab">
              {props?.row?.payments?.length > 0 ? props?.row?.payments?.map((payment) => <ListItem sx={{ width: '100%' }}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-evenly',
                    alignItems: 'start',
                    padding: '5px',
                    marginLeft: '5px',
                    width: '100%',
                    "&:hover": {
                      cursor: 'pointer',
                      // borderWidth:'2px',
                      // borderStyle:'solid',
                      // borderColor:"#349CDD"
                      boxShadow: '0 0 5px -2px #000000A0',
                      borderRadius: '10px'

                    }
                  }}
                  onClick={() => {
                    setOpenPaymentPrint(true)
                    setPayment(payment)
                  }}

                >
                  <FaIcons.FaFileAlt style={{ flex: 1, color: '#349CDD', fontSize: '22px' }} className="mx-1" />
                  <Box sx={{
                    display: 'flex',
                    justifyContent: 'start',
                    alignItems: 'start',
                    flexDirection: 'column',
                    flex: 4
                  }}
                    className="mx-1"
                  >
                    <span style={{
                      textAlign: "right", textDecoration: "underline", font: "normal normal bold 14px/26px Cairo", letterSpacing: "0px", color: "#349CDD", opacity: "1"
                    }}>{"سند قبض رقم (" + payment?.id + ") دفع القسط للوحدة السكنية"}</span>
                    <span style={{
                      textAlign: "right", font: "normal normal 300 12px/22px Cairo", letterSpacing: "0px", color: "#7D8185", opacity: "1"
                    }}>{getUnitAddress(payment?.unit)}</span>
                    <span style={{
                      textAlign: "right", font: "normal normal 300 12px/22px Cairo", letterSpacing: "0px", color: "#7D8185", opacity: "1"
                    }}>{" المبلغ المدفوع " + helpers.getFormatedPrice(payment?.paid_amount, payment?.unit?.iqd_usd)}</span>
                  </Box>

                  {/* <CgIcons.CgMenuRight style={{ color: '#1E6A99', fontSize: '22px' }} className="mx-1" /> */}

                </Box>
              </ListItem>) :
                <Box sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  textAlign: 'justify',
                  width: '100%',
                  height: '100%',
                  fontFamily: 'Cairo'
                }}>
                  {t('GLOBAL.noData')}
                </Box>}
            </List>
          </Box>
          {(!props?.fromViewUnitPage || (props?.fromViewUnitPage && !props?.row?.iqd_usd)) ?
            <Box className={props?.fromViewUnitPage ? 'col-12 my-2' : 'col-12 col-md-6 col-lg-6 col-xlg-4 my-2'}>
              <CustomTableHeader columns={[{
                title: t('CUSTOMER.financial_summary_in_iqd')
              }]} />
              <Box sx={{
                display: 'flex',
                justifyContent: 'start',
                alignItems: 'start',
                flexDirection: 'column',
                margin: '10px 0',

              }}

              >
                <p>
                  <span style={{
                    textAlign: "right", font: "normal normal  12px/22px Cairo-Bold", letterSpacing: "0px", color: "#7D8185", opacity: "1", margin: '0 10px'
                  }}>{t('CUSTOMER.total_invoices_in_iqd') + ':'}</span>
                  <span style={{
                    textAlign: "right", font: "normal normal  12px/22px Cairo", letterSpacing: "0px", color: "#FF0000", opacity: "1"
                  }}>{helpers.getFormatedPrice(props?.row?.discounted_price_sum_iqd ? props?.row?.discounted_price_sum_iqd : 0, 0)}</span>
                </p>
                <p>
                  <span style={{
                    textAlign: "left", font: "normal normal  12px/22px Cairo-Bold", letterSpacing: "0.36px", color: "#7D8185", opacity: "1", margin: '0 10px'
                  }}>{t('CUSTOMER.due_date_invoices_in_iqd') + ':'}</span>
                  <span style={{
                    textAlign: "right", font: "normal normal  12px/22px Cairo", letterSpacing: "0px", color: "#1e6a99", opacity: "1"
                  }}>{helpers.getFormatedPrice(props?.row?.due_date_sum_iqd ? props?.row?.due_date_sum_iqd : 0, 0)}</span>
                </p>
                <p
                  style={{
                    width: "258px", height: "2px", background: "transparent linear-gradient(180deg, #FFFFFF 0%, #7D8185 48%, #FFFFFF 100%) 0% 0% no-repeat padding-box", opacity: "1",
                    background: "linear-gradient(90deg, rgba(218,218,218,0.29485297536983546) 0%, rgba(184,184,184,1) 35%, rgba(184,184,184,1) 62%, rgba(189,189,189,0.25843840954350494) 100%, rgba(218,218,218,1) 100%, rgba(166,174,181,1) 100%, rgba(0,212,255,1) 100%)",
                    marginRight: '10px'
                  }}
                ></p>
                <p style={{ marginRight: '10px' }}>
                  <span style={{
                    textAlign: "right", font: "normal normal  12px/22px Cairo-Bold", letterSpacing: "1px", color: "#7D8185", opacity: "1", marginLeft: '10px'
                  }}>{t('CUSTOMER.total_payments_in_iqd') + ':'}</span>
                  <span style={{
                    textAlign: "right", font: "normal normal  12px/22px Cairo", letterSpacing: "0px", color: "#7D8185", opacity: "1"
                  }}>{helpers.getFormatedPrice(props?.row?.paid_amount_sum_iqd ? props?.row?.paid_amount_sum_iqd : 0, 0)}</span>
                </p>
                <p style={{ marginRight: '10px' }}>
                  <span style={{
                    textAlign: "right", font: "normal normal  12px/22px Cairo-Bold", letterSpacing: "1px", color: "#7D8185", opacity: "1", marginLeft: '10px'
                  }}>{t('CUSTOMER.balance_in_iqd') + ':'}</span>
                  <span style={{
                    textAlign: "right", font: "normal normal  12px/22px Cairo", letterSpacing: "0px", color: "#7D8185", opacity: "1"
                  }}>{getBalance(props?.row?.discounted_price_sum_iqd, props?.row?.paid_amount_sum_iqd, 0)}</span>
                </p>

              </Box>
            </Box>
            : null}
          {(!props?.fromViewUnitPage || (props?.fromViewUnitPage && !props?.iqd_usd)) ?
            <Box className={props?.fromViewUnitPage ? 'col-12 my-2' : `col-12 col-md-6 col-lg-6 col-xlg-4 my-2`}>
              <CustomTableHeader columns={[{
                title: t('CUSTOMER.financial_summary_in_usd')
              }]} />
              <Box sx={{
                display: 'flex',
                justifyContent: 'start',
                alignItems: 'start',
                flexDirection: 'column',
                margin: '10px 0'
              }}

              >
                <p>
                  <span style={{
                    textAlign: "right", font: "normal normal  12px/22px Cairo-Bold", letterSpacing: "0px", color: "#7D8185", opacity: "1", margin: '0 10px'
                  }}>{t('CUSTOMER.total_invoices_in_usd') + ':'}</span>
                  <span style={{
                    textAlign: "right", font: "normal normal  12px/22px Cairo", letterSpacing: "0px", color: "#FF0000", opacity: "1"
                  }}>{helpers.getFormatedPrice(props?.row?.discounted_price_sum_usd ? props?.row?.discounted_price_sum_usd : 0, 1)}</span>
                </p>
                <p>
                  <span style={{
                    textAlign: "left", font: "normal normal  12px/22px Cairo-Bold", letterSpacing: "0.36px", color: "#7D8185", opacity: "1", margin: '0 10px'
                  }}>{t('CUSTOMER.due_date_invoices_in_usd') + ':'}</span>
                  <span style={{
                    textAlign: "right", font: "normal normal  12px/22px Cairo", letterSpacing: "0px", color: "#1e6a99", opacity: "1"
                  }}>{helpers.getFormatedPrice(props?.row?.due_date_sum_usd ? props?.row?.due_date_sum_usd : 0, 1)}</span>
                </p>
                <p
                  style={{
                    width: "258px", height: "2px", background: "transparent linear-gradient(180deg, #FFFFFF 0%, #7D8185 48%, #FFFFFF 100%) 0% 0% no-repeat padding-box", opacity: "1",
                    background: "linear-gradient(90deg, rgba(218,218,218,0.29485297536983546) 0%, rgba(184,184,184,1) 35%, rgba(184,184,184,1) 62%, rgba(189,189,189,0.25843840954350494) 100%, rgba(218,218,218,1) 100%, rgba(166,174,181,1) 100%, rgba(0,212,255,1) 100%)",
                    marginRight: '10px'

                  }}
                ></p>
                <p style={{ marginRight: '10px' }}>
                  <span style={{
                    textAlign: "right", font: "normal normal  12px/22px Cairo-Bold", letterSpacing: "1px", color: "#7D8185", opacity: "1", marginLeft: '10px'
                  }}>{t('CUSTOMER.total_payments_in_usd') + ':'}</span>
                  <span style={{
                    textAlign: "right", font: "normal normal  12px/22px Cairo", letterSpacing: "0px", color: "#7D8185", opacity: "1"
                  }}>{helpers.getFormatedPrice(props?.row?.paid_amount_sum_usd ? props?.row?.paid_amount_sum_usd : 0, 1)}</span>
                </p>
                <p style={{ marginRight: '10px' }}>
                  <span style={{
                    textAlign: "right", font: "normal normal  12px/22px Cairo-Bold", letterSpacing: "1px", color: "#7D8185", opacity: "1", marginLeft: '10px'
                  }}>{t('CUSTOMER.balance_in_usd') + ':'}</span>
                  <span style={{
                    textAlign: "right", font: "normal normal  12px/22px Cairo", letterSpacing: "0px", color: "#7D8185", opacity: "1"
                  }}>{getBalance(props?.row?.discounted_price_sum_usd, props?.row?.paid_amount_sum_usd, 1)}</span>
                </p>

              </Box>
            </Box>
            : null}
        </Box>
      </Box>
    </Box>
  )
}

export default FinancialDetailsTab