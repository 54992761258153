import React from 'react'
import './Preloading.css'
import Milestonelogo from '../../assets/img/Milestonelogo.png'
import s1 from '../../assets/img/s1.png'
import s2 from '../../assets/img/s2.png'
import s3 from '../../assets/img/s3.png'


export default function Preloading() {
  return (
    <div className='containerOfPreloading'>
      <section>
        <div className='sk-cube-grid'>
          <div className='sk-cube sk-cube-1'
            style={{
              backgroundImage:`url(${s1})`,
            }}
          ></div>
          <div className='sk-cube sk-cube-2'
            style={{
              // backgroundImage:`url(${Milestonelogo})`,
            }}
          ></div>
          <div className='sk-cube sk-cube-3'
            style={{
              backgroundImage:`url(${s2})`,
            }}
          ></div>
          <div className='sk-cube sk-cube-4'
            style={{
              backgroundImage:`url(${s3})`,
            }}
          ></div>
          <div className='sk-cube sk-cube-5'
            style={{
              backgroundImage:`url(${s3})`,
            }}
          ></div>
          <div className='sk-cube sk-cube-6'
            style={{
              backgroundImage:`url(${s3})`,
            }}
          ></div>
          <div className='sk-cube sk-cube-7'
            style={{
              backgroundImage:`url(${s3})`,
            }}
          ></div>
          <div className='sk-cube sk-cube-8'
            style={{
              backgroundImage:`url(${s3})`,
            }}
          ></div>
          <div className='sk-cube sk-cube-9'
            style={{
              backgroundImage:`url(${s3})`,
            }}
          ></div>
        </div>
      </section>
    </div>
  )
}
