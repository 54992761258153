const reducer = (state, action) => {

    switch (action.type) {
    
        default:
            break;
    }
    return state
}

export default reducer