import callAPI from '../ApiConfig'
import store from '../../../app/store'

const contactMethod = async (props) => {
    const storeState = store.getState();
    return await callAPI({
        route:"contact_methods",
        method:"get",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
    
}
const contactMethodList = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"contact_methods_list",
        method:"get",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
    
}

const addContactMethod = async (props) => {
    const storeState = store.getState();
    return await callAPI({
        route:"contact_methods",
        method:"post",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        },
        data:props?.data,
    })
    
}
const updateContactMethod = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"contact_methods/"+props?.data?.id,
        method:"put",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        },
        data:props?.data
    })
    
}
const deleteContactMethod = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"contact_methods/"+props?.data?.id,
        method:"delete",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        },
    })
    
}

const ContactMethodAPI={
    ContactMethod:contactMethod,
    ContactMethodList:contactMethodList,
    addContactMethod:addContactMethod,
    updateContactMethod:updateContactMethod,
    deleteContactMethod:deleteContactMethod
}
export default ContactMethodAPI