import React, { useState } from 'react';
import Box from '@mui/material/Box';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import { Collapse, IconButton, List, ListItemButton, Typography } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { StarBorder } from '@mui/icons-material';
import CustomButton from '../../General/CustomComponents/CustomButton';
import { BsFillCreditCardFill } from 'react-icons/bs'
import { MdPhoneInTalk } from 'react-icons/md'
import { IoDocumentTextSharp } from 'react-icons/io5'
import { HiUser } from 'react-icons/hi'
import { TiUser } from 'react-icons/ti'
import Person2Icon from '@mui/icons-material/Person2';


const primaryTextSX = {
    color: '#212121',
    fontFamily: 'Cairo',
    fontSize: '13px'
}

const secondaryTextSX = {
    color: '#52575D',
    fontFamily: 'Cairo-ExtraLight',
    fontSize: '12px'
}

const buttonSX = {
    width: '164px',
    fontFamily: 'Cairo',
    fontSize: '13px',
    backgroundColor: '#FFFFFF66',
    color: '#FFFFFF',
    marginLeft: '5px',
    border: 'none'

}
const buttonHoverStyle = {
    backgroundColor: '#FFFFFF',
    color: '#994D1E',
}

export default function DataGridHeader(props) {
    console.log(props);
    return (
        <Box sx={{
            display: 'flex',
            backgroundColor: '#E3E9EF',
            justifyContent: 'space-between',
            alignItems: 'stretch',
            flexDirection: 'row',
            paddingRight: '86px',
            paddingLeft: '25px',
            marginBottom: '12px',
            paddingTop: '7px',
            paddingBottom: '7px'
        }}>
            {props?.columns?.length > 0 && props?.columns?.map(((column, index) =>
                <Box
                    key={'data_grid_header' + index}
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                        flex: 1
                    }}
                >
                    <Typography
                        variant="subtitle2"
                        sx={primaryTextSX}
                    >
                        {column?.title}
                    </Typography>

                </Box>
            ))}
            

        </Box>
    );
}
