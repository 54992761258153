import React, { useEffect, useRef } from 'react'
import { Box, Divider } from '@mui/material'
import InvoiceRow from './Components/InvoiceRow';
import { useReactToPrint } from 'react-to-print';
import helpers from '../../../assets/js/helper';
import logo from '../../../assets/img/milestone_logo.png'
import swal from 'sweetalert';
export default function PaymentPrint({ payment, print, setPrint }) {

  const printRef = useRef();
  const handlePrint = useReactToPrint({

    content: () => {
      const tableStat = printRef.current.cloneNode(true);
      const PrintElem = document.createElement('div');
      const header =
        `<img src="${logo}" alt="" class="watermark"/>`
        +
        `<div class="pageFooter">I'm The Footer</div>` +
        `<div class="page-header">I'm The Header</div>`;
      PrintElem.innerHTML = header;
      PrintElem.appendChild(tableStat);
      return PrintElem;
    },
  });
  useEffect(() => {
    if (print) {
      handlePrint()
      setPrint(false)
    }

    return () => {

    }
  }, [print])



  const getName = () => {
    let customer = payment?.invoice?.order?.customer
    let title = "السيد"
    if (customer?.user?.gender) {
      title = "السيدة"
      if (customer?.marital_status_id == 2) {
        title = "الانسة"
      }
    }
    return title + " " + (customer?.user?.full_name ? customer?.user?.full_name : "")
  }
  const getAddress = (lineNumber) => {
    let customerAddress = payment?.invoice?.order?.customer?.addresses?.length > 0 ? payment?.invoice?.order?.customer?.addresses[0] : null
    if (!customerAddress) {
      return
    }
    if (lineNumber == 1) {
      return customerAddress?.name ? " " + customerAddress?.name : " "
    }
    else if (lineNumber == 2) {
      return (customerAddress?.address ? " " + customerAddress?.address : " ")
    }
    else if (lineNumber == 3) {
      return (customerAddress?.first_phone ? " " + customerAddress?.first_phone : " ")
    }

    return ""

  }

  const getPaymentPriceFormated = () => {
    let paymentPrice = payment?.paid_amount
    let unitCurrencyCode = payment?.invoice?.order?.unit?.iqd_usd == 0 ? "IQD" : "USD"

    return "(" + paymentPrice?.format(payment?.invoice?.order?.unit?.iqd_usd == 1 ? 2 : 0, 3, ',', '.') + ") " + helpers?.tafqeet(paymentPrice, unitCurrencyCode)
  }

  const getNoteText = () => {
    let text = "سند قبض صادرة وفق الفاتورة المبينة أعلاه  "
    console.log("payment?.invoice?.payment_type_detail",payment?.invoice?.payment_type_detail);
    if (payment?.invoice?.payment_type_detail?.days_duration_from_contract == 0) {
      text += "عن الدفعة الأولى   "
    }
    text += "وحسب طريقة الدفع المبينة في العقد المذكور اعلاه."
    return text
  }
  return (

    <Box sx={{
      display: 'flex',
      flexDirection: 'column',

      width: '100%',
      justifyContent: 'center',
      alignItems: 'stretch',
      overflowX: 'hidden',

    }}
      className=" pt-3 mt-1"
    >
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          height: 'calc(100vh - 100px)',
          backgroundColor: "#FFFFFF",
          justifyContent: 'center',
          alignItems: 'center',
          overflow: 'scroll'
        }}
      >
        <Box
          ref={printRef}
          style={{
            backgroundColor: "#FFFFFF",
            width: '100%',
            padding: '20px',
            direction: 'rtl',
            height: '100%'
            // height: '842px',
          }}>
          <Box
            sx={{
              display: 'flex',
            }}
          >
            <InvoiceRow
              sx={{
                flex: 1
              }}
              title={"رقم السند:"}

              titleSX={{
                flex: 1,
                fontSize: '16px',
                fontWight: 800
              }}
              valueSX={{
                flex: 1,
                fontSize: '16px',
                fontWight: 400
              }}
              value={payment?.id + ""}
              paddingRight={"20px"}

            />
            <InvoiceRow
              sx={{
                flex: 1
              }}
              title={"تاريخ السند:"}
              valueSX={{
                flex: 1,
                fontSize: '16px',
                fontWight: 400
              }}
              titleSX={{
                flex: 1,
                fontSize: '16px',
                fontWight: 800
              }}
              value={payment?.created_at ? helpers.formatDate(payment?.created_at) : '0000-00-00' + ""}
              paddingRight={"20px"}

            />
          </Box>
          <Box
            sx={{
              display: 'flex',
            }}
          >
            <InvoiceRow
              sx={{
                flex: 1
              }}
              title={"رقم الفاتورة:"}

              titleSX={{
                flex: 1,
                fontSize: '16px',
                fontWight: 800
              }}
              valueSX={{
                flex: 1,
                fontSize: '16px',
                fontWight: 400
              }}
              value={payment?.invoice?.id + ""}
              paddingRight={"20px"}

            />
            <InvoiceRow
              sx={{
                flex: 1
              }}
              title={"تاريخ الفاتورة:"}
              valueSX={{
                flex: 1,
                fontSize: '16px',
                fontWight: 400
              }}
              titleSX={{
                flex: 1,
                fontSize: '16px',
                fontWight: 800
              }}
              value={payment?.invoice?.due_date + ""}
              paddingRight={"20px"}

            />
          </Box>
          <Divider
            sx={{
              borderColor: '#000000',
              borderBottomWidth: '2px',
              opacity: 1,
              marginTop: '20px'
            }}
          />

          <InvoiceRow
            title={"اسم الزبون الثلاثي واللقب:"}
            titleSX={{
              flex: 5,
              fontSize: '16px',
              fontWight: 800
            }}
            value={getName()}
            paddingRight={"20px"}
          />
          <InvoiceRow
            title={"هاتف الزبون:"}
            titleSX={{
              flex: 3,
              fontSize: '16px',
              fontWight: 800
            }}
            value={getAddress(3)}
            valueSX={{
              direction: 'ltr',
              textAlign: 'right'
            }}
            paddingRight={"20px"}
          />
          <InvoiceRow
            title={"عنوان الزبون:"}
            titleSX={{
              flex: 3,
              fontSize: '16px',
              fontWight: 800
            }}
            value={(getAddress(1) ? getAddress(1) : "") + " " + (getAddress(2) ? getAddress(2) : "")}
            paddingRight={"20px"}
          />
          <Box
            sx={{
              display: 'flex',
            }}
          >
            <InvoiceRow
              title={"رقم العقد:"}
              titleSX={{
                flex: 1,
                fontSize: '16px',
                fontWight: 800
              }}
              valueSX={{
                flex: 1,
                fontSize: '16px',
                fontWight: 400
              }}
              sx={{
                display: 'flex',
                flex: 1
              }}
              value={payment?.invoice?.order?.contract_number}
              paddingRight={"20px"}
            />
            <InvoiceRow
              title={"تاريخ العقد:"}
              titleSX={{
                flex: 1,
                fontSize: '16px',
                fontWight: 800
              }}
              valueSX={{
                flex: 1,
                fontSize: '16px',
                fontWight: 400
              }}
              sx={{
                display: 'flex',
                flex: 1
              }}
              value={payment?.invoice?.order?.contract_printed_date}
              paddingRight={"20px"}
            />
          </Box>
          <Divider
            sx={{
              borderColor: '#000000',
              borderBottomWidth: '2px',
              opacity: 1,
              marginTop: '20px'
            }}
          />
          <InvoiceRow
            title={"المبلغ المقبوض:"}
            titleSX={{
              flex: 3,
              fontSize: '16px',
              fontWight: 800
            }}
            value={getPaymentPriceFormated()}
            paddingRight={"20px"}
          />
          <Divider
            sx={{
              borderColor: '#000000',
              borderBottomWidth: '2px',
              opacity: 1,
              marginTop: '20px'
            }}
          />
          <InvoiceRow
            title={"الملاحظات:"}
            titleSX={{
              flex: 3,
              fontSize: '16px',
              fontWight: 800
            }}
            value={getNoteText()}
            paddingRight={"20px"}
          />
        </Box>
      </Box>
    </Box>
  )
}
