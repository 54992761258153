const EntityError = {
    id:"",
    name:{
        value: "",
        error: false,
        message: "",
        required: false
    },
    description:{
        value: "",
        error: false,
        message: "",
        required: false
    },
    floor_number:{
        value: "",
        error: false,
        message: "",
        required: false
    },
    skip:{
        value: "",
        error: false,
        message: "",
        required: false
    },
    compound_phase_id:{
        value: "",
        error: false,
        message: "",
        required: false
    },
}
export const resetErrors = (entityObject) => {
    var objectKeys = Object.keys(entityObject);
   
    var updatedObject = {
        ...entityObject
    }
    try {
        objectKeys.filter(key=>key!=="id").map((key) => {
        
            updatedObject[key]["error"] = false
            updatedObject[key]["message"] = ""
        })
    } catch (error) {
        
    }
    
    return updatedObject

}
export const checkErrors = (errorMessages, entityObject) => {

    let errorMessagesKeys = Object.keys(errorMessages);
    // const EntityErrorKeys = Object.keys(EntityError);

    errorMessagesKeys.map((key) => {
        if(!key?.includes('contacts')){
        let messages = []
        errorMessages[key].map((message) => {
            messages.push(message)
        })
        if(entityObject&&Object.keys(entityObject)?.includes(key)){
            entityObject[key]["error"] = true
            entityObject[key]["message"] = messages
        }
        else{
            console.log("entityObject",entityObject);
        }
       }
    })

  




    return entityObject
}
export const entityObjectMerge = (updatedObject) => {
    const mergedCustomerObject = {
    id:updatedObject?.id,
    name: {
        value: updatedObject?.name,
        error: false,
        message: "",
        required: false
    },
    compound_phase_id:{
        value: updatedObject?.compound_phase,
        error: false,
        message: "",
        required: false
    },
    floor_number:{
        value: updatedObject?.floor_number,
        error: false,
        message: "",
        required: false
    },
    skip:{
        value: updatedObject?.skip,
        error: false,
        message: "",
        required: false
    },

    }
    
    return mergedCustomerObject;
}
export const resetEntityObject=(entityObject,obj)=>{
    // var objectKeys = Object.keys(entityObject);
   
    try {
        
        entityObject[obj]["error"] = false
        entityObject[obj]["message"] = ""
    } catch (error) {
        
    }
    
    return entityObject
}
export default EntityError;