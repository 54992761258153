import callAPI from '../ApiConfig'
import store from '../../../app/store'

const employee = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"employees",
        method:"get",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
    
}
const employeeList = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"employees_list",
        method:"get",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
    
}
const addEmployee = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"employees",
        method:"post",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        },
        data:props?.data
    })
    
}
const updateEmployee = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"employees/"+props?.id,
        method:"post",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        },
        data:props?.data
    })
    
}
const deleteEmployee = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"employees/"+props?.data?.id,
        method:"delete",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        },
        data:props?.data
    })
    
}

const showEmployee = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"employee",
        method:"get",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
    
}

const EmployeeAPI={
    Employee:employee,
    EmployeeList:employeeList,
    AddEmployee:addEmployee,
    UpdateEmployee:updateEmployee,
    DeleteEmployee:deleteEmployee,
    ShowEmployee:showEmployee
}
export default EmployeeAPI