import React, { useState, useEffect, useMemo } from 'react'
import Grid from '../../Grid/Grid'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux';
import MaritalStatusAPI from '../../../Global/Network/MaritalStatus/index'
import { toast } from 'react-toastify';
import DropDownGrid from '../../Grid/Components/DropDownGrid'
import { Box, MenuItem, ListItemIcon, Typography, Divider, Autocomplete, TextField, Tooltip } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ViewWeekIcon from '@mui/icons-material/ViewWeek';
import AddFormDailog from './AddFormDailog';
import swal from 'sweetalert';
import SearchInput from '../../Components/SearchInput';
import SearchDate from '../../Components/SearchDate';
import EmployeeAPI from '../../../Global/Network/Employee/index'
import { from } from 'stylis';
import helpers from '../../../assets/js/helper';
import { useGlobalContext } from '../../../context'
import * as MdIcons from 'react-icons/md'
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import * as FaIcons from 'react-icons/fa'
import customerAPI from '../../../Global/Network/Customer';
import invoiceAPI from '../../../Global/Network/Invoice/index';
import RolesOfUserAPI from '../../../Global/Network/RoleUser/index';
import { useLocation, useNavigate } from 'react-router-dom'
import GavelIcon from '@mui/icons-material/Gavel';
import ReceiptIcon from '@mui/icons-material/Receipt';
import { GiReceiveMoney } from 'react-icons/gi'
import { AiTwotonePrinter } from 'react-icons/ai'
import InvoicePrint from './InvoicePrint';
import InvoicePrintPopup from './InvoicePrintPopup';
import unitAPI from '../../../Global/Network/Unit';
import generalAPI from '../../../Global/Network/General';
import floorAPI from '../../../Global/Network/Floor';
import buildingAPI from '../../../Global/Network/Building';
import blockAPI from '../../../Global/Network/Block';
import SearchPhone from '../../Components/SearchPhone';

const urlParams = new URLSearchParams(window.location.search);
const orderIdFromState = urlParams.get('order_id');


const RenderSearchContent = (props) => {
  const [t] = useTranslation('common')
  console.log('user', props?.users)
  return (
    <Box sx={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: 'column',
      width: '100% !important',
    }}>
      <SearchInput
        value={props?.orderId}
        setValue={(value) => {
          if (!isNaN(value)) {
            let val = value?.replace('.', '')
            props?.setOrderId(val)
          }
        }}
        title={t('INVOICES.order_id')}
      />
      <SearchDate
        value={props?.fromDueDate}
        setValue={props?.setFromDueDate}
        title={t('INVOICES.from_due_date')}
      />
      <SearchDate
        value={props?.toDueDate}
        setValue={props?.setToDueDate}
        title={t('INVOICES.to_due_date')}
      />

      <SearchDate
        value={props?.fromDate}
        setValue={props?.setFromDate}
        title={t('INVOICES.work_fromDate_search')}
        minDate={null}
        maxDate={props?.toDate}
      />
      <SearchDate
        value={props?.toDate}
        setValue={props?.setToDate}
        title={t('INVOICES.work_toDate_search')}
        minDate={props?.fromDate}
        maxDate={null}
      />

      <Autocomplete
        sx={{
          ml: 1.5, mt: 1, mb: 1, width: '95%',
          '& .MuiFormLabel-root,& .MuiInputBase-input': {
            fontFamily: 'Cairo-Medium'
          }
        }}
        options={ [
          {
            id:1,
            name:t('INVOICES.paid_status')
          },
          {
            id:2,
            name:t('INVOICES.partially_paid_status')
          },
          {
            id:3,
            name:t('INVOICES.unpaid_status')
          }
        ]}
        getOptionLabel={(option) => option?.name || ""}
        id="disable-close-on-select"
        value={props?.selectedPaidStatus}
        onChange={(e, v, r) => { props.setSelectedPaidStatus(v ); }}
        renderInput={(params) => (
          <TextField {...params}
            placeholder={t('INVOICES.paid_status_title')}
            variant="standard" sx={{ fontFamily: 'Cairo-Medium' }} />
        )}
      />
      <Autocomplete
        sx={{
          ml: 1.5, mt: 1, mb: 1, width: '95%',
          '& .MuiFormLabel-root,& .MuiInputBase-input': {
            fontFamily: 'Cairo-Medium'
          }
        }}
        options={props.customers ? props.customers : []}
        getOptionLabel={(option) => option?.user && option?.user?.full_name || ""}
        id="disable-close-on-select"
        value={props?.selectedCustomer}
        onChange={(e, v, r) => { props.setSelectedCustomer(v ? v : ''); }}
        renderInput={(params) => (
          <TextField {...params}
            placeholder={t('INVOICES.customer')}
            variant="standard" sx={{ fontFamily: 'Cairo-Medium' }} />
        )}
      />
       <SearchPhone
        value={props?.customerPhone}
        setValue={props?.setCustomerPhone}
        title={t('GLOBAL.customerPohone')}
       />

      <Autocomplete
        sx={{
          ml: 1.5, mt: 1, mb: 1, width: '95%',
          '& .MuiFormLabel-root,& .MuiInputBase-input': {
            fontFamily: 'Cairo-Medium'
          }
        }}
        options={props.users ? props.users : []}
        getOptionLabel={(option) => option?.user && option?.user?.full_name || ""}
        id="disable-close-on-select"
        value={props?.createdBy}
        onChange={(e, v, r) => { props.setCreatedBy(v ? v : ''); }}
        renderInput={(params) => (
          <TextField {...params}
            placeholder={t('INVOICES.SEARCH_USERS')}
            variant="standard" sx={{ fontFamily: 'Cairo-Medium' }} />
        )}
      />

      <Autocomplete
        sx={{
          ml: 1.5, mt: 1, mb: 1, width: '95%',
          '& .MuiFormLabel-root,& .MuiInputBase-input': {
            fontFamily: 'Cairo-Medium'
          }
        }}
        options={props?.compounds ? props?.compounds : []}

        value={props?.selectedCompound}
        onChange={(e, v, r) => {
          if (v) {
            props?.setSelectedCompound(v)
            props?.setSelectedBlock({
              id: '',
              name: ''
            })
            props?.setSelectedBuilding({
              id: '',
              name: ''
            })
            props?.setSelectedFloor({
              id: '',
              name: ''
            })

          }
          else {
            props?.setSelectedCompound({
              id: '',
              name: ''
            })
          }

        }}
        getOptionLabel={((option) => option?.name)}
        renderInput={(params) => (
          <TextField {...params}
            placeholder={"اختر المجمع السكني"}
            variant="standard" sx={{ fontFamily: 'Cairo-Medium' }} />
        )}
      />
      <Autocomplete
        sx={{
          ml: 1.5, mt: 1, mb: 1, width: '95%',
          '& .MuiFormLabel-root,& .MuiInputBase-input': {
            fontFamily: 'Cairo-Medium'
          }
        }}
        options={props?.blocks ? props?.blocks : []}

        value={props?.selectedBlock}
        onChange={(e, v, r) => {
          if (!v) {
            props?.setSelectedBlock({
              id: '',
              name: ''
            })
            props?.setSelectedBuilding({
              id: '',
              name: ''
            })
            props?.setSelectedFloor({
              id: '',
              name: ''
            })

          }
          else {
            props?.setSelectedBlock(v)
            props?.setSelectedBuilding({
              id: '',
              name: ''
            })
            props?.setSelectedFloor({
              id: '',
              name: ''
            })


          }


        }}
        renderInput={(params) => (
          <TextField {...params}
            placeholder={"اختر قاطع"}
            variant="standard" sx={{ fontFamily: 'Cairo-Medium' }} />
        )}
        getOptionLabel={((option) => option?.name)}
      />
      <Autocomplete
        sx={{
          ml: 1.5, mt: 1, mb: 1, width: '95%',
          '& .MuiFormLabel-root,& .MuiInputBase-input': {
            fontFamily: 'Cairo-Medium'
          }
        }}
        options={props?.buildings ? props?.buildings : []}

        value={props?.selectedBuilding}
        onChange={(e, v, r) => {
          if (!v) {
            props?.setSelectedBuilding({
              id: '',
              name: ''
            })
            props?.setSelectedFloor({
              id: '',
              name: ''
            })


          }
          else {
            props?.setSelectedBuilding(v)
            props?.setSelectedFloor({
              id: '',
              name: ''
            })


          }

        }}
        renderInput={(params) => (
          <TextField {...params}
            placeholder={"اختر البناية"}
            variant="standard" sx={{ fontFamily: 'Cairo-Medium' }} />
        )}
        getOptionLabel={((option) => option?.name)}
      />
      <Autocomplete
        sx={{
          ml: 1.5, mt: 1, mb: 1, width: '95%',
          '& .MuiFormLabel-root,& .MuiInputBase-input': {
            fontFamily: 'Cairo-Medium'
          }
        }}
        options={props?.floors ? props?.floors : []}

        value={props?.selectedFloor && props?.selectedFloor?.id ? props?.selectedFloor : null}
        onChange={(e, v, r) => {
          if (!v) {
            props?.setSelectedFloor({
              id: '',
              name: ''
            })
          }
          else {
            props?.setSelectedFloor(v)
          }

        }}
        renderInput={(params) => (
          <TextField {...params}
            placeholder={"اختر الطابق"}
            variant="standard" sx={{ fontFamily: 'Cairo-Medium' }} />
        )}
        getOptionLabel={((option) => option?.name)}
      />
      <Autocomplete
        sx={{
          ml: 1.5, mt: 1, mb: 1, width: '95%',
          '& .MuiFormLabel-root,& .MuiInputBase-input': {
            fontFamily: 'Cairo-Medium'
          }
        }}
        options={props.units ? props.units : []}
        getOptionLabel={(option) => option?.name || ""}
        id="disable-close-on-select"
        value={props?.selectedUnit}
        onChange={(e, v, r) => { props.setSelectedUnit(v ? v : ''); }}
        renderInput={(params) => (
          <TextField {...params}
            placeholder={t('INVOICES.unit_name')}
            variant="standard" sx={{ fontFamily: 'Cairo-Medium' }} />
        )}
      />


    </Box>
  )
}

export default function Invoices() {
  const { state } = useLocation();
  const [t] = useTranslation("common");
  const navigate = useNavigate()
  const [rows, setRows] = useState([])
  const [pageSize, setPageSize] = useState(5);
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(false)
  const [pageCount, setPageCount] = useState(0)
  const [openFilterColumn, setOpenFilterColumn] = useState(false)
  const [openFilterColumnValue, setOpenFilterColumnValue] = useState([])
  const [elementClicked, setElementClicked] = useState(null)
  const [addForm, setAddForm] = useState(false)
  const [selectedObject, setselectedObject] = useState(null)
  const [fromDate, setFromDate] = useState(null)
  const [toDate, setToDate] = useState(null)
  const [objectName, setobjectName] = useState(null)
  const [createdBy, setCreatedBy] = useState(null)
  const [users, setUsers] = useState([])
  const { exportToCSV } = useGlobalContext()

  const [invoice, setInvoice] = useState(null)
  const [openInvoicePrint, setOpenInvoicePrint] = useState(false)
  const [openAddPayment, setOpenAddPayment] = useState(false)
  const [userRolesHere, setUserRolesHere] = useState([
    {
      view: true,
      id: 170
    },
    {
      add: false,
      id: 171,
    },
    {
      update: false,
      id: 172,
    },
    {
      delete: false,
      id: 173,
    }, {
      export: false,
      id: 174
    },
    {
      print: false,
      id: 175
    },
    {
      viewPayment: false,
      id: 182
    },
    {
      addPayment: false,
      id: 183
    },{
      viewContract: false,
      id: 176
    }
  ])
  const [selectedCompound, setSelectedCompound] = useState(null)
  const [selectedBlock, setSelectedBlock] = useState(null)
  const [selectedBuilding, setSelectedBuilding] = useState(null)
  const [selectedFloor, setSelectedFloor] = useState(null)
  const [compounds, setCompounds] = useState([])
  const [blocks, setBlocks] = useState([])
  const [buildings, setBuildings] = useState([])
  const [floors, setFloors] = useState()
  const [customerPhone,setCustomerPhone]=useState(null)

  const [units, setUnits] = useState([])
  const [selectedUnit, setSelectedUnit] = useState(null)

  const [customers, setCustomers] = useState([])
  const [selectedCustomer, setSelectedCustomer] = useState(null)
  const [filterParams,setFilterParams]=useState({})
  const [totalPrice, setTotalPrice] = useState('')
  const [fromDueDate, setFromDueDate] = useState(null)
  const [toDueDate, setToDueDate] = useState(null)

  const [orderId, setOrderId] = useState(orderIdFromState)

  const [selectedPaidStatus, setSelectedPaidStatus] = useState(null)

  const handlePageChange = (newPage) => {
    setPageNumber(newPage + 1);
  };
  const controller = new AbortController();

  const [rowsTotal, setRowsTotal] = useState(0)
  const screenwidth = useSelector(state => state.settingsData.screenwidth)

  const showActionDropDawonMessage = () => {
    let check = false;

    return check
  }

  const getPaidStatusColor = (row) => {
    let color = 'lightgray'
    if (row?.payments_sum_paid_amount && (row?.total_price == row?.payments_sum_paid_amount)) {
      // paid
      color = 'green'
    }
    else if (row?.payments_sum_paid_amount && (row?.total_price > row?.payments_sum_paid_amount)) {
      color = 'orange'
    }
    else if (!row?.payments_sum_paid_amount) {
      color = 'red'
    }
    return color
  }
  const getPaidStatusLabel = (row) => {
    

    let label = t('INVOICES.unpaid_status')
    if (row?.payments_sum_paid_amount && (row?.total_price == row?.payments_sum_paid_amount)) {
      // paid
      label = t('INVOICES.paid_status')
    }
    else if (row?.payments_sum_paid_amount && (row?.total_price > row?.payments_sum_paid_amount)) {
      label = t('INVOICES.partially_paid_status')
    }
    else if (!row?.payments_sum_paid_amount) {
      label = t('INVOICES.unpaid_status')
    }
    return label
  }
  const columns = [
    { headerName: t('INVOICES.id'), field: 'id', flex: 0.25,minWidth: 50, hide: false },
    {
      headerName: t('INVOICES.paid_status_title'), field: 'paid_status', flex: 0.5, minWidth: 40, maxWidth: 40, hide: false,
      renderHeader: (rows) => (
        <Box>

        </Box>
      ),
      renderCell: (rows) => (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
           <Tooltip
                title={
                  <span style={{ fontFamily: "Cairo-Medium" }}>
                    {getPaidStatusLabel(rows?.row)}
                  </span>
                }
              >
          <span style={{
            fontFamily: 'Cairo-Medium',
            width: '10px',
            height: '10px',
            borderRadius: '50%',
            backgroundColor: getPaidStatusColor(rows?.row)
          }} className="gridHeaderItem">
          </span>
          </Tooltip>
        </Box>
      )
    },
    {
      headerName: t('INVOICES.unit_name'), field: 'unit.name', flex: 0.9, minWidth: 150, maxWidth: 450, hide: false,
      renderCell: (rows) => (
        <Typography>
          {rows?.row?.order?.unit?.name}
        </Typography>
      )
    },
    {
      headerName: t('INVOICES.customer_name'), field: 'customer.user.full_name', flex: 1, minWidth: 200, maxWidth: 450, hide: false,
      renderCell: (rows) => (
        <Typography>
          {rows?.row?.order?.customer?.user?.full_name}
        </Typography>
      )
    },
    {
      headerName: t('INVOICES.order_number'), field: 'order_number', flex: 0.5, minWidth: 100, maxWidth: 450, hide: false,
      renderCell: (rows) => (
        <Typography>
          {rows?.row?.order?.id}
        </Typography>
      )
    },
    {
      headerName: t('INVOICES.total_price'), field: 'total_price', flex: 1, minWidth: 200, maxWidth: 450, hide: false,
      renderCell: (rows) => (
        <Typography>
          {helpers.getFormatedPrice(rows?.row?.total_price, rows?.row?.iqd_usd)}
        </Typography>
      )
    },
    {
      headerName: t('INVOICES.total_paid_amount'), field: 'payments_sum_paid_amount', flex: 1, minWidth: 200, maxWidth: 450, hide: false,
      renderCell: (rows) => (
        <Typography>
          {helpers.getFormatedPrice(rows?.row?.payments_sum_paid_amount, rows?.row?.iqd_usd)}
        </Typography>
      )
    },
    {
      headerName: t('INVOICES.due_date'), field: 'created_at', flex: 1, minWidth: 200, maxWidth: 450, hide: false,
      renderCell: (rows) => (
        <Typography>
          {rows?.row?.due_date}
        </Typography>
      )
    },
    {
      headerName: t('INVOICES.actions'), field: 'Actions', flex: 0.4, minWidth: 100, maxWidth: 250, hide: false,
      renderCell: (rows) => (
        <DropDownGrid className="containerOFDropDownToolTipeGrid">
          {

            userRolesHere[6]?.viewPayment || userRolesHere[7]?.addPayment ?

              <MenuItem onClick={() => {

                setInvoice(rows?.row)
                setOpenAddPayment(true)

              }} className="ActionIcons">
                <ListItemIcon sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  margin: '0px 7px 0 0'
                }}>
                  <GiReceiveMoney style={{ fontSize: '20px', fill: "#1E6A99" }} />

                </ListItemIcon>
                {t('INVOICES.make_payment')}
              </MenuItem> : null

          }
          {

            userRolesHere[5]?.print ?
              <MenuItem onClick={() => {
                setInvoice(rows?.row)
                setOpenInvoicePrint(true)

              }} className="ActionIcons">
                <ListItemIcon sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  margin: '0px 7px 0 0'
                }}>
                  <AiTwotonePrinter style={{ fontSize: '20px', fill: "#1E6A99" }} />

                </ListItemIcon>
                {t('INVOICES.print')}
              </MenuItem> : null
          }

          {
              userRolesHere[8]?.viewContract&&rows?.row?.order_id ?
                <MenuItem onClick={() => {
                  window.open(window.location.origin+'/admin/order_contract?order_id='+rows?.row?.order_id)
                }} className="ActionIcons">
                  <ListItemIcon sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    margin: '0px 7px 0 0'
                  }}>
                    <FaIcons.FaFileInvoice style={{ fontSize: '20px', fill: "#1E6A99" }} />

                  </ListItemIcon>
                  {t('INVOICES.contract')}
                </MenuItem> : null
          }

          {
            !userRolesHere[5]?.print && !userRolesHere[6]?.viewPayment && !userRolesHere[7]?.addPayment&&(!userRolesHere[8]?.viewContract||rows?.row?.order_id) ?
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                <p style={{ fontFamily: 'Cairo', margin: '5px 20px' }}>{t('GLOBAL.emptyPoPup')}</p>
              </Box> : null
          }

        </DropDownGrid>
      ),
    },
  ]
  useEffect(() => {
    let abortController = new AbortController();
    loadData()
    return () => {
      abortController.abort();
    }
  }, [pageNumber, pageSize,orderId])

  useEffect(() => {
    let abortController = new AbortController();
    console.log('saldkjaskd', 1);
    
    // loadDataOfUsers()
    loadDataOfUnit()
    loadDataOfCustomer()

    loadMasterData()
    getUserRoles()
    return () => {
      abortController.abort();
    }
  }, [])

  const loadData = async (searchParams) => {
    setLoading(true)
    const filterParamsData={
      ...filterParams,
      ...searchParams,
      
     }
    const result = await invoiceAPI.invoices({
      params: {
        page_size: pageSize,
        page: pageNumber,
        ...(state?.data?.order?.id? {
          order_id: state?.data?.order?.id
        } : orderIdFromState?{
          order_id: orderIdFromState,
        }:{}),
        ...filterParamsData
      },
      signal: controller?.signal
    })

    setLoading(false)
    if (result.status) {
      setRows(result?.data?.data)
      setRowsTotal(result?.data?.total)
      setPageCount(result?.data?.last_page)
    }
    else {
      if (!result?.data?.error) {
        toast.error(t('NETWORKMESSAGE.messageError'))
        return
      }
    }

  }
  // const loadDataOfUsers = async (searchParams) => {
  //   setLoading(true)

  //   const result = await EmployeeAPI.EmployeeList({
  //     signal: controller?.signal
  //   })

  //   setLoading(false)
  //   if (result.status) {
  //     setUsers(result?.data)
  //   }
  //   else {
  //     if (!result?.data?.error) {
  //       toast.error(t('NETWORKMESSAGE.messageError'))
  //       return
  //     }
  //   }

  // }
  const createFUnction = () => {
    setselectedObject(null)
    setAddForm(true)
  }
  const updateFunction = (obj) => {
    setselectedObject(obj)
    setAddForm(true)
  }

  const clearFunction = () => {
    setobjectName('')
    setFromDate(null)
    setToDate(null)
    setCreatedBy(null)
    setSelectedUnit(null)
    setSelectedCustomer(null)
    setTotalPrice('')
    setFromDueDate(null)
    setToDueDate(null)
    setOrderId(null)
    setFilterParams({})

  }
  const searchFunction = () => {
    let data = {};

    if (orderId) {
      data = {
        ...data,
        order_id: orderId
      }
    }
    if (fromDueDate) {
      data = {
        ...data,
        due_date_from: helpers.formatDate(fromDueDate)
      }
    }
    if (toDueDate) {
      data = {
        ...data,
        due_date_to: helpers.formatDate(toDueDate)
      }
    }
    if (selectedCustomer) {
      data = {
        ...data,
        customer_id: selectedCustomer?.id
      }
    }
    if (customerPhone) {
      data = {
        ...data,
        customer_phone: customerPhone
      }
    }
    if (selectedCompound&&selectedCompound?.id) {
      data = {
        ...data,
        compound_id: selectedCompound?.id
      }
    }
    if (selectedBlock&&selectedBlock?.id) {
      data = {
        ...data,
        block_id: selectedBlock?.id
      }
    }
    if (selectedBuilding&&selectedBuilding?.id) {
      data = {
        ...data,
        building_id: selectedBuilding?.id
      }
    }
    if (selectedFloor&&selectedFloor?.id) {
      data = {
        ...data,
        floor_id: selectedFloor?.id
      }
    }
    if (selectedUnit&&selectedUnit?.id) {
      data = {
        ...data,
        unit_id: selectedUnit?.id
      }
    }
    
    console.log("selectedPaidStatus",selectedPaidStatus);
    if (selectedPaidStatus) {
      data = {
        ...data,
        paid_status_id: selectedPaidStatus?.id
      }
    }
    if (fromDate) {
      data = {
        ...data,
        creation_date_from: helpers.formatDate(fromDate)
      }
    }
    if (toDate) {
      data = {
        ...data,
        creation_date_to: helpers.formatDate(toDate)
      }
    }
    if (createdBy && createdBy?.user && createdBy.user?.id) {
      data = {
        ...data,
        created_by_id: createdBy.user?.id
      }
    }
    setFilterParams(data)

    loadData(data)
  }
  const loadDataForExport = async (searchParams) => {
    let result = await invoiceAPI.invoices({
      params: {
        page_size: rowsTotal,
        ...searchParams
      },
      signal: controller?.signal
    })
    return result;
  }
  const ExportFunction = async () => {
    let filteredData = {}
    if (orderId) {
      filteredData = {
        ...filteredData,
        order_id: orderId
      }
    }
    if (fromDueDate) {
      filteredData = {
        ...filteredData,
        due_date_from: helpers.formatDate(fromDueDate)
      }
    }
    if (toDueDate) {
      filteredData = {
        ...filteredData,
        due_date_to: helpers.formatDate(toDueDate)
      }
    }
    if (selectedCustomer) {
      filteredData = {
        ...filteredData,
        customer_id: selectedCustomer?.id
      }
    }
    if (customerPhone) {
      filteredData = {
        ...filteredData,
        customer_phone: customerPhone
      }
    }
    if (selectedCompound&&selectedCompound?.id) {
      filteredData = {
        ...filteredData,
        compound_id: selectedCompound?.id
      }
    }
    if (selectedBlock&&selectedBlock?.id) {
      filteredData = {
        ...filteredData,
        block_id: selectedBlock?.id
      }
    }
    if (selectedBuilding&&selectedBuilding?.id) {
      filteredData = {
        ...filteredData,
        building_id: selectedBuilding?.id
      }
    }
    if (selectedFloor&&selectedFloor?.id) {
      filteredData = {
        ...filteredData,
        floor_id: selectedFloor?.id
      }
    }
    if (selectedUnit&&selectedUnit?.id) {
      filteredData = {
        ...filteredData,
        unit_id: selectedUnit?.id
      }
    }
    if (selectedPaidStatus) {
      filteredData = {
        ...filteredData,
        paid_status_id: selectedPaidStatus?.id
      }
    }

    if (fromDate) {
      filteredData = {
        ...filteredData,
        creation_date_from: helpers.formatDate(fromDate)
      }
    }
    if (toDate) {
      filteredData = {
        ...filteredData,
        creation_date_to: helpers.formatDate(toDate)
      }
    }
    if (createdBy && createdBy?.id) {
      filteredData = {
        ...filteredData,
        created_by_id: createdBy?.id
      }
    }

    const fetchedData = await loadDataForExport(filteredData);
    if (!fetchedData || !fetchedData?.status) {
      toast.error("لا يمكن استرجاع البيانات")
      return
    }
    let arr = [];
    fetchedData?.data?.data && fetchedData?.data?.data?.map((itm) => {
      arr.push({
        [`${t('INVOICES.id')}`]: itm?.id,
        [`${t('INVOICES.unit_name')}`]: itm?.order?.unit?.name,
        [`${t('INVOICES.customer')}`]: itm?.order?.customer?.user?.full_name,
        [`${t('INVOICES.order_id')}`]: itm?.order?.id,
        [`${t('INVOICES.total_price')}`]: itm?.total_price,
        [`${t('INVOICES.iqd_usd')}`]: itm?.iqd_usd ? "USD" : 'IQD',
        // [`${t('INVOICES.payment_type_detail')}`]: itm?.payment_type_detail?.value,
        [`${t('INVOICES.due_date')}`]: itm?.due_date ? helpers?.formatDate(itm?.due_date) : '',

        [`${t('INVOICES.created_at')}`]: itm?.created_at ? helpers.formatDate(itm?.created_at) + " " + helpers.formatTime(itm?.created_at) : '0000-00-00 00:00:00',
      })
    })
    exportToCSV(arr, 'INVOICES')

  }
  const getUserRoles = async () => {
    // setLoading(true)
    console.log('saldkjaskd', 2);
    try {
      let data = await RolesOfUserAPI.GetRolesOfUser();
      if (data && data?.status) {
        console.log('userRolesmmm', data?.data);
        if (data?.data && data?.data?.length > 0) {
          let arr = [];

          let checkHasRole = data?.data?.includes(userRolesHere[0]?.id)
          if (checkHasRole) {
            arr.push(
              {
                view: true,
                id: userRolesHere[0]?.id,
              }
            )
          } else {
            arr.push(
              {
                view: false,
                id: userRolesHere[0]?.id,
              }
            )
          }
          console.log('sassssss', userRolesHere[1]?.id);
          checkHasRole = data?.data?.includes(userRolesHere[1]?.id)
          if (checkHasRole) {
            arr.push(
              {
                add: true,
                id: userRolesHere[1]?.id,
              }
            )
          } else {
            arr.push(
              {
                add: false,
                id: userRolesHere[1]?.id,
              }
            )
          }
          checkHasRole = data?.data?.includes(userRolesHere[2]?.id)
          if (checkHasRole) {
            arr.push(
              {
                update: true,
                id: userRolesHere[2]?.id,
              }
            )
          } else {
            arr.push(
              {
                update: false,
                id: userRolesHere[2]?.id,
              }
            )
          }
          checkHasRole = data?.data?.includes(userRolesHere[3]?.id)
          if (checkHasRole) {
            arr.push(
              {
                delete: true,
                id: userRolesHere[3]?.id,
              }
            )
          } else {
            arr.push(
              {
                delete: false,
                id: userRolesHere[3]?.id,
              }
            )
          }
          checkHasRole = data?.data?.includes(userRolesHere[4]?.id)
          if (checkHasRole) {
            arr.push(
              {
                export: true,
                id: userRolesHere[4]?.id,
              }
            )
          } else {
            arr.push(
              {
                export: false,
                id: userRolesHere[4]?.id,
              }
            )
          }

          checkHasRole = data?.data?.includes(userRolesHere[5]?.id)
          if (checkHasRole) {
            arr.push(
              {
                print: true,
                id: userRolesHere[5]?.id,
              }
            )
          } else {
            arr.push(
              {
                print: false,
                id: userRolesHere[5]?.id,
              }
            )
          }

          checkHasRole = data?.data?.includes(userRolesHere[6]?.id)
          if (checkHasRole) {
            arr.push(
              {
                viewPayment: true,
                id: userRolesHere[6]?.id,
              }
            )
          } else {
            arr.push(
              {
                viewPayment: false,
                id: userRolesHere[6]?.id,
              }
            )
          }

          checkHasRole = data?.data?.includes(userRolesHere[7]?.id)
          if (checkHasRole) {
            arr.push(
              {
                addPayment: true,
                id: userRolesHere[7]?.id,
              }
            )
          } else {
            arr.push(
              {
                addPayment: false,
                id: userRolesHere[7]?.id,
              }
            )
          }
          checkHasRole = data?.data?.includes(userRolesHere[8]?.id)
          if (checkHasRole) {
            arr.push(
              {
                viewContract: true,
                id: userRolesHere[8]?.id,
              }
            )
          } else {
            arr.push(
              {
                viewContract: false,
                id: userRolesHere[8]?.id,
              }
            )
          }

          setUserRolesHere(arr)
        }
      }
      setLoading(false)
    } catch (err) {
      setLoading(false)
      console.log(err?.message);

    }
  }
  const loadDataOfCustomer = async (searchParams) => {
    setLoading(true)

    const result = await customerAPI.customersList({
      params: {
        ...searchParams
      },
      signal: controller?.signal
    })

    if (result.status) {
      setCustomers(result?.data)
    }
    else {
      if (!result?.data?.error) {
        toast.error(t('NETWORKMESSAGE.messageError'))
        return
      }
    }

  }
 

  useEffect(() => {
    if (selectedCompound?.id)
      loadBlocks()

    return () => {

    }
  }, [selectedCompound])

  useEffect(() => {
    if (selectedBlock?.id)
      loadBuildings()

    return () => {

    }
  }, [selectedBlock])

  useEffect(() => {
    if (selectedBuilding?.id)
      loadFloors()

    return () => {

    }
  }, [selectedBuilding])

  useEffect(() => {
    if (selectedFloor?.id)
    loadDataOfUnit()

    return () => {
    }
  }, [selectedFloor])


  const loadBlocks = async () => {
    if (!selectedCompound || !selectedCompound?.id) {
      setFloors([])
      setBuildings([])
      setBlocks([])
      setFloors([])
      return
    }
    setLoading(true)


    try {
      const result = await blockAPI.blocksList({
        params: {
          compound_id: selectedCompound?.id,

        },
        signal: controller?.signal
      })
      if (result.status) {

        setBlocks(result?.data)
      }
    } catch (err) {
      console.log(err?.message)
      toast.error(err?.message)
    }
    setLoading(false)
  }
  const loadBuildings = async () => {
    if (!selectedBlock || !selectedBlock?.id) {
      setBuildings([])
      setFloors([])
      return
    }
    setLoading(true)
    try {
      const result = await buildingAPI.buildingsList({
        params: {
          block_id: selectedBlock?.id,

        },
        signal: controller?.signal
      })
      if (result.status) {

        setBuildings(result?.data)
      }
    } catch (err) {
      console.log(err?.message)
      toast.error(err?.message)
    }
    setLoading(false)
  }
  const loadFloors = async () => {
    if (!selectedBuilding || !selectedBuilding?.id) {
      setFloors([])
      return
    }
    setLoading(true)
    try {
      const result = await floorAPI.floorsList({
        params: {
          building_id: selectedBuilding?.id,

        },
        signal: controller?.signal
      })
      if (result.status) {

        setFloors(result?.data)
      }
    } catch (err) {
      console.log(err?.message)
      toast.error(err?.message)
    }
    setLoading(false)
  }
  const loadDataOfUnit = async (searchParams) => {
    if (!selectedFloor || !selectedFloor?.id) {
      setUnits([])
      return
    }
    setLoading(true)

    const result = await unitAPI.unitsList({
      params: {
        ...searchParams,
          floor_id: selectedFloor?.id,
      },
      signal: controller?.signal
    })

    if (result.status) {
      setUnits(result?.data)
    }
    else {
      if (!result?.data?.error) {
        toast.error(t('NETWORKMESSAGE.messageError'))
        return
      }
    }

  }


  const loadMasterData = async () => {
    let data = null;
    try {

      setLoading(true)
      let masters = [
       
        {
          type: 'customers'
        },
        {
          type: 'employees'
        },
        {
          type: 'compounds'
        }

      ]

      data = await generalAPI.getMasters(
        {
          params: {
            masters: masters
          }
        }
      );
      if (data && data?.status) {
        setUsers(Array.isArray(data?.data?.employees) ? data?.data?.employees : [])
        setCompounds(Array.isArray(data?.data?.compounds) ? data?.data?.compounds : [])
        setCustomers(Array.isArray(data?.data?.customers) ? data?.data?.customers : [])

      } else {
        toast.error(t('NETWORKMESSAGE.messageError'))
      }
    } catch (err) {
      console.log(err?.message);
    }
    setLoading(false)
  }



  return (
    <Box sx={{
      // padding: '10px',
      // paddingTop: '20px',
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      width: '100%',
      justifyContent: 'center',
      alignItems: 'start',
      overflowX: 'hidden',

    }}
      className=" pt-3 mt-1"

    >
      <InvoicePrintPopup
        invoice={invoice}
        open={openInvoicePrint}
        setOpen={setOpenInvoicePrint}
      />
      {openAddPayment?
      <AddFormDailog object={invoice} addForm={openAddPayment} setAddForm={setOpenAddPayment} loadData={loadData} />
      :null}

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          height: '100%',
          justifyContent: 'center',
          alignItems: 'stretch',
          position: 'relative',
        }}
      >
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Typography
            variant='h4'
            sx={{
              marginLeft: '10px',
              marginBottom: '10px',
              fontFamily: 'Cairo-Bold',
              fontSize: '16px'
            }}>
            {t('INVOICES.title')}
          </Typography>
        </Box>

        <Box sx={{ marginRight: '20px', backgroundColor: '#1E6A99', color: '#FFFFFF', width: '42px', height: '40px', borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }}
          onClick={(event) => {
            // setOpenFilterColumn(true)
            // setElementClicked(event.currentTarget)
          }}
          id="demo-customized-button"
          aria-controls={openFilterColumn ? 'demo-customized-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={openFilterColumn ? 'true' : undefined}
          variant="contained"
        >
          {/* <ViewWeekIcon sx={{color:'#fffff'}}/> */}
          <FaIcons.FaHouseUser
            style={{ fontSize: '30px', color: '#fff' }}
          />
        </Box>

      </Box>

      <Divider
        sx={{
          backgroundColor: '#EBF2F7',
          margin: '30px',
          marginTop: '10px',
          width: '100%'
        }}
        style={{
          height: '3px',

        }}
      />

      <Grid
        rows={rows}
        columns={columns}
        setColumns={null}
        pageSize={pageSize}
        setPageSize={setPageSize}
        pageNumber={pageNumber}
        setPageNumber={setPageNumber}
        loading={loading}
        handlePageChange={handlePageChange}
        rowsTotal={rowsTotal}
        checkScreenSize={screenwidth}
        pageCount={pageCount}
        openFilterColumn={openFilterColumn}
        elementClicked={elementClicked}
        setOpenFilterColumn={setOpenFilterColumn}
        setColumnFilterValue={setOpenFilterColumnValue}
        columFilterValue={openFilterColumnValue}
        clearFunction={clearFunction}
        searchFunction={searchFunction}
        creatFunction={createFUnction}
        ExportFunction={ExportFunction}
        importFunction={null}
        mapFunction={null}
        telegramFunction={null}
        hasCreate={userRolesHere[1].add ? false : false}// not have create just for now
        hasImport={false}
        hasExport={userRolesHere[4].export}
        hasMap={false}
        hasTetelgram={false}
        filterChilds={<RenderSearchContent
          objectName={objectName}
          setobjectName={setobjectName}
          fromDate={fromDate}
          setFromDate={setFromDate}
          toDate={toDate}
          setToDate={setToDate}
          createdBy={createdBy}
          setCreatedBy={setCreatedBy}
          users={users}
          setToDueDate={setToDueDate}
          toDueDate={toDueDate}
          setFromDueDate={setFromDueDate}
          fromDueDate={fromDueDate}
          setSelectedPaidStatus={setSelectedPaidStatus}
          selectedPaidStatus={selectedPaidStatus}
          units={units}
          selectedUnit={selectedUnit}
          setSelectedUnit={setSelectedUnit}
          customers={customers}
          selectedCustomer={selectedCustomer}
          setSelectedCustomer={setSelectedCustomer}
          totalPrice={totalPrice}
          setTotalPrice={setTotalPrice}
          orderId={orderId}
          setOrderId={setOrderId}

          floors={floors}
          selectedFloor={selectedFloor}
          setSelectedFloor={setSelectedFloor}

          buildings={buildings}
          selectedBuilding={selectedBuilding}
          setSelectedBuilding={setSelectedBuilding}

          blocks={blocks}
          selectedBlock={selectedBlock}
          setSelectedBlock={setSelectedBlock}

          compounds={compounds}
          selectedCompound={selectedCompound}
          setSelectedCompound={setSelectedCompound}
          customerPhone={customerPhone}
          setCustomerPhone={setCustomerPhone}


        />}
      />


    </Box>
  )
}
