import { Box } from "@mui/material";
import React from "react";
import "./style.css";
import {Link} from 'react-router-dom'
export default function RedirectPages(props) {
  return (
    <Box className="redirectPages pt-3 mt-1">
      <section className="page_404">
        <div className="container">
          <div className="row">
            <div >
              <div className="col-sm-12 col-sm-offset-1  text-center containerImage">
                <div className="four_zero_four_bg">
                  <h1 className="text-center"
                   style={{
                    fontFamily:'Cairo-Bold'
                   }}
                  >{props?.code}</h1>
                </div>

                <div className="contant_box_404">
                  <h3 className="h2" style={{
                    fontFamily:'Cairo-Medium'
                  }}>{props?.reason}</h3>

                  <p style={{
                    fontFamily:'Cairo'
                  }}>{props?.message}!</p>
                  {
                    !props?.hasReload?
                      <Link to={props?.path} className="link_404"
                        style={{
                          fontFamily:'Cairo-Medium'
                        }}
                      >
                         {props?.goTo}
                      </Link>:
                       <p className="link_404"
                       style={{
                         fontFamily:'Cairo-Medium',
                         cursor:'pointer'
                       }}
                       onClick={()=>{
                        document.location.reload();
                       }}
                     >
                        {props?.goTo}
                     </p>
                    }
                   
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Box>
  );
}
