import { Box } from '@mui/material'
import React,{useEffect,useState} from 'react'
import LeftSide from './Components/LeftSide'
import RightSide from './Components/RightSide'
import {useSelector} from 'react-redux'
import unitAPI from '../../Global/Network/Unit'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import RolesOfUserAPI from '../../Global/Network/RoleUser'
const urlParams = new URLSearchParams(window.location.search);
const UnitId = urlParams.get('id');

export default function ViewUnit() {
  const screenwidth=useSelector((state)=>state.settingsData.screenwidth)
  const [UnitData,setUnitData]=useState(null)
  const [userRolesHere,setUserRolesHere]=useState([
    {
      view:true,
      id:149
    },
    {
    add:false,
    id:150,
    },
    {
    update:false,
    id:151,
    },
    {
    delete:false,
    id:152,
    },{
    export:false,
    id:153
    },
    {
      editUnitStatus:false,
      id:136
    },
    {
      addOrder:false,
      id:165
    },
    {
      deleteOrder:false,
      id:167
    },
    {
      addContract:false,
      id:177
    },
    {
      addInvoice:false,
      id:171
    },
  ])
  // const {id}=useParams()
  const [t]=useTranslation('common')
  useEffect(() => {
    const abortController=new AbortController();
    loadDataOfUnit();
    getUserRoles()
    return () => {
      abortController.abort();
    };
  }, []);

  const loadDataOfUnit=async()=>{
    let data=null;
    try{
        data=await unitAPI.unit({
         params:{
          id:UnitId
         }
        });
        if(data&&data?.status){
          console.log('unit-view',data?.data);
          setUnitData(data?.data)
        }else{
            toast.warn(t('NETWORKMESSAGE.messageError'))
        }
    }catch(err){
        console.log(err?.message);
    }
}

const getUserRoles=async()=>{
  // setLoading(true)
  console.log('saldkjaskd',2);
 try{ 
  let data=await RolesOfUserAPI.GetRolesOfUser();
  if(data&&data?.status){
    if(data?.data&&data?.data?.length>0){
        let arr=[];
        
         let checkHasRole=data?.data?.includes(userRolesHere[0]?.id)
          if(checkHasRole){
            arr.push(
                {
                  view:true,
                  id:userRolesHere[0]?.id,
                }
            )
          }else{
            arr.push(
                  {
                    view:false,
                    id:userRolesHere[0]?.id,
                  }
               )
          }
          console.log('sassssss',userRolesHere[1]?.id);
         checkHasRole=data?.data?.includes(userRolesHere[1]?.id)
          if(checkHasRole){
             arr.push(
                {
                  add:true,
                  id:userRolesHere[1]?.id,
                }
             )
          }else{
            arr.push(
                  {
                    add:false,
                    id:userRolesHere[1]?.id,
                  }
               )
          }
        checkHasRole=data?.data?.includes(userRolesHere[2]?.id)
          if(checkHasRole){
             arr.push(
                {
                  update:true,
                  id:userRolesHere[2]?.id,
                }
             )
          }else{
              arr.push(
                  {
                    update:false,
                    id:userRolesHere[2]?.id,
                  }
               )
          }
        checkHasRole=data?.data?.includes(userRolesHere[3]?.id)
          if(checkHasRole){
             arr.push(
                {
                  delete:true,
                  id:userRolesHere[3]?.id,
                }
             )
          }else{
              arr.push(
                  {
                    delete:false,
                    id:userRolesHere[3]?.id,
                  }
               )
          }
          checkHasRole=data?.data?.includes(userRolesHere[4]?.id)
          if(checkHasRole){
             arr.push(
                {
                  export:true,
                  id:userRolesHere[4]?.id,
                }
             )
          }else{
              arr.push(
                  {
                    export:false,
                    id:userRolesHere[4]?.id,
                  }
               )
          }


          checkHasRole=data?.data?.includes(userRolesHere[5]?.id)
          if(checkHasRole){
             arr.push(
                {
                  editUnitStatus:true,
                  id:userRolesHere[5]?.id,
                }
             )
          }else{
            arr.push(
                  {
                    editUnitStatus:false,
                    id:userRolesHere[5]?.id,
                  }
               )
          }
        checkHasRole=data?.data?.includes(userRolesHere[6]?.id)
          if(checkHasRole){
             arr.push(
                {
                  addOrder:true,
                  id:userRolesHere[6]?.id,
                }
             )
          }else{
              arr.push(
                  {
                    addOrder:false,
                    id:userRolesHere[6]?.id,
                  }
               )
          }
        checkHasRole=data?.data?.includes(userRolesHere[7]?.id)
          if(checkHasRole){
             arr.push(
                {
                  deleteOrder:true,
                  id:userRolesHere[7]?.id,
                }
             )
          }else{
              arr.push(
                  {
                    deleteOrder:false,
                    id:userRolesHere[7]?.id,
                  }
               )
          }
          checkHasRole=data?.data?.includes(userRolesHere[8]?.id)
          if(checkHasRole){
             arr.push(
                {
                  addContract:true,
                  id:userRolesHere[8]?.id,
                }
             )
          }else{
              arr.push(
                  {
                    addContract:false,
                    id:userRolesHere[8]?.id,
                  }
               )
          }
          checkHasRole=data?.data?.includes(userRolesHere[9]?.id)
          if(checkHasRole){
             arr.push(
                {
                  addInvoice:true,
                  id:userRolesHere[9]?.id,
                }
             )
          }else{
              arr.push(
                  {
                    addInvoice:false,
                    id:userRolesHere[9]?.id,
                  }
               )
          }
        setUserRolesHere(arr)
    }
  }
 }catch(err){
   console.log(err?.message);
   
 }
}

  return (
    <Box
     className="container-fluid mt-4"
    >
     <Box className={`row ${screenwidth<1100?'flex-column-reverse':'flex-row'} justify-content-evenly`}>
        <Box className={`col-12 col-sm-12 col-md-12 ${screenwidth<1100?'col-lg-12':'col-lg-6'}`}>
            <LeftSide 
             UnitData={UnitData}
             loadDataOfUnit={loadDataOfUnit}
             UnitId={UnitId}
             userRolesHere={userRolesHere}
            />
        </Box>
        <Box className={`col-12 col-sm-12 col-md-12 ${screenwidth<1100?'col-lg-12':'col-lg-6'}`}>
           <RightSide
             UnitData={UnitData}
             loadDataOfUnit={loadDataOfUnit}
             UnitId={UnitId}
             userRolesHere={userRolesHere}
           />
        </Box>
     </Box>

    </Box>
  )
}
