import callAPI from '../ApiConfig'
import store from '../../../app/store'

const contractTemplate = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"contract_templates",
        method:"get",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
    
}
const contractTemplateList = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"contract_templates_list",
        method:"get",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
    
}
const addContractTemplate = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"contract_templates",
        method:"post",
        signal:props?.signal,
        params:props?.params,
        data:props?.data,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
    
}
const updatecontractTemplate = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"contract_templates/"+props?.data?.id,
        method:"put",
        signal:props?.signal,
        params:props?.params,
        data:props?.data,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
    
}
const deletecontractTemplate = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"contract_templates/"+props?.data?.id,
        method:"delete",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
    
}



const ContractTemplateAPI={
    ContractTemplate:contractTemplate,
    ContractTemplateList:contractTemplateList,
    addContractTemplate:addContractTemplate,
    updatecontractTemplate:updatecontractTemplate,
    deletecontractTemplate:deletecontractTemplate,

}
export default ContractTemplateAPI