import callAPI from '../ApiConfig'
import store from '../../../app/store'

const Phases = async (props) => {
    const storeState = store.getState();
    return await callAPI({
        route:"phases",
        method:"get",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
}
const PhaseList = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"phases_list",
        method:"get",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
    
}
const addPhase = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"phases",
        method:"post",
        signal:props?.signal,
        params:props?.params,
        data:props?.data,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
    
}
const updatePhase = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"phases/"+props?.data?.id,
        method:"put",
        signal:props?.signal,
        params:props?.params,
        data:props?.data,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
    
}
const deletePhase = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"phases/"+props?.data?.id,
        method:"delete",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
    
}



const PhaseAPI={
    Phase:Phases,
    PhaseList:PhaseList,
    addPhase:addPhase,
    updatePhase:updatePhase,
    deletePhase:deletePhase,

}
export default PhaseAPI