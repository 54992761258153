import callAPI from '../ApiConfig'
import store from '../../../app/store'

const CompoundPhase = async (props) => {
    const storeState = store.getState();
    return await callAPI({
        route:"compound_phases",
        method:"get",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
}
const CompoundPhaseList = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"compound_phases_list",
        method:"get",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
    
}
const addCompoundPhase= async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"compound_phases",
        method:"post",
        signal:props?.signal,
        params:props?.params,
        data:props?.data,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
    
}
const updateCompoundPhase = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"compound_phases/"+props?.data?.id,
        method:"put",
        signal:props?.signal,
        params:props?.params,
        data:props?.data,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
    
}
const deleteCompoundPhase = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"compound_phases/"+props?.data?.id,
        method:"delete",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
    
}



const CompoundPhaseAPI={
    CompoundPhase:CompoundPhase,
    CompoundPhaseList:CompoundPhaseList,
    addCompoundPhase:addCompoundPhase,
    updateCompoundPhase:updateCompoundPhase,
    deleteCompoundPhase:deleteCompoundPhase,

}
export default CompoundPhaseAPI