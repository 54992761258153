const ObjectOfErrors = {
    id: "",
    name: {
        value: "",
        error: false,
        message: [],
        required: false
    },
    description: {
        value: "",
        error: false,
        message: [],
        required: false
    },
    enable: {
        value: "",
        error: false,
        message: [],
        required: false
    },
    expiry_date: {
        value: "",
        error: false,
        message: [],
        required: false
    },
    paymentTypeDetails: []
}

export const resetErrors = (customerObject) => {
    var objectKeys = Object.keys(customerObject);

    var updatedObject = {
        ...customerObject
    }
    try {
        objectKeys.filter(key => key !== "id").map((key) => {

            updatedObject[key]["error"] = false
            updatedObject[key]["message"] = ""
        })
    } catch (error) {

    }

    return updatedObject

}
export const checkErrors = (errorMessages, customerObject) => {

    const errorMessagesKeys = Object.keys(errorMessages);
    errorMessagesKeys.map((key) => {
        if (!key?.includes('contacts')) {
            let messages = []
            errorMessages[key].map((message) => {
                messages.push(message)
            })

            customerObject[key]["error"] = true
            customerObject[key]["message"] = messages
        }
    })






    return customerObject
}
export const ObjectMerge = (updatedObject) => {
    const mergedCustomerObject = {
        id: updatedObject?.id,
        name: {
            value: updatedObject?.name,
            error: false,
            message: [],
            required: false
        },
        description: {
            value: updatedObject?.description,
            error: false,
            message: [],
            required: false
        },
        enable: {
            value: updatedObject?.enable,
            error: false,
            message: [],
            required: false
        },
        expiry_date: {
            value: updatedObject?.expiry_date,
            error: false,
            message: [],
            required: false
        },
        paymentTypeDetails: updatedObject?.payment_type_details?.length > 0 ? updatedObject?.payment_type_details?.map(paymentTypeDetail => {
            return {
                id:paymentTypeDetail?.id,
                percentage_absolute: {
                    value: paymentTypeDetail?.percentage_absolute,
                    error: false,
                    message: [],
                    required: false
                },
                days_duration_from_contract: {
                    value: paymentTypeDetail?.days_duration_from_contract,
                    error: false,
                    message: [],
                    required: false
                },
                value: {
                    value: paymentTypeDetail?.value,
                    error: false,
                    message: [],
                    required: false
                },
            }
        }) : [],
    }

    return mergedCustomerObject;
}
export const restCustomeObject = (customerObject, obj) => {
    // var objectKeys = Object.keys(customerObject);

    try {

        customerObject[obj]["error"] = false
        customerObject[obj]["message"] = ""
    } catch (error) {

    }

    return customerObject
}
export default ObjectOfErrors;