import { Box, Divider, IconButton, Typography } from '@mui/material'
import React, { useState, useEffect } from 'react'
import CustomFooter from '../../General/CustomFooter/CustomFooter'
import customerAPI from '../../Global/Network/Customer'
import DataGridRow from '../Grid/DataGridRow';
import { RiSearchFill } from 'react-icons/ri'
import { GrAdd } from 'react-icons/gr'
import NotificationsIcon from '@mui/icons-material/Notifications';
import Person2Icon from '@mui/icons-material/Person2';
import AddIcon from '@mui/icons-material/Add';
import Preloading from '../../Global/Preload/Preloading';
import DataGridHeader from '../Grid/DataGridHeader';
import AdvanceSearch from './Components/AdvanceSearch';
import helpers from '../../assets/js/helper';
import { toast } from 'react-toastify';
import {useLocation, useNavigate} from 'react-router-dom'
import { useTranslation } from "react-i18next";
import RolesOfUserAPI from '../../Global/Network/RoleUser';
import GetAppIcon from '@mui/icons-material/GetApp';
import { useGlobalContext } from '../../context'

const urlParams = new URLSearchParams(window.location.search);
var customerFromUrl = urlParams.get('id');
var financialCustomerID = urlParams.get('financialCustomerID');


const iconButtonSx = {
   backgroundColor: '#349CDD',
   boxShadow: '0px 3px 6px #00000029',
   marginRight: '10px',
   "&:hover": {
      backgroundColor: '#349CDDA0',
   }
}
const NavBarHeight = 100;
const FooterHeight = 0;
const RegisteredCustomers = () => {
  const [t] = useTranslation("common");
   const [pageCount, setPageCount] = useState(0)
   const [page, setPage] = useState(1)
   const [pageSize, setPageSize] = useState(5)

   const [rows, setRows] = useState([])
   const [loading, setLoading] = useState(false)
   const controller = new AbortController();
   const [openFilter,setOpenFIlter]=useState(false)
   const navigate=useNavigate();
   const [customerName, setCustomerName] = useState(null);
   const [customerPhone, setCustomerPhone] = useState(null);
   const [fromDate, setFromDate] = useState(null);
   const [toDate, setToDate] = useState(null);
   const [customerType,setCustomerType]=useState([])
   const [selectedCustomerType,setSelectedCustomerType]=useState(null)
   const [workType, setWorkType] = useState([]);
   const [selectedWorkType, setSelectedWorkType] = useState(null);
   const [salseEmployee, setSalseEmployee] = useState(null);
   const [PurposeOfPurchases, setPurposeOfPurchases] = useState([]);
   const [selectedPurposeOfPurchases, setSelectedPurposeOfPurchases] = useState(null);
   const [enableAdvanceSearch, setEnableAdvancedSearch] = useState(false);
   const [selectedWorkSide, setSelectedWorkSide] = useState(null);
   const [companyName, setCompanyName] = useState(null);
   const [openFormDatePicker, setOpenFormDatePicker] = useState(false);
   const [openToDatePicker, setOpenToDatePicker] = useState(false);
   const [employeeList,setEmployeeList]=useState([]);
   const [selectedEmployee,setSelectedEmployess]=useState();
   const [customerFromState,setSelectCustomerFormState]=useState(null)
   const [customerStatus,setCustomerStatus]=useState(null)
   const [rowsTotal,setRowsTotal]=useState(0)
   const {state}=useLocation()
   const [ClickedCollapse,setClickedCollapsed]=useState(null)
   const [userRolesHere,setUserRolesHere]=useState([
      {
        view:true,
        id:15
      },
      {
      add:false,
      id:14,
      },
      {
      update:false,
      id:16,
      },
      {
      delete:false,
      id:17,
      },{
      export:false,
      id:188
      },
      {
         viewCalls:false,
         id:105
      },
      {
         viewContract:false,
         id:176
      },
         {
            addContract:false,
            id:177
         },
         {
            viewCustomerFinancial:false,
            id:112
         },
   ])
   const { exportToCSV} = useGlobalContext()

   const columns = [
      { title: t('CUSTOMER.grid_customer_name') },
      { title: t('CUSTOMER.grid_customer_phone') },
      { title: t('CUSTOMER.grid_customer_address') },
      { title: t('CUSTOMER.grid_customer_relative') },
   ]

   const ApplyAdvanceSearch=()=>{
     let data={}

     if(customerName){
        data={
            ...data,
            search_text:customerName,
        }
     }
     if(customerPhone){
         data={
            ...data,
            phone:customerPhone,
         }
     }
     if(fromDate){
      data={
         ...data,
         creation_date_from:helpers.formatDate(fromDate),
      }
     }
     if(toDate){
         data={
            ...data,
         creation_date_to:helpers.formatDate(toDate),
     }
     }
     if(selectedCustomerType){
      data={
         ...data,
         customer_type_id:selectedCustomerType,
      }
     }
     if(selectedWorkType){
      data={
         ...data,
         working_type_id:selectedWorkType,
      }
     }
     if(selectedEmployee){
      data={
         ...data,
         employee_id:selectedEmployee,
      }
     }
     if(selectedPurposeOfPurchases){
      data={
         ...data,
         purpose_of_purchase_id:selectedPurposeOfPurchases,
      }
     }
     if(customerStatus!=null){
      data={
         ...data,
         customer_status_id:customerStatus?1:0
      }
     }
 
     loadData(data)
    }
    useEffect(()=>{
      // console.log('hfkjhaskldf',state)
       if(state?.data&&state?.data?.customer){
        setSelectCustomerFormState(state?.data?.customer)
       }
    },[state])

   useEffect(() => {
     const abortController=new AbortController()
      if(customerFromState&&customerFromState?.id){
      loadData({
         customer_id:customerFromState?.id
      })
      }else if(customerFromUrl){
         loadData({
            customer_id:customerFromUrl
         })
      }else if(financialCustomerID){
         loadData({
            customer_id:financialCustomerID
         })
      }else{
         loadData()
      }

      return () => {
         abortController.abort()
         customerFromUrl=null
         financialCustomerID=null
      }
   }, [page,pageSize,customerFromState])
   useEffect(()=>{
     const abortController=new AbortController()
      getUserRoles()

      return () => {
         abortController.abort()
      }
   },[])
   const loadData = async (searchParams) => {
      setLoading(true)

      const result = await customerAPI.customers({
         params: {
            page_size: pageSize,
            page: page,
            ...searchParams
         },
         signal: controller?.signal
      })

      setLoading(false)
      if (result.status) {
         let arr=[]
         result?.data?.data&&result?.data?.data?.length>0&&result?.data?.data?.map((itm)=>{
            if(itm?.id==ClickedCollapse){
               arr.push({
                  ...itm,
                  showCollapse:true,
               })
            }else{
               arr.push({
                  ...itm,
                  showCollapse:false,
               })
            }
         })
         setRows(arr)
         setRowsTotal(result?.data?.total)
         await getUserRoles()
         // console.log("pageCount",result?.data?.last_page);
         // console.log("page",result?.data?.current_page);
         setPageCount(result?.data?.last_page)
         // setPage(result?.data?.current_page)

      }
      else {
         // alert('3')

         if(!result?.data?.error)
         {
          toast.error("خطء غير معروف")
          return
         }
      }

   }

   const goTOAddNewCustomerPage=()=>{
      navigate('/admin/registered-customers/add',{
         state:{
            data:{
               customer:null
            }
         }
      })
   }
   const getUserRoles=async()=>{
     try{ 
      setLoading(true)
      let data=await RolesOfUserAPI.GetRolesOfUser();
      if(data&&data?.status){
        if(data?.data&&data?.data?.length>0){
            let arr=[];
            
             let checkHasRole=data?.data?.includes(userRolesHere[0]?.id)
              if(checkHasRole){
                arr.push(
                    {
                      view:true,
                      id:userRolesHere[0]?.id,
                    }
                )
              }else{
                arr.push(
                      {
                        view:false,
                        id:userRolesHere[0]?.id,
                      }
                   )
              }
              checkHasRole=data?.data?.includes(userRolesHere[1]?.id)
              if(checkHasRole){
                 arr.push(
                    {
                      add:true,
                      id:userRolesHere[1]?.id,
                    }
                 )
              }else{
                arr.push(
                      {
                        add:false,
                        id:userRolesHere[1]?.id,
                      }
                   )
              }
              checkHasRole=data?.data?.includes(userRolesHere[2]?.id)
              if(checkHasRole){
                 arr.push(
                    {
                      update:true,
                      id:userRolesHere[2]?.id,
                    }
                 )
              }else{
                  arr.push(
                      {
                        update:false,
                        id:userRolesHere[2]?.id,
                      }
                   )
              }
              checkHasRole=data?.data?.includes(userRolesHere[3]?.id)
              if(checkHasRole){
                 arr.push(
                    {
                      delete:true,
                      id:userRolesHere[3]?.id,
                    }
                 )
              }else{
                  arr.push(
                      {
                        delete:false,
                        id:userRolesHere[3]?.id,
                      }
                   )
              }
              checkHasRole=data?.data?.includes(userRolesHere[4]?.id)
              if(checkHasRole){
                 arr.push(
                    {
                      export:true,
                      id:userRolesHere[4]?.id,
                    }
                 )
              }else{
                  arr.push(
                      {
                        export:false,
                        id:userRolesHere[4]?.id,
                      }
                   )
              }
              checkHasRole=data?.data?.includes(userRolesHere[5]?.id)
              if(checkHasRole){
                 arr.push(
                    {
                      viewCalls:true,
                      id:userRolesHere[5]?.id,
                    }
                 )
              }else{
                  arr.push(
                      {
                        viewCalls:false,
                        id:userRolesHere[5]?.id,
                      }
                   )
              }

              checkHasRole=data?.data?.includes(userRolesHere[6]?.id)
              if(checkHasRole){
                 arr.push(
                    {
                     viewContract:true,
                      id:userRolesHere[6]?.id,
                    }
                 )
              }else{
                  arr.push(
                      {
                        viewContract:false,
                        id:userRolesHere[6]?.id,
                      }
                   )
              }

              checkHasRole=data?.data?.includes(userRolesHere[7]?.id)
              if(checkHasRole){
                 arr.push(
                    {
                      addContract:true,
                      id:userRolesHere[7]?.id,
                    }
                 )
              }else{
                  arr.push(
                      {
                        addContract:false,
                        id:userRolesHere[7]?.id,
                      }
                   )
              }
              checkHasRole=data?.data?.includes(userRolesHere[8]?.id)
              if(checkHasRole){
                 arr.push(
                    {
                      viewCustomerFinancial:true,
                      id:userRolesHere[8]?.id,
                    }
                 )
              }else{
                  arr.push(
                      {
                        viewCustomerFinancial:false,
                        id:userRolesHere[8]?.id,
                      }
                   )
              }
            setUserRolesHere(arr)
        }
      }
      setLoading(false)
     }catch(err){
       setLoading(false)
       console.log(err?.message);
       
     }
   }
   const loadDataForExport = async (searchParams) => {
      let  result = await customerAPI.customers({
         params: {
            page_size: rowsTotal,
            ...searchParams
         },
         signal: controller?.signal
      })
      return result;
    }
   const exportFunction=async () => {
      let data={}
      if(customerName){
         data={
             ...data,
             search_text:customerName,
         }
      }
      if(customerPhone){
          data={
             ...data,
             phone:customerPhone,
          }
      }
      if(fromDate){
       data={
          ...data,
          creation_date_from:helpers.formatDate(fromDate),
       }
      }
      if(toDate){
          data={
             ...data,
          creation_date_to:helpers.formatDate(toDate),
      }
      }
      if(selectedCustomerType){
       data={
          ...data,
          customer_type_id:selectedCustomerType,
       }
      }
      if(selectedWorkType){
       data={
          ...data,
          working_type_id:selectedWorkType,
       }
      }
      if(selectedEmployee){
       data={
          ...data,
          employee_id:selectedEmployee,
       }
      }
      if(selectedPurposeOfPurchases){
       data={
          ...data,
          purpose_of_purchase_id:selectedPurposeOfPurchases,
       }
      }
      if(customerStatus!=null){
       data={
          ...data,
          customer_status_id:customerStatus?1:0
       }
      }
    
      const fetchedData =await loadDataForExport(data);
      if(!fetchedData||!fetchedData?.status){
        toast.error("لا يمكن استرجاع البيانات")
         return
      }
    
      
      let arr=[];
      fetchedData?.data?.data&&fetchedData?.data?.data?.map((itm)=>{
         let paymentsList=''
         itm?.payment_types&&itm?.payment_types?.map((pay)=>{
            paymentsList+=pay?.name+' , ';
         })

         let realEstatesList=''
         itm?.real_estates&&itm?.real_estates?.map((rel)=>{
            realEstatesList+=rel?.name+' , ';
         })
         
         let compoundsList=''
         itm?.compounds&&itm?.compounds?.map((com)=>{
            compoundsList+=com?.name+' , ';
         })

       arr.push({
         [`${t('CUSTOMER.id')}`]:itm?.id,
         [`${t('CUSTOMER.customer_name')}`]:itm?.user?.full_name,
         [`${t('CUSTOMER.AcademicAchievement')}`]:itm?.academic_achievement,
         [`${t('CUSTOMER.maritalStatus')}`]:itm?.marital_status?.name,
         [`${t('CUSTOMER.workType')}`]:itm?.working_type?.name,
         [`${t('CUSTOMER.workPlace')}`]:itm?.work_place_name,
         [`${t('CUSTOMER.callsType')}`]:itm?.contact_method?.name,
         [`${t('CUSTOMER.governorate')}`]:itm?.addresses[0]?.governorate?.name,
         [`${t('CUSTOMER.region')}`]:itm?.addresses[0]?.name,
         [`${t('CUSTOMER.nearPoint')}`]:itm?.addresses[0]?.address,
         [`${t('CUSTOMER.firstPhone')}`]:itm?.addresses[0]?.first_phone,
         [`${t('CUSTOMER.secondPhone')}`]:itm?.addresses[0]?.second_phone,
         [`${t('CUSTOMER.thirdPhone')}`]:itm?.addresses[0]?.third_phone,
         [`${t('CUSTOMER.gender')}`]:itm?.gender==1?"أنثى":"ذكر",
         [`${t('CUSTOMER.grid_customer_relative')}`]:itm?.employee?.user?.full_name,
         [`${t('CUSTOMER.selectedPurposeOfSell')}`]:itm?.purpose_of_purchase?.name,
         [`${t('CUSTOMER.purpose_of_purchase_note')}`]:itm?.purpose_of_purchase_note,
         [`${t('CUSTOMER.selectedFavoritePaymentMethods')}`]:paymentsList,
         [`${t('CUSTOMER.selectedWorkType')}`]:itm?.customer_type?.name,
         [`${t('CUSTOMER.selectedTypeOfIterestRealEstate')}`]:realEstatesList,
         [`${t('CUSTOMER.selectedTypeOfInterestCompound')}`]:compoundsList,

         [`${t('CUSTOMER.created_at')}`]:itm?.created_at?helpers.formatDate(itm?.created_at)+" "+helpers.formatTime(itm?.created_at):'0000-00-00 00:00:00',
       })
     })
     exportToCSV(arr,'ROLE')
   }
   
   return (
      <Box 
      sx={{
         display: 'flex',
         flexDirection: 'column',
         width: '100%',
         height: '100%',
         justifyContent: 'center',
         alignItems: 'stretch'
      }}
        className=" pt-3 mt-1"
      >
         <Box
            sx={{
               display: 'flex',
               alignItems: 'center',
               justifyContent: 'space-between',
               flex: 1

            }}
         >
            <Typography
               variant='h4'
               sx={{
                  marginLeft: '25px',
                  marginBottom: '10px',
                  fontFamily: 'Cairo-Bold',
                  fontSize: '16px'
               }}>
               {t('CUSTOMER.register_customer')}
            </Typography>
            <Box
               sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  marginRight: '16px'
               }}
            >
               {
                userRolesHere[1]?.add?
               <IconButton
                  sx={iconButtonSx}
                  onClick={goTOAddNewCustomerPage}
               >
                  <AddIcon sx={{ color: '#FFFFFF' }} />
               </IconButton>:null
               }
               
               <IconButton
                  sx={iconButtonSx}
                  onClick={exportFunction}
               >
                  <GetAppIcon sx={{ color: '#FFFFFF' }} />
               </IconButton>
                {/*
               <IconButton
                  sx={iconButtonSx}
               >
                  <NotificationsIcon sx={{ color: '#FFFFFF' }} />
               </IconButton> */}

               <IconButton
                  sx={iconButtonSx}
                  onClick={()=>setOpenFIlter(true)}
               >
                  <RiSearchFill color='#FFFFFF' />
               </IconButton>
            </Box>
         </Box>

         <Divider
            sx={{
               backgroundColor: '#EBF2F7',
               margin: '30px',
               marginTop:'15px'
            }}
            style={{
               height: '3px'
            }}
         />
         {
         loading ? <Preloading /> :
          <>
            <Box sx={{
               backgroundColor: '#FFFFFF',
               overflow: 'auto',
               height: `calc(100vh - ${NavBarHeight + FooterHeight + 170}px)`, width: '100%'
            }}>
               <DataGridHeader columns={columns} />

               {rows?.map((row, index) => <DataGridRow 
               loadData={loadData} 
               row={row} 
               rows={rows} 
               setRows={setRows}
               key={index + "_" + row?.id + "_customer"} 
               pageNumber={page}
               userRolesHere={userRolesHere}
               setClickedCollapsed={setClickedCollapsed}
               ClickedCollapse={ClickedCollapse}
               customerFromUrl={financialCustomerID}
               />)}

            </Box>
            <Box>
               <CustomFooter
                  pageCount={pageCount}
                  page={page}
                  setPage={setPage}
                  loadData={loadData}
                  setPageSize={setPageSize}
                  pageSize={pageSize}
               />
            </Box>
         </>
         }
         <AdvanceSearch 
            openFilter={openFilter}
            setOpenFIlter={setOpenFIlter}
            customerName={customerName}
            setCustomerName={setCustomerName}
            customerPhone={customerPhone}
            setCustomerPhone={setCustomerPhone}
            fromDate={fromDate}
            setFromDate={setFromDate}
            toDate={toDate}
            setToDate={setToDate}
            customerType={customerType}
            setCustomerType={setCustomerType}
            selectedCustomerType={selectedCustomerType}
            setSelectedCustomerType={setSelectedCustomerType}
            workType={workType}
            setWorkType={setWorkType}
            selectedWorkType={selectedWorkType}
            setSelectedWorkType={setSelectedWorkType}
            salseEmployee={salseEmployee}
            setSalseEmployee={setSalseEmployee}
            PurposeOfPurchases={PurposeOfPurchases}
            setPurposeOfPurchases={setPurposeOfPurchases}
            selectedPurposeOfPurchases={selectedPurposeOfPurchases}
            setSelectedPurposeOfPurchases={setSelectedPurposeOfPurchases}
            enableAdvanceSearch={enableAdvanceSearch}
            setEnableAdvancedSearch={setEnableAdvancedSearch}
            companyName={companyName}
            setCompanyName={setCompanyName}
            openFormDatePicker={openFormDatePicker}
            setOpenFormDatePicker={setOpenFormDatePicker}
            openToDatePicker={openToDatePicker}
            setOpenToDatePicker={setOpenToDatePicker}
            employeeList={employeeList}
            setEmployeeList={setEmployeeList}
            selectedEmployee={selectedEmployee}
            setSelectedEmployess={setSelectedEmployess}
            ApplyAdvanceSearch={ApplyAdvanceSearch}
            customerStatus={customerStatus}
            setCustomerStatus={setCustomerStatus}
         />
      </Box >
   )
}

export default RegisteredCustomers