const CustomerErrors = {
    id:"",
    full_name: {
        value: "",
        error: false,
        message: "",
        required: false
    },
    academic_achievement: {
        value: "",
        error: false,
        message: "",
        required: false
    },
    marital_status_id: {
        value: "",
        error: false,
        message: "",
        required: false
    },
    working_type_id: {
        value: "",
        error: false,
        message: "",
        required: false
    },
    work_place_name: {
        value: "",
        error: false,
        message: "",
        required: false
    },
    contact_method_id: {
        value: "",
        error: false,
        message: "",
        required: false
    },
    address_governorate_id: {
        value: "",
        error: false,
        message: "",
        required: false
    },
    address_name: {
        value: "",
        error: false,
        message: "",
        required: false
    },
    address: {
        value: "",
        error: false,
        message: "",
        required: false
    },
    address_first_phone: {
        value: "",
        error: false,
        message: "",
        required: false
    },   
    address_second_phone: {
        value: "",
        error: false,
        message: "",
        required: false
    },
    employee_id: {
        value: "",
        error: false,
        message: "",
        required: false
    },   
    purpose_of_purchase_id: {
        value: "",
        error: false,
        message: "",
        required: false
    },
    purpose_of_purchase_note: {
        value: "",
        error: false,
        message: "",
        required: false
    },   
    customer_type_id: {
        value: "",
        error: false,
        message: "",
        required: false
    },
    real_estate_ids: {
        value: "",
        error: false,
        message: "",
        required: false
    },   
    compound_ids: {
        value: "",
        error: false,
        message: "",
        required: false
    },
    payment_type_ids: {
        value: "",
        error: false,
        message: "",
        required: false
    },
    interest_degree: {
        value: "",
        error: false,
        message: "",
        required: false
    },
    contacts_name: {
        value: "",
        error: false,
        message: "",
        required: false
    },
    contacts_address: {
        value: "",
        error: false,
        message: "",
        required: false
    },
    contacts_phone: {
        value: "",
        error: false,
        message: "",
        required: false
    },
    contacts_relation_type_id: {
        value: "",
        error: false,
        message: "",
        required: false
    },
    phone: {
        value: "",
        error: false,
        message: "",
        required: false
    },
    username: {
        value: "",
        error: false,
        message: "",
        required: false
    },
    password: {
        value: "",
        error: false,
        message: "",
        required: false
    },
}
// export const filterobject = 
// {
//   month: {
//     value: {
//       id: "",
//       name: ""
//     },
//     error: false,
//     message: "",
//     required: false
//   },
//   year: {
//     value: "",
//     error: false,
//     message: "",
//     required: false
//   },
//   customer_level_id: {

//     value: {
//       id: "",
//       name: ""
//     },
//     error: false,
//     message: "",
//     required: false
//   },
//   gender_id: {
//     value: {
//       id: "",
//       name: ""
//     },
//     error: false,
//     message: "",
//     required: false
//   },
//   governorate_id: {
//     value: {
//       id: "",
//       name: ""
//     },
//     error: false,
//     message: "",
//     required: false
//   },
// }
export const resetErrors = (customerObject) => {
    var objectKeys = Object.keys(customerObject);
   
    var updatedObject = {
        ...customerObject
    }
    try {
        objectKeys.filter(key=>key!=="id").map((key) => {
        
            updatedObject[key]["error"] = false
            updatedObject[key]["message"] = ""
        })
    } catch (error) {
        
    }
    
    return updatedObject

}
export const checkErrors = (errorMessages, customerObject) => {

    const errorMessagesKeys = Object.keys(errorMessages);
    errorMessagesKeys.map((key) => {
        if(!key?.includes('contacts')){
        let messages = []
        errorMessages[key].map((message) => {
            messages.push(message)
        })
       
        customerObject[key]["error"] = true
        customerObject[key]["message"] = messages
       }
    })

  




    return customerObject
}
export const customerObjectMerge = (updatedObject) => {
    const mergedCustomerObject = {
    id:updatedObject?.id,
    full_name: {
        value: updatedObject?.full_name,
        error: false,
        message: "",
        required: false
    },
    academic_achievement: {
        value: updatedObject?.academic_achievement,
        error: false,
        message: "",
        required: false
    },
    marital_status_id: {
        value: updatedObject?.marital_status_id,
        error: false,
        message: "",
        required: false
    },
    working_type_id: {
        value: updatedObject?.working_type_id,
        error: false,
        message: "",
        required: false
    },
    work_place_name: {
        value: updatedObject?.work_place_name,
        error: false,
        message: "",
        required: false
    },
    contact_method_id: {
        value: updatedObject?.contact_method_id,
        error: false,
        message: "",
        required: false
    },
    address_governorate_id: {
        value: updatedObject?.address_governorate_id,
        error: false,
        message: "",
        required: false
    },
    address_name: {
        value: updatedObject?.address_name,
        error: false,
        message: "",
        required: false
    },
    address: {
        value:updatedObject?.address,
        error: false,
        message: "",
        required: false
    },
    address_first_phone: {
        value: updatedObject?.address_first_phone,
        error: false,
        message: "",
        required: false
    },   
    address_second_phone: {
        value: updatedObject?.address_second_phone,
        error: false,
        message: "",
        required: false
    },
    employee_id: {
        value: updatedObject?.employee_id,
        error: false,
        message: "",
        required: false
    },   
    purpose_of_purchase_id: {
        value: updatedObject?.purpose_of_purchase_id,
        error: false,
        message: "",
        required: false
    },
    purpose_of_purchase_note: {
        value: updatedObject?.purpose_of_purchase_note,
        error: false,
        message: "",
        required: false
    },   
    customer_type_id: {
        value: updatedObject?.customer_type_id,
        error: false,
        message: "",
        required: false
    },
    real_estate_ids: {
        value: updatedObject?.real_estate_ids,
        error: false,
        message: "",
        required: false
    },   
    compound_ids: {
        value: updatedObject?.compound_ids,
        error: false,
        message: "",
        required: false
    },
    payment_type_ids: {
        value: updatedObject?.payment_type_ids,
        error: false,
        message: "",
        required: false
    },
    interest_degree: {
        value: updatedObject?.interest_degree,
        error: false,
        message: "",
        required: false
    },
    contacts_name: {
        value: updatedObject?.contacts_name,
        error: false,
        message: "",
        required: false
    },
    contacts_address: {
        value: updatedObject?.contacts_address,
        error: false,
        message: "",
        required: false
    },
    contacts_phone: {
        value: updatedObject?.contacts_phone,
        error: false,
        message: "",
        required: false
    },
    contacts_relation_type_id: {
        value: updatedObject?.contacts_relation_type_id,
        error: false,
        message: "",
        required: false
    },
    phone: {
        value: updatedObject?.phone,
        error: false,
        message: "",
        required: false
    },
    username: {
        value: updatedObject?.username,
        error: false,
        message: "",
        required: false
    },
    password: {
        value: updatedObject?.password,
        error: false,
        message: "",
        required: false
    },
    }
    
    return mergedCustomerObject;
}
export const restCustomeObject=(customerObject,obj)=>{
    // var objectKeys = Object.keys(customerObject);
   
    try {
        
        customerObject[obj]["error"] = false
        customerObject[obj]["message"] = ""
    } catch (error) {
        
    }
    
    return customerObject
}
export default CustomerErrors;