import { Tooltip } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { styled, alpha } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { red } from "@mui/material/colors";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShareIcon from "@mui/icons-material/Share";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import ArchiveIcon from "@mui/icons-material/Archive";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useTranslation } from "react-i18next";
import { setGlobalLoading } from "../../../reduxStore/SettingsReducer";
import { useDispatch, useSelector } from "react-redux";
import entityAPI from "../../../Global/Network/Entity";
import Swal from "sweetalert";
import { toast } from "react-toastify";
import UnitStatusAPI from "../../../Global/Network/UnitStatuses";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import { async } from "q";
import unitAPI from "../../../Global/Network/Unit";
import CustomTextField from "../../../General/CustomComponents/CustomTextField";
import helpers from '../../../assets/js/helper'
import * as CgIcons from 'react-icons/cg'
import * as TbIcons from 'react-icons/tb'
import * as VscIcons from 'react-icons/vsc'
import {MdCancel} from 'react-icons/md'
import UnitTabs from "./UnitTabs";
import AssingCustomerToUnit from "./AssingCustomerToUnit";
import AddFormDailog from '../../Masters/Orders/AddFormDailog'
import GavelIcon from '@mui/icons-material/Gavel';
import { useNavigate } from "react-router-dom";
import orderAPI from "../../../Global/Network/Orders";
import swal from "sweetalert";
import { BsPrinter } from 'react-icons/bs'

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    padding:'10px 5px',
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

export default function LeftSide(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl2, setAnchorEl2] = React.useState(null);

  const open = Boolean(anchorEl);
  const open2 = Boolean(anchorEl2);

  const [t] = useTranslation("common");
  const [unitStatuses, setUnitStatuses] = useState([]);
  const [selectedUnitStatus, setSelectedUnitStatus] = useState(null);
  const screenwidth=useSelector(state=>state.settingsData.screenwidth)
  const [oepnAssignForm,setOepnAssignForm]=useState(false)
  const [timeDown,setTimeDown]=useState(null)
  const [timeInMinutesFroAPI,setTimeInMinutesFroAPI]=useState(props?.UnitData?.unit_status?.duration_in_mins)
  const [startDateForUpdate,setStartDateForUpdate]=useState(null)
  const globalloading=useSelector((state)=>state.settingsData.globalloading)
  const [showOrderForm,setShowOrderForm]=useState(false);
  const [showInvoicePage,setShowInvoicePage]=useState(false);

  const [ListOfLogs,setListOfLogs]=useState([])

  const navigate=useNavigate()
  const dispatch = useDispatch();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  useEffect(() => {
    const abortController = new AbortController();
      loadDataOfUnitStatus();
      if(props?.UnitData?.unit_status?.has_customer&&!props?.UnitData?.temp_customer_id){
        setOepnAssignForm(true)
      }
      return () => {
        abortController.abort();
      };
  }, [props?.UnitData]);
  //get data of units status.
  const loadDataOfUnitStatus = async () => {
    let data = null;
    try {
      data = await UnitStatusAPI.UnitStatusList({
        params:{
          list_mode:2,
        }
      });
      if (data && data?.status) {
        setUnitStatuses(data?.data);
      } else {
        toast.success(t("NETWORKMESSAGE.addCustomerMessageError"));
      }
    } catch (err) {
      console.log(err?.message);
    }
  };
  //change status of units
  const changeUnitStatus = async (itm) => {
    let result = null;
    setSelectedUnitStatus(itm)
    setAnchorEl(null)
    setOepnAssignForm(true)
    // if (itm?.id) {
    //   Swal({
    //     title: "?هل انت متأكد",
    //     text: "لن تتمكن من التراجع عن هذا!",
    //     icon: "warning",
    //     buttons: ["الغاء!", "نعم!"],
    //     dangerMode: true,
    //   }).then(async (willDelete) => {
    //     if (willDelete) {
    //       try {
    //         let data = {
    //           unit_status_id: itm?.id,
    //           _method: "put",
    //         };
    //         // data.append("_method", "put");
    //         result = await unitAPI.updateUnit({
    //           data: data,
    //           id: props?.UnitId,
    //         });
    //         if (result && result?.status) {
    //           props?.loadDataOfUnit()
    //           setAnchorEl(null);
    //           toast.success(t("NETWORKMESSAGE.UpdateCustomerMessageSuccess"));
    //         } else {
    //           setAnchorEl(null);
    //           toast.success(t("NETWORKMESSAGE.addCustomerMessageError"));
    //         }
    //       } catch (err) {
    //         console.log(err?.message);
    //         setAnchorEl(null);
    //       }
    //     } else {
    //       // alert('canceled')
    //       setAnchorEl(null);
    //     }
    //   });
    // }
  };

  const getBackgroundColor=(itm)=>{
    if(props?.UnitData?.unit_status?.id==itm?.id){
        return `${itm?.color}36 !important`;
    }else return 'transparent';
  }

  const getBackgroundColorForMenuButton=(itm)=>{
    if(props?.UnitData?.unit_status?.color)
        return `${props?.UnitData?.unit_status?.color}a6 !important`;
    else return '#1e6a99 !important';
  }
  const getDateForUpdatedStatus=()=>{
    let arr=[]
    props?.UnitData?.effected_activities?.length>0&&props?.UnitData?.effected_activities?.map((itm)=>{
       if(itm?.activity_log_type_id==1){
        arr.push(itm)
       }
    })
    console.log('ddddddddd')
    let date=null
    if(arr&&arr?.length){
      arr.sort((a,b) => (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0));
      date= new Date(arr[arr?.length-1]?.created_at)
      // console.log('created_date=>',arr[arr?.length-1]?.created_at)
    }
    return date//for test only this value.

  }

  const checkUnitStatusIsTemporay=()=>{
      let check=false;
      if(props?.UnitData&&props?.UnitData?.unit_status?.id>4){
        check=true;
      }
      return check;
  }
  
  // make interval when I get unit status and duration from api (and should id of status greater from 4 )
  useEffect(()=>{
    handleGetLogs()
    console.log('asdasdasd',props?.UnitData?.unit_status?.duration_in_mins)
    if(props?.UnitData?.unit_status?.duration_in_mins>0&&checkUnitStatusIsTemporay()&&getDateForUpdatedStatus()){

    const interval= setInterval(() => {
      let timeInSeconds=(props?.UnitData?.unit_status?.duration_in_mins*60)-(getSecondDiff(getDateForUpdatedStatus(),new Date()))
        console.log('asdasdasdas',timeInSeconds,' ',)
      if(timeInSeconds<=0){
        clearInterval(interval);
        handleSetUnitStatusToBeAvalable()
      }
      setTimeDown(helpers.getFormatTimeCounDown(timeInSeconds))//send time in second format.
   

    }, 1000);
    return()=>{
      clearInterval(interval);
    }
   }else{
    setTimeDown(null)
   }
  },[props?.UnitData])
   
  // get different time in seconds formate between two dates.
  function getSecondDiff(startDate, endDate) {
    return Math.floor((endDate.getTime() - startDate.getTime()) / 1000);
  }

  // rest unit status to be available once the timer ends or when the user clicks to cancel the booking.
  const handleSetUnitStatusToBeAvalable=async()=>{
    try {
      dispatch(setGlobalLoading(true))
       let result = null;
        var params={
            // temp_customer_id: null,
            unit_status_id: 1,
          }
        

        // data.append('_method','put')
        result = await unitAPI.updateUnitStatus({
          params: params,
          id:props?.UnitId,
        });

      if (result?.status) {
        toast.success(t("NETWORKMESSAGE.addCustomerMessageSuccess"));
        props?.loadDataOfUnit();
      } else {
        if(result?.error){
          toast.error(result?.errorMessage);
        }else
        toast.error(t("NETWORKMESSAGE.addCustomerMessageError"));
      }
      dispatch(setGlobalLoading(false))
    } catch (error) {
      dispatch(setGlobalLoading(false))
      toast.error(error?.message);
    }
  }

  //show popup for create order.
 const handleCreateOrder=()=>{
  setShowOrderForm(true)
  handleClose2()
 }
  //show popup for create invoce if hase order.
 const handleMakeInvoice=()=>{
    setShowInvoicePage(true)
    navigate('/admin/order_contract', {
      state: {
        data: {
          order: props?.UnitData?.orders[props?.UnitData?.orders?.length-1]
        }
      }
    })

    handleClose2()
 }
 const checkOrderFound=()=>{
    let check=false;
    if(props?.UnitData?.orders&&props?.UnitData?.orders?.length>0){
      if(props?.UnitData?.orders[props?.UnitData?.orders?.length-1]?.order_status_id==1||props?.UnitData?.orders[props?.UnitData?.orders?.length-1]?.order_status_id==2){
        check=true;
      }
    }else if(props?.UnitData?.orders?.length==0){
      check='empty'
    }

    return check
 }
 
 //get list of Logs.
 const handleGetLogs=()=>{
    let arr=[]
    props?.UnitData?.effected_activities&&props?.UnitData?.effected_activities?.map((itm)=>{
      arr.push({
        ...itm,
        type:1//means is effective
      })
    })

    props?.UnitData?.related_activities&&props?.UnitData?.related_activities?.map((itm)=>{
      arr.push({
        ...itm,
        type:2//means is relative
      })
    })
    arr.sort(function (a, b) {
      var dateA = new Date(a.created_at), dateB = new Date(b.created_at)
      return dateB - dateA
    });


    setListOfLogs(arr)
 }

 const CancelOrder=async(obj)=>{
  swal({
    title: '?هل انت متأكد',
    text: "لن تتمكن من التراجع عن هذا!",
    icon: 'warning',
    buttons: ["الغاء!", "نعم!"],
    dangerMode: true,
  }).then(async(willDelete) => {
    if (willDelete) {

      try{
          dispatch(setGlobalLoading(true))
          let result=null;
          if(obj&&obj?.id){
              result=await orderAPI.cancelOrder({
              data:{
                id:obj.id,
              },
              params:{
                order_status_id:3
              }
              });
          }

          if(result?.status){
            // toast.success(t('NETWORKMESSAGE.deleteSuccess'))
            swal(`${t('NETWORKMESSAGE.canceledSuccess')}`, {
              icon: "success",
            });
            props?.loadDataOfUnit()
          }else{
            toast.error(t('NETWORKMESSAGE.deleteErrorr'))
          }
          dispatch(setGlobalLoading(false))

      }catch(error){
          toast.error(t(error?.message))
          dispatch(setGlobalLoading(false))
      }
   }else{
    // alert('canceled')
    dispatch(setGlobalLoading(true))

   }
  })
 }

 const handleShowEmptyPoPup=()=>{
  let check=false;
   if(

     ( ( !checkUnitStatusIsTemporay()&&!props?.UnitData?.unit_status?.id==1&&checkOrderFound()&&checkOrderFound()!=='empty')||!props?.userRolesHere[6]?.addOrder)//for handle add order cant show.
     &&
      (!checkOrderFound()&&checkOrderFound()=='empty'||!props?.userRolesHere[9].addContract)
     &&
       ( (!checkOrderFound()&&checkOrderFound()=='empty'&&props?.UnitData?.orders[props?.UnitData?.orders?.length-1]?.order_status_id!=1)||!props?.userRolesHere[7]?.deleteOrder )
     ){
      check=true;
   }

  return check
 }

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        paddingRight: screenwidth<1100?'0':"40px",
        // backgroundColor:'rgba(0,255,0,0.3)'
      }}
    >
      {
        showOrderForm?
      <AddFormDailog  customer={checkUnitStatusIsTemporay()?props?.UnitData?.temp_customer:null} unit={props?.UnitData} addForm={showOrderForm} setAddForm={setShowOrderForm} loadData={props?.loadDataOfUnit} />:null
      }
   
      {/* appbar for this section  */}
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          margin: "15px  15px 15px 5px",
          padding: "0 20px 0 10px",
          position:'relative',
        }}
      >
        <Box
          sx={{
            // backgroundColor:'#ie6a99',
            // width: "30px",
            // height: "30px",
            display:'flex',
            // justifyContent:'center',
            alignItems:'center',
            borderRadius: "50%",
          }}
        >
        {
        <Tooltip
          title={
            <span style={{ fontFamily: "Cairo" }}>
              {t("UNIT.ShowUnitMenu")}
            </span>
          }
          arrow
        >
          <IconButton
            sx={{
              backgroundColor: '#1e6a99',
              width: "35px",
              height: "35px",
              borderRadius: "50%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              "&:hover": {
                backgroundColor:'#1e6a99',
              },
            }}
            aria-label="settings"
            id="demo-customized-button"
            aria-controls={open ? "demo-customized-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            disableElevation
            onClick={handleClick2}
          >
            <CgIcons.CgMenuRight
              style={{
                color: "#fff",
                fontSize: "30px",
              }}
            />
          </IconButton>
        </Tooltip>
        }
       {checkUnitStatusIsTemporay()&&props?.userRolesHere[5]?.editUnitStatus?
        <IconButton
         sx={{
          backgroundColor:'rgba(194, 21, 53,0.9)',
          boxShadow:'0 0 15px -2px rgba(194, 21, 53,0.5)',
          display:'flex',
          justifyContent:'center',
          alignItems:'center',
          borderRadius:'20px',
          padding:'0 5px',
          minWidth:'90px',
          height:'35px',
          margin:'0 10px',
          cursor:'pointer',
          "&:hover":{
            backgroundColor:'rgba(194, 21, 53,0.9)',
          }
         }}
         className="animationButton"
         onClick={handleSetUnitStatusToBeAvalable}
        >
          <span style={{
            fontFamily:'Cairo-Medium',color:'#fff',fontSize:'14px'}}>
            {t('GLOBAL.cancelBooking')}
          </span>
        </IconButton>:null}

        </Box>
      
        <Box
          sx={{
            backgroundColor: "#ie6a99",
            width: "fit-content",
            height: "fit-content",
            borderRadius: "50%",
          }}
        >
          <span
            style={{
              fontFamily: "Cairo-Bold",
              color: "#000",
              fontSize: "21px",
            }}
          >
            {t("UNIT.unitsInfo")}
          </span>
        </Box>
        <Box
          sx={{
            // backgroundColor:'#ie6a99',
            // width: "30px",
            // height: "30px",
            display:'flex',
            justifyContent:'space-between',
            alignItems:'center',
            borderRadius: "50%",
          }}
        >
    
          {
          timeDown&&timeDown?.show?
          <Tooltip  title={<span style={{fontFamily:'Cairo',color:'#fff'}}>{t('GLOBAL.timeAvailableUntilCancelBooking')}</span>} arrow>
            <Box
              sx={{
                width:'fit-content',
                minWidth:'150px',
                height:'35px',
                borderRadius:'20px',
                backgroundColor:props?.UnitData?.unit_status?.color&&props?.UnitData?.unit_status?.color!='#ffffff'?props?.UnitData?.unit_status?.color:'lightgray',
                // position:'absolute',
                // top:'50%',
                // right:'10%',
                boxShadow:'0 0 10px -2px rgba(0,0,0,0.4)',
                display:'flex',
                justifyContent: 'center',
                alignItems:'center',
                textAlign: 'center',
                fontFamily:'Cairo-Medium',
                cursor:'pointer',
                margin:'0 10px'
              }}
            >
              <span style={{margin:'0 3px',color:'#fff'}}>{timeDown?.days} </span>
              <span style={{margin:'0 2px',color:'#fff'}}>{timeDown?.hour}:{timeDown?.min}:{timeDown?.sec}</span>

            </Box>
          </Tooltip>:null
          }
          {
            (checkUnitStatusIsTemporay()||props?.UnitData?.unit_status?.id==1)&&props?.userRolesHere[5]?.editUnitStatus?
          <Tooltip
            title={
              <span style={{ fontFamily: "Cairo" }}>
                {t("UNIT.showActionsMenu")}
              </span>
            }
            arrow
          >
            <IconButton
              sx={{
                backgroundColor: getBackgroundColorForMenuButton(),
                width: "35px",
                height: "35px",
                borderRadius: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                "&:hover": {
                  backgroundColor:getBackgroundColorForMenuButton(),
                },
              }}
              aria-label="settings"
              id="demo-customized-button"
              aria-controls={open ? "demo-customized-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              disableElevation
              onClick={handleClick}
            >
              <TbIcons.TbExchange
                style={{
                  color: "#fff",
                  fontSize: "30px",
                }}
              />
            </IconButton>
          </Tooltip>:null
          }

        </Box>

      </Box>

      {/* content  */}
      <Box className="row my-3">
        <Box className="col-12 col-md-12 col-lg-6">
          <CustomTextField
            label={t("UNIT.name")}
            value={props?.UnitData?.name}
            error={null}
            message={null}
            haswidth={true}
            readOnly={true}
          />
          
          <Box sx={{margin:'10px 0'}}></Box>
          <CustomTextField
            label={t("UNIT.block")}
            value={props?.UnitData?.block?.name}
            error={null}
            message={null}
            haswidth={true}
            readOnly={true}
          />
          
          <Box sx={{margin:'10px 0'}}></Box>
          <CustomTextField
            label={t("UNIT.floor")}
            value={props?.UnitData?.floor?.name}
            error={null}
            message={null}
            haswidth={true}
            readOnly={true}
          />
          <Box sx={{margin:'10px 0'}}></Box>
           <CustomTextField
            label={t("UNIT.unitStatus")}
            value={props?.UnitData?.unit_status?.name}
            error={null}
            message={null}
            haswidth={true}
            readOnly={true}
          />
          <Box sx={{margin:'10px 0'}}></Box>
          <CustomTextField
            label={t("UNIT.deliveryDate")}
            value={props?.UnitData?.delivery_date?helpers.formatDate(props?.UnitData?.delivery_date):t('GLOBAL.notAssingedDateYet')}
            error={null}
            message={null}
            haswidth={true}
            readOnly={true}
          />
           <Box sx={{margin:'10px 0'}}></Box>
           <CustomTextField
            label={t("UNIT.price")}
            value={props?.UnitData?.price?.format(props?.UnitData?.iqd_usd == 1 ? 2 : 0, 3, ',', '.') }
            error={null}
            message={null}
            haswidth={true}
            readOnly={true}
          />
           <Box sx={{margin:'10px 0'}}></Box>
           <CustomTextField
            label={t("UNIT.area")}
            value={props?.UnitData?.area}
            error={null}
            message={null}
            haswidth={true}
            readOnly={true}
          />
          {
            props?.UnitData?.temp_customer?.user?.full_name?
            <>
              <Box sx={{margin:'10px 0'}}></Box>
              <CustomTextField
                label={t("UNIT.temp_customer")}
                value={props?.UnitData?.temp_customer?.user?.full_name}
                error={null}
                message={null}
                haswidth={true}
                readOnly={true}
              />
            </>:null
           }
           {
            props?.UnitData?.customer?.user?.full_name?
            <>
              <Box sx={{margin:'10px 0'}}></Box>
              <CustomTextField
                label={t("UNIT.customer")}
                value={props?.UnitData?.customer?.user?.full_name}
                error={null}
                message={null}
                haswidth={true}
                readOnly={true}
              />
            </>:null
           }
            
        </Box>
        <Box className="col-12 col-md-12 col-lg-6">
        
          <CustomTextField
            label={t("UNIT.compound")}
            value={props?.UnitData?.compound?.name}
            error={null}
            message={null}
            haswidth={true}
            readOnly={true}
          />
          <Box sx={{margin:'10px 0'}}></Box>
          <CustomTextField
            label={t("UNIT.building")}
            value={props?.UnitData?.building?.name}
            error={null}
            message={null}
            haswidth={true}
            readOnly={true}
          />
          <Box sx={{margin:'10px 0'}}></Box>
          <CustomTextField
            label={t("UNIT.unitLocation")}
            value={props?.UnitData?.unit_location?.name}
            error={null}
            message={null}
            haswidth={true}
            readOnly={true}
          />
          <Box sx={{margin:'10px 0'}}></Box>
          <CustomTextField
            label={t("UNIT.unitType")}
            value={props?.UnitData?.unit_type?.name}
            error={null}
            message={null}
            haswidth={true}
            readOnly={true}
          />
          <Box sx={{margin:'10px 0'}}></Box>
          <CustomTextField
            label={t("UNIT.compoundPhase")}
            value={props?.UnitData?.compound_phase?.phase?.name}
            error={null}
            message={null}
            haswidth={true}
            readOnly={true}
          />
          <Box sx={{margin:'10px 0'}}></Box>
          <CustomTextField
            label={t("UNIT.iqd_usd")}
            value={props?.UnitData?.iqd_usd?'USD':'IQD'}
            error={null}
            message={null}
            haswidth={true}
            readOnly={true}
          />
          
          <CustomTextField
            label={t("UNIT.created_at")}
            value={props?.UnitData?.created_at?helpers.formatDate(props?.UnitData?.created_at):t('GLOBAL.notAssingedDateYet')}
            error={null}
            message={null}
            haswidth={true}
            readOnly={true}
          />
           
        </Box>
        <Box className="col-12 col-md-12 col-lg-12 my-2">
          <CustomTextField
            label={t("UNIT.description")}
            value={props?.UnitData?.description}
            error={null}
            message={null}
            haswidth={true}
            readOnly={true}
            hasMultipleLine={true}
          />
        </Box>
        <Box className="col-12 col-md-12 col-lg-12 my-2 px-3 mx-1">
          <UnitTabs 
            UnitData={props?.UnitData}
            loadDataOfUnit={props?.loadDataOfUnit}
            UnitId={props?.UnitId}
            ListOfLogs={ListOfLogs}
          />
        </Box>
      </Box>

      {/* menu popup for change status of unit  */}
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {unitStatuses &&
          unitStatuses?.length > 0 &&
          unitStatuses?.map((itm) => {
            return (
              <MenuItem
                onClick={() => changeUnitStatus(itm)}
                disableRipple
                sx={{
                  fontFamily: "Cairo",
                  display: "flex",
                  alignItems: "center",
                  backgroundColor:getBackgroundColor(itm)
                }}
              >
                <span
                  style={{
                    width: "10px",
                    height: "10px",
                    borderRadius: "50%",
                    backgroundColor: itm?.color,
                    boxShadow: "0 0 4px -1px rgba(0,0,0,.4)",
                  }}
                ></span>
                <span
                  style={{
                    fontFamily: "Cairo-Bold",
                    margin: "0 10px",
                  }}
                >
                  {itm?.name}
                </span>
              </MenuItem>
            );
          })}
      </StyledMenu>

   {/* menu popup for make order  */}
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl2}
        open={open2}
        onClose={handleClose2}
      >
        {
        (checkUnitStatusIsTemporay()||props?.UnitData?.unit_status?.id==1||!checkOrderFound()||checkOrderFound()=='empty')&&props?.userRolesHere[6]?.addOrder?
        <MenuItem
          onClick={handleCreateOrder}
          disableRipple
          sx={{
            fontFamily: "Cairo",
            display: "flex",
            alignItems: "center",
          }}
        >
          <VscIcons.VscGitPullRequestCreate style={{color:"#1e6a99",fontSize:'25px',margin:'0 4px'}}/>
          <span
            style={{
              fontFamily: "Cairo-Bold",
              margin: "0 10px",
            }}
          >
            {t('GLOBAL.create_order')}
          </span>
        </MenuItem>:null
        }
        {
        checkOrderFound()&&checkOrderFound()!='empty'&&props?.userRolesHere[8].addContract?
        <MenuItem
          onClick={handleMakeInvoice}
          disableRipple
          sx={{
            fontFamily: "Cairo",
            display: "flex",
            alignItems: "center",
          }}
        >
          {props?.UnitData?.orders[props?.UnitData?.orders?.length-1]?.order_status_id==2?
          <BsPrinter style={{color:"#1e6a99",fontSize:'25px',margin:'0 4px'}}/>
          :
          <GavelIcon style={{color:"#1e6a99",fontSize:'25px',margin:'0 4px'}}/>
          }
          <span
            style={{
              fontFamily: "Cairo-Bold",
              margin: "0 10px",
            }}
          >
            {props?.UnitData?.orders[props?.UnitData?.orders?.length-1]?.order_status_id==2?
            t('GLOBAL.print_contract')
            :
            t('GLOBAL.create_contract')
            }
          </span>
        </MenuItem>
        :
        null}

         {
          (checkOrderFound()&&checkOrderFound()!='empty'&&props?.UnitData?.orders[props?.UnitData?.orders?.length-1]?.order_status_id==1)&&props?.userRolesHere[7]?.deleteOrder?
        <MenuItem
          onClick={()=>CancelOrder(props?.UnitData?.orders[props?.UnitData?.orders?.length-1])}
          disableRipple
          sx={{
            fontFamily: "Cairo",
            display: "flex",
            alignItems: "center",
          }}
        >
          <MdCancel style={{color:"#aa0000",fontSize:'25px',margin:'0 4px'}}/>
          <span
            style={{
              fontFamily: "Cairo-Bold",
              margin: "0 10px",
            }}
          >
            {t('GLOBAL.Cancel_order')}
          </span>
        </MenuItem>
        :
        null}

        {
          handleShowEmptyPoPup()?
            <Box 
              sx={{
                display:'flex',
                justifyContent:'center',
                alignItems:'center',
                fontFamily:'Cairo'  ,
                color:'#000'   
              }}
            >
              {t('GLOBAL.noData')}
            </Box>
          :null
        }

            
          
      </StyledMenu>

      {/* assing customer to unit when status is for  booking */}
      <AssingCustomerToUnit
         addForm={oepnAssignForm}
         setAddForm={setOepnAssignForm}
         loadData={props?.loadDataOfUnit}
         id={props?.UnitId}
         selectedUnitStatus={selectedUnitStatus}
      />
    </Box>
  );
}
