import * as React from 'react';
import Checkbox from '@mui/material/Checkbox';
import SvgIcon from '@mui/material/SvgIcon';
import { FormControlLabel } from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CloseIcon from '@mui/icons-material/Close';
const PlusSquare = (props) => {
    
    return (
        <SvgIcon fontSize="inherit" style={{
            width: 18,
            height: 18,
            backgroundColor: "#000000",
            color: "#FFFFFF",
        }} {...props}>
            {/* tslint:disable-next-line: max-line-length */}
            <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z" />
        </SvgIcon>
    );
}
function MinusSquare(props) {
    
    return (
        <SvgIcon fontSize="inherit" style={{
            width: 18,
            height: 18,
            backgroundColor: "#000000",
            color: "#FFFFFF",
        }} {...props}>
            {/* tslint:disable-next-line: max-line-length */}
            <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z" />
        </SvgIcon>
    );
}
function CloseSquare(props) {
    
    return (
        <SvgIcon
            className="close"
            fontSize="inherit"
            style={{
                width: 18,
                height: 18,
                backgroundColor: "#7E7E7E",
                color: "#FFFFFF",
            }}
            {...props}
        >
            {/* tslint:disable-next-line: max-line-length */}
            <path d="M17.485 17.512q-.281.281-.682.281t-.696-.268l-4.12-4.147-4.12 4.147q-.294.268-.696.268t-.682-.281-.281-.682.294-.669l4.12-4.147-4.12-4.147q-.294-.268-.294-.669t.281-.682.682-.281.696 .268l4.12 4.147 4.12-4.147q.294-.268.696-.268t.682.281 .281.669-.294.682l-4.12 4.147 4.12 4.147q.294.268 .294.669t-.281.682zM22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0z" />
        </SvgIcon>
    );
}
export default function CustomCheckBox(props) {
    
    const [checked, setChecked] = React.useState(true);

    const handleChange = (event) => {
        props?.setChecked(event.target.checked);
    };


    return (
        props?.label ?
            <FormControlLabel

                control={<Checkbox
                    icon={props?.disabled ? <CloseIcon sx={{color:"#FFFFFF"}} /> : props?.notCheckedIcon ? props?.notCheckedIcon : <KeyboardArrowLeftIcon sx={{color:"#FFFFFF"}}  />}
                    checked={props?.checked}
                    disabled={props?.disabled||props?.readOnly}
                    checkedIcon={props?.checkedIcon ? props?.checkedIcon : <KeyboardArrowDownIcon sx={{color:"#FFFFFF"}} />}

                    sx={{
                        '& .MuiCheckbox-root svg': {
                            color: "#000000"
                        },
                        '& .MuiCheckbox-root.MuiCheckbox-indeterminate': {
                            color: "#000000"
                        },
                        ...props?.sx
                    }}
                    onChange={handleChange}
                    inputProps={{ 'aria-label': 'controlled' }}
                />}
                label={props?.label}
            />
            :
            <Checkbox
                icon={props?.disabled ? <CloseIcon sx={{color:"#FFFFFF"}} /> : props?.notCheckedIcon ? props?.notCheckedIcon : <KeyboardArrowLeftIcon sx={{color:"#FFFFFF"}}  />}
                checked={props?.checked}
                disabled={props?.disabled || props?.readOnly}
                checkedIcon={props?.checkedIcon ? props?.checkedIcon : <KeyboardArrowDownIcon sx={{color:"#FFFFFF"}} />}

                sx={{
                    '& .MuiCheckbox-root svg': {
                        color: "#000000"
                    },
                    '& .MuiCheckbox-root.MuiCheckbox-indeterminate': {
                        color: "#000000"
                    },
                    ...props?.sx
                }}
                onChange={handleChange}
                inputProps={{ 'aria-label': 'controlled' }}
            />
    );
}
