import callAPI from '../ApiConfig'
import store from '../../../app/store'

const workType = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"working_types",
        method:"get",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
    
}
const workTypeList = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"working_types_list",
        method:"get",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
    
}
const addWorkType = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"working_types",
        method:"post",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        },
        data:props?.data
    })
    
}
const updateWorkType = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"working_types/"+props.data?.id,
        method:"post",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        },
        data:props.data,
            
    })
    
}
const deleteWorkType = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"working_types/"+props.data?.id,
        method:"delete",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        },            
    })
    
}

const WorkTypeAPI={
    WorkType:workType,
    WorkTypeList:workTypeList,
    AddWorkType:addWorkType,
    UpdateWorkType:updateWorkType,
    DeleteWorkType:deleteWorkType

}
export default WorkTypeAPI