import callAPI from '../ApiConfig'
import store from '../../../app/store'

const UnitStatuses = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"unit_statuses",
        method:"get",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
    
}
const UnitStatusList = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"unit_statuses_list",
        method:"get",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
    
}
const addUnitStatus = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"unit_statuses",
        method:"post",
        signal:props?.signal,
        params:props?.params,
        data:props?.data,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
    
}
const updateUnitStatus = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"unit_statuses/"+props?.data?.id,
        method:"put",
        signal:props?.signal,
        params:props?.params,
        data:props?.data,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
    
}

const deleteUnitStatus = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"unit_statuses/"+props?.data?.id,
        method:"delete",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
    
}



const UnitStatusAPI={
    UnitStatus:UnitStatuses,
    UnitStatusList:UnitStatusList,
    addUnitStatus:addUnitStatus,
    updateUnitStatus:updateUnitStatus,
    deleteUnitStatus:deleteUnitStatus,

}
export default UnitStatusAPI