import React ,{useEffect} from 'react'
import { Box, IconButton } from "@mui/material";
import { Navigation, Pagination, Autoplay, Lazy } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import './style.css'
// import CardImage from "./CardImage";
import { useSelector } from "react-redux";
import ImagePreview from '../Components/ImagePreview';

export default function SlideImages(props) {
  const screenwidth = useSelector((state) => state.settingsData.screenwidth);
  const [heroSwiper, setSwiperRef] = React.useState(null);
  const [selecedImage,setSelectedImage]=React.useState(null)
  const [openImage,setOpenImage]=React.useState(false)
  const isopensidemenu=useSelector(state=>state.settingsData.isopensidemenu)

  const pagination = {
    clickable: true,
    renderBullet: function (index, className) {
      return (
        '<span class="' +
        className +
        '" style="width:20px;height:20px;color:#fff;box-shadow:0 0 10px -2px rgba(255,255,255,0.4) ;background-color:#1e6a99">' +
        (index + 1) +
        "</span>"
      );
    },
  };
  useEffect(() => {
    // const swiper=new Swiper()
    window.addEventListener('resize', function () {
      if(heroSwiper)
      heroSwiper?.update();
    });
    
    return () => {
        
    };
  }, [heroSwiper]);

  useEffect(()=>{
    if(heroSwiper)
      heroSwiper?.update();
  },[isopensidemenu])


  return (<>
    <Swiper
    onSwiper={setSwiperRef}
    // spaceBetween={30}
    // centeredSlides={true}
    // slidesPerView={4}
    // autoplay={{
    //   delay: 2500,
    //   disableOnInteraction: false,
    // }}
    pagination={pagination}
    direction="horizontal"
    // pagination={{
    //   clickable: true,
    // }}
    breakpoints={{
      300: {
        slidesPerView: 1,
        spaceBetween: 20,
      },
      400: {
        slidesPerView: 1,
        spaceBetween: 20,
      },
      450: {
        slidesPerView: 1,
        spaceBetween: 20,
      },
      500: {
        slidesPerView: 1,
        spaceBetween: 20,
      },
      540: {
        slidesPerView: 1,
        spaceBetween: 20,
      },
      640: {
        slidesPerView: 1.3,
        spaceBetween: 60,
      },
      768: {
        slidesPerView: 1.7,
        spaceBetween: 40,
      },
      900: {
        slidesPerView: 2.2,
        spaceBetween: 30,
      },
      1100: {
        slidesPerView: 2.5,
        spaceBetween: 5,
      },
      1200: {
        slidesPerView: 3,
        spaceBetween: 10,
      },
      1300: {
        slidesPerView: isopensidemenu?2.9:3.2,
        spaceBetween: 70,
      },
      1500: {
        slidesPerView: isopensidemenu?3:3.5,
        spaceBetween: 100,
      },
      default: {
        slidesPerView: isopensidemenu?3:3.5,
        spaceBetween: 100,
      },
    }}
    observer={true}
    observeParents={true}
    updateOnWindowResize={true}
    navigation={true}
    centeredSlidesBounds
    

    modules={[Autoplay, Pagination, Navigation]}
    className="mySwiper2"
    style={{
      "--swiper-navigation-color": "#ffff",
      "--swiper-pagination-color": "#fff",
      height: "500px",
      width: '100%'
    }}
      >
         {
          props?.images?.length > 0 &&
          props?.images?.map((itm) => (
            <SwiperSlide>
               <img
                  src={process.env.REACT_APP_API_URL_image+itm?.image_url}
                  style={{
                    width:'300px',
                    height:'400px',
                    borderRadius:'3px',
                    aspectRatio:3,
                    objectFit:'cover'
                  }}
                  onClick={()=>{
                    setSelectedImage(process.env.REACT_APP_API_URL_image+itm?.image_url)
                    setOpenImage(true)
                  }}
               />
            </SwiperSlide>
          ))}
      </Swiper>
      <ImagePreview
        src={selecedImage}
        open={openImage}
        setOpen={setOpenImage}
      />
      </>
  )
}
