import React from 'react';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';


const primaryTextSX = {
    color: '#FFFFFF',
    fontFamily: 'Cairo',
    fontSize: '13px'
}


export default function CustomTableHeader(props) {
    console.log(props);
    return (
        <Box sx={{
            display: 'flex',
            backgroundColor: '#994D1E',
            justifyContent: 'space-between',
            alignItems: 'stretch',
            flexDirection: 'row',
            paddingRight: '86px',
            paddingLeft: '25px',
            paddingTop: '7px',
            paddingBottom: '7px'
        }}>
            {props?.columns?.length > 0 && props?.columns?.map(((column, index) =>
                <Box
                    key={'data_grid_header' + index}
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                        flex: 1,
                        alignItems:column?.alignItems?column?.alignItems:'center'
                    }}
                >
                    <Typography
                        variant="subtitle2"
                        sx={{

                        ...primaryTextSX,
                        textAlign:column?.align?column?.align:'center'
                    }}
                    >
                        {column?.title}
                    </Typography>

                </Box>
            ))}
            

        </Box>
    );
}
