import { createSlice } from '@reduxjs/toolkit';
import Cookies from "universal-cookie";
const cookies=new Cookies();
const token =cookies.get('token')?cookies.get('token'):'';
const username = cookies.get('username')?cookies.get('username'):'';
const full_name = cookies.get('full_name')?cookies.get('full_name'):'';
const Phone = cookies.get('phone')?cookies.get('phone'):'';
const image = cookies.get('image')?cookies.get('image'):'';
var userData=localStorage.getItem('userData')?JSON.parse(localStorage.getItem('userData')):''


const initialState={
    data:{},
    userName:username,
    roleName:'',
    fullName:full_name,
    phone:Phone,
    image:image,
    token:token,
    userData:userData
}
export const userSlice = createSlice({
  name: 'userData',
  initialState:initialState,
  reducers: {
    setUserName:(state,action)=>{
        state.userName=action.payload
    },
    setUserPhone:(state,action)=>{
       state.phone=action.payload
    },
    setFullName:(state,action)=>{
      state.fullName=action.payload
    },
   setImage:(state,action)=>{
    state.image=action.payload
    },
    setUserRoleName:(state,action)=>{
       state.roleName=action.payload
    },
    setUserData:(state,action)=>{
        state.userData=action.payload
    },
    setUserToken:(state,action)=>{
       state.token=action.payload
    },
    
  },
});

export const {
  setUserName,setUserPhone,setUserData,setUserRoleName,setUserToken,setFullName,setImage
 } = userSlice.actions;

export const userName = state => state.settingsData.userName;
export const phone = state => state.settingsData.phone;
export const roleName = state => state.settingsData.roleName;
export const data = state => state.settingsData.data;



export default userSlice.reducer;
