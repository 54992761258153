import callAPI from '../ApiConfig'
import store from '../../../app/store'

const floors = async (props) => {
    const storeState = store.getState();

    return await callAPI({
        route: "floors",
        method: "get",
        signal: props?.signal,
        params: props?.params,
        headers: {
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }

    })

}

const floorsList = async (props) => {
    const storeState = store.getState();

    return await callAPI({
        route: "floors_list",
        method: "get",
        signal: props?.signal,
        params: props?.params,
        headers: {
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }

    })

}
const floor = async (props) => {
    const storeState = store.getState();

    return await callAPI({
        route: "floor",
        method: "get",
        signal: props?.signal,
        params: props?.params,
        headers: {
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }

    })

}
const bulkInsert = async (props) => {
    const storeState = store.getState();

    return await callAPI({
        route: "floors/bulk_insert",
        method: "post",
        signal: props?.signal,
        params: props?.params,
        data:props?.data,
        headers: {
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }

    })

}
const bulkDelete = async (props) => {
    const storeState = store.getState();

    return await callAPI({
        route: "floors/bulk_delete",
        method: "delete",
        signal: props?.signal,
        params: props?.params,
        data:props?.data,
        headers: {
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }

    })

}

const floorAPI = {
    floors: floors,
    floorsList: floorsList,
    bulkInsert: bulkInsert,
    bulkDelete: bulkDelete

}
export default floorAPI