import React, { useState } from "react";
import Box from "@mui/material/Box";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import {
  Collapse,
  Divider,
  IconButton,
  List,
  ListItemButton,
  Typography,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { StarBorder } from "@mui/icons-material";
import StarIcon from '@mui/icons-material/Star';
import CustomButton from "../../General/CustomComponents/CustomButton";
import { BsFillCreditCardFill, BsPlus } from "react-icons/bs";
import { MdPhoneInTalk } from "react-icons/md";
import { IoDocumentTextSharp } from "react-icons/io5";
import Person2Icon from "@mui/icons-material/Person2";
import CustomTable from "../RegisteredCustomers/CustomTable/CustomTable";
import AddIcon from "@mui/icons-material/Add";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CustomTabs from "../../General/CustomTabs";
import ContactsTab from "../RegisteredCustomers/Tabs/ContactsTab";
import CommunicationsTab from "../RegisteredCustomers/Tabs/CommunicationsTab";
import ContractsAndUnitsTab from "../RegisteredCustomers/Tabs/ContractsAndUnitsTab";
import FinancialDetailsTab from "../RegisteredCustomers/Tabs/FinancialDetailsTab";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Stack from "@mui/material/Stack";
import WifiCalling3Icon from "@mui/icons-material/WifiCalling3";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import DeleteIcon from "@mui/icons-material/Delete";
import helpers from "../../assets/js/helper";
import { useNavigate } from "react-router-dom";
import customerAPI from "../../Global/Network/Customer";
import swal from "sweetalert";
import { toast } from "react-toastify";
import PersonalInfoTab from "../RegisteredCustomers/Tabs/PersonalInfoTab";
import ContactEmergencyIcon from '@mui/icons-material/ContactEmergency';

const iconButtonSx = {
  backgroundColor: "#994D1E",
  boxShadow: "0px 3px 6px #00000029",
  width: "20px",
  height: "20px",
  "&:hover": {
    backgroundColor: "#994D1EA0",
  },
};
const primaryTextSX = {
  color: "#212121",
  fontFamily: "Cairo",
  fontSize: "13px",
};

const secondaryTextSX = {
  color: "#777676",
  fontFamily: "Cairo-ExtraLight",
  fontSize: "12px",
  fontWeight:'600'
};

const buttonSX = {
  width: "164px",
  fontFamily: "Cairo",
  fontSize: "13px",
  backgroundColor: "#FFFFFF66",
  color: "#FFFFFF",
  marginLeft: "5px",
  border: "none",
};
const buttonHoverStyle = {
  backgroundColor: "#FFFFFF",
  color: "#994D1E",
};

const addButtonSX = {
  width: "max-content",
  fontFamily: "Cairo",
  fontSize: "13px",
  backgroundColor: "#FFFFFF66",
  color: "#994D1E",
  paddingLeft: "0px",
  paddingRight: "0px",
  border: "none",
};
const addButtonHoverStyle = {
  backgroundColor: "#FFFFFF",
  color: "#994D1EA0",
  border: "none",
};

export default function DataGridRow(props) {
  const [open, setOpen] = useState(false);
  const [t] = useTranslation("common");
  const navigate = useNavigate();
  const handleClick = () => {
    props?.setClickedCollapsed(props?.row?.id)
    setOpen(!open);
    let arr=[]
    props?.rows&&props?.rows?.length>0&&props?.rows?.map((itm)=>{
       if(itm?.id==props?.row?.id){
        arr.push({
          ...itm,
          showCollapse:!open,
        })
       }else{
        arr.push({
          ...itm,
          showCollapse:false,
        })
       }
    })
    props?.setRows(arr)
  };
  const [tabsObject,setTabsObject]=useState({
    tabs:[]
  })
  React.useEffect(() => {
  
    let arr=[
              {
        id: 1,
        title: "المرافقين",
        startIcon: <Person2Icon />,
        selected: false,
        content: (
          <ContactsTab
            loadData={props?.loadData}
            row={props?.row}
            userRolesHere={props?.userRolesHere}
          />
        ),
      },
    ];
     if(props?.userRolesHere?.length>0){
           if( props?.userRolesHere[6].viewContract){
              arr.push(
                {
                    id: 2,
                    title: "العقود والوحدات",
                    startIcon: <IoDocumentTextSharp />,
                    selected: false,
                    content: (
                      <ContractsAndUnitsTab
                        loadData={props?.loadData}
                        row={props?.row}
                        userRolesHere={props?.userRolesHere}
                      />
                    ),
                  }
              )
           }
           if(props?.userRolesHere[5].viewCalls){
            arr.push(
                {
                    id: 3,
                    title: "سجل الاتصالات",
                    startIcon: <MdPhoneInTalk />,
                    selected: false,
                    content: (
                      <CommunicationsTab
                        row={props?.row}
                        userRolesHere={props?.userRolesHere}
                      />
                    ),
                  }
            )
           } 
           if(props?.userRolesHere[8].viewCustomerFinancial){
            arr.push(
                {
                    id: 4,
                    title: "التفاصيل المالية",
                    startIcon: <BsFillCreditCardFill />,
                    selected: false,
                    content: (
                      <FinancialDetailsTab
                        row={props?.row}
                        userRolesHere={props?.userRolesHere}
                      />
                    ),
                  }
            )
           }
           arr.push(
            {
                id: 5,
                title: "معلومات الحساب",
                startIcon: <ContactEmergencyIcon />,
                selected: false,
                content: (
                  <PersonalInfoTab
                    row={props?.row}
                    userRolesHere={props?.userRolesHere}
                  />
                ),
              }
        )
     }
     setTabsObject({
        tabs:arr,
     })
    if(props?.row){
      setOpen(props?.customerFromUrl?true:props?.row?.showCollapse?props?.row?.showCollapse:false)
    }
     return ()=>{
     }

  }, [props?.userRolesHere,props?.row]);
  

  const [openDropDown, setOpenDropDown] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpenDropDown((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpenDropDown(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpenDropDown(false);
    } else if (event.key === "Escape") {
      setOpenDropDown(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(openDropDown);
  React.useEffect(() => {
    if (prevOpen.current === true && openDropDown === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = openDropDown;
  }, [openDropDown]);

  const deleteFunction = async (obj) => {
    
    swal({
      title: "?هل انت متأكد",
      text: "لن تتمكن من التراجع عن هذا!",
      icon: "warning",
      buttons: ["الغاء!", "نعم!"],
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        try {
          let result = null;
          if (obj && obj?.id) {
            result = await customerAPI.deleteCustomer({
              id: obj.id,
            });
          }
          if (result?.status) {
            // toast.success(t('NETWORKMESSAGE.deleteSuccess'))
            swal(`${t("NETWORKMESSAGE.deleteSuccess")}`, {
              icon: "success",
            });
            props?.loadData({
              page: props?.pageNumber,
            });
          } else {
            if(result?.errorMessage){
              toast.error(result?.errorMessage)
            }else
            toast.error(t('NETWORKMESSAGE.deleteErrorr'))
          }
        } catch (error) {
          toast.error(t(error?.message));
        }
      } else {
        // alert('canceled')
      }
    });
  };

  const getColorForCustomerStatus=(data)=>{
    if(data?.has_units>0){
      return 'red'
    }else if(data?.has_reserved_units){
      return 'yellow'
    }else if(data?.has_temp_reserved_units){
      return '#FFA500'
    }else return 'black'
  }

  return (
    <Box
      sx={{
        display: "flex",

        justifyContent: "space-between",
        alignItems: "stretch",
        flexDirection: "column",
        backgroundColor: open ? "#ddecf6" : "#f3f5f7",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "stretch",
          flexDirection: "row",
        }}
      >
        <ListItemButton onClick={handleClick}>
          <KeyboardArrowDown
            sx={{
              mr: -1,
              opacity: 1,
              transform: open ? "rotate(-180deg)" : "rotate(0)",
              transition: "0.2s",
              color: "#52575D",
              width: "25px",
            }}
          />
          <Box
            sx={{
              display:'flex',
              alignItems:'center',
              justifyContent:'center',
              flex: 1,
            }}
           >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                flex: 0.5,
              }}
            >
              <span
                style={{
                  width:'15px',
                  height:'15px',
                  backgroundColor:getColorForCustomerStatus(props?.row),
                  borderRadius:'50%',
                }}
              ></span>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "start",
                flexDirection: "column",
                flex: 1,
              }}
            >
              <Typography variant="subtitle2" sx={primaryTextSX}>
                {props?.row && props?.row?.user && props?.row?.user?.full_name}
              </Typography>
              <Typography variant="caption" sx={secondaryTextSX}>
                {props?.row && props?.row?.academic_achievement}
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              flex: 1,
            }}
          >
            <Typography variant="subtitle2" sx={{...primaryTextSX,direction:'rtl'}}>
              {helpers?.getAddress(props?.row?.addresses, 0, 3)}
            </Typography>
            <Typography variant="caption" sx={secondaryTextSX}>
              {props?.row &&
                props?.row?.contact_method &&
                props?.row?.contact_method?.name}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              flex: 1,
            }}
          >
            <Typography variant="subtitle2" sx={primaryTextSX}>
              {helpers?.getAddress(props?.row?.addresses, 0, 0) +
                " - " +
                helpers.getAddress(props?.row?.addresses, 0, 1)}
            </Typography>
            <Typography variant="caption" sx={secondaryTextSX}>
              {helpers?.getAddress(props?.row?.addresses, 0, 2)}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              flex: 1,
            }}
          >
            <Typography variant="subtitle2" sx={primaryTextSX}>
              {props?.row && props?.row?.employee && props?.row?.employee?.user
                ? props?.row?.employee?.user?.full_name
                : ""}
            </Typography>
            <Box
              sx={{
                flex: 1,
                display: "flex",
                justifyContent: "stretch",
                alignItems: "center",
              }}
            >
              <StarIcon
                sx={{
                  color:
                    props?.row && props?.row?.interest_degree > 0
                      ? "#9bcdec"
                      : "#aab6bf",
                }}
              />
              <StarIcon
                sx={{
                  color:
                    props?.row && props?.row?.interest_degree >= 2
                      ? "#9bcdec"
                      : "#aab6bf",
                }}
              />
              <StarIcon
                sx={{
                  color:
                    props?.row && props?.row?.interest_degree >= 3
                      ? "#9bcdec"
                      : "#aab6bf",
                }}
              />
              <StarIcon
                sx={{
                  color:
                    props?.row && props?.row?.interest_degree >= 4
                      ? "#9bcdec"
                      : "#aab6bf",
                }}
              />
              <StarIcon
                sx={{
                  color:
                    props?.row && props?.row?.interest_degree == 5
                      ? "#9bcdec"
                      : "#aab6bf",
                }}
              />
            </Box>
          </Box>
        </ListItemButton>
        <IconButton
          sx={{
            width: "70px",
            "&:hover": {
              borderRadius: "0px",
            },
          }}
          // onClick={() => {
        
          // }}
          ref={anchorRef}
          id="composition-button"
          aria-controls={openDropDown ? "composition-menu" : undefined}
          aria-expanded={openDropDown ? "true" : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
        >
          <MoreVertIcon />
        </IconButton>
        {/* master grid action popup  */}
        <Popper
          open={openDropDown}
          anchorEl={anchorRef.current}
          role={undefined}
          placement="bottom-start"
          transition
          disablePortal
          sx={{
            zIndex: 2342423,
          }}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom-start" ? "left top" : "left bottom",
              }}
            >
              <Paper
                sx={{
                  backgroundColor: "#E3E9EF",
                  borderRadius: "0 !important",
                }}
              >
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={openDropDown}
                    id="composition-menu"
                    aria-labelledby="composition-button"
                    onKeyDown={handleListKeyDown}
                  >
                    {props?.userRolesHere[5]?.viewCalls ? (
                      <MenuItem
                        onClick={() => {
                          navigate("/admin/calls", {
                            state: {
                              data: {
                                customer: props?.row,
                              },
                            },
                          });
                        }}
                      >
                        <WifiCalling3Icon
                          sx={{
                            color: "#349CDD",
                            fontSize: "20px",
                            margin: "0 10px",
                          }}
                        />
                        <span
                          style={{
                            textAlign: "right",
                            font: "normal normal 300 14px/22px Cairo",
                            letterSpacing: "1.26px",
                            color: "#52575D",
                            opacity: "1",
                          }}
                        >
                          {t("CUSTOMER.call_log")}
                        </span>
                      </MenuItem>
                    ) : null}
                    {/* <MenuItem onClick={handleClose}>
                                <CalendarMonthIcon sx={{
                                    color:'#349CDD',
                                    fontSize:'20px',
                                    margin:'0 10px'

                                }}/>
                                <span style={{
                                    textAlign: "right", font: "normal normal 300 14px/22px Cairo", letterSpacing: "1.26px", color: "#52575D", opacity: "1"
                                }}>{t('CUSTOMER.schedule_new_event')}</span>
                            </MenuItem> */}
                    {props?.userRolesHere[2]?.update ? (
                      <MenuItem
                        onClick={() => {
                          navigate("/admin/registered-customers/add", {
                            state: {
                              data: {
                                customer: props?.row,
                              },
                            },
                          });
                        }}
                      >
                        <AppRegistrationIcon
                          sx={{
                            color: "#349CDD",
                            fontSize: "20px",
                            margin: "0 10px",
                          }}
                        />
                        <span
                          style={{
                            textAlign: "right",
                            font: "normal normal 300 14px/22px Cairo",
                            letterSpacing: "1.26px",
                            color: "#52575D",
                            opacity: "1",
                          }}
                        >
                          {t("CUSTOMER.edit_informations")}
                        </span>
                      </MenuItem>
                    ) : null}
                    {props?.userRolesHere[3]?.delete ? (
                      <MenuItem onClick={() => deleteFunction(props?.row)}>
                        <DeleteIcon
                          sx={{
                            color: "#349CDD",
                            fontSize: "20px",
                            margin: "0 10px",
                          }}
                        />
                        <span
                          style={{
                            textAlign: "right",
                            font: "normal normal 300 14px/22px Cairo",
                            letterSpacing: "1.26px",
                            color: "#52575D",
                            opacity: "1",
                          }}
                        >
                          {t("CUSTOMER.delete_customer")}
                        </span>
                      </MenuItem>
                    ) : null}
                    {
                      !props?.userRolesHere[5]?.viewCalls&& !props?.userRolesHere[2]?.update&& !props?.userRolesHere[3]?.delete ?
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "5px 15px",
                        color: "#fff",
                        fontFamily: "Cairo",
                      }}
                    >
                      {t("GLOBAL.emptyPoPup")}
                    </Box>:null}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Box>
      {
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Box
          sx={{
            minHeight: "200px",
            background: "#FFFFFF",
            display: "flex",
            flexDirection: "column",
          }}
        >
          
          <CustomTabs
            tabsObject={tabsObject}
            userRolesHere={props?.userRolesHere}
            ClickedCollapse={props?.ClickedCollapse}
            customerFromUrl={props?.customerFromUrl}
          />
        </Box>
      </Collapse>}
    </Box>
  );
}
