import callAPI from '../ApiConfig'
import store from '../../../app/store'

const Rooles = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"roles",
        method:"get",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
    
}
const RooleList = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"roles_list",
        method:"get",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
    
}
const addRoole = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"roles",
        method:"post",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        },
        data:props?.data,
    })
    
}
const updateRoole = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"roles/"+props?.data?.id,
        method:"put",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        },
        data:props?.data
    })
    
}
const deleteRoole = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"roles/"+props?.data?.id,
        method:"delete",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        },
    })
    
}
const RolesAPI={
    Rooles:Rooles,
    RooleList:RooleList,
    addRoole:addRoole,
    updateRoole:updateRoole,
    deleteRoole:deleteRoole

}
export default RolesAPI