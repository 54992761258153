import { Box, IconButton, Tooltip } from "@mui/material";
import React, { useState, useEffect } from "react";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import companyCover from "../../assets/img/companyCover.jpg";
import companyPro from "../../assets/img/prof.jpg";
import HolidayVillageIcon from '@mui/icons-material/HolidayVillage';
import {
  setIsOpenSideMenu,
  setCompanyProfileFullScreen,
  setGlobalLoading,
} from "../../reduxStore/SettingsReducer";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import FullscreenIcon from "@mui/icons-material/Fullscreen";

import "./style.css";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import ApartmentIcon from "@mui/icons-material/Apartment";
import * as FaIcons from "react-icons/fa";
import * as RiIcons from "react-icons/ri";
import HomeIcon from "@mui/icons-material/Home";
import BadgeIcon from "@mui/icons-material/Badge";
import DomainAddIcon from "@mui/icons-material/DomainAdd";
import PersonPinCircleIcon from "@mui/icons-material/PersonPinCircle";
import FestivalIcon from "@mui/icons-material/Festival";
import Project from "./Project";
import CompanyAPI from "../../Global/Network/Companies/index";
import Cookies from "universal-cookie";
import { useLocation, useNavigate } from "react-router-dom";
import QrCode2Icon from '@mui/icons-material/QrCode2';
import { toast } from "react-toastify";
import YouTubeIcon from '@mui/icons-material/YouTube';
import LanguageIcon from '@mui/icons-material/Language';

const temUrl =
  "https://imgsrv2.voi.id/vOfuqm1jb_C2mW9g8CIdIEX-sd2tZJqJDJjNo11Yccw/auto/1200/675/sm/1/bG9jYWw6Ly8vcHVibGlzaGVycy8xNDU2NTcvMjAyMjAzMTUxNzQ1LW1haW4uY3JvcHBlZF8xNjQ3MzQxMTE2LmpwZWc.jpg";
const thumb = "http://www.electrony.net/media/2017/09/Google-Company.jpg";

const temp1 =
  "https://media.istockphoto.com/id/511061090/photo/business-office-building-in-london-england.jpg?s=612x612&w=0&k=20&c=nYAn4JKoCqO1hMTjZiND1PAIWoABuy1BwH1MhaEoG6w=";
const temp2 =
  "https://images.unsplash.com/photo-1610307766999-01f45d259959?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8YnVpbGRpbmclMjBpbiUyMGJhY2tncm91bmR8ZW58MHx8MHx8&w=1000&q=80";
const temp3 =
  "https://www.sustainableplaces.eu/wp-content/uploads/2017/02/SmartBuilding.jpg";
const imageProfiTemp =
  "https://people.com/thmb/gMicCoucWmCLemVYtLpEYWEu1bU=/1500x0/filters:no_upscale():max_bytes(150000):strip_icc():focal(967x512:969x514)/World-Cup-121822-02-1fd687e1a1ed4e4e9ec30a80042fe86d.jpg";

const urlParams = new URLSearchParams(window.location.search);
const companyId = urlParams.get('id');

const cookies = new Cookies();
export default function CompanyProfile() {
  const [t] = useTranslation("common");
  const dispatch = useDispatch();
  const company_profile_full_screen = useSelector(
    (state) => state.settingsData.company_profile_full_screen
  );
  const screenwidth = useSelector((state) => state.settingsData.screenwidth);
  const [company, setCompany] = useState(null);
  const [isMoseOver1, setIsMouseOver1] = useState(false);
  const [isMoseOver2, setIsMouseOver2] = useState(false);
  const [isMoseOver3, setIsMouseOver3] = useState(false);
  const [isMoseOver4, setIsMouseOver4] = useState(false);
  const [isMoseOver5, setIsMouseOver5] = useState(false);
  const [isMoseOver6, setIsMouseOver6] = useState(false);
  const { state } = useLocation();
  const [selectedCompany, setSelectedCompany] = useState("");
  const showFullModeToggle = () => {
    dispatch(setCompanyProfileFullScreen(!company_profile_full_screen));
  };
  const dataSorce = [
    {
      id: 1,
      user_name: "Saif Jamal",
      created_at: "September 14, 2022",
      userImage: imageProfiTemp,
      title: "مشروع مجمع بسماية الجديد",
      description:
        "هنالك العديد من الأنواع المتوفرة لنصوص لوريم إيبسوم، ولكن الغالبية تم تعديلها بشكل ما عبر إدخال بعض النوادر أو الكلمات العشوائية إلى النص. إن كنت تريد أن تستخدم نص لوريم إيبسوم ما، عليك أن تتحقق أولاً أن ليس هناك أي كلمات أو عبارات محرجة أو غير لائقة مخبأة في هذا النص. بينما تعمل جميع مولّدات نصوص لوريم إيبسوم على الإنترنت على إعادة تكرار مقاطع من نص لوريم إيبسوم نفسه عدة مرات بما تتطلبه الحاجة",
      images: [
        {
          id: 1,
          url: temp1,
        },
        {
          id: 2,
          url: temp2,
        },
        {
          id: 3,
          url: temp3,
        },
        {
          id: 4,
          url: temp1,
        },
        {
          id: 5,
          url: temp2,
        },
        {
          id: 6,
          url: temp3,
        },
      ],
    },
    {
      id: 2,
      user_name: "Saif Jamal",
      created_at: "September 30, 2022",
      userImage: imageProfiTemp,
      title: "مشروع مجمع بغداد الجديد",
      description:
        "هنالك العديد من الأنواع المتوفرة لنصوص لوريم إيبسوم، ولكن الغالبية تم تعديلها بشكل ما عبر إدخال بعض النوادر أو الكلمات العشوائية إلى النص. إن كنت تريد أن تستخدم نص لوريم إيبسوم ما، عليك أن تتحقق أولاً أن ليس هناك أي كلمات أو عبارات محرجة أو غير لائقة مخبأة في هذا النص. بينما تعمل جميع مولّدات نصوص لوريم إيبسوم على الإنترنت على إعادة تكرار مقاطع من نص لوريم إيبسوم نفسه عدة مرات بما تتطلبه الحاجة",
      images: [
        {
          id: 1,
          url: temp1,
        },
        {
          id: 2,
          url: temp2,
        },
        {
          id: 3,
          url: temp3,
        },
      ],
    },
    {
      id: 3,
      user_name: "Saif Jamal",
      created_at: "September 30, 2022",
      userImage: imageProfiTemp,
      title: "مشروع مجمع بغداد الجديد",
      description:
        "هنالك العديد من الأنواع المتوفرة لنصوص لوريم إيبسوم، ولكن الغالبية تم تعديلها بشكل ما عبر إدخال بعض النوادر أو الكلمات العشوائية إلى النص. إن كنت تريد أن تستخدم نص لوريم إيبسوم ما، عليك أن تتحقق أولاً أن ليس هناك أي كلمات أو عبارات محرجة أو غير لائقة مخبأة في هذا النص. بينما تعمل جميع مولّدات نصوص لوريم إيبسوم على الإنترنت على إعادة تكرار مقاطع من نص لوريم إيبسوم نفسه عدة مرات بما تتطلبه الحاجة",
      images: [
        {
          id: 1,
          url: temp1,
        },
        {
          id: 2,
          url: temp2,
        },
        {
          id: 3,
          url: temp3,
        },
      ],
    },
  ];

  useEffect(() => {
    if (state && state?.data && state?.data?.company) {
      setSelectedCompany(state?.data?.company?.id);
    }
    // window.history.replaceState({}, document.title)
  }, [state]);

  useEffect(() => {
    if (selectedCompany) 
    loadDataOfCompany(selectedCompany);
    else {
      if(companyId)
      loadDataOfCompany(companyId);
      else
      loadDataOfCompany(null);
    }
  }, [selectedCompany]);

  const loadDataOfCompany = async (id) => {
    // console.log('sasdasdasdasdasda',id)
    let data = null;
    dispatch(setGlobalLoading(true));
    try {
      data = await CompanyAPI.Company({
        params: {
          id: id ? id : cookies.get("login_company_id"),
        },
      });
      if (data && data?.status) {
        console.log("company", data?.data);
        setCompany(data?.data);
      }
      dispatch(setGlobalLoading(false));
    } catch (err) {
      console.log(err?.message);
      dispatch(setGlobalLoading(false));
    }
  };

  const getImageProfile = () => {
    let imageUrl = null;
    let lastImageProf = null;
    company &&
      company?.images?.length &&
      company?.images?.map((itm) => {
        if (itm?.image_type?.id == 1) {
          lastImageProf = itm?.image_url;
        }
      });
    if (lastImageProf) imageUrl = lastImageProf;

    console.log('image_Pro',imageUrl)
    return process.env.REACT_APP_API_URL_image+imageUrl;
  };
  const getImageCover = () => {
    let imageUrl = companyCover;
    let lastImageCover = null;
    company &&
      company?.images?.length &&
      company?.images?.map((itm) => {
        if (itm?.image_type?.id == 2) {
          lastImageCover = itm?.image_url;
        }
      });
    if (lastImageCover) imageUrl = lastImageCover;
    console.log('image_Cov',imageUrl)

    return process.env.REACT_APP_API_URL_image+imageUrl;
  };
  const getFirstPhoneFromAddress=()=>{
       let first_phone=null;
      if(company&&company?.addresses){
        first_phone=company?.addresses[0]?.first_phone;
      }
      return first_phone;
  }
  function copyTextToClipboard() {
    let text=`${window.location.origin}/home/company/profile?id=${selectedCompany?selectedCompany:cookies.get("login_company_id")}`;
    // console.log('clipboard',text)
    // if ('clipboard' in navigator) {
    //       toast.success('تم نسخ الرابط')
    //   return  navigator?.clipboard?.writeText(text);
    // } else {
    //   // toast.success('تم نسخ الرابط')
    //   return document.execCommand('copy', true, text);
    // }
    var dummy = document.createElement("textarea");
    document.body.appendChild(dummy);
    dummy.value = text
    dummy.select();
    document.execCommand("copy");
    document.body.removeChild(dummy);
    toast.success('تم نسخ الرابط')
  }
  const openLinkInNewTab=(url)=>{
    window?.open(url,'_blank')
  }

  const showCopyToClipBoardIcon=()=>{
      let path=window.location.pathname;
      if(path&&path?.includes('/home')){
        return false
      }else{
        return true
      }
  }

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
      }}
    >
      {/* button on top page  */}
      { showCopyToClipBoardIcon()?
      <Tooltip
        title={
          <span style={{ fontFamily: "Cairo" }}>
            {!company_profile_full_screen
              ? t("GLOBAL.fullScreenMode")
              : t("GLOBAL.exitFullScreenMode")}
          </span>
        }
        arrow
      >
        <IconButton
          sx={{
            width: "40px",
            height: "40px",
            borderRadius: "50%",
            background: "#fff",
            position: "absolute",
            top: "20px",
            right: "15px",
            boxShadow: "0 0 10px -1px rgba(255,255,255,0.5)",
            transition: "all 0.8 ease",
            "&:hover": {
              backgroundColor: "#1e6a99",
            },
            "&:hover .icon": {
              color: "#fff",
            },
          }}
          onClick={showFullModeToggle}
        >
          {!company_profile_full_screen ? (
            <FullscreenIcon
              sx={{
                color: "#1e6a99",
                fontSize: "25px",
              }}
              className="icon"
            />
          ) : (
            <FullscreenExitIcon
              sx={{
                color: "#1e6a99",
                fontSize: "25px",
              }}
              className="icon"
            />
          )}
        </IconButton>
      </Tooltip>:null}
      {
        showCopyToClipBoardIcon()?
      <Tooltip
        title={
          <span style={{ fontFamily: "Cairo" }}>
            {
              t("GLOBAL.copyLink")
            }
          </span>
        }
        arrow
      >
        <IconButton
          sx={{
            width: "40px",
            height: "40px",
            borderRadius: "50%",
            background: "#fff",
            position: "absolute",
            top: "20px",
            right: "65px",
            boxShadow: "0 0 10px -1px rgba(255,255,255,0.5)",
            transition: "all 0.8 ease",
            "&:hover": {
              backgroundColor: "#1e6a99",
            },
            "&:hover .icon": {
              color: "#fff",
            },
          }}
          onClick={copyTextToClipboard}
        >
          {
            <QrCode2Icon
              sx={{
                color: "#1e6a99",
                fontSize: "25px",
              }}
              className="icon"
            />
         }
        </IconButton>
      </Tooltip>:null}



      <Box
        sx={{
          width: "100%",
          minHeight: "450px",
          height: "fit-content",
          backgroundColor: "rgb(30,106,153)",
          backgroundImage: `
            linear-gradient(to top, #1e6a99 0%,#1e6a9960)
           , url(${getImageCover()})
           `,
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
          alignContent: "center",
          alignSelf:'center',
          flexDirection: screenwidth <= 991 ? "column-reverse" : "row",
          paddingBottom: screenwidth <= 991 ? "50px" : "10px",
          alignItems: "top",
          //  paddingTop:'20px'
        }}
        className="headerOfCompany"
      >
        <Box
          sx={{
            width: screenwidth <= 991 ? "100%" : "60%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            alignSelf: "center",
            // textAlign:'justify',
            padding: "0 30px",
            marginTop: screenwidth <= 991 ? "20px" : "0",
          }}
        >
          <p
            style={{
              textAlign: "justify",
              alignItems: "center",
              fontFamily: "Cairo-BOLD",
              color: "#fff",
              fontSize: "30px",
              wordSpacing: "0.01em",
            }}
          >
            {company?.name}
          </p>
          <p
            style={{
              textAlign: "justify",
              alignItems: "center",
              fontFamily: "Cairo-Medium",
              color: "#fff",
            }}
          >
            {company?.description}
          </p>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "0 10px",
              marginTop: "41px",
              "& .icon": {
                cursor: "pointer",
              },
            }}
          >
            {
              company?.facebook?
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#fff",
                width: "40px",
                height: "40px",
                borderRadius: "50%",
                transition: "all ease 0.8",
                margin: "0 10px",
                "&:hover": {
                  backgroundColor: "#1e6a99",
                },
                "&:hover .icon": {
                  color: "#fff",
                },
              }}
              onClick={()=>{
                openLinkInNewTab(company?.facebook)
              }}
            >
              <FacebookRoundedIcon
                sx={{
                  color: "#1e6a99",
                  fontSize: "25px",
                }}
                className="icon"
              />
            </Box>:null}
            {
              company?.twitter?
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#fff",
                width: "40px",
                height: "40px",
                borderRadius: "50%",
                transition: "all ease 0.8",
                margin: "0 10px",
                "&:hover": {
                  backgroundColor: "#1e6a99",
                },
                "&:hover .icon": {
                  color: "#fff",
                },
              }}
              onClick={()=>{
                openLinkInNewTab(company?.twitter)
              }}
            >
              <TwitterIcon
                sx={{
                  color: "#1e6a99",
                  fontSize: "25px",
                }}
                className="icon"
              />
            </Box>:null}
            {
              company?.instagram?
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#fff",
                width: "40px",
                height: "40px",
                borderRadius: "50%",
                transition: "all ease 0.8",
                margin: "0 10px",
                "&:hover": {
                  backgroundColor: "#1e6a99",
                },
                "&:hover .icon": {
                  color: "#fff",
                },
              }}
              onClick={()=>{
                openLinkInNewTab(company?.instagram)
              }}
            >
              <InstagramIcon
                sx={{
                  color: "#1e6a99",
                  fontSize: "25px",
                }}
                className="icon"
              />
            </Box>:null}
            {company?.youtube?
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#fff",
                width: "40px",
                height: "40px",
                borderRadius: "50%",
                transition: "all ease 0.8",
                margin: "0 10px",
                "&:hover": {
                  backgroundColor: "#1e6a99",
                },
                "&:hover .icon": {
                  color: "#fff",
                },
              }}
              onClick={()=>{
                openLinkInNewTab(company?.youtube)
              }}
            >
              <YouTubeIcon
                sx={{
                  color: "#1e6a99",
                  fontSize: "25px",
                }}
                className="icon"
              />
            </Box>:null
            }

            {company?.personal_website?
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#fff",
                width: "40px",
                height: "40px",
                borderRadius: "50%",
                transition: "all ease 0.8",
                margin: "0 10px",
                "&:hover": {
                  backgroundColor: "#1e6a99",
                },
                "&:hover .icon": {
                  color: "#fff",
                },
              }}
              onClick={()=>{
                openLinkInNewTab(company?.personal_website)
              }}
            >
              <LanguageIcon
                sx={{
                  color: "#1e6a99",
                  fontSize: "25px",
                }}
                className="icon"
              />
            </Box>:null
            }

          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "0 10px",
              marginTop: "20px",
              "& .icon": {
                cursor: "pointer",
              },
            }}
          >
            <span
              style={{
                fontFamily: "Cairo-Medium",
                color: "#fff",
                fontSize: "18px",
                margin: "10px",
              }}
            >
              {t("COMPANY.callCenter")}
            </span>
            <span
              style={{
                fontFamily: "Cairo-Bold",
                color: "#fff",
                fontSize: "18px",
                textDecoration: "underline",
                direction:'ltr'
              }}
            >
              {getFirstPhoneFromAddress()}
            </span>
          </Box>
        </Box>
        <Box
          sx={{
            width: screenwidth <= 991 ? "100%" : "40%",
            display: "flex",
            justifyContent: "center",
            alignItems: "start",
            flexDirection: "column",
            padding:  screenwidth <= 991 ?"0 30px 0 30px" :"0 30px 0 0",
            marginTop:
              screenwidth <= 991 ? (screenwidth <= 700 ? "70px" : "20px") : "0",
          }}
        >
          {
            getImageProfile()?
          <img
            src={getImageProfile()}
            style={{
              width:'100%',
              height: "300px",
              borderRadius: "3px",
              objectFit: "cover",
              backgroundPosition: "center center",
              objectPosition: "center center",
            }}
          />:null
          }
        </Box>
      </Box>

      <Box
        sx={{
          width: "100%",
          justifyContent: "start",
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
        }}
        className="contentOfComapny"
      >
        {/* top summary for company  */}
        <Box
          sx={{
            width: "fit-content",
            backgroundColor: "#fff",
            boxShadow: "0 0 10px -2px rgba(0,0,0,0.3)",
            height: "fit-content",
            position: "relative",
            top: "-40px",
            margin: "auto",
            borderRadius: "3px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexWrap: "wrap",
            justifyContent: "center",
            margin: "0 10px",
            "& .icon": {
              color: "#1e6a99",
            },
          }}
        >

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              justifyContent: "center",
              margin:'5px 0',
              position: "relative",
              width: "180px",
              height: "150px",
              transition:
                "top 0.8s ease-in-out !important,box-shadow 0.8s ease-in-out !important",
              borderRadius: "10px",
              cursor: "pointer",
              // '&:hover':{
              // transform:'scaleY(1.2)',
              animationName: isMoseOver2 ? "move" : "unMove",
              animationDuration: "0.5s",
              animationFillMode: "forwards",
              // animationDirection:'revert-layer',
              // top:'-15px',

              // },
              "&:hover .icon,&:hover .text, &:hover .number": {
                color: "#fff",
              },
              "&:hover .number": {
                backgroundColor: "rgba(0,0,0,0.2)",
              },
            }}
            onMouseOver={() => {
              setIsMouseOver2(true);
            }}
            onMouseOut={() => {
              setIsMouseOver2(false);
            }}
          >
            <DomainAddIcon
              sx={{
                color: "#8DF0E2",
                fontSize: "57px",
                margin: "5px 0",
              }}
              className="icon"
            />
            <span
              style={{
                fontFamily: "Cairo-Medium",
                fontSize: "15px",
                margin: "5px 0",
              }}
              className="text"
            >
               {t('COMPANY.compounds')}
            </span>
            <Box
              sx={{
                width: "fit-content",
                height: "30px",
                backgroundColor: "lightgrey",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontFamily: "Cairo",
                borderRadius: "20px",
                padding: "5px 20px",
                fontSize: "16x",
                fontWeight: "bold",
                margin: "5px 0",
              }}
              className="number"
            >
              {company?.compounds_count}
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              margin:'5px 0',
              flexDirection: "column",
              justifyContent: "center",
              position: "relative",
              width: "180px",
              height: "150px",
              transition:
                "top 0.8s ease-in-out !important,box-shadow 0.8s ease-in-out !important",
              borderRadius: "10px",
              cursor: "pointer",
              // '&:hover':{
              // transform:'scaleY(1.2)',
              animationName: isMoseOver1 ? "move" : "unMove",
              animationDuration: "0.5s",
              animationFillMode: "forwards",
              // animationDirection:'revert-layer',
              // top:'-15px',

              // },
              "&:hover .icon,&:hover .text, &:hover .number": {
                color: "#fff",
              },
              "&:hover .number": {
                backgroundColor: "rgba(0,0,0,0.2)",
              },
            }}
            onMouseOver={() => {
              setIsMouseOver1(true);
            }}
            onMouseOut={() => {
              setIsMouseOver1(false);
            }}
          >
            <HolidayVillageIcon
              sx={{
                color: "#8DF0E2",
                fontSize: "57px",
                margin: "5px 0",
              }}
              className="icon"
            />
            <span
              style={{
                fontFamily: "Cairo-Medium",
                fontSize: "15px",
                margin: "5px 0",
              }}
              className="text"
            >
              {t('COMPANY.available_units')}
            </span>
            <Box
              sx={{
                width: "fit-content",
                height: "30px",
                backgroundColor: "lightgrey",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontFamily: "Cairo",
                borderRadius: "20px",
                padding: "5px 20px",
                fontSize: "16x",
                fontWeight: "bold",
                margin: "5px 0",
              }}
              className="number"
            >
              {company?.units_count}
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              margin:'5px 0',
              justifyContent: "center",
              position: "relative",
              width: "180px",
              height: "150px",
              transition:
                "top 0.8s ease-in-out !important,box-shadow 0.8s ease-in-out !important",
              borderRadius: "10px",
              cursor: "pointer",
              // '&:hover':{
              // transform:'scaleY(1.2)',
              animationName: isMoseOver3 ? "move" : "unMove",
              animationDuration: "0.5s",
              animationFillMode: "forwards",
              // animationDirection:'revert-layer',
              // top:'-15px',

              // },
              "&:hover .icon,&:hover .text, &:hover .number": {
                color: "#fff",
              },
              "&:hover .number": {
                backgroundColor: "rgba(0,0,0,0.2)",
              },
            }}
            onMouseOver={() => {
              setIsMouseOver3(true);
            }}
            onMouseOut={() => {
              setIsMouseOver3(false);
            }}
          >
            <HomeIcon
              sx={{
                color: "#8DF0E2",
                fontSize: "57px",
                margin: "5px 0",
              }}
              className="icon"
            />
            <span
              style={{
                fontFamily: "Cairo-Medium",
                fontSize: "15px",
                margin: "5px 0",
              }}
              className="text"
            >
              {t('COMPANY.units')}
            </span>
            <Box
              sx={{
                width: "fit-content",
                height: "30px",
                backgroundColor: "lightgrey",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontFamily: "Cairo",
                borderRadius: "20px",
                padding: "5px 20px",
                fontSize: "16x",
                fontWeight: "bold",
                margin: "5px 0",
              }}
              className="number"
            >
              {company?.units_count}
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              margin:'5px 0',
              justifyContent: "center",
              position: "relative",
              width: "180px",
              height: "150px",
              transition:
                "top 0.8s ease-in-out !important,box-shadow 0.8s ease-in-out !important",
              borderRadius: "10px",
              cursor: "pointer",
              // '&:hover':{
              // transform:'scaleY(1.2)',
              animationName: isMoseOver4 ? "move" : "unMove",
              animationDuration: "0.5s",
              animationFillMode: "forwards",
              // animationDirection:'revert-layer',
              // top:'-15px',

              // },
              "&:hover .icon,&:hover .text, &:hover .number": {
                color: "#fff",
              },
              "&:hover .number": {
                backgroundColor: "rgba(0,0,0,0.2)",
              },
            }}
            onMouseOver={() => {
              setIsMouseOver4(true);
            }}
            onMouseOut={() => {
              setIsMouseOver4(false);
            }}
          >
            <PersonPinCircleIcon
              sx={{
                color: "#8DF0E2",
                fontSize: "57px",
                margin: "5px 0",
              }}
              className="icon"
            />
            <span
              style={{
                fontFamily: "Cairo-Medium",
                fontSize: "15px",
                margin: "5px 0",
              }}
              className="text"
            >
              {t('COMPANY.customers')}
            </span>
            <Box
              sx={{
                width: "fit-content",
                height: "30px",
                backgroundColor: "lightgrey",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontFamily: "Cairo",
                borderRadius: "20px",
                padding: "5px 20px",
                fontSize: "16x",
                fontWeight: "bold",
                margin: "5px 0",
              }}
              className="number"
            >
              {company?.customers}
            </Box>
          </Box>
          
          {/* <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              justifyContent: "center",
              position: "relative",
              width: "180px",
              height: "150px",
              transition:
                "top 0.8s ease-in-out !important,box-shadow 0.8s ease-in-out !important",
              borderRadius: "10px",
              cursor: "pointer",
              // '&:hover':{
              // transform:'scaleY(1.2)',
              animationName: isMoseOver5 ? "move" : "unMove",
              animationDuration: "0.5s",
              animationFillMode: "forwards",
              // animationDirection:'revert-layer',
              // top:'-15px',

              // },
              "&:hover .icon,&:hover .text, &:hover .number": {
                color: "#fff",
              },
              "&:hover .number": {
                backgroundColor: "rgba(0,0,0,0.2)",
              },
            }}
            onMouseOver={() => {
              setIsMouseOver5(true);
            }}
            onMouseOut={() => {
              setIsMouseOver5(false);
            }}
          >
            <BadgeIcon
              sx={{
                color: "#8DF0E2",
                fontSize: "57px",
                margin: "5px 0",
              }}
              className="icon"
            />
            <span
              style={{
                fontFamily: "Cairo-Medium",
                fontSize: "15px",
                margin: "5px 0",
              }}
              className="text"
            >
              {t('COMPANY.employee')}
            </span>
            <Box
              sx={{
                width: "fit-content",
                height: "30px",
                backgroundColor: "lightgrey",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontFamily: "Cairo",
                borderRadius: "20px",
                padding: "5px 20px",
                fontSize: "16x",
                fontWeight: "bold",
                margin: "5px 0",
              }}
              className="number"
            >
              {company?.employees}
            </Box>
          </Box> */}
    
        </Box>

        {/* our projects for this company  */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
            width: "83.2%",
            margin: "20px 0",
          }}
        >
          <span style={{ fontFamily: "Cairo-Bold", fontSize: "20px" }}>
            {t("COMPANY.projectsTitle")}
          </span>
        </Box>
        {company &&
          company?.compounds &&company?.compounds?.length&&
          company?.compounds?.map((itm) => <Project {...itm} key={itm?.id} />)}

        <br />
        <br />
        <br />
        <br />
      </Box>
    </Box>
  );
}
