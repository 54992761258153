import { Box, Button } from "@mui/material";
import React ,{useState,useEffect} from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import CustomeSelectField from '../../../General/CustomComponents/CustomeSelectField'
import CompoundError,{checkErrors,resetErrors,resetEntityObject} from '../../../Global/Errors/Compound/Compound'

export default function Filter(props) {
    const [t]=useTranslation('common')
    const [ErrorsObject,setErrorsObject]=useState(CompoundError)
    const screenwidth=useSelector(state=>state.settingsData.screenwidth)

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-evenly",
        width:screenwidth<=991?"98%":'60%'
      }}
    >
      <CustomeSelectField
        label={t("DASHBOARD.compounds")}
        haswidth={true}
        value={props?.selectedCompound?props?.selectedCompound:[]}
        list={props?.compounds ? props?.compounds : []}
        customGetOptionLabel={(option) => option?.name}
        error={ErrorsObject?.compound_id?.error}
        message={ErrorsObject?.compound_id?.message}
        multiple={true}
        readOnly={false}
        onChange={(e, newValue) => {
          console.log("asdssss", newValue);
          props?.setSelectedCompound(newValue);
          setErrorsObject(resetEntityObject(ErrorsObject, "compound_id"));
        }}
        onClearClick={() => {
          props?.setSelectedCompound(null);
          setErrorsObject(resetEntityObject(ErrorsObject, "compound_id"));
        }}
      />
        <Button
          variant="contained"
          sx={{
            margin: 1,
            width: "100px !important",
            minWidth: "100px !important",
            maxWidth: "100px !important",
            "&:hover": {
            },
            height: "55px",
            fontFamily: "Cairo-Bold",
          }}
          className="iconeFilterSearch"
          onClick={() => {
            props.submit();
          }}
        >
          {t("GLOBAL.SEARCH_SEARCH_BUTTON")}
        </Button>
    </Box>
  );
}
