import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify';
import { Box, Typography, Autocomplete, TextField } from '@mui/material'
import swal from 'sweetalert';
import compoundAPI from '../../../Global/Network/Compound';
import blockAPI from '../../../Global/Network/Block';
import buildingAPI from '../../../Global/Network/Building';
import CustomTextField from '../../../General/CustomComponents/CustomTextField';
import BulkDeleteFooter from './BulkDeleteFooter';
import BuildingSelection from './BuildingSelection';



export default function BulkDeleteBuildings(props) {
  const [t] = useTranslation("common");
  const controller = new AbortController();
  const [loading, setLoading] = useState(true)

  const [compounds, setCompounds] = useState([])
  const [blocks, setBlocks] = useState([])
  const [buildings, setBuildings] = useState([])


  const [selectedCompound, setSelectedCompound] = useState(null)
  const [selectedBlock, setSelectedBlock] = useState(null)
  const [selectedBuilding, setSelectedBuilding] = useState(null)

  const [from, setFrom] = useState("")
  const [to, setTo] = useState("")




  useEffect(() => {
    loadCompounds()

    return () => {

    }
  }, [])

  useEffect(() => {
    loadBlocks()

    return () => {

    }
  }, [selectedCompound])

  useEffect(() => {
    loadBuildings()

    return () => {

    }
  }, [selectedBlock])


  const loadCompounds = async () => {
    setLoading(true)

    try {
      const result = await compoundAPI.compoundsList({

        signal: controller?.signal
      })
      if (result.status) {
        let list = [];
        result?.data && result?.data?.length > 0 && result?.data?.map((item) => {
          list.push({
            label: item?.name,
            id: item?.id,
            images: item?.images
          })
        })
        setCompounds(list)
        if (list?.length > 0) {
          setSelectedCompound(list[0])



        }
      }




    } catch (err) {
      console.log(err?.message)
      toast.error(err?.message)
    }
    setLoading(false)
  }
  const loadBlocks = async () => {
    if (!selectedCompound || !selectedCompound?.id) {

      setBuildings([])
      setBlocks([])
      return
    }
    setLoading(true)


    try {
      const result = await blockAPI.blocksList({
        params: {
          compound_id: selectedCompound?.id,

        },
        signal: controller?.signal
      })
      if (result.status) {
        let list = [];
        result?.data && result?.data?.length > 0 && result?.data?.map((item) => {
          list.push({
            label: item?.name,
            id: item?.id,
            images: item?.images
          })
        })
        setBlocks(list)
      }
    } catch (err) {
      console.log(err?.message)
      toast.error(err?.message)
    }
    setLoading(false)
  }
  const loadBuildings = async () => {
    if (!selectedBlock || !selectedBlock?.id) {
      setBuildings([])
      return
    }
    setLoading(true)
    try {
      const result = await buildingAPI.buildingsList({
        params: {
          block_id: selectedBlock?.id,

        },
        signal: controller?.signal
      })
      if (result.status) {
        let list = [];
        result?.data && result?.data?.length > 0 && result?.data?.map((item) => {
          list.push({
            label: item?.name,
            id: item?.id,
            images: item?.images
          })
        })
        setBuildings(list)
      }
    } catch (err) {
      console.log(err?.message)
      toast.error(err?.message)
    }
    setLoading(false)
  }

  const submit = () => {
    if (selectedBuildings?.length<=0 ) {
      console.log("selectedBuildings",selectedBuildings);
      swal(`${t('BULK_DELETE_BUILDING.please_select_building')}`, {
        icon: "warning",
      });
      return
    }
    swal({
      title: t('GLOBAL.are_you_sure'),
      text: t('BULK_DELETE_BUILDING.delete_process_will_be_started'),
      icon: 'warning',
      buttons: [t('GLOBAL.no'), t('GLOBAL.yes')],
      dangerMode: true,
    }).then(async (willDelete) => {

      if (willDelete) {

        try {
          setLoading(true)

          let result = null;


          result = await buildingAPI.bulkDelete({
            data: {
              building_ids: selectedBuildings
            }
          });


          if (result?.status) {
            setLoading(false)
            if(result?.data?.unlinked_floors)
            {
              swal(result?.data?.message, {
                icon: "warning",
              });
            }
            else{
              swal(`${t('BULK_DELETE_BUILDING.deleted_successfully')}`, {
                icon: "success",
              });
            }
            

            
            clear()
          } else {
            setLoading(false)
            if (result?.errorMessage) {
              toast.error(result?.errorMessage)
            } else
              toast.error(t('BULK_DELETE_BUILDING.deleted_failed'))
          }
          setLoading(false)
        } catch (error) {
          setLoading(false)
          toast.error(t(error?.message))
        }
      } else {
        // alert('canceled')
      }
    })
  }

  const clear = () => {
    setSelectedCompound(null)
    setSelectedBlock(null)
    setSelectedBuilding(null)

    setFrom("")
    setTo("")
  }

  const [selectedBuildings, setSelectedBuildings] = useState([])


  return (
    <Box sx={{
      // padding: '10px',
      // paddingTop: '20px',
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      width: '100%',
      justifyContent: 'center',
      alignItems: 'start',
      overflowX: 'hidden',

    }}
      className=" pt-3 mt-1"

    >
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'start',
        paddingLeft: '20px',
        paddingRight: '20px'


      }}>
        <Typography
          sx={{ fontFamily: 'Cairo-Bold', marginTop: '20px', marginBottom: '10px' }}
        >
          {t('BULK_DELETE_BUILDING.select_block_to_delete_buildings_from') + ":"}
        </Typography>

        <Box sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: 'column',
          width: '100% !important',
        }}>

          <Autocomplete
            sx={{
              ml: 1.5, mt: 1, mb: 1, width: '95%',
              '& .MuiFormLabel-root,& .MuiInputBase-input': {
                fontFamily: 'Cairo-Medium'
              }
            }}
            options={compounds ? compounds : []}

            value={selectedCompound}
            onChange={(e, v, r) => {
              if (v) {
                setSelectedCompound(v)
                setSelectedBlock(null)
                setSelectedBuilding(null)
              }

            }}
            renderInput={(params) => (
              <TextField {...params}
                placeholder={t('BULK_DELETE_BUILDING.select_compound')}
                variant="standard" sx={{ fontFamily: 'Cairo-Medium' }} />
            )}
          />
          <Autocomplete
            sx={{
              ml: 1.5, mt: 1, mb: 1, width: '95%',
              '& .MuiFormLabel-root,& .MuiInputBase-input': {
                fontFamily: 'Cairo-Medium'
              }
            }}
            options={blocks ? blocks : []}

            value={selectedBlock}
            onChange={(e, v, r) => {
              if (!v) {
                setSelectedBlock(null)
                setSelectedBuilding(null)
              }
              else {
                setSelectedBlock(v)
                setSelectedBuilding(null)

              }

            }}
            renderInput={(params) => (
              <TextField {...params}
                placeholder={t('BULK_DELETE_BUILDING.select_block')}
                variant="standard" sx={{ fontFamily: 'Cairo-Medium' }} />
            )}
          />
          

        </Box>
        {selectedBlock?.id ?
          <Box sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-start",
            flexDirection: 'column',
            width: '100% !important',
          }}>

            <Typography
              sx={{ fontFamily: 'Cairo-Medium', marginTop: '20px' }}
            >
              {t('BULK_DELETE_BUILDING.selected_block') + ":" + selectedBuilding?.label}
            </Typography>
            <Typography
              sx={{ fontFamily: 'Cairo-Bold', marginTop: '20px', marginBottom: '10px' }}
            >
              {t('BULK_DELETE_BUILDING.select_building_range') + ":"}
            </Typography>
            <Box
              sx={{
                flex: 1,
                display: 'flex',
                flexDirection: 'row',
                width: '100%'
              }}
            >
              <BuildingSelection
                blockId={selectedBlock?.id}
                buildingId={selectedBuilding?.id}
                listOfSelectedRows={selectedBuildings}
                setListOfSelectedRows={setSelectedBuildings}
              />
            </Box>

            <BulkDeleteFooter
              submit={submit}
              clear={clear}
            />
          </Box> : null}

      </Box>
    </Box>
  )
}
