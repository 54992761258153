import { Avatar, Box, IconButton, Typography } from '@mui/material'
import React, { useState } from 'react'
import CreateIcon from '@mui/icons-material/Create';
import DeleteIcon from '@mui/icons-material/Delete';

const MessageCard = (props) => {
    const [showActions, setShowActions] = useState(false)
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          background: '#EFF4F8 0% 0% no-repeat padding-box',
          boxShadow: '0px 3px 6px #00000029',
          opacity: 1,
          width: 'fit-content',
          marginBottom: '12px',
          position: 'relative',
        }}
        onMouseEnter={() => {
          setShowActions(true)
        }}
        onMouseLeave={() => {
          setShowActions(false)
        }}
      >
  
        <Avatar
          sx={{ bgcolor: '#994D1E', marginTop: '15px', marginRight: '15px', marginLeft: '15px' }}
          alt={props?.message?.by_name}
          src="/broken-image.jpg"
        />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            paddingTop: '20px',
            paddingBottom: '20px',
            paddingRight: '16px'
          }}
        >
  
          <Typography
            sx={{
              textAlign: 'left',
              font: 'normal normal 600 11px/20px Cairo',
              letterSpacing: '0px',
              color: '#7D8185',
              opacity: 1,
              display: 'flex'
            }}
          >
            سجل بواسطة
  
            <Typography
              sx={{
                textAlign: 'left',
                font: 'normal normal bold 15px/21px Cairo',
                letterSpacing: '0px',
                color: '#212121',
                opacity: 1,
                marginLeft: '5px',
                marginRight: '5px',
              }}>
              {props?.message?.by_name}
            </Typography>
            شركة جهزني لتجهيز العقارات
  
            <Typography
              sx={{
                textAlign: 'left',
                font: 'normal normal 300 10px/22px Cairo',
                letterSpacing: '0px',
                color: '#212121',
                opacity: 1,
                marginLeft: '5px',
                marginRight: '5px',
  
              }}>
              {props?.message?.time}
            </Typography>
          </Typography>
          <Typography
            sx={{
              textAlign: 'left',
              font: 'normal normal normal 12px/18px Cairo',
              letterSpacing: '0px',
              color: '#212121',
              opacity: 1,
              marginTop: '10px'
            }}>
            {props?.message?.text}
          </Typography>
  
        </Box>
        <Box
          sx={{
            position: 'absolute',
            top: '-13px',
            right: '10px',
            height: 'max-content',
            backgroundColor: '#FFFFFF',
            display: showActions ? 'block' : 'none'
          }}
        >
          <IconButton
            sx={{
              padding: 0,
              margin: 0,
              width: '25px',
              height: '25px',
              "&:hover": {
                borderRadius: '0px',
                backgroundColor:'#994D1E86'
              }
            }}
            onClick={() => {
              console.log("hello");
            }}
          >
            <CreateIcon sx={{color:'#994D1E'}}/>
          </IconButton>
          <IconButton
            sx={{
              padding: 0,
              margin: 0,
              width: '25px',
              height: '25px',
              "&:hover": {
                borderRadius: '0px',
                backgroundColor:'#994D1E86'
              }
            }}
            onClick={() => {
              console.log("hello");
            }}
          >
            <DeleteIcon  sx={{color:'#994D1E'}} />
          </IconButton>
        </Box>
      </Box >
    )
  }

export default MessageCard