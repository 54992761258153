import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { Box, TextField, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import Popup from "../../General/Popup";
import {
  WrapperOfLogin,
  ContainerOfLoginForm,
  TextLogin,
  LeftSide,
  RightSide,
  ContainerOfNewPasswordFields,
} from "../Login/ThemeModal";
import { TitleText, ContainerOfInputLogin } from "../Login/ThemeModal";
import MilsetoneLogo from "../../assets/img/Milestonelogo.png";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Checkbox from "@mui/material/Checkbox";
import SendMessageImage from "../../assets/img/ForgetPasswordIcon.png";
import SendIcon from '@mui/icons-material/Send';

import { useSelector, useDispatch } from "react-redux";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function NewPasswordGenerater(props) {
  const [t] = useTranslation("common");
  const logintheme = useSelector((state) => state.themeData.logintheme);
  const [NewUserPassword, setNewUserPassword] = useState("");
  const [confirmUserPassword, setConfirmUserPassword] = useState("");
  const screenwidth = useSelector((state) => state.settingsData.screenwidth);

  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);

  const closePopupFunction = () => {
    props?.setOpen(false);
  };
  const handleNewPasswordChange = (e) => {
    setNewUserPassword(e.target.value);
  };
  const handleConfirmPasswordChange = (e) => {
    setConfirmUserPassword(e.target.value);
  };

  const setShowPasswordField1 = () => {
    setShowPassword1(true);
  };
  const setShowPasswordField2 = () => {
    setShowPassword2(true);
  };

  const setHidePasswordField1 = () => {
    setShowPassword1(false);
  };
  const setHidePasswordField2 = () => {
    setShowPassword2(false);
  };
  const completeRestPassword = () => {
    props?.setShowNewUserPasswordForm(false);
  };

  return (
    <Popup
      open={props?.showNewUserPasswordForm}
      setOpen={props?.setShowNewUserPasswordForm}
      sizeType="md"
      CustomeWidth="637px"
      CustomeHeight={
        screenwidth <= 900 ? (screenwidth <= 700 ? "350px" : "330px") : "317px"
      }
      title={t("LOGIN.reset_password_title")}
      hasButton1={false}
      hasButton2={false}
      handlebutton1={null}
      handlebutton2={null}
      button1Title={t("GLOBAL.save_button")}
      button2Title={t("GLOBAL.cancel_button")}
      hasTitle={false}
    >
      <Box sx={{ width: "100%", height: "100%" }}>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <TitleText
            variant="p"
            logintheme={logintheme}
            sx={{
              fontSize: {
                xs: "18px !important",
                sm: "18px !important",
                md: "20px !important",
                lg: "21px !important",
                xl: "25px !important",
              },
            }}
          >
            {t("LOGIN.new_password_form_title")}
          </TitleText>
        </Box>
        <ContainerOfNewPasswordFields
          logintheme={logintheme}
          customeWidth={"100%"}
        >
          <Box
            sx={{
              position: "relative",
              margin: "5px 0",
              width: "100%",
              backgroundColor: "#fff",
            }}
          >
            <TextField
              id="standard-basic"
              variant="filled"
              label={t("LOGIN.user_password")}
              value={NewUserPassword}
              onChange={handleNewPasswordChange}
              className="user-password"
              type={showPassword1 ? "text" : "password"}
            />
            {!showPassword1 ? (
              <VisibilityIcon
                className="passwordIcon"
                onClick={setShowPasswordField1}
              />
            ) : (
              <VisibilityOffIcon
                className="passwordIcon"
                onClick={setHidePasswordField1}
              />
            )}
          </Box>

          <Box
            sx={{
              position: "relative",
              margin: "5px 0",
              width: "100%",
              backgroundColor: "#fff",
            }}
          >
            <TextField
              id="standard-basic"
              variant="filled"
              label={t("LOGIN.confirm_user_password")}
              value={confirmUserPassword}
              onChange={handleConfirmPasswordChange}
              className="user-password"
              type={showPassword2 ? "text" : "password"}
            />
            {!showPassword2 ? (
              <VisibilityIcon
                className="passwordIcon"
                onClick={setShowPasswordField2}
              />
            ) : (
              <VisibilityOffIcon
                className="passwordIcon"
                onClick={setHidePasswordField2}
              />
            )}
          </Box>
        </ContainerOfNewPasswordFields>

        <Box
          sx={{
            display: "flex",
            alignContent: "center",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          <Typography
            variant="span"
            sx={{
              textAlign: "left",
              textDecoration: "underline",
              font: "normal normal medium 18px/37px Cairo",
              letterSpacing: "1px",
              color: logintheme?.primaryColor,
              opacity: "1",
              cursor: "pointer",
              margin: "0 10px",
            }}
            onClick={completeRestPassword}
          >
            {t("LOGIN.save_changes_passwords")}
          </Typography>
          {/* <img
            src={SendMessageImage}
            style={{
              width: "20px",
              height: "20px",
              transform: "matrix(-1, 0, 0, -1, 0, 0)",
              background:
                "transparent url('img/ForgetPasswordIcon.png') 0% 0% no-repeat padding-box",
              opacity: "1",
              cursor: "pointer",
            }}
            onClick={completeRestPassword}
          /> */}
           <SendIcon
            sx={{
              fontSize:'30px',
              color:logintheme?.iconsColor,
              cursor: "pointer",
              transform: 'matrix(-1, 0, 0, -1, 0, 0)',
              cursor: "pointer",
              width: "20px",
              height: "20px",
            }}
            onClick={completeRestPassword}
          />
        </Box>
      </Box>
    </Popup>
  );
}
