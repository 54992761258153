import callAPI from '../ApiConfig'
import store from '../../../app/store'

const invoices = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"invoices",
        method:"get",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
    
}
const invoiceList = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"invoices_list",
        method:"get",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
    
}
const Addinvoice = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"invoices",
        method:"post",
        signal:props?.signal,
        params:props?.params,
        data:props?.data,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
    
}
const updateOrder = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"invoices/"+props?.data?.id,
        method:"put",
        signal:props?.signal,
        params:props?.params,
        data:props?.data,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
    
}
const deleteinvoice = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"invoices/"+props?.data?.id,
        method:"delete",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
    
}
const generateOrderInvoices = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"invoice/generate_invoices/"+props?.data?.id,
        method:"post",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
    
}

const calculateDiscountedPrice = async (props) => { 
    const storeState = store.getState();
    
    return await callAPI({
        route:"invoice/calculate_discounted_price",
        method:"get",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
}


const invoice = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"invoice",
        method:"get",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
    
}

const invoiceAPI={
    invoices:invoices,
    invoiceList:invoiceList,
    Addinvoice:Addinvoice,
    updateOrder:updateOrder,
    deleteinvoice:deleteinvoice,
    calculateDiscountedPrice:calculateDiscountedPrice,
    invoice:invoice,
    generateOrderInvoices:generateOrderInvoices
}
export default invoiceAPI