import callAPI from '../ApiConfig'
import store from '../../../app/store'

const getRolesOfUser = async (props) => {
    const storeState = store.getState();
    return await callAPI({
        route:"user_roles",
        method:"get",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
    
}

const setUserRolesBulk = async (props) => {
    const storeState = store.getState();
    return await callAPI({
        route:"role_users/bulk_sync",
        method:"post",
        signal:props?.signal,
        params:props?.params,
        data:props?.data,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
    
} 



const RolesOfUserAPI={
    GetRolesOfUser:getRolesOfUser,
    setUserRolesBulk:setUserRolesBulk

}
export default RolesOfUserAPI