import callAPI from '../ApiConfig'
import store from '../../../app/store'

const imagePolygons = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"image_polygons",
        method:"get",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
    
}
const imagePolygonsList = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"image_polygons_list",
        method:"get",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
    
}
const addImagePolygon = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"image_polygons/bulk_sync",
        method:"post",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        },
        data:props?.data,
    })
    
}
const updateImagePolygon = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"image_polygons/"+props?.data?.id,
        method:"put",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        },
        data:props?.data
    })
    
}
const deleteImagePolygon = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"image_polygons/"+props?.data?.id,
        method:"delete",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        },
    })
    
}
const ImagePolygonsAPI={
    imagePolygons:imagePolygons,
    imagePolygonsList:imagePolygonsList,
    addImagePolygon:addImagePolygon,
    updateImagePolygon:updateImagePolygon,
    deleteImagePolygon:deleteImagePolygon

}
export default ImagePolygonsAPI