import React, { useState, useEffect } from 'react'
import IraqMap from '../../Global/IraqMap/IraqMap'
import compoundAPI from '../../Global/Network/Compound'
import Preloading from '../../Global/Preload/Preloading'
import CloseIcon from '@mui/icons-material/Close';
import { Box, Divider, IconButton, Typography,TextField,Autocomplete,Rating} from '@mui/material'
import { useTranslation } from "react-i18next";
import CompoundBlock from './CompoundBlock'

function AvailableCompounds() {
  const [t] = useTranslation("common");
  const [loading, setLoading] = useState(false)
  const [rows, setRows] = useState([])
  const controller = new AbortController();
  useEffect(() => {

    loadData()

    return () => {
      // controller.abort()
    }
  }, [])

  const loadData = async (searchParams) => {
    setLoading(true)

    const result = await compoundAPI.compoundsList({
      params: {
        ...searchParams
      },
      signal: controller?.signal
    })

    setLoading(false)
    if (result.status) {
      setRows(result?.data)
    }
    else {

    }

  }
  return (
    <Box style={{
      // padding: '10px',
      // paddingTop: '20px',
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      width: '100%',
      justifyContent: 'center',
      alignItems: 'start',
      overflowX: 'hidden',

    }}
    className=" pt-3 mt-1"
    >
        <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              height: '100%',
              justifyContent: 'center',
              alignItems: 'stretch',
              // backgroundColor: '#fff',
              position: 'relative',
              // top:'-25px'
            }}
         >
          <Box sx={{width:'100%',display:'flex',justifyContent:'center',alignItems:'center'}}>
            <Typography
               variant='h4'
               sx={{
                  marginLeft: '10px',
                  marginBottom: '10px',
                  fontFamily: 'Cairo-Bold',
                  fontSize: '16px'
               }}>
               {t('CUSTOMER.select_compound')}
            </Typography>
         </Box>
          
         </Box>

         <Divider
            sx={{
               backgroundColor: '#EBF2F7',
               margin: '30px',
               marginTop:'10px',
               width:'100%',
               marginBottom:'0',
            }}
            style={{
               height: '3px',

            }}
         />

      {
      loading ? <Preloading /> :
          <Box
          sx={{
            padding: '10px',
            // paddingTop: '20px',
            display: 'flex',
            flexDirection: 'column',
            height: 'auto',
            width: '100%',
            justifyContent: 'start',
            alignItems: 'start',
            paddingBottom:0,
            paddingTop:0
          }}>
          <IraqMap
            compounds={rows}
          />
        </Box>
      }
    </Box>
  )
}

export default AvailableCompounds