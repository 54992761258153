import React, { useState } from "react";
import Popup from "../../General/Popup";
import { Box, Typography, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { TitleText, ContainerOfRecivedSMSCode } from "../Login/ThemeModal";
import { useSelector, useDispatch } from "react-redux";
import SendMessageImage from "../../assets/img/ForgetPasswordIcon.png";

export default function RecivedSMSCode(props) {
  const [t] = useTranslation("common");
  const logintheme = useSelector((state) => state.themeData.logintheme);
  const screenwidth = useSelector((state) => state.settingsData.screenwidth);
  const [phone, setPhone] = useState(null);
  const [smsCode, setSMSCode] = useState({
    code1: null,
    code2: null,
    code3: null,
    code4: null,
    code5: null,
    code6: null,
  });
  const setPhoneData = (e) => {
    setPhone(e.target.value);
  };

  const validateSMSCodeEnter = (name, e) => {
    let slicedData = e.target.value?.split("");
    console.log("edit=>", e.target.value);
    console.log("sdasdas", slicedData);

    if (slicedData?.length > 0) {
      let code = parseInt(slicedData[slicedData?.length - 1]);
      console.log("code", code);

      // if(code==0){
      //   code=0
      //   // return
      // }
      if (code >= 0 && code <= 9) {
        console.log("dasdasdasdas", code);
        setSMSCode({
          ...smsCode,
          [name]: code,
        });
      }
    }

    if (!e.target.value) {
      setSMSCode({
        ...smsCode,
        [name]: null,
      });
    }
  };

  const startInput = (name) => {
    setSMSCode({
      ...smsCode,
      [name]: 0,
    });
  };
  const getCodeOfSMSForCell = (SliceNumber) => {};
  const showGenerationPasswordForm = () => {
    props?.setShowRecivedSmsCodeForm(false);
    props?.setShowNewUserPasswordForm(true);
  };

  return (
    <Popup
      open={props?.showRecivedSmsCodeForm}
      setOpen={props?.setShowRecivedSmsCodeForm}
      sizeType="md"
      CustomeWidth="637px"
      CustomeHeight={
        screenwidth <= 900 ? (screenwidth <= 700 ? "350px" : "330px") : "317px"
      }
      title={t("LOGIN.reset_password_title")}
      hasButton1={false}
      hasButton2={false}
      handlebutton1={null}
      handlebutton2={null}
      button1Title={t("GLOBAL.save_button")}
      button2Title={t("GLOBAL.cancel_button")}
      hasTitle={false}
    >
      <Box sx={{ width: "100%", height: "100%" }}>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <TitleText
            variant="p"
            logintheme={logintheme}
            sx={{
              fontSize: {
                xs: "18px !important",
                sm: "18px !important",
                md: "20px !important",
                lg: "21px !important",
                xl: "25px !important",
              },
            }}
          >
            {t("LOGIN.recived_sms_code_title")}
          </TitleText>
        </Box>
        <ContainerOfRecivedSMSCode logintheme={logintheme} customeWidth="65%">
          <TextField
            fullWidth
            variant="filled"
            type={"text"}
            value={smsCode?.code1}
            onChange={(e) => validateSMSCodeEnter("code1", e)}
            className="sms-code"
            inputProps={{
              inputProps: { maxLength: 1 },
            }}
          />
          <TextField
            fullWidth
            id="standard-basic"
            variant="filled"
            type={"text"}
            inputProps={{
              inputProps: { maxLength: 1 },
            }}
            value={smsCode?.code2}
            onChange={(e) => validateSMSCodeEnter("code2", e)}
            className="sms-code"
          />
          <TextField
            fullWidth
            id="standard-basic"
            variant="filled"
            type={"text"}
            inputProps={{
              inputProps: { maxLength: 1 },
            }}
            value={smsCode?.code3}
            onChange={(e) => validateSMSCodeEnter("code3", e)}
            className="sms-code"
          />
          <TextField
            fullWidth
            id="standard-basic"
            variant="filled"
            type={"text"}
            inputProps={{
              inputProps: { maxLength: 1 },
            }}
            value={smsCode?.code4}
            onChange={(e) => validateSMSCodeEnter("code4", e)}
            className="sms-code"
          />
          <TextField
            fullWidth
            id="standard-basic"
            variant="filled"
            type={"text"}
            inputProps={{
              inputProps: { maxLength: 1 },
            }}
            value={smsCode?.code5}
            onChange={(e) => validateSMSCodeEnter("code5", e)}
            className="sms-code"
          />
          <TextField
            fullWidth
            // focused
            defaultFocused
            id="standard-basic"
            variant="filled"
            type={"text"}
            inputProps={{
              inputProps: { maxLength: 1 },
            }}
            value={smsCode?.code6}
            onChange={(e) => validateSMSCodeEnter("code6", e)}
            className="sms-code"
          />
        </ContainerOfRecivedSMSCode>
        <Box
          sx={{
            display: "flex",
            alignContent: "center",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            variant="span"
            sx={{
              textAlign: "left",
              font: "normal normal 16px/37px Cairo-Medium",
              letterSpacing: "1px",
              color: logintheme?.primaryColor,
              opacity: "1",
              cursor: "pointer",
            }}
            onClick={showGenerationPasswordForm}
          >
            {t("LOGIN.not_recivied_message")}
          </Typography>
          <Typography
            variant="span"
            sx={{
              textAlign: "left",
              textDecoration: "underline",
              font: "normal normal  16px/37px Cairo-Medium",
              letterSpacing: "1px",
              color: logintheme?.secondaryColor,
              opacity: "1",
              cursor: "pointer",
            }}
            onClick={showGenerationPasswordForm}
          >
            {t("LOGIN.reSend_message")}
          </Typography>
        </Box>
      </Box>
    </Popup>
  );
}
