import callAPI from '../ApiConfig'
import store from '../../../app/store'

const dashboard = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"dashboard",
        method:"get",
        signal:props?.signal,
        params:props?.params,
        data:props?.data,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
    
}




const DashboardAPI={
    dashboard:dashboard,


}
export default DashboardAPI