import callAPI from '../ApiConfig'
import store from '../../../app/store'

const buildings = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"buildings",
        method:"get",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    
    })
    
}

const buildingsList = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"buildings_list",
        method:"get",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    
    })
    
}
const building = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"building",
        method:"get",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    
    })
    
}

const bulkInsert = async (props) => {
    const storeState = store.getState();

    return await callAPI({
        route: "buildings/bulk_insert",
        method: "post",
        signal: props?.signal,
        params: props?.params,
        data:props?.data,
        headers: {
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }

    })

}
const bulkDelete = async (props) => {
    const storeState = store.getState();

    return await callAPI({
        route: "buildings/bulk_delete",
        method: "delete",
        signal: props?.signal,
        params: props?.params,
        data:props?.data,
        headers: {
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }

    })

}

const buildingAPI={
    buildings:buildings,
    buildingsList:buildingsList,
    bulkInsert: bulkInsert,
    bulkDelete: bulkDelete

}
export default buildingAPI