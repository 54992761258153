import callAPI from '../ApiConfig'
import store from '../../../app/store'

const payments = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"payments",
        method:"get",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
    
}
const paymentsList = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"payments_list",
        method:"get",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
    
}
const addPayment = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"payments",
        method:"post",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        },
        data:props?.data,
    })
    
}
const updatePayment = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"payments/"+props?.data?.id,
        method:"put",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        },
        data:props?.data
    })
    
}
const deletePayment = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"payments/"+props?.data?.id,
        method:"delete",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        },
    })
    
}
const PaymentsAPI={
    payments:payments,
    paymentsList:paymentsList,
    addPayment:addPayment,
    updatePayment:updatePayment,
    deletePayment:deletePayment

}
export default PaymentsAPI