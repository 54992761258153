import callAPI from '../ApiConfig'
import store from '../../../app/store'


const entityTypes={
    compounds:{
        name:"compounds",
        parent_id:"company_id"
    },
    compound:{
        name:"compound",
        parent_id:"company_id"
    },
    blocks:{
        name:"blocks",
        parent_id:"compound_id"
    },
    block:{
        name:"block",
        parent_id:"compound_id"
    },
    buildings:{
        name:"buildings",
        parent_id:"block_id"
    },
    building:{
        name:"building",
        parent_id:"block_id"
    },
    floors:{
        name:"floors",
        parent_id:"building_id"
    },
    floor:{
        name:"floor",
        parent_id:"building_id"
    },
    unit_type:{
        name:"unit_type",
        parent_id:"unit_type_id"
    },
    unit:{
        name:"unit",
        parent_id:"unit_id"
    },
    customer:{
        name:"customer",
        parent_id:"customer_id"
    },
    company:{
        name:"company",
        parent_id:"company_id"
    },
    employee:{
        name:"employee",
        parent_id:"employee_id"
    },
}

const validateRequest = (props) => { 
    if (!props?.type || !entityTypes[props?.type])
    {
        return {
            status:false,
            message:"نوع الكيان غير موجود"
        }
    }
    else{
        return {
            status:true,
            message:""
        }
    }
}
const addEntity = async (props) => {
    const storeState = store.getState();

    
    if (!validateRequest(props)?.status)
    {
        return validateRequest(props)?.status
    }
    
    var data = new FormData();

    let skip = 0
    let check= props?.type?.includes('compound');
     
    if (props?.data?.skip&&!check) {
        skip = 1
    }

    
  
    data.append('name', props?.data?.name);
    data.append('skip', skip);
    data.append('floor_number', props?.data?.floor_number);
    data.append(entityTypes[props?.type]?.parent_id, props?.data?.parent_id);
    data.append('description', props?.data?.description? props?.data?.description:'');
    if(props?.data?.compound_phase_id)
    data.append('compound_phase_id', props?.data?.compound_phase_id);

    props?.data?.images.forEach((image,index) => {
        data.append('images['+index+'][image]', image?.file);
        data.append('images['+index+'][image_type_id]', image?.image_type?.id);
        data.append('images['+index+'][name]', image?.name);
        data.append('images['+index+'][description]', image?.description);
    });
    props?.data?.addresses?.map((itm,index)=>{
        if(itm?.name)
        data.append(`addresses[${index}][name]`,itm?.name)
        if(itm?.address)
        data.append(`addresses[${index}][address]`,itm?.address)
        if(itm?.first_phone)
        data.append(`addresses[${index}][first_phone]`,itm?.first_phone)
        if(itm?.second_phone)
        data.append(`addresses[${index}][second_phone]`,itm?.second_phone)
        if(itm?.third_phone)
        data.append(`addresses[${index}][third_phone]`,itm?.third_phone)
        if(itm?.governorate)
        data.append(`addresses[${index}][governorate_id]`,itm?.governorate?.id)
        if(itm?.latitude)
        data.append(`addresses[${index}][latitude]`,itm?.latitude)
        if(itm?.longitude)
        data.append(`addresses[${index}][longitude]`,itm?.longitude)
    })

    return await callAPI({
        route:entityTypes[props?.type]?.name,
        method:"post",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Content-Type": "multipart/form-data",
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        },
        data:data
    })
    
}
const updateEntity = async (props) => {
    if (!validateRequest(props)?.status)
    {
        return validateRequest(props)?.status
    }
    const storeState = store.getState();
    
    var data = new FormData();

    
    let skip = 0
    let check= props?.type?.includes('compound');

    if (props?.data?.skip&&!check) {
        skip = 1
    }
    // if(props?.data?.name)
    data.append('name', props?.data?.name);
    data.append('skip', skip);
    data.append('floor_number', props?.data?.floor_number);

    data.append(entityTypes[props?.type]?.parent_id, props?.data?.parent_id);
    // if(props?.data?.description)
    data.append('description', props?.data?.description?props?.data?.description:' ');

    if(props?.data?.compound_phase_id)
     data.append('compound_phase_id', props?.data?.compound_phase_id);

    // props?.data?.images.forEach((image,index) => {
    //     if(image?.file){
    //         data.append('images['+index+'][image]', image?.file);
    //         data.append('images['+index+'][image_type_id]', image?.image_type?.id);
    //     }
    // });
    props?.data?.addresses?.map((itm,index)=>{
        if(itm?.name)
        data.append(`addresses[${index}][name]`,itm?.name)
        if(itm?.address)
        data.append(`addresses[${index}][address]`,itm?.address)
        if(itm?.first_phone)
        data.append(`addresses[${index}][first_phone]`,itm?.first_phone)
        if(itm?.second_phone)
        data.append(`addresses[${index}][second_phone]`,itm?.second_phone)
        if(itm?.third_phone)
        data.append(`addresses[${index}][third_phone]`,itm?.third_phone)
        if(itm?.governorate)
        data.append(`addresses[${index}][governorate_id]`,itm?.governorate?.id)
        if(itm?.latitude)
        data.append(`addresses[${index}][latitude]`,itm?.latitude)
        if(itm?.longitude)
        data.append(`addresses[${index}][longitude]`,itm?.longitude)
    })
    data.append('_method','put')

    
    return await callAPI({
        route:entityTypes[props?.type]?.name+"/"+props.data?.id,
        method:"post",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
            "Content-Type": "multipart/form-data",
        },
        data:data,
            
    })
    
}
const deleteEntity = async (props) => {
    if (!validateRequest(props)?.status)
    {
        return validateRequest(props)?.status
    }
    const storeState = store.getState();
    
    return await callAPI({
        route:entityTypes[props?.type]?.name+"/"+props.data?.id,
        method:"delete",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        },            
    })
    
}

const entity = async (props) => {
    if (!validateRequest(props)?.status)
    {
        return validateRequest(props)?.status
    }
    const storeState = store.getState();
    
    return await callAPI({
        route:entityTypes[props?.type]?.name,
        method:"get",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    
    })
    
}

const AddRequestFile = async (props) => {
    if (!validateRequest(props)?.status)
    {
        return validateRequest(props)?.status
    }
    const storeState = store.getState();
    
    return await callAPI({
        route:'images',
        method:"post",
        signal:props?.signal,
        params:props?.params,
        data:props?.data,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
            "Content-Type": "multipart/form-data",
        },            
    })
    
}
const updateRequestFile = async (props) => {
    if (!validateRequest(props)?.status)
    {
        return validateRequest(props)?.status
    }
    const storeState = store.getState();
    
    return await callAPI({
        route:'images/'+props?.id,
        method:"post",
        signal:props?.signal,
        params:props?.params,
        data:props?.data,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
            
        },            
    })
    
}
const DeleteRequestFile = async (props) => {
    if (!validateRequest(props)?.status)
    {
        return validateRequest(props)?.status
    }
    const storeState = store.getState();
    
    return await callAPI({
        route:'images/'+props?.id,
        method:"delete",
        signal:props?.signal,
        params:props?.params,
        data:{
           id: props?.id,
           type:entityTypes[props?.type]?.name
        },
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        },            
    })
    
}
const addAddress = async (props) => {
    if (!validateRequest(props)?.status)
    {
        return validateRequest(props)?.status
    }
    const storeState = store.getState();
    
    return await callAPI({
        route:'addresses',
        method:"post",
        signal:props?.signal,
        params:props?.params,
        data:{
            type:props?.type,
            type_id:props?.type_id,
            ...props?.data,
        },
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        },            
    })
    
}
const updateAddress = async (props) => {
    if (!validateRequest(props)?.status)
    {
        return validateRequest(props)?.status
    }
    const storeState = store.getState();
    
    return await callAPI({
        route:'addresses/'+props?.id,
        method:"put",
        signal:props?.signal,
        params:props?.params,
        data:{
            type:props?.type,
            type_id:props?.type_id,
            ...props?.data,
        },
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        },            
    })
    
}
const deleteAddress = async (props) => {
    if (!validateRequest(props)?.status)
    {
        return validateRequest(props)?.status
    }
    const storeState = store.getState();
    
    return await callAPI({
        route:'addresses/'+props?.id,
        method:"delete",
        signal:props?.signal,
        params:props?.params,
        data:{
           id: props?.id,
           type:entityTypes[props?.type]?.name
        },
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        },            
    })
    
}
const entityAPI={
    addEntity:addEntity,
    updateEntity:updateEntity,
    deleteEntity:deleteEntity,
    entity:entity,
    DeleteRequestFile:DeleteRequestFile,
    AddRequestFile:AddRequestFile,
    updateRequestFile:updateRequestFile,
    addAddress:addAddress,
    updateAddress:updateAddress,
    deleteAddress:deleteAddress,

}
export default entityAPI