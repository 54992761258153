import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { DrawerHeader } from "../SideMenu/SideMenuTheme";
import { useSelector } from "react-redux";
import * as ImIcon from "react-icons/im";
import * as RiIcons from "react-icons/ri";
import { IconButton } from "@mui/material";
import employeeImage from '../../assets/img/user.png'

const tempImgURL =
  "https://dev.chromeandwhyte.com/wp-content/uploads/2022/07/User-Profile-PNG.png";

export default function HeaderDrawer({
  open,
  setOpen,
  handleDrawerOpen,
  handleDrawerClose,
  isSmallWidth
}) {
  const drawerwidth = useSelector((state) => state.settingsData.drawerwidth);
  const sidemenutheme = useSelector((state) => state.themeData.sidemenutheme);
  const userData = useSelector((state) => state.userData.userData);

  const maintheme = useSelector((state) => state.themeData.maintheme);
  const styleToggleIconeMenu = {
    position: "absolute",
    top: "0px",
    left: "25px",
    fontWeight: "bold",
    cursor: "pointer",
  };
  return (
    <Box>
      {/* <IconButton onClick={handleDrawerClose}>
      {theme.direction === 'rtl' ? open?<ChevronRightIcon />:<MenuIcon/> : <ChevronLeftIcon />}
    </IconButton> */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: open ? "space-between" : "end",
          alignItems: "center",
          alignContent: "center",
          marginBottom: "10px",
          width: "100%",
          height: open ? "175px" : "100px",
          position: "relative",
        }}
      >
        {open ? (
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerClose}
            edge="start"
            sx={{
              // marginRight: 5,
              display:'flex',
              justifyContent:'center',
              alignItems:'center',
            }}
            style={styleToggleIconeMenu}
          >
            <RiIcons.RiMenuUnfoldFill
             
              size={24}
              color={sidemenutheme?.colorblack}
            />
          </IconButton>
        ) : null}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: open ? "start" : "center",
            alignItems: open ? "center" : "flex-end",
            alignContent: open ? "center" : "flex-end",
          }}
        >
          <img
            src={
              userData && userData?.image_url ? userData?.image_url : employeeImage
            }
            alt="admin-picture"
            style={{
              width: open ? "100px" : "43px",
              height: open ? "100px" : "43px",
              background: "transparent  u0% 0% no-repeat padding-box",
              borderRadius: open ? "50%" : "28px",
              opacity: "1",
              objectFit: "cover",
              backgroundPosition: "center center",
              transition: "all 0.4s ease",
              border:'1px solid lightgray',
            }}
          />
          {open ? (
            <Typography
              variant="span"
              sx={{
                textAlign: "left",
                font: "normal normal  19px/22px Cairo-Bold",
                letterSpacing: " -1px",
                color: sidemenutheme?.lightblack,
                opacity: 1,
                marginTop: "18px",
                transition: "all 0.6s ease",
              }}
            >
              {userData?.full_name}
            </Typography>
          ) : null}
          {open ? (
            <Typography
              variant="span"
              sx={{
                textِlign: "left",
                font: " normal normal  15px/20px Cairo-Light",
                letterSpacing: "0px",
                color: sidemenutheme?.lightblack,
                opacity: 1,
                marginTop: "10px",
                transition: "all 0.8s ease",
              }}
            >
              {userData?.username}
            </Typography>
          ) : null}
        </Box>
      </Box>
    </Box>
  );
}
