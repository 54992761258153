import callAPI from '../ApiConfig'
import store from '../../../app/store'

const getDepartmentsOfUser = async (props) => {
    const storeState = store.getState();
    return await callAPI({
        route:"department_users",
        method:"get",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
    
}

const setDepartmentUsersBulk = async (props) => {
    const storeState = store.getState();
    return await callAPI({
        route:"department_users/bulk_sync",
        method:"post",
        signal:props?.signal,
        params:props?.params,
        data:props?.data,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
    
} 



const departmentsOfUserAPI={
    GetRolesOfDepartment:getDepartmentsOfUser,
    setDepartmentUsersBulk:setDepartmentUsersBulk

}
export default departmentsOfUserAPI