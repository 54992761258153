import callAPI from '../ApiConfig'
import store from '../../../app/store'

const governorate = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"governorates",
        method:"get",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
    
}
const governorateList = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"governorates_list",
        method:"get",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
    
}

const GovernorateAPI={
    Governorate:governorate,
    GovernorateList:governorateList

}
export default GovernorateAPI