import {
  Box,
  Divider,
  IconButton,
  Typography,
  TextField,
  Autocomplete,
  Rating,
  FormControlLabel,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import helpers from "../../assets/js/helper";
import { toast } from "react-toastify";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  ContainerOfInput,
  ContainerOfInputFields,
  ContainerOfSelectField,
} from "./ThemDesign";
import { useSelector, useDispatch } from "react-redux";
import customerAPI from "../../Global/Network/Customer/index";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import SaveIcon from "@mui/icons-material/Save";
import * as RiIcons from "react-icons/ri";
import * as FaIcons from "react-icons/fa";
import CustomerErrors from "../../Global/Errors/Customers/Customers";
import {
  checkErrors,
  customerObjectMerge,
  resetErrors,
  restCustomeObject,
} from "../../Global/Errors/Customers/Customers";
import entityAPI from "../../Global/Network/Entity";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import CustomePhoneField from "../../General/CustomComponents/CustomePhoneField";
import CustomTextField from "../../General/CustomComponents/CustomTextField";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import generalAPI from "../../Global/Network/General";
const AddNewCustomer = () => {
  const [t] = useTranslation("common");
  const [loading, setLoading] = useState(false);
  const controller = new AbortController();
  const mainTheme = useSelector((state) => state.themeData.maintheme);
  const navigate = useNavigate();
  const { state } = useLocation();
  const [customerName, setCustomerName] = useState(null);
  const [AcademicAchievement, setAcademicAchievement] = useState(null);
  const [maritalStatus, setMaritalStatus] = useState(null);
  const [selectedMaritalStatus, setSelectedMaritalStatus] = useState(null);
  const [workType, setWorkType] = useState([]);
  const [selectedWorkType, setSelectedWorkType] = useState(null);
  const [workPlace, setWorkPlace] = useState(null);
  const [callsType, setCallsType] = useState(null);
  const [selectedcallType, setSelectedCallType] = useState(null);
  const [governorate, setGovernorate] = useState(null);
  const [selectedGovernorate, setSelectedGovernorate] = useState(null);
  const [region, setRegions] = useState(null);
  const [nearPoint, setNearPoint] = useState(null);
  const [firstPhone, setFirstPhone] = useState(null);
  const [secondPhone, setSecondPhone] = useState(null);

  const [accountProvider, setAccountProvider] = useState(null);
  const [selectedAccountProvider, setSelectedAccountProvider] = useState(null);
  const [purposeOfSell, setPurposeOfSell] = useState(null);
  const [purposeOfSellNote, setPurposeOfSellNote] = useState(null);
  const [selectedPurposeOfSell, setSelectedPurposeOfSell] = useState(null);
  const [ownUnitWithCompany, setOwnUnitWithCompany] = useState([
    {
      id: 1,
      label: "كلا",
      value: 0,
    },
    {
      id: 2,
      label: "نعم",
      value: 1,
    },
  ]);
  const [selectedOwnUnitWithCompany, setSelectedOwnUnitWithCompany] =
    useState(null);
  const [favoritePaymentMethods, setFavoritePaymentMethods] = useState(null);
  const [selectedFavoritePaymentMethods, setSelectedFavoritePaymentMethods] =
    useState([]);
  const [customerType, setCustomerType] = useState(null);
  const [SelectedCustomerType, setSelectedCustomerType] = useState(null);
  const [typeOfInterestRealEstate, setTypeOfIterestRealEstate] = useState(null);
  const [typeOfInterestCompound, setTypeOfIterestCompound] = useState(null);
  const [selectedTypeOfIterestRealEstate, setSelectedTypeOfIterestRealEstate] =
    useState([]);
  const [selectedTypeOfInterestCompound, setSelectedTypeOfIntersetCompound] =
    useState([]);
  const [relativePersone, setRelativePersone] = useState([]);
  const [relationTypes, setRelationTypes] = useState(null);
  const [ratingValue, setRatingValue] = useState(3);
  const screenwidth = useSelector((state) => state.settingsData.screenwidth);
  const isopensidemenu = useSelector(
    (state) => state.settingsData.isopensidemenu
  );
  const [customersErrorsObject, setCustomersErrorsObject] =
    useState(CustomerErrors);
  const [contactMethods, setContactMethods] = useState([]);
  const [selectedContactMethod, setSelectedContactMethod] = useState(null);
  const [addressId, setAddressid] = useState("");
  const [customerImage, setCustmerImage] = useState({
    file: null,
    name: "",
    index: null,
    url: "",
  });

  const [username, setUsername] = useState({
    value: "",
    error: false,
    message: [],
  });
  const [password, setPassword] = useState({
    value: "",
    error: false,
    message: [],
  });
  const [gender, setGender] = useState(0);

  useEffect(() => {
    loadMasterData();
  }, []);

  const loadMasterData = async () => {
    let data = null;
    try {
      setLoading(true);
      let masters = [
        {
          type: "marital_statuses",
        },
        {
          type: "working_types",
        },
        {
          type: "contact_methods",
        },
        {
          type: "governorates",
        },
        {
          type: "purpose_of_purchases",
        },
        {
          type: "employees",
        },
        {
          type: "payment_types",
        },
        {
          type: "customer_types",
        },
        {
          type: "real_estates",
        },
        {
          type: "compounds",
        },
        {
          type: "relation_types",
        },
      ];

      data = await generalAPI.getMasters({
        params: {
          masters: masters,
        },
      });
      if (data && data?.status) {
        setMaritalStatus(
          Array.isArray(data?.data?.marital_statuses)
            ? changeNameToLabel(data?.data?.marital_statuses)
            : []
        );
        setWorkType(
          Array.isArray(data?.data?.working_types)
            ? changeNameToLabel(data?.data?.working_types)
            : []
        );
        setPurposeOfSell(
          Array.isArray(data?.data?.purpose_of_purchases)
            ? changeNameToLabel(data?.data?.purpose_of_purchases)
            : []
        );
        setAccountProvider(
          Array.isArray(data?.data?.employees)
            ? changeNameToLabel(data?.data?.employees, "user", "full_name")
            : []
        );
        setContactMethods(
          Array.isArray(data?.data?.contact_methods)
            ? changeNameToLabel(data?.data?.contact_methods)
            : []
        );
        setGovernorate(
          Array.isArray(data?.data?.governorates)
            ? changeNameToLabel(data?.data?.governorates)
            : []
        );

        setFavoritePaymentMethods(
          Array.isArray(data?.data?.payment_types)
            ? changeNameToLabel(data?.data?.payment_types)
            : []
        );
        setCustomerType(
          Array.isArray(data?.data?.customer_types)
            ? changeNameToLabel(data?.data?.customer_types)
            : []
        );
        setTypeOfIterestRealEstate(
          Array.isArray(data?.data?.real_estates)
            ? changeNameToLabel(data?.data?.real_estates)
            : []
        );
        setTypeOfIterestCompound(
          Array.isArray(data?.data?.compounds)
            ? changeNameToLabel(data?.data?.compounds)
            : []
        );
        setRelationTypes(
          Array.isArray(data?.data?.relation_types)
            ? changeNameToLabel(data?.data?.relation_types)
            : []
        );
      } else {
        toast.error(t("NETWORKMESSAGE.messageError"));
      }
    } catch (err) {
      console.log(err?.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    console.log("csustomer from state", state);
    if (state && state?.data && state?.data?.customer) {
      let customer = state?.data?.customer;
      setCustomerName(customer?.user?.full_name);
      setUsername({
        ...username,
        value: customer?.user?.username,
      });

      setGender(customer?.user?.gender);
      setAcademicAchievement(customer?.academic_achievement);

      if (customer?.marital_status)
        setSelectedMaritalStatus({
          id: customer?.marital_status?.id,
          label: customer?.marital_status?.name,
        });
      if (customer?.working_type)
        setSelectedWorkType({
          id: customer?.working_type?.id,
          label: customer?.working_type?.name,
        });
      setWorkPlace(customer?.work_place_name);
      if (customer?.contact_method)
        setSelectedContactMethod({
          id: customer?.contact_method?.id,
          label: customer?.contact_method?.name,
        });
      if (customer?.addresses?.length) {
        setSelectedGovernorate({
          id: customer?.addresses[0]?.governorate?.id,
          label: customer?.addresses[0]?.governorate?.name,
        });

        setAddressid(customer?.addresses[0]?.id); //address id,

        setRegions(customer?.addresses[0]?.name);
        setNearPoint(customer?.addresses[0]?.address);
        let obj1 = handleSplitPhoneWithStateCode(
          customer?.addresses[0]?.first_phone
        );
        let obj2 = handleSplitPhoneWithStateCode(
          customer?.addresses[0]?.second_phone
        );

        setFirstPhone(obj1?.phone);
        setSecondPhone(obj2?.phone);
        setStateCode1(obj1?.code);
        setStateCode2(obj2?.code);
      }
      if (customer?.employee)
        setSelectedAccountProvider({
          id: customer?.employee?.id,
          label: customer?.employee?.user?.full_name,
        });
      if (customer?.purpose_of_purchase)
        setSelectedPurposeOfSell({
          id: customer?.purpose_of_purchase?.id,
          label: customer?.purpose_of_purchase?.name,
        });
      setPurposeOfSellNote(customer?.purpose_of_purchase_note);
      if (customer?.customer_type)
        setSelectedCustomerType({
          id: customer?.customer_type?.id,
          label: customer?.customer_type?.name,
        });
      setRatingValue(customer?.interest_degree);

      if (customer?.images?.length)
        setCustmerImage({
          file: null,
          url:
            process.env.REACT_APP_API_URL_image +
            customer?.images[0]?.image_url,
          index: customer?.images[0]?.id,
          id: customer?.images[0]?.id,
        });

      if (customer?.payment_types) {
        let arr = [];
        customer?.payment_types &&
          customer?.payment_types?.length > 0 &&
          customer?.payment_types?.map((itm) => {
            arr.push({
              label: itm?.name,
              id: itm?.id,
            });
          });
        setSelectedFavoritePaymentMethods(arr);
      }
      if (customer?.real_estates) {
        let arr = [];
        customer?.real_estates &&
          customer?.real_estates?.length > 0 &&
          customer?.real_estates?.map((itm) => {
            arr.push({
              label: itm?.name,
              id: itm?.id,
            });
          });
        setSelectedTypeOfIterestRealEstate(arr);
      }
      if (customer?.compounds) {
        let arr = [];
        customer?.compounds &&
          customer?.compounds?.length > 0 &&
          customer?.compounds?.map((itm) => {
            arr.push({
              label: itm?.name,
              id: itm?.id,
            });
          });
        setSelectedTypeOfIntersetCompound(arr);
      }
      if (customer?.customer_contacts) {
        let contacts = [];
        customer?.customer_contacts &&
          customer?.customer_contacts?.length > 0 &&
          customer?.customer_contacts?.map((itm, index) => {
            let obj1 = handleSplitPhoneWithStateCode(itm?.phone);

            contacts.push({
              index: index,
              id: itm?.id,
              real_id: itm?.id,
              name: itm?.name ? itm?.name : null,
              address: itm?.address ? itm?.address : null,
              phone: obj1?.phone,
              code: obj1?.code,
              relationType: {
                id: itm?.relation_type?.id,
                label: itm?.relation_type?.name,
              },
            });
          });
        setRelativePersone(contacts);
      }
    }
  }, [state]);

  const changeNameToLabel = (data, relation, name) => {
    let arr = [];
    data?.length > 0 &&
      data?.map((itm) => {
        let itemName = itm?.name;
        if (relation) {
          itemName = itm[relation][name ? name : "name"];
        }
        arr.push({
          label: itemName,
          id: itm?.id,
        });
      });
    return arr;
  };

  const cancelOperation = () => {
    navigate(-1);
  };
  const handleInputChange = (number, e, keyName) => {
    if (number == 1) {
      setCustomerName(e.target.value);
      restCustomeObject(customersErrorsObject, keyName);
    } else if (number == 2) {
      setAcademicAchievement(e.target.value);
      restCustomeObject(customersErrorsObject, keyName);
    } else if (number == 3) {
      setSelectedMaritalStatus(e);
      restCustomeObject(customersErrorsObject, keyName);
    } else if (number == 4) {
      setSelectedWorkType(e);
      restCustomeObject(customersErrorsObject, keyName);
    } else if (number == 5) {
      setWorkPlace(e.target.value);
      restCustomeObject(customersErrorsObject, keyName);
    } else if (number == 6) {
      setSelectedContactMethod(e);
      restCustomeObject(customersErrorsObject, keyName);
    } else if (number == 7) {
      // setSelectedCountry(e)
    } else if (number == 8) {
      setSelectedGovernorate(e);
      restCustomeObject(customersErrorsObject, keyName);
    } else if (number == 9) {
      setRegions(e.target.value);
      restCustomeObject(customersErrorsObject, keyName);
    } else if (number == 10) {
      setNearPoint(e.target.value);
      restCustomeObject(customersErrorsObject, keyName);
    } else if (number == 11) {
      setFirstPhone(e.target.value);
      restCustomeObject(customersErrorsObject, keyName);
    } else if (number == 12) {
      setSecondPhone(e.target.value);
      restCustomeObject(customersErrorsObject, keyName);
    } else if (number == 13) {
      setSelectedAccountProvider(e);
      restCustomeObject(customersErrorsObject, keyName);
    } else if (number == 14) {
      setSelectedPurposeOfSell(e);
      restCustomeObject(customersErrorsObject, keyName);
    } else if (number == 15) {
      setSelectedOwnUnitWithCompany(e);
      restCustomeObject(customersErrorsObject, keyName);
    } else if (number == 16) {
      setSelectedFavoritePaymentMethods(e);
      restCustomeObject(customersErrorsObject, keyName);
    } else if (number == 17) {
      setSelectedCustomerType(e);
      restCustomeObject(customersErrorsObject, keyName);
    } else if (number == 18) {
      setSelectedTypeOfIterestRealEstate(e);
      restCustomeObject(customersErrorsObject, keyName);
    } else if (number == 19) {
      setSelectedTypeOfIntersetCompound(e);
      restCustomeObject(customersErrorsObject, keyName);
    } else if (number == 20) {
      setPurposeOfSellNote(e.target.value);
      restCustomeObject(customersErrorsObject, keyName);
    }
    if (keyName) {
      setCustomersErrorsObject(
        restCustomeObject(customersErrorsObject, keyName)
      );
    }
  };
  const clearField = (number, keyName) => {
    if (number == 1) {
      setCustomerName("");
    } else if (number == 2) {
      setAcademicAchievement("");
    } else if (number == 3) {
      setSelectedMaritalStatus(null);
    } else if (number == 4) {
      setSelectedWorkType(null);
    } else if (number == 5) {
      setWorkPlace("");
    } else if (number == 6) {
      setSelectedContactMethod(null);
    } else if (number == 7) {
      // setSelectedCountry(null)
    } else if (number == 8) {
      setSelectedGovernorate(null);
    } else if (number == 9) {
      setRegions("");
    } else if (number == 10) {
      setNearPoint("");
    } else if (number == 11) {
      setFirstPhone("");
    } else if (number == 12) {
      setSecondPhone("");
    } else if (number == 13) {
      setSelectedAccountProvider(null);
    } else if (number == 14) {
      setSelectedPurposeOfSell(null);
    } else if (number == 15) {
      setSelectedOwnUnitWithCompany(null);
    } else if (number == 16) {
      setSelectedFavoritePaymentMethods(null);
    } else if (number == 17) {
      setSelectedCustomerType(null);
    } else if (number == 18) {
      setSelectedTypeOfIterestRealEstate(null);
    } else if (number == 19) {
      setSelectedTypeOfIntersetCompound(null);
    } else if (number == 20) {
      setPurposeOfSellNote("");
    }
    if (keyName) {
      setCustomersErrorsObject(
        restCustomeObject(customersErrorsObject, keyName)
      );
    }
  };
  const addNewRelationsData = () => {
    let data = {
      id: relativePersone?.length + 1,
      name: "",
      governorate: null,
      address: "",
      phone: "",
      relationType: null,
    };
    setRelativePersone((arr) => [...arr, data]);

    console.log("array=>", relativePersone);
  };
  const removeThisItem = (item) => {
    let arr = [];
    relativePersone &&
      relativePersone?.length > 0 &&
      relativePersone?.map((itm) => {
        if (item?.id !== itm?.id) {
          arr.push(itm);
        }
      });
    setRelativePersone(arr);
    console.log("array=>", relativePersone);
  };
  const handleRelationTypeChanged = (
    item,
    name,
    value,
    error_name,
    message_name
  ) => {
    let arr = [];
    let objectErrors = null;
    if (name == "phone")
      objectErrors = helpers?.handleValidatePhoneValue(value);
    relativePersone &&
      relativePersone?.length > 0 &&
      relativePersone?.map((itm) => {
        if (item?.id === itm?.id) {
          let data = {
            ...itm,
            [name]: value,
            [error_name]: false,
            [message_name]: [],
            messageStateCode: objectErrors?.message
              ? objectErrors?.message
              : "",
            errorStateCode: objectErrors?.error ? objectErrors?.error : false,
          };
          arr.push(data);
        } else {
          arr.push(itm);
        }
      });
    setRelativePersone(arr);
    console.log("array=>", relativePersone);
  };
  const [firstPhoneMessageError, setFirstPhoneMessageError] = useState({
    error: false,
    message: "",
  });
  const [secondPhoneMessageError, setSecondPhoneMessageError] = useState({
    error: false,
    message: "",
  });

  const [stateCode1, setStateCode1] = useState("");
  const [stateCode2, setStateCode2] = useState("");

  const handleMergePhoneWithStateCode = (phone, code) => {
    let str_phone = phone?.slice(0, 1);
    if (str_phone != 0) {
      return code + "-" + phone;
    } else {
      str_phone = phone?.slice(1);
      return code + "-" + str_phone;
    }
  };
  const handleSplitPhoneWithStateCode = (phone) => {
    if (phone?.includes("-")) {
      let temp = phone?.split("-");
      return {
        code: temp[0] ? temp[0] : "",
        phone: temp[1] ? "0" + temp[1] : "",
      };
    } else {
      return {
        code: "",
        phone: phone,
      };
    }
  };
  const submitForm = async () => {
    let message = "";
    let message2 = "";
    if (!firstPhone) {
      message += "رقم الهاتف مطلوب";
    } else if (
      firstPhone &&
      (firstPhone?.length > 11 || firstPhone?.length < 11)
    ) {
      message += "الهاتف يجب ان يساوي 11 رقم \n";
    } else if (firstPhone && isNaN(firstPhone)) {
      message += " " + "رقم الهاتف يجب ان يكون رقم";
    }
    if (firstPhone && !stateCode1) {
      message += "كود الدولة للرقم مطلوب";
    }

    if (secondPhone && (secondPhone?.length > 11 || secondPhone?.length < 11)) {
      message2 += "رقم الهاتف يجب ان يساوي 11 رقم";
    } else if (secondPhone && isNaN(secondPhone)) {
      message2 += "رقم الهاتف يجب ان يكون رقم";
    }
    if (secondPhone && !stateCode1) {
      message2 += "كود الدولة للرقم مطلوب";
    }

    if (message) {
      setFirstPhoneMessageError({
        message: message,
        error: false,
      });
    }
    if (message2) {
      setSecondPhoneMessageError({
        message: message2,
        error: false,
      });
    }
    if (message || message2) {
      return;
    }

    try {
      let real_estate_ids = [],
        compound_ids = [],
        payment_type_ids = [],
        contacts = [];
      selectedTypeOfIterestRealEstate &&
        selectedTypeOfIterestRealEstate?.length > 0 &&
        selectedTypeOfIterestRealEstate?.map((itm) => {
          real_estate_ids.push(itm?.id);
        });
      selectedTypeOfInterestCompound &&
        selectedTypeOfInterestCompound?.length > 0 &&
        selectedTypeOfInterestCompound?.map((itm) => {
          compound_ids.push(itm?.id);
        });
      selectedFavoritePaymentMethods &&
        selectedFavoritePaymentMethods?.length > 0 &&
        selectedFavoritePaymentMethods?.map((itm) => {
          payment_type_ids.push(itm?.id);
        });
      console.log("array=>", relativePersone);

      relativePersone &&
        relativePersone?.length > 0 &&
        relativePersone?.map((itm) => {
          contacts.push({
            name: itm?.name ? itm?.name : null,
            address: itm?.address ? itm?.address : null,
            phone: itm?.phone
              ? handleMergePhoneWithStateCode(itm?.phone, itm?.code)
              : null,
            relation_type_id:
              itm?.relationType && itm?.relationType?.id
                ? itm?.relationType?.id
                : null,
            id: itm?.real_id ? itm?.real_id : null,
          });
        });

      let data = {
        full_name: customerName ? customerName : null,
        academic_achievement: AcademicAchievement ? AcademicAchievement : null,
        marital_status_id:
          selectedMaritalStatus && selectedMaritalStatus?.id
            ? selectedMaritalStatus?.id
            : null,
        working_type_id:
          selectedWorkType && selectedWorkType?.id
            ? selectedWorkType?.id
            : null,
        work_place_name: workPlace ? workPlace : null,
        contact_method_id:
          selectedContactMethod && selectedContactMethod?.id
            ? selectedContactMethod?.id
            : null,
        address_governorate_id:
          selectedGovernorate && selectedGovernorate?.id
            ? selectedGovernorate?.id
            : null,
        address_name: region ? region : null,
        address: nearPoint ? nearPoint : null,
        address_first_phone: firstPhone
          ? handleMergePhoneWithStateCode(firstPhone, stateCode1)
          : null,
        address_second_phone: secondPhone
          ? handleMergePhoneWithStateCode(secondPhone, stateCode2)
          : null,
        employee_id:
          selectedAccountProvider && selectedAccountProvider
            ? selectedAccountProvider?.id
            : null,
        purpose_of_purchase_id:
          selectedPurposeOfSell && selectedPurposeOfSell?.id
            ? selectedPurposeOfSell?.id
            : null,
        purpose_of_purchase_note: purposeOfSellNote ? purposeOfSellNote : null,
        customer_type_id:
          SelectedCustomerType && SelectedCustomerType?.id
            ? SelectedCustomerType?.id
            : null,
        real_estate_ids: real_estate_ids ? real_estate_ids : null,
        compound_ids: compound_ids ? compound_ids : null,
        payment_type_ids: payment_type_ids ? payment_type_ids : null,
        interest_degree: ratingValue,
        contacts: contacts ? contacts : null,
        // phone :phoneNumber?.value ? handleMergePhoneWithStateCode(phoneNumber?.value, phoneCode?.value) : null,
        phone: firstPhone
          ? handleMergePhoneWithStateCode(firstPhone, stateCode1)
          : null,
        gender: gender==1? 1 : 0,
      };
      if(username&&username?.value){
          data={
            ...data,
            username: username?.value,
          }
      }
      if(password&&password?.value){
        data={
          ...data,
          password: password?.value,
        }
    }
      if (addressId) {
        data = {
          ...data,
          address_id: addressId,
        };
      }

      setLoading(true);
      let dumData = resetErrors(customersErrorsObject);
      let result = null;
      if (state?.data?.customer?.id) {
        result = await customerAPI.editCustomer({
          data: data,
          id: state?.data?.customer?.id,
        });
      } else {
        result = await customerAPI.addCustomer({
          data: data,
        });
      }

      if (result?.status) {
        // if(result?.data?.)
        setLoading(false);
        toast.success(t("NETWORKMESSAGE.addCustomerMessageSuccess"));

        if (!state?.data?.customer?.id) {
          clearForm();
          if(customerImage&&customerImage?.file)
          addImageToUser(result?.data?.id, null, null);
        }
      } else {
        if (typeof result.errorMessage === "object") {
          console.log("sdadasdasd", result.errorMessage);
          let updatedObject = resetErrors(customersErrorsObject);
          setCustomersErrorsObject(
            checkErrors(result.errorMessage, updatedObject)
          );

          // let count=0;
          let arr = [];
          const errorMessagesKeys = Object.keys(result.errorMessage);
          relativePersone &&
            relativePersone?.length &&
            relativePersone?.map((itm) => {
              let obj = {};
              errorMessagesKeys &&
                errorMessagesKeys?.map((key) => {
                  if (key.includes("contacts")) {
                    let arr_split = key?.split(".");
                    if (arr_split?.length > 1 && arr_split[1] == itm?.id - 1) {
                      let arrmes = [];
                      result.errorMessage[key].map((message) => {
                        arrmes.push(message);
                      });

                      obj = {
                        ...itm,
                        [`error_${arr_split[2]}`]: true,
                        [`message_${arr_split[2]}`]: arrmes,
                      };
                    }
                    //  count++;
                  }
                });
              if (obj) {
                arr.push(obj);
              } else {
                arr.push(itm);
              }
            });
          if (arr?.length > 0) setRelativePersone(arr);
          console.log("saadaffff=>", arr);
        } else {
        }

        setLoading(false);
        toast.error(t("NETWORKMESSAGE.messageError"));
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(t(error?.message));
    }
  };

  const addImageToUser = async (customerId, id, e) => {
    let images = new FormData();
    if (id) {
      images.append(`image`, e.target.files[0]);
      images.append(`type_id`, id);
      images.append(`image_type_id`, 1);
      images.append(`type`, "customer");

      // images.append(`description`,' ');
      // images.append(`name`,' ');

      // images.append(`name`,imageDetailsName);
      // images.append(`description`,imageDetailsDescription);
      let result = await entityAPI.AddRequestFile({
        data: images,
        // params: params,
        type: "customer",
      });

      if (result?.status) {
        setCustmerImage({
          file: e.target.files[0],
          url: window.URL?.createObjectURL(e.target.files[0]),
          name: e?.target?.value,
          index: 1,
        });
      } else {
      }
    } else {
      if (customerImage) {
        images.append(`image`, customerImage?.file);
        images.append(`type_id`, customerId);
        images.append(`image_type_id`, 1);
        images.append(`type`, "customer");

        // images.append(`description`,' ');
        // images.append(`name`,' ');

        // images.append(`name`,imageDetailsName);
        // images.append(`description`,imageDetailsDescription);
        let result = await entityAPI.AddRequestFile({
          data: images,
          // params: params,
          type: "customer",
        });

        if (result?.status) {
        } else {
        }
      }
    }
  };

  const clearForm = () => {
    let counter = 25;
    for (let i = 0; i < counter; i++) {
      clearField(i + 1);
    }
    setRelativePersone([]);
    setCustomersErrorsObject(resetErrors(customersErrorsObject));
  };

  const handleChangeImage = async (e) => {
    if (
      e &&
      e?.target &&
      e.target.files[0] &&
      (await handleSizeOfFile(e.target.files[0]))
    ) {
      if (state?.data?.customer?.id) {
        //  alert(1)
        if (customerImage?.id) await handleDeleteImage(customerImage?.id);
        await addImageToUser(null, state?.data?.customer?.id, e);
      } else {
        // alert(2)
        setCustmerImage({
          file: e.target.files[0],
          url: window.URL?.createObjectURL(e.target.files[0]),
          name: e?.target?.value,
          index: 1,
        });
      }
    }
  };
  const handleAddImage = (e) => {
    const inputFIled = document.getElementById("imageCustomer");

    if (inputFIled) {
      inputFIled.click();
    }
  };
  const handleSizeOfFile = async (e) => {
    if (e.size > 2097152) {
      toast.warn("حجم الملفة تجاوز الحد المسموح");
      return false;
    }
    return true;
  };

  const handleDeleteImage = async (id) => {
    let params = {};
    let result = await entityAPI.DeleteRequestFile({
      params: params,
      type: "customer",
      id: id,
    });

    if (result?.status && result?.data?.success) {
      setCustmerImage(null);
    } else {
    }
  };

  const handlePhoneChange = (name, e) => {
    let inputtxt = e;
    var phoneno = /^\d{11}$/;
    if (name == "first_phone") {
      let object = {
        error: "",
        message: false,
      };
      if (isNaN(inputtxt)) {
        setFirstPhoneMessageError({
          message: "يجب ان يكون الهاتف رقم",
          error: true,
        });
        return false;
      }

      if (e?.length == 11 && !isNaN(inputtxt)) {
        object = {
          message: "",
          error: false,
        };
      } else if (e?.length >= 11) {
        object = {
          message: "رقم الهاتف بصيغة غير صحيحة !",
          phone: true,
        };
      } else {
        object = {
          message: "",
          phone: false,
        };
      }
      setFirstPhoneMessageError({
        ...object,
      });
    } else if (name == "second_phone") {
      let object = {
        error: "",
        message: false,
      };
      if (isNaN(inputtxt)) {
        setSecondPhoneMessageError({
          message: "يجب ان يكون الهاتف رقم",
          error: true,
        });
        return false;
      }

      if (e?.length == 11 && !isNaN(inputtxt)) {
        object = {
          message: "",
          error: false,
        };
      } else if (e?.length >= 11) {
        object = {
          message: "رقم الهاتف بصيغة غير صحيحة !",
          phone: true,
        };
      } else {
        object = {
          message: "",
          phone: false,
        };
      }
      setSecondPhoneMessageError({
        ...object,
      });
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        justifyContent: "center",
        alignItems: "stretch",
        backgroundColor: "#fff",
        position: "relative",
        top: "-25px",
      }}
      className=" pt-3 mt-1"
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          flex: 1,
          height: "80px",
          position: "relative",
          top: "20px",
        }}
      >
        <CloseIcon
          size={40}
          color="#349CDD"
          sx={{
            color: "#349CDD",
            fontSize: "45px",
            position: "relative",
            left: "20px",
            cursor: "pointer",
          }}
          onClick={cancelOperation}
        />
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h4"
            sx={{
              marginLeft: "10px",
              marginBottom: "10px",
              fontFamily: "Cairo-Bold",
              fontSize: "16px",
            }}
          >
            {t("CUSTOMER.add_ccustomer")}
          </Typography>
        </Box>
      </Box>

      <Divider
        sx={{
          backgroundColor: "#EBF2F7",
          margin: "30px",
        }}
        style={{
          height: "3px",
        }}
      />
      {
        // loading ? <Preloading /> :
        <>
          <Box
            sx={{
              backgroundColor: "#FFFFFF",
              overflow: "auto",
              height: `100% !important`,
              width: "100%",
            }}
            className="row"
          >
            <Box
              className="col-md-12"
              sx={{
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                gap:'10px'
              }}
            >
              <Box
                sx={{
                  width:'55%',
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
               <Box
                  sx={{
                     width: "200px",
                     height: "200px",
                     borderRadius: "50%",
                     border: "2px dashed #1e6a99",
                     backgroundColor: "lightgray",
                     display: "flex",
                     justifyContent: "center",
                     alignItems: "center",
                     cursor: "pointer",
                     backgroundImage: customerImage?.url
                     ? `url(${customerImage?.url})`
                     : "",
                     objectFit: "cover",
                     backgroundPosition: "center center",
                     backgroundSize: "cover",
                     "&:hover": {
                     opacity: "0.6",
                     },
                  }}
                  onClick={handleAddImage}
               >
                  <input
                     type="file"
                     className="d-none imageCustomer"
                     id="imageCustomer"
                     onChange={handleChangeImage}
                     accept="image/png, image/gif, image/jpeg ,image/jpg"
                  />
                  <CameraAltIcon
                     sx={{
                     color: "#1e6a99",
                     fontSize: "27px",
                     }}
                  />
               </Box>
              </Box>

              <Box 
                sx={{
                  width:'70%',
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                }}
              >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                  width: "fit-content",
                  // marginLeft: "auto",
                  marginRight: "70px",
                  "& .MuiRating-root svg": {
                    color: mainTheme?.iconColor + " !important",
                  },
                }}
              >
                <Typography
                  component="legend"
                  style={{
                    textAlign: "right",
                    font: "normal normal  20px/22px Cairo",
                    letterSpacing: "0px",
                    color: "#707070",
                    opacity: "1",
                    marginLeft: "10px",
                  }}
                >
                  {t("CUSTOMER.rating_message")}
                </Typography>
                <Rating
                  name="simple-controlled"
                  value={ratingValue}
                  onChange={(event, newValue) => {
                    setRatingValue(newValue);
                    restCustomeObject(customersErrorsObject, "interest_degree");
                  }}
                />
              </Box>
              {customersErrorsObject?.interest_degree?.error &&
              customersErrorsObject?.interest_degree?.message?.length ? (
                <Box
                  sx={{
                    height: "fit-content",
                    padding: "5px",
                    display: "flex",
                    flexDirection: "column",
                    flexWrap: "wrap",
                    width: "95%",
                  }}
                >
                  {customersErrorsObject?.interest_degree?.message &&
                  customersErrorsObject?.interest_degree?.message?.length > 0
                    ? customersErrorsObject?.interest_degree?.message?.map(
                        (messg) => (
                          <span
                            style={{
                              fontFamily: "Cairo-Bold",
                              fontSize: "14px",
                              color: "red",
                              height: "auto",
                            }}
                          >
                            {messg}
                          </span>
                        )
                      )
                    : null}
                </Box>
              ) : null}
            </Box>
            </Box>
            <Box
              className={`col-12 col-sm-12 col-md-6 ${
                isopensidemenu && screenwidth < 1300 ? "col-lg-6" : "col-lg-4"
              } col-xlg-4 my-3 `}
            >
              <Box
                sx={{
                  width: "90%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <span
                  style={{
                    textAlign: "right",
                    font: "normal normal 17px/22px Cairo-Light",
                    letterSpacing: "0px",
                    color: "#808080",
                    opacity: "1",
                  }}
                >
                  {t("CUSTOMER.personal_information")}
                </span>
              </Box>
              <br />
              <ContainerOfInputFields
                mainTheme={mainTheme}
                customeWidth={"100%"}
              >
                <Box
                  sx={{
                    position: "relative",
                    // margin: "5px 0",
                    width: "100%",
                    backgroundColor: "#fff",
                  }}
                  className="mt-3 orderdata"
                >
                  <TextField
                    id="standard-basic"
                    variant="filled"
                    label={t("CUSTOMER.customer_name")}
                    value={customerName}
                    onChange={(e) => handleInputChange(1, e, "full_name")}
                    type={"text"}
                    // error={customersErrorsObject?.full_name?.error}
                    // helperText={customersErrorsObject?.full_name?.message}
                    //  focused={customersErrorsObject?.full_name?.error}
                    className={`${
                      customersErrorsObject?.full_name?.error
                        ? "errors"
                        : "Mui-focused"
                    }`}
                  />
                  {customerName ? (
                    <CloseIcon
                      className="closeIcon"
                      onClick={() => clearField(1, "full_name")}
                    />
                  ) : null}
                </Box>
                {customersErrorsObject?.full_name?.error &&
                customersErrorsObject?.full_name?.message?.length ? (
                  <Box
                    sx={{
                      height: "fit-content",
                      padding: "5px",
                      display: "flex",
                      flexDirection: "column",
                      flexWrap: "wrap",
                      width: "95%",
                    }}
                  >
                    {customersErrorsObject?.full_name?.message &&
                    customersErrorsObject?.full_name?.message?.length > 0
                      ? customersErrorsObject?.full_name?.message?.map(
                          (messg) => (
                            <span
                              style={{
                                fontFamily: "Cairo-Bold",
                                fontSize: "14px",
                                color: "red",
                                height: "auto",
                              }}
                            >
                              {messg}
                            </span>
                          )
                        )
                      : null}
                  </Box>
                ) : null}
                <Box
                  sx={{
                    position: "relative",
                    // margin: "10px 0",
                    width: "100%",
                    backgroundColor: "#fff",
                  }}
                  className="mt-3 mb-3"
                >
                  <TextField
                    id="standard-basic"
                    variant="filled"
                    label={t("CUSTOMER.AcademicAchievement")}
                    value={AcademicAchievement}
                    onChange={(e) =>
                      handleInputChange(2, e, "academic_achievement")
                    }
                    type={"text"}
                    // focused={customersErrorsObject?.academic_achievement?.error}
                    className={`${
                      customersErrorsObject?.academic_achievement?.error
                        ? "errors"
                        : ""
                    }`}
                  />
                  {AcademicAchievement ? (
                    <CloseIcon
                      className="closeIcon"
                      onClick={() => clearField(2, "academic_achievement")}
                    />
                  ) : null}
                </Box>
                {customersErrorsObject?.academic_achievement?.error &&
                customersErrorsObject?.academic_achievement?.message?.length ? (
                  <Box
                    sx={{
                      height: "fit-content",
                      padding: "5px",
                      display: "flex",
                      flexDirection: "column",
                      flexWrap: "wrap",
                      width: "95%",
                    }}
                  >
                    {customersErrorsObject?.academic_achievement?.message &&
                    customersErrorsObject?.academic_achievement?.message
                      ?.length > 0
                      ? customersErrorsObject?.academic_achievement?.message?.map(
                          (messg) => (
                            <span
                              style={{
                                fontFamily: "Cairo-Bold",
                                fontSize: "14px",
                                color: "red",
                                height: "auto",
                              }}
                            >
                              {messg}
                            </span>
                          )
                        )
                      : null}
                  </Box>
                ) : null}
              </ContainerOfInputFields>

              <ContainerOfSelectField
                mainTheme={mainTheme}
                customeWidth={"100%"}
                hasError={customersErrorsObject?.marital_status_id?.error}
              >
                <Box
                  sx={{
                    position: "relative",
                    // margin: "10px 0",
                    width: "100%",
                    backgroundColor: "#fff",
                  }}
                  // className="mt-3"
                >
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={maritalStatus ? maritalStatus : []}
                    value={selectedMaritalStatus}
                    onChange={(e, newValue) =>
                      handleInputChange(3, newValue, "marital_status_id")
                    }
                    popupIcon={<RiIcons.RiArrowDropDownLine />}
                    clearIcon={
                      <CloseIcon
                        sx={{ fontSize: "20px", color: mainTheme?.iconColor }}
                        onClick={() => clearField(3, "marital_status_id")}
                      />
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t("CUSTOMER.maritalStatus")}
                        variant="filled"
                      />
                    )}
                    className={`${customersErrorsObject?.full_name?.error}`}
                  />
                </Box>
                {customersErrorsObject?.marital_status_id?.error &&
                customersErrorsObject?.marital_status_id?.message?.length ? (
                  <Box
                    sx={{
                      height: "fit-content",
                      padding: "5px",
                      display: "flex",
                      flexDirection: "column",
                      flexWrap: "wrap",
                      width: "95%",
                    }}
                  >
                    {customersErrorsObject?.marital_status_id?.message &&
                    customersErrorsObject?.marital_status_id?.message?.length >
                      0
                      ? customersErrorsObject?.marital_status_id?.message?.map(
                          (messg) => (
                            <span
                              style={{
                                fontFamily: "Cairo-Bold",
                                fontSize: "14px",
                                color: "red",
                                height: "auto",
                              }}
                            >
                              {messg}
                            </span>
                          )
                        )
                      : null}
                  </Box>
                ) : null}
              </ContainerOfSelectField>

              <ContainerOfSelectField
                mainTheme={mainTheme}
                customeWidth={"100%"}
                hasError={customersErrorsObject?.working_type_id?.error}
                className="mt-3"
              >
                <Box
                  sx={{
                    position: "relative",
                    // margin: "10px 0",
                    width: "100%",
                    backgroundColor: "#fff",
                  }}
                  // className="mt-3"
                >
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={workType ? workType : []}
                    value={selectedWorkType}
                    onChange={(e, newValue) =>
                      handleInputChange(4, newValue, "working_type_id")
                    }
                    popupIcon={<RiIcons.RiArrowDropDownLine />}
                    clearIcon={
                      <CloseIcon
                        sx={{ fontSize: "20px", color: mainTheme?.iconColor }}
                        onClick={() => clearField(4, "working_type_id")}
                      />
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t("CUSTOMER.workType")}
                        variant="filled"
                      />
                    )}
                    className={`${customersErrorsObject?.working_type_id?.error}`}
                  />
                </Box>
                {customersErrorsObject?.working_type_id?.error &&
                customersErrorsObject?.working_type_id?.message?.length ? (
                  <Box
                    sx={{
                      height: "fit-content",
                      padding: "5px",
                      display: "flex",
                      flexDirection: "column",
                      flexWrap: "wrap",
                      width: "95%",
                    }}
                  >
                    {customersErrorsObject?.working_type_id?.message &&
                    customersErrorsObject?.working_type_id?.message?.length > 0
                      ? customersErrorsObject?.working_type_id?.message?.map(
                          (messg) => (
                            <span
                              style={{
                                fontFamily: "Cairo-Bold",
                                fontSize: "14px",
                                color: "red",
                                height: "auto",
                              }}
                            >
                              {messg}
                            </span>
                          )
                        )
                      : null}
                  </Box>
                ) : null}
              </ContainerOfSelectField>

              <ContainerOfInputFields
                mainTheme={mainTheme}
                customeWidth={"100%"}
              >
                <Box
                  sx={{
                    position: "relative",
                    // margin: "10px 0",
                    width: "100%",
                    backgroundColor: "#fff",
                  }}
                  className="mt-3"
                >
                  <TextField
                    id="standard-basic"
                    variant="filled"
                    label={t("CUSTOMER.workPlace")}
                    value={workPlace}
                    onChange={(e) => handleInputChange(5, e, "work_place_name")}
                    type={"text"}
                    focused={customersErrorsObject?.work_place_name?.error}
                    className={`${
                      customersErrorsObject?.work_place_name?.error
                        ? "errors"
                        : ""
                    }`}
                  />
                  {workPlace ? (
                    <CloseIcon
                      className="closeIcon"
                      onClick={() => clearField(5, "work_place_name")}
                    />
                  ) : null}
                </Box>
                {customersErrorsObject?.work_place_name?.error &&
                customersErrorsObject?.work_place_name?.message?.length ? (
                  <Box
                    sx={{
                      height: "fit-content",
                      padding: "5px",
                      display: "flex",
                      flexDirection: "column",
                      flexWrap: "wrap",
                      width: "95%",
                    }}
                  >
                    {customersErrorsObject?.work_place_name?.message &&
                    customersErrorsObject?.work_place_name?.message?.length > 0
                      ? customersErrorsObject?.work_place_name?.message?.map(
                          (messg) => (
                            <span
                              style={{
                                fontFamily: "Cairo-Bold",
                                fontSize: "14px",
                                color: "red",
                                height: "auto",
                              }}
                            >
                              {messg}
                            </span>
                          )
                        )
                      : null}
                  </Box>
                ) : null}
              </ContainerOfInputFields>

              <ContainerOfSelectField
                mainTheme={mainTheme}
                customeWidth={"100%"}
                hasError={customersErrorsObject?.contact_method_id?.error}
              >
                <Box
                  sx={{
                    position: "relative",
                    width: "100%",
                    backgroundColor: "#fff",
                  }}
                  className="mt-3"
                >
                  <Autocomplete
                    // disablePortal
                    id="combo-box-demo"
                    options={contactMethods ? contactMethods : []}
                    value={selectedContactMethod}
                    onChange={(e, newValue) =>
                      handleInputChange(6, newValue, "contact_method_id")
                    }
                    popupIcon={<RiIcons.RiArrowDropDownLine />}
                    clearIcon={
                      <CloseIcon
                        sx={{ fontSize: "20px", color: mainTheme?.iconColor }}
                        onClick={() => clearField(6, "contact_method_id")}
                      />
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t("CUSTOMER.callsType")}
                        variant="filled"
                      />
                    )}
                    className={`${customersErrorsObject?.contact_method_id?.error}`}
                  />
                </Box>
                {customersErrorsObject?.contact_method_id?.error &&
                customersErrorsObject?.contact_method_id?.message?.length ? (
                  <Box
                    sx={{
                      height: "fit-content",
                      padding: "5px",
                      display: "flex",
                      flexDirection: "column",
                      flexWrap: "wrap",
                      width: "95%",
                    }}
                  >
                    {customersErrorsObject?.contact_method_id?.message &&
                    customersErrorsObject?.contact_method_id?.message?.length >
                      0
                      ? customersErrorsObject?.contact_method_id?.message?.map(
                          (messg) => (
                            <span
                              style={{
                                fontFamily: "Cairo-Bold",
                                fontSize: "14px",
                                color: "red",
                                height: "auto",
                              }}
                            >
                              {messg}
                            </span>
                          )
                        )
                      : null}
                  </Box>
                ) : null}
              </ContainerOfSelectField>
            </Box>
            <Box
              className={`col-12 col-sm-12 col-md-6 ${
                isopensidemenu && screenwidth < 1300 ? "col-lg-6" : "col-lg-4"
              } col-xlg-4 my-3 `}
            >
              <Box
                sx={{
                  width: "90%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <span
                  style={{
                    textAlign: "right",
                    font: "normal normal 17px/22px Cairo-Light",
                    letterSpacing: "0px",
                    color: "#808080",
                    opacity: "1",
                  }}
                >
                  {t("CUSTOMER.address_and_connection_information")}
                </span>
              </Box>
              <br />
              <ContainerOfSelectField
                mainTheme={mainTheme}
                customeWidth={"100%"}
                hasError={customersErrorsObject?.address_governorate_id?.error}
              >
                <Box
                  sx={{
                    position: "relative",
                    // margin: "10px 0",
                    width: "100%",
                    backgroundColor: "#fff",
                  }}
                  className="mt-3"
                >
                  <Autocomplete
                    // disablePortal
                    id="combo-box-demo"
                    options={governorate ? governorate : []}
                    value={selectedGovernorate}
                    onChange={(e, newValue) =>
                      handleInputChange(8, newValue, "address_governorate_id")
                    }
                    popupIcon={<RiIcons.RiArrowDropDownLine />}
                    clearIcon={
                      <CloseIcon
                        sx={{ fontSize: "20px", color: mainTheme?.iconColor }}
                        onClick={() => clearField(8, "address_governorate_id")}
                      />
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t("CUSTOMER.governorate")}
                        className="errors"
                        variant="filled"
                      />
                    )}
                    className={`${customersErrorsObject?.address_governorate_id?.error}`}
                  />
                </Box>
                {customersErrorsObject?.address_governorate_id?.error &&
                customersErrorsObject?.address_governorate_id?.message
                  ?.length ? (
                  <Box
                    sx={{
                      height: "fit-content",
                      padding: "5px",
                      display: "flex",
                      flexDirection: "column",
                      flexWrap: "wrap",
                      width: "95%",
                    }}
                  >
                    {customersErrorsObject?.address_governorate_id?.message &&
                    customersErrorsObject?.address_governorate_id?.message
                      ?.length > 0
                      ? customersErrorsObject?.address_governorate_id?.message?.map(
                          (messg) => (
                            <span
                              style={{
                                fontFamily: "Cairo-Bold",
                                fontSize: "14px",
                                color: "red",
                                height: "auto",
                              }}
                            >
                              {messg}
                            </span>
                          )
                        )
                      : null}
                  </Box>
                ) : null}
              </ContainerOfSelectField>

              <ContainerOfInputFields
                mainTheme={mainTheme}
                customeWidth={"100%"}
              >
                <Box
                  sx={{
                    position: "relative",
                    // margin: "10px 0",
                    width: "100%",
                    backgroundColor: "#fff",
                  }}
                  className="mt-3"
                >
                  <TextField
                    id="standard-basic"
                    variant="filled"
                    label={t("CUSTOMER.region")}
                    value={region}
                    onChange={(e) => handleInputChange(9, e, "address_name")}
                    type={"text"}
                    // focused={customersErrorsObject?.address_name?.error}
                    className={`${
                      customersErrorsObject?.address_name?.error ? "errors" : ""
                    }`}
                  />
                  {region ? (
                    <CloseIcon
                      className="closeIcon"
                      onClick={() => clearField(9, "address_name")}
                    />
                  ) : null}
                </Box>
                {customersErrorsObject?.address_name?.error &&
                customersErrorsObject?.address_name?.message?.length ? (
                  <Box
                    sx={{
                      height: "fit-content",
                      padding: "5px",
                      display: "flex",
                      flexDirection: "column",
                      flexWrap: "wrap",
                      width: "95%",
                    }}
                  >
                    {customersErrorsObject?.address_name?.message &&
                    customersErrorsObject?.address_name?.message?.length > 0
                      ? customersErrorsObject?.address_name?.message?.map(
                          (messg) => (
                            <span
                              style={{
                                fontFamily: "Cairo-Bold",
                                fontSize: "14px",
                                color: "red",
                              }}
                            >
                              {messg}
                            </span>
                          )
                        )
                      : null}
                  </Box>
                ) : null}
                <Box
                  sx={{
                    position: "relative",
                    // margin: "10px 0",
                    width: "100%",
                    backgroundColor: "#fff",
                  }}
                  className="mt-3"
                >
                  <TextField
                    id="standard-basic"
                    variant="filled"
                    label={t("CUSTOMER.nearPoint")}
                    value={nearPoint}
                    onChange={(e) => handleInputChange(10, e, "address")}
                    type={"text"}
                    // focused={customersErrorsObject?.address?.error}
                    className={`${
                      customersErrorsObject?.address?.error ? "errors" : ""
                    }`}
                  />
                  {nearPoint ? (
                    <CloseIcon
                      className="closeIcon"
                      onClick={() => clearField(10, "address")}
                    />
                  ) : null}
                </Box>
                {customersErrorsObject?.address?.error &&
                customersErrorsObject?.address?.message?.length ? (
                  <Box
                    sx={{
                      height: "fit-content",
                      padding: "5px",
                      display: "flex",
                      flexDirection: "column",
                      flexWrap: "wrap",
                      width: "95%",
                    }}
                  >
                    {customersErrorsObject?.address?.message &&
                    customersErrorsObject?.address?.message?.length > 0
                      ? customersErrorsObject?.address?.message?.map(
                          (messg) => (
                            <span
                              style={{
                                fontFamily: "Cairo-Bold",
                                fontSize: "14px",
                                color: "red",
                              }}
                            >
                              {messg}
                            </span>
                          )
                        )
                      : null}
                  </Box>
                ) : null}

                <CustomePhoneField
                  value={firstPhone}
                  title={t("CUSTOMER.firstPhone")}
                  noTopMargin={false}
                  paddingHorizontal="0"
                  onChange={(e) => {
                    if (!isNaN(e?.target?.value)) {
                      setFirstPhone(e?.target?.value);
                      handlePhoneChange("first_phone", e?.target?.value);
                    }
                  }}
                  onClearClick={() => {
                    setFirstPhone("");
                    setStateCode1("");
                  }}
                  haswidth={true}
                  stateCode={stateCode1}
                  setStateCode={setStateCode1}
                  messageStateCode={firstPhoneMessageError?.message}
                  error={
                    firstPhoneMessageError?.error
                      ? true
                      : customersErrorsObject?.phone?.error
                  }
                  message={customersErrorsObject?.address_second_phone?.message}
                />

                <CustomePhoneField
                  value={secondPhone}
                  title={t("CUSTOMER.secondPhone")}
                  paddingHorizontal="0"
                  onChange={(e) => {
                    if (!isNaN(e?.target?.value)) {
                      setSecondPhone(e?.target?.value);
                      handlePhoneChange("second_phone", e?.target?.value);
                    }
                  }}
                  onClearClick={() => {
                    setSecondPhone("");
                    setStateCode2("");
                  }}
                  haswidth={true}
                  stateCode={stateCode2}
                  setStateCode={setStateCode2}
                  messageStateCode={secondPhoneMessageError?.message}
                  error={
                    secondPhoneMessageError?.error
                      ? true
                      : customersErrorsObject?.address_second_phone?.error
                  }
                  message={customersErrorsObject?.address_second_phone?.message}
                />

                {/* <Box 
                      sx={{
                        backgroundColor:'#fff',
                        boxShadow:'0 0 15px -1px rgba(0,0,0,0.2)',
                        padding:'10px',
                        margin:'2px 10px',
                        '& .MuiTypography-root ':{
                          fontFamily:'Cairo-Medium',
                        },
                        padding: '7px 10px',
                        margin: '10px auto',
                        width: screenwidth<991?'100%':'100%',
                        height:'55px'
                      }}
                    >
                       <FormControlLabel
                         sx={{
                            width:'100%',
                            fontFamily:'Cairo-Medium',
                         }}
                        control={<Switch
                          checked={gender} 
                          onChange={(e)=>{
                            setGender(e.target.checked)
                          }}
                        />} label={gender?'انثى':'ذكر'} />

                    </Box> */}
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  sx={{
                    width: "100%",
                    "& .MuiTypography-root": {
                      fontFamily: "Cairo",
                    },
                  }}
                  value={gender}
                  onChange={(e) => {
                    console.log('gender',e.target.value);
                    setGender(e?.target?.value);
                  }}
                >
                  <FormControlLabel
                    value={0}
                    control={<Radio sx={{ fontFamily: "Cairo" }} />}
                    label={t("CUSTOMER.male")}
                  />
                  <FormControlLabel
                    value={1}
                    control={<Radio sx={{ fontFamily: "Cairo" }} />}
                    label={t("CUSTOMER.FEMALE")}
                  />
                </RadioGroup>
              </ContainerOfInputFields>
            </Box>
            <Box
              className={`col-12 col-sm-12 col-md-6 ${
                isopensidemenu && screenwidth < 1300 ? "col-lg-6" : "col-lg-4"
              } col-xlg-4 my-3 `}
            >
              <Box
                sx={{
                  width: "90%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <span
                  style={{
                    textAlign: "right",
                    font: "normal normal 17px/22px Cairo-Light",
                    letterSpacing: "0px",
                    color: "#808080",
                    opacity: "1",
                  }}
                >
                  {t("CUSTOMER.company_information")}
                </span>
              </Box>
              <br />

              <ContainerOfSelectField
                mainTheme={mainTheme}
                customeWidth={"100%"}
                hasError={customersErrorsObject?.employee_id?.error}
              >
                <Box
                  sx={{
                    position: "relative",
                    // margin: "10px 0",
                    width: "100%",
                    backgroundColor: "#fff",
                  }}
                >
                  <Autocomplete
                    // disablePortal
                    id="combo-box-demo"
                    options={accountProvider ? accountProvider : []}
                    value={selectedAccountProvider}
                    onChange={(e, newValue) =>
                      handleInputChange(13, newValue, "employee_id")
                    }
                    popupIcon={<RiIcons.RiArrowDropDownLine />}
                    clearIcon={
                      <CloseIcon
                        sx={{ fontSize: "20px", color: mainTheme?.iconColor }}
                        onClick={() => clearField(13, "employee_id")}
                      />
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t("CUSTOMER.selectedAccountProvider")}
                        className="mt-3"
                        variant="filled"
                      />
                    )}
                    className={`${customersErrorsObject?.employee_id?.error}`}
                  />
                </Box>
                {customersErrorsObject?.employee_id?.error &&
                customersErrorsObject?.employee_id?.message?.length ? (
                  <Box
                    sx={{
                      height: "fit-content",
                      padding: "5px",
                      display: "flex",
                      flexDirection: "column",
                      flexWrap: "wrap",
                      width: "95%",
                    }}
                  >
                    {customersErrorsObject?.employee_id?.message &&
                    customersErrorsObject?.employee_id?.message?.length > 0
                      ? customersErrorsObject?.employee_id?.message?.map(
                          (messg) => (
                            <span
                              style={{
                                fontFamily: "Cairo-Bold",
                                fontSize: "14px",
                                color: "red",
                                height: "auto",
                              }}
                            >
                              {messg}
                            </span>
                          )
                        )
                      : null}
                  </Box>
                ) : null}
              </ContainerOfSelectField>

              <ContainerOfSelectField
                mainTheme={mainTheme}
                customeWidth={"100%"}
                hasError={customersErrorsObject?.purpose_of_purchase_id?.error}
                className={"mt-3"}
              >
                <Box
                  sx={{
                    position: "relative",
                    // margin: "10px 0",
                    width: "100%",
                    backgroundColor: "#fff",
                  }}
                >
                  <Autocomplete
                    // disablePortal
                    id="combo-box-demo"
                    options={purposeOfSell ? purposeOfSell : []}
                    value={selectedPurposeOfSell}
                    onChange={(e, newValue) =>
                      handleInputChange(14, newValue, "purpose_of_purchase_id")
                    }
                    popupIcon={<RiIcons.RiArrowDropDownLine />}
                    clearIcon={
                      <CloseIcon
                        sx={{ fontSize: "20px", color: mainTheme?.iconColor }}
                        onClick={() => clearField(14, "purpose_of_purchase_id")}
                      />
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t("CUSTOMER.selectedPurposeOfSell")}
                        variant="filled"
                      />
                    )}
                    className={`${customersErrorsObject?.purpose_of_purchase_id?.error}`}
                  />
                </Box>
                {customersErrorsObject?.purpose_of_purchase_id?.error &&
                customersErrorsObject?.purpose_of_purchase_id?.message
                  ?.length ? (
                  <Box
                    sx={{
                      height: "fit-content",
                      padding: "5px",
                      display: "flex",
                      flexDirection: "column",
                      flexWrap: "wrap",
                      width: "95%",
                    }}
                  >
                    {customersErrorsObject?.purpose_of_purchase_id?.message &&
                    customersErrorsObject?.purpose_of_purchase_id?.message
                      ?.length > 0
                      ? customersErrorsObject?.purpose_of_purchase_id?.message?.map(
                          (messg) => (
                            <span
                              style={{
                                fontFamily: "Cairo-Bold",
                                fontSize: "14px",
                                color: "red",
                                height: "auto",
                              }}
                            >
                              {messg}
                            </span>
                          )
                        )
                      : null}
                  </Box>
                ) : null}
              </ContainerOfSelectField>
              {selectedPurposeOfSell &&
              selectedPurposeOfSell?.id &&
              selectedPurposeOfSell?.id == 1 ? (
                <ContainerOfInputFields
                  mainTheme={mainTheme}
                  customeWidth={"100%"}
                >
                  <Box
                    sx={{
                      position: "relative",
                      // margin: "10px 0",
                      width: "100%",
                      backgroundColor: "#fff",
                    }}
                    className="mt-3"
                  >
                    <TextField
                      id="standard-basic"
                      variant="filled"
                      label={t("CUSTOMER.purposeOfSellNote")}
                      value={purposeOfSellNote}
                      onChange={(e) =>
                        handleInputChange(20, e, "purpose_of_purchase_note")
                      }
                      type={"text"}
                      // focused={customersErrorsObject?.purpose_of_purchase_note?.error}
                      className={`${
                        customersErrorsObject?.purpose_of_purchase_note?.error
                          ? "errors"
                          : ""
                      }`}
                    />
                    {purposeOfSellNote ? (
                      <CloseIcon
                        className="closeIcon"
                        onClick={() =>
                          clearField(20, "purpose_of_purchase_note")
                        }
                      />
                    ) : null}
                  </Box>
                  {customersErrorsObject?.purpose_of_purchase_note?.error &&
                  customersErrorsObject?.purpose_of_purchase_note?.message
                    ?.length ? (
                    <Box
                      sx={{
                        height: "fit-content",
                        padding: "5px",
                        display: "flex",
                        flexDirection: "column",
                        flexWrap: "wrap",
                        width: "95%",
                      }}
                    >
                      {customersErrorsObject?.purpose_of_purchase_note
                        ?.message &&
                      customersErrorsObject?.purpose_of_purchase_note?.message
                        ?.length > 0
                        ? customersErrorsObject?.purpose_of_purchase_note?.message?.map(
                            (messg) => (
                              <span
                                style={{
                                  fontFamily: "Cairo-Bold",
                                  fontSize: "14px",
                                  color: "red",
                                  height: "auto",
                                }}
                              >
                                {messg}
                              </span>
                            )
                          )
                        : null}
                    </Box>
                  ) : null}
                </ContainerOfInputFields>
              ) : null}
              <ContainerOfSelectField
                mainTheme={mainTheme}
                customeWidth={"100%"}
                hasError={customersErrorsObject?.payment_type_ids?.error}
                className="mt-3"
              >
                <Box
                  sx={{
                    position: "relative",
                    // margin: "10px 0",
                    width: "100%",
                    backgroundColor: "#fff",
                  }}
                >
                  <Autocomplete
                    // disablePortal
                    id="combo-box-demo"
                    multiple
                    options={
                      favoritePaymentMethods ? favoritePaymentMethods : []
                    }
                    value={
                      selectedFavoritePaymentMethods
                        ? selectedFavoritePaymentMethods
                        : []
                    }
                    onChange={(e, newValue) =>
                      handleInputChange(16, newValue, "payment_type_ids")
                    }
                    popupIcon={<RiIcons.RiArrowDropDownLine />}
                    clearIcon={
                      <CloseIcon
                        sx={{ fontSize: "20px", color: mainTheme?.iconColor }}
                        onClick={() => clearField(16, "payment_type_ids")}
                      />
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t("CUSTOMER.selectedFavoritePaymentMethods")}
                        variant="filled"
                      />
                    )}
                    className={`${customersErrorsObject?.payment_type_ids?.error}`}
                  />
                </Box>
                {customersErrorsObject?.payment_type_ids?.error &&
                customersErrorsObject?.payment_type_ids?.message?.length ? (
                  <Box
                    sx={{
                      height: "fit-content",
                      padding: "5px",
                      display: "flex",
                      flexDirection: "column",
                      flexWrap: "wrap",
                      width: "95%",
                    }}
                  >
                    {customersErrorsObject?.payment_type_ids?.message &&
                    customersErrorsObject?.payment_type_ids?.message?.length > 0
                      ? customersErrorsObject?.payment_type_ids?.message?.map(
                          (messg) => (
                            <span
                              style={{
                                fontFamily: "Cairo-Bold",
                                fontSize: "14px",
                                color: "red",
                                height: "auto",
                              }}
                            >
                              {messg}
                            </span>
                          )
                        )
                      : null}
                  </Box>
                ) : null}
              </ContainerOfSelectField>

              <ContainerOfSelectField
                mainTheme={mainTheme}
                customeWidth={"100%"}
                hasError={customersErrorsObject?.customer_type_id?.error}
                className="mt-3"
              >
                <Box
                  sx={{
                    position: "relative",
                    // margin: "10px 0",
                    width: "100%",
                    backgroundColor: "#fff",
                  }}
                >
                  <Autocomplete
                    // disablePortal
                    id="combo-box-demo"
                    options={customerType ? customerType : []}
                    value={SelectedCustomerType}
                    onChange={(e, newValue) =>
                      handleInputChange(17, newValue, "customer_type_id")
                    }
                    popupIcon={<RiIcons.RiArrowDropDownLine />}
                    clearIcon={
                      <CloseIcon
                        sx={{ fontSize: "20px", color: mainTheme?.iconColor }}
                        onClick={() => clearField(17, "customer_type_id")}
                      />
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t("CUSTOMER.SelectedCustomerType")}
                        variant="filled"
                      />
                    )}
                    className={`${customersErrorsObject?.customer_type_id?.error}`}
                  />
                </Box>
                {customersErrorsObject?.customer_type_id?.error &&
                customersErrorsObject?.customer_type_id?.message?.length ? (
                  <Box
                    sx={{
                      height: "fit-content",
                      padding: "5px",
                      display: "flex",
                      flexDirection: "column",
                      flexWrap: "wrap",
                      width: "95%",
                    }}
                  >
                    {customersErrorsObject?.customer_type_id?.message &&
                    customersErrorsObject?.customer_type_id?.message?.length > 0
                      ? customersErrorsObject?.customer_type_id?.message?.map(
                          (messg) => (
                            <span
                              style={{
                                fontFamily: "Cairo-Bold",
                                fontSize: "14px",
                                color: "red",
                                height: "auto",
                              }}
                            >
                              {messg}
                            </span>
                          )
                        )
                      : null}
                  </Box>
                ) : null}
              </ContainerOfSelectField>

              <ContainerOfSelectField
                mainTheme={mainTheme}
                customeWidth={"100%"}
                hasError={customersErrorsObject?.real_estate_ids?.error}
                className="mt-3"
              >
                <Box
                  sx={{
                    position: "relative",
                    // margin: "10px 0",
                    width: "100%",
                    backgroundColor: "#fff",
                  }}
                >
                  <Autocomplete
                    // disablePortal
                    id="combo-box-demo"
                    multiple
                    options={
                      typeOfInterestRealEstate ? typeOfInterestRealEstate : []
                    }
                    value={
                      selectedTypeOfIterestRealEstate
                        ? selectedTypeOfIterestRealEstate
                        : []
                    }
                    onChange={(e, newValue) =>
                      handleInputChange(18, newValue, "real_estate_ids")
                    }
                    popupIcon={<RiIcons.RiArrowDropDownLine />}
                    clearIcon={
                      <CloseIcon
                        sx={{ fontSize: "20px", color: mainTheme?.iconColor }}
                        onClick={() => clearField(18, "real_estate_ids")}
                      />
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t("CUSTOMER.selectedTypeOfIterestRealEstate")}
                        variant="filled"
                      />
                    )}
                    className={`${customersErrorsObject?.real_estate_ids?.error}`}
                  />
                </Box>
                {customersErrorsObject?.real_estate_ids?.error &&
                customersErrorsObject?.real_estate_ids?.message?.length ? (
                  <Box
                    sx={{
                      height: "fit-content",
                      padding: "5px",
                      display: "flex",
                      flexDirection: "column",
                      flexWrap: "wrap",
                      width: "95%",
                    }}
                  >
                    {customersErrorsObject?.real_estate_ids?.message &&
                    customersErrorsObject?.real_estate_ids?.message?.length > 0
                      ? customersErrorsObject?.real_estate_ids?.message?.map(
                          (messg) => (
                            <span
                              style={{
                                fontFamily: "Cairo-Bold",
                                fontSize: "14px",
                                color: "red",
                                height: "auto",
                              }}
                            >
                              {messg}
                            </span>
                          )
                        )
                      : null}
                  </Box>
                ) : null}
              </ContainerOfSelectField>

              <ContainerOfSelectField
                mainTheme={mainTheme}
                customeWidth={"100%"}
                hasError={customersErrorsObject?.compound_ids?.error}
                className="mt-3"
              >
                <Box
                  sx={{
                    position: "relative",
                    // margin: "10px 0",
                    width: "100%",
                    backgroundColor: "#fff",
                  }}
                >
                  <Autocomplete
                    // disablePortal
                    id="combo-box-demo"
                    multiple
                    options={
                      typeOfInterestCompound ? typeOfInterestCompound : []
                    }
                    value={
                      selectedTypeOfInterestCompound
                        ? selectedTypeOfInterestCompound
                        : []
                    }
                    onChange={(e, newValue) =>
                      handleInputChange(19, newValue, "compound_ids")
                    }
                    popupIcon={<RiIcons.RiArrowDropDownLine />}
                    clearIcon={
                      <CloseIcon
                        sx={{ fontSize: "20px", color: mainTheme?.iconColor }}
                        onClick={() => clearField(19, "compound_ids")}
                      />
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t("CUSTOMER.selectedTypeOfInterestCompound")}
                        variant="filled"
                      />
                    )}
                    className={`${customersErrorsObject?.compound_ids?.error}`}
                  />
                </Box>
                {customersErrorsObject?.compound_ids?.error &&
                customersErrorsObject?.compound_ids?.message?.length ? (
                  <Box
                    sx={{
                      height: "fit-content",
                      padding: "5px",
                      display: "flex",
                      flexDirection: "column",
                      flexWrap: "wrap",
                      width: "95%",
                    }}
                  >
                    {customersErrorsObject?.compound_ids?.message &&
                    customersErrorsObject?.compound_ids?.message?.length > 0
                      ? customersErrorsObject?.compound_ids?.message?.map(
                          (messg) => (
                            <span
                              style={{
                                fontFamily: "Cairo-Bold",
                                fontSize: "14px",
                                color: "red",
                                height: "20px",
                              }}
                            >
                              {messg}
                            </span>
                          )
                        )
                      : null}
                  </Box>
                ) : null}
              </ContainerOfSelectField>
            </Box>
          
            {/* border Line  */}
            <Box
              className="col-md-12"
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p
                style={{
                  width: "40%",
                  height: "3px",
                  backgroundColor: "#efa12d",
                  margin: "5px 0",
                  marginTop: "15px",
                }}
              ></p>
            </Box>
            <Box
              className="col-md-12 my-2 px-5"
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "start",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  width: "93%",
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                }}
              >
                <span
                  style={{
                    textAlign: "left",
                    font: "normal normal  20px/37px Cairo",
                    letterSpacing: "0px",
                    color: "#707070",
                    opacity: "1",
                  }}
                >
                  {t("CUSTOMER.user_info")}
                </span>
              </Box>
              <Box
                sx={{
                  borderRadius: "5px",
                  // border: `1px solid ${mainTheme?.iconColor}`,
                  padding: "10px",
                  width: "93%",
                  minHeight: "50px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "start",
                    width: "98%",
                  }}
                >
                  <Box
                    className={`col-12 col-sm-12 col-md-6 col-lg-6 col-xlg-6 `}
                  >
                    <CustomTextField
                      label={t("CUSTOMER.username")}
                      value={username?.value}
                      error={customersErrorsObject?.username?.error}
                      message={customersErrorsObject?.username?.message}
                      haswidth={true}
                      readOnly={false}
                      hasMultipleLine={false}
                      onChange={(e) => {
                        setUsername({
                          value: e?.target?.value,
                          error: false,
                          message: [],
                        });
                        setCustomersErrorsObject(
                          restCustomeObject(customersErrorsObject, "username")
                        );
                      }}
                      onClearClick={() => {
                        setUsername({
                          value: "",
                          error: false,
                          message: [],
                        });
                        setCustomersErrorsObject(
                          restCustomeObject(customersErrorsObject, "password")
                        );
                      }}
                    />
                    {/* <CustomePhoneField
                              value={phoneNumber?.value}
                              
                              title={t("CUSTOMER.user_phone")}
                              noTopMargin={true}
                              onChange={(e) => {
                                 if (!isNaN(e?.target?.value)) {

                                    setPhoneNumber({
                                       value: e.target.value,
                                       error: false,
                                       message: [],
                                       ...helpers?.handleValidatePhoneValue(e.target.value)
                                    })

                                 }
                              }}
                              onClearClick={() => {
                                 setPhoneNumber({
                                    value: "",
                                    error: false,
                                    message: []
                                 })
                              }}
                              haswidth={true}
                              stateCode={phoneCode?.value}
                              setStateCode={(e) => {
                                 setPhoneCode({
                                    value: e,
                                    error: false,
                                    message: []
                                 })

                              }}
                              messageStateCode={phoneCode?.message}
                              error={phoneCode?.error ? true : phoneNumber?.error}
                              message={phoneNumber?.message}
                           /> */}
                  </Box>
                  <Box
                    className={`col-12 col-sm-12 col-md-6  col-lg-6 col-xlg-6  `}
                  >
                    <CustomTextField
                      label={t("CUSTOMER.password")}
                      value={password?.value}
                      error={customersErrorsObject?.password?.error?.error}
                      message={customersErrorsObject?.password?.error?.message}
                      haswidth={true}
                      readOnly={false}
                      hasMultipleLine={false}
                      type={"password"}
                      onChange={(e) => {
                        setPassword({
                          value: e?.target?.value,
                          error: false,
                          message: [],
                        });
                        setCustomersErrorsObject(
                          restCustomeObject(customersErrorsObject, "password")
                        );
                      }}
                      onClearClick={() => {
                        setPassword({
                          value: "",
                          error: false,
                          message: [],
                        });
                        setCustomersErrorsObject(
                          restCustomeObject(customersErrorsObject, "password")
                        );
                      }}
                    />
                    {/* <CustomTextField
                              label={t('CUSTOMER.confirm_password')}
                              value={confirmPassword?.value}
                              error={confirmPassword?.error}
                              message={confirmPassword?.message}
                              haswidth={true}
                              type={'password'}
                              readOnly={false}
                              hasMultipleLine={false}
                              onChange={(e) => {
                                 setConfirmPassword({
                                    value: e?.target?.value,
                                    error: false,
                                    message: [],
                                 })
                              }}
                              onClearClick={() => {
                                 setConfirmPassword({
                                    value: "",
                                    error: false,
                                    message: [],
                                 })
                              }}
                           /> */}
                  </Box>
                </Box>
              </Box>
            </Box>
            {/* border Line  */}
            <Box
              className="col-md-12"
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p
                style={{
                  width: "40%",
                  height: "3.3px",
                  backgroundColor: "#efa12d",
                  margin: "5px 0",
                  marginTop: "15px",
                }}
              ></p>
            </Box>
            <Box
              className="col-md-12 my-2 px-5"
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "start",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  width: "93%",
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                }}
              >
                <span
                  style={{
                    textAlign: "left",
                    font: "normal normal  20px/37px Cairo",
                    letterSpacing: "0px",
                    color: "#707070",
                    opacity: "1",
                  }}
                >
                  {t("CUSTOMER.add_relative_persone")}
                </span>
              </Box>
              <Box
                sx={{
                  borderRadius: "5px",
                  // border: `1px solid ${mainTheme?.iconColor}`,
                  padding: "10px",
                  width: "93%",
                  minHeight: "50px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "start",
                    width: "98%",
                  }}
                >
                  <AddCircleIcon
                    className="iconAddRelationType"
                    sx={{
                      fontSize: "30px",
                      cursor: "pointer",
                      color: mainTheme?.iconColor,
                    }}
                    onClick={addNewRelationsData}
                  />
                </Box>

                {relativePersone &&
                  relativePersone?.length > 0 &&
                  relativePersone?.map((itm, index) => (
                    <Box
                      sx={{
                        width: "98%",
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "start",
                        // flexWrap:'wrap'
                      }}
                      className="my-3"
                    >
                      <RemoveCircleIcon
                        className="iconAddRelationType mt-4"
                        sx={{
                          fontSize: "28px",
                          color: mainTheme?.iconColor,
                          cursor: "pointer",
                        }}
                        onClick={() => removeThisItem(itm)}
                      />
                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                          flexWrap: "wrap",
                        }}
                      >
                        <ContainerOfInputFields
                          mainTheme={mainTheme}
                          customeWidth={
                            screenwidth < 800
                              ? "90%"
                              : screenwidth < 1200
                              ? "50%"
                              : "30%"
                          }
                          haswidth={true}
                        >
                          <Box
                            sx={{
                              position: "relative",
                              // margin: "5px 0",
                              width: "100%",
                              backgroundColor: "#fff",
                            }}
                            className="mt-3"
                          >
                            <TextField
                              id="standard-basic"
                              variant="filled"
                              label={t("CUSTOMER.relative_persone_name")}
                              value={itm?.name}
                              onChange={(e) =>
                                handleRelationTypeChanged(
                                  itm,
                                  "name",
                                  e.target.value,
                                  "error_name",
                                  "message_name"
                                )
                              }
                              type={"text"}
                              focused={itm?.error_name}
                              className={`${itm?.error_name ? "errors" : ""}`}
                            />
                            {itm?.name ? (
                              <CloseIcon
                                className="closeIcon"
                                onClick={() =>
                                  handleRelationTypeChanged(
                                    itm,
                                    "name",
                                    "",
                                    "error_name",
                                    "message_name"
                                  )
                                }
                              />
                            ) : null}
                          </Box>
                          {itm?.message_name && itm?.message_name?.length ? (
                            <Box
                              sx={{
                                height: "fit-content",
                                padding: "5px",
                                display: "flex",
                                flexDirection: "column",
                                flexWrap: "wrap",
                                width: "95%",
                              }}
                            >
                              {itm?.message_name &&
                              itm?.message_name?.length > 0
                                ? itm?.message_name?.map((messg) => (
                                    <span
                                      style={{
                                        fontFamily: "Cairo-Bold",
                                        fontSize: "14px",
                                        color: "red",
                                        height: "auto",
                                      }}
                                    >
                                      {messg}
                                    </span>
                                  ))
                                : null}
                            </Box>
                          ) : null}
                        </ContainerOfInputFields>

                        <ContainerOfInputFields
                          mainTheme={mainTheme}
                          customeWidth={
                            screenwidth < 800
                              ? "90%"
                              : screenwidth < 1200
                              ? "50%"
                              : "30%"
                          }
                          haswidth={true}
                        >
                          <Box
                            sx={{
                              position: "relative",
                              // margin: "5px 0",
                              width: "100%",
                              backgroundColor: "#fff",
                            }}
                            className="mt-3"
                          >
                            <TextField
                              id="standard-basic"
                              variant="filled"
                              label={t("CUSTOMER.relative_persone_address")}
                              value={itm?.address}
                              onChange={(e) =>
                                handleRelationTypeChanged(
                                  itm,
                                  "address",
                                  e.target.value,
                                  "error_address",
                                  "message_address"
                                )
                              }
                              type={"text"}
                              focused={itm?.error_address}
                              className={`${
                                itm?.error_address ? "errors" : ""
                              }`}
                            />
                            {itm?.address ? (
                              <CloseIcon
                                className="closeIcon"
                                onClick={() =>
                                  handleRelationTypeChanged(
                                    itm,
                                    "address",
                                    "",
                                    "error_address",
                                    "message_address"
                                  )
                                }
                              />
                            ) : null}
                          </Box>
                          {itm?.message_address &&
                          itm?.message_address?.length ? (
                            <Box
                              sx={{
                                height: "fit-content",
                                padding: "5px",
                                display: "flex",
                                flexDirection: "column",
                                flexWrap: "wrap",
                                width: "95%",
                              }}
                            >
                              {itm?.message_address &&
                              itm?.message_address?.length > 0
                                ? itm?.message_address?.map((messg) => (
                                    <span
                                      style={{
                                        fontFamily: "Cairo-Bold",
                                        fontSize: "14px",
                                        color: "red",
                                        height: "auto",
                                      }}
                                    >
                                      {messg}
                                    </span>
                                  ))
                                : null}
                            </Box>
                          ) : null}
                        </ContainerOfInputFields>

                        {/* <ContainerOfInputFields
                              mainTheme={mainTheme}
                              customeWidth={screenwidth<800?"90%":screenwidth<1200?'50%':'30%'}
                              haswidth={true}
                           >
                              
                              <Box
                                 sx={{
                                 position: "relative",
                                 // margin: "5px 0",
                                 width: "100%",
                                 backgroundColor: "#fff",
                                 }}
                                 className={`${screenwidth<1200?'mt-3':'mt-0'}`}
                              >
                                 <TextField
                                 id="standard-basic"
                                 variant="filled"
                                 label={t("CUSTOMER.relative_persone_phone")}
                                 value={itm?.phone}
                                 onChange={(e)=>handleRelationTypeChanged(itm,'phone',e.target.value,'error_phone','message_phone')}
                                 type={'text'}
                                 focused={itm?.error_phone}
                                 className={`${itm?.error_phone?'errors':''}`}
                                 />
                                 {itm?.phone ? (
                                 <CloseIcon
                                    className="closeIcon phone"
                                    onClick={()=>handleRelationTypeChanged(itm,'phone','','error_phone','message_phone')}
                                 />
                                 ) : null}
                              </Box>
                              {
                                 itm?.message_phone&&itm?.message_phone?.length?
                                 <Box sx={{
                                    height: 'fit-content',
                                    padding:'5px',
                                    display: 'flex',
                                    flexDirection:'column',
                                    flexWrap:'wrap',
                                    width:'95%',
                                 }}>
                                    {
                                    itm?.message_phone&&itm?.message_phone?.length>0?itm?.message_phone?.map((messg)=>(
                                    <span style={{fontFamily:'Cairo-Bold',fontSize:'14px',color:'red',height:'auto'}}>{messg}</span>
                                    )):null
                                    }
                                 </Box>:null}
                           </ContainerOfInputFields> */}
                        <CustomePhoneField
                          value={itm?.phone}
                          customeWidth={
                            screenwidth < 800
                              ? "90%"
                              : screenwidth < 1200
                              ? "50%"
                              : "30%"
                          }
                          title={t("CUSTOMER.relative_persone_phone")}
                          onChange={(e) => {
                            if (!isNaN(e?.target?.value)) {
                              handleRelationTypeChanged(
                                itm,
                                "phone",
                                e.target.value,
                                "error_phone",
                                "message_phone"
                              );
                              // handlePhoneChange('second_phone',e?.target?.value)
                            }
                          }}
                          onClearClick={() => {
                            handleRelationTypeChanged(
                              itm,
                              "phone",
                              "",
                              "error_phone",
                              "message_phone"
                            );
                            setStateCode2("");
                          }}
                          haswidth={true}
                          stateCode={itm?.code}
                          setStateCode={(e) => {
                            handleRelationTypeChanged(
                              itm,
                              "code",
                              e,
                              "error_phone",
                              "message_phone"
                            );
                          }}
                          messageStateCode={itm?.messageStateCode}
                          error={itm?.errorStateCode ? true : itm?.error_phone}
                          message={itm?.message_phone}
                        />

                        <ContainerOfSelectField
                          mainTheme={mainTheme}
                          customeWidth={
                            screenwidth < 800
                              ? "90%"
                              : screenwidth < 1200
                              ? "50%"
                              : "30%"
                          }
                          haswidth={true}
                          hasError={itm?.error_relation_type_id}
                        >
                          <Box
                            sx={{
                              position: "relative",
                              width: "100%",
                              backgroundColor: "#fff",
                            }}
                            className={`${
                              screenwidth < 1200 ? "mt-3" : "mt-3"
                            }`}
                          >
                            <Autocomplete
                              id="combo-box-demo"
                              options={relationTypes ? relationTypes : []}
                              value={itm?.relationType}
                              onChange={(e, newValue) =>
                                handleRelationTypeChanged(
                                  itm,
                                  "relationType",
                                  newValue,
                                  "error_relation_type_id",
                                  "message_relation_type_id"
                                )
                              }
                              popupIcon={<RiIcons.RiArrowDropDownLine />}
                              clearIcon={
                                <CloseIcon
                                  sx={{
                                    fontSize: "20px",
                                    color: mainTheme?.iconColor,
                                  }}
                                  onClick={() =>
                                    handleRelationTypeChanged(
                                      itm,
                                      "relationType",
                                      null,
                                      "error_relation_type_id",
                                      "message_relation_type_id"
                                    )
                                  }
                                />
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label={t("CUSTOMER.relative_persone")}
                                  variant="filled"
                                />
                              )}
                              className={`${itm?.error_relation_type_id}`}
                            />
                          </Box>
                          {itm?.message_relation_type_id &&
                          itm?.message_relation_type_id?.length ? (
                            <Box
                              sx={{
                                height: "fit-content",
                                padding: "5px",
                                display: "flex",
                                flexDirection: "column",
                                flexWrap: "wrap",
                                width: "95%",
                              }}
                            >
                              {itm?.message_relation_type_id &&
                              itm?.message_relation_type_id?.length > 0
                                ? itm?.message_relation_type_id?.map(
                                    (messg) => (
                                      <p
                                        style={{
                                          fontFamily: "Cairo-Bold",
                                          fontSize: "14px",
                                          color: "red",
                                          height: "auto",
                                        }}
                                      >
                                        {messg}
                                      </p>
                                    )
                                  )
                                : null}
                            </Box>
                          ) : null}
                        </ContainerOfSelectField>
                      </Box>
                    </Box>
                  ))}
              </Box>
            </Box>
            <Box
              className="col-md-12 my-2 px-5 my-3"
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "relative",
                  cursor: "pointer",
                  width: "274px",
                  height: "49px",
                  background: "#994D1E 0% 0% no-repeat padding-box",
                  boxShadow: "0px 15px 30px #43425D1A",
                  opacity: "1",
                }}
                onClick={submitForm}
              >
                <span
                  style={{
                    color: "#FFFFFF",
                    textAlign: "center",
                    font: "normal normal  15px/30px Cairo-SemiBold",
                    letterSpacing: "0px",
                    opacity: "1",
                  }}
                >
                  {t("CUSTOMER.saveInfo")}
                </span>
                <SaveIcon
                  sx={{
                    fontSize: "25px",
                    color: "#fff",
                    position: "absolute",
                    top: "13.5px",
                    right: "10px",
                  }}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "relative",
                  cursor: "pointer",
                  width: "274px",
                  height: "49px",
                  //   background: "#994D1E 0% 0% no-repeat padding-box",
                  border: "1px solid #1E6A99",
                  boxShadow: "0px 15px 30px #43425D1A",
                  opacity: "1",
                  margin: "0 20px",
                }}
                onClick={clearForm}
              >
                <span
                  style={{
                    color: "#1E6A99",
                    textAlign: "center",
                    font: "normal normal  15px/30px Cairo-SemiBold",
                    letterSpacing: "0px",
                    opacity: "1",
                  }}
                >
                  {t("CUSTOMER.clearData")}
                </span>
                <FaIcons.FaEraser
                  style={{
                    fontSize: "25px",
                    color: "#1E6A99",
                    position: "absolute",
                    top: "13.5px",
                    left: "10px",
                  }}
                />
              </Box>
            </Box>
          </Box>
        </>
      }
    </Box>
  );
};

export default AddNewCustomer;
