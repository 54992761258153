import { Box, Typography } from '@mui/material'
import React from 'react'

function InvoiceRow({ title, value, paddingRight,titleSX,valueSX,marginTop,sx }) {
    return (
        <Box
            style={{
                display: 'flex',
                flexDirection: 'row',
                marginTop:marginTop?marginTop:'20px',
                ...(paddingRight && {
                    paddingRight: paddingRight
                }),
                ...sx
            }}>
            <Typography
                component={'span'}
                style={{
                    fontFamily: 'Cairo-Bold',
                    textAlign: 'justify',
                    textJustify: 'inter-word',
                    lineHeight: 'normal',
                    ...titleSX
                }}>
                {title}
            </Typography>
                <Typography
                    style={{
                        flex:9,
                        wordWrap: 'break-word',
                        fontFamily: 'Cairo',
                        textAlign: 'justify',
                        textJustify: 'inter-word',
                        lineHeight: 'normal',
                        ...valueSX
                    }}>
                    {value}
                </Typography>

        </Box>
    )
}

export default InvoiceRow