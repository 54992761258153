import callAPI from '../ApiConfig'
import store from '../../../app/store'

const Rooms= async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"rooms",
        method:"get",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
    
}
const RoomList = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"rooms_list",
        method:"get",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
    
}
const addRoom = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"rooms",
        method:"post",
        signal:props?.signal,
        params:props?.params,
        data:props?.data,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
    
}
const updateRoom = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"rooms/"+props?.data?.id,
        method:"put",
        signal:props?.signal,
        params:props?.params,
        data:props?.data,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
    
}
const deleteRoom = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"rooms/"+props?.data?.id,
        method:"delete",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
    
}



const RoomAPI={
    Rooms:Rooms,
    RoomList:RoomList,
    addRoom:addRoom,
    updateRoom:updateRoom,
    deleteRoom:deleteRoom,

}
export default RoomAPI