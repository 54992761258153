const departmentError = {
    id:"",
    name:{
        value: "",
        error: false,
        message: [],
        required: false
    },
    company_id:{
        value: {
            id:"",
            name:"",
        },
        error: false,
        message: [],
        required: false
    },
    
}
export const resetErrors = (currentObject) => {
    var objectKeys = Object.keys(currentObject);
   
    var updatedObject = {
        ...currentObject
    }
    try {
        objectKeys.filter(key=>key!=="id").map((key) => {
        
            updatedObject[key]["error"] = false
            updatedObject[key]["message"] = ""
        })
    } catch (error) {
        
    }
    
    return updatedObject

}
export const checkErrors = (errorMessages, currentObject) => {

    const errorMessagesKeys = Object.keys(errorMessages);
    errorMessagesKeys.map((key) => {
        let messages = []
        errorMessages[key].map((message) => {
            messages.push(message)
        })
       
        currentObject[key]["error"] = true
        currentObject[key]["message"] = messages
    })


    return currentObject
}
export const currentObjectMerge = (updatedObject) => {
    const mergedCustomerObject = {
    id:updatedObject?.id,
    name:{
        value: updatedObject?.name,
        error: false,
        message: [],
        required: false
    },
    
    company_id:{
        value: updatedObject?.company,
        error: false,
        message: [],
        required: false
    },

    }
    
    return mergedCustomerObject;
}
export const resetCurrentObject=(currentObject,obj)=>{
    // var objectKeys = Object.keys(currentObject);
   
    try {
        
        currentObject[obj]["error"] = false
        currentObject[obj]["message"] = ""
    } catch (error) {
        
    }
    
    return currentObject
}
export default departmentError;