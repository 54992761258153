import { Box, IconButton, TextField } from "@mui/material";
import React from "react";
import { ContainerOfInputFields } from "../../Admin/RegisteredCustomers/ThemDesign";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";
import CustomPhoneCodeList from "../../Admin/Components/CustomPhoneCodeList";
import { useTranslation } from "react-i18next";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

function CustomePhoneField({
  label,
  error,
  message,
  value,
  onChange,
  onClearClick,
  readOnly,
  type,
  haswidth,
  hasMultipleLine,
  stateCode,
  setStateCode,
  handleSetChanges,
  messageStateCode,
  title,
  customeWidth,
  paddingHorizontal,
  customePaddingVertical,
  direction,
  noTopMargin
}) {
    const maintheme = useSelector((state) => state.themeData.maintheme);
    const [anchorEl,setAnchorEl]=React.useState(null)
    const [t]=useTranslation('common')

    const open=Boolean(anchorEl)
    const handleClose=()=>{
        setAnchorEl(null)
    }
    const handleClick=(e)=>{
       setAnchorEl(e.currentTarget)
    }
    React.useEffect(() => {
     if(!stateCode){
       setStateCode('+964')
     }
    }, [stateCode]);

  return (
    <ContainerOfInputFields
      mainTheme={maintheme}
      customeWidth={customeWidth?customeWidth:"100%"}
      isForm={true}
      haswidth={haswidth ? true : false}
      hasMultiLine={hasMultipleLine ? true : false}
      customePadding="0"
      paddingHorizontal={paddingHorizontal?paddingHorizontal:'2px 0px'}
      direction={direction}
      customePaddingVertical={customePaddingVertical}
    >
      <Box
        sx={{
          position: "relative",
          // margin: "5px 0",
          width: "100%",
          backgroundColor: "#fff",
          padding:'0 !important',
          marginLeft:'0 !important',
          marginRight:'0 !important',
          '& .MuiFormHelperText-root':{
            color:'red',
            fontFamily:'Cairo'
          },
          // '& .MuiFilledInput-input':{
          //   direction:direction?direction:'rtl'
          // }

        }}
        className={(noTopMargin?"":"mt-3") +" orderdata"}
      >
        <TextField
          id="standard-basic"
          variant="filled"
          label={title}
          value={value?value:''}
          onChange={(e) =>onChange&&onChange(e)
          }
          type={"text"}
          error={error}
          helperText={messageStateCode}
          className={`${
            error ? "errors" : "Mui-focused"
          }`}
          inputProps={{
            readOnly:readOnly
          }}
        />
        {value ? (
          <CloseIcon
            className="closeIcon"
            sx={{
              width: "15px",
              height: "15px",
            }}
            onClick={() => onClearClick&&onClearClick()}
          />
        ) : null}
        <Box
          sx={{
            position: "absolute",
            top: "30px",
            transform: "translate(0, -50%)",
            right: "45px",
            width: "fit-content",
            minWidth: "32px",
            maxWidth: "55px",
            cursor: "pointer",
            height: "21px",
            backgroundColor: "#e7e2e2",
            color: "#000",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "3px",
            direction: "rtl",
          }}
          id="demo-customized-button"
          aria-controls={open ? "demo-customized-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={!readOnly?handleClick:()=>{}}
        >
          <IconButton>
            {!stateCode ? (
              <ArrowDropDownIcon sx={{ color: "#1E6A99" }} />
            ) : (
              <Box
                sx={{
                  fontFamily: "Cairo-Medium",
                  fontSize: "12px",
                  display: "flex",
                  flexDirection: "row",
                  color: "#1e6a99 !important",
                }}
              >
                {stateCode}
              </Box>
            )}
          </IconButton>
        </Box>

        <CustomPhoneCodeList
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
          open={open}
          handleClose={handleClose}
          setStateCode={setStateCode}
          stateCode={stateCode}
        />
      </Box>

      {error && message?.length ? (
        <Box
          sx={{
            height: "fit-content",
            padding: "5px",
            display: "flex",
            flexDirection: "column",
            flexWrap: "wrap",
            width: "95%",
          }}
        >
          {message && message?.length > 0
            ? message?.map((messg) => (
                <span
                  style={{
                    fontFamily: "Cairo-Bold",
                    fontSize: "14px",
                    color: "red",
                    height: "auto",
                  }}
                >
                  {messg}
                </span>
              ))
            : null}
        </Box>
      ) : null}
    </ContainerOfInputFields>
  );
}

export default CustomePhoneField;
