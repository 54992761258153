import { Box,TextField,Button} from '@mui/material'
import React,{useState,useEffect}from 'react'
import PopupForm from '../../Components/PopupForm'
import {useTranslation} from 'react-i18next'
import {ContainerOfInput,ContainerOfInputFields,ContainerOfSelectField} from '../../RegisteredCustomers/ThemDesign'
import { useSelector } from 'react-redux'
import ObjectOfErrors,{checkErrors,resetErrors,restCustomeObject} from '../../../Global/Errors/MasterUnitType/MasterUnitType'
import CloseIcon from '@mui/icons-material/Close';
import MaritalStatusAPI from '../../../Global/Network/MaritalStatus/index'
import {toast} from 'react-toastify'
import MasterUnitTypeAPI from '../../../Global/Network/MasterUnitTyps'
import CustomTextField from '../../../General/CustomComponents/CustomTextField'
import CustomeSelectField from '../../../General/CustomComponents/CustomeSelectField'
import compoundAPI from '../../../Global/Network/Compound'


const RenderContent=(props)=>{
    const [t]=useTranslation('common')
    const maintheme=useSelector(state=>state.themeData.maintheme);
    const [ErrorsObject,setErrorsObject]=useState(ObjectOfErrors)
    const handleChange=(e)=>{
       props.setName(e.target.value)
       setErrorsObject(restCustomeObject(ErrorsObject,'name'))
    }
    const clearInput=()=>{
       props.setName('')
       setErrorsObject(restCustomeObject(ErrorsObject,'name'))

    }

    return(
        <Box className='Container-fluid'>
            <Box className="row">
                <Box className="col-12 col-sm-12 col-md-6">
                    <CustomTextField
                        label={t('MASTERUNITTYPES.add_name')}
                        value={props?.name}
                        error={ErrorsObject?.name?.error}
                        message={ ErrorsObject?.name?.message}
                        haswidth={true}
                        readOnly={false}
                        focused={true}
                        onChange={(e) => {
                           props?.setName(e?.target?.value)
                           setErrorsObject(restCustomeObject(ErrorsObject,'name'))
                        }}
                        onClearClick={() => {
                            props?.setName('')
                            setErrorsObject(restCustomeObject(ErrorsObject,'name'))
                        }}
                    />
                </Box>
                <Box className="col-12 col-sm-12 col-md-6">
                    <CustomeSelectField
                        label={t('MASTERUNITTYPES.compound_id')}
                        haswidth={true}
                        value={props?.selectedCompound}
                        list={props?.compounds ? props?.compounds : []}
                        error={ErrorsObject?.compound_id?.error}
                        message={ErrorsObject?.compound_id?.message}
                        readOnly={false}
                        onChange={(e, newValue) => {
                            props?.setSelectedCompound(newValue)
                           setErrorsObject(restCustomeObject(ErrorsObject,'name'))
                        }}
                        onClearClick={() => {
                            props?.setSelectedCompound(null)
                            setErrorsObject(restCustomeObject(ErrorsObject,'name'))
                        }}
                    />
                </Box>
            </Box>
        </Box>
    )
}

const RenderFooter=(props)=>{
    const [t]=useTranslation('common')
    const gridtheme=useSelector(state=>state.themeData.gridtheme)

    return(
        <Box sx={{
            display:'flex',
            alignItems:'center',
        }}>
          

              <Box sx={{display:'flex',justifyContent: 'end',alignItems: 'center',width:'100%'}}>
          <Box display="flex" >
              
                  <Button variant="outlined"
                      // spacing={2}
                      sx={{
                            width:'50% !important',
                              // flexGrow: 1, 
                              minWidth:'80px !important',
                              maxWidth:'80px !important',
                              margin: 1,
                              backgroundColor:'#f7f7f7',
                              borderColor:gridtheme?.colorWhite,
                              color:gridtheme?.colorblack,
                             boxShadow:'0 0 7px -2px white',
                            //   color:SearchButtonTheme?.clear_button_text_color,
                          '&:hover':{
                            backgroundColor:'#f7f7f7',
                            borderColor:gridtheme?.colorWhite,
                            color:gridtheme?.colorblack,
                             boxShadow:'0 0 7px 1px white',
                             boxShadow:'0',


                          },
                          height:'35px',
                          fontFamily:'Cairo-Bold'

                      }}
                      className="iconeFilterClear"
                      color="secondary"
                      onClick={()=>{
                        props.setOpen(false)
                    }}
                        >
                      {t('GLOBAL.close')}
                  </Button>
                  <Button
                      variant="contained"
                      // spacing={2} 
                      sx={{ 
                          // flexGrow: 1,
                          margin: 1,
                          width:'80px !important',
                          minWidth:'80px !important',
                          maxWidth:'80px !important',
                          '&:hover':{
                            //   backgroundColor:SearchButtonTheme?.search_button_color+'88',
                          },
                          height:'35px',
                          fontFamily:'Cairo-Bold'
                      }}
                      className="iconeFilterSearch"
                      onClick={() => {
                            props.submit()
                      }} 
                      >
                      {t('GLOBAL.Save')}
                  </Button>
          </Box>
      </Box>
        </Box>
    )
}


export default function AddFormDailog(props) {
    const [t]=useTranslation('common');
    const [name,setName]=useState(null)
    const [compounds,setCompounds]=useState([])
    const [selectedCompound,setSelectedCompound]=useState(null)
    const [loading,setLoading]=useState(false)
    const [ErrorsObject,setErrorsObject]=useState(ObjectOfErrors)


    useEffect(()=>{
        if(props?.object&&props?.object?.id){
            let dumData=resetErrors(ErrorsObject)
           setName(props?.object?.name)
           setSelectedCompound(props?.object?.compound)
        }else{
            let dumData=resetErrors(ErrorsObject)
            clearForm()
        }
    },[props?.object])

    const submit=async()=>{
     try{
        setLoading(true)
        let dumData=resetErrors(ErrorsObject)
        let data={
            name:name,
            compound_id:selectedCompound?.id?selectedCompound?.id:''
        }
        let result=null;
        if(props?.object&&props?.object?.id){
            data={
                ...data,
                id:props?.object?.id,
                _method:'put'
            }
             result=await MasterUnitTypeAPI.updateMasterUnitType({
            data:data
            });
        }else{
            result=await MasterUnitTypeAPI.addMasterUnitType({
                data:data
            });
        }
  
        if(result?.status){
          setLoading(false)
          toast.success(t('NETWORKMESSAGE.addCustomerMessageSuccess'))
          clearForm()
          props.loadData()
          props.setAddForm(false)
        }else{
          
           if (typeof result.errorMessage === "object") {
              let updatedObject = resetErrors(ErrorsObject)
              setErrorsObject(checkErrors(result.errorMessage, updatedObject))   
          }
          setLoading(false)
          toast.error(t('NETWORKMESSAGE.messageError'))
        }
        setLoading(false)
     }catch(error){
        setLoading(false)
        toast.error(error?.message)
     }
        
    }

    const clearForm=()=>{
        setName('')
        setSelectedCompound(null)
    }

    useEffect(()=>{
        if(!props?.object)
          clearForm()
        loadDataOfCompoundsList()
    },[props?.addForm,props?.object])

    const loadDataOfCompoundsList=async()=>{
        let data=null;
        try{
            data=await compoundAPI.compoundsList();
            if(data&&data?.status){
                setCompounds(data?.data)
            }else{
                toast.error(t('NETWORKMESSAGE.messageError'))
            }
        }catch(err){
            console.log(err?.message);
        }
    }

  return (
       <PopupForm 
        open={props.addForm} 
        setOpen={props.setAddForm}
        title={props?.object?t('MASTERUNITTYPES.updateTitle'):t('MASTERUNITTYPES.addTitle')}
        content={<RenderContent 
            name={name} setName={setName}
            selectedCompound={selectedCompound}
            setSelectedCompound={setSelectedCompound}
            setCompounds={setCompounds}
            compounds={compounds}
        open={props.addForm} 
        setOpen={props.setAddForm}
        />}
        footer={<RenderFooter
            open={props.addForm} 
            setOpen={props.setAddForm}
            submit={submit}
        />}
       />
  )
}
