import { Box, IconButton, Tooltip } from '@mui/material'
import React ,{useState} from 'react'
import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from 'react-i18next';
import SlideImages from './SlideImages';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import { useSelector } from 'react-redux';

export default function RightSide(props) {
    const [t]=useTranslation('common')
    const [playImage,setImageSlide]=useState(true)
    const [playImage2,setImageSlide2]=useState(true)

    const [heroSwiper, setSwiperRef] = useState(null);
    const [heroSwiper2, setSwiperRef2] = useState(null);

    const screenwidth=useSelector(state=>state.settingsData.screenwidth)

    const playHero = () => {
      heroSwiper.autoplay.start();
      heroSwiper2.autoplay.start();
      

    };
    const pauseHero = () => {
      heroSwiper.autoplay.stop();
      heroSwiper2.autoplay.stop();

    };

  return (
    <Box
       sx={{
            width:'100%',
            display:'flex',
            flexDirection:'column',
            justifyContent:'center',
            
            // backgroundColor:'rgba(255,0,0,0.3)'
        }}
   >
    <Box 
      sx={{
        width:'100%',
        display:'flex',
        alignItems:'center',
        justifyContent:'space-between',
        margin:'10px  15px 15px 5px',
        padding:'0 20px 0 10px'
      }}
    >
       <Box
       sx={{
        // backgroundColor:'#ie6a99',
        width:'30px',
        height:'30px',
        borderRadius:'50%',
       }}
      >
       <Tooltip title={playImage?<span style={{fontFamily:'Cairo'}}>{t('GLOBAL.puse')}</span>:<span style={{fontFamily:'Cairo'}}>{t('GLOBAL.play')}</span>} arrow>
       <IconButton
       sx={{
        // backgroundColor:'#1e6a99',
        width:'40px',
        height:'40px',
        borderRadius:'50%',
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        '&:hover':{
          backgroundColor:'#1e6a99 !important',
       }
       }}
       onClick={()=>{
        setImageSlide(!playImage)
        if(!playImage){
           playHero()
        }
        if(playImage)
         pauseHero()
       }}
      >
        {
          !playImage?
         <PlayArrowIcon
           sx={{
            color:'#000',
            fontSize:'35px',
            '&:hover':{
            color:'#fff',
            }
           }}
         />:<PauseIcon
          sx={{
            color:'#000',
            fontSize:'35px',
            '&:hover':{
              color:'#fff',
              }
          }}
         />
        }
       </IconButton>
       </Tooltip>
       </Box>
      
       <Box
        sx={{
            backgroundColor:'#ie6a99',
            width:'fit-content',
            height:'fit-content',
            borderRadius:'50%',
        }}
      >
        <span style={{
            fontFamily:'Cairo-Bold',
            color:'#000',
            fontSize:'21px',
        }}>{t('UNIT.unitsImage')}</span>
       </Box>
       <Box></Box>
       {/* <IconButton
       sx={{
        backgroundColor:'#1e6a99 !important',
        width:'35px',
        height:'35px',
        borderRadius:'50%',
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        '&:hover':{
           backgroundColor:'#1e6a99 !important',
        }
       }}
      >
         <AddIcon
           sx={{
            color:'#fff',
            fontSize:'35px',
           }}
         />
       </IconButton> */}

    </Box>

    <Box 
      sx={{
        width:'100%',
        minWidth:'100%',
        maxWidth:window.innerWidth-130,
        display:'flex',
        alignItems:'center',
        // height:screenwidth<=1100?'350px':'500px'
      }}
    >
      {
        props?.UnitData?.images?.length>0?
      <SlideImages 
        playImag={playImage}
        setSwiperRef={setSwiperRef}
        heroSwiper={heroSwiper}
        UnitData={props?.UnitData}
        loadDataOfUnit={props?.loadDataOfUnit}
        UnitId={props?.UnitId}
        setSwiperRef2={setSwiperRef2}
        heroSwiper2={heroSwiper2}
        setImageSlide2={setImageSlide2}
        playImage2={playImage2}
      />:<Box
         sx={{
          display:'flex',
          justifyContent:'center',
          alignItems:'center',
          fontFamily:'Cairo',
          width:'100%',
          height:'300px',
          fontSize:'16px'
         }}
      >
          {t('GLOBAL.noDataImage')}
      </Box>
      }
    </Box>
       
   </Box>
  )
}
