import React,{useState,useEffect} from 'react'
import ImageMapper from 'react-img-mapper';
import {useSelector,useDispatch} from 'react-redux'
import  videoSource from '../../../assets/img/compound_1.jpg'
import {styled} from '@mui/material/styles'
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import ZoomInIcon from '@mui/icons-material/ZoomIn';

const CustomeButton=styled('button')(({theme,style})=>({
        margin: 8,
        padding: "10px 16px",
        color: "white",
        backgroundColor: "#51d9f0",
        boxShadow: "0 0 9px 0px #000",
        border: "none",
        borderRadius: ".4rem",
        width:'50px',
        ...style,
        '&:hover':{
          backgroundColor: "#51d9f0",
          boxShadow: "0 0 0px 0px #000",
        }
}))
const CustomWrapper=styled('div')(({theme,style})=>({
    width:'100%',
    height:'100%',
    // '& img':{
    //     width:'700px !important',
    //     height:'640px !important'
    // },
}))

export default function ViewImage() {
    const polygonsRedux=useSelector(state=>state.settingsData.polygons)
    const URL = videoSource;
    const minWidth = 400;
    const [polygons,setPolygons]=useState([])
    const [zoom, setZoom] = useState(640);
    const [Map,setMap]=useState( {
        name: 'my-map',
        // GET JSON FROM BELOW URL AS AN EXAMPLE
        areas: [],
    })
    var MAP = {
      name: 'my-map',
      // GET JSON FROM BELOW URL AS AN EXAMPLE
      areas: 'https://raw.githubusercontent.com/img-mapper/react-docs/master/src/assets/example.json',
    };
    const doMathCalculations=()=>{
        // try{
            let data=polygonsRedux
            let arr=[];
            data&&data?.length>0&&data?.map((itm,index)=>{
                let cor=[];
                itm?.points&&itm?.points?.length>0&&itm?.points?.map((po)=>{
                    cor.push(po[0])
                    cor.push(po[1])
                })
                arr.push({
                    "id": "469f9800-c45a-483f-b13e-bd24f3fb79f4",
                    "title": "Hardwood",
                    "shape": "poly",
                    "name": "1",
                    "fillColor": "#e1150b52",
                    "strokeColor": "#f00",
                    "coords": cor,
                    "polygon":itm?.points,
                })
            })
            setMap({
                name: 'my-map',
                areas: arr,
            });
        
        // }catch(error){
        //     console.log(error?.message)
        // }
    }
    
    const handleZoom = type => {
        setZoom(prev => {
          if (prev <= minWidth && type === 'out') return prev;
          return type === 'in' ? prev + 10 : prev -10;
        });
    };


    React.useEffect(()=>{
        if(polygonsRedux){
            doMathCalculations()
        }
        return () => {
            
        }
    },[polygonsRedux])
  
  return (
    <CustomWrapper>

     <ImageMapper 
        src={videoSource} 
        map={Map} 
        responsive
        parentWidth={zoom}
     />
    <div 
    style={{
        display: "flex",
        justifyContent: "center",
        left:'20px',
        top:'110px',
        flexDirection:'column',
        alignItems: "center",
        position:'absolute',
        zIndex:2990
    }}>
        <CustomeButton style={{fontSize:'35px',marginRight: 8 ,width:'50px',height:'50px',borderRadius:'50%',display:'flex',justifyContent:'center',alignItems:'center'}} onClick={() => handleZoom('in')}>{<ZoomInIcon/>}</CustomeButton>
        <CustomeButton style={{fontSize:'35px',marginRight: 8 ,width:'50px',height:'50px',borderRadius:'50%',display:'flex',justifyContent:'center',alignItems:'center'}} onClick={() => handleZoom('out')}>{<ZoomOutIcon/>}</CustomeButton>
    </div>
    </CustomWrapper>
  )
}
