import callAPI from '../ApiConfig'
import store from '../../../app/store'

const RoomType = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"room_types",
        method:"get",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
    
}
const RoomTypeList = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"room_types_list",
        method:"get",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
    
}
const addRoomType = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"room_types",
        method:"post",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        },
        data:props?.data,
    })
    
}
const updateRoomType = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"room_types/"+props?.data?.id,
        method:"put",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        },
        data:props?.data
    })
    
}
const deleteRoomType = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"room_types/"+props?.data?.id,
        method:"delete",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        },
    })
    
}
const RoomTypeAPI={
    RoomType:RoomType,
    RoomTypeList:RoomTypeList,
    addRoomType:addRoomType,
    updateRoomType:updateRoomType,
    deleteRoomType:deleteRoomType

}
export default RoomTypeAPI