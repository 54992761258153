import * as React from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import Finance from './Components/Finance';
import Salse from './Components/Salse';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
  
      {...other}
    >
      {value === index && (
        <Box >
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}
export default function DashboardTabs(props) {
    const theme = useTheme();
    const [value, setValue] = React.useState(0);
    const [t]=useTranslation('common')
  
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
    const handleChangeIndex = (index) => {
      setValue(index);
    };

  return (
    <Box sx={{width:'100%',
    '& .MuiPaper-root':{
      marginLeft:'10px !important'
    },
    }}>
    <AppBar 
    position="static"
    sx={{
      '& .MuiPaper-root':{
        marginLeft:'10px !important'
      },
        width:'fit-content !important',
        minWidth:'300px !important',
        backgroundColor:'#1e6a99 !important',
        '& .MuiTabs-indicator':{
          backgroundColor:'#fff !important'
        },
        '& .MuiButtonBase-root':{
            fontFamily: 'Cairo-Medium !important',
        },

    }}
    
    >
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="secondary"
        textColor="inherit"
        variant="fullWidth"
        aria-label="full width tabs example"
      >
        <Tab label={t('DASHBOARD.salse')} {...a11yProps(0)} />
        <Tab label={t('DASHBOARD.Finance')} {...a11yProps(1)} />
      </Tabs>
    </AppBar>
    {/* <SwipeableViews
      axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
      index={value}
      onChangeIndex={handleChangeIndex}
      sx={{
        position:'inherit !important'
      }}
    > */}
      <TabPanel value={value} index={0} dir={theme.direction} sx={{width:'100%'}}>
         <Salse 
         dashboards={props?.dashboards} loadDataOfDashboards={props?.loadDataOfDashboards}
         pageNumber={props?.pageNumber}
         setPageNumber={props?.setPageNumber}
         pageSize={props?.pageSize}
         setPageSize={props?.setPageSize}
         />
      </TabPanel>
      <TabPanel value={value} index={1} dir={theme.direction} sx={{width:'100%'}}>
         <Finance 
         dashboards={props?.dashboards} loadDataOfDashboards={props?.loadDataOfDashboards}
         pageNumber={props?.pageNumber}
         setPageNumber={props?.setPageNumber}
         pageSize={props?.pageSize}
         setPageSize={props?.setPageSize}
         />
      </TabPanel>
    {/* </SwipeableViews> */}
  </Box>
  )
}
