import React,{useState,useEffect,useMemo,useCallback,useRef}from 'react'
import compoundMapImage from '../../assets/img/compound-building.png'
import { useTranslation } from "react-i18next";
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import ImageMapper from 'react-img-mapper';
import ReactTooltip from "react-tooltip";
import { Box, IconButton, Typography ,Divider} from "@mui/material";
// import {useWindowSize} from "@rehooks/window-size";
import Test from '../../assets/img/Test.png'
import * as FaIcons from 'react-icons/fa'
// import "./iraq_map.css";
import { ImBoxRemove, ImTree } from "react-icons/im";
import ApartmentIcon from '@mui/icons-material/Apartment';
import PinDropIcon from '@mui/icons-material/PinDrop';
import {useSelector,useDispatch} from 'react-redux'
import {useNavigate} from 'react-router'
import ErrorIcon from '@mui/icons-material/Error';
import { Stage, Layer, Image } from "react-konva";
import PolygonAnnotation from "../DrawMapper/components/PolygonAnnotation";


const dataPaths=[
  {
  "id": "469f9800-c45a-483f-b13e-bd24f3fb79f4",
  "title": "Hardwood",
  "shape": "poly",
  "name": "1",
  "fillColor": "#eab54d4d",
  "strokeColor": "black",
  "coords": [
  520.0646766169153,
  393.0348258706467,
  85.23880597014923,
  378.6069651741293,
  637,
  479,
  13.099502487562177,
  478.10945273631836,
  11.606965174129343,
  438.3084577114427
  ],
  "polygon": [
  [
  520.0646766169153,
  393.0348258706467
  ],
  [
  85.23880597014923,
  378.6069651741293
  ],
  [
  637,
  479
  ],
  [
  13.099502487562177,
  478.10945273631836
  ],
  [
  11.606965174129343,
  438.3084577114427
  ]
  ]
  },
  {
  "id": "1db62daa-22a4-4b02-b5c0-fffdcf77c66c",
  "title": "Carpet",
  "shape": "poly",
  "name": "2",
  "fillColor": "#eab54d4d",
  "strokeColor": "black",
  "coords": [
  126.5323383084577,
  345.273631840796,
  465.3383084577114,
  349.25373134328356,
  520.0646766169153,
  393.0348258706467,
  85.23880597014923,
  378.6069651741293
  ],
  "polygon": [
  [
  126.5323383084577,
  345.273631840796
  ],
  [
  465.3383084577114,
  349.25373134328356
  ],
  [
  520.0646766169153,
  393.0348258706467
  ],
  [
  85.23880597014923,
  378.6069651741293
  ]
  ]
  },
  {
  "id": "667d73b1-4583-4080-ab6b-5759f25440bb",
  "title": "Materials",
  "shape": "poly",
  "name": "3",
  "fillColor": "#eab54d4d",
  "strokeColor": "black",
  "coords": [],
  "polygon": []
  },
  {
  "id": "a87203cb-3916-48ea-856f-2bacab8b7eda",
  "title": "Floor",
  "shape": "poly",
  "name": "4",
  "fillColor": "#eab54d4d",
  "strokeColor": "black",
  "coords": [
  130.0149253731343,
  341.2935323383084,
  462.8507462686566,
  347.7611940298507,
  637,
  479,
  13.099502487562177,
  478.10945273631836,
  11.606965174129343,
  438.3084577114427
  ],
  "polygon": [
  [
  130.0149253731343,
  341.2935323383084
  ],
  [
  462.8507462686566,
  347.7611940298507
  ],
  [
  637,
  479
  ],
  [
  13.099502487562177,
  478.10945273631836
  ],
  [
  11.606965174129343,
  438.3084577114427
  ]
  ]
  },
  {
  "id": "37ed1569-1e68-4816-9033-1a88c53b39df",
  "title": "Electrical Fixture",
  "shape": "poly",
  "name": "5",
  "fillColor": "#00ff194c",
  "strokeColor": "black",
  "coords": [
  521.0597014925372,
  335.820895522388,
  528.0248756218905,
  338.30845771144277,
  527.0298507462686,
  354.228855721393,
  518.0746268656716,
  349.25373134328356
  ],
  "polygon": [
  [
  521.0597014925372,
  335.820895522388
  ],
  [
  528.0248756218905,
  338.30845771144277
  ],
  [
  527.0298507462686,
  354.228855721393
  ],
  [
  518.0746268656716,
  349.25373134328356
  ]
  ]
  },
  {
  "id": "ce471cbe-4103-45cc-899c-2be6497dc79a",
  "title": "Electrical Fixture",
  "shape": "poly",
  "name": "6",
  "fillColor": "#00ff194c",
  "strokeColor": "black",
  "coords": [
  531.5074626865671,
  342.2885572139303,
  538.4726368159203,
  342.78606965174123,
  538.4726368159203,
  357.7114427860696,
  530.5124378109452,
  355.72139303482584
  ],
  "polygon": [
  [
  531.5074626865671,
  342.2885572139303
  ],
  [
  538.4726368159203,
  342.78606965174123
  ],
  [
  538.4726368159203,
  357.7114427860696
  ],
  [
  530.5124378109452,
  355.72139303482584
  ]
  ]
  },
  {
  "id": "5fde0edd-4e1c-4130-9ee5-4ec6dfd34f46",
  "title": "Electrical Fixture",
  "shape": "poly",
  "name": "7",
  "fillColor": "#00ff194c",
  "strokeColor": "black",
  "coords": [
  589.2189054726367,
  136.31840796019898,
  605.6368159203979,
  133.83084577114425,
  604.1442786069651,
  153.73134328358208,
  590.7114427860696,
  153.23383084577114
  ],
  "polygon": [
  [
  589.2189054726367,
  136.31840796019898
  ],
  [
  605.6368159203979,
  133.83084577114425
  ],
  [
  604.1442786069651,
  153.73134328358208
  ],
  [
  590.7114427860696,
  153.23383084577114
  ]
  ]
  },
  {
  "id": "976082e0-0653-4e5d-8094-cc351e482e72",
  "title": "Electrical Fixture",
  "shape": "poly",
  "name": "8",
  "fillColor": "#00ff194c",
  "strokeColor": "black",
  "coords": [
  606.6318407960198,
  130.8457711442786,
  619.5671641791045,
  129.8507462686567,
  621.0597014925372,
  152.73631840796017,
  606.1343283582089,
  155.72139303482587
  ],
  "polygon": [
  [
  606.6318407960198,
  130.8457711442786
  ],
  [
  619.5671641791045,
  129.8507462686567
  ],
  [
  621.0597014925372,
  152.73631840796017
  ],
  [
  606.1343283582089,
  155.72139303482587
  ]
  ]
  },
  {
  "id": "cc3c2799-ce62-4236-b4f6-6f4b50e7b666",
  "title": "GWB",
  "shape": "poly",
  "name": "9",
  "fillColor": "#00ff194c",
  "strokeColor": "black",
  "coords": [
  521.5621890547263,
  103.98009950248755,
  508.6268656716418,
  381.09452736318406,
  638.9701492537313,
  28.358208955223876,
  637.9751243781094,
  477.6119402985074
  ],
  "polygon": [
  [
  521.5621890547263,
  103.98009950248755
  ],
  [
  508.6268656716418,
  381.09452736318406
  ],
  [
  638.9701492537313,
  28.358208955223876
  ],
  [
  637.9751243781094,
  477.6119402985074
  ]
  ]
  },
  {
  "id": "6c682813-8162-42eb-b3a7-c7296a009b5a",
  "title": "Brick",
  "shape": "poly",
  "name": "10",
  "fillColor": "#00ff194c",
  "strokeColor": "black",
  "coords": [
  465.8358208955224,
  137.81094527363183,
  520.5621890547263,
  103.98009950248755,
  507.6268656716418,
  381.09452736318406,
  464.3432835820895,
  350.7462686567164
  ],
  "polygon": [
  [
  465.8358208955224,
  137.81094527363183
  ],
  [
  520.5621890547263,
  103.98009950248755
  ],
  [
  507.6268656716418,
  381.09452736318406
  ],
  [
  464.3432835820895,
  350.7462686567164
  ]
  ]
  },
  {
  "id": "1c9cabf2-4306-46cd-9423-63c7156cf4d4",
  "title": "Materials",
  "shape": "poly",
  "name": "11",
  "fillColor": "#00ff194c",
  "strokeColor": "black",
  "coords": [],
  "polygon": []
  },
  {
  "id": "53c311f7-4e1c-4636-ac7e-b9cdec0d7ab7",
  "title": "Right Wall",
  "shape": "poly",
  "name": "12",
  "fillColor": "#00ff194c",
  "strokeColor": "black",
  "coords": [
  465.8358208955224,
  138.8059701492537,
  638.9701492537313,
  28.358208955223876,
  637.9751243781094,
  477.6119402985074,
  463.8457711442785,
  349.25373134328356
  ],
  "polygon": [
  [
  465.8358208955224,
  138.8059701492537
  ],
  [
  638.9701492537313,
  28.358208955223876
  ],
  [
  637.9751243781094,
  477.6119402985074
  ],
  [
  463.8457711442785,
  349.25373134328356
  ]
  ]
  },
  {
  "id": "21a3befd-c97b-476d-8e0c-7c98399988bf",
  "title": "Window",
  "shape": "poly",
  "name": "13",
  "fillColor": "#00ff194c",
  "strokeColor": "black",
  "coords": [
  211.10945273631836,
  161.6915422885572,
  387.7263681592039,
  164.67661691542287,
  383.7462686567164,
  292.5373134328358,
  207.62686567164178,
  288.5572139303482
  ],
  "polygon": [
  [
  211.10945273631836,
  161.6915422885572
  ],
  [
  387.7263681592039,
  164.67661691542287
  ],
  [
  383.7462686567164,
  292.5373134328358
  ],
  [
  207.62686567164178,
  288.5572139303482
  ]
  ]
  },
  {
  "id": "2f36ad1d-b934-4fb0-9486-7f429ef46a1b",
  "title": "Front Wall",
  "shape": "poly",
  "name": "14",
  "fillColor": "#00ff194c",
  "strokeColor": "black",
  "coords": [
  131.50746268656715,
  131.34328358208953,
  465.3383084577114,
  138.30845771144277,
  462.35323383084574,
  347.7611940298507,
  129.51741293532336,
  341.79104477611935
  ],
  "polygon": [
  [
  131.50746268656715,
  131.34328358208953
  ],
  [
  465.3383084577114,
  138.30845771144277
  ],
  [
  462.35323383084574,
  347.7611940298507
  ],
  [
  129.51741293532336,
  341.79104477611935
  ]
  ]
  },
  {
  "id": "f3653fb6-c1c5-4fe7-aec1-699d9da7bba1",
  "title": "Microwave",
  "shape": "poly",
  "name": "15",
  "fillColor": "#00ff194c",
  "strokeColor": "black",
  "coords": [
  120.06467661691539,
  193.5323383084577,
  145.93532338308455,
  197.51243781094524,
  146.4328358208955,
  234.82587064676613,
  118.07462686567163,
  233.33333333333331
  ],
  "polygon": [
  [
  120.06467661691539,
  193.5323383084577
  ],
  [
  145.93532338308455,
  197.51243781094524
  ],
  [
  146.4328358208955,
  234.82587064676613
  ],
  [
  118.07462686567163,
  233.33333333333331
  ]
  ]
  },
  {
  "id": "eca521ca-11c6-4312-830b-3492829649df",
  "title": "Stove",
  "shape": "poly",
  "name": "16",
  "fillColor": "#00ff194c",
  "strokeColor": "black",
  "coords": [
  85.73631840796017,
  254.22885572139302,
  85.73631840796017,
  279.10447761194024,
  139.46766169154228,
  282.08955223880594,
  162.85074626865668,
  276.1194029850746,
  118.07462686567163,
  274.6268656716418,
  117.57711442786066,
  264.67661691542287,
  115.08955223880594,
  249.7512437810945
  ],
  "polygon": [
  [
  85.73631840796017,
  254.22885572139302
  ],
  [
  85.73631840796017,
  279.10447761194024
  ],
  [
  139.46766169154228,
  282.08955223880594
  ],
  [
  162.85074626865668,
  276.1194029850746
  ],
  [
  118.07462686567163,
  274.6268656716418
  ],
  [
  117.57711442786066,
  264.67661691542287
  ],
  [
  115.08955223880594,
  249.7512437810945
  ]
  ]
  },
  {
  "id": "e8da6027-7563-4a50-9b7b-9ffc1bb1b613",
  "title": "Oven",
  "shape": "poly",
  "name": "17",
  "fillColor": "#00ff194c",
  "strokeColor": "black",
  "coords": [
  145.4378109452736,
  288.0597014925373,
  164.34328358208953,
  282.08955223880594,
  166.33333333333331,
  353.731343283582,
  142.45273631840794,
  371.6417910447761
  ],
  "polygon": [
  [
  145.4378109452736,
  288.0597014925373
  ],
  [
  164.34328358208953,
  282.08955223880594
  ],
  [
  166.33333333333331,
  353.731343283582
  ],
  [
  142.45273631840794,
  371.6417910447761
  ]
  ]
  },
  {
  "id": "5248f935-10c8-4b16-8cff-21b66d2cb56f",
  "title": "Countertop",
  "shape": "poly",
  "name": "18",
  "fillColor": "#00ff194c",
  "strokeColor": "black",
  "coords": [
  70.31343283582089,
  287.56218905472633,
  82.25373134328356,
  281.09452736318406,
  140.46268656716416,
  283.0845771144278,
  77.77611940298505,
  303.4825870646766
  ],
  "polygon": [
  [
  70.31343283582089,
  287.56218905472633
  ],
  [
  82.25373134328356,
  281.09452736318406
  ],
  [
  140.46268656716416,
  283.0845771144278
  ],
  [
  77.77611940298505,
  303.4825870646766
  ]
  ]
  },
  {
  "id": "5b40c828-ecb3-4633-b181-78e2832823b1",
  "title": "Double Cabinet",
  "shape": "poly",
  "name": "19",
  "fillColor": "#00ff194c",
  "strokeColor": "black",
  "coords": [
  108.62189054726366,
  298.5074626865671,
  139.46766169154228,
  289.5522388059701,
  138.47263681592037,
  364.1791044776119,
  106.13432835820893,
  390.54726368159197
  ],
  "polygon": [
  [
  108.62189054726366,
  298.5074626865671
  ],
  [
  139.46766169154228,
  289.5522388059701
  ],
  [
  138.47263681592037,
  364.1791044776119
  ],
  [
  106.13432835820893,
  390.54726368159197
  ]
  ]
  },
  {
  "id": "7810e113-49d2-4284-9e49-318af8378663",
  "title": "Dishwasher",
  "shape": "poly",
  "name": "20",
  "fillColor": "#00ff194c",
  "strokeColor": "black",
  "coords": [
  81.25870646766168,
  308.45771144278604,
  108.12437810945272,
  300.4975124378109,
  106.13432835820893,
  393.53233830845767,
  80.26368159203977,
  410.4477611940298
  ],
  "polygon": [
  [
  81.25870646766168,
  308.45771144278604
  ],
  [
  108.12437810945272,
  300.4975124378109
  ],
  [
  106.13432835820893,
  393.53233830845767
  ],
  [
  80.26368159203977,
  410.4477611940298
  ]
  ]
  },
  {
  "id": "5998531a-25b3-4288-adbe-53c4470a369b",
  "title": "Refrigerator",
  "shape": "poly",
  "name": "21",
  "fillColor": "#00ff194c",
  "strokeColor": "black",
  "coords": [
  18.572139303482572,
  169.65174129353233,
  82.25373134328356,
  182.5870646766169,
  80.76119402985074,
  424.8756218905472,
  14.09452736318407,
  475.6218905472636
  ],
  "polygon": [
  [
  18.572139303482572,
  169.65174129353233
  ],
  [
  82.25373134328356,
  182.5870646766169
  ],
  [
  80.76119402985074,
  424.8756218905472
  ],
  [
  14.09452736318407,
  475.6218905472636
  ]
  ]
  },
  {
  "id": "9db9f57d-c15e-4d3a-abb7-faa7e69657c8",
  "title": "Single Cabinet",
  "shape": "poly",
  "name": "22",
  "fillColor": "#00ff194c",
  "strokeColor": "black",
  "coords": [
  140.46268656716416,
  142.28855721393032,
  149.91542288557213,
  148.75621890547262,
  147.4278606965174,
  234.3283582089552,
  138.9701492537313,
  232.3383084577114
  ],
  "polygon": [
  [
  140.46268656716416,
  142.28855721393032
  ],
  [
  149.91542288557213,
  148.75621890547262
  ],
  [
  147.4278606965174,
  234.3283582089552
  ],
  [
  138.9701492537313,
  232.3383084577114
  ]
  ]
  },
  {
  "id": "d2c06088-49ce-404b-ab78-d865a336248d",
  "title": "Double Cabinet",
  "shape": "poly",
  "name": "23",
  "fillColor": "#00ff194c",
  "strokeColor": "black",
  "coords": [
  111.10945273631839,
  128.35820895522386,
  139.46766169154228,
  142.7860696517413,
  139.96517412935322,
  196.01990049751242,
  112.10447761194027,
  191.04477611940297
  ],
  "polygon": [
  [
  111.10945273631839,
  128.35820895522386
  ],
  [
  139.46766169154228,
  142.7860696517413
  ],
  [
  139.96517412935322,
  196.01990049751242
  ],
  [
  112.10447761194027,
  191.04477611940297
  ]
  ]
  },
  {
  "id": "07feade7-e370-4384-bb96-c21f9eedb238",
  "title": "Double Cabinet",
  "shape": "poly",
  "name": "24",
  "fillColor": "#00ff194c",
  "strokeColor": "black",
  "coords": [
  72.80099502487562,
  108.45771144278606,
  111.60696517412933,
  127.36318407960198,
  112.60199004975124,
  233.83084577114425,
  74.79104477611938,
  234.82587064676613
  ],
  "polygon": [
  [
  72.80099502487562,
  108.45771144278606
  ],
  [
  111.60696517412933,
  127.36318407960198
  ],
  [
  112.60199004975124,
  233.83084577114425
  ],
  [
  74.79104477611938,
  234.82587064676613
  ]
  ]
  },
  {
  "id": "db82b663-6c46-4a21-9ba6-fa6aa5bf84dc",
  "title": "Double Cabinet",
  "shape": "poly",
  "name": "25",
  "fillColor": "#00ff194c",
  "strokeColor": "black",
  "coords": [
  19.567164179104466,
  56.21890547263681,
  71.80597014925371,
  87.56218905472636,
  71.80597014925371,
  173.63184079601987,
  18.572139303482572,
  159.20398009950247
  ],
  "polygon": [
  [
  19.567164179104466,
  56.21890547263681
  ],
  [
  71.80597014925371,
  87.56218905472636
  ],
  [
  71.80597014925371,
  173.63184079601987
  ],
  [
  18.572139303482572,
  159.20398009950247
  ]
  ]
  },
  {
  "id": "9258a68c-dc5d-4b08-bee1-720d8e8e3509",
  "title": "Left Wall",
  "shape": "poly",
  "name": "26",
  "fillColor": "#00ff194c",
  "strokeColor": "black",
  "coords": [
  20.064676616915406,
  57.71144278606965,
  131.50746268656715,
  131.34328358208953,
  130.0149253731343,
  341.79104477611935,
  12.104477611940283,
  436.8159203980099
  ],
  "polygon": [
  [
  20.064676616915406,
  57.71144278606965
  ],
  [
  131.50746268656715,
  131.34328358208953
  ],
  [
  130.0149253731343,
  341.79104477611935
  ],
  [
  12.104477611940283,
  436.8159203980099
  ]
  ]
  },
  {
  "id": "e30e9e21-0a03-4514-9473-887f23991361",
  "title": "Vent",
  "shape": "poly",
  "name": "27",
  "fillColor": "#ff000026",
  "strokeColor": "black",
  "coords": [
  249.91542288557213,
  0,
  299.66666666666663,
  0.49751243781094523,
  298.67164179104475,
  13.930348258706466,
  250.910447761194,
  13.930348258706466
  ],
  "polygon": [
  [
  249.91542288557213,
  0
  ],
  [
  299.66666666666663,
  0.49751243781094523
  ],
  [
  298.67164179104475,
  13.930348258706466
  ],
  [
  250.910447761194,
  13.930348258706466
  ]
  ]
  },
  {
  "id": "f5a8d660-61df-4783-a631-8ea6758ee50d",
  "title": "Vent",
  "shape": "poly",
  "name": "28",
  "fillColor": "#ff000026",
  "strokeColor": "black",
  "coords": [
  285.2388059701492,
  117.41293532338307,
  309.1194029850746,
  116.91542288557213,
  309.1194029850746,
  128.8557213930348,
  286.731343283582,
  128.35820895522386
  ],
  "polygon": [
  [
  285.2388059701492,
  117.41293532338307
  ],
  [
  309.1194029850746,
  116.91542288557213
  ],
  [
  309.1194029850746,
  128.8557213930348
  ],
  [
  286.731343283582,
  128.35820895522386
  ]
  ]
  },
  {
  "id": "6721b73c-a4f7-486c-8d72-5d7e817db59a",
  "title": "Light",
  "shape": "poly",
  "name": "29",
  "fillColor": "#ff000026",
  "strokeColor": "black",
  "coords": [
  266.83084577114425,
  93.5323383084577,
  277.2786069651741,
  93.03482587064676,
  277.2786069651741,
  99.50248756218905,
  267.3283582089552,
  99.00497512437809
  ],
  "polygon": [
  [
  266.83084577114425,
  93.5323383084577
  ],
  [
  277.2786069651741,
  93.03482587064676
  ],
  [
  277.2786069651741,
  99.50248756218905
  ],
  [
  267.3283582089552,
  99.00497512437809
  ]
  ]
  },
  {
  "id": "6fe8c503-66f8-47be-bad8-5a39d170e538",
  "title": "Recessed Light",
  "shape": "poly",
  "name": "30",
  "fillColor": "#ff000026",
  "strokeColor": "black",
  "coords": [
  206.1343283582089,
  103.48258706467661,
  227.0298507462686,
  105.47263681592038,
  222.5522388059701,
  113.93034825870646,
  207.12935323383084,
  112.93532338308457
  ],
  "polygon": [
  [
  206.1343283582089,
  103.48258706467661
  ],
  [
  227.0298507462686,
  105.47263681592038
  ],
  [
  222.5522388059701,
  113.93034825870646
  ],
  [
  207.12935323383084,
  112.93532338308457
  ]
  ]
  },
  {
  "id": "b5ef36ad-484b-4605-a2ba-72b9f1e7114f",
  "title": "Recessed Light",
  "shape": "poly",
  "name": "31",
  "fillColor": "#ff000026",
  "strokeColor": "black",
  "coords": [
  164.84079601990047,
  55.721393034825866,
  187.72636815920396,
  54.72636815920397,
  185.73631840796017,
  66.16915422885572,
  167.82587064676613,
  66.66666666666666
  ],
  "polygon": [
  [
  164.84079601990047,
  55.721393034825866
  ],
  [
  187.72636815920396,
  54.72636815920397
  ],
  [
  185.73631840796017,
  66.16915422885572
  ],
  [
  167.82587064676613,
  66.66666666666666
  ]
  ]
  },
  {
  "id": "75449960-7fde-4907-a463-7bb5b146d70c",
  "title": "Ceiling",
  "shape": "poly",
  "name": "32",
  "fillColor": "#ff000026",
  "strokeColor": "black",
  "coords": [
  19.567164179104466,
  52.73631840796019,
  19.567164179104466,
  1.990049751243781,
  637.9751243781094,
  1.4925373134328357,
  638.9701492537313,
  28.358208955223876,
  464.8407960199004,
  138.8059701492537,
  131.50746268656715,
  130.34825870646765
  ],
  "polygon": [
  [
  19.567164179104466,
  52.73631840796019
  ],
  [
  19.567164179104466,
  1.990049751243781
  ],
  [
  637.9751243781094,
  1.4925373134328357
  ],
  [
  638.9701492537313,
  28.358208955223876
  ],
  [
  464.8407960199004,
  138.8059701492537
  ],
  [
  131.50746268656715,
  130.34825870646765
  ]
  ]
  }
  ]

export default function CompoundBuilding() {
  const imageRef = useRef(null);
  const [image, setImage] = useState();
  const [size, setSize] = useState({
    width:700,
    height:620
  });
    const stageRef = useRef(null);
    const width = 700;
    const height = 620;

    const tooltipContainer = React.useRef(null)
    const [t] = useTranslation("common");
    const [polygons,setPolygons]=useState([])
    const minWidth = 400;
    const [zoom, setZoom] = useState(500);
    const [zoom2, setZoom2] = useState(620); 

    // const [width, setWidth] = useState(0);
    const [hoveredArea, setHoveredArea] = useState(null);
    const [images, setImages] = useState({});
    const { innerHeight, innerWidth } = window;
    const polygonsRedux=useSelector(state=>state.settingsData.polygons)
    const navigate =useNavigate()
    const [Map,setMap]=useState( {
        name: 'my-map',
        // GET JSON FROM BELOW URL AS AN EXAMPLE
        areas:[
          {
          "id": "469f9800-c45a-483f-b13e-bd24f3fb79f4",
          "title": "Hardwood",
          "shape": "poly",
          "name": "1",
          "fillColor": "#e1150b52",
          'preFillColor': 'rgba(0,255,0,0.4)',
          "strokeColor": "#f00",
          "coords":[190,305,144,304,145,276,193,276],"href":"","title":"","id":4,"closed":true,
          'polygon':[
            [190,305],[144,304],[145,276],[193,276]
          ]
        },
          {
            "id": "469f9800-c45a-483f-b13e-bd24f3fb79f4",
            "title": "Hardwood",
            "shape": "poly",
            "name": "1",
            "fillColor": "#e1150b52",
            'preFillColor': 'rgba(0,255,0,0.4)',
            "strokeColor": "#f00",
            "coords":[354,277,308,277,309,247,356,247],
            'polygon':[
              [354,277],[308,277],[309,247],[356,247]
            ],
            "href":"","title":"","id":3,"closed":true},
        ]
    })

    const videoElement = useMemo(() => {
        const element = new window.Image();
        element.width = 500;
        element.height = 620;
        // element.style.position="fixed";
        // element.style.top="60px";
        // element.style.left="84px";
        // element.style.bottom="0";
        element.style.objectFit="scale-down";
        // element.style.zIndex="23432423";
        element.src = compoundMapImage;
        // element.style.width="100%";
        // element.style.height="100%";
        element.classList.add('imgMapper')
        return element;
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [compoundMapImage]); 
    useEffect(() => {
        const onload = function () {
          setSize({
            width: videoElement.width,
            height: videoElement.height,
          });
          setImage(videoElement);
          imageRef.current = videoElement;
        };
        videoElement.addEventListener("load", onload);
        return () => {
          videoElement.removeEventListener("load", onload);
        };
      }, [videoElement]);


    useEffect(() => {
      // setWidth(innerWidth);
    }, [innerHeight, innerWidth]);

    const doMathCalculations=()=>{
      // try{
          const randomColor=['rgba(255,0,0,0.4)','rgba(0,255,0,0.3)']
          let data=polygonsRedux
          let arr=[];
          data&&data?.length>0&&data?.map((itm,index)=>{
              let cor=[];
              itm?.points&&itm?.points?.length>0&&itm?.points?.map((po)=>{
                  cor.push(po[0])
                  cor.push(po[1])
              })
              arr.push({
                  "id": "469f9800-c45a-483f-b13e-bd24f3fb79f4",
                  "title": "Hardwood",
                  "shape": "poly",
                  "name": "1",
                  "fillColor": "#e1150b52",
                  'preFillColor': randomColor[Math.round(Math.random() * 1)],
                  "strokeColor": "#f00",
                  "coords": cor,
                  "polygon":itm?.points,
              })
          })
          setMap({
              name: 'my-map',
              areas: arr,
          });
      
      // }catch(error){
      //     console.log(error?.message)
      // }
  }

    useEffect(()=>{
      // doMathCalculations()
    },[])

    
    const handleZoom = type => {
        setZoom(prev => {
          if (prev <= minWidth && type === 'out') return prev;
          return type === 'in' ? prev + 20 : prev -20;
        });
        setZoom2(prev => {
          if (prev <= minWidth && type === 'out') return prev;
          return type === 'in' ? prev + 5.8 : prev -5.8;
        });
    };

    function clickArea(area) {
      // setImages(prevImage => ({
      //   ...prevImage,
      //   [area.id]: {
      //     ...area,
      //     show: prevImage[area.id] && !prevImage[area.id].show
      //   }
      // }));
      navigate('/admin/available-compounds/compound-map/compound-building')
    }

    function enterArea(area) {
      setImages({
          ...area,
          show: true
      });
    }
  
    function leaveArea() {
      setImages(null);
    }
  
    function getTipPosition(area) {
      return { top: `${area.center[1]}px`, left: `${area.center[0]}px` };
    }

    const handleMouseOut=(polygon)=>{
      console.log('logOut',null)
      setImages(null)


    }
    const handleMouseOver=(e,polygon,n)=>{
      // console.log('logOver',e)
      console.log('logOver',e)
      setImages({
        ...polygon,
        show:true,
        top:e.evt.clientY,
        left:e.evt.clientX
      })

    }
    const [stage, setStage] = useState({
      scale: 1,
      x: 0,
      y: 0
    });
    const scaleRelativeToPoint = (point, increaseScale) => {
      const scaleBy = 1.02;
      const stage = stageRef.current;
      const oldScale = stage.scaleX();
      const mousePointTo = {
        x: point.x / oldScale - stage.x() / oldScale,
        y: point.y / oldScale - stage.y() / oldScale
      };
  
      const newScale = increaseScale ? oldScale * scaleBy : oldScale / scaleBy;
  
      setStage({
        scale: newScale,
        x: (point.x / newScale - mousePointTo.x) * newScale,
        y: (point.y / newScale - mousePointTo.y) * newScale
      });
    };
  
    const handleWheel = (e) => {
      e.evt.preventDefault();
      scaleRelativeToPoint(
        e.target.getStage().getPointerPosition(),
        e.evt.deltaY < 0
      );
    };

  return (
    <Box style={{
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      width: '100%',
      justifyContent: 'center',
      alignItems: 'start',
      overflowX: 'hidden',

    }}>
        <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              height: 'fit-content',
              justifyContent: 'center',
              alignItems: 'stretch',
              // backgroundColor: '#fff',
              position: 'relative',
              // top:'-25px'
            }}
         >
          <Box sx={{width:'100%',display:'flex',justifyContent:'center',alignItems:'center'}}>
            <Typography
               variant='h4'
               sx={{
                  marginLeft: '10px',
                  marginBottom: '10px',
                  fontFamily: 'Cairo-Bold',
                  fontSize: '16px'
               }}>
               {t('CUSTOMER.select_compound_building')}
            </Typography>
         </Box>
          
         </Box>

         <Divider
            sx={{
               backgroundColor: '#EBF2F7',
               margin: '30px',
               marginTop:'10px',
               width:'100%'
            }}
            style={{
               height: '3px',

            }}
         />

    <Box 
    sx={{
        position:'relative',
        overflow:'hidden !important',
        display:'flex',
        justifyContent: 'start !important',
        alignItems: 'center !important',
        width:'100% !important',
        height:'100% !important',
        '& > div:nth-child(1)':{
          display:'flex',
          justifyContent: 'center !important',
          alignItems: 'center !important',
          // width:'100% !important',
          // height:'100% !important',
        },
        '& .konvajs-content':{
          display:'flex',
          justifyContent: 'center !important',
          alignItems: 'center !important',
          // width:'100% !important',
          // height:'100% !important',
        }
        // '& .img-mapper-img':{
        //     position:'relative !important',
        //     top:'60px !important',
        //     left:'0 !important',
        //     bottom:0+' !important',
        //     right:'84px !important',
        //     width:zoom+'px !important',
        //     height:zoom2+'px  !important',
        //     objectFit:'revert !important',
        //     zIndex:100,
        // },
        // '& .img-mapper-canvas':{
        //     width:zoom,
        //     height:zoom2+' !important',
        // },
        // '& #img-mapper':{
        //     width:zoom,
        //     height:zoom2+'px  !important',

        // }
    }}>
      

      {/* <ImageMapper
        src={compoundMapImage}
        map={Map}
        onClick={area => clickArea(area)}
        onMouseEnter={area => enterArea(area)}
        onMouseLeave={() => leaveArea()}
        imgWidth={zoom}
        height={zoom2}
        responsive
        parentWidth={zoom}
        
      /> */}
         <Stage
                width={size.width}
                height={size.height}
                style={{
                  position:'fixed',
                  top:'80px',
                  left:'0',
                  right:'134px',
                  bottom:'0'
                }}
                onWheel={handleWheel}
                scaleX={stage.scale}
                scaleY={stage.scale}
                x={stage.x}
                y={stage.y}
                ref={stageRef}
              >
                <Layer >
                  <Image
                    ref={imageRef}
                    image={image}
                    x={0}
                    y={0}
                    width={size.width}
                    height={size.height}
                    style={{
                       objectFit:'cover',
                    }}
                    
                  />
                  {polygonsRedux.map((polygon,index)=>(
                    <PolygonAnnotation
                    key={index}
                    points={polygon.points}
                    polygon={polygon}
                    flattenedPoints={polygon.flattenedPoints}
                    notHasLine={true}
                    notHasRadius={true}
                    notHasColorRadius={true}

                    // handlePointDragMove={handlePointDragMove}
                    // handleGroupDragEnd={handleGroupDragEnd}
                    // handleMouseOverStartPoint={handleMouseOverStartPoint}
                    // handleMouseOutStartPoint={handleMouseOutStartPoint}
                    onMouseOver={handleMouseOver}
                    onMouseOut={handleMouseOut}
                    isFinished={true}
                    
                    
                  />
                  ))}
           
                </Layer>
         </Stage>
      {images&&images?.show?(
          <div
            // key={areaId}
            alt="test"
            src={Test}
            style={{
              position: "fixed",
              left: `${images?.left}px`,
              top: `${images.top}px`,
              pointerEvents: "none",
              background: "#C8D0D6",
              zIndex:23424234,
              width: "fit-content", 
              height: "102px", background: "#C8D0D6 0% 0% no-repeat padding-box", boxShadow: "0px 0px 15px 0px lightgrey", opacity: "1",
              transition:"all 0.9s ease-in-out",
              display:'flex',
              alignItems:'start',
              justifyContent: 'center',
              flexDirection:'column',
              padding:'5px 5px 5px 25px'
            }}
          >
            <Box
              sx={{
                display:'flex',
                alignItems:'center',
                justifyContent: 'start',
                margin:'2px 0'
              }}
            >
            <FaIcons.FaBuilding 
              style={{
                fontSize:'28px',
                color:'#1E6A99',
                margin:'0 10px'
              }}
            />
            <span style={{
              textAlign: "left", font: "normal normal bold 18px/22px Cairo", letterSpacing: "0px", color: "#212121", opacity: "1"
            }}> شقة رقم 16</span>
            </Box>

            <Box
              sx={{
                display:'flex',
                alignItems:'center',
                justifyContent: 'start',
                margin:'2px 0'

              }}
            >
            <ErrorIcon 
              sx={{
                fontSize:'28px',
                color:'#1E6A99',
                margin:'0 10px'
              }}
            />
            <span style={{
              textAlign: "left", font: "normal normal bold 18px/22px Cairo", letterSpacing: "0px", color: "#212121", opacity: "1"
            }}>مشتراة قبل 13 يوم</span>
            </Box>

          </div>
        ):null}
      {hoveredArea && (
        <span className="tooltip" style={{ ...getTipPosition(hoveredArea) ,zIndex:'3453453',color:'red',fontSize:'60px',position:'absolute'}}>
          {hoveredArea && hoveredArea.name}
        </span>
      )}
      
      <Box
        sx={{
            width: "396px",
             height: "31px", 
             background: "#FAFAFA 0% 0% no-repeat padding-box", 
             opacity: "1",
             position: "fixed",
             bottom:0,
             left:'82px',
             zIndex:200,
             display:'flex',
             justifyContent: "center",
             alignItems: "center",
             boxShadow:'0 0 7px -3px #000'
        }}
      >
        <Box sx={{display:'flex',alignItems:'center'}}>
            <span style={{
                width: "15px", height: "15px", background: "#00FF58 0% 0% no-repeat padding-box", opacity: "1",
                margin:'0 10px'
            }}></span>
            <span style={{
                textAlign: "left", font: "normal normal 300 12px/13px Cairo", letterSpacing: "0px", color: "#7D8185", opacity: "1"
            }}>{t('CUSTOMER.has_unit')}</span>
           
        </Box>
        <Box sx={{display:'flex',alignItems:'center'}}>
            <span style={{
                width: "15px", height: "15px", background: "#FF0000 0% 0% no-repeat padding-box", opacity: "1",
                margin:'0 10px'
            }}></span>
            <span style={{
                textAlign: "left", font: "normal normal 300 12px/13px Cairo", letterSpacing: "0px", color: "#7D8185", opacity: "1"
            }}>{t('CUSTOMER.no_unit')}</span>
        </Box>

      </Box>
      <Box
        sx={{
             width: "auto",
             height: "auto", 
            //  background: "#FAFAFA 0% 0% no-repeat padding-box", 
             opacity: "1",
             position: "fixed",
             bottom:'25px',
             right:'25px',
             zIndex:200,
             display:'flex',
             justifyContent: "center",
             alignItems: "center",
             flexDirection:'column'
        }}
      >
          <Box sx={{
            width: "50px", height: "50px", background: "#FFFFFF 0% 0% no-repeat padding-box",boxShadow: "0px 0 10px -3px #000", opacity: "1",
            borderRadius: "50%",
            display:'flex',
            justifyContent: "center",
            alignItems: "center",
            margin:'5px 0',
            cursor:'pointer'
          }}
          onClick={() => {
            scaleRelativeToPoint(
              // zoom relative to center of the screen
              {
                x: width / 2,
                y: height / 2
              },
              true
            );
          }}
          >
              <ZoomInIcon sx={{color:'#1E6A99',fontSize:'34px'}}/>
          </Box>
          <Box 
            sx={{
                width: "50px", height: "50px", background: "#FFFFFF 0% 0% no-repeat padding-box", boxShadow: "0px 0 10px -3px #000", opacity: "1",
            borderRadius: "50%",
            display:'flex',
            justifyContent: "center",
            alignItems: "center",
            margin:'5px 0',
            cursor:'pointer',

            }}
            onClick={() => {
              scaleRelativeToPoint(
                // zoom relative to center of the screen
                {
                  x: width / 2,
                  y: height / 2
                },
                false
              );
            }}
           >
              <ZoomOutIcon sx={{color:'#1E6A99',fontSize:'34px'}}/>
          </Box>
          <Box
           sx={{
            width: "50px", height: "50px", background: "#FFFFFF 0% 0% no-repeat padding-box",boxShadow: "0px 0 10px -3px #000", opacity: "1",
            borderRadius: "50%",
            display:'flex',
            justifyContent: "center",
            alignItems: "center",
            margin:'5px 0',
            cursor:'pointer'
           }}
            
           >
              <RemoveRedEyeIcon sx={{color:'#1E6A99',fontSize:'34px'}}/>
          </Box>

      </Box>


    </Box>
    </Box>
  )
}
