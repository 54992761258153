import React ,{useEffect,useState} from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Divider } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import ApartmentIcon from '@mui/icons-material/Apartment';

const bull = (
  <Box
    component="span"
    sx={{ display: "inline-block", mx: "2px", transform: "scale(0.8)" }}
  >
    •
  </Box>
);
export default function DashboardCard(props) {
  const [t] = useTranslation("common");
  const dashboardtheme=useSelector(state=>state.themeData.dashboardtheme)
  const [listOfSoldUnitPerCompoundPerType,setListOfSoldUnitPerCompoundPerType]=useState([])

  const getPercentageOfSoldUnitPerCompound=()=>{
    if(props?.compounds?.units_count){
      return Math.floor((props?.compounds?.sold_units/props?.compounds?.units_count)*100)
    }else return 0
  }
  const getPercentageOfSoldUnitPerCompoundDyn=(total,per)=>{
    if(parseInt(total)){
      return Math.floor((per/parseInt(total))*100)
    }else return 0
  }

  useEffect(() => {
    getPercentageOfSoldUnitPerCompoundPerType()
  }, [props?.compounds,props?.dashboards]);

  const getPercentageOfSoldUnitPerCompoundPerType=()=>{
    let arr=[]
    if(props?.dashboards&&props?.dashboards?.sales&&props?.dashboards?.sales?.units_sold_per_compound_per_master_type?.length){
      props?.dashboards?.sales?.units_sold_per_compound_per_master_type?.map((itm)=>{
          if(props?.compounds?.id==itm?.compound_id){
            arr.push(itm)
          } 
      })
    }
    setListOfSoldUnitPerCompoundPerType(arr)
  }

  return (
    <Card
      sx={{
        width: '300px',
        minHeight:'250px',
        height: "fit-content",
        backgroundColor:'#fff !important',
        borderRadius: " 5px",
        boxShadow: "0px 0px 7px -4px #000 !important",
        opacity: 1,
        height: "fit-content",
        margin:'10px',
        position:'relative',
        padding:'0 !important',
        '&:hover':{
          boxShadow:'0 0 20px 6px rgba(0,0,0,0.7)'
        },
        // '&:hover .colorWhite':{
        //   transition:'all 0.8s ease',
        //   color:'#fff !important',
        // },
        // '&:hover .backgroundTag':{
        //   transition:'all 0.8s ease',
        //   backgroundColor:'rgba(0,0,0,0.3) !important',
        // },
        // '&:hover .headerCard':{
        //   transition:'all 0.8s ease',
        //   backgroundColor:'#fff !important',
        // },
        // '& .headerCard p ,& .headerCard span,& .headerCard svg':{
        //     color:'#000 !important'
        // }
      }}
    >
      {/* <Box
        sx={{
          position:'absolute',
          top:0,
          left:0,
          right:0,
          bottom:0,
          zIndex:'1',
          clipPath: 'circle(0% at 0 100%)',
          backgroundColor:'#1e6a99 !important',
          transition:'all 0.8s ease',
        }}
        className="hoverEffect"
      >

      </Box> */}

      <CardContent
         sx={{
          width:'100%',
          display: "flex",
          flexDirection: "column",
          position:'relative',
          zIndex:'3'
         }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexDirection: "row",
            backgroundColor:'#1e6a99',
            padding:'5px 5px'
          }}
          className="headerCard"
        >
          <Box>
            <ApartmentIcon  sx={{
              color:'#fff',fontSize:'30px',
              margin:'10px 0',
              border:'2px solid lightgray',
              padding:'2px',
              borderRadius:'50%',
              }}
              className="colorWhite"
            />
            <span
              style={{
                fontFamily: "Cairo",
                flex:2,
                textAlign:'justify',
                marginRight:'4px',
                color:'#fff',
              }}
              className="colorWhite"
            >
             {props?.compounds?.name}
            </span>
          </Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "end",
              alignContent:'center',
              // paddingRight:'20px',
              flexDirection: "column",
              flex:1
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                alignContent:'center',
                flexDirection: "column",

              }}
            >
              <span
                style={{
                  width:'fit-content',
                  height:'25px',
                  backgroundColor:'lightgray',
                  borderRadius:'15px',
                  padding:'2px 5px',
                  textAlign:'center',
                  fontSize:'15px',
                  color:'#000',
                }}
                className="colorWhite backgroundTag"
              >{getPercentageOfSoldUnitPerCompound()}%</span>
              <span style={{
                fontFamily:'Cairo',
                fontSize:'14px',
                textAlign:'center',
                color:'#fff',

                // marginLeft:'5px'
              }}
              className="colorWhite"
              >{t("DASHBOARD.sold")}</span>
            </Box>
          </Box>
        </Box>
        {/* <Divider sx={{ width: "100%",margin:'10px 0',height:'2px !important' }} /> */}
        <Box
         sx={{
          fontFamily:'Cairo-Medium',
          margin:'3px 0',
          width:'fit-content',
          padding:'10px'
          // borderBottom:'2px solid lightgray'
         }}
         className="colorWhite"
        >
            <span
            className="colorWhite"
            >{t('DASHBOARD.typeOfSoldeUnits')}</span>
        </Box>
        <Box
          sx={{
            display:'flex',
            flexDirection:'row',
            justifyContent:'space-between',
            alignItems:'center',
            flexWrap:'wrap',
            width:'100%',
            padding:'10px'

          }}
        >
            {
              listOfSoldUnitPerCompoundPerType&&listOfSoldUnitPerCompoundPerType?.length ?listOfSoldUnitPerCompoundPerType?.map((itm)=>(
                itm?.master_unit_type_name&&itm?.master_unit_type_name!=null?
                <Box
                  sx={{
                      margin:'5px',
                      backgroundColor: '#8e8e8e',
                      padding: '2px 7px',
                      borderRadius: '18px',
                      color: '#fff',
                      width:'fit-content',
                      // display:'flex',
                      // justifyContent:'center',
                      // alignItems:'center',
                   

                  }}
                  className="backgroundTag"
                >
                    <span style={{
                      fontFamily:'Cairo',
                      textAlign:'center',
                      wordBreak:'break-all',
                      textAlign:'justify',
                      whiteSpace:'break-spaces'
                    }}>{itm?.master_unit_type_name}</span>
                    <span style={{
                      width:'fit-content',
                      height:'25px',
                      backgroundColor:'#b1b1b1',
                      borderRadius:'15px',
                      padding:'2px 5px',
                      textAlign:'center',
                      fontSize:'15px',
                      margin:'0 5px',
                    }}
                    className="backgroundTag"
                    >{getPercentageOfSoldUnitPerCompoundDyn(itm?.total_units_per_comound_per_master_type,itm?.total_units_sold_per_comound_per_master_type)+"%"}</span>
                </Box>:null
           )):null
           }
  

        </Box>
      </CardContent>
      {/* <CardActions>
      <Button size="small">Learn More</Button>
    </CardActions> */}
    </Card>
  );
}
