import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify';
import { Box, Typography, Autocomplete, TextField } from '@mui/material'

import compoundAPI from '../../../Global/Network/Compound';
import blockAPI from '../../../Global/Network/Block';
import buildingAPI from '../../../Global/Network/Building';
import floorAPI from '../../../Global/Network/Floor';



export default function FilterImage(props) {
  const [t] = useTranslation("common");
  const controller = new AbortController();
  const [loading, setLoading] = useState(true)

  const [compounds, setCompounds] = useState([])
  const [blocks, setBlocks] = useState([])
  const [buildings, setBuildings] = useState([])
  const [floors, setFloors] = useState()

  const [selectedCompound, setSelectedCompound] = useState(null)
  const [selectedBlock, setSelectedBlock] = useState(null)
  const [selectedBuilding, setSelectedBuilding] = useState(null)
  const [selectedFloor, setSelectedFloor] = useState(null)




  useEffect(() => {
    loadCompounds()

    return () => {

    }
  }, [])

  useEffect(() => {
    loadBlocks()

    return () => {

    }
  }, [selectedCompound])

  useEffect(() => {
    loadBuildings()

    return () => {

    }
  }, [selectedBlock])

  useEffect(() => {
    loadFloors()

    return () => {

    }
  }, [selectedBuilding])

  const loadCompounds = async () => {
    setLoading(true)

    try {
      const result = await compoundAPI.compoundsList({
        params: {
          has_image_polygon: 1
        },
        signal: controller?.signal
      })
      if (result.status) {
        let list = [];
        result?.data && result?.data?.length > 0 && result?.data?.map((item) => {
          list.push({
            label: item?.name,
            id: item?.id,
            images: item?.images
          })
        })
        setCompounds(list)
        if (list?.length > 0) {
          setSelectedCompound(list[0])
          props?.setSelectedEntity({
            ...props?.selectedEntity,
            sourceEntity: {
              type: 'compounds',
              entity: list[0],
              label: "المجمع"
            }

          })
          setSelectedImages(list[0])
          

        }
      }




    } catch (err) {
      console.log(err?.message)
      toast.error(err?.message)
    }
    setLoading(false)
  }
  const loadBlocks = async () => {
    if (!selectedCompound || !selectedCompound?.id) {
      setFloors([])
      setBuildings([])
      setBlocks([])
      return
    }
    setLoading(true)


    try {
      const result = await blockAPI.blocksList({
        params: {
          compound_id: selectedCompound?.id,
          has_image_polygon: 1
        },
        signal: controller?.signal
      })
      if (result.status) {
        let list = [];
        result?.data && result?.data?.length > 0 && result?.data?.map((item) => {
          list.push({
            label: item?.name,
            id: item?.id,
            images: item?.images
          })
        })
        setBlocks(list)
      }
    } catch (err) {
      console.log(err?.message)
      toast.error(err?.message)
    }
    setLoading(false)
  }
  const loadBuildings = async () => {
    if (!selectedBlock || !selectedBlock?.id) {
      setBuildings([])
      setFloors([])
      return
    }
    setLoading(true)
    try {
      const result = await buildingAPI.buildingsList({
        params: {
          block_id: selectedBlock?.id,
          has_image_polygon: 1
        },
        signal: controller?.signal
      })
      if (result.status) {
        let list = [];
        result?.data && result?.data?.length > 0 && result?.data?.map((item) => {
          list.push({
            label: item?.name,
            id: item?.id,
            images: item?.images
          })
        })
        setBuildings(list)
      }
    } catch (err) {
      console.log(err?.message)
      toast.error(err?.message)
    }
    setLoading(false)
  }
  const loadFloors = async () => {
    if (!selectedBuilding || !selectedBuilding?.id) {
      setFloors([])
      return
    }
    props?.setSelectedBuilding(selectedBuilding)
    setLoading(true)
    try {
      const result = await floorAPI.floorsList({
        params: {
          building_id: selectedBuilding?.id,
          has_image_polygon: 1
        },
        signal: controller?.signal
      })
      if (result.status) {
        let list = [];
        result?.data && result?.data?.length > 0 && result?.data?.map((item) => {
          list.push({
            label: item?.name,
            id: item?.id,
            images: item?.images
          })
        })
        setFloors(list)
      }
    } catch (err) {
      console.log(err?.message)
      toast.error(err?.message)
    }
    setLoading(false)
  }


  const setSelectedImages = (objectWithImages) => {
    // const imagePoly = objectWithImages?.images?.filter(image => image?.image_type_id == 4)
    // const imageFloorsPoly = objectWithImages?.images?.filter(image => image?.image_type_id == 5)
    // if (imagePoly?.length) {
    //   props?.setSelectedImage(objectWithImages?.images?.filter(image => image?.image_type_id == 4)[0])
    // }
    // if (imageFloorsPoly?.length) {
    //   props?.setSelectedFloorsImage(objectWithImages?.images?.filter(image => image?.image_type_id == 5)[0])
    // }
  }


  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      width: '100%',
      justifyContent: 'center',
      alignItems: 'start',
      overflowX: 'hidden',

    }}>

      <Box sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: 'column',
        width: '100% !important',
      }}>

        <Autocomplete
          sx={{
            ml: 1.5, mt: 1, mb: 1, width: '95%',
            '& .MuiFormLabel-root,& .MuiInputBase-input': {
              fontFamily: 'Cairo-Medium'
            }
          }}
          options={compounds ? compounds : []}

          value={selectedCompound}
          onChange={(e, v, r) => {
            if (v) {
              setSelectedCompound(v)
              setSelectedBlock(null)
              setSelectedBuilding(null)
              setSelectedFloor(null)
              props?.setSelectedEntity({
                ...props?.selectedEntity,
                sourceEntity: {
                  type: 'compounds',
                  entity: v,
                  label: "المجمع"
                }
              })
              setSelectedImages(v)

              
            }

          }}
          renderInput={(params) => (
            <TextField {...params}
              placeholder={"اختر المجمع السكني"}
              variant="standard" sx={{ fontFamily: 'Cairo-Medium' }} />
          )}
        />
        <Autocomplete
          sx={{
            ml: 1.5, mt: 1, mb: 1, width: '95%',
            '& .MuiFormLabel-root,& .MuiInputBase-input': {
              fontFamily: 'Cairo-Medium'
            }
          }}
          options={blocks ? blocks : []}

          value={selectedBlock}
          onChange={(e, v, r) => {
            if (!v) {
              setSelectedBlock(null)
              setSelectedBuilding(null)
              setSelectedFloor(null)

              props?.setSelectedEntity({
                ...props?.selectedEntity,
                sourceEntity: {
                  type: 'compounds',
                  entity: selectedCompound,
                  label: "المجمع"
                }
              })
              setSelectedImages(selectedCompound)
              
            }
            else {
              setSelectedBlock(v)
              setSelectedBuilding(null)
              setSelectedFloor(null)

              props?.setSelectedEntity({
                ...props?.selectedEntity,
                sourceEntity: {
                  type: 'blocks',
                  entity: v,
                  label: "القاطع"
                }
              })
              setSelectedImages(v)
            }


          }}
          renderInput={(params) => (
            <TextField {...params}
              placeholder={"اختر قاطع"}
              variant="standard" sx={{ fontFamily: 'Cairo-Medium' }} />
          )}
        />
        <Autocomplete
          sx={{
            ml: 1.5, mt: 1, mb: 1, width: '95%',
            '& .MuiFormLabel-root,& .MuiInputBase-input': {
              fontFamily: 'Cairo-Medium'
            }
          }}
          options={buildings ? buildings : []}

          value={selectedBuilding}
          onChange={(e, v, r) => {
            if (!v) {
              setSelectedBuilding()
              setSelectedFloor(null)
              props?.setSelectedEntity({
                ...props?.selectedEntity,
                sourceEntity: {
                  type: 'blocks',
                  entity: selectedBlock,
                  label: "القاطع"
                }
              })
              setSelectedImages(selectedBlock)
            }
            else {
              setSelectedBuilding(v)
              setSelectedFloor(null)

              props?.setSelectedEntity({
                ...props?.selectedEntity,
                sourceEntity: {
                  type: 'buildings',
                  entity: v,
                  label: "البناية"
                }
              })
              setSelectedImages(v)
            }

          }}
          renderInput={(params) => (
            <TextField {...params}
              placeholder={"اختر البناية"}
              variant="standard" sx={{ fontFamily: 'Cairo-Medium' }} />
          )}
        />
        <Autocomplete
          sx={{
            ml: 1.5, mt: 1, mb: 1, width: '95%',
            '& .MuiFormLabel-root,& .MuiInputBase-input': {
              fontFamily: 'Cairo-Medium'
            }
          }}
          options={floors ? floors : []}

          value={selectedFloor}
          onChange={(e, v, r) => {
            if (!v) {
              setSelectedFloor(null)
              props?.setSelectedEntity({
                ...props?.selectedEntity,
                sourceEntity: {
                  type: 'building',
                  entity: selectedBuilding,
                  label: "البناية"
                }
              })
              setSelectedImages(selectedBuilding)
            }
            else {
              setSelectedFloor(v)
              props?.setSelectedEntity({
                ...props?.selectedEntity,
                sourceEntity: {
                  type: 'floors',
                  entity: v,
                  label: "الطابق"
                }
              })
              setSelectedImages(v)
            }

          }}
          renderInput={(params) => (
            <TextField {...params}
              placeholder={"اختر الطابق"}
              variant="standard" sx={{ fontFamily: 'Cairo-Medium' }} />
          )}
        />

        <Typography
          sx={{ fontFamily: 'Cairo-Medium' }}
        >
          {"تم اختيار " + props?.selectedEntity?.sourceEntity?.label + ":" + props?.selectedEntity?.sourceEntity?.entity?.label}
        </Typography>

      </Box>


    </Box>
  )
}
