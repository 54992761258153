import callAPI from '../ApiConfig'
import store from '../../../app/store'

const customerContacts = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"customer_contacts",
        method:"get",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
    
}
const customerContactList = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"customer_contacts_list",
        method:"get",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
    
}
const addCustomerContact = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"customer_contacts",
        method:"post",
        signal:props?.signal,
        params:props?.params,
        data:props?.data,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
    
}
const updateCustomerContact = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"customer_contacts/"+props?.data?.id,
        method:"put",
        signal:props?.signal,
        params:props?.params,
        data:props?.data,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
    
}
const deleteCustomerContact = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"customer_contacts/"+props?.data?.id,
        method:"delete",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
    
}


const customerContact = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"customer_contact",
        method:"get",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
    
}

const customerContactAPI={
    customerContacts:customerContacts,
    customerContactList:customerContactList,
    addCustomerContact:addCustomerContact,
    updateCustomerContact:updateCustomerContact,
    deleteCustomerContact:deleteCustomerContact,
    customerContact:customerContact,
}
export default customerContactAPI