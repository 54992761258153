import { Box, TextField } from '@mui/material'
import React from 'react'
import { ContainerOfInputFields } from '../../Admin/RegisteredCustomers/ThemDesign'
import CloseIcon from '@mui/icons-material/Close';
import { useSelector } from 'react-redux'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers';

function CustomDateField({label,error,message,value,onChange,onClearClick,readOnly, type,haswidth,maxDate,minDate,customWidth}) {
    const maintheme=useSelector(state=>state.themeData.maintheme);
    return (
    <ContainerOfInputFields
        mainTheme={maintheme}
        customeWidth={customWidth?customWidth:"100%"}
        hasError={error}
        haswidth={true}
     >
        <Box
        sx={{
            position: "relative",
            // margin: "5px 0",
            width: "100%",
            backgroundColor: "#fff",
        }}
        className="orderdata"
        >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
            label={label}
            inputFormat="DD/MM/YYYY"
            value={value}
            // minDate={new Date()}
            inputProps={{
                placeholder:'لم يتم التحديد'
            }}
            minDate={minDate?minDate:null}
            maxDate={maxDate?maxDate:null}
            onChange={(date) => onChange && onChange(date)}
            renderInput={(params) => <TextField
                {...params} variant="filled"
                focused={value ? true : false}
                className={`${error ? 'errors' : ''}`}
                inputProps={{
                ...params.inputProps,
                placeholder:'لم يتم التحديد'
                }}
            />}
            />
            {value&&!readOnly ? (
            <CloseIcon
                className="closeIcon"
                onClick={() =>onClearClick && onClearClick()}
                sx={{
                right: '37px !important',
                top: '14px !important'
                }}
            />
            ) : null}
        </LocalizationProvider>

        </Box>
        {
                error && message?.length ?
                    <Box sx={{
                        height: 'fit-content',
                        padding: '5px',
                        display: 'flex',
                        flexDirection: 'column',
                        flexWrap: 'wrap',
                        width: '95%',
                    }}>
                        {
                            message && message?.length > 0 ? message?.map((messg) => (
                                <span style={{ fontFamily: 'Cairo-Bold', fontSize: '14px', color: 'red', height: 'auto' }}>{messg}</span>
                            )) : null
                        }
                    </Box> : null}
   </ContainerOfInputFields>
    )
}

export default CustomDateField