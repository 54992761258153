import  React,{useState,useEffect} from 'react';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import Drawer from '@mui/material/Drawer';
import { useTranslation } from "react-i18next";
import TextField from '@mui/material/TextField';
import CloseIcon from '@mui/icons-material/Close';

export default function CustomeInput({value,setValue,title,customeTop}) {
  const changedValue=(e)=>{
    setValue(e.target.value)
  }

  useEffect(()=>{
    console.log('value',value)
  },[value])
  return (
    <Box sx={{
        width:'100%',
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        "& .MuiInputLabel-root":{
            textAlign: "left", font: "normal normal  16px/30px Cairo-Bold", letterSpacing: "0px", color: "#22232B", opacity: "1"
           , padding:'0 0 0px 0px',position:'relative',top:'21px  !important'
        },
        position:'relative',
        top:customeTop?customeTop:'-35px',
        height:'65px',

    }}>
        <TextField
            id="outlined-basic" 
            label={title}
            variant="standard"
            value={value}
            onChange={changedValue}
            sx={{
                width:'80%',
                textAlign: "left", font: "normal normal  15px/29px Cairo", letterSpacing: "0px", color: "#22232B", opacity: "1"
            }}
        />
       {value?
           <Box sx={{
            width:'30px',
            height:'30px',
            paddintTop:10,
            backgroundColor:'#fff',
            zIndex:999999,position:'absolute',top:'39px',right:'25px',
            display:'flex',
            justifyContent:'center',
            alignItems: 'center',
           }}>
           <CloseIcon sx={{fontSize:'20px',backgroundColor:'#fff',cursor:'pointer',color:'grey',fill:'grey'}}  onClick={()=>setValue('')} />
           </Box>
        :null}
    </Box>
  )
}
