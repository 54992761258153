import callAPI from '../ApiConfig'
import store from '../../../app/store'

const imageTypes = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"image_types",
        method:"get",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
    
}
const imageTypeList = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"image_types_list",
        method:"get",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
    
}
const addImageType = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"image_types",
        method:"post",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        },
        data:props?.data,
    })
    
}
const updateImageType = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"image_types/"+props?.data?.id,
        method:"put",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        },
        data:props?.data
    })
    
}
const deleteImageType = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"image_types/"+props?.data?.id,
        method:"delete",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        },
    })
    
}
const ImageTypeAPI={
    ImageTypes:imageTypes,
    ImageTypeList:imageTypeList,
    AddImageType:addImageType,
    UpdateImageType:updateImageType,
    DeleteImageType:deleteImageType
}
export default ImageTypeAPI