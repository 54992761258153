import { FormControl, FormHelperText, InputAdornment, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'

const CustomSelect = (props) => {
    const renderMenuItems = () => {

        if (props?.items?.length) {
            return props.items.map((item, index) => {
              
                return <MenuItem key={index + "_" + item?.id} value={item}>{item?.name}</MenuItem>
            })
        }
    }

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            ...props?.style,
            ...props?.sx
        }} >
            <Box sx={{
                display: 'flex',
                flex: 9,
                // ...props?.padding
            }}>


                <FormControl variant='outlined' sx={{
                    width: '100%',
                    // ...props?.padding
                }}>
                    <InputLabel sx={{

                        color:  "#707070a3",
                        // backgroundColor:settingRedux?.colors?.white,


                        ...(props.value && {
                            paddingTop: '10px',
                            //    ...props?.padding

                        }),
                        "&:focus": {
                            color: "#000000",
                            // backgroundColor:settingRedux?.colors?.white,
                            paddingTop: '10px',
                            // ...props?.padding
                        },
                        "&.Mui-focused": {
                            color: "#000000",
                            // backgroundColor:settingRedux?.colors?.white,
                            paddingTop: '10px',
                            // ...props?.padding
                        },
                    }} htmlFor="grouped-native-select">{props.placeholder}</InputLabel>
                    <Select
                        id="grouped-native-select" label="Grouping"
                        required={props.required}
                        error={props.error}
                        // helperText={props.helperText}
                        multiple={props?.multiple}
                        disabled={props?.readOnly}
                        displayEmpty
                        value={props.value}

                        onChange={(event) => { props.onChange(event.target.value) }}
                        renderValue={(value) => {
                            if(props?.multiple)
                            {
                                if (props?.value?.length > 0 ) {
                                    let values = props?.value?.map(v=>v?.name)
                                    if(values?.length>6)
                                    {
                                        return `${values.slice(0, 6)}...`
                                    }
                                    else{
                                        return `${values}`
                                    }
                                    
                                }

                                else {
                                    //placeholder
                                    return <Typography sx={{ color: props.color ? props.color + "a0" :  "#707070a0" }}>{props.placeholderValue}</Typography>
                                }
                            }
                            else{
                                if (props?.value?.id != "") {
                                
                                    return `${props?.value?.name}`
                                }
                                else {
                                    //placeholder
                                    return <Typography sx={{ color: props.color ? props.color + "a0" :  "#707070a0" }}>{props.placeholderValue}</Typography>
                                }
                            }
                            
                        }}

                       
                        sx={{
                            // ...props?.padding,
                            "& .MuiOutlinedInput-notchedOutline": {
                                border: "0 none",
                                
                                // ...props?.padding
                            },
                            "& .Mui-disabled":{
                                WebkitTextFillColor: 'rgba(0, 0, 0) !important',
                                // "-webkit-text-fill-color": 'rgba(0, 0, 0) !important'
                            },

                            "& .MuiOutlinedInput-input":{
                                ...props?.selectSX
                            },

                            

                            backgroundColor: "#FFFFFF",
                            borderRadius: '0px',
                            
                        }}
                        

                        inputProps={{
                            style: {
                                // ...props?.padding
                                
                            }

                        }}
                        

                    >
                        <MenuItem value={{ id: "", name: "" }}>
                            <em>{props.placeholderValue}</em>
                        </MenuItem>
                        {renderMenuItems()}
                    </Select>

                    <FormHelperText sx={{ color: "#FF0000" }}>{props.helperText}</FormHelperText>
                </FormControl>
            </Box>
            


        </Box>


    )
}

export default CustomSelect