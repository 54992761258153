import { Autocomplete, Box, Button, Checkbox, Divider, FormControlLabel, IconButton, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ContainerOfInputFields, ContainerOfSelectField } from '../../../RegisteredCustomers/ThemDesign'
import { useSelector } from 'react-redux'
import entityError, { resetEntityObject } from '../../../../Global/Errors/Entity/Entity'

import { toast } from 'react-toastify'
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import CameraswitchIcon from '@mui/icons-material/Cameraswitch';
import DeleteIcon from '@mui/icons-material/Delete';
import * as FaIcons from 'react-icons/fa'
import CloseIcon from '@mui/icons-material/Close';
import entityAPI from '../../../../Global/Network/Entity'
import * as RiIcons from 'react-icons/ri'
import ImageTypeAPI from '../../../../Global/Network/ImageType'
import Addresses from '../../../Components/Addresses'
import AddressesTable from './AddressesTable'
import AddIcon from '@mui/icons-material/Add';
import Images from '../../../Components/Images'
import CustomTextField from '../../../../General/CustomComponents/CustomTextField'

const RenderContent = (props) => {
    const [t] = useTranslation('common')
    const mainTheme = useSelector(state => state.themeData.maintheme)
    const [selectedImage, setSelectedImage] = useState('')
    const controller = new AbortController();
    const [loading, setLoading] = useState(false)
    const [isUpdateImage, setIsUpdatedImage] = useState(false)
    const screenwidth = useSelector(state => state.settingsData.screenwidth);
    const maintheme = useSelector(state => state.themeData.maintheme);
    const [entityErrorsObject, setEntityErrorsObject] = useState(entityError)
    const [selectedAddress, setSelectedAddress] = useState(null)
    const [floorNumber, setFloorNumber] = useState("")
    const handleChange = (e) => {
        props.setName(e.target.value)
        setEntityErrorsObject(resetEntityObject(entityErrorsObject, 'name'))
    }
    const handleDescriptionChange = (e) => {
        props.setDescription(e.target.value)
        setEntityErrorsObject(resetEntityObject(entityErrorsObject, 'description'))
    }
    const handleChangeFloorNumber = (value) => {
        props.setFloorNumber(value)
        setEntityErrorsObject(resetEntityObject(entityErrorsObject, 'floor_number'))
    }
    const clearInput = () => {
        props.setName('')
        setEntityErrorsObject(resetEntityObject(entityErrorsObject, 'name'))
    }
    const clearDescriptionInput = () => {
        props.setDescription('')
        setEntityErrorsObject(resetEntityObject(entityErrorsObject, 'description'))
    }


    const checkIsNotCompound = () => {
        return props?.type?.includes('compound');

    }
    const handleUpdateAddress = (obj) => {
        setSelectedAddress(obj)
        props?.setAddAddresses(true)
        props?.setSelectedAddress(obj)
    }


    return (
        <Box className='Container-fluid'>
            <Box className="row">
                <Box className={`col-12 col-sm-12 col-md-6 col-lg-5 `}>
                    <ContainerOfInputFields
                        mainTheme={maintheme}
                        customeWidth={"100%"}
                        isForm={true}
                        haswidth={true}
                    >
                        {props?.type?.includes("floors") ?
                            <CustomTextField
                                customeWidth={'100%'}
                                customePadding={'2px 0px 2px 0px'}
                                paddingHorizontal={'0px'}
                                haswidth={true}
                                label={t(props?.type?.toUpperCase() + ".floor_number")}
                                value={props?.floorNumber}
                                error={entityErrorsObject?.floor_number?.message?.length}
                                message={entityErrorsObject?.floor_number?.message}
                                readOnly={false}
                                onChange={(e) => {
                                    if (!isNaN(e.target.value)) {
                                        let val = e.target.value?.replace('.', '')
                                        handleChangeFloorNumber(val)
                                        
                                    }
                                }}
                                onClearClick={() => {
                                    props?.setFloorNumber("")
                                }}
                            />
                            : null}
                        <Box
                            sx={{
                                position: "relative",
                                // margin: "5px 0",
                                width: "100%",
                                backgroundColor: "#fff",
                            }}
                            className="mt-3 orderdata"
                        >
                            <TextField
                                id="standard-basic"
                                variant="filled"
                                label={t(props?.type?.toUpperCase() + ".add_name")}
                                value={props?.name}
                                onChange={(e) => handleChange(e)}
                                inputProps={{
                                    readOnly: props?.isViewMode
                                }}
                                type={'text'}
                                focused
                                className={`${entityErrorsObject?.name?.error ? 'errors' : ''}`}
                            />
                            {props?.name && !props?.isViewMode ? (
                                <CloseIcon
                                    className="closeIcon textAreaIcons"
                                    onClick={() => clearInput()}
                                />
                            ) : null}
                        </Box>
                        {
                            entityErrorsObject?.name?.error && entityErrorsObject?.name?.message?.length ?
                                <Box sx={{
                                    height: 'fit-content',
                                    padding: '5px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    flexWrap: 'wrap',
                                    width: '95%',
                                }}>
                                    {
                                        entityErrorsObject?.name?.message && entityErrorsObject?.name?.message?.length > 0 ? entityErrorsObject?.name?.message?.map((messg) => (
                                            <span style={{ fontFamily: 'Cairo-Bold', fontSize: '14px', color: 'red', height: 'auto' }}>{messg}</span>
                                        )) : null
                                    }
                                </Box> : null}
                    </ContainerOfInputFields>
                    <ContainerOfInputFields
                        hasMultiLine={true}
                        mainTheme={maintheme}
                        customeWidth={"100%"}
                        isForm={true}
                        haswidth={true}

                    >
                        <Box
                            sx={{
                                position: "relative",
                                // margin: "5px 0",
                                width: "100%",
                                backgroundColor: "#fff",
                            }}
                            className="mt-3 orderdata"
                        >
                            <TextField
                                id="standard-basic"
                                variant="filled"
                                label={t(props?.type?.toUpperCase() + ".description")}
                                value={props?.description}
                                onChange={(e) => {
                                    handleDescriptionChange(e)
                                }}
                                type={'text'}
                                inputProps={{
                                    readOnly: props?.isViewMode
                                }}
                                focused
                                multiline={true}
                                className={`${entityErrorsObject?.description?.error ? 'errors' : ''}`}
                            />
                            {props?.description && !props?.isViewMode ? (
                                <CloseIcon
                                    className="closeIcon textAreaIcons"
                                    onClick={() => clearDescriptionInput()}
                                />
                            ) : null}
                        </Box>
                        {
                            entityErrorsObject?.description?.error && entityErrorsObject?.description?.message?.length ?
                                <Box sx={{
                                    height: 'fit-content',
                                    padding: '5px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    flexWrap: 'wrap',
                                    width: '95%',
                                }}>
                                    {
                                        entityErrorsObject?.description?.message && entityErrorsObject?.description?.message?.length > 0 ? entityErrorsObject?.name?.message?.map((messg) => (
                                            <span style={{ fontFamily: 'Cairo-Bold', fontSize: '14px', color: 'red', height: 'auto' }}>{messg}</span>
                                        )) : null
                                    }
                                </Box> : null}
                    </ContainerOfInputFields>
                    <ContainerOfSelectField
                        mainTheme={mainTheme}
                        customeWidth={"100%"}
                        hasError={entityError?.compound_phase_id?.error}
                        haswidth={true}
                    >
                        <Box
                            sx={{
                                position: "relative",
                                // margin: "10px 0",
                                width: "100%",
                                backgroundColor: "#fff",
                            }}
                            className="mt-3"
                        >
                            <Autocomplete
                                //    disablePortal
                                id="combo-box-demo"
                                options={props?.phases ? props?.phases : []}
                                value={props?.selectedPhase}
                                getOptionLabel={(option) => option?.phase?.name || ''}
                                onChange={(e, newValue) => {
                                    props?.setSelectedPhase(newValue)
                                    setEntityErrorsObject(resetEntityObject(entityErrorsObject, 'compound_phase_id'))
                                }}
                                focused
                                readOnly={props?.isViewMode}
                                popupIcon={<RiIcons.RiArrowDropDownLine />}
                                clearIcon={<CloseIcon sx={{ fontSize: '20px', color: mainTheme?.iconColor }} onClick={() => {
                                    props?.setSelectedPhase(null)
                                    setEntityErrorsObject(resetEntityObject(entityErrorsObject, 'compound_phase_id'))
                                }} />}
                                renderInput={(params) => <TextField
                                    {...params}
                                    focused
                                    label={t(props?.type?.toUpperCase() + ".phase")} variant="filled" />}
                                className={`${entityError?.compound_phase_id?.error} Mui-focused`}
                            />
                        </Box>
                        {
                            entityError?.compound_phase_id?.error && entityError?.compound_phase_id?.message?.length ?
                                <Box sx={{
                                    height: 'fit-content',
                                    padding: '5px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    flexWrap: 'wrap',
                                    width: '95%',
                                }}>
                                    {
                                        entityError?.compound_phase_id?.message && entityError?.compound_phase_id?.message?.length > 0 ? entityError?.compound_phase_id?.message?.map((messg) => (
                                            <span style={{ fontFamily: 'Cairo-Bold', fontSize: '14px', color: 'red', height: 'auto' }}>{messg}</span>
                                        )) : null
                                    }
                                </Box> : null}
                    </ContainerOfSelectField>
                    {
                        !checkIsNotCompound() ?
                            <Box
                                sx={{
                                    "& .MuiTypography-root": {
                                        fontFamily: 'Cairo-Medium'
                                    },
                                    backgroundColor: '#fff',
                                    boxShadow: '0 0 10px -2px rgba(0,0,0,0.3)',
                                    height: '55px',
                                    marginTop: '18px',
                                }}
                                className=" pt-2 px-3 mx-2 rounded"
                            >

                                <FormControlLabel control={<Checkbox
                                    checked={props?.skip}
                                    onChange={(e) => {
                                        props?.setSkip(e.target.checked)
                                    }}

                                    disabled={props?.isViewMode}
                                />} sx={{ fontFamily: 'Cairo-Medium', width: '100%' }} label={t(props?.type?.toUpperCase() + ".skip")} />


                            </Box>
                            : null}

                </Box>

                <Box className="col-12 col-md-12 col-lg-7 col-xlg-7 my-3">
                    {
                        props?.userRolesHere[4][0]?.view ?
                            <Images
                                imageList={props?.imageList}
                                setImageList={props.setImageList}
                                isUpdateImage={isUpdateImage}
                                setIsUpdatedImage={setIsUpdatedImage}
                                editMode={props?.editMode}
                                loading={loading}
                                setLoading={setLoading}
                                type={props?.type}
                                type_id={props?.type_id}
                                userRolesHere={props?.userRolesHere}
                                isViewMode={props?.isViewMode}
                            /> : null
                    }
                </Box>
                {
                    props?.userRolesHere[5][0]?.view && (props?.type === 'compounds' && props?.editMode || props?.type === 'compounds' && !props?.editMode) ?
                        <Box className='col-12 col-sm-12 col-md-12 col-lg-12 mt-5' sx={{ paddingRight: '30px' }}>

                            {/* <Divider sx={{
                        color:'lightgrey',
                        marginBottom:'10px',
                        marginLeft:'10px',
                    }}/> */}
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'start',
                                alignItems: 'center',
                            }}>
                                {
                                    props?.userRolesHere[5][1]?.add && !props?.isViewMode ?
                                        <IconButton
                                            onClick={() => {
                                                props.setAddAddresses(!props?.addAddresses)
                                                props?.setSelectedAddress(null)
                                                setSelectedAddress(null)
                                            }}
                                        >
                                            <AddIcon sx={{ color: "#1E6A99", fontSize: '30px', margin: '0 10px' }} />
                                        </IconButton> : null
                                }
                                <span style={{ color: '#000', fontFamily: 'Cairo-Bold', fontSize: '19px', marginRight: '10px' }}>{t('ADDRESSES.addNewAddressTitle')}</span>


                            </Box>
                            <AddressesTable
                                editMode={props?.editMode}
                                listOfAddresses={props?.listOfAddresses} setListOfAddresses={props.setListOfAddresses}
                                handleUpdateAddress={handleUpdateAddress}
                                userRolesHere={props?.userRolesHere}
                                type={props?.type}
                                isViewMode={props?.isViewMode}

                            />
                        </Box> : null
                }
                {
                    props?.userRolesHere[5][0]?.view && props?.userRolesHere[5][1]?.add ?
                        <Addresses
                            editMode={props?.editMode}
                            addForm={props?.addAddress}
                            setAddForm={props.setAddAddresses}
                            listOfAddresses={props?.listOfAddresses}
                            setListOfAddresses={props.setListOfAddresses}
                            handleGetNewAddress={props.handleGetNewAddress}
                            object={selectedAddress}
                            setObject={setSelectedAddress}
                            userRolesHere={props?.userRolesHere}
                        /> : null
                }

            </Box>
        </Box>
    )
}

export default RenderContent;