import React, { useState, useEffect, useMemo } from 'react'
import Grid from '../../Grid/Grid'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux';
import MaritalStatusAPI from '../../../Global/Network/MaritalStatus/index'
import { toast } from 'react-toastify';
import DropDownGrid from '../../Grid/Components/DropDownGrid'
import { Box, MenuItem, ListItemIcon, Typography, Divider, Autocomplete, TextField } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ViewWeekIcon from '@mui/icons-material/ViewWeek';
import AddFormDailog from './AddFormDailog';
import swal from 'sweetalert';
import SearchInput from '../../Components/SearchInput';
import SearchDate from '../../Components/SearchDate';
import EmployeeAPI from '../../../Global/Network/Employee/index'
import { from } from 'stylis';
import helpers from '../../../assets/js/helper';
import { useGlobalContext } from '../../../context'
import * as MdIcons from 'react-icons/md'
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import * as FaIcons from 'react-icons/fa'
import customerAPI from '../../../Global/Network/Customer';
import RolesOfUserAPI from '../../../Global/Network/RoleUser/index';
// import RoomAPI from '../../../Global/Network/UnitType';
import RoomAPI from '../../../Global/Network/Rooms';
import UnitTypesAPI from '../../../Global/Network/UnitType';
import RoomTypeAPI from '../../../Global/Network/RoomType';

const RenderSearchContent = (props) => {
  const [t] = useTranslation('common')
  console.log('user', props?.users)
  return (
    <Box sx={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: 'column',
      width: '100% !important',
    }}>
      <SearchInput
        value={props?.width}
        setValue={props?.setWidth}
        title={t('ROOMS.width')}
      />
      <SearchInput
        value={props?.length}
        setValue={props?.setLength}
        title={t('ROOMS.length')}
      />
      <SearchDate
        value={props?.fromDate}
        setValue={props?.setFromDate}
        title={t('ROOMS.work_fromDate_search')}
        minDate={null}
        maxDate={props?.toDate}
      />
      <SearchDate
        value={props?.toDate}
        setValue={props?.setToDate}
        title={t('ROOMS.work_toDate_search')}
        minDate={props?.fromDate}
        maxDate={null}
      />
      <Autocomplete
        sx={{
          ml: 1.5, mt: 1, mb: 1, width: '95%',
          '& .MuiFormLabel-root,& .MuiInputBase-input': {
            fontFamily: 'Cairo-Medium'
          }
        }}
        options={props.unitTypes ? props.unitTypes : []}
        getOptionLabel={(option) => option && option?.name || ""}
        id="disable-close-on-select"
        value={props?.selectedUnitType}
        onChange={(e, v, r) => { props.setSelectedUnitType(v ? v : ''); }}
        renderInput={(params) => (
          <TextField {...params}
            placeholder={t('ROOMS.unitType')}
            variant="standard" sx={{ fontFamily: 'Cairo-Medium' }} />
        )}
      />
      <Autocomplete
        sx={{
          ml: 1.5, mt: 1, mb: 1, width: '95%',
          '& .MuiFormLabel-root,& .MuiInputBase-input': {
            fontFamily: 'Cairo-Medium'
          }
        }}
        options={props.roomTypes ? props.roomTypes : []}
        getOptionLabel={(option) => option && option?.name || ""}
        id="disable-close-on-select"
        value={props?.selecteRoomType}
        onChange={(e, v, r) => { props.setSelecteRoomType(v ? v : ''); }}
        renderInput={(params) => (
          <TextField {...params}
            placeholder={t('ROOMS.roomType')}
            variant="standard" sx={{ fontFamily: 'Cairo-Medium' }} />
        )}
      />
      <Autocomplete
        sx={{
          ml: 1.5, mt: 1, mb: 1, width: '95%',
          '& .MuiFormLabel-root,& .MuiInputBase-input': {
            fontFamily: 'Cairo-Medium'
          }
        }}
        options={props.users ? props.users : []}
        getOptionLabel={(option) => option?.user && option?.user?.full_name || ""}
        id="disable-close-on-select"
        value={props?.createdBy}
        onChange={(e, v, r) => { props.setCreatedBy(v ? v : ''); }}
        renderInput={(params) => (
          <TextField {...params}
            placeholder={t('WORKTYPE.SEARCH_USERS')}
            variant="standard" sx={{ fontFamily: 'Cairo-Medium' }} />
        )}
      />

    </Box>
  )
}

export default function Room() {
  const [t] = useTranslation("common")
  const [rows, setRows] = useState([])
  const [pageSize, setPageSize] = useState(5);
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(false)
  const [pageCount, setPageCount] = useState(0)
  const [openFilterColumn, setOpenFilterColumn] = useState(false)
  const [openFilterColumnValue, setOpenFilterColumnValue] = useState([])
  const [elementClicked, setElementClicked] = useState(null)
  const [addForm, setAddForm] = useState(false)
  const [selectedObject, setselectedObject] = useState(null)
  const [fromDate, setFromDate] = useState(null)
  const [toDate, setToDate] = useState(null)
  const [objectName, setobjectName] = useState(null)
  const [createdBy, setCreatedBy] = useState(null)
  const [users, setUsers] = useState([])
  const { exportToCSV } = useGlobalContext()
  const [width, setWidth] = useState(null)
  const [length, setLength] = useState(null)
  const [unitTypes, setUnitTypes] = useState([])
  const [selectedUnitType, setSelectedUnitType] = useState(null)
  const [roomTypes, setRoomTypes] = useState([])
  const [selecteRoomType, setSelecteRoomType] = useState(null)
  const [filterParams,setFilterParams]=useState({})
  const [userRolesHere, setUserRolesHere] = useState([
    [
      {
        view: true,
        id: 154
      },
      {
        add: false,
        id: 155,
      },
      {
        update: false,
        id: 156,
      },
      {
        delete: false,
        id: 157,
      }, {
        export: false,
        id: 158
      }
    ],
    [],
    [],
    [],
    [//image here...
    ]]
  )

  const handlePageChange = (newPage) => {
    setPageNumber(newPage + 1);
  };
  const controller = new AbortController();

  const [rowsTotal, setRowsTotal] = useState(0)
  const screenwidth = useSelector(state => state.settingsData.screenwidth)
  const columns = useMemo(() => [
    { headerName: t('ROOMS.id'), field: 'id', flex: 0.5, minWidth: 50, maxWidth: 150, hide: false },
    // {headerName:t('ROOMS.name'),field:'name',flex:2,minWidth:100,maxWidth:250,hide:false},

    {
      headerName: t('ROOMS.unitType'), field: 'unitType', flex: 2, minWidth: 100, maxWidth: 250, hide: false,
      renderCell: (rows) => (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <span style={{ fontFamily: 'Cairo-Medium' }} className="gridHeaderItem">{rows?.row?.unit_type?.name}</span>
        </Box>
      )
    },
    {
      headerName: t('ROOMS.roomType'), field: 'roomType', flex: 2, minWidth: 100, maxWidth: 250, hide: false,
      renderCell: (rows) => (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <span style={{ fontFamily: 'Cairo-Medium' }} className="gridHeaderItem">{rows?.row?.room_type?.name}</span>
        </Box>
      )
    },
    { headerName: t('ROOMS.width'), field: 'width', flex: 2, minWidth: 100, maxWidth: 250, hide: false },
    { headerName: t('ROOMS.length'), field: 'length', flex: 2, minWidth: 100, maxWidth: 250, hide: false },

    {
      headerName: t('ROOMS.created_at'), field: 'created_at', flex: 2, minWidth: 150, maxWidth: 450, hide: false,
      renderCell: (rows) => (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <span style={{ fontFamily: 'Cairo-Medium' }} className="gridHeaderItem">{rows?.row?.created_at ? helpers.formatDate(rows?.row?.created_at) : '0000-00-00'}</span>
          <span style={{ fontFamily: 'Cairo-Medium' }} className="gridHeaderItem">{rows?.row?.created_at ? helpers.formatTime(rows?.row?.created_at) : '00:00:00'}</span>

        </Box>
      )
    },
    {
      headerName: t('ROOMS.actions'), field: 'Actions', flex: 0.4, minWidth: 100, maxWidth: 250, hide: false,
      renderCell: (rows) => (
        <DropDownGrid className="containerOFDropDownToolTipeGrid">
          {
            userRolesHere[0][2]?.update ?
              <MenuItem onClick={() => { updateFunction(rows?.row) }} className="ActionIcons">
                <ListItemIcon sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  margin: '0px 7px 0 0'
                }}>
                  <EditIcon style={{ fill: "#1E6A99" }} />

                </ListItemIcon>
                {t('GLOBAL.action_edit')}
              </MenuItem> : null
          }
          {
            userRolesHere[0][3]?.delete ?
              <MenuItem onClick={() => { deleteFunction(rows?.row) }} className="ActionIcons">
                <ListItemIcon sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  margin: '0px 7px 0 0'
                }}>
                  <DeleteIcon style={{ fill: "#f00" }} />
                </ListItemIcon>
                {t('GLOBAL.action_delete')}

              </MenuItem> : null
          }
          {
            userRolesHere[0][2]?.update == false && userRolesHere[0][3]?.delete == false ?
              <p style={{ fontFamily: 'Cairo', margin: '5px 20px' }}>{t('GLOBAL.emptyPoPup')}</p>
              : null
          }

        </DropDownGrid>
      ),
    },
  ], [userRolesHere])
  useEffect(() => {
    let abortController = new AbortController();
    loadData()
    return () => {
      abortController.abort();
    }
  }, [pageNumber, pageSize])

  useEffect(() => {
    let abortController = new AbortController();
    console.log('saldkjaskd', 1);
    getUserRoles()
    loadDataOfUsers()
    loadDataOfRoomType()
    loadDataOfUnitTypes()
    return () => {
      abortController.abort();
    }
  }, [])

  const loadData = async (searchParams) => {
    setLoading(true)
    const filterParamsData={
      ...filterParams,
      ...searchParams
     }
    const result = await RoomAPI.Rooms({
      params: {
        page_size: pageSize,
        page: pageNumber,
        ...filterParamsData
      },
      signal: controller?.signal
    })

    setLoading(false)
    if (result.status) {
      setRows(result?.data?.data)
      setRowsTotal(result?.data?.total)
      setPageCount(result?.data?.last_page)
    }
    else {
      if (!result?.data?.error) {
        toast.error(t('NETWORKMESSAGE.messageError'))
        return
      }
    }

  }
  const loadDataOfUsers = async (searchParams) => {
    setLoading(true)

    const result = await EmployeeAPI.EmployeeList({
      signal: controller?.signal
    })

    setLoading(false)
    if (result.status) {
      setUsers(result?.data)
    }
    else {
      if (!result?.data?.error) {
        toast.error(t('NETWORKMESSAGE.messageError'))
        return
      }
    }

  }
  const createFUnction = () => {
    setselectedObject(null)
    setAddForm(true)
  }
  const updateFunction = (obj) => {
    setselectedObject(obj)
    setAddForm(true)
  }
  const deleteFunction = async (obj) => {
    swal({
      title: '?هل انت متأكد',
      text: "لن تتمكن من التراجع عن هذا!",
      icon: 'warning',
      buttons: ["الغاء!", "نعم!"],
      dangerMode: true,
    }).then(async (willDelete) => {
      console.log('1')
      if (willDelete) {
        console.log('2')

        try {
          setLoading(true)

          let result = null;
          if (obj && obj?.id) {
            console.log('3')

            result = await RoomAPI.deleteRoom({
              data: {
                id: obj.id,
              }
            });
          }

          if (result?.status) {
            setLoading(false)
            // toast.success(t('NETWORKMESSAGE.deleteSuccess'))
            swal(`${t('NETWORKMESSAGE.deleteSuccess')}`, {
              icon: "success",
            });
            loadData({
              page: pageNumber
            })
          } else {
            setLoading(false)
            if(result?.errorMessage){
              toast.error(result?.errorMessage)
            }else
            toast.error(t('NETWORKMESSAGE.deleteErrorr'))
          }
          setLoading(false)
        } catch (error) {
          setLoading(false)
          toast.error(t(error?.message))
        }
      } else {
        // alert('canceled')
      }
    })
  }
  const clearFunction = () => {
    setobjectName('')
    setFromDate(null)
    setToDate(null)
    setCreatedBy(null)
    setSelecteRoomType(null)
    setSelectedUnitType(null)
    setWidth(null)
    setLength(null)
    setFilterParams({})

  }
  const searchFunction = () => {
    let data = {};
    if (width) {
      data = {
        ...data,
        width: width
      }
    }
    if (length) {
      data = {
        ...data,
        length: length
      }
    }
    if (selectedUnitType) {
      data = {
        ...data,
        unit_type_id: selectedUnitType?.id
      }
    }
    if (selecteRoomType) {
      data = {
        ...data,
        room_type_id: selecteRoomType?.id
      }
    }
    if (fromDate) {
      data = {
        ...data,
        creation_date_from: helpers.formatDate(fromDate)
      }
    }
    if (toDate) {
      data = {
        ...data,
        creation_date_to: helpers.formatDate(toDate)
      }
    }
    if (createdBy && createdBy?.user && createdBy.user?.id) {
      data = {
        ...data,
        created_by_id: createdBy.user?.id
      }
    }
    setFilterParams(data)

    loadData(data)
  }
  const loadDataForExport = async (searchParams) => {
    let result = await RoomAPI.Rooms({
      params: {
        page_size: rowsTotal,
        ...searchParams
      },
      signal: controller?.signal
    })
    return result;
  }
  const ExportFunction = async () => {
    let filteredData = {}

    if (width) {
      filteredData = {
        ...filteredData,
        width: width
      }
    }
    if (length) {
      filteredData = {
        ...filteredData,
        length: length
      }
    }
    if (selectedUnitType) {
      filteredData = {
        ...filteredData,
        unit_type_id: selectedUnitType?.id
      }
    }
    if (selecteRoomType) {
      filteredData = {
        ...filteredData,
        room_type_id: selecteRoomType?.id
      }
    }
    if (fromDate) {
      filteredData = {
        ...filteredData,
        creation_date_from: helpers.formatDate(fromDate)
      }
    }
    if (toDate) {
      filteredData = {
        ...filteredData,
        creation_date_to: helpers.formatDate(toDate)
      }
    }
    if (createdBy && createdBy?.id) {
      filteredData = {
        ...filteredData,
        created_by_id: createdBy?.id
      }
    }

    const fetchedData = await loadDataForExport(filteredData);
    if (!fetchedData || !fetchedData?.status) {
      toast.error("لا يمكن استرجاع البيانات")
      return
    }

    let arr = [];
    fetchedData?.data?.data && fetchedData?.data?.data?.map((itm) => {
      arr.push({
        [`${t('ROOMS.id')}`]: itm?.id,
        [`${t('ROOMS.name')}`]: itm?.name,
        [`${t('ROOMS.width')}`]: itm?.width,
        [`${t('ROOMS.length')}`]: itm?.length,
        [`${t('ROOMS.unitType')}`]: itm?.unit_type?.name,
        [`${t('ROOMS.roomType')}`]: itm?.room_type?.name,
        [`${t('ROOMS.created_at')}`]: itm?.created_at ? helpers.formatDate(itm?.created_at) + " " + helpers.formatTime(itm?.created_at) : '0000-00-00 00:00:00',
      })
    })
    exportToCSV(arr, 'CUSTOMERTYPE')
  }
  const getUserRoles = async () => {
    // setLoading(true)
    console.log('saldkjaskd', 2);
    try {
      let data = await RolesOfUserAPI.GetRolesOfUser();
      if (data && data?.status) {
        console.log('userRolesmmm', data?.data);
        let mainArr = []
        let arr = [];
        for (let i = 0; i < 5; i++) {
          let checkHasRole = data?.data?.includes(userRolesHere[0][0]?.id)
          if (checkHasRole) {
            arr.push(
              {
                view: true,
                id: userRolesHere[0][0]?.id,
              }
            )
          } else {
            arr.push(
              {
                view: false,
                id: userRolesHere[0][0]?.id,
              }
            )
          }
          console.log('sassssss', userRolesHere[0][1]?.id);
          checkHasRole = data?.data?.includes(userRolesHere[0][1]?.id)
          if (checkHasRole) {
            arr.push(
              {
                add: true,
                id: userRolesHere[0][1]?.id,
              }
            )
          } else {
            arr.push(
              {
                add: false,
                id: userRolesHere[0][1]?.id,
              }
            )
          }
          checkHasRole = data?.data?.includes(userRolesHere[0][2]?.id)
          if (checkHasRole) {
            arr.push(
              {
                update: true,
                id: userRolesHere[0][2]?.id,
              }
            )
          } else {
            arr.push(
              {
                update: false,
                id: userRolesHere[0][2]?.id,
              }
            )
          }
          checkHasRole = data?.data?.includes(userRolesHere[0][3]?.id)
          if (checkHasRole) {
            arr.push(
              {
                delete: true,
                id: userRolesHere[0][3]?.id,
              }
            )
          } else {
            arr.push(
              {
                delete: false,
                id: userRolesHere[0][3]?.id,
              }
            )
          }
          checkHasRole = data?.data?.includes(userRolesHere[0][4]?.id)
          if (checkHasRole) {
            arr.push(
              {
                export: true,
                id: userRolesHere[0][4]?.id,
              }
            )
          } else {
            arr.push(
              {
                export: false,
                id: userRolesHere[0][4]?.id,
              }
            )
          }
          mainArr.push(arr)
          mainArr.push([])//index 1
          mainArr.push([])//index 2
          mainArr.push([])//index 3
          mainArr.push([])

          setUserRolesHere(mainArr)
        }
      }
      setLoading(false)
    } catch (err) {
      setLoading(false)
      console.log(err?.message);

    }
  }

  const loadDataOfUnitTypes = async () => {
    let data = null;
    try {
      data = await UnitTypesAPI.unitTypesList();
      if (data && data?.status) {
        console.log('sdasdasdasdsa', data?.data);
        setUnitTypes(data?.data)
      } else {
        toast.error(t('NETWORKMESSAGE.messageError'))
      }
    } catch (err) {
      console.log(err?.message);
    }
  }
  const loadDataOfRoomType = async () => {
    let data = null;
    try {
      data = await RoomTypeAPI.RoomTypeList();
      if (data && data?.status) {
        setRoomTypes(data?.data)
      } else {
        toast.error(t('NETWORKMESSAGE.messageError'))
      }
    } catch (err) {
      console.log(err?.message);
    }
  }


  return (
    <Box sx={{
      // padding: '10px',
      // paddingTop: '20px',
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      width: '100%',
      justifyContent: 'center',
      alignItems: 'start',
      overflowX: 'hidden',

    }}
      className=" pt-3 mt-1"

    >
      <AddFormDailog object={selectedObject} addForm={addForm} setAddForm={setAddForm} loadData={loadData} userRolesHere={userRolesHere} />

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          height: '100%',
          justifyContent: 'center',
          alignItems: 'stretch',
          position: 'relative',
        }}
      >
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Typography
            variant='h4'
            sx={{
              marginLeft: '10px',
              marginBottom: '10px',
              fontFamily: 'Cairo-Bold',
              fontSize: '16px'
            }}>
            {t('ROOMS.title')}
          </Typography>
        </Box>

        <Box sx={{ marginRight: '20px', backgroundColor: '#1E6A99', color: '#FFFFFF', width: '42px', height: '40px', borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }}
          onClick={(event) => {
            // setOpenFilterColumn(true)
            // setElementClicked(event.currentTarget)
          }}
          id="demo-customized-button"
          aria-controls={openFilterColumn ? 'demo-customized-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={openFilterColumn ? 'true' : undefined}
          variant="contained"
        >
          {/* <ViewWeekIcon sx={{color:'#fffff'}}/> */}
          <FaIcons.FaHouseUser
            style={{ fontSize: '30px', color: '#fff' }}
          />
        </Box>

      </Box>

      <Divider
        sx={{
          backgroundColor: '#EBF2F7',
          margin: '30px',
          marginTop: '10px',
          width: '100%'
        }}
        style={{
          height: '3px',

        }}
      />

      <Grid
        rows={rows}
        columns={columns}
        setColumns={null}
        pageSize={pageSize}
        setPageSize={setPageSize}
        pageNumber={pageNumber}
        setPageNumber={setPageNumber}
        loading={loading}
        handlePageChange={handlePageChange}
        rowsTotal={rowsTotal}
        checkScreenSize={screenwidth}
        pageCount={pageCount}
        openFilterColumn={openFilterColumn}
        elementClicked={elementClicked}
        setOpenFilterColumn={setOpenFilterColumn}
        setColumnFilterValue={setOpenFilterColumnValue}
        columFilterValue={openFilterColumnValue}
        clearFunction={clearFunction}
        searchFunction={searchFunction}
        creatFunction={createFUnction}
        ExportFunction={ExportFunction}
        importFunction={null}
        mapFunction={null}
        telegramFunction={null}
        hasCreate={userRolesHere[0][1]?.add}
        hasImport={false}
        hasExport={userRolesHere[0][4]?.export}
        hasMap={false}
        hasTetelgram={false}
        filterChilds={<RenderSearchContent
          objectName={objectName}
          setobjectName={setobjectName}
          fromDate={fromDate}
          setFromDate={setFromDate}
          toDate={toDate}
          setToDate={setToDate}
          createdBy={createdBy}
          setCreatedBy={setCreatedBy}
          users={users}
          width={width}
          setWidth={setWidth}
          length={length}
          setLength={setLength}
          setSelectedUnitType={setSelectedUnitType}
          selectedUnitType={selectedUnitType}
          setSelecteRoomType={setSelecteRoomType}
          selecteRoomType={selecteRoomType}
          unitTypes={unitTypes}
          roomTypes={roomTypes}
        />}
      />

    </Box>
  )
}
