import { Box,TextField,Button} from '@mui/material'
import React,{useState,useEffect}from 'react'
import PopupForm from '../../Components/PopupForm'
import {useTranslation} from 'react-i18next'
import {ContainerOfInput,ContainerOfInputFields,ContainerOfSelectField} from '../../RegisteredCustomers/ThemDesign'
import { useSelector } from 'react-redux'
import ObjectOfErrors,{checkErrors,resetErrors,restCustomeObject} from '../../../Global/Errors/UnitType/UnitType'
import CloseIcon from '@mui/icons-material/Close';
import MaritalStatusAPI from '../../../Global/Network/MaritalStatus/index'
import {toast} from 'react-toastify'
import RoomAPI from '../../../Global/Network/Rooms/index'
import CustomTextField from '../../../General/CustomComponents/CustomTextField'
import CustomeSelectField from '../../../General/CustomComponents/CustomeSelectField'
import buildingAPI from '../../../Global/Network/Building'
import MasterUnitTypeAPI from '../../../Global/Network/MasterUnitTyps'
import Images from '../../Components/Images'
import RoomTypeAPI from '../../../Global/Network/RoomType'
import UnitTypesAPI from '../../../Global/Network/UnitType'

const RenderContent=(props)=>{
    const [t]=useTranslation('common')
    const maintheme=useSelector(state=>state.themeData.maintheme);
    const [ErrorsObject,setErrorsObject]=useState(ObjectOfErrors)
    const handleChange=(e)=>{
       props.setName(e.target.value)
       setErrorsObject(restCustomeObject(ErrorsObject,'name'))
    }
    const clearInput=()=>{
       props.setName('')
       setErrorsObject(restCustomeObject(ErrorsObject,'name'))
    }

console.log("props.selectedBuilding",props.selectedBuilding);
    return(
        <Box className='Container-fluid'>
            <Box className="row my-3">
                <Box className="col-12 col-sm-12 col-md-12 col-lg-6">
                    <CustomTextField
                        label={t('ROOMS.add_width')}
                        value={props?.width}
                        error={ErrorsObject?.width?.error}
                        message={ ErrorsObject?.width?.message}
                        haswidth={true}
                        readOnly={false}
                        focused={true}
                        onChange={(e) => {
                           if(!isNaN(e?.target?.value))
                           props?.setWidth(e?.target?.value)
                           setErrorsObject(restCustomeObject(ErrorsObject,'width'))
                        }}
                        onClearClick={() => {
                            props?.setWidth('')
                            setErrorsObject(restCustomeObject(ErrorsObject,'width'))
                        }}
                    />
                     <Box sx={{margin:'8px 0'}}></Box>

                      <CustomTextField
                        label={t('ROOMS.add_length')}
                        value={props?.length}
                        error={ErrorsObject?.length?.error}
                        message={ ErrorsObject?.length?.message}
                        haswidth={true}
                        readOnly={false}
                        onChange={(e) => {
                           if(!isNaN(e?.target?.value))
                           props?.setLength(e?.target?.value)
                           setErrorsObject(restCustomeObject(ErrorsObject,'length'))
                        }}
                        onClearClick={() => {
                            props?.setLength('')
                            setErrorsObject(restCustomeObject(ErrorsObject,'length'))
                        }}
                    />
                </Box>
                <Box className="col-12 col-sm-12 col-md-12 col-lg-6">
                    
                    <CustomeSelectField
                        label={t('ROOMS.addRoomType')}
                        haswidth={true}
                        value={props?.selecteRoomType}
                        list={props?.roomTypes?props?.roomTypes:[]}
                        error={ErrorsObject?.room_type_id?.error}
                        message={ ErrorsObject?.room_type_id?.message}
                        readOnly={false}
                        onChange={(e,newValue) => {
                            console.log('asdssss',newValue);
                           props?.setSelecteRoomType(newValue)
                           setErrorsObject(restCustomeObject(ErrorsObject,'room_type_id'))
                        }}
                        onClearClick={() => {
                            props?.setSelecteRoomType(null)
                            setErrorsObject(restCustomeObject(ErrorsObject,'room_type_id'))
                        }}
                    />
                     <Box sx={{margin:'12px 0'}}></Box>
                     <CustomeSelectField
                        label={t('ROOMS.addUnitType')}
                        haswidth={true}
                        value={props?.selectedUnitType}
                        list={props?.unitTypes?props?.unitTypes:[]}
                        error={ErrorsObject?.unit_type_id?.error}
                        message={ ErrorsObject?.unit_type_id?.message}
                        readOnly={false}
                        onChange={(e,newValue) => {
                            console.log('asdssss',newValue);
                           props?.setSelectedUnitType(newValue)
                           setErrorsObject(restCustomeObject(ErrorsObject,'unit_type_id'))
                        }}
                        onClearClick={() => {
                            props?.setSelectedUnitType(null)
                            setErrorsObject(restCustomeObject(ErrorsObject,'unit_type_id'))
                        }}
                    />
                </Box>
           
                {/* <Box className="col-12 col-sm-12 col-md-12 col-lg-12 my-3">
                    
                 {
                    <Images 
                     imageList={props?.imageList} 
                     setImageList={props.setImageList}
                     isUpdateImage={props?.isUpdateImage} 
                     setIsUpdatedImage={props?.setIsUpdatedImage}
                     editMode={props?.editMode}
                     loading={props?.loading}
                     setLoading={props?.setLoading}
                     type={props?.type}
                     type_id={props?.type_id}
                     notHasSlice={props?.notHasSlice}
                     userRolesHere={props?.userRolesHere}
                     hideImageType={props?.hideImageType}
                    />
                    // :null
                    }
               </Box> */}
                
            </Box>
        </Box>
    )
}

const RenderFooter=(props)=>{
    const [t]=useTranslation('common')
    const gridtheme=useSelector(state=>state.themeData.gridtheme)

    return(
        <Box sx={{
            display:'flex',
            alignItems:'center',
        }}>
          

              <Box sx={{display:'flex',justifyContent: 'end',alignItems: 'center',width:'100%'}}>
          <Box display="flex" >
              
                  <Button variant="outlined"
                      // spacing={2}
                      sx={{
                            width:'50% !important',
                              // flexGrow: 1, 
                              minWidth:'80px !important',
                              maxWidth:'80px !important',
                              margin: 1,
                              backgroundColor:'#f7f7f7',
                              borderColor:gridtheme?.colorWhite,
                              color:gridtheme?.colorblack,
                             boxShadow:'0 0 7px -2px white',
                            //   color:SearchButtonTheme?.clear_button_text_color,
                          '&:hover':{
                            backgroundColor:'#f7f7f7',
                            borderColor:gridtheme?.colorWhite,
                            color:gridtheme?.colorblack,
                             boxShadow:'0 0 7px 1px white',
                             boxShadow:'0',


                          },
                          height:'35px',
                          fontFamily:'Cairo-Bold'

                      }}
                      className="iconeFilterClear"
                      color="secondary"
                      onClick={()=>{
                        props.setOpen(false)
                    }}
                        >
                      {t('GLOBAL.close')}
                  </Button>
                  <Button
                      variant="contained"
                      // spacing={2} 
                      sx={{ 
                          // flexGrow: 1,
                          margin: 1,
                          width:'80px !important',
                          minWidth:'80px !important',
                          maxWidth:'80px !important',
                          '&:hover':{
                            //   backgroundColor:SearchButtonTheme?.search_button_color+'88',
                          },
                          height:'35px',
                          fontFamily:'Cairo-Bold'
                      }}
                      className="iconeFilterSearch"
                      onClick={() => {
                            props.submit()
                      }} 
                      >
                      {t('GLOBAL.Save')}
                  </Button>
          </Box>
      </Box>
        </Box>
    )
}


export default function AddFormDailog(props) {
    const [t]=useTranslation('common');
    // const [name,setName]=useState(null)
    const [loading,setLoading]=useState(false)
    const [ErrorsObject,setErrorsObject]=useState(ObjectOfErrors)
    const [width,setWidth]=useState(null)
    const [length,setLength]=useState(null)
    const [unitTypes,setUnitTypes]=useState([])
    const [selectedUnitType,setSelectedUnitType]=useState(null)
    const [roomTypes,setRoomTypes]=useState([])
    const [selecteRoomType,setSelecteRoomType]=useState(null)


    // const [buildings,setBuildings]=useState([])
    // const [masterUnitTypes,setMasterUnitTypes]=useState([])
    // const [selectedBuilding,setSelectedBuilding]=useState(null)
    // const [selectedMasterUnitType,setSelectedMasterUnitType]=useState(null)
    // const [imageList,setImageList]=useState([])
    // const [isUpdateImage,setIsUpdatedImage]=useState(false)

    useEffect(()=>{
        if(props?.object&&props?.object?.id){
            let dumData=resetErrors(ErrorsObject)
            setWidth(props?.object?.width)
            setLength(props?.object?.length)
            setSelecteRoomType(props?.object?.room_type)
            setSelectedUnitType(props?.object?.unit_type)
        }else{
            let dumData=resetErrors(ErrorsObject)
            clearForm()
        }
    },[props?.object])

    const submit=async()=>{
     try{
        setLoading(true)
        let dumData=resetErrors(ErrorsObject)
        let data={
            width:width,
            length:length,
            unit_type_id:selectedUnitType?.id,
            room_type_id:selecteRoomType?.id
        }
        


        let result=null;
        if(props?.object&&props?.object?.id){
            data={
                ...data,
                id:props?.object?.id,
                _method:'put'
            }
             result=await RoomAPI.updateRoom({
            type:'unit_type',
            type_id:props?.object?.id,
            data:data
            });
        }else{
            result=await RoomAPI.addRoom({
                data:data
            });
        }
  
        if(result?.status){
          setLoading(false)
          toast.success(t('NETWORKMESSAGE.addCustomerMessageSuccess'))
          clearForm()
          props.loadData()
          props.setAddForm(false)
        }else{
          
           if (typeof result.errorMessage === "object") {
              let updatedObject = resetErrors(ErrorsObject)
              setErrorsObject(checkErrors(result.errorMessage, updatedObject))   
          }
          setLoading(false)
          toast.error(t('NETWORKMESSAGE.messageError'))
        }
        setLoading(false)
     }catch(error){
        setLoading(false)
        toast.error(error?.message)
     }
        
    }

    const clearForm=()=>{
        // setName('')
        setWidth('')
        setLength('')
        setSelecteRoomType(null)
        setSelectedUnitType(null)
    }

    useEffect(()=>{
        const abortController = new AbortController()
        if(!props?.object)
          clearForm()
        // else{
            loadDataOfUnitTypes()
            loadDataOfRoomType()
        // }
        return ()=>{
            abortController.abort()
        }
    },[props?.addForm,props?.object])

    const loadDataOfUnitTypes=async()=>{
        let data=null;
        try{
            data=await UnitTypesAPI.unitTypesList();
            if(data&&data?.status){
                console.log('sdasdasdasdsa',data?.data);
                setUnitTypes(data?.data)
            }else{
                toast.error(t('NETWORKMESSAGE.messageError'))
            }
        }catch(err){
            console.log(err?.message);
        }
    }
    const loadDataOfRoomType=async()=>{
        let data=null;
        try{
            data=await RoomTypeAPI.RoomTypeList();
            if(data&&data?.status){
                setRoomTypes(data?.data)
            }else{
                toast.error(t('NETWORKMESSAGE.messageError'))
            }
        }catch(err){
            console.log(err?.message);
        }
    }

  return (
       <PopupForm 
        open={props.addForm} 
        setOpen={props.setAddForm}
        title={props?.object?t('ROOMS.updateTitle'):t('ROOMS.addTitle')}
        content={<RenderContent
              width={width}
              setWidth={setWidth}
              length={length}
              setLength={setLength}
              setSelectedUnitType={setSelectedUnitType}
              selectedUnitType={selectedUnitType}
              setSelecteRoomType={setSelecteRoomType}
              selecteRoomType={selecteRoomType}
              unitTypes={unitTypes}
              roomTypes={roomTypes}
             editMode={props?.object?.id?true:false}
             loading={loading}
             setLoading={setLoading}
             userRolesHere={props?.userRolesHere}
             type={'unit_type'}
             notHasSlice={true}
             type_id={props?.object?.id}
             hideImageType={true}
             open={props.addForm} 
             setOpen={props.setAddForm}
        />}
        footer={<RenderFooter
            open={props.addForm} 
            setOpen={props.setAddForm}
            submit={submit}
        />}
       />
  )
}
