import React, { useState } from "react";
import { Line, Circle, Group, Rect, useStrictMode } from "react-konva";
import { minMax, dragBoundFunc } from "../utils";
/**
 *
 * @param {minMaxX} props
 * minMaxX[0]=>minX
 * minMaxX[1]=>maxX
 *
 */
const PolygonAnnotation = (props) => {
  useStrictMode(true);
  const {
    points,
    flattenedPoints,
    isFinished,
    handlePointDragMove,
    handleGroupDragEnd,
    handleMouseOverStartPoint,
    handleMouseOutStartPoint,
    onMouseOut,
    onMouseOver,
    polygon,
    onPolygonClicked
  } = props;
  const vertexRadius = 6;

  const [stage, setStage] = useState();
  const handleGroupMouseOver = (e) => {
    if (props?.notHasLine)
      onMouseOver(e, polygon, e.target.getStage())
    if (!isFinished) return;
    e.target.getStage().container().style.cursor = props?.readOnly ? "pointer" : "default";
    // e.target.style.backgroundColor = "#00FFFF"
    setStage(e.target.getStage());
  };
  const handleGroupMouseOut = (e) => {
    if (props?.notHasLine)
      onMouseOut(polygon)
    e.target.getStage().container().style.cursor = "default";
    // e.target.style.backgroundColor ="rgb(140,30,255,0.5)"
  };
  const [minMaxX, setMinMaxX] = useState([0, 0]); //min and max in x axis
  const [minMaxY, setMinMaxY] = useState([0, 0]); //min and max in y axis
  const handleGroupDragStart = (e) => {
    let arrX = points.map((p) => p[0]);
    let arrY = points.map((p) => p[1]);
    setMinMaxX(minMax(arrX));
    setMinMaxY(minMax(arrY));
  };
  const groupDragBound = (pos) => {
    let { x, y } = pos;
    const sw = stage.width();
    const sh = stage.height();
    if (minMaxY[0] + y < 0) y = -1 * minMaxY[0];
    if (minMaxX[0] + x < 0) x = -1 * minMaxX[0];
    if (minMaxY[1] + y > sh) y = sh - minMaxY[1];
    if (minMaxX[1] + x > sw) x = sw - minMaxX[1];
    return { x, y };
  };

  const handleLineMouseOver = (e) => { 
  
    // e.target.fill("#00FFFFA0")
  }

  const handleLineMouseOut = (e) => { 
   
    // e.target.fill("rgb(140,30,255,0.5)")
  }

  const getColorForStatusUnits=(data)=>{
    let color='rgba(255,0,0,0.4)'
    if(!data?.floor_id){
      if(data?.units_count>0&&data?.available_units_counts<=0){
        color="rgba(255,0,0,0.4)"
      }else if(data?.units_count>0&&(data?.available_units_counts>0&&data?.available_units_counts<data?.units_count)){
        color="rgba(247, 211, 32,0.4)"
      }else if(data?.units_count>0&&data?.available_units_counts==data?.units_count){
        color="rgba(0,255,0,0.4)"
      }
    }else if(data?.floor_id){
      if(data?.unit_status){
        color=data?.unit_status?.color+'a0';
      }
    }
    return color
  }

  return (
    <Group
      name="polygon"
      draggable={false}
      onDragStart={handleGroupDragStart}
      onDragEnd={handleGroupDragEnd}
      dragBoundFunc={groupDragBound}
      onMouseOver={handleGroupMouseOver}
      onMouseOut={handleGroupMouseOut}
      onClick={(e)=>{onPolygonClicked(e,polygon)}}
      
      //mobile events
      onTap={handleGroupMouseOver}
      // onTouchStart={handleGroupMouseOver}
      // onTouchMove={handleGroupMouseOver}
      onTouchEnd={handleGroupMouseOut}
      onDblTap={(e)=>{
        onPolygonClicked(e,polygon)
        handleGroupMouseOut()
      }}
    >
      <Line
        points={flattenedPoints}
        stroke="#00F1FF"
        strokeWidth={props?.notHasLine ? 0 : 0.6}
        closed={isFinished}
        fill={props?.polygonInfo?.polygonInfo?.morph_object ? getColorForStatusUnits(props?.polygonInfo?.polygonInfo?.morph_object):'rgba(255,0,0,0.4)'}
        perfectDrawEnabled
        onMouseOver={handleLineMouseOver}
        onMouseOut={handleLineMouseOut}


      />
      {!props?.readOnly && points.map((point, index) => {
        const x = point[0] - vertexRadius / 7;
        const y = point[1] - vertexRadius / 7;
        const startPointAttr =
          index === 0
            ? {
              hitStrokeWidth: 12,
              onMouseOver: handleMouseOverStartPoint,
              onMouseOut: handleMouseOutStartPoint,
            }
            : null;
        return (
          <Circle
            key={index}
            x={x}
            y={y}
            radius={1}
            fill={props?.polygonInfo?.polygonInfo?.morph_object ? getColorForStatusUnits(props?.polygonInfo?.polygonInfo?.morph_object) : "#FF019A"}
            stroke="#00F1FF"
            strokeWidth={props?.notHasRadius ? 0 : 0.4}
            draggable
            style={{
              
            }}
            // onDragMove={handlePointDragMove}
            dragBoundFunc={(pos) =>
              dragBoundFunc(stage.width(), stage.height(), vertexRadius, pos)
            }
            {...startPointAttr}
            _useStrictMode
          />
        );
      })}
    </Group>
  );
};

export default PolygonAnnotation;
