import React from 'react'
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import ClearIcon from '@mui/icons-material/Clear';

export default function SearchPhone({
    value,setValue,title,hasFontSize
}) { 
    const handleChange=(e)=>{
        if(!isNaN(e.target.value)){
            let val = e.target.value?.replace('.', '')
            setValue(val)
        }
    }
    const clearFiled=()=>{
        setValue('')
    }





    return (
        <Box
        // component="form"
        sx={{
        width:'100%',
        display:'flex',
        justifyContent: 'center',
        alignItems: 'center',
        alignSelf:'center',
        margin:'auto',
        position: 'relative',
        '& .MuiFormLabel-root,& .MuiInputBase-input':{
            fontFamily:'Cairo-Medium',
            fontSize:hasFontSize?hasFontSize:'15px'
        },
        '& .MuiInputBase-input':{
            paddingRight:'35px'
        }
        }}
        noValidate
        autoComplete="off"
    >
        <TextField id="standard-basic"
         placeholder={title} value={value} onChange={handleChange} 
         autocomplete="off"
         variant="standard" sx={{width:'95%',ml:1.5,mt:1,mb:1}} />
        {
            value?<ClearIcon sx={{
                fontSize:'15px !important',color:'grey !important',position:'absolute',top:'5px',right:'0px',
                width:'30px',
                height:'30px',
                borderRadius:'50%',
                backgroundColor:'#fff',
                cursor:'pointer',
        }}
          onClick={clearFiled}
        />:null
        }  
    </Box>
    )
}
