import React,{useState,useEffect} from 'react'
import Cookies from 'universal-cookie'
import {useNavigate,useLocation} from 'react-router-dom'
import RolesOfUserAPI from '../Global/Network/RoleUser'
import RedirectPages from '../Global/RedirectPages/RedirectPages'
import {useTranslation} from 'react-i18next'
import Preloading from '../Global/Preload/Preloading'
const cookies = new Cookies()

export const pathsList=[
  {
      id:1,
      roles:[1],
      to:'dashboard',
      subLink:[],
  },
  {
      id:2,
      roles:[22],
      to:'available-compounds',
      subLink:[],
  },
  {
    id:5,
    roles:[25],
    to:'registered-customers',
    subLink:[],
  },
  {
    id:5,
    roles:[14],
    to:'registered-customers/add',
    subLink:[],
  },
  {
    id:80,
    roles:[189],
    to:'image-draw',
    subLink:[],
  },
  {
    id:80,
    roles:[190],
    to:'image-view',
    subLink:[],
  },
  {
    id:26,
    roles:[105],
    to:'calls',
    subLink:[]
  },
  {
    id:27,
    roles:[164],
    to:'orders',
    subLink:[]
  },
  {
    id:28,
    roles:[176],
    to:'order_contract',
    subLink:[]
  },
  {
    id:29,
    roles:[170],
    to:'invoices',
    subLink:[]
  },
  {
    id:29,
    roles:[159],
    to:'view-unit',
    subLink:[]
  },
  {
    id:36,
    roles:[191],//show company profile, with role view company.
    to:'company/profile',
    subLink:[]
  },
  {
    id:37,
    roles:[2000],//show user profile,role 0 because all user have access to his profile.
    to:'user/profile',
    subLink:[]
  },
  {
    id:9,
    roles:[],
    to:'',
    subLink:[
      {
        id:10,
        roles:[29],
        to:'masters/worktype',
        subLink:[]
      },
      {
        id:11,
        roles:[30],
        to:'masters/employee',
        subLink:[]
      },
      {
        id:1111,
        roles:[30],
        to:'masters/department',
        subLink:[]
      },
      {
        id:12,
        roles:[31],
        to:'masters/purpose-of-purshases',
        subLink:[]
      },
      {
        id:13,
        roles:[32],
        to:'masters/marital-status',
        subLink:[]
      },   
      {
        id:14,
        roles:[33],
        to:'masters/customer-type',
        subLink:[]
      },
      {
        id:15,
        roles:[34],
        to:'masters/contact-method',
        subLink:[]
      },
      {
        id:16,
        roles:[35],
        to:'masters/national-id-types',
        subLink:[]
      },
      {
        id:17,
        roles:[64],
        to:'masters/real-estates',
        subLink:[]
      },
      {
        id:18,
        roles:[],
        to:'',
        subLink:[
          {
            id:19,
            roles:[69],
            to:'masters/call-types',
            subLink:[]
          },
          {
            id:20,
            roles:[74],
            to:'masters/call-statuses',
            subLink:[]
          },
          {
            id:21,
            roles:[79],
            to:'masters/call-destinations',
            subLink:[]
          },
          {
            id:22,
            roles:[84],
            to:'masters/call-reasons',
            subLink:[]
          }
      ]
      },
      {
        id:23,
        roles:[89],
        to:'masters/unit-locations',
        subLink:[]
      },
      {
        id:24,
        roles:[94],
        to:'masters/room-types',
        subLink:[]
      },
      {
        id:25,
        roles:[100],
        to:'masters/image-types',
        subLink:[]
      },
      {
        id:26,
        roles:[7],
        to:'masters/roles',
        subLink:[]
      },
      {
        id:27,
        roles:[7],
        to:"masters/compound",
        subLink:[]
      },
      {
        id:28,
        roles:[134],
        to:'masters/unit-statuses',
        subLink:[]
      },
      {
        id:30,
        roles:[149],
        to:'masters/unit-types',
        subLink:[]
      },
      {
        id:31,
        roles:[154],
        to:'masters/rooms',
        subLink:[]
      },
      {
        id:32,
        roles:[159],
        to:'masters/units',
        subLink:[]
      },
      {
        id:33,
        roles:[197],
        to:'masters/payment_types',
        subLink:[]
      },
      {
        id:34,
        roles:[202],
        to:'masters/phases',
        subLink:[]
      },
      {
        id:35,
        roles:[196,191],//only user have role viewAllCompanies can access this.
        to:'masters/companies',
        subLink:[]
      },
      {
        id:38,
        roles:[208],
        to:'masters/discounts',
        subLink:[]
      },
      {
        id:39,
        roles:[213],
        to:'masters/master-unit-types',
        subLink:[]
      },
      {
        id:40,
        roles:[218],
        to:'masters/bulk-insert-floors',
        subLink:[]
      },
      {
        id:41,
        roles:[219],
        to:'masters/bulk-delete-floors',
        subLink:[]
      },
      {
        id:42,
        roles:[218],
        to:'masters/bulk-insert-buildings',
        subLink:[]
      },
      {
        id:43,
        roles:[219],
        to:'masters/bulk-delete-buildings',
        subLink:[]
      },

    ],
  }
 ]

export default function ProtectionAdmin(props) {
  const [t]=useTranslation('common')
   const token =cookies.get('token')
   const access_type =cookies.get('access_type')
   const navigate=useNavigate()
   const [userRoles,setUserRoles]=useState([])
   const [hasAccessToThisRoute,setHasAccessToThisRoute]=useState(false)
   
   const [code,setCode]=useState(null)
   const [loading,setLoading]=useState(false)
   let location = useLocation();

  const getUserRoles=async()=>{
    setLoading(true)
   try{ 
    let data=await RolesOfUserAPI.GetRolesOfUser();
    if(data&&data?.status){
     
      let arr=[]
      if(data?.data){
        arr=[...data?.data]
        arr.push(2000)
      }
      setUserRoles(arr);
    }else{
      if(data?.errorCode==401){
        cookies.set('token',null)
        cookies.remove('token')
        cookies.remove('token', { path: '/' });
        navigate('/')
      }
    }
    setLoading(false)
   }catch(err){
     setLoading(false)
    
     
   }
  }
  useEffect(()=>{
    let abortController = new AbortController();
    getUserRoles()
    return () => {
      abortController.abort();
    }
  },[])

  useEffect(()=>{
    let abortController = new AbortController();
    if(userRoles){
      // setHasAccessToThisRoute(false)
      routeChange()
    }
    return () => {
      abortController.abort();
    }
  },[userRoles])

  useEffect(()=>{
    let abortController = new AbortController();
    // setHasAccessToThisRoute(false)
    routeChange()
   
    return () => {
      abortController.abort();
    }
  },[location?.pathname])

  const routeChange=async()=>{
    setLoading(true)
    if(userRoles&&userRoles?.length>0){
      let check=false
      let codeError=null;
      pathsList&&pathsList?.length>0&&pathsList?.map((itm)=>{
        if(!check){
          if(itm&&itm?.subLink&&itm?.subLink?.length>0){//has sub
              itm?.subLink?.map((subItm)=>{
                if(!check){
                    if(subItm&&subItm?.subLink&&subItm?.subLink?.length>0){//has sub and has sub
                        // here will be the condition of sub sub...
                        subItm?.subLink?.map((subSubItem)=>{
                          if(!check){
                            if(subSubItem&&subSubItem?.length>0){
                              
                            }
                           else{
                              if(location?.pathname==`/admin/${subSubItem?.to}`){
                                let checkIsInRole=false
                                subSubItem&&subSubItem?.roles&&subSubItem?.roles?.map((role)=>{
                                 
                                  if(!checkIsInRole)
                                    checkIsInRole=userRoles.includes(role)
                                })
                                if(checkIsInRole){
                                  check=true;
                                }else{
                                  check=false;
                                  codeError=501
                                }
                                
                              }else{
                                
                                check=false;
                                if(!codeError)
                                codeError=404
                              }
                            }
                          }
                        })
                    }else{
                        if(location?.pathname==`/admin/${subItm?.to}`){
                          let checkIsInRole=false
                          subItm&&subItm?.roles&&subItm?.roles?.map((role)=>{
                            
                            if(!checkIsInRole)
                              checkIsInRole=userRoles.includes(role)
                          })
                          if(checkIsInRole){
                            check=true;
                          }else{
                            check=false;
                            codeError=501
                          }
                          
                        }else{
                          
                          check=false;
                          if(!codeError)
                          codeError=404
                        }
                    }
                }
              })
          }else{
              if(location?.pathname==`/admin/${itm?.to}`){
                let checkIsInRole=false
                itm&&itm?.roles&&itm?.roles?.map((subItm)=>{
                 
                  if(!checkIsInRole)
                    checkIsInRole=userRoles.includes(subItm)
                })
                if(checkIsInRole){
                  check=true;
                }else{
                  check=false;
                  codeError=501
                }
                
              }else{
                
                check=false;
                if(!codeError)
                codeError=404
              }
          }
        }
      })

      if(check){
        setHasAccessToThisRoute(true)
        setLoading(false)
      }else{
        setLoading(false)
        setCode(codeError)
      }
    }else if(!loading&&!userRoles){
      setCode(503)
      setHasAccessToThisRoute(false)
    }
  }

  if(!loading) {
    if(access_type==='admin'&&hasAccessToThisRoute&&token){
      return (
        props?.children
      )
    }else{
      if(code===404)
        return <RedirectPages
            code={code}
            reason={t('ERRORS.reason_404')}
            message={t('ERRORS.message_404')}
            path={'/admin/dashboard'}
            goTo={t('ERRORS.goTo_404')}
        />
      else if(code===501)
         return <RedirectPages
              code={code}
              reason={t('ERRORS.reason_501')}
              message={t('ERRORS.message_501')}
              path={'/admin/dashboard'}
              goTo={t('ERRORS.goTo_501')}
              />
      else if(code===503)
        return <RedirectPages
            code={code}
            reason={t('ERRORS.reason_503')}
            message={t('ERRORS.message_503')}
            path={'/admin/dashboard'}
            goTo={t('ERRORS.goTo_503')}
            hasReload={true}
            />
    }
  }else{
     return <Preloading/>
  }
}
