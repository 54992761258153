import callAPI from '../ApiConfig'
import store from '../../../app/store'

const CallDestinations = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"call_destinations",
        method:"get",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
    
}
const CallDestinationsList = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"call_destinations_list",
        method:"get",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
    
}
const addCallDestinations = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"call_destinations",
        method:"post",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        },
        data:props?.data,
    })
    
}
const updateCallDestinations = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"call_destinations/"+props?.data?.id,
        method:"put",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        },
        data:props?.data
    })
    
}
const DeleteCallDestinations = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"call_destinations/"+props?.data?.id,
        method:"delete",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        },
    })
    
}
const CallDestinationsAPI={
    CallDestinations:CallDestinations,
    CallDestinationsList:CallDestinationsList,
    addCallDestinations:addCallDestinations,
    updateCallDestinations:updateCallDestinations,
    DeleteCallDestinations:DeleteCallDestinations

}
export default CallDestinationsAPI