const Phase = {
    id:"",
    name:{
        value: "",
        error: false,
        message:[],
        required: false
    },
    description:{
        value: "",
        error: false,
        message:[],
        required: false
    },
    
    compoundPhases: []
}
export const resetErrors = (customrObject) => {
    var objectKeys = Object.keys(customrObject);
   
    var updatedObject = {
        ...customrObject
    }
    try {
        objectKeys.filter(key=>key!=="id").map((key) => {
        
            updatedObject[key]["error"] = false
            updatedObject[key]["message"] = ""
        })
    } catch (error) {
        
    }
    
    return updatedObject

}
export const checkErrors = (errorMessages, customrObject) => {

    const errorMessagesKeys = Object.keys(errorMessages);
    errorMessagesKeys.map((key) => {
        if(!key?.includes('contacts')){
        let messages = []
        errorMessages[key].map((message) => {
            messages.push(message)
        })
       
        customrObject[key]["error"] = true
        customrObject[key]["message"] = messages
       }
    })

  




    return customrObject
}
export const ObjectMerge = (updatedObject) => {
    const mergedCustomrObject = {
    id:updatedObject?.id,
    name: {
        value: updatedObject?.name,
        error: false,
        message:[],
        required: false
    },
    
    description:{
        value: updatedObject?.description,
        error: false,
        message:[],
        required: false
    },
    

    compoundPhases: updatedObject?.compound_phases?.length > 0 ? updatedObject?.compound_phases?.map(compoundPhase => {
        return {
            id:compoundPhase?.id,
            start_date: {
                value: compoundPhase?.start_date,
                error: false,
                message: [],
                required: false
            },
            end_date: {
                value: compoundPhase?.end_date,
                error: false,
                message: [],
                required: false
            },
            compound: {
                value: compoundPhase?.compound,
                error: false,
                message: [],
                required: false
            },
        }
    }) : [],

    }
    
    return mergedCustomrObject;
}
export const restCustomObject=(customrObject,obj)=>{
    // var objectKeys = Object.keys(customrObject);
   
    try {
        
        customrObject[obj]["error"] = false
        customrObject[obj]["message"] = ""
    } catch (error) {
        
    }
    
    return customrObject
}
export default Phase;