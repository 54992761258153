const ObjectOfErrors = {
    id:"",

    relation_type_id:{
        value: {
            id:"",
            name:"",
        },
        error: false,
        message: "",
        required: false
    },
    name:{
        value: "",
        error: false,
        message: "",
        required: false
    },
    phone:{
        value: "",
        error: false,
        message: "",
        required: false
    },
    address:{
        value: "",
        error: false,
        message: "",
        required: false
    },
    
    

    
}
export const resetErrors = (modelObject) => {
    var objectKeys = Object.keys(modelObject);
   
    var updatedObject = {
        ...modelObject
    }
    try {
        objectKeys.filter(key=>key!=="id").map((key) => {
        
            updatedObject[key]["error"] = false
            updatedObject[key]["message"] = ""
        })
    } catch (error) {
        
    }
    
    return updatedObject

}
export const checkErrors = (errorMessages, modelObject) => {

    const errorMessagesKeys = Object.keys(errorMessages);
    errorMessagesKeys.map((key) => {
        if(!key?.includes('contacts')){
        let messages = []
        errorMessages[key].map((message) => {
            messages.push(message)
        })
    
        modelObject[key]["error"] = true
        modelObject[key]["message"] = messages
       }
    })


    return modelObject
}
export const ObjectMerge = (updatedObject) => {
    const mergedModelrObject = {
    id:updatedObject?.id,
    
    relation_type_id:{
        value: {
            id:"",
            name:"",
            ...updatedObject?.relation_type,
        },
        error: false,
        message: "",
        required: false
    },
    name:{
        value: updatedObject?.name,
        error: false,
        message: "",
        required: false
    },
    phone:{
        value: updatedObject?.phone,
        error: false,
        message: "",
        required: false
    },
    address:{
        value: updatedObject?.address,
        error: false,
        message: "",
        required: false
    },
    }
    
    return mergedModelrObject;
}
export const resetModelObject=(modelObject,obj)=>{
    // var objectKeys = Object.keys(modelObject);
   
    try {
        
        modelObject[obj]["error"] = false
        modelObject[obj]["message"] = ""
    } catch (error) {
        
    }
    
    return modelObject
}
export default ObjectOfErrors;