import { Box } from "@mui/material";
import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import BarChartIcon from '@mui/icons-material/BarChart';

export default function PieChart(props) {
  const [t] = useTranslation("common");
  const [state, setState] = useState({
    series: [0, 0, 0, 0, 0],
    options: {
      chart: {
        width: 350,
        type: "pie",
        events: {
          dataPointSelection: function (event, chartContext, config) {
            handleBarClicked(event, chartContext, config);
          },
        },
      },
      labels: [
        t("DASHBOARD.status_reverse"),
        t("DASHBOARD.status_sale"),
        t("DASHBOARD.status_sold"),
        t("DASHBOARD.status_saleBycustomer"),
        t("DASHBOARD.Total_status"),
      ],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
      legend: {
        show: true,
        fontSize: "12px",
        fontFamily: "Cairo-Medium",
        margin: "10px",
        position:'right',
        markers: {
             margin:'10px'
        }
      },
      colors: ["#64df3e", "#f1e846", "#f95151", "#43a3f7", "#00f"],
      tooltip: {
        enabled: true,
        enabledOnSeries: undefined,
        shared: true,
        followCursor: false,
        intersect: false,
        inverseOrder: false,
        custom: undefined,
        fillSeriesColor: true,
        theme: false,
        style: {
          fontSize: "12px",
          fontFamily: "Cairo-Medium",
        },
      },
    },
  });
  const navigate = useNavigate();

  const handleBarClicked = (event, chartContext, config) => {
    console.log("sadasdasdasdsadasdas", config);

    if (config?.dataPointIndex > -1) {
      if (config?.dataPointIndex == 0) {
        navigate("/admin/masters/units", {
          state: {
            data: {
              status_id: "reserved",
            },
          },
        });
        localStorage.removeItem("isUseNavigation");
      } else if (config?.dataPointIndex == 1) {
        navigate("/admin/masters/units", {
          state: {
            data: {
              status_id: 1,
            },
          },
        });
        localStorage.removeItem("isUseNavigation");
      } else if (config?.dataPointIndex == 2) {
        navigate("/admin/masters/units", {
          state: {
            data: {
              status_id: 3,
            },
          },
        });
        localStorage.removeItem("isUseNavigation");
      } else if (config?.dataPointIndex == 3) {
        navigate("/admin/masters/units", {
          state: {
            data: {
              status_id: 4,
            },
          },
        });
        localStorage.removeItem("isUseNavigation");
      } else if (config?.dataPointIndex == 4) {
        navigate("/admin/masters/units", {
          state: {
            data: {
              status_id: "all",
            },
          },
        });
        localStorage.removeItem("isUseNavigation");
      }
    }
  };

  const getPercentageOfPieChart = (data, totla) => {
    console.log(
      "dashboardresult",
      Math.ceil((parseInt(data) / parseInt(totla)) * 100)
    );
    return Math.round((data / totla) * 100);
  };

  useEffect(() => {
    console.log("dashboarddat6a", props?.dashboardObject);
    if (props?.dashboardObject) {
      console.log("dashboarddat6a", props?.dashboardObject);
      let check = false;
      // let message=t('DASHBOARD')
      if (
        props?.dashboardObject?.reserved == 0 &&
        props?.dashboardObject?.for_sale == 0 &&
        props?.dashboardObject?.sold == 0 &&
        props?.dashboardObject?.for_sale_by_customer == 0
      ) {
        check = true;
      }

      if (!check) {
        setState({
          ...state,
          series: [
            getPercentageOfPieChart(
              props?.dashboardObject?.reserved,
              props?.dashboardObject?.all_statuses
            ),
            getPercentageOfPieChart(
              props?.dashboardObject?.for_sale,
              props?.dashboardObject?.all_statuses
            ),
            getPercentageOfPieChart(
              props?.dashboardObject?.sold,
              props?.dashboardObject?.all_statuses
            ),
            getPercentageOfPieChart(
              props?.dashboardObject?.for_sale_by_customer,
              props?.dashboardObject?.all_statuses
            ),
          ],
        });
      } else {
        setState({
          ...state,
          series: [0, 0, 0, 0, props?.dashboardObject?.all_statuses],
        });
      }
    }
  }, [props?.dashboardObject]);

  return (
    <div id="chart" >
      {parseInt(props?.dashboardObject?.all_statuses) > 0 ? (
        <ReactApexChart
          options={state.options}
          series={state.series}
          type="pie"
          width={450}
          height={400}
        />
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <BarChartIcon
            sx={{ color: "gray", fontSize: "40px", margin: "10px 0" }}
          />
          <span style={{fontFamily:'Cairo'}}>{t("GLOBAL.noData")}</span>
        </Box>
      )}
    </div>
  );
}
