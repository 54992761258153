import callAPI from '../ApiConfig'
import store from '../../../app/store'

const login = async (props) => {

    console.log("props",props);
    var data = new FormData();
    data.append('username', props?.username);
    data.append('password', props?.password);
    return await callAPI({
        route: "login",
        method: "post",
        signal: props?.signal,
        data: data,
    })
}

const logout = async (props) => {
    const storeState = store.getState();
    var data = new FormData();
    return await callAPI({
        route: "logout",
        method: "post",
        signal: props?.signal,
        headers: {
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
}

const users = async (props) => {
    const storeState = store.getState();

    return await callAPI({
        route: "users",
        method: "get",
        signal: props?.signal,
        params: props?.params,
        headers: {
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }

    })

}

const addUser = async (props) => {
    const storeState = store.getState();

    let active=0
    if(props?.active?.value)
    {
        
        active=1
    }

    var data = new FormData();
    data.append('name', props?.name?.value);
    data.append('email', props?.email?.value);
    data.append('password', props?.password?.value);
    data.append('username', props?.username?.value);
   
    data.append('active',active)
    
    
    return await callAPI({
        route: "users",
        method: "post",
        signal: props?.signal,
        data: data,
        headers: {
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
}
const editUser = async (props) => {
    const storeState = store.getState();

    let active = false
    if (props?.active?.value) {

        active = true
    }

    const data = {
        name: props?.name?.value,
        email: props?.email?.value,
        password: props?.password?.value,
        username: props?.username?.value,
        active: active,
        id: props?.id
    }
    return await callAPI({
        route: "users",
        method: "put",
        signal: props?.signal,
        data: data,
        headers: {
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
}
const deleteUser = async (props) => {
    const storeState = store.getState();


    return await callAPI({
        route: "users/" + props?.id,
        method: "delete",
        signal: props?.signal,
        headers: {
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
}
const getUser = async (props) => {
    const storeState = store.getState();


    return await callAPI({
        route: "user",
        method: "get",
        signal: props?.signal,
        params: {
            id: props?.id
        },
        headers: {
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
}
const usersList = async (props) => {
    const storeState = store.getState();

    return await callAPI({
        route: "users/list",
        method: "get",
        signal: props?.signal,
        params: props?.params,
        headers: {
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }

    })

}



const userAPI = {
    login: login,
    users:users,
    addUser:addUser,
    editUser:editUser,
    deleteUser:deleteUser,
    getUser:getUser,
    usersList:usersList,
    logout:logout
}
export default userAPI