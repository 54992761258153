import callAPI from '../ApiConfig'
import store from '../../../app/store'

const realEstate = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"real_estates",
        method:"get",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
    
}
const realEstateList = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"real_estates_list",
        method:"get",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
    
}
const addRealEstate = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"real_estates",
        method:"post",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        },
        data:props?.data,
    })
    
}
const updateRealEstate = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"real_estates/"+props?.data?.id,
        method:"put",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        },
        data:props?.data
    })
    
}
const deleteRealEstate = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"real_estates/"+props?.data?.id,
        method:"delete",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        },
    })
    
}

const RealEstateAPI={
    RealEstate:realEstate,
    RealEstateList:realEstateList,
    addRealEstate:addRealEstate,
    updateRealEstate:updateRealEstate,
    deleteRealEstate:deleteRealEstate
}
export default RealEstateAPI