import {
  Avatar,
  Box,
  Divider,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import CreateIcon from "@mui/icons-material/Create";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTranslation } from "react-i18next";
import * as BsIcons from "react-icons/bs";
import * as AiIcons from "react-icons/ai";
import * as RiIcons from "react-icons/ri";
import * as MdIcons from "react-icons/md";
import { useNavigate } from "react-router-dom";
import helpers from "../../../../assets/js/helper";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import { numberToArabic } from "number-to-arabic";
import "./style.css";
import { useSelector } from "react-redux";
const APP_CONSTANTS = {
  API_URL_IMG: `${process.env.REACT_APP_API_URL_image}`,
};

const tempImage = "https://cdn-icons-png.flaticon.com/512/560/560338.png";
const MessageCard = (props) => {
  const [showActions, setShowActions] = useState(false);
  const screenwidth=useSelector(state=>state.settingsData.screenwidth);
  const [t] = useTranslation("common");
  const navigate = useNavigate();

  var DateDiff = {
    inDays: function (d1, d2) {
      var t2 = d2.getTime();
      var t1 = d1.getTime();

      return parseInt((t2 - t1) / (24 * 3600 * 1000));
    },

    inWeeks: function (d1, d2) {
      var t2 = d2.getTime();
      var t1 = d1.getTime();

      return parseInt((t2 - t1) / (24 * 3600 * 1000 * 7));
    },

    inMonths: function (d1, d2) {
      var d1Y = d1.getFullYear();
      var d2Y = d2.getFullYear();
      var d1M = d1.getMonth();
      var d2M = d2.getMonth();

      return d2M + 12 * d2Y - (d1M + 12 * d1Y);
    },

    inYears: function (d1, d2) {
      return d2.getFullYear() - d1.getFullYear();
    },
  };
  const getDateTime = (date) => {
    var datetime = date ? new Date(helpers.formatDate(date)) : null;
    var dateNow = new Date();
    const diffTime = Math.abs(dateNow - datetime);
    var Difference_In_Time = dateNow.getTime() - datetime.getTime();
    const diffDays = Math.abs(
      Math.floor(Difference_In_Time / (1000 * 3600 * 24))
    );

    let message = "";

    if (diffDays >= 30) {
      let months = parseInt(diffDays / 30);
      let days = diffDays % 30;
      if (diffDays >= 360) {
        let yesrs = parseInt(diffDays / 360);
        let months = parseInt((diffDays % 360) / 30);
        let days = (diffDays % 360) % 30;
        if (yesrs >= 3)
          message = `منذ ${numberToArabic(yesrs)} سنوات و ${numberToArabic(
            months
          )} شهر و ${numberToArabic(days)} يوم`;
        else
          message = `منذ ${numberToArabic(yesrs)} سنة و ${numberToArabic(
            months
          )} شهر و ${numberToArabic(days)} يوم`;
      } else {
        message = `منذ ${numberToArabic(months)} شهر و ${numberToArabic(
          days
        )} يوم`;
      }
    } else {
      if (diffDays == 0) message = `منذ   يوم`;
      else message = `منذ ${numberToArabic(diffDays)} يوم`;
    }

    return message;
  };
  const goToCustomerDetailsPage = () => {
    // navigate('/admin/registered-customers',{
    //   state:{
    //     data:{
    //       customer:props?.customer
    //     }
    //   }
    // })
    window.open(
      window.location.origin +
        "/admin/registered-customers?id=" +
        props?.customer?.id,
      "_blank"
    );
  };
  const goToUnitsTable = () => {
    // navigate('',{
    //   state:{
    //     data:{
    //       customer:props?.customer
    //     }
    //   }
    // })
    window.open(
      window.location.origin + "/admin/masters/units?id=" + props?.customer?.id,
      "_blank"
    );
  };
  const goToFinancialTable = () => {
    // navigate('/admin/registered-customers',{
    //   state:{
    //     data:{
    //       customer:props?.customer
    //     }
    //   }
    // })
    window.open(
      window.location.origin +
        "/admin/registered-customers?financialCustomerID=" +
        props?.customer?.id,
      "_blank"
    );
  };

  return (
    <Box
      sx={{
        // background: '#1E6A99 0% 0% no-repeat padding-box',
        boxShadow: "0px 3px 6px #00000029",
        width: props?.fromCustomerView ? "80%" : "fit-content !important",
        marginBottom: "25px",
        borderRadius: "5px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        flex: 1,
        flexGrow: 1,
        // borderRadius: "12px",
        background:
          props?.call_destination?.direction == 1 ? "#838383eb" : "#1E6A99",
        padding: "10px",
        // boxShadow: "6px 6px 12px lightgray,-6px -6px 12px lightgray",
        transition: "all 0.5s ease",
        "&:hover": {
          // background: "linear-gradient(145deg, #e0e0e0, #bcbcbc)",
          boxShadow: "6px 6px 12px #6b6b6b,-6px -6px 12px #c2c2c2",
        },
        position: "relative",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "start",
          opacity: 1,
          width: "100%",
          position: "relative",
        }}
        className="containerContact"
        onMouseEnter={() => {
          setShowActions(true);
        }}
        onMouseLeave={() => {
          setShowActions(false);
        }}
      >
        <Avatar
          sx={{
            bgcolor: "#fff",
            marginTop: "15px",
            marginRight: "15px",
            marginLeft: "15px",
            position: "relative",
            top: "6px",
          }}
          alt={props?.message?.by_name}
          sizes={45}
          src={
            props?.created_by?.userable?.images
              ? APP_CONSTANTS.API_URL_IMG +
                props?.created_by?.userable?.images[
                  props?.created_by?.userable?.images?.length - 1
                ]?.image_url
              : tempImage
          }
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            paddingTop: "20px",
            paddingBottom: "20px",
            paddingRight: "16px",
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: props?.fromCustomerView? "start" : "center",
              alignItems: props?.fromCustomerView? "center" : "start",
              flexDirection: props?.fromCustomerView? "row" : "column",
              flexWrap:'wrap'
            }}
          >
            <Typography
              sx={{
                textAlign: "left",
                font: "normal normal  11px/20px Cairo-Medium",
                letterSpacing: "0px",
                color: "#fff",
                opacity: 1,
                display: "flex",
                position: "relative",
                top: props?.fromCustomerView ? "2px" : "-4px",
              }}
              className="text"
            >
              سجل بواسطة
              <Typography
                sx={{
                  textAlign: "left",
                  font: "normal normal 16px/16px Cairo-Bold",
                  letterSpacing: "0px",
                  color: "#ffffff",
                  opacity: 1,
                  marginLeft: "5px",
                  marginRight: "5px",
                }}
                className="userName"
              >
                {props?.created_by?.full_name}
              </Typography>
              {props?.created_by?.company?.name}
              <Typography
                sx={{
                  textAlign: "left",
                  font: "normal normal 300 10px/22px Cairo",
                  letterSpacing: "0px",
                  color: "#f1f1f",
                  opacity: 1,
                  marginLeft: "15px",
                  marginRight: "5px",
                }}
                className="time"
              >
                {props?.date_time ? getDateTime(props?.date_time) : ""}
              </Typography>
            </Typography>

            <Box
              sx={{
                textAlign: "left",
                font: "normal normal  11px/20px Cairo",
                letterSpacing: "0px",
                color: "#fff",
                opacity: 1,
                display: "flex",
                alignItems: "center",
                position: "relative",
                top: "0px",
                backgroundColor: "#fff",
                borderRadius: "10px",
                width: "fit-content",
                padding: "0 10px",
                marginTop: "1px",
                position: "relative",
                top: "3px",
              }}
              className="destination"
            >
              <Box
                sx={{
                  color: "#fff",
                  display: "flex",
                  alignItems: "center",
                  width: "fit-content !important",
                }}
              >
                <span style={{ margin: "0 5px", color: "#000" }} className="destination-first-title">
                  {t("CALLS.destination") + " : "}
                </span>
                <span
                  style={{
                    color: "#000",
                    fontSize: "12px",
                    fontFamily: "Cairo",
                  }}
                  className="destination-first-content"
                >
                  {props?.call_destination?.direction == 0
                    ? props?.created_by?.full_name + " ( موظف )"
                    : props?.customer?.user?.full_name + " ( زبون )"}
                </span>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  justifyContent: "center",
                  position: "relative",
                  top: "2px",
                }}
              >
                <BsIcons.BsArrowLeft
                  style={{
                    color: "#000",
                    fontSize: "14px",
                    margin: "0px 13px",
                    position: "relative",
                    top: "0px",
                  }}
                  className="destination-arraw"
                />
                {/* <BsIcons.BsArrowRight style={{color:'#f00',fontSize:'14px',margin:'0px 13px',position:'relative',top:'-2px'}}/> */}
              </Box>
              <Box
                sx={{
                  color: "#fff",
                  display: "flex",
                  alignItems: "center",
                }}
                className="destination-second"
              >
                <span
                  style={{
                    color: "#000",
                    fontSize: "12px",
                    fontFamily: "Cairo",
                  }}
                className="destination-second-content"

                >
                  {props?.call_destination?.direction == 0
                    ? props?.customer?.user?.full_name + " ( زبون )"
                    : props?.created_by?.full_name + " ( موظف )"}
                </span>
              </Box>
            </Box>
          </Box>

          <Typography
            sx={{
              textAlign: "left",
              font: "normal normal  12px/18px Cairo-Medium",
              letterSpacing: "0px",
              color: "#ededeb",
              opacity: 1,
              marginTop: "10px",
              fontFamily: "Cairo-Medium",
              textAlign: "left !important",
              wordWrap: "break-word !important",
              spaceWord: "break-word !important",
              whiteSpace: "break-spaces !important",
              wordBreak: "break-all",
              overflow: "hidden",
              textOverflow: "clip",
              textAlign: "justify",
              lineHeight: props?.fromCustomerView ? "19px" : "24px",
              lineWidth: "100% !important",
              maxWdith: "100%",
              overflow: "hidden",
            }}
          >
            {props?.reply}
          </Typography>
        </Box>
        <Box
          sx={{
            position: "absolute",
            top: "5px",
            right: "5px",
            height: "max-content",
            borderRadius: "10px",
            backgroundColor: "#FFFFFF",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            boxShadow: "0 0 13px -2px #000",
            borderRadius: "52px",
            background: "#fff",
            boxShadow: " 0 0 7px -2px #ffffff",
            padding: "3px 12px",
            height: "25px",
            background: "#fff",
            flexDirection: "row-reverse",
          }}
          className="tooltipContact"
        >
          {props?.userRolesHere[2]?.update ? (
            <Tooltip
              arrow
              placement="bottom"
              title={
                <span style={{ fontFamily: "Cairo-Medium" }}>
                  {t("CALLS.edit")}
                </span>
              }
            >
              <IconButton
                sx={{
                  padding: 0,
                  margin: 0,
                  width: "20px",
                  height: "20px",
                  margin: "0 2px",
                  borderRadius: "50% !important",
                  "&:hover": {
                    backgroundColor: "lightgray",
                    // "& svg":{
                    //   color:"#fff"
                    // }
                  },
                }}
                onClick={() => {
                  props?.updateFunction(props?.itm);
                }}
              >
                <CreateIcon sx={{ color: "#1E6A99", fontSize: "17px" }} />
              </IconButton>
            </Tooltip>
          ) : null}
          {props?.userRolesHere[3]?.delete ? (
            <Tooltip
              arrow
              placement="bottom"
              title={
                <span style={{ fontFamily: "Cairo-Medium" }}>
                  {t("CALLS.delete")}
                </span>
              }
            >
              <IconButton
                sx={{
                  padding: 0,
                  margin: 0,
                  width: "20px",
                  height: "20px",
                  margin: "0 2px",
                  borderRadius: "50% !important",
                  "&:hover": {
                    backgroundColor: "lightgray",
                  },
                }}
                onClick={() => {
                  props?.deleteFunction(props?.itm);
                }}
              >
                <DeleteIcon sx={{ color: "#f00", fontSize: "17px" }} />
              </IconButton>
            </Tooltip>
          ) : null}
          {props?.userRolesHere[5]?.customerMaster &&
          !props?.fromCustomerView ? (
            <Tooltip
              arrow
              placement="bottom"
              title={
                <span style={{ fontFamily: "Cairo-Medium" }}>
                  {t("CALLS.customerMaster")}
                </span>
              }
            >
              <IconButton
                sx={{
                  padding: 0,
                  margin: 0,
                  width: "20px",
                  height: "20px",
                  margin: "0 2px",
                  borderRadius: "50% !important",
                  "&:hover": {
                    backgroundColor: "lightgray",
                  },
                }}
                onClick={goToCustomerDetailsPage}
              >
                <RiIcons.RiCustomerServiceLine
                  style={{ color: "#1e6091", fontSize: "17px" }}
                />
              </IconButton>
            </Tooltip>
          ) : null}
          {props?.userRolesHere[6]?.customerUnits ? (
            <Tooltip
              arrow
              placement="bottom"
              title={
                <span style={{ fontFamily: "Cairo-Medium" }}>
                  {t("CALLS.customerUnits")}
                </span>
              }
            >
              <IconButton
                sx={{
                  padding: 0,
                  margin: 0,
                  width: "20px",
                  height: "20px",
                  margin: "0 2px",
                  borderRadius: "50% !important",
                  "&:hover": {
                    backgroundColor: "lightgray",
                  },
                }}
                onClick={() => {
                  goToUnitsTable();
                }}
              >
                <RiIcons.RiCommunityFill
                  style={{ color: "#48bfe3", fontSize: "17px" }}
                />
              </IconButton>
            </Tooltip>
          ) : null}
          {props?.userRolesHere[7]?.customerFinancial &&
          !props?.fromCustomerView ? (
            <Tooltip
              arrow
              placement="bottom"
              title={
                <span style={{ fontFamily: "Cairo-Medium" }}>
                  {t("CALLS.customerFinancial")}
                </span>
              }
            >
              <IconButton
                sx={{
                  padding: 0,
                  margin: 0,
                  width: "20px",
                  height: "20px",
                  margin: "0 2px",
                  borderRadius: "50% !important",
                  "&:hover": {
                    backgroundColor: "lightgray",
                  },
                }}
                onClick={() => {
                  goToFinancialTable();
                }}
              >
                <MdIcons.MdAttachMoney
                  style={{ color: "#4da86e", fontSize: "17px" }}
                />
              </IconButton>
            </Tooltip>
          ) : null}
          {props?.needs_follow_up &&
          props?.userRolesHere[8]?.customerDisActiveFollowUp ? (
            <Tooltip
              arrow
              placement="bottom"
              title={
                <span style={{ fontFamily: "Cairo-Medium" }}>
                  {t("CALLS.doneFollowUp")}
                </span>
              }
            >
              <IconButton
                sx={{
                  padding: 0,
                  margin: 0,
                  width: "20px",
                  height: "20px",
                  margin: "0 2px",
                  borderRadius: "50% !important",
                  "&:hover": {
                    backgroundColor: "lightgray",
                  },
                }}
                onClick={() => {
                  props.submitNeedFollowUp(props?.itm);
                }}
              >
                <BsIcons.BsPatchCheck
                  style={{ color: "#005e94", fontSize: "17px" }}
                />
              </IconButton>
            </Tooltip>
          ) : null}
        </Box>
      </Box>
      {!props?.fromCustomerView ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "start",
            opacity: 1,
            width: "100%",
            position: "relative",
            flexDirection: "column",
            flex: 1,
          }}
        >
          <Divider
            sx={{
              color: "#fff",
              width: "95%",
              margin: "auto",
              height: "2px",
              borderRadius: "10px",
              marginBottom: "10px",
            }}
          />
          <Box
            sx={{
              width: "fit-content",
              display: "flex",
              alignItems: "center",
              flexFlow: "wrap",
              marginLeft: "13px",
              // width:'95%',
              flex: 1,
            }}
          >
            <Box
              sx={{
                backgroundColor: "#fff",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "2px 17px",
                borderRadius: "14px",
                width: "fit-Content",
                margin: "5px",
                cursor: "pointer",
                "&:hover": {
                  opacity: "0.8",
                },
                background: "#fff",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Cairo-Bold",
                  fontSize: "12px",
                }}
                className="footer-title"
              >
                الحالة
              </Typography>
              <Box
                sx={{
                  width: "1.5px",
                  height: "10px",
                  backgroundColor: "gray",
                  margin: "0 3px",
                }}
              ></Box>
              <Typography
                sx={{
                  fontFamily: "Cairo-Bold",
                  fontSize: "12px",
                }}
                className="footer-content"
              >
                {props?.call_status?.name}
              </Typography>
            </Box>
            <Box
              sx={{
                backgroundColor: "#fff",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "2px 17px",
                borderRadius: "14px",
                width: "fit-Content",
                margin: "5px",
                cursor: "pointer",
                "&:hover": {
                  opacity: "0.8",
                },
                background: "#fff",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Cairo-Bold",
                  fontSize: "12px",
                }}
                className="footer-title"
              >
                النوع
              </Typography>
              <Box
                sx={{
                  width: "1.5px",
                  height: "10px",
                  backgroundColor: "gray",
                  margin: "0 3px",
                }}
              ></Box>
              <Typography
                sx={{
                  fontFamily: "Cairo-Bold",
                  fontSize: "12px",
                }}
                className="footer-content"

              >
                {props?.call_type?.name}
              </Typography>
            </Box>
            <Box
              sx={{
                backgroundColor: "#fff",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "2px 17px",
                borderRadius: "14px",
                width: "fit-Content",
                margin: "5px",
                cursor: "pointer",
                "&:hover": {
                  opacity: "0.8",
                },
                background: "#fff",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Cairo-Bold",
                  fontSize: "12px",
                }}
                className="footer-title"
              >
                السبب
              </Typography>
              <Box
                sx={{
                  width: "1.5px",
                  height: "10px",
                  backgroundColor: "gray",
                  margin: "0 3px",
                }}
              ></Box>
              <Typography
                sx={{
                  fontFamily: "Cairo-Bold",
                  fontSize: "12px",
                }}
                className="footer-content"
              >
                {props?.call_reason?.name &&
                props?.call_reason?.name?.length <= 40
                  ? props?.call_reason?.name
                  : props?.call_reason?.name?.slice(0, 40) + "..."}
              </Typography>
            </Box>
          </Box>
        </Box>
      ) : null}
      {props?.importance_degree > 0 || props?.importance_degree == 1 ? (
        <Box
          sx={{
            width: "fit-content",
            height: "30px",
            backgroundColor: "transparent",
            borderRadius: "10px",
            // boxShadow:'0 0 7px -2px rgba(0,0,0,0.4)',
            position: "absolute",
            top: "-15px",
            left: "-7px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Tooltip
            arrow
            placement="bottom"
            title={
              <span style={{ fontFamily: "Cairo-Medium" }}>
                {t("CALLS.importance")}
              </span>
            }
          >
            <Box
              sx={{
                width: "25px",
                height: "25px",
                borderRadius: "50% !important",
                // position:'absolute',
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginRight: "5px",
                // backgroundColor:'#f00',
                cursor: "pointer",
                backgroundColor: "#fff",
                boxShadow: "0 0 7px -2px rgba(0,0,0,0.3)",
              }}
              // className="contactImportanGlow"
            >
              <PriorityHighIcon sx={{ color: "#f00", fontSize: "25px" }} />
            </Box>
          </Tooltip>
          {props?.needs_follow_up ? (
            <Tooltip
              arrow
              placement="bottom"
              title={
                <span style={{ fontFamily: "Cairo-Medium" }}>
                  {t("CALLS.followups")}
                </span>
              }
            >
              <Box
                sx={{
                  width: "25px",
                  height: "25px",
                  borderRadius: "50% !important",
                  // position:'absolute',
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",

                  // backgroundColor:'#f00',
                  cursor: "pointer",
                  backgroundColor: "#fff",
                  boxShadow: "0 0 7px -2px rgba(0,0,0,0.3)",
                }}
                // className="contactImportanGlow"
              >
                <BsIcons.BsFillPersonCheckFill
                  style={{ color: "#005e94", fontSize: "18px" }}
                />
              </Box>
            </Tooltip>
          ) : null}
        </Box>
      ) : null}
    </Box>
  );
};

export default MessageCard;
