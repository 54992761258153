import callAPI from '../ApiConfig'
import store from '../../../app/store'

const orders = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"orders",
        method:"get",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
    
}
const orderList = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"orders_list",
        method:"get",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
    
}
const Addorder = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"orders",
        method:"post",
        signal:props?.signal,
        params:props?.params,
        data:props?.data,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
    
}
const updateOrder = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"orders/"+props?.data?.id,
        method:"put",
        signal:props?.signal,
        params:props?.params,
        data:props?.data,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
    
}
const deleteorder = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"orders/"+props?.data?.id,
        method:"delete",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
    
}

const cancelOrder= async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"order/change_status/"+props?.data?.id,
        method:"patch",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
    
}

const generateOrderInvoices = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"order/generate_invoices/"+props?.data?.id,
        method:"post",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
    
}

const calculateDiscountedPrice = async (props) => { 
    const storeState = store.getState();
    
    return await callAPI({
        route:"order/calculate_discounted_price",
        method:"get",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
}


const order = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"order",
        method:"get",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
    
}

const orderAPI={
    orders:orders,
    orderList:orderList,
    Addorder:Addorder,
    updateOrder:updateOrder,
    deleteorder:deleteorder,
    calculateDiscountedPrice:calculateDiscountedPrice,
    order:order,
    generateOrderInvoices:generateOrderInvoices,
    cancelOrder:cancelOrder
}
export default orderAPI