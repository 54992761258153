import callAPI from '../ApiConfig'
import store from '../../../app/store'

const getRolesOfDepartment = async (props) => {
    const storeState = store.getState();
    return await callAPI({
        route:"department_roles",
        method:"get",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
    
}

const setDepartmentRolesBulk = async (props) => {
    const storeState = store.getState();
    return await callAPI({
        route:"department_roles/bulk_sync",
        method:"post",
        signal:props?.signal,
        params:props?.params,
        data:props?.data,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
    
} 



const rolesOfDepartmentAPI={
    GetRolesOfDepartment:getRolesOfDepartment,
    setDepartmentRolesBulk:setDepartmentRolesBulk

}
export default rolesOfDepartmentAPI