import * as React from 'react';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';


// function valuetext(value) {
//     return `${value}`;
// }

export default function CustomSlider(props) {
    // function valueLabelFormat(value) {
    //     return props?.marks.findIndex((mark) => mark.value === value) + 1;
    // }
    function preventHorizontalKeyboardNavigation(event) {
        if (event.key === 'ArrowLeft' || event.key === 'ArrowRight') {
            event.preventDefault();
        }
    }

    return (
        <Box sx={{ height: '100%' }}>
            <Slider
                sx={{
                    '& input[type="range"]': {
                        WebkitAppearance: 'slider-vertical',
                    },
                    color: '#1E6A99', //color of the slider between thumbs
                    "& .MuiSlider-thumb": {
                        backgroundColor: '#1E6A99' //color of thumbs
                    },
                    "& .MuiSlider-rail": {
                        color: '#1E6A99' ////color of the slider outside  teh area between thumbs
                    },
                    '& .MuiSlider-markLabelActive': {

                    },
                    '& .MuiSlider-markLabel': {
                        '&:hover': {
                            background: "#FFFFFFA0 0% 0% no-repeat padding-box",
                            paddingTop: '5px',
                            paddingBottom: '5px',
                            opacity: "1",
                            borderRadius: '10px',
                        },
                        color:'#ffffff',
                        // color:'#1E6A99',
                        textShadow:'0 0 2px #1E6A99, 0 0 2px #1E6A99, 0 0 2px #1E6A99, 0 0 2px #1E6A99, 0 0 2px #1E6A99, 0 0 2px #1E6A99, 0 0 2px #1E6A99'
                        // textShadow:'0 0 1px #ffffff, 0 0 1px #ffffff, 0 0 1px #ffffff, 0 0 1px #ffffff, 0 0 1px #ffffff, 0 0 1px #ffffff, 0 0 1px #ffffff'

                    },
                    '& .MuiSlider-markLabelActive': {
                        background: "#1E6A99",
                        color: '#FFFFFF',
                        paddingTop: '5px',
                        paddingBottom: '5px',
                        paddingLeft: '5px',
                        paddingRight: '5px',
                        opacity: "1",
                        borderRadius: '10px',
                        textShadow: 'none',
                    },
                }}
                track={false}
                orientation="vertical"
                value={props?.value}
                onChange={(event, value, activThumb) => {
                    props?.onChange(value)
                }}
                aria-label="الطوابق"
                valueLabelDisplay="off"
                min={props?.marks?.length > 0 ? props?.marks[0]?.value : 0}
                max={props?.marks?.length > 0 ? props?.marks[props?.marks?.length - 1]?.value : 0}
                onKeyDown={preventHorizontalKeyboardNavigation}
                // valueLabelFormat={valueLabelFormat}
                // getAriaValueText={valuetext}
                step={null}
                marks={props?.marks}
            />
        </Box>
    );
}