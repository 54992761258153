import callAPI from '../ApiConfig'
import store from '../../../app/store'

const discounts = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"discounts",
        method:"get",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    
    })
    
}

const discountsList = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"discounts_list",
        method:"get",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    
    })
    
}

const addDiscount = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"discounts",
        method:"post",
        signal:props?.signal,
        params:props?.params,
        data:props?.data,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
    
}
const updateDiscount = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"discounts/"+props?.data?.id,
        method:"put",
        signal:props?.signal,
        params:props?.params,
        data:props?.data,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
    
}
const deleteDiscount = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"discounts/"+props?.data?.id,
        method:"delete",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
    
}


const discount = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"discount",
        method:"get",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    
    })
    
}

const discountAPI={
    discounts:discounts,
    discountsList:discountsList,
    addDiscount:addDiscount,
    updateDiscount:updateDiscount,
    deleteDiscount:deleteDiscount,
}
export default discountAPI