import { Box, Typography } from '@mui/material'
import React from 'react'

function ContractRow({ title, value, paddingRight,titleFlex,marginTop }) {
    return (
        <Box
            style={{
                display: 'flex',
                flexDirection: 'row',
                marginTop:marginTop?marginTop:'10px',
                ...(paddingRight && {
                    paddingRight: paddingRight
                })
            }}>
            <Typography
                component={'span'}
                style={{
                    fontFamily: 'Cairo-Bold',
                    textAlign: 'justify',
                    textJustify: 'inter-word',
                    lineHeight: 'normal',
                    flex:titleFlex?titleFlex:0.3
                }}>
                {title}
            </Typography>
                <Typography
                    style={{
                        flex:9,
                        wordWrap: 'break-word',
                        fontFamily: 'Cairo',
                        textAlign: 'justify',
                        textJustify: 'inter-word',
                        lineHeight: 'normal'
                    }}>
                    {value}
                </Typography>

        </Box>
    )
}

export default ContractRow