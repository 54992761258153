
import React, { useState, useEffect } from 'react';
import Pagination from '@mui/material/Pagination';
import { Box, FormControl, MenuItem, Select } from '@mui/material';
import CustomSelect from '../CustomComponents/CustomSelect';
import '../../Admin/Grid/style.css'
const CustomPagination = (props) => {
    
  
  console.log("props",props);
    return (
      <Pagination
        sx={{
          backgroundColor: "#FFFFFF",
          color: "#000000",
  
  
          "&& .Mui-selected": {
            backgroundColor: "#349CDD",
            borderRadius:0
          },
          "&& .Mui-selected:hover": {
            backgroundColor: '#00A5BA',
            borderRadius:0
          }
  
  
  
        }}
  
        color="primary"
        count={props?.pageCount}
        page={props?.page}
        
        onChange={(event, value) => 
          {
          
            props?.setPage(value)
          }}
      />
    );
}

const CustomFooter = (props) => {
  
    return (
  
    
        // <Box sx={{
        //   display: 'flex',
        //   flexDirection: { md: 'row', sm: 'column', xs: 'column' },
        //   width: '100%',
        //   justifyContent: 'center',
        //   alignItems: 'center'
  
        // }}>
          
        //   <Box sx={{
  
  
        //   }}>
        //     <CustomPagination 
        //     pageCount={props?.pageCount}
        //     page={props?.page}
        //     setPage={props?.setPage}
        //     />
        //   </Box>
  
        // </Box>
        <Box
        sx={{
          width: "100%",
          height: "fit-content",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          '& .MuiPaginationItem-previousNext ':{
            backgroundColor:'lightgrey !important'
          },
          '& .MuiPaginationItem-previousNext .MuiSvgIcon-root ':{
            color:'black !important'
          }
        }}
      >
        <Box className={`paginationTabsBottom ${props?.notHaveGrid?'paginationTabsBottom_not':''}`} sx={{display:'flex',justifyContent:'space-between',alignItemd:'center'}}>
          <Box className="paginationTabsBottom_sub" >
            <Box className="d-flex align-items-center" 
              sx={{
                '& label,& input':{
                  fontFamily: "Cairo-Medium !important",
                  color:'grey'
                }
              }}
            >
              <label htmlFor="pageNumberSet">الصفحة</label>
              <input
                className="pageNumberSet"
                id="pageNumberSet"
                min={1}
                max={props?.pageCount}
                type="number"
                value={props?.page}
                focused
                onChange={(e) => {
                  props?.setPage(parseInt(e.target.value));
                }}
              />
            </Box>
            <Box className="boxOfPagination1" >
              <Pagination
                color="primary"
              //   size={
              //     checkScreenSize
              //       ? "small"
              //       : checkScreenSize == 580
              //       ? "30px"
              //       : "large"
              //   }
                count={props?.pageCount}
                page={props?.page}
              //   showFirstButton={checkScreenSize ? false : true}
              //   showLastButton={checkScreenSize ? false : true}
                onChange={(e, p) => {
                  props?.setPage(p);
                }}
              />
            </Box>
          </Box>
             
          <Box sx={{flex:0.5}}>
           <Box className="d-flex align-items-center" 
              sx={{
                '& label,& input':{
                  fontFamily: "Cairo-Medium !important",
                  color:'grey'
                }
              }}
            >
              <label htmlFor="pageNumberSet">الصفحة</label>
              <Box sx={{ width:'fit-content',"& .MuiInputBase-root":{
                height:40,margin:'0 10px'
              } }}>
                <FormControl fullWidth>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={props?.pageSize}
                    // label="Age"
                    onChange={(e)=>{
                      props?.setPageSize(e.target.value)
                    }}
                  >
                    <MenuItem value={5}>5</MenuItem>
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={20}>20</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              
            </Box>
         </Box>

        </Box>
     
      </Box>
  
    );
  
  
  }
  
export default CustomFooter;