import { RenderContentOfContact } from '../../Masters/Calls/Calls'
import CustomMenu from './CommunicationsTabComponents/CustomMenu'
import MessageCard from './CommunicationsTabComponents/MessageCard'
import React ,{useState,useEffect,useMemo} from 'react'
import Grid from '../../Grid/Grid'
import {useTranslation} from 'react-i18next'
import { useSelector } from 'react-redux';
import MaritalStatusAPI from '../../../Global/Network/MaritalStatus/index'
import { toast } from 'react-toastify';
import DropDownGrid from '../../Grid/Components/DropDownGrid'
import {Box,MenuItem,ListItemIcon,Typography,Divider,Autocomplete,TextField, FormControlLabel, Checkbox} from '@mui/material'
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ViewWeekIcon from '@mui/icons-material/ViewWeek';
// import AddFormDailog from './AddFormDailog';
import swal from 'sweetalert';
import SearchInput from '../../Components/SearchInput';
import SearchDate from '../../Components/SearchDate';
import EmployeeAPI from '../../../Global/Network/Employee/index'
import { from } from 'stylis';
import helpers from '../../../assets/js/helper';
import { useGlobalContext } from '../../../context'
import * as MdIcons from 'react-icons/md'
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import * as FaIcons from 'react-icons/fa'
import RolesOfUserAPI from '../../../Global/Network/RoleUser/index';
import CallAPI from '../../../Global/Network/Calls/index'
import Preloading from '../../../Global/Preload/Preloading';
import customerAPI from '../../../Global/Network/Customer';
import CallsTypeAPI from '../../../Global/Network/CallsType/index';
import CallStatusAPI from '../../../Global/Network/CallStatus/index'
import call_reasonsAPI from '../../../Global/Network/CallReasons/index'
import Addresses from '../../Components/Addresses';
import {useLocation, useNavigate,useParams} from 'react-router-dom'
import AddFormDailog from '../../Masters/Calls/AddFormDailog'



const CommunicationsTab = (props) => {
  const [t] = useTranslation("common");
  const [rows,setRows]=useState([])
  const [pageSize, setPageSize] = useState(5);
  const [pageNumber, setPageNumber] = useState(1);
  const [loading,setLoading]=useState(false)
  const [pageCount,setPageCount]=useState(0)

  const [addForm,setAddForm]=useState(false)
  const [selectedObject,setselectedObject]=useState(null)
  const [fromDate,setFromDate]=useState(null)
  const [toDate,setToDate]=useState(null)
  const [objectName,setobjectName]=useState(null)
  const [createdBy,setCreatedBy]=useState(null)
  const [users,setUsers]=useState([])
  const { exportToCSV} = useGlobalContext()
  const [customers,setCustomers]=useState([]);
  const [selectedCustomer,setSelectedCustomer]=useState(null)
  const [customerFromState,setSelectCustomerFormState]=useState(null)
  const screenwidth=useSelector(state=>state.settingsData.screenwidth)
  const [callTypes,setCallTypes]=useState([]);
  const [selectedCallType,setSelectedCallType]=useState(null)
  const [callstateus,setCallStateus]=useState([]);
  const [selectedCallStatus,setSelectedCallStatus]=useState(null)
  const [callReasons,setCallReasons]=useState([]);
  const [selectedCallReasons,setSelectedCallReasons]=useState(null)
  const [neededFollowUp,setNeededFollowUp]=useState(null)
  const { state } = useLocation();
  const [userRolesHere,setUserRolesHere]=useState([
   {
     view:true,
     id:105
   },
   {
   add:false,
   id:106,
   },
   {
   update:false,
   id:107,
   },
   {
   delete:false,
   id:108,
   },{
   export:false,
   id:109
   },
   {
     customerMaster:false,
     id:110
   },
   {
     customerUnits:false,
     id:111
   },
   {
     customerFinancial:false,
     id:112
   },
   {
     customerDisActiveFollowUp:false,
     id:113
   }
  ])
 
 
  const handlePageChange = (newPage) => {
     setPageNumber(newPage + 1);
   };
  const controller = new AbortController();
 
  const [rowsTotal,setRowsTotal]=useState(0)

 useEffect(()=>{
   let abortController = new AbortController();
   loadData()
   return () => {
     abortController.abort();
   }
 },[pageNumber,pageSize,customerFromState])
 

 
 useEffect(()=>{
   let abortController = new AbortController();
   console.log('saldkjaskd',1);
   getUserRoles()

   return () => {
     abortController.abort();
   }
 },[])
 
  const loadData = async (searchParams) => {
   setLoading(true)
 
   const result = await CallAPI.Calls({
      params: {
         page_size: 2,
         page: 1,
         customer_id:props?.row?.id,
         ...searchParams
      },
      signal: controller?.signal
   })
 
   setLoading(false)
   if (result.status) {
      setRows(result?.data?.data)
      setRowsTotal(result?.data?.total)
      setPageCount(result?.data?.last_page)
   }
   else {
      if(!result?.data?.error)
      {
       toast.error(t('NETWORKMESSAGE.messageError'))
       return
      }
   }
 
 }
 const deleteFunction=async(obj)=>{
   swal({
     title: '?هل انت متأكد',
     text: "لن تتمكن من التراجع عن هذا!",
     icon: 'warning',
     buttons: ["الغاء!", "نعم!"],
     dangerMode: true,
   }).then(async(willDelete) => {
     console.log('1')
     if (willDelete) {
       console.log('2')
 
       try{
           setLoading(true)
 
           let result=null;
           if(obj&&obj?.id){
     console.log('3')
 
               result=await CallAPI.deleteCall({
               data:{
                 id:obj.id,
               }
               });
           }
 
           if(result?.status){
             setLoading(false)
             // toast.success(t('NETWORKMESSAGE.deleteSuccess'))
             swal(`${t('NETWORKMESSAGE.deleteSuccess')}`, {
               icon: "success",
             });
             loadData({
               page:pageNumber
             })
           }else{
             setLoading(false)
             if(result?.errorMessage){
              toast.error(result?.errorMessage)
            }else
            toast.error(t('NETWORKMESSAGE.deleteErrorr'))
           }
           setLoading(false)
       }catch(error){
           setLoading(false)
           toast.error(t(error?.message))
       }
    }else{
     // alert('canceled')
    }
   })
 }
 const createFUnction=()=>{
  setselectedObject(null)
   setAddForm(true)
}
const updateFunction=(obj)=>{
  setselectedObject(obj)
  setAddForm(true)
}
 const getUserRoles=async()=>{
   // setLoading(true)
   console.log('saldkjaskd',2);
  try{ 
   let data=await RolesOfUserAPI.GetRolesOfUser();
   if(data&&data?.status){
     console.log('userRolesmmm',data?.data);
     if(data?.data&&data?.data?.length>0){
         let arr=[];
         
          let checkHasRole=data?.data?.includes(userRolesHere[0]?.id)
           if(checkHasRole){
             arr.push(
                 {
                   view:true,
                   id:userRolesHere[0]?.id,
                 }
             )
           }else{
             arr.push(
                   {
                     view:false,
                     id:userRolesHere[0]?.id,
                   }
                )
           }
           console.log('sassssss',userRolesHere[1]?.id);
          checkHasRole=data?.data?.includes(userRolesHere[1]?.id)
           if(checkHasRole){
              arr.push(
                 {
                   add:true,
                   id:userRolesHere[1]?.id,
                 }
              )
           }else{
             arr.push(
                   {
                     add:false,
                     id:userRolesHere[1]?.id,
                   }
                )
           }
         checkHasRole=data?.data?.includes(userRolesHere[2]?.id)
           if(checkHasRole){
              arr.push(
                 {
                   update:true,
                   id:userRolesHere[2]?.id,
                 }
              )
           }else{
               arr.push(
                   {
                     update:false,
                     id:userRolesHere[2]?.id,
                   }
                )
           }
         checkHasRole=data?.data?.includes(userRolesHere[3]?.id)
           if(checkHasRole){
              arr.push(
                 {
                   delete:true,
                   id:userRolesHere[3]?.id,
                 }
              )
           }else{
               arr.push(
                   {
                     delete:false,
                     id:userRolesHere[3]?.id,
                   }
                )
           }
           checkHasRole=data?.data?.includes(userRolesHere[4]?.id)
           if(checkHasRole){
              arr.push(
                 {
                   export:true,
                   id:userRolesHere[4]?.id,
                 }
              )
           }else{
               arr.push(
                   {
                     export:false,
                     id:userRolesHere[4]?.id,
                   }
                )
           }
 
           checkHasRole=data?.data?.includes(userRolesHere[5]?.id)
           if(checkHasRole){
              arr.push(
                 {
                   customerMaster:true,
                   id:userRolesHere[5]?.id,
                 }
              )
           }else{
               arr.push(
                   {
                     customerMaster:false,
                     id:userRolesHere[5]?.id,
                   }
                )
           }
           checkHasRole=data?.data?.includes(userRolesHere[6]?.id)
           if(checkHasRole){
              arr.push(
                 {
                   customerUnits:true,
                   id:userRolesHere[6]?.id,
                 }
              )
           }else{
               arr.push(
                   {
                     customerUnits:false,
                     id:userRolesHere[6]?.id,
                   }
                )
           }
 
           checkHasRole=data?.data?.includes(userRolesHere[7]?.id)
           if(checkHasRole){
              arr.push(
                 {
                   customerFinancial:true,
                   id:userRolesHere[7]?.id,
                 }
              )
           }else{
               arr.push(
                   {
                     customerFinancial:false,
                     id:userRolesHere[7]?.id,
                   }
                )
           }
 
           checkHasRole=data?.data?.includes(userRolesHere[8]?.id)
           if(checkHasRole){
              arr.push(
                 {
                   customerDisActiveFollowUp:true,
                   id:userRolesHere[8]?.id,
                 }
              )
           }else{
               arr.push(
                   {
                     customerDisActiveFollowUp:false,
                     id:userRolesHere[8]?.id,
                   }
                )
           }
           
           
         setUserRolesHere(arr)
     }
   }
   setLoading(false)
  }catch(err){
    setLoading(false)
    console.log(err?.message);
    
  }
 }
 const submitNeedFollowUp=async(itm)=>{
  let result=null;
  try{
    
  if(itm&&itm?.id){
    setLoading(true)
      let data={
          needs_follow_up:0,
          id:itm?.id,
          _method:'put'
      }
      result=await CallAPI.DisActiveFollowUp({
      data:data
      });
  }

  if(result?.status){
      setLoading(false)
      toast.success(t('NETWORKMESSAGE.UpdateCustomerMessageSuccess'))
      loadData()
    }else{
      setLoading(false)
      toast.error(t('NETWORKMESSAGE.messageError'))
    }
  }catch(err){
      console.log(err?.message)
  }
}
 
  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      paddingLeft: '70px',
      paddingTop: '32px',
      paddingBottom: '32px',
      position: 'relative',
    }}>
        <AddFormDailog object={selectedObject} addForm={addForm} setAddForm={setAddForm} loadData={loadData} customer={props?.row}/>

      {/* {messageList?.map(message => <MessageCard message={message} />)} */}
      <RenderContentOfContact
          rows={rows}
          columns={null}
          setColumns={null}
          pageSize={pageSize}
          setPageSize={setPageSize}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
          loading={loading}
          handlePageChange={handlePageChange}
          rowsTotal={rowsTotal}
          checkScreenSize={screenwidth}
          deleteFunction={deleteFunction}
          updateFunction={updateFunction}
          pageCount={pageCount}
          submitNeedFollowUp={submitNeedFollowUp}
          userRolesHere={userRolesHere}
          fromCustomerView={true}
      />

      <Box
          sx={{
            position: 'absolute',
            top: '20px',
            right: '10px',
            height: 'max-content',
            backgroundColor: '#FFFFFF',
            display: 'block',
          }}
        >
          <CustomMenu createFUnction={createFUnction} row={props?.row} userRolesHere={userRolesHere}/>
        </Box>
    </Box>
  )
}

export default CommunicationsTab