import { Box, Divider, List, ListItem, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from "react-i18next";
import CustomTableHeader from '../../RegisteredCustomers/CustomTable/CustomTableHeader';
import * as FaIcons from 'react-icons/fa'
import * as CgIcons from 'react-icons/cg'
import {useSelector} from 'react-redux'
import FinancialCard from './FinancialCard';


function Financial(props) {
  const [t] = useTranslation("common");
  const screenwidth=useSelector(state=>state.settingsData.screenwidth)


    return (
        <Box
            sx={{
                flex: 1
            }}
        >
          
            <Box className="container-fluid">
                <Box className="row my-2 d-flex justify-content-between">
                    <Box className={`col-12 col-md-12 col-lg-12 col-xlg-6 my-3 px-4`}>
                        <CustomTableHeader columns={[{
                            title:t('CUSTOMER.invoices')
                        }]} />
                        <List sx={{height:'180px',width:'100%',overflowY:'scroll'}} className="CustomerFinancialSummaryTab">
                            {[1,2,3,4,5,6,7]?.map((itm)=><ListItem sx={{width:'100%'}}>
                                <FinancialCard itm={itm} isFatoora={true}/>
                            </ListItem>)}
                        </List>
                    </Box>
                    <Box className={`col-12 col-md-12 col-lg-12 col-xlg-6 my-3 px-4`}>
                        <CustomTableHeader columns={[{
                            title:t('CUSTOMER.receipt')
                        }]} />
                        <List sx={{height:'180px',width:'100%',overflowY:'scroll'}} className="CustomerFinancialSummaryTab">
                        {[1,2,3,4,5,6,7]?.map((itm)=><ListItem sx={{width:'100%'}}>
                             <FinancialCard/>
                        </ListItem>)}
                     </List>
                    </Box>
                    <Box className={`col-12 col-md-12 col-lg-12 col-xlg-6 my-3 px-4`}>
                        <CustomTableHeader columns={[{
                            title:t('CUSTOMER.FinancialSummary')
                        }]} />
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'start',
                            alignItems:'center',
                            flexDirection: 'column',
                            margin:'10px 0'
                        }}
                        
                        >
                            <p>
                                <span style={{
                                    textAlign: "right", font: "normal normal  12px/22px Cairo-Bold", letterSpacing: "0px", color: "#7D8185", opacity: "1",margin:'0 10px'
                                }}>{t('CUSTOMER.Totalamountowed')+':'}</span>
                                <span style={{
                                    textAlign: "right", font: "normal normal  12px/22px Cairo", letterSpacing: "0px", color: "#FF0000", opacity: "1"
                                }}>10,000,000 دينار عراقي</span>
                            </p>
                            <p>
                                <span style={{
                                    textAlign: "left", font: "normal normal  12px/22px Cairo-Bold", letterSpacing: "0.36px", color: "#7D8185", opacity: "1",margin:'0 10px'
                                }}>{t('CUSTOMER.Totalamountpaid')+':'}</span>
                                <span style={{
                                    textAlign: "right", font: "normal normal  12px/22px Cairo", letterSpacing: "0px", color: "#00FF14", opacity: "1"
                                }}>10,000,000 دينار عراقي</span>
                            </p>
                            <p
                             style={{
                                width: "258px", height: "2px", background: "transparent linear-gradient(180deg, #FFFFFF 0%, #7D8185 48%, #FFFFFF 100%) 0% 0% no-repeat padding-box", opacity: "1",
                                background: "linear-gradient(90deg, rgba(218,218,218,0.29485297536983546) 0%, rgba(184,184,184,1) 35%, rgba(184,184,184,1) 62%, rgba(189,189,189,0.25843840954350494) 100%, rgba(218,218,218,1) 100%, rgba(166,174,181,1) 100%, rgba(0,212,255,1) 100%)"
                             }}
                            ></p>
                              <p>
                                <span style={{
                                    textAlign: "right", font: "normal normal  12px/22px Cairo-Bold", letterSpacing: "1px", color: "#7D8185", opacity: "1",marginLeft:'10px'
                                }}>{t('CUSTOMER.lastPaymentAmount')+':'}</span>
                                <span style={{
                                    textAlign: "right", font: "normal normal  12px/22px Cairo", letterSpacing: "0px", color: "#7D8185", opacity: "1"
                                }}>10,000,000 دينار عراقي</span>
                            </p>
                            <p>
                                <span style={{
                                    textAlign: "right", font: "normal normal  12px/22px Cairo-Bold", letterSpacing: "1px", color: "#7D8185", opacity: "1",marginLeft:'10px'
                                }}>{t('CUSTOMER.dateForLastPayment')+':'}</span>
                                <span style={{
                                    textAlign: "right", font: "normal normal  12px/22px Cairo", letterSpacing: "0px", color: "#7D8185", opacity: "1"
                                }}>10,000,000 دينار عراقي</span>
                            </p>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default Financial