import callAPI from '../ApiConfig'
import store from '../../../app/store'

const maritalStatus = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"marital_statuses",
        method:"get",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
    
}
const maritalStatusList = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"marital_statuses_list",
        method:"get",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
    
}
const addMaritalStatus = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"marital_statuses",
        method:"post",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        },
        data:props?.data,
    })
    
}
const updateMaritalStatus = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"marital_statuses/"+props?.data?.id,
        method:"put",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        },
        data:props?.data
    })
    
}
const deleteMaritalStatus = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"marital_statuses/"+props?.data?.id,
        method:"delete",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        },
    })
    
}
const MaritalStatusAPI={
    MaritalStatus:maritalStatus,
    MaritalStatusList:maritalStatusList,
    AddMaritalStatus:addMaritalStatus,
    UpdateMaritalStatus:updateMaritalStatus,
    DeleteMaritalStatus:deleteMaritalStatus
}
export default MaritalStatusAPI