import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import { useTranslation } from "react-i18next";

export default function SalesManBarChart(props) {
  const [t] = useTranslation("common");
  const [state, setState] = useState({
    series: [],
    options: {
      chart: {
        type: "bar",
        height: 410,
        stacked: true,
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: true,
        },
        events: {
          click: function (event, chartContext, config) {
            handleBarClicked(event, chartContext, config);
          },
        },
      },
      colors: ["#0be369"],
      // responsive: [{
      //   breakpoint: 480,
      //   options: {
      //     width:1200,
      //   }
      // }],
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 0,
          hideOverflowingLabels: false,
          dataLabels: {
            total: {
              enabled: true,
              style: {
                fontSize: "13px",
                fontWeight: 900,
                fontFamily: "Cairo",
              },
            },
          },
        },
      },
      xaxis: {
        type: "string",
        categories: [
          "01/01/2011 GMT",
          "01/02/2011 GMT",
          "01/03/2011 GMT",
          "01/04/2011 GMT",
          "01/05/2011 GMT",
          "01/06/2011 GMT",
        ],
        fontFamily: "Cairo",
        labels: {
          show: true,
          style: {
            fontFamily: "Cairo",
          },
        },
      },
      legend: {
        position: "right",
        offsetY: 40,
        show: false,
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        style: {
          fontSize: "12px",
          fontFamily: "Cairo",
        },
      },
      yaxis: {
        labels: {
          show: true,
          formatter: (value) => {
            return Math.ceil(parseInt(value));
          },
        },
      },
    },
  });
  const [listOfIds, setListOfIds] = useState([]);
  const [names, setNames] = useState([]);



  const handleBarClicked = (event, chartContext, config) => {
    console.log("sadasdasdasdsadasdas", event);
    console.log("sadasdasdasdsadasdas", chartContext);
    console.log("sadasdasdasdsadasdas", config);
    console.log("userid", 0);

    if (config?.dataPointIndex > -1) {
      console.log("userid", names);
      const userIds = localStorage.getItem("userIds")
        ? JSON.parse(localStorage.getItem("userIds"))
        : [];
      const userNames = localStorage.getItem("userNames")
        ? JSON.parse(localStorage.getItem("userNames"))
        : [];

      console.log("userid", userIds);

      userIds &&
        userIds?.length > 0 &&
        userIds?.map((itm, index) => {
          console.log("userid", 2);

          if (index == config?.dataPointIndex) {
            console.log("userid", 3);

            props?.setSelectedUserId({
              name: userNames[index],
              user_id: itm,
            });
            console.log("userid", itm);
          }
        });
    }
  };

  const handleGetDataForSalesMan = (data) => {
    let values = [],
      names = [],
      ids = [];
      data&&data?.length>0&&data?.map((itm)=>{
        values.push(itm?.total_value);
        names.push(itm?.employee_name);
        ids.push(itm?.employee_id);
      })
    return {
      values: values,
      names: names,
      ids: ids,
    };
  };

  useEffect(() => {
    let data = handleGetDataForSalesMan(props?.dashboardData);
    localStorage.setItem("userIds", JSON.stringify(data?.ids));
    localStorage.setItem("userNames", JSON.stringify(data?.names));

    setState({
      ...state,
      series: [
        {
          name: props?.title,
          data: data?.values,
        },
      ],
      options: {
        ...state?.options,
        colors:[props?.color],
        xaxis: {
          ...state?.options?.xaxis,
          categories: data?.names,
        },
      },
    });
    setListOfIds(data?.ids);
    setNames(data?.names);
  }, [props?.dashboardData]);

  return (
    <div
      id="chart"
      style={{
        width: "100%",
        overflowX: "scroll",
      }}
    >
      <ReactApexChart
        options={state.options}
        series={state.series}
        type="bar"
        height={410}
        // width={'100%'}
      />
    </div>
  );
}
