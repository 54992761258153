import { Box,TextField,Button} from '@mui/material'
import React,{useState,useEffect}from 'react'
import PopupForm from '../../Components/PopupForm'
import {useTranslation} from 'react-i18next'
import {ContainerOfInput,ContainerOfInputFields,ContainerOfSelectField} from '../../RegisteredCustomers/ThemDesign'
import { useSelector } from 'react-redux'
import ObjectOfErrors,{checkErrors,resetErrors,restCustomeObject} from '../../../Global/Errors/UnitStatuses/UnitStatuses'
import CloseIcon from '@mui/icons-material/Close';
import MaritalStatusAPI from '../../../Global/Network/MaritalStatus/index'
import {toast} from 'react-toastify'
import UnitStatusAPI from '../../../Global/Network/UnitStatuses/index'
import { TimePicker } from '@mui/x-date-pickers'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import ColorLensIcon from '@mui/icons-material/ColorLens';
import helpers from '../../../assets/js/helper'


const RenderContent=(props)=>{
    const [t]=useTranslation('common')
    const maintheme=useSelector(state=>state.themeData.maintheme);
    const [ErrorsObject,setErrorsObject]=useState(ObjectOfErrors)
    const handleChange=(e)=>{
       props.setName(e.target.value)
       setErrorsObject(restCustomeObject(ErrorsObject,'name'))
    }
    const clearInput=()=>{
       props.setName('')
       setErrorsObject(restCustomeObject(ErrorsObject,'name'))

    }
    const getTimeInMinuts=(date)=>{
       let timeInMin=helpers.formatTimeToMinutesSingle(date);
    //    console.log('')
        // alert(timeInMin)
       return timeInMin;
    }
    const handleChangeDuration=(date,errName)=>{
        props.setTimeInMinuts(date)
        props.setTimeInMinutsDuration(getTimeInMinuts(new Date(date)))
        setErrorsObject(restCustomeObject(ErrorsObject,errName))
     }
     const clearInputDuration=(errName)=>{
        props.setTimeInMinutsDuration(0)
        props.setTimeInMinuts(null)

        setErrorsObject(restCustomeObject(ErrorsObject,errName))
 
     }
     const handleColorChange=(e)=>{
        console.log('asdsadvv',e?.target?.value)
       props.setColor(e?.target?.value)
     }

    return(
        <Box className='Container-fluid'>
            <Box className="row">
                <Box className="col-12 col-sm-12 col-md-6 col-lg-4 ">
                    <ContainerOfInputFields
                            mainTheme={maintheme}
                            customeWidth={"100%"}
                            isForm={true}

                    >
                        <Box
                            sx={{
                            position: "relative",
                            // margin: "5px 0",
                            width: "100%",
                            backgroundColor: "#fff",
                            }}
                            className="mt-3 orderdata"
                        >
                            <TextField
                            id="standard-basic"
                            variant="filled"
                            label={t("UNITSTATUSESE.add_name")}
                            value={props?.name}
                            onChange={(e)=>handleChange(e)}
                            type={'text'}
                             focused
                            className={`${ErrorsObject?.name?.error?'errors':''}`}
                            />
                            {props?.name ? (
                            <CloseIcon
                            className="closeIcon"
                            onClick={()=>clearInput()}
                            />
                            ) : null}
                        </Box>
                        {
                        ErrorsObject?.name?.error&&ErrorsObject?.name?.message?.length?
                        <Box sx={{
                            height: 'fit-content',
                            padding:'5px',
                            display: 'flex',
                            flexDirection:'column',
                            flexWrap:'wrap',
                            width:'95%',
                        }}>
                            {
                            ErrorsObject?.name?.message&&ErrorsObject?.name?.message?.length>0?ErrorsObject?.name?.message?.map((messg)=>(
                            <span style={{fontFamily:'Cairo-Bold',fontSize:'14px',color:'red',height:'auto'}}>{messg}</span>
                            )):null
                            }
                        </Box>:null}
                    </ContainerOfInputFields>
                </Box>
                <Box className="col-12 col-sm-12 col-md-6  col-lg-4">
                    <ContainerOfInputFields
                            mainTheme={maintheme}
                            customeWidth={"100%"}
                            isForm={true}

                    >
                        <Box
                            sx={{
                            position: "relative",
                            // margin: "5px 0",
                            width: "100%",
                            backgroundColor: "#fff",
                            }}
                            className="mt-3 orderdata"
                        >
                            <TextField
                            id="standard-basic"
                            variant="filled"
                            label={t("UNITSTATUSESE.add_days")}
                            value={props?.days}
                            onChange={(e)=>{
                                if(!isNaN(e?.target?.value)){
                                    let value=e?.target?.value?.replace('.','')
                                     props?.setDays(value)
                                }
                            }}
                            type={'text'}
                             focused
                            className={`${ErrorsObject?.duration_in_mins?.error?'errors':''}`}
                            />
                            {props?.days ? (
                            <CloseIcon
                            className="closeIcon"
                            onClick={()=>{
                                props?.setDays(0)
                            }}
                            />
                            ) : null}
                        </Box>
                        {
                        ErrorsObject?.duration_in_mins?.error&&ErrorsObject?.duration_in_mins?.message?.length?
                        <Box sx={{
                            height: 'fit-content',
                            padding:'5px',
                            display: 'flex',
                            flexDirection:'column',
                            flexWrap:'wrap',
                            width:'95%',
                        }}>
                            {
                            ErrorsObject?.duration_in_mins?.message&&ErrorsObject?.duration_in_mins?.message?.length>0?ErrorsObject?.duration_in_mins?.message?.map((messg)=>(
                            <span style={{fontFamily:'Cairo-Bold',fontSize:'14px',color:'red',height:'auto'}}>{messg}</span>
                            )):null
                            }
                        </Box>:null}
                    </ContainerOfInputFields>
                </Box>
                <Box className="col-12 col-sm-12 col-md-6 col-lg-4 ">
                    <ContainerOfInputFields
                        mainTheme={maintheme}
                        customeWidth={"100%"}
                        hasError={ErrorsObject?.duration_in_mins?.error}
                        haswidth={true}
                    >
                        <Box
                        sx={{
                            position: "relative",
                            // margin: "5px 0",
                            width: "100%",
                            backgroundColor: "#fff",
                        }}
                        className="mt-3 orderdata"
                        >
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <TimePicker
                            label={t('UNITSTATUSESE.durations')}
                            // inputFormat="MM/DD/YYYY"
                            value={props?.timeInMinuts}
                            // minDate={new Date()}
                            ampm={false}
                            inputFormat="HH"
                            minutesStep={60}
                            inputProps={{
                                placeholder:'لم يتم التحديد'
                            }}
                            onChange={(date) => handleChangeDuration(date, 'duration_in_mins')}
                            renderInput={(params) => <TextField
                                {...params} variant="filled"
                                focused={props?.timeInMinuts ? true : false}
                                className={`${ErrorsObject?.duration_in_mins?.error ? 'errors' : ''}`}
                                inputProps={{
                                ...params.inputProps,
                                placeholder:'لم يتم التحديد'
                                }}
                            />}
                            />
                            {props?.timeInMinuts ? (
                            <CloseIcon
                                className="closeIcon"
                                onClick={() => clearInputDuration('duration_in_mins')}
                                sx={{
                                right: '37px !important',
                                top: '14px !important'
                                }}
                            />
                            ) : null}
                        </LocalizationProvider>

                        </Box>
                        {
                        ErrorsObject?.duration_in_mins?.error && ErrorsObject?.duration_in_mins?.message?.length ?
                        <Box sx={{
                        height: 'fit-content',
                        padding: '5px',
                        display: 'flex',
                        flexDirection: 'column',
                        flexWrap: 'wrap',
                        width: '95%',
                        }}>
                        {
                            ErrorsObject?.duration_in_mins?.message && ErrorsObject?.duration_in_mins?.message?.length > 0 ? ErrorsObject?.duration_in_mins?.message?.map((messg) => (
                            <span style={{ fontFamily: 'Cairo-Bold', fontSize: '14px', color: 'red', height: 'auto' }}>{messg}</span>
                            )) : null
                        }
                        </Box> : null}
                   </ContainerOfInputFields>
                </Box>
                
                <Box className="col-md-12 my-3">
                     <Box
                       sx={{
                        width:'100%',
                        display:'flex',
                        justifyContent:'center',
                        alignItems:'center',
                       }}
                     >
                        <Box
                          sx={{
                            width:'200px',
                            height:'200px',
                            borderRadius:'50%',
                            cursor:'pointer',
                            backgroundColor:props?.color?props?.color:'rgba(0,0,0,0.2)',
                            border:'2px dashed green',
                            display:'flex',
                            justifyContent:'center',
                            alignItems:'center',
                            position:'relative'
                          }}
                          onClick={()=>{
                            if(document.getElementById('colorPicker')){
                                document.getElementById('colorPicker').click();
                            }
                          }}
                        >
                           <ColorLensIcon color="#fff" sx={{
                            fontSize:'30px',
                            color:helpers.checkDarknessOrLightnessForColor(props?.color?props?.color:'#ffffff')?'#fff':'#000',
                            position:'absolute',
                            left:'50%',
                            top:'50%',
                            transform:'translate(-50%,-50%)',
                            zIndex:'234234'
                             }}/>
                           <input type="color" style={{
                              width:'100%',
                              height:'100%',
                              borderRadius:'50%',
                              visibility:'hidden',
                            }} id="colorPicker" onChange={(e)=>{
                                handleColorChange(e)
                            }} />
                        </Box>
                  
                     </Box>
                </Box>
                <Box className="col-12 col-sm-12 col-md-6"></Box>
            </Box>
        </Box>
    )
}

const RenderFooter=(props)=>{
    const [t]=useTranslation('common')
    const gridtheme=useSelector(state=>state.themeData.gridtheme)

    return(
        <Box sx={{
            display:'flex',
            alignItems:'center',
        }}>
          

              <Box sx={{display:'flex',justifyContent: 'end',alignItems: 'center',width:'100%'}}>
          <Box display="flex" >
              
                  <Button variant="outlined"
                      // spacing={2}
                      sx={{
                            width:'50% !important',
                              // flexGrow: 1, 
                              minWidth:'80px !important',
                              maxWidth:'80px !important',
                              margin: 1,
                              backgroundColor:'#f7f7f7',
                              borderColor:gridtheme?.colorWhite,
                              color:gridtheme?.colorblack,
                             boxShadow:'0 0 7px -2px white',
                            //   color:SearchButtonTheme?.clear_button_text_color,
                          '&:hover':{
                            backgroundColor:'#f7f7f7',
                            borderColor:gridtheme?.colorWhite,
                            color:gridtheme?.colorblack,
                             boxShadow:'0 0 7px 1px white',
                             boxShadow:'0',


                          },
                          height:'35px',
                          fontFamily:'Cairo-Bold'

                      }}
                      className="iconeFilterClear"
                      color="secondary"
                      onClick={()=>{
                        props.setOpen(false)
                    }}
                        >
                      {t('GLOBAL.close')}
                  </Button>
                  <Button
                      variant="contained"
                      // spacing={2} 
                      sx={{ 
                          // flexGrow: 1,
                          margin: 1,
                          width:'80px !important',
                          minWidth:'80px !important',
                          maxWidth:'80px !important',
                          '&:hover':{
                            //   backgroundColor:SearchButtonTheme?.search_button_color+'88',
                          },
                          height:'35px',
                          fontFamily:'Cairo-Bold'
                      }}
                      className="iconeFilterSearch"
                      onClick={() => {
                            props.submit()
                      }} 
                      >
                      {t('GLOBAL.Save')}
                  </Button>
          </Box>
      </Box>
        </Box>
    )
}


export default function AddFormDailog(props) {
    const [t]=useTranslation('common');
    const [name,setName]=useState(null)
    const [loading,setLoading]=useState(false)
    const [ErrorsObject,setErrorsObject]=useState(ObjectOfErrors)
    const [timeInMinuts,setTimeInMinuts]=useState(null);
    const [timeInMinutsDuration,setTimeInMinutsDuration]=useState(null);
    const [color,setColor]=useState('');
    const [days,setDays]=useState(0)

    

    useEffect(()=>{
        if(props?.object&&props?.object?.id){
            let dumData=resetErrors(ErrorsObject)
           setName(props?.object?.name)
           setColor(props?.object?.color?props?.object?.color:'')

           let getFormatedDuration=helpers.getFormatTimeCounDown(props?.object?.duration_in_mins*60)
           let seconds=(props?.object?.duration_in_mins*60)-(getFormatedDuration?.days_without_text*24*3600)
           
           setTimeInMinutsDuration(Math.floor(seconds/60))
           setDays(getFormatedDuration?.days_without_text)

           setTimeInMinuts(helpers.refomrmatMinutesToHours(props?.object?.duration_in_mins?Math.floor(seconds/60):0))
        }else{
            let dumData=resetErrors(ErrorsObject)
            clearForm()
        }
    },[props?.object])

    const handleMergeDaysWithMinuts=()=>{
        return (days * 24 * 60)+timeInMinutsDuration;
    }

    const submit=async()=>{
        if(!color){
            toast.warn(t('UNITSTATUSESE.colorRequired'))
            return false;
        }
     try{
        setLoading(true)
        let dumData=resetErrors(ErrorsObject)
        let data={
            name:name,
            duration_in_mins:handleMergeDaysWithMinuts(),
            color:color,
        }
        let result=null;
        if(props?.object&&props?.object?.id){
            data={
                ...data,
                id:props?.object?.id,
                _method:'put'
            }
             result=await UnitStatusAPI.updateUnitStatus({
            data:data
            });
        }else{
            result=await UnitStatusAPI.addUnitStatus({
                data:data
            });
        }
  
        if(result?.status){
          setLoading(false)
          toast.success(t('NETWORKMESSAGE.addCustomerMessageSuccess'))
          clearForm()
          props.loadData()
          props.setAddForm(false)
        }else{
          
           if (typeof result.errorMessage === "object") {
              let updatedObject = resetErrors(ErrorsObject)
              setErrorsObject(checkErrors(result.errorMessage, updatedObject))   
          }
          setLoading(false)
          toast.error(t('NETWORKMESSAGE.messageError'))
        }
        setLoading(false)
     }catch(error){
        setLoading(false)
        toast.error(error?.message)
     }
        
    }

    const clearForm=()=>{
        setName('')
        setTimeInMinuts(null)
        setTimeInMinutsDuration(0)
        setColor('')
        setDays(0)
    }

    useEffect(()=>{
        if(!props?.object)
          clearForm()
    },[props?.addForm,props?.object])

  return (
       <PopupForm 
        open={props.addForm} 
        setOpen={props.setAddForm}
        title={props?.object?t('UNITSTATUSESE.updateTitle'):t('UNITSTATUSESE.addTitle')}
        content={<RenderContent
             name={name} setName={setName}
             timeInMinutsDuration={timeInMinutsDuration}
             setTimeInMinutsDuration={setTimeInMinutsDuration}
             setTimeInMinuts={setTimeInMinuts}
             timeInMinuts={timeInMinuts}
             setColor={setColor}
             color={color}
             setDays={setDays}
             days={days}
        open={props.addForm} 
        setOpen={props.setAddForm}
        />}
        footer={<RenderFooter
            open={props.addForm} 
            setOpen={props.setAddForm}
            submit={submit}
        />}
       />
  )
}
