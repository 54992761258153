import React from 'react'
import {Typography} from '@mui/material'
import './style.css'

export default function CustomeTYpography(props) {
  return (
    <Typography
      sx={{
        // fontSize:{xxl:'35px !important',xl:'31px !important',lg:'25px !important',md:'24px !important',sm:'31px !important',xs:'35px !important'},
        ...props.sx,
      }}
      className={props?.className}
    >
        {props.children}
    </Typography>
  )
}
