import React, { useState, useEffect } from 'react'
import { Box, colors, IconButton, Typography, MenuItem, ListItemIcon, Tooltip } from '@mui/material'
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import { FaTrashAlt } from 'react-icons/fa';
import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import CustomCheckBox from './CustomCheckBox';
import { useTranslation } from 'react-i18next'
import DropDownGrid from './DropDownGrid'
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import AppsIcon from '@mui/icons-material/Apps';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import CoPresentIcon from '@mui/icons-material/CoPresent';
import StorefrontIcon from '@mui/icons-material/Storefront';
import helpers from '../../assets/js/helper';

const CustomTreeViewRow = (props) => {
    const [t] = useTranslation("common");
    const navigate = useNavigate()
    const [userRolesHere, setUserRolesHere] = useState([])
    const isDisable = () => {
        if (props?.row?.company_id && props?.row?.blocks?.length === 0) {
           
            return true
        }
        else if (props?.row?.compound_id && props?.row?.buildings?.length === 0) {
            
            return true
        }
        else if (props?.row?.block_id && props?.row?.floors?.length === 0) {
           
            return true
        }
        else if (props?.row?.building_id) {
            
            return true
        }
        return false

    }
    const getColor = () => {
        if (props?.row?.company_id) {
           
            return "#1E6A99"
        }
        else if (props?.row?.compound_id) {
          
            return "#054164"
        }
        else if (props?.row?.block_id) {
            
            return "#456D82"
        }
        else if (props?.row?.building_id) {
        
            return "#6C8D9E"
        }
        return false
    }

    const getType = () => {
        if (props?.row?.company_id) {
            
            return "blocks"
        }
        else if (props?.row?.compound_id) {
            
            return "buildings"
        }
        else if (props?.row?.block_id) {
            
            return "floors"
        }
        else if (props?.row?.building_id) {
            
            return "units"
        }
        return ''
    }
    const getCurrentType = () => {
        if (props?.row?.company_id) {
            
            return "compounds"
        }
        else if (props?.row?.compound_id) {
           
            return "blocks"
        }
        else if (props?.row?.block_id) {
           
            return "buildings"
        }
        else if (props?.row?.building_id) {
            
            return "floors"
        }
        return ''
    }
    const getRoleCheck = (index) => {
        let type = getCurrentType()
        
        if (props?.userRolesHere?.length > 0) {
            if (type == 'compounds') {
                return props?.userRolesHere[0][index]
            } else if (type == 'blocks') {
                return props?.userRolesHere[1][index]
            } else if (type == 'buildings') {
                return props?.userRolesHere[2][index]
            } else if (type == 'floors') {
                return props?.userRolesHere[3][index]
            }
        }
        return false;
    }
    useEffect(() => {
        setUserRolesHere(props?.setUserRolesHere)
        return () => {

        };
    }, [props?.userRolesHere]);

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                marginBottom: '10px',
                backgroundColor: getColor(),
                borderRadius: '5px'
            }}>



            <CustomCheckBox
                checked={props?.row?.checked}
                setChecked={props?.setCheckedRow}
                disabled={isDisable()}
            />
            <Box sx={{
                flex: 1,
                maxHeight: '52px',
                minHeight: '52px',
                // backgroundColor: '#FFFFFF',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                paddingLeft: '10px',
                paddingRight: '10px'

            }}>
                <Typography sx={{
                    color: '#FFFFFF',
                    fontFamily: 'Cairo'

                }}>
                    {props?.row?.name}
                </Typography>
                <Box sx={{

                    display: 'flex',
                    alignItems: 'center',

                    paddingLeft: '10px',
                    paddingRight: '10px'

                }}>
                    <Box
                      sx={{
                        display:'flex',
                        justifyContent:'end',
                        alignItems:'center'
                      }}
                    >
                        <Box
                            sx={{
                                display:'flex',
                                justifyContent:'end',
                                alignItems:'center',
                                margin:'0 5px',
                                border:'1px solid #fff',
                                padding:'0px 5px',
                                borderRadius:'10px',
                                '& .MuiButtonBase-root':{
                                    padding:'4px 2px !important'
                                }
                            }}
                        >
                            <Tooltip title={<span style={{fontFamily:'Cairo-Bold'}}>{t('GLOBAL.allUnit')}</span>} arrow>
                                <IconButton>
                                    <AppsIcon  sx={{color:"#fff"}}/>
                                </IconButton>
                            </Tooltip>
                            <span style={{fontFamily:'Cairo',color:'#fff',fontSize:'18px'}}>{
                            helpers.getFormatedPriceV2(props?.row?.all_units_count,0,1)
                            }</span>
                        </Box>

                        <Box
                         sx={{
                            display:'flex',
                            justifyContent:'end',
                            alignItems:'center',
                            margin:'0 5px',
                            border:'1px solid #fff',
                            padding:'0px 5px',
                            borderRadius:'10px',
                            '& .MuiButtonBase-root':{
                                padding:'4px 2px !important'
                            }
                         }}
                        >
                            <Tooltip title={<span style={{fontFamily:'Cairo-Bold'}}>{t('GLOBAL.soldUnit')}</span>} arrow>
                                <IconButton>
                                    <ShoppingBasketIcon  sx={{color:"#fff"}}/>
                                </IconButton>
                            </Tooltip>
                            <span style={{fontFamily:'Cairo',color:'#fff',fontSize:'18px'}}>{
                            helpers.getFormatedPriceV2(props?.row?.sold_units_count,0,1)
                            }</span>
                        </Box>

                        <Box
                         sx={{
                            display:'flex',
                            justifyContent:'end',
                            alignItems:'center',
                            margin:'0 5px',
                            border:'1px solid #fff',
                            padding:'0px 5px',
                            borderRadius:'10px',
                            '& .MuiButtonBase-root':{
                                padding:'4px 2px !important'
                            }
                         }}
                        >
                            <Tooltip title={<span style={{fontFamily:'Cairo-Bold'}}>{t('GLOBAL.reservedUnit')}</span>} arrow>
                                <IconButton>
                                    <CoPresentIcon  sx={{color:"#fff"}}/>
                                </IconButton>
                            </Tooltip>
                            <span style={{fontFamily:'Cairo',color:'#fff',fontSize:'18px'}}>{
                            helpers.getFormatedPriceV2(props?.row?.reserved_units_count,0,1)
                            }</span>
                        </Box>

                        <Box
                         sx={{
                            display:'flex',
                            justifyContent:'end',
                            alignItems:'center',
                            margin:'0 5px',
                            // backgroundColor:'#d1d1d1e0',
                            border:'1px solid #fff',
                            padding:'0px 5px',
                            borderRadius:'10px',
                            '& .MuiButtonBase-root':{
                                padding:'4px 2px !important'
                            }

                         }}
                        >
                            <Tooltip title={<span style={{fontFamily:'Cairo-Bold'}}>{t('GLOBAL.freeUnit')}</span>} arrow>
                                <IconButton>
                                    <StorefrontIcon sx={{color:"#fff"}}/>
                                </IconButton>
                            </Tooltip>
                            <span style={{fontFamily:'Cairo',color:'#fff',fontSize:'18px'}}>{
                            helpers.getFormatedPriceV2(props?.row?.free_units_count,0,1)
                            }</span>
                        </Box>

                    </Box>
                    <DropDownGrid>
                        {!getCurrentType().includes('floors') && (getRoleCheck(1) && getRoleCheck(1)?.add) ?
                            <MenuItem onClick={() => { props?.addEntity({ entity: props?.row, type: getType() }) }} className="ActionIcons">
                                <ListItemIcon sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    margin: '0px 7px 0 0'
                                }}>
                                    <AddIcon style={{ fill: "#009900" }} />

                                </ListItemIcon>
                                {t(getCurrentType()?.toUpperCase() + '.action_add')}
                            </MenuItem> :
                            <MenuItem onClick={() => { 
                               
                                navigate("/admin/masters/units", {
                                    state: {
                                      data: {
                                        floor: props?.row,
                                      },
                                    },
                                  });
                            }} className="ActionIcons">
                                <ListItemIcon sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    margin: '0px 7px 0 0'
                                }}>
                                    <VisibilityIcon style={{ fill: "#1E6A99" }} />

                                </ListItemIcon>
                                {t('UNITS.view_units')}
                            </MenuItem>
                        }
                        {
                            (getRoleCheck(2) && getRoleCheck(2)?.update) ?
                                <MenuItem onClick={() => {
                                    props?.editEntity({
                                        entity: props?.row,
                                        type: getCurrentType()
                                    })
                                    

                                }} className="ActionIcons">
                                    <ListItemIcon sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        margin: '0px 7px 0 0'
                                    }}>
                                        <EditIcon style={{ fill: "#1E6A99" }} />

                                    </ListItemIcon>
                                    {t(getCurrentType()?.toUpperCase() + '.action_edit')}
                                </MenuItem> : null
                        }
                        {
                            (getRoleCheck(3) && getRoleCheck(3)?.delete) ?
                                <MenuItem onClick={() => {
                                    props?.deleteObject({ entity: props?.row, type: getCurrentType() })
                                }} className="ActionIcons">
                                    <ListItemIcon sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        margin: '0px 7px 0 0'
                                    }}>
                                        <DeleteIcon style={{ fill: "#f00" }} />
                                    </ListItemIcon>
                                    {t(getCurrentType()?.toUpperCase() + '.action_delete')}

                                </MenuItem> : null
                        }
                        {
                           (!getRoleCheck(2) || !getRoleCheck(2)?.update) ?
                                <MenuItem onClick={() => {
                                    props?.viewObject({ entity: props?.row, type: getCurrentType() })
                                }} className="ActionIcons">
                                    <ListItemIcon sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        margin: '0px 7px 0 0'
                                    }}>
                                        <RemoveRedEyeIcon style={{ fill: "#1e6a99" }} />
                                    </ListItemIcon>
                                    {t(getCurrentType()?.toUpperCase() + '.action_view')}

                            </MenuItem> : null
                        }
                        {
                            getCurrentType().includes('floors') && (!getRoleCheck(1) || !getRoleCheck(1)?.add)
                                && (!getRoleCheck(3) || !getRoleCheck(3)?.delete) || !props?.userRolesHere ?
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        padding: '10px',
                                        fontFamily: 'Cairo'
                                    }}
                                >
                                    {t('GLOBAL.noData')}
                                </Box>
                                : null
                        }

                    </DropDownGrid>

                    {/* <IconButton onClick={() => { props?.addEntity({ entity: props?.row, type: getType() }) }} >
                        <AddIcon sx={{ color: "#FFFFFF" }} />
                    </IconButton>
                    <IconButton onClick={() => { props?.editEntity(props?.row) }} >
                        <EditIcon sx={{ color: "#FFFFFF" }} />
                    </IconButton>
                    <IconButton onClick={() => { props?.deleteObject(props?.row) }}>
                        <FaTrashAlt style={{ color: "#FFFFFF" }} />
                    </IconButton> */}
                </Box>
            </Box>
        </Box>
    )
}

export default CustomTreeViewRow