import callAPI from '../ApiConfig'
import store from '../../../app/store'

const customers = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"customer_types",
        method:"get",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
    
}
const customerList = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"customer_types_list",
        method:"get",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
    
}
const addCustomerType = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"customer_types",
        method:"post",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        },
        data:props?.data,
    })
    
}
const updateCustomerType = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"customer_types/"+props?.data?.id,
        method:"put",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        },
        data:props?.data
    })
    
}
const deleteCustomer = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"customer_types/"+props?.data?.id,
        method:"delete",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        },
    })
    
}
const CustomerTypeAPI={
    Customers:customers,
    CustomerList:customerList,
    addCustomerType:addCustomerType,
    updateCustomerType:updateCustomerType,
    deleteCustomer:deleteCustomer

}
export default CustomerTypeAPI