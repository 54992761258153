import React, { useState, useEffect, useMemo } from 'react'
import Grid from '../../Grid/Grid'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import DropDownGrid from '../../Grid/Components/DropDownGrid'
import { Box, MenuItem, ListItemIcon, Typography, Divider, Autocomplete, TextField, Tooltip } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddFormDailog from './AddFormDailog';
import swal from 'sweetalert';
import SearchInput from '../../Components/SearchInput';
import SearchDate from '../../Components/SearchDate';
import EmployeeAPI from '../../../Global/Network/Employee/index'
import helpers from '../../../assets/js/helper';
import { useGlobalContext } from '../../../context'
import * as FaIcons from 'react-icons/fa'
import PaymentTypeAPI from '../../../Global/Network/PaymentType/index';
import RolesOfUserAPI from '../../../Global/Network/RoleUser/index';

const RenderSearchContent = (props) => {
  const [t] = useTranslation('common')
  console.log('user', props?.users)
  return (
    <Box sx={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: 'column',
      width: '100% !important',
    }}>
      <SearchInput
        value={props?.objectName}
        setValue={props?.setobjectName}
        title={t('WORKTYPE.work_name_search')}
      />
      <SearchDate
        value={props?.fromDate}
        setValue={props?.setFromDate}
        title={t('WORKTYPE.work_fromDate_search')}
        minDate={null}
        maxDate={props?.toDate}
      />
      <SearchDate
        value={props?.toDate}
        setValue={props?.setToDate}
        title={t('WORKTYPE.work_toDate_search')}
        minDate={props?.fromDate}
        maxDate={null}
      />
      <Autocomplete
        sx={{
          ml: 1.5, mt: 1, mb: 1, width: '95%',
          '& .MuiFormLabel-root,& .MuiInputBase-input': {
            fontFamily: 'Cairo-Medium'
          }
        }}
        options={props.users ? props.users : []}
        getOptionLabel={(option) => option?.user && option?.user?.full_name || ""}
        id="disable-close-on-select"
        value={props?.createdBy}
        onChange={(e, v, r) => { props.setCreatedBy(v ? v : ''); }}
        renderInput={(params) => (
          <TextField {...params}
            placeholder={t('WORKTYPE.SEARCH_USERS')}
            variant="standard" sx={{ fontFamily: 'Cairo-Medium' }} />
        )}
      />

    </Box>
  )
}

export default function PaymentType() {
  const [t] = useTranslation("common");
  const [rows, setRows] = useState([])
  const [pageSize, setPageSize] = useState(5);
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(false)
  const [pageCount, setPageCount] = useState(0)
  const [openFilterColumn, setOpenFilterColumn] = useState(false)
  const [openFilterColumnValue, setOpenFilterColumnValue] = useState([])
  const [elementClicked, setElementClicked] = useState(null)
  const [addForm, setAddForm] = useState(false)
  const [selectedObject, setselectedObject] = useState(null)
  const [fromDate, setFromDate] = useState(null)
  const [toDate, setToDate] = useState(null)
  const [objectName, setobjectName] = useState(null)
  const [createdBy, setCreatedBy] = useState(null)
  const [users, setUsers] = useState([])
  const { exportToCSV } = useGlobalContext()
  const [filterParams,setFilterParams]=useState({})
  const [userRolesHere, setUserRolesHere] = useState([
    {
      view: true,
      id: 197
    },
    {
      add: false,
      id: 198,
    },
    {
      update: false,
      id: 199,
    },
    {
      delete: false,
      id: 200,
    }, {
      export: false,
      id: 201
    }
  ])


  const handlePageChange = (newPage) => {
    setPageNumber(newPage + 1);
  };
  const controller = new AbortController();

  const [rowsTotal, setRowsTotal] = useState(0)
  const screenwidth = useSelector(state => state.settingsData.screenwidth)
  const columns = useMemo(() => [
    { headerName: t('PAYMENT_TYPE.id'), field: 'id', flex: 0.5, minWidth: 50, maxWidth: 150, hide: false },
    {
      headerName: t('PAYMENT_TYPE.status'), field: 'status', flex: 0.5, minWidth: 40, maxWidth: 40, hide: false,
      renderHeader: (rows) => (
        <Box>

        </Box>
      ),
      renderCell: (rows) => (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {
            <Tooltip
              title={
                <span style={{ fontFamily: 'Cairo-Medium' }}>
                  {rows?.row?.unit_status?.name ? t('PAYMENT_TYPE.active') : t('PAYMENT_TYPE.in_active')}
                </span>
              }
            >
              <span

                style={{
                  fontFamily: 'Cairo-Medium',
                  width: '10px',
                  height: '10px',
                  borderRadius: '50%',
                  backgroundColor: rows?.row?.enable ? "lightgreen"  : 'darkred',
                }} className="gridHeaderItem">{
                  // rows?.row?.customer?.user?.full_name?rows?.row?.customer?.user?.full_name:t('GLOBAL.notAssignedYet')
                }</span>
            </Tooltip>}

        </Box>
      )
    },
    { headerName: t('PAYMENT_TYPE.name'), field: 'name', flex: 2, minWidth: 250, maxWidth: 450, hide: false },
    {
      headerName: t('PAYMENT_TYPE.totalPaymentNumber'), field: 'totalPaymentNumber', flex: 2, minWidth: 250, maxWidth: 450, hide: false,
      renderCell: (rows) => (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <span style={{ fontFamily: 'Cairo-Medium' }} className="gridHeaderItem">{rows?.row?.payment_type_details?.length}</span>

        </Box>
      )
    },
    {
      headerName: t('PAYMENT_TYPE.actions'), field: 'Actions', flex: 0.4, minWidth: 100, maxWidth: 250, hide: false,
      renderCell: (rows) => (
        <DropDownGrid className="containerOFDropDownToolTipeGrid">
          {
            userRolesHere[2]?.update ?
              <MenuItem onClick={() => { updateFunction(rows?.row) }} className="ActionIcons">
                <ListItemIcon sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  margin: '0px 7px 0 0'
                }}>
                  <EditIcon style={{ fill: "#1E6A99" }} />

                </ListItemIcon>
                {t('GLOBAL.action_edit')}
              </MenuItem> : null
          }
          {
            userRolesHere[3]?.delete ?
              <MenuItem onClick={() => { deleteFunction(rows?.row) }} className="ActionIcons">
                <ListItemIcon sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  margin: '0px 7px 0 0'
                }}>
                  <DeleteIcon style={{ fill: "#f00" }} />
                </ListItemIcon>
                {t('GLOBAL.action_delete')}

              </MenuItem> : null
          }
          {
            userRolesHere[2]?.update == false && userRolesHere[3]?.delete == false ?
              <p style={{ fontFamily: 'Cairo', margin: '5px 20px' }}>{t('GLOBAL.emptyPoPup')}</p>
              : null
          }

        </DropDownGrid>
      ),
    },
  ], [userRolesHere])
  useEffect(() => {
    let abortController = new AbortController();
    loadData()
    return () => {
      abortController.abort();
    }
  }, [pageNumber, pageSize])

  useEffect(() => {
    let abortController = new AbortController();
    console.log('saldkjaskd', 1);
    getUserRoles()
    loadDataOfUsers()
    return () => {
      abortController.abort();
    }
  }, [])

  const loadData = async (searchParams) => {
    setLoading(true)
    const filterParamsData={
      ...filterParams,
      ...searchParams
     }
    const result = await PaymentTypeAPI.PaymentType({
      params: {
        page_size: pageSize,
        page: pageNumber,
        ...filterParamsData
      },
      signal: controller?.signal
    })

    setLoading(false)
    if (result.status) {
      setRows(result?.data?.data)
      setRowsTotal(result?.data?.total)
      setPageCount(result?.data?.last_page)
    }
    else {
      if (!result?.data?.error) {
        toast.error(t('NETWORKMESSAGE.messageError'))
        return
      }
    }

  }
  const loadDataOfUsers = async (searchParams) => {
    setLoading(true)

    const result = await EmployeeAPI.EmployeeList({
      signal: controller?.signal
    })

    setLoading(false)
    if (result.status) {
      setUsers(result?.data)
    }
    else {
      if (!result?.data?.error) {
        toast.error(t('NETWORKMESSAGE.messageError'))
        return
      }
    }

  }
  const createFUnction = () => {
    setselectedObject(null)
    setAddForm(true)
  }
  const updateFunction = (obj) => {
    setselectedObject(obj)
    setAddForm(true)
  }
  const deleteFunction = async (obj) => {
    swal({
      title: '?هل انت متأكد',
      text: "لن تتمكن من التراجع عن هذا!",
      icon: 'warning',
      buttons: ["الغاء!", "نعم!"],
      dangerMode: true,
    }).then(async (willDelete) => {
      console.log('1')
      if (willDelete) {
        console.log('2')

        try {
          setLoading(true)

          let result = null;
          if (obj && obj?.id) {
            console.log('3')

            result = await PaymentTypeAPI.deletePaymentType({
              data: {
                id: obj.id,
              }
            });
          }

          if (result?.status) {
            setLoading(false)
            // toast.success(t('NETWORKMESSAGE.deleteSuccess'))
            swal(`${t('NETWORKMESSAGE.deleteSuccess')}`, {
              icon: "success",
            });
            loadData({
              page: pageNumber
            })
          } else {

            setLoading(false)
            if(result?.errorMessage){
              toast.error(result?.errorMessage)
            }else
            toast.error(t('NETWORKMESSAGE.deleteErrorr'))
          }
          setLoading(false)
        } catch (error) {
          setLoading(false)
          toast.error(t(error?.message))
        }
      } else {
        // alert('canceled')
      }
    })
  }
  const clearFunction = () => {
    setobjectName('')
    setFromDate(null)
    setToDate(null)
    setCreatedBy(null)
    setFilterParams({})

  }
  const searchFunction = () => {
    let data = {};
    if (objectName) {
      data = {
        ...data,
        name: objectName
      }
    }
    if (fromDate) {
      data = {
        ...data,
        creation_date_from: helpers.formatDate(fromDate)
      }
    }
    if (toDate) {
      data = {
        ...data,
        creation_date_to: helpers.formatDate(toDate)
      }
    }
    if (createdBy && createdBy?.user && createdBy.user?.id) {
      data = {
        ...data,
        created_by_id: createdBy.user?.id
      }
    }
    setFilterParams(data)

    loadData(data)
  }
  const loadDataForExport = async (searchParams) => {
    let result = await PaymentTypeAPI.PaymentType({
      params: {
        page_size: rowsTotal,
        ...searchParams
      },
      signal: controller?.signal
    })
    return result;
  }
  const handleGetFormatedData=(data)=>{
      console.log('asdasdasdasd',data)
      let arr=[]
      let count=1;
       data&&data?.length>0&&data?.map((itm,index)=>{

         if(itm?.payment_type_details&&itm?.payment_type_details?.length>0)
            itm.payment_type_details?.map((itm2,index2)=>{
              arr.push({
                ...itm,
                ...itm2,
                id:count++,
              })
            })
          else
          arr.push({
            ...itm,
            id:count++
          })
       })
      return arr
  }
  const ExportFunction = async () => {
    let filteredData = {}
    if (objectName) {
      filteredData = {
        ...filteredData,
        name: objectName
      }
    }
    if (fromDate) {
      filteredData = {
        ...filteredData,
        creation_date_from: helpers.formatDate(fromDate)
      }
    }
    if (toDate) {
      filteredData = {
        ...filteredData,
        creation_date_to: helpers.formatDate(toDate)
      }
    }
    if (createdBy && createdBy?.id) {
      filteredData = {
        ...filteredData,
        created_by_id: createdBy?.id
      }
    }

    const fetchedData = await loadDataForExport(filteredData);
    if (!fetchedData || !fetchedData?.status) {
      toast.error("لا يمكن استرجاع البيانات")
      return
    }
    let dataList=await handleGetFormatedData(fetchedData?.data?.data)
    let arr = [];
    dataList && dataList?.map((itm) => {
      arr.push({
        [`${t('PAYMENT_TYPE.id')}`]: itm?.id,
        [`${t('PAYMENT_TYPE.name')}`]: itm?.name,
        [`${t('PAYMENT_TYPE.status')}`]: itm?.enable==1?t('PAYMENT_TYPE.enable'):t('PAYMENT_TYPE.disable'),
        [`${t('PAYMENT_TYPE.expiry_date')}`]: itm?.expiry_date?helpers.formatDate(itm?.expiry_date):'',
        [`${t('PAYMENT_TYPE.value')}`]: itm?.value,
        [`${t('PAYMENT_TYPE.percentage_absolute')}`]: itm?.percentage_absolute==0?t('PAYMENT_TYPE.isPercentage'):t('PAYMENT_TYPE.isNotPercentage'),
        [`${t('PAYMENT_TYPE.days_duration_from_contract')}`]: helpers?.refomrmatMinutesToHours(itm?.days_duration_from_contract),
        [`${t('PAYMENT_TYPE.created_at')}`]: itm?.created_at ? helpers.formatDate(itm?.created_at) + " " + helpers.formatTime(itm?.created_at) : '0000-00-00 00:00:00',
      })
    })
    exportToCSV(arr, 'PAYMENT_TYPE')

  }
  const getUserRoles = async () => {
    // setLoading(true)
    console.log('saldkjaskd', 2);
    try {
      let data = await RolesOfUserAPI.GetRolesOfUser();
      if (data && data?.status) {
        console.log('userRolesmmm', data?.data);
        if (data?.data && data?.data?.length > 0) {
          let arr = [];

          let checkHasRole = data?.data?.includes(userRolesHere[0]?.id)
          if (checkHasRole) {
            arr.push(
              {
                view: true,
                id: userRolesHere[0]?.id,
              }
            )
          } else {
            arr.push(
              {
                view: false,
                id: userRolesHere[0]?.id,
              }
            )
          }
          console.log('sassssss', userRolesHere[1]?.id);
          checkHasRole = data?.data?.includes(userRolesHere[1]?.id)
          if (checkHasRole) {
            arr.push(
              {
                add: true,
                id: userRolesHere[1]?.id,
              }
            )
          } else {
            arr.push(
              {
                add: false,
                id: userRolesHere[1]?.id,
              }
            )
          }
          checkHasRole = data?.data?.includes(userRolesHere[2]?.id)
          if (checkHasRole) {
            arr.push(
              {
                update: true,
                id: userRolesHere[2]?.id,
              }
            )
          } else {
            arr.push(
              {
                update: false,
                id: userRolesHere[2]?.id,
              }
            )
          }
          checkHasRole = data?.data?.includes(userRolesHere[3]?.id)
          if (checkHasRole) {
            arr.push(
              {
                delete: true,
                id: userRolesHere[3]?.id,
              }
            )
          } else {
            arr.push(
              {
                delete: false,
                id: userRolesHere[3]?.id,
              }
            )
          }
          checkHasRole = data?.data?.includes(userRolesHere[4]?.id)
          if (checkHasRole) {
            arr.push(
              {
                export: true,
                id: userRolesHere[4]?.id,
              }
            )
          } else {
            arr.push(
              {
                export: false,
                id: userRolesHere[4]?.id,
              }
            )
          }
          setUserRolesHere(arr)
        }
      }
      setLoading(false)
    } catch (err) {
      setLoading(false)
      console.log(err?.message);

    }
  }


  return (
    <Box sx={{
      // padding: '10px',
      // paddingTop: '20px',
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      width: '100%',
      justifyContent: 'center',
      alignItems: 'start',
      overflowX: 'hidden',

    }}
      className=" pt-3 mt-1"

    >
      <AddFormDailog object={selectedObject} addForm={addForm} setAddForm={setAddForm} loadData={loadData} />

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          height: '100%',
          justifyContent: 'center',
          alignItems: 'stretch',
          position: 'relative',
        }}
      >
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Typography
            variant='h4'
            sx={{
              marginLeft: '10px',
              marginBottom: '10px',
              fontFamily: 'Cairo-Bold',
              fontSize: '16px'
            }}>
            {t('PAYMENT_TYPE.title')}
          </Typography>
        </Box>

        <Box sx={{ marginRight: '20px', backgroundColor: '#1E6A99', color: '#FFFFFF', width: '42px', height: '40px', borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }}
          onClick={(event) => {
            // setOpenFilterColumn(true)
            // setElementClicked(event.currentTarget)
          }}
          id="demo-customized-button"
          aria-controls={openFilterColumn ? 'demo-customized-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={openFilterColumn ? 'true' : undefined}
          variant="contained"
        >
          {/* <ViewWeekIcon sx={{color:'#fffff'}}/> */}
          <FaIcons.FaHouseUser
            style={{ fontSize: '30px', color: '#fff' }}
          />
        </Box>

      </Box>

      <Divider
        sx={{
          backgroundColor: '#EBF2F7',
          margin: '30px',
          marginTop: '10px',
          width: '100%'
        }}
        style={{
          height: '3px',

        }}
      />

      <Grid
        rows={rows}
        columns={columns}
        setColumns={null}
        pageSize={pageSize}
        setPageSize={setPageSize}
        pageNumber={pageNumber}
        setPageNumber={setPageNumber}
        loading={loading}
        handlePageChange={handlePageChange}
        rowsTotal={rowsTotal}
        checkScreenSize={screenwidth}
        pageCount={pageCount}
        openFilterColumn={openFilterColumn}
        elementClicked={elementClicked}
        setOpenFilterColumn={setOpenFilterColumn}
        setColumnFilterValue={setOpenFilterColumnValue}
        columFilterValue={openFilterColumnValue}
        clearFunction={clearFunction}
        searchFunction={searchFunction}
        creatFunction={createFUnction}
        ExportFunction={ExportFunction}
        importFunction={null}
        mapFunction={null}
        telegramFunction={null}
        hasCreate={userRolesHere[1].add}
        hasImport={false}
        hasExport={userRolesHere[4].export}
        hasMap={false}
        hasTetelgram={false}
        filterChilds={<RenderSearchContent
          objectName={objectName}
          setobjectName={setobjectName}
          fromDate={fromDate}
          setFromDate={setFromDate}
          toDate={toDate}
          setToDate={setToDate}
          createdBy={createdBy}
          setCreatedBy={setCreatedBy}
          users={users}
        />}
      />

    </Box>
  )
}
