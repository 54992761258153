import { Box, Button, FormControlLabel, Switch } from '@mui/material'
import React, { useState, useEffect } from 'react'
import PopupForm from '../../Components/PopupForm'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import ObjectOfErrors, { checkErrors, resetErrors, restCustomeObject, ObjectMerge } from '../../../Global/Errors/PaymentType/PaymentType'
import { toast } from 'react-toastify'
import PaymentTypeAPI from '../../../Global/Network/PaymentType'
import CustomDateField from '../../../General/CustomComponents/CustomDateField'
import CustomTextField from '../../../General/CustomComponents/CustomTextField'
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import helpers from '../../../assets/js/helper'

const RenderContent = (props) => {
    const [t] = useTranslation('common')
    const mainTheme = useSelector(state => state.themeData.maintheme)
    const screenwidth = useSelector(state => state.settingsData.screenwidth);
    const addPaymentTypeDetail = () => {
        let data = {
            id: props?.paymentType?.paymentTypeDetails?.length > 0 ? Math.max.apply(Math, props?.paymentType?.paymentTypeDetails?.map(function (paymentTypeDetail) { return paymentTypeDetail?.id; })) + 1 : 1,
            percentage_absolute: {
                value: 0,
                error: false,
                message: [],
                required: false
            },
            value: {
                value: "",
                error: false,
                message: [],
                required: false
            },
            days_duration_from_contract: {
                value: "",
                error: false,
                message: [],
                required: false
            }
        }
        props?.setPaymentType({
            ...props?.paymentType,
            paymentTypeDetails: [
                ...props?.paymentType?.paymentTypeDetails,
                data
            ]
        })


    }
    const removeThisItem = (deletePaymentTypeDetail) => {

        props?.setPaymentType({
            ...props?.paymentType,
            paymentTypeDetails: props?.paymentType?.paymentTypeDetails?.filter(paymentTypeDetail => paymentTypeDetail?.id != deletePaymentTypeDetail?.id),
        })

    }
    return (
        <Box className='Container-fluid my-3'>
            <Box className="row">
                <Box className="col-12 col-sm-12 col-md-6 col-lg-6">
                    <CustomTextField
                        label={t('PAYMENT_TYPE.add_name')}
                        value={props?.paymentType?.name?.value}
                        error={props?.paymentType?.name?.error}
                        message={props?.paymentType?.name?.message}
                        haswidth={true}
                        readOnly={false}
                        focused={true}
                        onChange={(e) => {
                            props?.setPaymentType({
                                ...props?.paymentType,
                                name: {
                                    value: e?.target?.value,
                                    error: false,
                                    message: []
                                }
                            })
                        }}
                        onClearClick={() => {
                            props?.setPaymentType({
                                ...props?.paymentType,
                                name: {
                                    value: "",
                                    error: false,
                                    message: []
                                }
                            })
                        }}
                    />
                    <Box sx={{ margin: '10px 0' }}></Box>
                    <CustomTextField
                        label={t('PAYMENT_TYPE.description')}
                        value={props?.paymentType?.description?.value}
                        error={props?.paymentType?.description?.error}
                        message={props?.paymentType?.description?.message}
                        hasMultipleLine={true}
                        haswidth={true}
                        readOnly={false}
                        onChange={(e) => {
                            props?.setPaymentType({
                                ...props?.paymentType,
                                description: {
                                    value: e?.target?.value,
                                    error: false,
                                    message: []
                                }
                            })
                        }}
                        onClearClick={() => {
                            props?.setPaymentType({
                                ...props?.paymentType,
                                description: {
                                    value: "",
                                    error: false,
                                    message: []
                                }
                            })
                        }}
                    />
                    <Box sx={{ margin: '10px 0' }}></Box>
                </Box>
                <Box className="col-12 col-sm-12 col-md-6 col-lg-6">
                    <Box
                        sx={{
                            backgroundColor: '#fff',
                            boxShadow: '0 0 15px -1px rgba(0,0,0,0.2)',
                            padding: '7px 10px',
                            margin: '2px 10px',
                            '& .MuiTypography-root ': {
                                fontFamily: 'Cairo-Medium',
                                
                            },
                            
                          
                        }}
                    >
                        <FormControlLabel
                            sx={{
                                width:'fit-content',
                                fontFamily: 'Cairo-Medium',
                                
                            }}
                            control={<Switch
                                checked={props?.paymentType?.enable?.value}
                                onChange={(e) => {

                                    props?.setPaymentType({
                                        ...props?.paymentType,
                                        enable: {
                                            value: e.target.checked,
                                            error: false,
                                            message: []
                                        }
                                    })
                                }}
                            />} label={props?.paymentType?.enable?.value ? t('PAYMENT_TYPE.enable') : t('PAYMENT_TYPE.disable')} />

                    </Box>
                    <Box sx={{ margin: '20px 0' }}></Box>
                    <CustomDateField
                        label={t('PAYMENT_TYPE.expiry_date')}
                        value={props?.paymentType?.expiry_date?.value}
                        error={props?.paymentType?.expiry_date?.error}
                        message={props?.paymentType?.expiry_date?.message}
                        haswidth={true}
                        readOnly={false}
                        onChange={(e) => {
                            props?.setPaymentType({
                                ...props?.paymentType,
                                expiry_date: {
                                    value: e,
                                    error: false,
                                    message: []
                                }
                            })
                        }}
                        onClearClick={() => {
                            props?.setPaymentType({
                                ...props?.paymentType,
                                expiry_date: {
                                    value: "",
                                    error: false,
                                    message: []
                                }
                            })
                        }}
                    />
                </Box>
                <Box className="col-md-12 my-2 "
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'start',
                        alignItems: 'center',

                        width: '100%'
                    }}
                >
                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'start',
                            alignItems: 'center'
                        }}
                    >
                        <span style={{
                            textAlign: "left",
                            font: "normal normal  20px/37px Cairo",
                            letterSpacing: "0px",
                            color: "#707070",
                            paddingRight: '10px',
                            opacity: "1"
                        }}>{t('PAYMENT_TYPE.add_payment_type_details')}</span>
                    </Box>
                    <Box
                        sx={{
                            // borderRadius: '5px',
                            // border: `1px solid ${mainTheme?.iconColor}`,
                            padding: '10px',
                            width: '100%',
                            minHeight: '50px',

                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'start',
                                width: '100%',
                            }}
                        >
                            <AddCircleIcon className="iconAddRelationType" sx={{ fontSize: '30px', cursor: 'pointer', color: mainTheme?.iconColor }} onClick={addPaymentTypeDetail} />
                        </Box>

                        {
                            props?.paymentType?.paymentTypeDetails?.length > 0 && props?.paymentType?.paymentTypeDetails?.map((paymentTypeDetail, index) => (
                                <Box
                                    key={index + "_payment_type_detail"}
                                    sx={{
                                        width: '100%',
                                        display: 'flex',
                                        justifyContent: 'flex-start',
                                        alignItems: 'start',
                                    }}

                                >
                                    <RemoveCircleIcon className="iconAddRelationType" sx={{ fontSize: '28px', color: mainTheme?.iconColor, cursor: 'pointer', marginTop: '15px' }} onClick={() => removeThisItem(paymentTypeDetail)} />
                                    <Box sx={{
                                        width: '100%',
                                        display: 'flex',
                                        justifyContent: 'flex-start',
                                        alignItems: 'center',
                                        flexWrap: 'wrap'
                                    }}>


                                        <CustomTextField
                                            label={t('PAYMENT_TYPE.value')}
                                            customWidth={screenwidth < 800 ? "100%" : screenwidth < 1200 ? '50%' : '30%'}
                                            value={paymentTypeDetail?.value?.value}
                                            error={false}
                                            message={""}
                                            hasMultipleLine={true}
                                            haswidth={true}
                                            readOnly={false}
                                            onChange={(e) => {
                                                if (!isNaN(e?.target?.value) && !e?.target?.value?.includes('.')) {
                                                    props?.setPaymentType({
                                                        ...props?.paymentType,
                                                        paymentTypeDetails: props?.paymentType?.paymentTypeDetails?.map(currentPaymentTypeDetail => {
                                                            if (currentPaymentTypeDetail?.id == paymentTypeDetail?.id) {
                                                                if (!paymentTypeDetail?.percentage_absolute?.value && e.target.value > 100) {
                                                                    toast.warn(t('PAYMENT_TYPE.percentage_greater_than_100'))
                                                                }
                                                                else {
                                                                    return {
                                                                        ...paymentTypeDetail,
                                                                        value: {
                                                                            value: e.target.value,
                                                                            error: false,
                                                                            message: [],
                                                                            required: false

                                                                        }

                                                                    }
                                                                }
                                                            }
                                                            return currentPaymentTypeDetail
                                                        })
                                                    })
                                                }
                                            }}
                                            onClearClick={() => {
                                                props?.setPaymentType({
                                                    ...props?.paymentType,
                                                    paymentTypeDetails: props?.paymentType?.paymentTypeDetails?.map(currentPaymentTypeDetail => {
                                                        if (currentPaymentTypeDetail?.id == paymentTypeDetail?.id) {
                                                            return {
                                                                ...paymentTypeDetail,
                                                                value: {
                                                                    value: "",
                                                                    error: false,
                                                                    message: [],
                                                                    required: false

                                                                }

                                                            }
                                                        }
                                                        return currentPaymentTypeDetail
                                                    })
                                                })
                                            }}
                                        />
                                        <Box sx={{ margin: '10px 0' }}></Box>
                                        <CustomTextField
                                            customWidth={screenwidth < 800 ? "100%" : screenwidth < 1200 ? '50%' : '40%'}
                                            label={t('PAYMENT_TYPE.days_duration_from_contract')}
                                            value={paymentTypeDetail?.days_duration_from_contract?.value}
                                            error={false}
                                            message={""}
                                            hasMultipleLine={true}
                                            haswidth={true}
                                            readOnly={false}
                                            onChange={(e) => {
                                                if (!isNaN(e?.target?.value) && !e?.target?.value?.includes('.')) {
                                                    props?.setPaymentType({
                                                        ...props?.paymentType,
                                                        paymentTypeDetails: props?.paymentType?.paymentTypeDetails?.map(currentPaymentTypeDetail => {
                                                            if (currentPaymentTypeDetail?.id == paymentTypeDetail?.id) {
                                                                return {
                                                                    ...paymentTypeDetail,
                                                                    days_duration_from_contract: {
                                                                        value: e.target.value,
                                                                        error: false,
                                                                        message: [],
                                                                        required: false

                                                                    }

                                                                }
                                                            }
                                                            return currentPaymentTypeDetail
                                                        })
                                                    })
                                                }

                                            }}
                                            onClearClick={() => {
                                                props?.setPaymentType({
                                                    ...props?.paymentType,
                                                    paymentTypeDetails: props?.paymentType?.paymentTypeDetails?.map(currentPaymentTypeDetail => {
                                                        if (currentPaymentTypeDetail?.id == paymentTypeDetail?.id) {
                                                            return {
                                                                ...paymentTypeDetail,
                                                                days_duration_from_contract: {
                                                                    value: "",
                                                                    error: false,
                                                                    message: [],
                                                                    required: false

                                                                }

                                                            }
                                                        }
                                                        return currentPaymentTypeDetail
                                                    })
                                                })
                                            }}
                                        />

                                        <Box
                                            sx={{
                                                width: '130px',
                                                marginLeft: '10px',
                                                '& .MuiTypography-root ': {
                                                    fontFamily: 'Cairo-Medium',
                                                    
                                                }
                                            }}
                                        >
                                            <FormControlLabel
                                                sx={{
                                                    width:'140px !important',
                                                    fontFamily: 'Cairo-Medium',
                                                }}
                                                control={<Switch

                                                    checked={paymentTypeDetail?.percentage_absolute?.value}
                                                    onChange={(e) => {

                                                        props?.setPaymentType({
                                                            ...props?.paymentType,
                                                            paymentTypeDetails: props?.paymentType?.paymentTypeDetails?.map(currentPaymentTypeDetail => {
                                                                if (currentPaymentTypeDetail?.id == paymentTypeDetail?.id) {
                                                                    //TODO check percentage 100
                                                                    if (!e.target.checked && paymentTypeDetail?.value?.value > 100) {
                                                                        toast.warn(t('PAYMENT_TYPE.percentage_greater_than_100'))
                                                                    }
                                                                    else {
                                                                        return {
                                                                            ...paymentTypeDetail,
                                                                            percentage_absolute: {
                                                                                value: e.target.checked ? 1 : 0,
                                                                                error: false,
                                                                                message: [],
                                                                                required: false

                                                                            }
                                                                        }
                                                                    }

                                                                }
                                                                return currentPaymentTypeDetail
                                                            })
                                                        })
                                                    }}
                                                />} label={paymentTypeDetail?.percentage_absolute?.value ? t('PAYMENT_TYPE.absolute') : t('PAYMENT_TYPE.percentage')} />

                                        </Box>

                                    </Box>
                                </Box>
                            ))
                        }

                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

const RenderFooter = (props) => {
    const [t] = useTranslation('common')
    const gridtheme = useSelector(state => state.themeData.gridtheme)

    return (
        <Box sx={{
            display: 'flex',
            alignItems: 'center',
        }}>


            <Box sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center', width: '100%' }}>
                <Box display="flex" >

                    <Button variant="outlined"
                        // spacing={2}
                        sx={{
                            width: '50% !important',
                            // flexGrow: 1, 
                            minWidth: '80px !important',
                            maxWidth: '80px !important',
                            margin: 1,
                            backgroundColor: '#f7f7f7',
                            borderColor: gridtheme?.colorWhite,
                            color: gridtheme?.colorblack,
                            boxShadow: '0 0 7px -2px white',
                            //   color:SearchButtonTheme?.clear_button_text_color,
                            '&:hover': {
                                backgroundColor: '#f7f7f7',
                                borderColor: gridtheme?.colorWhite,
                                color: gridtheme?.colorblack,
                                boxShadow: '0 0 7px 1px white',
                                boxShadow: '0',


                            },
                            height: '35px',
                            fontFamily: 'Cairo-Bold'

                        }}
                        className="iconeFilterClear"
                        color="secondary"
                        onClick={() => {
                            props.setOpen(false)
                        }}
                    >
                        {t('GLOBAL.close')}
                    </Button>
                    <Button
                        variant="contained"
                        // spacing={2} 
                        sx={{
                            // flexGrow: 1,
                            margin: 1,
                            width: '80px !important',
                            minWidth: '80px !important',
                            maxWidth: '80px !important',
                            '&:hover': {
                                //   backgroundColor:SearchButtonTheme?.search_button_color+'88',
                            },
                            height: '35px',
                            fontFamily: 'Cairo-Bold'
                        }}
                        className="iconeFilterSearch"
                        onClick={() => {
                            props.submit()
                        }}
                    >
                        {t('GLOBAL.Save')}
                    </Button>
                </Box>
            </Box>
        </Box>
    )
}


export default function AddFormDailog(props) {
    const [t] = useTranslation('common');
    const [name, setName] = useState(null)
    const [loading, setLoading] = useState(false)
    const [paymentType, setPaymentType] = useState(ObjectOfErrors)


    useEffect(() => {
        if (props?.object && props?.object?.id) {
            let updatedObject = ObjectMerge(props?.object)
            setPaymentType(updatedObject)
        } else {
            setPaymentType(ObjectOfErrors)
        }
    }, [props?.object, props?.addForm])

    const submit = async () => {
        try {
            setLoading(true)
            let updatedObject = resetErrors(paymentType)

            if (!updatedObject?.paymentTypeDetails?.length) {
                toast.error(t('PAYMENT_TYPE.no_payment_type_details'))
                return
            }
            else {
                for (let index = 0; index < updatedObject?.paymentTypeDetails.length; index++) {
                    const paymentType = updatedObject?.paymentTypeDetails[index];
                    if (paymentType?.value == "" || paymentType?.value == 0) {
                        toast.error(t('PAYMENT_TYPE.no_value_for_payment_type_detail'))
                        return
                    }
                    // if(paymentType?.days_duration_from_contract=="")
                    // {
                    //     console.log(paymentType)
                    //     toast.error(t('PAYMENT_TYPE.no_days_duration_for_payment_type_detail'))
                    //     return 
                    // }

                }
            }

            let paymentTypeDetails = updatedObject?.paymentTypeDetails?.sort((a, b) => a?.days_duration_from_contract - b?.days_duration_from_contract)

            let total = 0
            let hasAbsolute = false
            paymentTypeDetails?.map(paymentTypeDetail=>{
                if(!paymentTypeDetail?.percentage_absolute?.value)
                {
                    try {
                        total += parseFloat(paymentTypeDetail?.value?.value)
                    } catch (error) {
                        
                    }
                    
                    
                    
                }
                else{
                    hasAbsolute = true
                }
            })
            console.log("ttttttttttttttttttttttttotal",total);
            if(total>100)
            {
                toast.warn(t('PAYMENT_TYPE.percentage_greater_than_100'))
                return
            }

            if(!hasAbsolute && total!=100)
            {
                toast.warn(t('PAYMENT_TYPE.percentage_should_be_100'))
                return
            }

            let data = {
                name: updatedObject?.name?.value,
                description: updatedObject?.description?.value,
                enable: updatedObject?.enable?.value,
                expiry_date: updatedObject?.expiry_date?.value ? helpers.formatDate(updatedObject?.expiry_date?.value) : "",
                payment_type_details: paymentTypeDetails?.map(paymentTypeDetail => {
                    return {
                        percentage_absolute: paymentTypeDetail?.percentage_absolute?.value,
                        value: paymentTypeDetail?.value?.value,
                        days_duration_from_contract: paymentTypeDetail?.days_duration_from_contract?.value
                    }
                })
            }

            let result = null;
            if (props?.object && props?.object?.id) {
                data = {
                    ...data,
                    id: props?.object?.id,
                    _method: 'put'
                }
                result = await PaymentTypeAPI.updatePaymentType({
                    data: data
                });
            } else {
                result = await PaymentTypeAPI.addPaymentType({
                    data: data
                });
            }

            if (result?.status) {
                setLoading(false)
                toast.success(t('NETWORKMESSAGE.addCustomerMessageSuccess'))
                clearForm()
                props.loadData()
                props.setAddForm(false)
            } else {

                if (typeof result.errorMessage === "object") {
                    let updatedObject = resetErrors(paymentType)
                    setPaymentType(checkErrors(result.errorMessage, updatedObject))
                }
                setLoading(false)
                toast.error(result.errorMessage)
            }
            setLoading(false)
        } catch (error) {
            setLoading(false)
            toast.error(error?.message)
        }

    }

    const clearForm = () => {
        setPaymentType(ObjectOfErrors)
    }


    return (
        <PopupForm
            open={props.addForm}
            setOpen={props.setAddForm}
            title={props?.object ? t('PAYMENT_TYPE.updateTitle') : t('PAYMENT_TYPE.addTitle')}
            content={<RenderContent
                setPaymentType={setPaymentType}
                paymentType={paymentType}
                open={props.addForm}
                setOpen={props.setAddForm}
            />}
            footer={<RenderFooter
                open={props.addForm}
                setOpen={props.setAddForm}
                submit={submit}
            />}
        />
    )
}
