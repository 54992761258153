import React,{useState,useEffect} from 'react'
import 'devextreme/dist/css/dx.light.css';
import {Box,Typography,Divider} from '@mui/material'
import {useSelector,useDispatch} from 'react-redux'
import { useTranslation } from "react-i18next";
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import * as AiIcons from 'react-icons/ai'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import * as FaIcons from 'react-icons/fa'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import BarCart from './Components/BarChart';
import PieChartDashboard from './Components/PieChartDashboard';
import RadialBar from './Components/RadialBar';
import StackedBarCgart from './Components/StackedBarCgart';
import LineColumnChart from './Components/LineColumnChart';

import './style.css'
import Filter from './Components/Filter';
import compoundAPI from '../../Global/Network/Compound';
import { toast } from 'react-toastify';
import DashboardTabs from './DashboardTabs';
import DashboardAPI from '../../Global/Network/Dashboard';
const tempImageUrl="https://images.unsplash.com/flagged/photo-1570612861542-284f4c12e75f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8M3x8cGVyc29ufGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60"
export default function Dashboard() {
  const [t] = useTranslation("common");
  const dashboardtheme=useSelector(state=>state.themeData.dashboardtheme)
  const isopensidemenu=useSelector(state=>state.settingsData.isopensidemenu)
  const screenwidth=useSelector(state=>state.settingsData.screenwidth)
  const [country,setCountry]=useState(1)
  const [selectedCompound,setSelectedCompound]=useState([])
  const [compounds,setCompounds]=useState([])
  const controller=new AbortController();
  const [loading,setLoading]=useState(false)
  const [pageSize, setPageSize] = useState(5);
  const [pageNumber, setPageNumber] = useState(1);
  const [dashboards,setDashboards]=useState(null)

  useEffect(()=>{
    loadDataOfCompounds()
  },[])

  useEffect(()=>{
    loadDataOfDashboards()
  },[pageNumber,pageSize])
  const loadDataOfCompounds= async () => {
    setLoading(true)
  
    const result = await compoundAPI.compoundsList({
       signal: controller?.signal
    })
  
    if (result.status) {
      setCompounds(result?.data)
    }
    else {
       if(!result?.data?.error)
       {
        toast.error(t('NETWORKMESSAGE.messageError'))
        return
       }
    }
  
  }
  const loadDataOfDashboards= async (data) => {
    setLoading(true)
   
    let obj={
      page_size:pageSize,
      page:pageNumber,
      ...data
    }
    if(selectedCompound&&selectedCompound?.length>0){
      let compound_ids=[]
      selectedCompound&&selectedCompound?.map((itm)=>{
       compound_ids.push(itm?.id)
      })
      obj={
        ...obj,
        compound_ids:compound_ids
      }
    }
    const result = await DashboardAPI.dashboard({
       signal: controller?.signal,
       params:obj,
    })
  
    if (result.status) {
      setDashboards(result?.data)
      return result?.data
    }
    else {
       if(!result?.data?.error)
       {
        toast.error(t('NETWORKMESSAGE.messageError'))
        return
       }
    }
  
  }
  const submit=async()=>{
     let data=null;
     try{
      console.log('sasdasdsad',selectedCompound)
       if(selectedCompound&&selectedCompound?.length>0){
         let compound_ids=[]
         selectedCompound&&selectedCompound?.map((itm)=>{
          compound_ids.push(itm?.id)
         })
        loadDataOfDashboards({
          compound_ids:compound_ids,
        })

       }else{
        loadDataOfDashboards()
       }
     }catch(err){
      console.log(err?.message)
     }
  }

  return (
    <Box sx={{width:'100%',padding:'10px 5px'}}>
        <Box className="row px-0 mx-0">
             {/* header ofsalse sections  */}
           <Box className="col-md-12"
           >
               
              <Box
                sx={{
                  backgroundColor:'#fff',
                  justifyContent:'center',
                  alignItems:'start',
                  borderRadius:'3px',
                  flexDirection:'column',
                  display:'flex',
                  alignContent:'center',
                  backgroundColor:dashboardtheme?.colorWhite,
                  boxShadow: '0px 0px 8px -4px #0000000A',
                  opacity: 1,
                  height: 'fit-content',
                  padding:'10px 5px',
                }}
              >
                {/* <span style={{
                fontFamily:'Cairo-Bold',
                fontSize:'30px',
                color:'#000'
                }}>{t('DASHBOARD.filters')}</span> */}
                <Box
                  sx={{
                    width:'100%',
                    display:'flex',
                    justifyContent:'center',
                    alignItems:'center',
                  }}
                >
                   <Filter
                     selectedCompound={selectedCompound}
                     setSelectedCompound={setSelectedCompound}
                     compounds={compounds}
                     setCompounds={selectedCompound}
                     submit={submit}
                   />
                </Box>

              </Box>
           </Box>
        </Box>
            <br />
           <DashboardTabs 
           dashboards={dashboards} 
           loadDataOfDashboards={loadDataOfDashboards}
           pageNumber={pageNumber}
           setPageNumber={setPageNumber}
           pageSize={pageSize}
           setPageSize={setPageSize}

            
           />

    </Box>
  )
}
