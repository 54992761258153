import * as React from 'react';
import { alpha } from '@mui/material/styles';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CustomButton from '../../../../General/CustomComponents/CustomButton';
import {GiArchiveRegister} from 'react-icons/gi'
import {ImBin} from 'react-icons/im'

const buttonSX = {
    width: '164px',
    fontFamily: 'Cairo',
    fontSize: '13px',
    backgroundColor: '#FFFFFF66',
    color: '#FFFFFF',
    marginLeft: '5px',
    border: 'none',

    width: '85px',
    height: '30px',
    background: 'var(--unnamed-color-994d1e) 0% 0% no-repeat padding-box',
    background: '#994D1E 0% 0% no-repeat padding-box',
    boxShadow: '0px 3px 6px #00000029',
    opacity: 1,
}
const buttonHoverStyle = {
    backgroundColor: '#FFFFFF',
    color: '#994D1E',
}

export default function CustomMenu() {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <CustomButton

                text={"الخيارات"}
                sx={buttonSX}
                buttonHoverStyle={buttonHoverStyle}
                id="demo-customized-button"
                ariaControls={open ? 'demo-customized-menu' : undefined}
                ariaHaspopup="true"
                ariaExpanded={open ? 'true' : undefined}
                variant="contained"
                endIcon={<KeyboardArrowDownIcon />}
                onClick={handleClick}
            />
            {/* <Button
                id="demo-customized-button"
                aria-controls={open ? 'demo-customized-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                variant="contained"
                disableElevation
                onClick={handleClick}
                endIcon={<KeyboardArrowDownIcon />}
            >
                الخيارات
            </Button> */}
            <Menu
                elevation={0}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}

                sx={{
                    '& .MuiPaper-root': {
                        borderRadius: 0,
                        marginTop: '10px',
                        minWidth: 180,
                        right: '-20px',
                        color: 'rgb(55, 65, 81)',
                        boxShadow:
                            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
                        '& .MuiMenu-list': {
                            padding: '0px 0',
                        },
                        '& .MuiMenuItem-root': {
                            '& .MuiSvgIcon-root': {
                                fontSize: 18,
                                color: '#994D1E',
                                marginRight: '15px',
                            },
                            '&:active': {
                                backgroundColor: alpha(
                                    '#994D1E',
                                    0.1,
                                ),
                            },
                        },
                    },
                }}

                id="demo-customized-menu"
                MenuListProps={{
                    'aria-labelledby': 'demo-customized-button',
                }}

                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                <MenuItem sx={{
                    border: '0.5px solid #994D1E',
                    color: '#994D1E',
                    font: 'normal normal 300 12px/22px Cairo',
                    padding: '5px'
                }}
                    onClick={handleClose} disableRipple>
                    <GiArchiveRegister size={'18px'} style={{
                        marginLeft:'5px'
                    }}/>
                    اضافة اتصال جديد
                </MenuItem>
                <MenuItem
                    sx={{
                        border: '0.5px solid #994D1E',
                        color: '#994D1E',
                        font: 'normal normal 300 12px/22px Cairo',
                        padding: '5px'
                    }}
                    onClick={handleClose} disableRipple>
                    <ImBin size={'18px'} style={{
                        marginLeft:'5px'
                    }}/>
                    عرض جميع الاتصالات
                </MenuItem>
                {/* <Divider sx={{ my: 0.5 }} />
                <MenuItem onClick={handleClose} disableRipple>
                    <ArchiveIcon />
                    Archive
                </MenuItem>
                <MenuItem onClick={handleClose} disableRipple>
                    <MoreHorizIcon />
                    More
                </MenuItem> */}
            </Menu>
        </div>
    );
}
