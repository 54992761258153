import * as React from "react";
import { alpha } from "@mui/material/styles";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CustomButton from "../../../../General/CustomComponents/CustomButton";
import { GiArchiveRegister } from "react-icons/gi";
import { ImBin } from "react-icons/im";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { Box } from "@mui/material";

const buttonSX = {
  width: "164px",
  fontFamily: "Cairo",
  fontSize: "13px",
  backgroundColor: "#FFFFFF66",
  color: "#FFFFFF",
  marginLeft: "5px",
  border: "none",

  width: "85px",
  height: "30px",
  background: "var(--unnamed-color-994d1e) 0% 0% no-repeat padding-box",
  background: "#994D1E 0% 0% no-repeat padding-box",
  boxShadow: "0px 3px 6px #00000029",
  opacity: 1,
};
const buttonHoverStyle = {
  backgroundColor: "#FFFFFF",
  color: "#994D1E",
};

export default function CustomMenu(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [t] = useTranslation("common");
  const navigate = useNavigate();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <CustomButton
        text={t("CUSTOMER.callOptions")}
        sx={buttonSX}
        buttonHoverStyle={buttonHoverStyle}
        id="demo-customized-button"
        ariaControls={open ? "demo-customized-menu" : undefined}
        ariaHaspopup="true"
        ariaExpanded={open ? "true" : undefined}
        variant="contained"
        endIcon={<KeyboardArrowDownIcon />}
        onClick={handleClick}
      />
      {/* <Button
                id="demo-customized-button"
                aria-controls={open ? 'demo-customized-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                variant="contained"
                disableElevation
                onClick={handleClick}
                endIcon={<KeyboardArrowDownIcon />}
            >
                الخيارات
            </Button> */}
      <Menu
        elevation={0}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={{
          "& .MuiPaper-root": {
            borderRadius: 0,
            marginTop: "10px",
            minWidth: 180,
            right: "-20px",
            color: "rgb(55, 65, 81)",
            boxShadow:
              "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
            "& .MuiMenu-list": {
              padding: "0px 0",
            },
            "& .MuiMenuItem-root": {
              "& .MuiSvgIcon-root": {
                fontSize: 18,
                color: "#994D1E",
                marginRight: "15px",
              },
              "&:active": {
                backgroundColor: alpha("#994D1E", 0.1),
              },
            },
          },
        }}
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {
        props?.userRolesHere[1]?.add?
        <MenuItem
          sx={{
            border: "0.5px solid #994D1E",
            color: "#994D1E",
            font: "normal normal 300 12px/22px Cairo",
            padding: "5px",
          }}
          onClick={() => {
            props?.createFUnction();
            handleClose();
          }}
          disableRipple
        >
          <GiArchiveRegister
            size={"18px"}
            style={{
              marginLeft: "5px",
            }}
          />
          {t("CUSTOMER.addNewCalls")}
        </MenuItem>:null}
        {
        props?.userRolesHere[0]?.view?
        <MenuItem
          sx={{
            border: "0.5px solid #994D1E",
            color: "#994D1E",
            font: "normal normal 300 12px/22px Cairo",
            padding: "5px",
          }}
          onClick={() => {
            window.open('/admin/calls?CustomerId='+props?.row?.id,'_blank');
            // navigate("/admin/calls", {
            //   state: {
            //     data: {
            //       customer: props?.row,
            //     },
            //   },
            // });
          }}
          disableRipple
        >
          <RemoveRedEyeIcon
            size={"18px"}
            style={{
              marginLeft: "5px",
            }}
          />
          {t("CUSTOMER.ShowAllCalls")}
        </MenuItem>:null}
        {
        !props?.userRolesHere[0]?.view&& !props?.userRolesHere[1]?.add?
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "5px 15px",
            color: "#fff",
            fontFamily: "Cairo",
          }}
        >
          {t("GLOBAL.emptyPoPup")}
        </Box>:null}
        {/* <Divider sx={{ my: 0.5 }} />
                <MenuItem onClick={handleClose} disableRipple>
                    <ArchiveIcon />
                    Archive
                </MenuItem>
                <MenuItem onClick={handleClose} disableRipple>
                    <MoreHorizIcon />
                    More
                </MenuItem> */}
      </Menu>
    </div>
  );
}
