import callAPI from '../ApiConfig'
import store from '../../../app/store'

const unitLocations = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"unit_locations",
        method:"get",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
    
}
const unitLocationsList = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"unit_locations_list",
        method:"get",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
    
}
const addUnitLocation = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"unit_locations",
        method:"post",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        },
        data:props?.data,
    })
    
}
const updateUnitLocation = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"unit_locations/"+props?.data?.id,
        method:"put",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        },
        data:props?.data
    })
    
}
const deleteUnitLocation = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"unit_locations/"+props?.data?.id,
        method:"delete",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        },
    })
    
}
const UnitLocationsAPI={
    unitLocations:unitLocations,
    unitLocationsList:unitLocationsList,
    addUnitLocation:addUnitLocation,
    updateUnitLocation:updateUnitLocation,
    deleteUnitLocation:deleteUnitLocation

}
export default UnitLocationsAPI