import { Box, Button, FormControlLabel, Switch } from '@mui/material'
import React, { useState, useEffect } from 'react'
import PopupForm from '../../Components/PopupForm'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import ObjectOfErrors, { checkErrors, resetErrors, restCustomeObject, ObjectMerge } from '../../../Global/Errors/Phases/Phases'
import { toast } from 'react-toastify'
import PhaseAPI from '../../../Global/Network/Phase'
import CustomDateField from '../../../General/CustomComponents/CustomDateField'
import CustomTextField from '../../../General/CustomComponents/CustomTextField'
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import compoundAPI from '../../../Global/Network/Compound'
import helpers from '../../../assets/js/helper'
import CustomeSelectField from '../../../General/CustomComponents/CustomeSelectField'

const RenderContent = (props) => {
    const [t] = useTranslation('common')
    const mainTheme = useSelector(state => state.themeData.maintheme)
    const screenwidth = useSelector(state => state.settingsData.screenwidth);
    const addPhaseDetail = () => {
        let data = {
            id: props?.phase?.compoundPhases?.length > 0 ? Math.max.apply(Math, props?.phase?.compoundPhases?.map(function (compoundPhase) { return compoundPhase?.id; })) + 1 : 1,
            is_new:true,
            start_date: {
                value: new Date(),
                error: false,
                message: [],
                required: false
            },
            end_date: {
                value: new Date(),
                error: false,
                message: [],
                required: false
            },
            compound: {
                value: {
                    id:"",
                    name:""
                },
                error: false,
                message: [],
                required: false
            }
        }
        props?.setPhase({
            ...props?.phase,
            compoundPhases: [
                ...props?.phase?.compoundPhases,
                data
            ]
        })


    }
    const removeThisItem = (deletePhaseDetail) => {

        props?.setPhase({
            ...props?.phase,
            compoundPhases: props?.phase?.compoundPhases?.filter(compoundPhase => compoundPhase?.id != deletePhaseDetail?.id),
        })

    }
    return (
        <Box className='Container-fluid my-3'>
            <Box className="row">
                <Box className="col-12 col-sm-12 col-md-6 col-lg-6">
                    <CustomTextField
                        label={t('PHASES.name')}
                        value={props?.phase?.name?.value}
                        error={props?.phase?.name?.error}
                        message={props?.phase?.name?.message}
                        haswidth={true}
                        readOnly={false}
                        focused={true}
                        onChange={(e) => {
                            props?.setPhase({
                                ...props?.phase,
                                name: {
                                    value: e?.target?.value,
                                    error: false,
                                    message: []
                                }
                            })
                        }}
                        onClearClick={() => {
                            props?.setPhase({
                                ...props?.phase,
                                name: {
                                    value: "",
                                    error: false,
                                    message: []
                                }
                            })
                        }}
                    />

                </Box>
                <Box className="col-12 col-sm-12 col-md-6 col-lg-6">
                    <CustomTextField
                        label={t('PHASES.add_description')}
                        value={props?.phase?.description?.value}
                        error={props?.phase?.description?.error}
                        message={props?.phase?.description?.message}
                        hasMultipleLine={true}
                        haswidth={true}
                        readOnly={false}
                        onChange={(e) => {
                            props?.setPhase({
                                ...props?.phase,
                                description: {
                                    value: e?.target?.value,
                                    error: false,
                                    message: []
                                }
                            })
                        }}
                        onClearClick={() => {
                            props?.setPhase({
                                ...props?.phase,
                                description: {
                                    value: "",
                                    error: false,
                                    message: []
                                }
                            })
                        }}
                    />
                </Box>
                <Box className="col-md-12 my-2 "
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'start',
                        alignItems: 'center',

                        width: '100%'
                    }}
                >
                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'start',
                            alignItems: 'center'
                        }}
                    >
                        <span style={{
                            textAlign: "left",
                            font: "normal normal  20px/37px Cairo",
                            letterSpacing: "0px",
                            color: "#707070",
                            paddingRight: '10px',
                            opacity: "1"
                        }}>{t('PHASES.add_phase_details')}</span>
                    </Box>
                    <Box
                        sx={{
                            // borderRadius: '5px',
                            // border: `1px solid ${mainTheme?.iconColor}`,
                            padding: '10px',
                            width: '100%',
                            minHeight: '50px',

                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'start',
                                width: '100%',
                            }}
                        >
                            <AddCircleIcon className="iconAddRelationType" sx={{ fontSize: '30px', cursor: 'pointer', color: mainTheme?.iconColor }} onClick={addPhaseDetail} />
                        </Box>
                        {
                            props?.phase?.compoundPhases?.length > 0 && props?.phase?.compoundPhases?.map((compoundPhase, index) => (
                                <Box
                                    key={index + "_payment_type_detail"}
                                    sx={{
                                        width: '100%',
                                        display: 'flex',
                                        justifyContent: 'flex-start',
                                        alignItems: 'start',
                                        marginTop:'10px'
                                    }}
                                >
                                    <RemoveCircleIcon className="iconAddRelationType" sx={{ fontSize: '28px', color: mainTheme?.iconColor, cursor: 'pointer', marginTop: '15px' }} onClick={() => removeThisItem(compoundPhase)} />
                                    <Box sx={{
                                        width: '100%',
                                        display: 'flex',
                                        justifyContent: 'flex-start',
                                        alignItems: 'center',
                                        flexWrap: 'wrap'
                                    }}>
                                        <CustomDateField
                                            label={t('PHASES.start_date')}
                                            customWidth={screenwidth < 800 ? "100%" :'50%'}
                                            value={compoundPhase?.start_date?.value}
                                            error={false}
                                            message={""}

                                            haswidth={true}
                                            readOnly={false}
                                            onChange={(e) => {

                                                props?.setPhase({
                                                    ...props?.phase,
                                                    compoundPhases: props?.phase?.compoundPhases?.map(currentCompoundPhase => {
                                                        if (currentCompoundPhase?.id == compoundPhase?.id) {

                                                            return {
                                                                ...compoundPhase,
                                                                start_date: {
                                                                    value: e,
                                                                    error: false,
                                                                    message: [],
                                                                    required: false

                                                                }

                                                            }

                                                        }
                                                        return currentCompoundPhase
                                                    })
                                                })

                                            }}
                                            onClearClick={() => {
                                                props?.setPhase({
                                                    ...props?.phase,
                                                    compoundPhases: props?.phase?.compoundPhases?.map(currentCompoundPhase => {
                                                        if (currentCompoundPhase?.id == compoundPhase?.id) {
                                                            return {
                                                                ...compoundPhase,
                                                                start_date: {
                                                                    value: "",
                                                                    error: false,
                                                                    message: [],
                                                                    required: false

                                                                }

                                                            }
                                                        }
                                                        return currentCompoundPhase
                                                    })
                                                })
                                            }}
                                        />
                                        
                                        <CustomDateField
                                            customWidth={screenwidth < 800 ? "100%" : '50%' }
                                            label={t('PHASES.end_date')}
                                            value={compoundPhase?.end_date?.value}
                                            error={false}
                                            message={""}
                                            minDate={compoundPhase?.start_date?.value}
                                            hasMultipleLine={true}
                                            haswidth={true}
                                            readOnly={false}
                                            onChange={(e) => {

                                                props?.setPhase({
                                                    ...props?.phase,
                                                    compoundPhases: props?.phase?.compoundPhases?.map(currentCompoundPhase => {
                                                        if (currentCompoundPhase?.id == compoundPhase?.id) {
                                                            return {
                                                                ...compoundPhase,
                                                                end_date: {
                                                                    value: e,
                                                                    error: false,
                                                                    message: [],
                                                                    required: false

                                                                }

                                                            }
                                                        }
                                                        return currentCompoundPhase
                                                    })
                                                })
                                            }}
                                            onClearClick={() => {
                                                props?.setPhase({
                                                    ...props?.phase,
                                                    compoundPhases: props?.phase?.compoundPhases?.map(currentCompoundPhase => {
                                                        if (currentCompoundPhase?.id == compoundPhase?.id) {
                                                            return {
                                                                ...compoundPhase,
                                                                end_date: {
                                                                    value: "",
                                                                    error: false,
                                                                    message: [],
                                                                    required: false

                                                                }

                                                            }
                                                        }
                                                        return currentCompoundPhase
                                                    })
                                                })
                                            }}
                                        />
                                        
                                        <CustomeSelectField
                                        margin={'10px 0'}
                                            label={t('PHASES.select_compounds')}
                                            haswidth={true}
                                            value={compoundPhase?.compound?.value}
                                            list={props?.compounds ? props?.compounds : []}
                                            multiple={false}
                                            error={compoundPhase?.compound?.error}
                                            message={compoundPhase?.compound?.message}
                                            readOnly={false}
                                            onChange={(e, newValue) => {
                                                props?.setPhase({
                                                    ...props?.phase,
                                                    compoundPhases: props?.phase?.compoundPhases?.map(currentCompoundPhase => {
                                                        if (currentCompoundPhase?.id == compoundPhase?.id) {

                                                            return {
                                                                ...compoundPhase,

                                                                compound: {
                                                                    value: newValue,
                                                                    error: false,
                                                                    message: []
                                                                }
                                                            }
                                                        }
                                                        return currentCompoundPhase
                                                    })
                                                })
                                            }}
                                            onClearClick={() => {
                                                props?.setPhase({
                                                    ...props?.phase,
                                                    compoundPhases: props?.phase?.compoundPhases?.map(currentCompoundPhase => {
                                                        if (currentCompoundPhase?.id == compoundPhase?.id) {

                                                            return {
                                                                ...compoundPhase,

                                                                compound: {
                                                                    value: "",
                                                                    error: false,
                                                                    message: []
                                                                }
                                                            }
                                                        }
                                                        return currentCompoundPhase
                                                    })
                                                })
                                            }}
                                        />
                                    </Box>
                                </Box>
                            ))
                        }

                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

const RenderFooter = (props) => {
    const [t] = useTranslation('common')
    const gridtheme = useSelector(state => state.themeData.gridtheme)

    return (
        <Box sx={{
            display: 'flex',
            alignItems: 'center',
        }}>


            <Box sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center', width: '100%' }}>
                <Box display="flex" >

                    <Button variant="outlined"
                        // spacing={2}
                        sx={{
                            width: '50% !important',
                            // flexGrow: 1, 
                            minWidth: '80px !important',
                            maxWidth: '80px !important',
                            margin: 1,
                            backgroundColor: '#f7f7f7',
                            borderColor: gridtheme?.colorWhite,
                            color: gridtheme?.colorblack,
                            boxShadow: '0 0 7px -2px white',
                            //   color:SearchButtonTheme?.clear_button_text_color,
                            '&:hover': {
                                backgroundColor: '#f7f7f7',
                                borderColor: gridtheme?.colorWhite,
                                color: gridtheme?.colorblack,
                                boxShadow: '0 0 7px 1px white',
                                boxShadow: '0',


                            },
                            height: '35px',
                            fontFamily: 'Cairo-Bold'

                        }}
                        className="iconeFilterClear"
                        color="secondary"
                        onClick={() => {
                            props.setOpen(false)
                        }}
                    >
                        {t('GLOBAL.close')}
                    </Button>
                    <Button
                        variant="contained"
                        // spacing={2} 
                        sx={{
                            // flexGrow: 1,
                            margin: 1,
                            width: '80px !important',
                            minWidth: '80px !important',
                            maxWidth: '80px !important',
                            '&:hover': {
                                //   backgroundColor:SearchButtonTheme?.search_button_color+'88',
                            },
                            height: '35px',
                            fontFamily: 'Cairo-Bold'
                        }}
                        className="iconeFilterSearch"
                        onClick={() => {
                            props.submit()
                        }}
                    >
                        {t('GLOBAL.Save')}
                    </Button>
                </Box>
            </Box>
        </Box>
    )
}


export default function AddFormDailog(props) {
    const [t] = useTranslation('common');
    const [compounds, setCompounds] = useState([])
    const [name, setName] = useState(null)
    const [loading, setLoading] = useState(false)
    const [phase, setPhase] = useState(ObjectOfErrors)


    useEffect(() => {
        if (props?.object && props?.object?.id) {
            let updatedObject = ObjectMerge(props?.object)
            setPhase(updatedObject)
        } else {
            setPhase(ObjectOfErrors)
        }
    }, [props?.object, props?.addForm])

    const submit = async () => {
        try {
            setLoading(true)
            let updatedObject = resetErrors(phase)

            console.log("compoundPhase?.compound?.value?.id",updatedObject);
            if (!updatedObject?.compoundPhases?.length) {
                toast.error(t('PHASE.no_compound_phases'))
                return
            }
            else {
                for (let index = 0; index < updatedObject?.compoundPhases.length; index++) {
                    const phase = updatedObject?.compoundPhases[index];
                    if (phase?.value == "" || phase?.value == 0) {
                        toast.error(t('PHASE.no_value_for_payment_type_detail'))
                        return
                    }


                }
            }

            let compoundPhases = updatedObject?.compoundPhases

            let total = 0
            let compoundMissing = false
            compoundPhases?.map(compoundPhase => {
                if (!compoundPhase?.compound?.value?.id) {
                    console.log("compoundPhase?.compound?.value?.id",compoundPhase?.compound);
                    compoundMissing = true
                }
            })

            if (compoundMissing) {
                toast.warn(t('PHASES.compound_missing'))
                return
            }

            

            let data = {
                name: updatedObject?.name?.value,
                description: updatedObject?.description?.value,
                
                compound_phases: compoundPhases?.map(compoundPhase => {
                    return {
                        id:compoundPhase?.is_new?null:compoundPhase?.id,
                        start_date: compoundPhase?.start_date?.value?helpers.formatDate(compoundPhase?.start_date?.value):"",
                        end_date: compoundPhase?.end_date?.value?helpers.formatDate(compoundPhase?.end_date?.value):"",
                        compound_id: compoundPhase?.compound?.value?.id
                    }
                })
            }

            let result = null;
            if (props?.object && props?.object?.id) {
                data = {
                    ...data,
                    id: props?.object?.id,
                    _method: 'put'
                }
                result = await PhaseAPI.updatePhase({
                    data: data
                });
            } else {
                result = await PhaseAPI.addPhase({
                    data: data
                });
            }

            if (result?.status) {
                setLoading(false)
                toast.success(t('NETWORKMESSAGE.addCustomerMessageSuccess'))
                clearForm()
                props.loadData()
                props.setAddForm(false)
            } else {

                if (typeof result.errorMessage === "object") {
                    let updatedObject = resetErrors(phase)
                    setPhase(checkErrors(result.errorMessage, updatedObject))
                }
                setLoading(false)
                toast.error(result.errorMessage)
            }
            setLoading(false)
        } catch (error) {
            setLoading(false)
            toast.error(error?.message)
        }

    }

    const clearForm = () => {
        setPhase(ObjectOfErrors)
    }

    useEffect(() => {
        let abortController = new AbortController();
        getDataOfCompounds()
        if (!props?.object)
            clearForm()
        return () => {
            abortController.abort();
        }
    }, [props?.addForm, props?.object])

    const getDataOfCompounds = async () => {
        let data = null;
        try {
            data = await compoundAPI.compoundsList();
            if (data && data?.status) {

                setCompounds(data?.data)
            }
        } catch (err) {
            console.log('errr', err?.message)
        }
    }

    return (
        <PopupForm
            open={props.addForm}
            setOpen={props.setAddForm}
            title={props?.object ? t('PHASES.updateTitle') : t('PHASES.addTitle')}
            content={<RenderContent
                setPhase={setPhase}
                phase={phase}
                open={props.addForm}
                setOpen={props.setAddForm}
                compounds={compounds}
                
            />}
            footer={<RenderFooter
                open={props.addForm}
                setOpen={props.setAddForm}
                submit={submit}
            />}
        />
    )
}
