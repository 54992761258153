const EmployeeError = {
    id:"",
    username:{
        value: "",
        error: false,
        message: "",
        required: false
    },
    full_name:{
        value: "",
        error: false,
        message: "",
        required: false
    },
    password:{
        value: "",
        error: false,
        message: "",
        required: false
    },
    confirm_password:{
        value: "",
        error: false,
        message: "",
        required: false
    },
    company_id:{
        value: "",
        error: false,
        message: "",
        required: false
    },
    phone:{
        value: "",
        error: false,
        message: "",
        required: false
    },
    email:{
        value: "",
        error: false,
        message: "",
        required: false
    },
}
// export const filterobject = 
// {
//   month: {
//     value: {
//       id: "",
//       name: ""
//     },
//     error: false,
//     message: "",
//     required: false
//   },
//   year: {
//     value: "",
//     error: false,
//     message: "",
//     required: false
//   },
//   customer_level_id: {

//     value: {
//       id: "",
//       name: ""
//     },
//     error: false,
//     message: "",
//     required: false
//   },
//   gender_id: {
//     value: {
//       id: "",
//       name: ""
//     },
//     error: false,
//     message: "",
//     required: false
//   },
//   governorate_id: {
//     value: {
//       id: "",
//       name: ""
//     },
//     error: false,
//     message: "",
//     required: false
//   },
// }
export const resetErrors = (customerObject) => {
    var objectKeys = Object.keys(customerObject);
   
    var updatedObject = {
        ...customerObject
    }
    try {
        objectKeys.filter(key=>key!=="id").map((key) => {
        
            updatedObject[key]["error"] = false
            updatedObject[key]["message"] = ""
        })
    } catch (error) {
        
    }
    
    return updatedObject

}
export const checkErrors = (errorMessages, customerObject) => {

    const errorMessagesKeys = Object.keys(errorMessages);
    errorMessagesKeys.map((key) => {
        let messages = []
        errorMessages[key].map((message) => {
            messages.push(message)
        })
       
        customerObject[key]["error"] = true
        customerObject[key]["message"] = messages
    })


    return customerObject
}
export const customerObjectMerge = (updatedObject) => {
    const mergedCustomerObject = {
    id:updatedObject?.id,
    username:{
        value: updatedObject?.username,
        error: false,
        message: "",
        required: false
    },
    fullName:{
        value: updatedObject?.fullName,
        error: false,
        message: "",
        required: false
    },
    password:{
        value: updatedObject?.password,
        error: false,
        message: "",
        required: false
    },
    confirmPassword:{
        value: updatedObject?.confirmPassword,
        error: false,
        message: "",
        required: false
    },
    company:{
        value: updatedObject?.company,
        error: false,
        message: "",
        required: false
    },
    phone:{
        value: updatedObject?.phone,
        error: false,
        message: "",
        required: false
    },
    email:{
        value: updatedObject?.email,
        error: false,
        message: "",
        required: false
    },

    }
    
    return mergedCustomerObject;
}
export const restCustomeObject=(customerObject,obj)=>{
    // var objectKeys = Object.keys(customerObject);
   
    try {
        
        customerObject[obj]["error"] = false
        customerObject[obj]["message"] = ""
    } catch (error) {
        
    }
    
    return customerObject
}
export default EmployeeError;