import callAPI from '../ApiConfig'
import store from '../../../app/store'

const Companies = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"companies",
        method:"get",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
    
}
const CompanyList = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"companies_list",
        method:"get",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
    
}
const addCompany = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"companies",
        method:"post",
        signal:props?.signal,
        params:props?.params,
        data:props?.data,
        headers:{
            "Content-Type": "multipart/form-data",
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
    
}
const updateCompany = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"companies/"+props?.id,
        method:"post",
        signal:props?.signal,
        params:props?.params,
        data:props?.data,
        headers:{
            "Content-Type": "multipart/form-data",
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
    
}
const deleteCompany = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"companies/"+props?.data?.id,
        method:"delete",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
    
}

const Company = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"company",
        method:"get",
        signal:props?.signal,
        params:props?.params,
        headers:{
            'Content-Type': 'application/json',
        }
    })
    
}



const CompanyAPI={
    Companies:Companies,
    CompanyList:CompanyList,
    addCompany:addCompany,
    updateCompany:updateCompany,
    deleteCompany:deleteCompany,
    Company:Company,
}
export default CompanyAPI