import callAPI from '../ApiConfig'
import store from '../../../app/store'

const nationalIdTypes = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"national_id_types",
        method:"get",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
    
}
const nationalIdTypesList = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"national_id_types_list",
        method:"get",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
    
}
const addNationalIdTypes = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"national_id_types",
        method:"post",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        },
        data:props?.data,
    })
    
}
const updateNationalIdTypes = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"national_id_types/"+props?.data?.id,
        method:"put",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        },
        data:props?.data
    })
    
}
const deleteNationalIdTypes = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"national_id_types/"+props?.data?.id,
        method:"delete",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        },
    })
    
}
const NationalIdTypesAPI={
    nationalIdTypes:nationalIdTypes,
    nationalIdTypesList:nationalIdTypesList,
    addNationalIdTypes:addNationalIdTypes,
    updateNationalIdTypes:updateNationalIdTypes,
    deleteNationalIdTypes:deleteNationalIdTypes

}
export default NationalIdTypesAPI