import { createSlice } from '@reduxjs/toolkit';
const directions__= localStorage.getItem('direction')? (localStorage.getItem('direction')==1?'rtl':'ltr'):'rtl'
var screenwidth= window.innerWidth
window.addEventListener('resize', ()=>{
   screenwidth=window.innerWidth
})
const GridMode=localStorage.getItem('gridMode')?localStorage.getItem('gridMode')=='true'?true:false:false;

const initialState={
    offLine:false,
    directions:'rtl',
    screenwidth:screenwidth,
    drawerwidth:350,
    currentpath:'admin/dashboard',
    polygons:[],
    isopensidemenu:false,
    open_advance_search:false,
    globalloading:false,
    company_profile_full_screen:false,
    openCollapseOfOpen:null,
    openChildOfOpen:null,
    currentOpenMenuCollapse:null,
    menuList:[]
}
export const settingsSlice = createSlice({
  name: 'settingsData',
  initialState:initialState,
  reducers: {
    setcurrentOpenMenuCollapse:(state,action)=>{
      state.currentOpenMenuCollapse=action?.payload
    },
    setOpenChildOfOpen:(state,action)=>{
      state.openChildOfOpen=action.payload
    },
    setOpenCollapseOfOpen:(state,action)=>{
      state.openCollapseOfOpen=action.payload
    },
    setMenuList:(state,action)=>{
      state.menuList=action.payload
    },
    setOffLine:(state,action)=>{
     state.offLine=action.payload
    },
    setscreenwidth:(state,action)=>{
      state.screenwidth=action.payload
    },
    setDrawerWidth:(state,action)=>{
      state.drawerwidth=action.payload
    },
    setCurrentPath:(state,action)=>{
      state.currentpath=action.payload
    },
    setPolygonsRedux:(state,action)=>{
      state.polygons=action.payload
    },
    setIsOpenSideMenu:(state,action)=>{
      state.isopensidemenu=action.payload
    },
    setGlobalLoading:(state,action)=>{
      state.globalloading=action.payload
    },
    setOpenAdvanceSearch:(state,action)=>{
       state.open_advance_search=action.payload
    },
    setCompanyProfileFullScreen:(state,action)=>{
      state.company_profile_full_screen=action.payload
    },

  }
});

export const {
  setcurrentOpenMenuCollapse,
  setOpenCollapseOfOpen,
  setOpenChildOfOpen,
  setMenuList,
  setUserData,
  setCompanyProfileFullScreen,
  setOpenAdvanceSearch,
  setGlobalLoading,
  setOffLine,
  setscreenwidth,
  setCurrentPath,
  setPolygonsRedux,
  setIsOpenSideMenu
} = settingsSlice.actions;



export default settingsSlice.reducer;
