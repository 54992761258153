import { Box, Button } from '@mui/material'
import React,{useState} from 'react'
import * as FaIcons from 'react-icons/fa'
import { ImTree } from "react-icons/im";
function PolygonToolTip({ left, top, show,icon,title,availableUnits,showAvailableUnits }) {
    if (!show ) {
        return null
    }
    return (
        <Box
            style={{
                position: "fixed",
                left: `${left}px`,
                top: `${top}px`,
                pointerEvents: "none",
                background: "#fff",
                zIndex: 23424234,
                width: "fit-content",
                height: "fit-content", background: "#fff 0% 0% no-repeat padding-box", boxShadow: "0px 0px 15px 0px lightgrey", opacity: "1",
                transition: "all 0.9s ease-in-out",
                display: 'flex',
                alignItems: 'start',
                justifyContent: 'center',
                flexDirection: 'column',
                padding: '10px 5px 10px 25px'
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'start',
                    margin: '2px 0'
                }}
            >
                {icon?icon:
                <FaIcons.FaBuilding
                    style={{
                        fontSize: '28px',
                        color: '#1E6A99',
                        margin: '0 10px'
                    }}
                />}
                <span style={{
                    textAlign: "left", font: "normal normal bold 18px/22px Cairo", letterSpacing: "0px", color: "#212121", opacity: "1"
                }}> {title?title:""}</span>
            </Box>
            {showAvailableUnits &&
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'start',
                    margin: '2px 0'
                }}
            >
                
                <ImTree 
                    style={{
                        fontSize: '28px',
                        color: '#1E6A99',
                        margin: '0 10px'
                    }}
                />
                <span style={{
                    textAlign: "left", font: "normal normal bold 18px/22px Cairo", letterSpacing: "0px", color: "#212121", opacity: "1"
                }}> { "الوحدات المتوفرة : "+(availableUnits?availableUnits:"0")}</span>
            </Box>}
        </Box>
    )
}

export default PolygonToolTip