import React ,{useEffect} from 'react'
import { Box, IconButton } from "@mui/material";
import { Navigation, Pagination, Autoplay, Lazy } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import './style.css'
import CardImage from "./CardImage";
import { useSelector } from "react-redux";

export default function Horizontal(props) {
  const screenwidth = useSelector((state) => state.settingsData.screenwidth);

  const pagination = {
    clickable: true,
    renderBullet: function (index, className) {
      return (
        '<span class="' +
        className +
        '" style="width:20px;height:20px;color:#fff;box-shadow:0 0 10px -2px rgba(255,255,255,0.4) ;background-color:#1e6a99">' +
        (index + 1) +
        "</span>"
      );
    },
  };
  useEffect(() => {
    // const swiper=new Swiper()
    window.addEventListener('resize', function () {
      if(props?.heroSwiper2)
      props?.heroSwiper2?.update();
    });
    
    return () => {
        
    };
  }, [props?.heroSwiper2]);



  return (
    <Swiper
        onSwiper={props?.setSwiperRef2}
        spaceBetween={30}
        centeredSlides={true}
        slidesPerView={3.5}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        pagination={pagination}
        direction="horizontal"
        // pagination={{
        //   clickable: true,
        // }}
        breakpoints={{
          300: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          400: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          450: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          500: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          540: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          640: {
            slidesPerView: 2,
            spaceBetween: 60,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 40,
          },
          900: {
            slidesPerView: 3,
            spaceBetween: 200,
          },
          991: {
            slidesPerView: 3,
            spaceBetween: 150,
          },
          1100: {
            slidesPerView: 4,
            spaceBetween: 10,
          },
        }}
        navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper"
        style={{
          "--swiper-navigation-color": "#1e6a99",
          "--swiper-pagination-color": "#1e6a99",
          height: "500px",
          // width: "100%",
        }}
      >
         {props?.UnitData &&
          props?.UnitData?.images &&
          props?.UnitData?.images?.length > 0 &&
          props?.UnitData?.images?.map((itm) => (
            <SwiperSlide>
              <CardImage
                itm={itm}
                loadDataOfUnit={props?.loadDataOfUnit}
                UnitId={props?.UnitId}
              />
            </SwiperSlide>
          ))}
      </Swiper>
  )
}
