import { Button, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'


const CustomButton = (props) => {
  return (
    // <Box sx={{
    //   display: 'flex',
    //   flexDirection: 'row',
    //   ...props.style,
    //   ...props?.sx
    // }}>
    //   <Box sx={{
    //     display: 'flex',
    //     flex: 9,
    //     ...props.style
    //   }}>
    <Button
      
      onClick={(e) => {
        props?.onClick && props?.onClick(e)
      }}
      disabled={props?.disabled}
      startIcon={props?.startIcon}
      endIcon={props?.endIcon}
      onMouseEnter={(e) => {
        props?.onMouseEnter && props?.onMouseEnter(e)
      }}
      onMouseLeave={(e) => {
        props?.onMouseLeave && props?.onMouseLeave(e)
      }}
      style={{
        ...props?.style
      }}
      sx={{
        width: '100%',
        color: '#FFFFFF',
        borderColor: '#FFFFFF',
        border:'none !important',
        borderRadius: '0px',
        // ...props?.buttonStyle,
        "&:hover": {
          color: '#FFFFFF',
          backgroundColor: '#FFFFFF' + "0f",
          borderRadius: '0px',
          ...props?.buttonHoverStyle,
          border:'none !important',
        },
        "&.Mui-disabled": {
          color: '#FFFFFF',
          ...props?.buttonDisabledStyle
          // "-webkit-text-fill-color": 'rgba(0, 0, 0) !important'
        },
        // ...props?.buttonSx
        ...props?.sx

      }}

      id={props?.id}
      aria-controls={props?.ariaControls}
      aria-haspopup={props?.ariaHaspopup ? props?.ariaHaspopup : undefined}
      aria-expanded={props?.ariaExpanded}
      variant={props?.variant?props?.variant:"outlined"}

    >
      {props.text}
    </Button>
    //   </Box>


    // </Box>


  )
}

export default CustomButton