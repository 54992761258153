import { Box, IconButton } from "@mui/material";
import React from "react";
import { Navigation, Pagination, Autoplay, Lazy } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "./style.css";
import CardImage from "./CardImage";
import { useSelector } from "react-redux";
import Horizontal from "./Horizontal";

export default function SlideImages(props) {
  const screenwidth = useSelector((state) => state.settingsData.screenwidth);
  const [formatedData, setFormatedData] = React.useState([]);
  const pagination = {
    clickable: true,
    renderBullet: function (index, className) {
      return (
        '<span class="' +
        className +
        '" style="width:20px;height:20px;color:#fff;box-shadow:0 0 10px -2px rgba(255,255,255,0.4) ;background-color:#1e6a99">' +
        (index + 1) +
        "</span>"
      );
    },
  };
  React.useEffect(() => {
    let arr = [];
    const groupSize = screenwidth <= 1100 ? 1 : 2;
    if (
      props?.UnitData &&
      props?.UnitData?.images &&
      props?.UnitData?.images?.length > 0
    ) {
      arr = props?.UnitData?.images
        ?.map((item, index) => {
          return index % groupSize === 0
            ? props?.UnitData?.images.slice(index, index + groupSize)
            : null;
        })
        .filter((item) => item);
    }

    console.log("grouped array", arr);
    setFormatedData(arr);
    return () => {};
  }, [props?.UnitData, screenwidth]);
  React.useEffect(() => {
    // const swiper=new Swiper()
    window.addEventListener('resize', function () {
      if(props?.heroSwiper)
      props?.heroSwiper?.update();
    });
    
    return () => {
        
    };
  }, [props?.heroSwiper]);
  return (
    <>
      {screenwidth >= 1100 ? (
        <Swiper
          onSwiper={props?.setSwiperRef}
          slidesPerView={formatedData?.length<=2?1:1.7}
          spaceBetween={20}
          autoHeight={true}
          loop={true}
          effect={"creative"}
          direction={screenwidth <= 1100 ? "horizontal" : "vertical"}
          pagination={pagination}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
            // pauseOnMouseEnter: true,
          }}
          // navigation={true}
          modules={[Autoplay, Pagination, Lazy]}
          className="mySwiper"
          lazy={true}
          style={{
            "--swiper-navigation-color": "#fff",
            "--swiper-pagination-color": "#fff",
            height: "550px",
            width: "100%",
            backgroundColor: "transparent !important",
          }}
        >
          {formatedData &&
            formatedData?.length > 0 &&
            formatedData?.map((itm, index) => (
              <SwiperSlide>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "stretch",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <CardImage
                    itm={itm[0]}
                    loadDataOfUnit={props?.loadDataOfUnit}
                    UnitId={props?.UnitId}
                    isLessThenTwo={formatedData?.length<=2}
                  />
                  <CardImage
                    itm={itm[1]}
                    loadDataOfUnit={props?.loadDataOfUnit}
                    UnitId={props?.UnitId}
                    isLessThenTwo={formatedData?.length<=2}
                  />
                </Box>
              </SwiperSlide>
            ))}
        </Swiper>
      ) : (
        <Horizontal {...props} />
      )}
    </>
  );
}
