import callAPI from '../ApiConfig'
import store from '../../../app/store'

const department = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"departments",
        method:"get",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
    
}
const departmentList = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"departments_list",
        method:"get",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
    
}
const addDepartment = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"departments",
        method:"post",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        },
        data:props?.data
    })
    
}
const updateDepartment = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"departments/"+props?.id,
        method:"post",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        },
        data:props?.data
    })
    
}
const deleteDepartment = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"departments/"+props?.data?.id,
        method:"delete",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        },
        data:props?.data
    })
    
}

const showDepartment = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"department",
        method:"get",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
    
}

const departmentAPI={
    department:department,
    departmentList:departmentList,
    addDepartment:addDepartment,
    updateDepartment:updateDepartment,
    deleteDepartment:deleteDepartment,
    showDepartment:showDepartment
}
export default departmentAPI