import { Box, TextField, Button } from "@mui/material";
import React, { useState, useEffect } from "react";
import PopupForm from "../../Components/PopupForm";
import { useTranslation } from "react-i18next";
import {
  ContainerOfInput,
  ContainerOfInputFields,
  ContainerOfSelectField,
} from "../../RegisteredCustomers/ThemDesign";
import { useSelector } from "react-redux";
import ObjectOfErrors, {
  checkErrors,
  resetErrors,
  restCustomeObject,
} from "../../../Global/Errors/Unit/Unit";
import CloseIcon from "@mui/icons-material/Close";
import MaritalStatusAPI from "../../../Global/Network/MaritalStatus/index";
import { toast } from "react-toastify";
import unitAPI from "../../../Global/Network/Unit/index";
import customerAPI from "../../../Global/Network/Customer";
import CustomeSelectField from "../../../General/CustomComponents/CustomeSelectField";

const RenderContent = (props) => {
  const [t] = useTranslation("common");
  const maintheme = useSelector((state) => state.themeData.maintheme);
  const [ErrorsObject, setErrorsObject] = useState(ObjectOfErrors);
  const handleChange = (e) => {
    props.setName(e.target.value);
    setErrorsObject(restCustomeObject(ErrorsObject, "name"));
  };
  const clearInput = () => {
    props.setName("");
    setErrorsObject(restCustomeObject(ErrorsObject, "name"));
  };

  return (
    <Box className="Container-fluid">
      <Box className="row">
        <Box className="col-12 col-sm-12 col-md-10 m-auto">
          <CustomeSelectField
            label={t("UNIT.customer")}
            haswidth={true}
            value={props?.customer}
            list={props?.customers ? props?.customers : []}
            customGetOptionLabel={(option) => option?.user?.full_name}
            error={ErrorsObject?.customer_id?.error}
            message={ErrorsObject?.customer_id?.message}
            readOnly={false}
            focused={true}
            onChange={(e, newValue) => {
              props?.setCustomer(newValue);
              setErrorsObject(
                restCustomeObject(ErrorsObject, "customer_id")
              )
            }}
            onClearClick={() => {
              props?.setCustomer(null);
              setErrorsObject(
                restCustomeObject(ErrorsObject, "customer_id")
              );
            }}
          />
        </Box>
        <Box className="col-12 col-sm-12 col-md-6"></Box>
      </Box>
    </Box>
  );
};

const RenderFooter = (props) => {
  const [t] = useTranslation("common");
  const gridtheme = useSelector((state) => state.themeData.gridtheme);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Box display="flex">
          <Button
            variant="outlined"
            // spacing={2}
            sx={{
              width: "50% !important",
              // flexGrow: 1,
              minWidth: "80px !important",
              maxWidth: "80px !important",
              margin: 1,
              backgroundColor: "#f7f7f7",
              borderColor: gridtheme?.colorWhite,
              color: gridtheme?.colorblack,
              boxShadow: "0 0 7px -2px white",
              //   color:SearchButtonTheme?.clear_button_text_color,
              "&:hover": {
                backgroundColor: "#f7f7f7",
                borderColor: gridtheme?.colorWhite,
                color: gridtheme?.colorblack,
                boxShadow: "0 0 7px 1px white",
                boxShadow: "0",
              },
              height: "35px",
              fontFamily: "Cairo-Bold",
            }}
            className="iconeFilterClear"
            color="secondary"
            onClick={() => {
              props.setOpen(false);
            }}
          >
            {t("GLOBAL.close")}
          </Button>
          <Button
            variant="contained"
            // spacing={2}
            sx={{
              // flexGrow: 1,
              margin: 1,
              width: "80px !important",
              minWidth: "80px !important",
              maxWidth: "80px !important",
              "&:hover": {
                //   backgroundColor:SearchButtonTheme?.search_button_color+'88',
              },
              height: "35px",
              fontFamily: "Cairo-Bold",
            }}
            className="iconeFilterSearch"
            onClick={() => {
              props.submit();
            }}
          >
            {t("GLOBAL.Save")}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default function AssingCustomerToUnit(props) {
  const [t] = useTranslation("common");
  const [customer, setCustomer] = useState(null);
  const [customers, setCustomers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [ErrorsObject, setErrorsObject] = useState(ObjectOfErrors);

  

  const submit = async () => {
    try {
      setLoading(true);
      let dumData = resetErrors(ErrorsObject);

      let result = null;
      if (props?.id) {
        var params={}
        if(customer&&customer?.id){
          params={
            temp_customer_id: customer.id
          }
        }else{
          toast.warn("يجب اختيار الزبون")
          return false
        }

        if(props?.selectedUnitStatus&&props?.selectedUnitStatus?.id){
          params={
            ...params,
            unit_status_id: props?.selectedUnitStatus.id
          }
        }

        // data.append('_method','put')
        result = await unitAPI.updateUnitStatus({
          params: params,
          id:props?.id,
        });
      }

      if (result?.status) {
        setLoading(false);
        toast.success(t("NETWORKMESSAGE.addCustomerMessageSuccess"));
        clearForm();
        props.loadData();
        props.setAddForm(false);
      } else {
        // if (typeof result.errorMessage === "object") {
        //   let updatedObject = resetErrors(ErrorsObject);
        //   setErrorsObject(checkErrors(result.errorMessage, updatedObject));
        // }
        console.log('adasdsad=<',result)
        setLoading(false);
        if(result?.error){
          toast.error(result?.errorMessage);
        }else
        toast.error(t("NETWORKMESSAGE.addCustomerMessageError"));
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(error?.message);
    }
  };

  const clearForm = () => {
    // setName('')
  };

  useEffect(() => {
    console.log('asdasdadasdsa',props?.id)
    const abortController= new AbortController()
    loadDataOfCustomer()
    return()=>{
        abortController.abort()
    }
  }, [props?.addForm]);

    const loadDataOfCustomer=async()=>{
        let data=null;
        try{
            data=await customerAPI.customersList();
            if(data&&data?.status){
                setCustomers(data?.data)
            }else{
                toast.error(t('NETWORKMESSAGE.messageError'))
            }
        }catch(err){
            console.log(err?.message);
        }
    }

  return (
    <PopupForm
      open={props.addForm}
      setOpen={props.setAddForm}
      title={props?.object ? t("UNIT.assingCustomerFormTitle") : t("UNIT.assingCustomerFormTitle")}
      content={
        <RenderContent
          customer={customer}
          setCustomer={setCustomer}
          customers={customers}
          setCustomers={setCustomers}
          open={props.addForm}
          setOpen={props.setAddForm}
        />
      }
      footer={
        <RenderFooter
          open={props.addForm}
          setOpen={props.setAddForm}
          submit={submit}
        />
      }
    />
  );
}
