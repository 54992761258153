import { Box } from '@mui/material'
import React,{useState,useEffect} from 'react'
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { styled ,alpha} from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import ArchiveIcon from '@mui/icons-material/Archive';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useTranslation } from 'react-i18next';
import { setGlobalLoading } from '../../../reduxStore/SettingsReducer';
import { useDispatch, useSelector } from 'react-redux';
import entityAPI from '../../../Global/Network/Entity';
import Swal from 'sweetalert';
import { toast } from 'react-toastify';
import ImagePreview from '../../Components/ImagePreview';
const StyledMenu = styled((props) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      {...props}
    />
  ))(({ theme }) => ({
    '& .MuiPaper-root': {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      minWidth: 180,
      color:
        theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
      boxShadow:
        'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
      '& .MuiMenu-list': {
        padding: '4px 0',
      },
      '& .MuiMenuItem-root': {
        '& .MuiSvgIcon-root': {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
        '&:active': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity,
          ),
        },
      },
    },
  }));

export default function CardImage({itm,UnitId,loadDataOfUnit,isLessThenTwo}) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const screenwidth=useSelector(state=>state.settingsData.screenwidth)

    const open = Boolean(anchorEl);
    const [t]=useTranslation('common')
    const dispatch=useDispatch()
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const [openImagePreivew,setOpenImagePreivew]=useState(false)
    const [srcImage,setSrcImage]=useState('')

    const handleClose = () => {
      setAnchorEl(null);
    };

    const handleRemoveImage=async()=>{
        Swal({
            title: '?هل انت متأكد',
            text: "لن تتمكن من التراجع عن هذا!",
            icon: 'warning',
            buttons: ["الغاء!", "نعم!"],
            dangerMode: true,
          }).then(async(willDelete) => {
            console.log(' unit view delete image  1')
            if (willDelete) {
              console.log('unit view delete image 2')
        
              try{
                    let params = {};
                    dispatch(setGlobalLoading(true))
            
                    let result = await entityAPI.DeleteRequestFile({
                        params: params,
                        type:'unit',
                        id: itm?.id,
                    });
            
                    if (result?.status && result?.data?.success) {
                    loadDataOfUnit()
                    dispatch(setGlobalLoading(false))
                    setAnchorEl(null)
                    } else {
                    dispatch(setGlobalLoading(false))
                    setAnchorEl(null)
                    }
                  
              }catch(error){
                  dispatch(setGlobalLoading(false))
                  toast.error(t(error?.message))
              }
           }else{
            // alert('canceled')
           }
          })
          

    }
    const handleImageClick=(src)=>{
      setOpenImagePreivew(true)
      setSrcImage(src)
    }

    

  return (
    <Box sx={{
      width:'100%',

    }}
      
    >
           
    <Card sx={{
      height:screenwidth<1100?'fit-content':'fit-content',width:screenwidth<1100?'300px':'100%',
      '& img':{
        height:screenwidth<1100?'220px !important':'194px !important',
        objectFit:'cover',
      }
      }}>
      {/* <CardHeader
        // avatar={
        //   <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
        //     R
        //   </Avatar>
        // }
        action={
          <IconButton 
          aria-label="settings"
          id="demo-customized-button"
          aria-controls={open ? 'demo-customized-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          disableElevation
          onClick={handleClick}
          >
            <MoreVertIcon />
          </IconButton>
        }
        // title="Shrimp and Chorizo Paella"
        // subheader="September 14, 2016"
      /> */}
      <CardMedia
        component="img"
        height={screenwidth<1100?'220':"194"}
        image={process.env.REACT_APP_API_URL_image+itm?.image_url}
        alt={`${itm?.name} photo`}
        className="swiper-lazy"
        sx={{
          cursor:'pointer',
          height:screenwidth<1100?'220px':'194px',
          '& img':{
            height:screenwidth<1100?'220px !important':'194px !important',
          }
        }}
        onClick={()=>handleImageClick(process.env.REACT_APP_API_URL_image+itm?.image_url)}
      />
      <CardContent sx={{height:'fit-content'}}>
        <span style={{
          fontFamily:'Cairo-Bold',
          textAlign:'justify',
          wordWrap:'break-word',
          wordBreak:'break-all',
          spaceWord:'break-word',
          margin:'10px 0',
          display:'flex',
          justifyContent:'start',
          alignItems:'center',

          }}>{itm?.name}</span>
        <Typography variant="body2" color="text.secondary"
         sx={{
            fontFamily:'Cairo',
            textAlign: 'justify',
            textAlign:'justify',
            wordWrap:'break-word',
            wordBreak:'break-all',
            spaceWord:'break-word',
            overflowY:'scroll',
         }}
         className="cardSlideDescription"
        >
          {itm?.description}
        </Typography>
      </CardContent>
    </Card>

    {/* menu popup fro edit or delete image or any actions  */}
    <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClose} disableRipple sx={{fontFamily:'Cairo'}}>
          <EditIcon size={30} sx={{color:'#1e6a99'}}/>
          {t('GLOBAL.action_edit')}
        </MenuItem>
        <MenuItem onClick={handleRemoveImage} disableRipple sx={{fontFamily:'Cairo'}}>
          <DeleteIcon size={30} sx={{color:'#f00'}}/>
          {t('GLOBAL.action_delete')}
        </MenuItem>
 
    </StyledMenu>

    {/* show full image  */}
    {openImagePreivew?
    <ImagePreview
      open={openImagePreivew}
      setOpen={setOpenImagePreivew}
      src={srcImage}
    />:null}

    </Box>
  )
}
