import callAPI from '../ApiConfig'
import store from '../../../app/store'

const PaymentType = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"payment_types",
        method:"get",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
    
}
const PaymentTypeList = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"payment_types_list",
        method:"get",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
    
}
const addPaymentType = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"payment_types",
        method:"post",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        },
        data:props?.data,
    })
    
}
const updatePaymentType = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"payment_types/"+props?.data?.id,
        method:"put",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        },
        data:props?.data
    })
    
}
const deletePaymentType = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"payment_types/"+props?.data?.id,
        method:"delete",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        },
    })
    
}
const PaymentTypeAPI={
    PaymentType:PaymentType,
    PaymentTypeList:PaymentTypeList,
    addPaymentType:addPaymentType,
    updatePaymentType:updatePaymentType,
    deletePaymentType:deletePaymentType

}
export default PaymentTypeAPI