import callAPI from '../ApiConfig'
import store from '../../../app/store'

const customers = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"customers",
        method:"get",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    
    })
    
}
const addCustomer = async (props) => {
    const storeState = store.getState();
    

    var data = new FormData();

    
    return await callAPI({
        route:"customers",
        method:"post",
        signal:props?.signal,
        data:props?.data,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
    
}
const editCustomer = async (props) => {
    const storeState = store.getState();


    return await callAPI({
        route:"customers/"+props?.id,
        method:"put",
        signal:props?.signal,
        data:props?.data,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
}
const deleteCustomer = async (props) => {
    const storeState = store.getState();

    
    return await callAPI({
        route:"customers/"+props?.id,
        method:"delete",
        signal:props?.signal,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
}
const getCustomer = async (props) => {
    const storeState = store.getState();
   
   
    return await callAPI({
        route:"customer",
        method:"get",
        signal:props?.signal,
        params:{
            id:props?.id
        },
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
}
const customersList = async (props) => {
    const storeState = store.getState();

    return await callAPI({
        route:"customers_list",
        method:"get",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    
    })
    
}


const customerAPI={
    customers:customers,
    addCustomer:addCustomer,
    editCustomer:editCustomer,
    deleteCustomer:deleteCustomer,
    getCustomer:getCustomer,
    customersList:customersList
}
export default customerAPI