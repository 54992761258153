import { createSlice } from '@reduxjs/toolkit';

const initialState={
    rows:[],
}
export const compoundNestedSlice = createSlice({
  name: 'compoundNested',
  initialState:initialState,
  reducers: {
    
    setRowsRedux:(state,action)=>{
      state.rows=action.payload
    }
  }
});

export const {
  setRowsRedux
} = compoundNestedSlice.actions;



export default compoundNestedSlice.reducer;
