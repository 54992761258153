import callAPI from '../ApiConfig'
import store from '../../../app/store'

const callStatus = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"call_statuses",
        method:"get",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
    
}
const callStatusList = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"call_statuses_list",
        method:"get",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
    
}
const addCallStatus = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"call_statuses",
        method:"post",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        },
        data:props?.data,
    })
    
}
const updateCallStatus = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"call_statuses/"+props?.data?.id,
        method:"put",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        },
        data:props?.data
    })
    
}
const DeleteCallStatus = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"call_statuses/"+props?.data?.id,
        method:"delete",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        },
    })
    
}
const CallStatusAPI={
    callStatus:callStatus,
    callStatusList:callStatusList,
    addCallStatus:addCallStatus,
    updateCallStatus:updateCallStatus,
    DeleteCallStatus:DeleteCallStatus

}
export default CallStatusAPI