import callAPI from '../ApiConfig'
import store from '../../../app/store'

const purpose = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"purpose_of_purchases",
        method:"get",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
    
}
const purposeList = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"purpose_of_purchases_list",
        method:"get",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
    
}

const addPurpose = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"purpose_of_purchases",
        method:"post",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        },
        data:props?.data,
    })
    
}

const updatePurpose = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"purpose_of_purchases/"+props?.data?.id,
        method:"put",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        },
        data:props?.data
    })
    
}

const deletePurpose = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"purpose_of_purchases/"+props?.data?.id,
        method:"delete",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        },
    })
    
}
const PurposeAPI={
    Purpose:purpose,
    PurposeList:purposeList,
    AddPurpose:addPurpose,
    UpdatePurpose:updatePurpose,
    DeletePurpose:deletePurpose
}
export default PurposeAPI