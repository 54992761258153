import React from 'react'
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useTranslation } from "react-i18next";
import CloseIcon from '@mui/icons-material/Close';


export default function CustomeSelect(props) {
  const [t] = useTranslation("common");

    const handleChange=(e)=>{
      props.setValue(e.target.value)
    }
  return (
    <Box sx={{
        width:'100%',
        display:'flex',
        justifyContent:'center',
        alignItems:'start',
        "& .MuiInputLabel-root":{
            textAlign: "left", font: "normal normal  16px/30px Cairo-Bold", letterSpacing: "0px", color: "#22232B", opacity: "1"
           , padding:'0 10px 0px 0px',position:'relative',top:'25px  !important'
        },
        "& #demo-simple-select":{
            textAlign: "left",
             font: "normal normal  15px/29px Cairo !important", 
             letterSpacing: "0px", 
             color: "#22232B !important", 
             opacity: "1"
        },
        position:'relative',
        top:props?.customeTop?props?.customeTop:'-30px'
    }}>
        <FormControl sx={{width:'80%',position:'relative'}} variant='standard'>
        <InputLabel id="demo-simple-select-label" sx={{
            textAlign: "left", font: "normal normal  16px/30px Cairo-Bold", letterSpacing: "0px", color: "#22232B", opacity: "1"
        }}>{props?.title}</InputLabel>
        <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={props?.value}
            label={props?.title}
            onChange={handleChange}
            sx={{
                zIndex:999999,
               
            }}
        > 
        {
            props?.data&&props?.data?.length>0?props?.data?.map((itm)=>(
                <MenuItem value={itm?.id} key={itm?.id+'__'+itm?.name} sx={{
                    textAlign: "left", font: "normal normal  15px/29px Cairo", letterSpacing: "0px", color: "#22232B", opacity: "1"
                }}>{props?.hasLable?itm?.label:itm?.name}</MenuItem>
            )):
            <Box sx={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                <span>{props?.message}</span>
            </Box>
        }
        </Select>
        {props?.value?<CloseIcon sx={{fontSize:'23px',position:'absolute',top:'54px',zIndex:999999,borderRadius:'50%',right:'30px',backgroundColor:'#fcfafa',padding:'1px',cursor:'pointer',color:'grey',fill:'grey'}}  onClick={()=>props?.setValue('')} />:null}


        </FormControl>
   </Box>
  )
}
