import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Avatar, Box, CardActionArea, CardHeader, IconButton } from "@mui/material";
import SlideImages from "./SlideImages";
import { red } from '@mui/material/colors';
import { useSelector } from "react-redux";
export default function Project(props) {
  const screenwidth=useSelector(state=>state.settingsData.screenwidth)
  const isopensidemenu=useSelector(state=>state.settingsData.isopensidemenu)

  return (
    <Box sx={{
      width:screenwidth<700?'98%':'83.2%',margin:'15px 0',
      '& .MuiPaper-root':{
        padding:'10px 20px !important'
      }
      }} key={props?.id}>
      <Card 
      sx={{
        width:'100%',
    
    }}
      >
      {/* <CardHeader
        avatar={
          <Avatar 
          aria-label="recipe"
          src={props?.userImage}
          sx={{ width: 56, height: 56,bgcolor: red[500] }}
          >
            R
          </Avatar>
        }
        // action={
        //   <IconButton aria-label="settings">
        //     <MoreVertIcon />
        //   </IconButton>
        // }
        title={<span style={{fontFamily:'Cairo-Bold',fontSize:'24px'}}>{props?.user_name}</span>}
        subheader={<span style={{fontFamily:'Cairo',fontSize:'16px'}}>{props?.created_at}</span>}
      /> */}
          <CardContent
           sx={{
            padding:'10 30px !important',
           }}
          >
            <Typography gutterBottom variant="h5" component="div" sx={{
                fontFamily:'Cairo-Medium',
            }}>
             {props?.name}
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{
                fontFamily:'Cairo',
                textAlign:'justify',
                whiteSpace: 'pre-wrap',
            }}>
         
                {props?.description}
            </Typography>
          </CardContent>
              <Box sx={{
                margin:'10px 0 20px 0',
                width:screenwidth<600?isopensidemenu?'72vw':'85vw':screenwidth<880?isopensidemenu?'57vw':'70vw':isopensidemenu?'62vw':'75vw',
                display:'flex',
                justifyContent:'center',
                alignItems:'center',
                }}>
                {props?.images?.length>0?
                  <SlideImages {...props}/>:null
                }
               </Box>
        
      </Card>
    </Box>
  );
}
