import { Box,TextField,Button, FormControlLabel, Switch} from '@mui/material'
import React,{useState,useEffect}from 'react'
import PopupForm from '../../Components/PopupForm'
import {useTranslation} from 'react-i18next'
import {ContainerOfInput,ContainerOfInputFields,ContainerOfSelectField} from '../../RegisteredCustomers/ThemDesign'
import { useSelector } from 'react-redux'
import ObjectOfErrors,{checkErrors,resetErrors,restCustomeObject} from '../../../Global/Errors/Discounts/Discount'
import CloseIcon from '@mui/icons-material/Close';
import MaritalStatusAPI from '../../../Global/Network/MaritalStatus/index'
import {toast} from 'react-toastify'
import discountAPI from '../../../Global/Network/Discount/index'
import CustomTextField from '../../../General/CustomComponents/CustomTextField'
import CustomDateField from '../../../General/CustomComponents/CustomDateField'
import helpers from '../../../assets/js/helper'


const RenderContent=(props)=>{
    const [t]=useTranslation('common')
    const maintheme=useSelector(state=>state.themeData.maintheme);
    const [ErrorsObject,setErrorsObject]=useState(ObjectOfErrors)
    const handleChange=(e)=>{
       props.setName(e.target.value)
       setErrorsObject(restCustomeObject(ErrorsObject,'name'))
    }
    const clearInput=()=>{
       props.setName('')
       setErrorsObject(restCustomeObject(ErrorsObject,'name'))

    }

    return(
        <Box className='Container-fluid'>
            <Box className="row my-3">
                <Box className="col-12 col-sm-12 col-md-6">
                    <CustomTextField
                        label={t('DISCOUNTS.add_name')}
                        value={props?.name}
                        error={ErrorsObject?.name?.error}
                        message={ ErrorsObject?.name?.message}
                        haswidth={true}
                        readOnly={false}
                        focused={true}
                        onChange={(e) => {
                           props?.setName(e?.target?.value)
                           setErrorsObject(restCustomeObject(ErrorsObject,'name'))
                        }}
                        onClearClick={() => {
                            props?.setName('')
                            setErrorsObject(restCustomeObject(ErrorsObject,'name'))
                        }}
                    />
                     <Box sx={{margin:'15px 0'}}></Box>
                     <Box
                        sx={{
                            backgroundColor: '#fff',
                            boxShadow: '0 0 15px -1px rgba(0,0,0,0.2)',
                            padding: '7px 10px',
                            margin: '2px 10px',
                            '& .MuiTypography-root ': {
                                fontFamily: 'Cairo-Medium',
                            }
                        }}
                    >
                        <FormControlLabel
                            sx={{
                                width: '100%',
                                fontFamily: 'Cairo-Medium',
                            }}
                            control={<Switch
                                checked={props?.percentage_absolute}
                                onChange={(e) => {
                                    props?.setPercentage_absolute(e.target.checked)
                                }}
                            />} label={props?.percentage_absolute? t('DISCOUNTS.add_percentage_absolute') : t('DISCOUNTS.add_percentage_stabel')} />

                    </Box>

                    <Box sx={{margin:'15px 0'}}></Box>
                    <CustomDateField
                        label={t('DISCOUNTS.add_active_from')}
                        value={props?.active_from}
                        error={ErrorsObject?.active_from?.error}
                        message={ ErrorsObject?.active_from?.message}
                        haswidth={true}
                        readOnly={false}
                        minDate={new Date()}
                        maxDate={null}
                        onChange={(e) => {
                           props?.setActive_from(e)
                           setErrorsObject(restCustomeObject(ErrorsObject,'active_from'))
                        }}
                        onClearClick={() => {
                            props?.setActive_from('')
                            setErrorsObject(restCustomeObject(ErrorsObject,'active_from'))
                        }}
                    />
                     <Box sx={{margin:'12px 0'}}></Box>

                    <Box
                        sx={{
                            backgroundColor: '#fff',
                            boxShadow: '0 0 15px -1px rgba(0,0,0,0.2)',
                            padding: '7px 10px',
                            margin: '2px 10px',
                            '& .MuiTypography-root ': {
                                fontFamily: 'Cairo-Medium',
                            }
                        }}
                    >
                        <FormControlLabel
                            sx={{
                                width: '100%',
                                fontFamily: 'Cairo-Medium',
                            }}
                            control={<Switch
                                checked={props?.iqd_usd}
                                onChange={(e) => {
                                    props?.setIqd_usd(e.target.checked)
                                }}
                            />} label={props?.iqd_usd? "USD" : "IQD"} />

                    </Box>
                </Box>
                <Box className="col-12 col-sm-12 col-md-6">
                    <CustomTextField
                        label={t('DISCOUNTS.add_description')}
                        value={props?.description}
                        error={ErrorsObject?.description?.error}
                        message={ ErrorsObject?.description?.message}
                        hasMultipleLine={true}
                        haswidth={true}
                        readOnly={false}
                        onChange={(e) => {
                           props?.setDescription(e?.target?.value)
                           setErrorsObject(restCustomeObject(ErrorsObject,'description'))
                        }}
                        onClearClick={() => {
                            props?.setDescription('')
                            setErrorsObject(restCustomeObject(ErrorsObject,'description'))
                        }}
                    />
                    <Box sx={{margin:'10px 0'}}></Box>
                    <CustomTextField
                        label={t('DISCOUNTS.add_value')}
                        value={props?.value}
                        error={ErrorsObject?.value?.error}
                        message={ ErrorsObject?.value?.message}
                        haswidth={true}
                        readOnly={false}
                        onChange={(e) => {
                           if(!isNaN(e?.target?.value)){
                              props?.setValue(e?.target?.value)
                           }
                           setErrorsObject(restCustomeObject(ErrorsObject,'value'))
                        }}
                        onClearClick={() => {
                            props?.setValue('')
                            setErrorsObject(restCustomeObject(ErrorsObject,'value'))
                        }}
                    />
                    <Box sx={{margin:'10px 0'}}></Box>
                    <CustomDateField
                        label={t('DISCOUNTS.add_active_to')}
                        value={props?.active_to}
                        error={ErrorsObject?.active_to?.error}
                        message={ ErrorsObject?.active_to?.message}
                        haswidth={true}
                        readOnly={false}
                        minDate={props?.active_from}
                        maxDate={null}
                        onChange={(e) => {
                           props?.setActive_to(e)
                           setErrorsObject(restCustomeObject(ErrorsObject,'active_to'))
                        }}
                        onClearClick={() => {
                            props?.setActive_to('')
                            setErrorsObject(restCustomeObject(ErrorsObject,'active_to'))
                        }}
                    />

                </Box>
            </Box>
        </Box>
    )
}

const RenderFooter=(props)=>{
    const [t]=useTranslation('common')
    const gridtheme=useSelector(state=>state.themeData.gridtheme)

    return(
        <Box sx={{
            display:'flex',
            alignItems:'center',
        }}>
          

              <Box sx={{display:'flex',justifyContent: 'end',alignItems: 'center',width:'100%'}}>
          <Box display="flex" >
              
                  <Button variant="outlined"
                      // spacing={2}
                      sx={{
                            width:'50% !important',
                              // flexGrow: 1, 
                              minWidth:'80px !important',
                              maxWidth:'80px !important',
                              margin: 1,
                              backgroundColor:'#f7f7f7',
                              borderColor:gridtheme?.colorWhite,
                              color:gridtheme?.colorblack,
                             boxShadow:'0 0 7px -2px white',
                            //   color:SearchButtonTheme?.clear_button_text_color,
                          '&:hover':{
                            backgroundColor:'#f7f7f7',
                            borderColor:gridtheme?.colorWhite,
                            color:gridtheme?.colorblack,
                             boxShadow:'0 0 7px 1px white',
                             boxShadow:'0',


                          },
                          height:'35px',
                          fontFamily:'Cairo-Bold'

                      }}
                      className="iconeFilterClear"
                      color="secondary"
                      onClick={()=>{
                        props.setOpen(false)
                    }}
                        >
                      {t('GLOBAL.close')}
                  </Button>
                  <Button
                      variant="contained"
                      // spacing={2} 
                      sx={{ 
                          // flexGrow: 1,
                          margin: 1,
                          width:'80px !important',
                          minWidth:'80px !important',
                          maxWidth:'80px !important',
                          '&:hover':{
                            //   backgroundColor:SearchButtonTheme?.search_button_color+'88',
                          },
                          height:'35px',
                          fontFamily:'Cairo-Bold'
                      }}
                      className="iconeFilterSearch"
                      onClick={() => {
                            props.submit()
                      }} 
                      >
                      {t('GLOBAL.Save')}
                  </Button>
          </Box>
      </Box>
        </Box>
    )
}


export default function AddFormDailog(props) {
    const [t]=useTranslation('common');
    const [name,setName]=useState('')
    const [description,setDescription]=useState('')
    const [percentage_absolute,setPercentage_absolute]=useState(false)
    const [value,setValue]=useState('')
    const [active_from,setActive_from]=useState(null)
    const [active_to,setActive_to]=useState(null)
    const [iqd_usd,setIqd_usd]=useState(false)
    const [loading,setLoading]=useState(false)
    const [ErrorsObject,setErrorsObject]=useState(ObjectOfErrors)
    

    useEffect(()=>{
        if(props?.object&&props?.object?.id){
            let dumData=resetErrors(ErrorsObject)
           setName(props?.object?.name)
           setDescription(props?.object?.description)
           setValue(props?.object?.value)
           setPercentage_absolute(props?.object?.percentage_absolute==1?true:false)
           setActive_from(props?.object?.active_from?new Date(props?.object?.active_from):null)
           setActive_to(props?.object?.active_to?new Date(props?.object?.active_to):null)
           setIqd_usd(props?.object?.iqd_usd==1?true:false)

        }else{
            let dumData=resetErrors(ErrorsObject)
            clearForm()
        }
    },[props?.object])

    const submit=async()=>{
     try{
        setLoading(true)
        let dumData=resetErrors(ErrorsObject)
        let data={
            name:name,
            description:description,
            percentage_absolute:percentage_absolute?1:0,
            value:value,
            active_from:active_from?helpers.formatDate(active_from):null,
            active_to:active_to?helpers.formatDate(active_to):null,
            iqd_usd:iqd_usd?1:0
        }
        let result=null;
        if(props?.object&&props?.object?.id){
            data={
                ...data,
                id:props?.object?.id,
                _method:'put'
            }
             result=await discountAPI.updateDiscount({
            data:data
            });
        }else{
            result=await discountAPI.addDiscount({
                data:data
            });
        }
  
        if(result?.status){
          setLoading(false)
          toast.success(t('NETWORKMESSAGE.addCustomerMessageSuccess'))
          clearForm()
          props.loadData()
          props.setAddForm(false)
        }else{
          
           if (typeof result.errorMessage === "object") {
              let updatedObject = resetErrors(ErrorsObject)
              setErrorsObject(checkErrors(result.errorMessage, updatedObject))   
          }
          setLoading(false)
          toast.error(t('NETWORKMESSAGE.messageError'))
        }
        setLoading(false)
     }catch(error){
        setLoading(false)
        toast.error(error?.message)
     }
        
    }

    const clearForm=()=>{
        setName('')
        setDescription('')
        setActive_from(null)
        setActive_to(null)
        setIqd_usd(false)
        setPercentage_absolute(false)
        setValue('')
    }

    useEffect(()=>{
        if(!props?.object)
          clearForm()
    },[props?.addForm,props?.object])

  return (
       <PopupForm 
        open={props.addForm} 
        setOpen={props.setAddForm}
        title={props?.object?t('DISCOUNTS.updateTitle'):t('DISCOUNTS.addTitle')}
        content={<RenderContent 
            name={name} 
            setName={setName}
            description={description}
            setDescription={setDescription}
            setActive_from={setActive_from}
            active_from={active_from}
            setActive_to={setActive_to}
            active_to={active_to}
            iqd_usd={iqd_usd}
            setIqd_usd={setIqd_usd}
            value={value}
            setValue={setValue}
            setPercentage_absolute={setPercentage_absolute}
            percentage_absolute={percentage_absolute}
            open={props.addForm} 
            setOpen={props.setAddForm}
        />}
        footer={<RenderFooter
            open={props.addForm} 
            setOpen={props.setAddForm}
            submit={submit}
        />}
       />
  )
}
