import React from 'react'
import { Autocomplete, Box, TextField } from '@mui/material'
import { ContainerOfInputFields,ContainerOfSelectField } from '../../Admin/RegisteredCustomers/ThemDesign'
import CloseIcon from '@mui/icons-material/Close';
import { useSelector } from 'react-redux'
import * as RiIcons from 'react-icons/ri'
function CustomeSelectField({
    label,error,message,value,onChange,onClearClick,readOnly,
    list,haswidth,customGetOptionLabel,multiple,margin,focused
}) {
    const maintheme=useSelector(state=>state.themeData.maintheme);
    return (
        <ContainerOfSelectField
            mainTheme={maintheme}
            customeWidth={"100%"}
            isForm={true}
            haswidth={haswidth?true:false}
            hasError={error}
        >
            <Box
                sx={{
                    position: "relative",
                    // margin: "5px 0",
                    margin:margin?margin:'none',
                    width: "100%",
                    backgroundColor: "#fff",
                }}
                className="orderdata"
            >
                 <Autocomplete
                    // disablePortal
                    id="combo-box-demo"
                    options={list?list:[]}
                    value={value}
                    onChange={(e,newValue) => {
                        onChange && onChange(e,newValue)
                    }}
                    getOptionLabel={customGetOptionLabel?customGetOptionLabel:((option)=>{
                        
                        return option?.name
                    })||''}
                    popupIcon={<RiIcons.RiArrowDropDownLine/>}
                    InputProps={{
                        readOnly:readOnly,
                    }}
                    multiple={multiple}
                    clearIcon={<CloseIcon  sx={{fontSize:'20px',color:maintheme?.iconColor}} onClick={() => onClearClick && onClearClick()}/>}
                    renderInput={(params) => <TextField {...params} label={label}  variant="filled"  />}
                    className={`${error?'Mui-focused errors':'' } ${focused?'Mui-focused':''}`}
                />
            </Box>
            {
                error && message?.length ?
                    <Box sx={{
                        height: 'fit-content',
                        padding: '5px',
                        display: 'flex',
                        flexDirection: 'column',
                        flexWrap: 'wrap',
                        width: '95%',
                    }}>
                        {
                            message && message?.length > 0 ? message?.map((messg) => (
                                <span style={{ fontFamily: 'Cairo-Bold', fontSize: '14px', color: 'red', height: 'auto' }}>{messg}</span>
                            )) : null
                        }
                    </Box> : null}
        </ContainerOfSelectField>
    )
}

export default CustomeSelectField