import React, { useState, useRef, useEffect } from 'react';

import GoogleMapReact,{Marker} from 'google-map-react';
import {Box,Button} from '@mui/material'
import {useTranslation} from "react-i18next";
import {useSelector,useDispatch} from 'react-redux'
import { toast } from 'react-toastify';
import Queue from 'tinyqueue'
import * as ImIcons from 'react-icons/im'
// import GoogleMapReact from 'google-map-react';

const AnyReactComponent = ({ text }) =><ImIcons.ImLocation style={{color:'red',fontSize:'30px',position:'relative',top:'-10px',left:'15px'}}/>

const GoogleMap = (props) => {
  const MapRef = useRef(null);
  const [googleMapInitialized, setGoogleMapInitialized] = useState(false)
  const [draggable, setDraggable] = useState(true)
  const [markers, setMarkers] = useState(props?.marks)
  const [isFullScreen,setIsFullScreen]=useState({
    position:'relative',
    width:'100%',
    height:'100%',
    top:0,
    left:0,
    bottom:0,
    right:0
  })
  const [isFullScreenMode,setIsFullScreenMode]=useState(false)
  // const [marks,setMarks]=useState({
  //   lat:0,
  //   lng:0
  // })
  const [defaultProps,setdefaultProps]=useState({
    center: {
      lat: 33.33, lng: 44.44 
    },
    zoom: 7
  })



useEffect(()=>{
  setMarkers(props?.marks)
},[props?.marks])

const handleApiLoaded = (map, maps) => {
  MapRef.current = map;
  setGoogleMapInitialized(true)
  const bounds = new window.google.maps.LatLngBounds();

    const location = new window.google.maps.LatLng(
      props?.marks.lat,
      props?.marks.lng
    );
    bounds.extend(location);
    map.fitBounds(bounds)

};
const handleClick=(e)=>{
   console.log('googlemapClicked',e)
   props.setMarks({
    lat:e?.lat,
    lng:e?.lng
   })

   setMarkers({
    lat:e?.lat,
    lng:e?.lng
   })
   setDraggable(true)
}

const changeIngoogleMaps=(event)=>{
  if(event&&event?.size&&event?.size?.width>=window.screen.width&&event?.size?.height>=window.screen.height){
     setIsFullScreen({
      ...isFullScreen,
      position:'fixed',
      top:0,
      left:0,
     })
     setIsFullScreenMode(true)
     return
  }else{
    setIsFullScreen({
      ...isFullScreen,
      position:'relative',
      top:0,
      left:0,
     })
     setIsFullScreenMode(false)
     return
  }
}
const onChildMouseDown = (hoverKey, childProps) => {
  setDraggable(false)
}
const onChildMouseUp = (hoverKey, childProps) => {
  setDraggable(true)
}
const onChildMouseMove = (hoverKey, childProps, mouse) => {
  let tempMarkers = {
    lat: mouse?.lat,
    lng: mouse?.lng
  }

  props?.setMarks(tempMarkers)
  setMarkers(tempMarkers)
}


  return (
    <GoogleMapReact
     ref={MapRef}
     defaultCenter={defaultProps.center}
     defaultZoom={defaultProps.zoom}
     layerTypes={[
      // 'TrafficLayer',
      'TransitLayer'
    ]}
    bootstrapURLKeys={{
      key: process.env.REACT_APP_GOOGLE_API_KEY,
      language: 'ar',
      region: 'iq',
      libraries: ['places'],
      // ...otherUrlParams,
    }}
    style={isFullScreen}
    onClick={handleClick}
    
    onChange={changeIngoogleMaps}
    // onChildMouseDown={draggable && onChildMouseDown}
    // onChildMouseUp={draggable && onChildMouseUp}
    // onChildMouseMove={!draggable && onChildMouseMove}
    // draggable={draggable}
    yesIWantToUseGoogleMapApiInternals
    onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
    options={{
      scrollwheel:isFullScreenMode?true:false,
    }}
  >
    <AnyReactComponent
      lat={markers?.lat}
      lng={markers?.lng}
      text="My Marker"
    />
  </GoogleMapReact>
  );

}

export default GoogleMap;



