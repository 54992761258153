import { Box, IconButton, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import ReactTooltip from "react-tooltip";
import Preloading from "../Preload/Preloading";


import MapChart from "./MapChart";
import "./iraq_map.css";
import { ImTree } from "react-icons/im";
import ApartmentIcon from '@mui/icons-material/Apartment';
import PinDropIcon from '@mui/icons-material/PinDrop';

const IraqMap = (props) => {
  const [content, setContent] = useState("");
  const [markers, setMarkers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [governoratesIds, setGovernoratesIds] = useState([])
  useEffect(() => {
    let tempMarkers = []
    let tempGovernoratesIds = []
    if (props?.compounds?.length > 0) {
      props?.compounds?.map((compound,index) => {
        if (compound?.addresses?.length > 0) {
          compound?.addresses?.map(address => {
            tempGovernoratesIds?.push(address?.governorate_id)
            tempMarkers?.push({
              type:'compound',
              type_id:compound?.id,
              address_id:address?.id,
              markerOffset: -30,
              name: compound?.name,
              coordinates: [address?.longitude, address?.latitude],
              address: address,
              availableUnits: (compound?.available_units_counts) +" وحدة متوفرة"
            })
          })
        }
      })
    }
    setGovernoratesIds(tempGovernoratesIds)
    setMarkers(tempMarkers)
    setLoading(false)
    return () => {

    }
  }, [])

  return (
    <div style={{
      width: '100%',
      height: '100%'
    }}>
      {
      loading ? <Preloading /> :
        <>
          <MapChart
            setTooltipContent={setContent}
            tooltipContent={content}
            markers={markers}
            governoratesIds={governoratesIds}
          />
          {/* <div data-tip = "your placeholder" style={{width:100,height:100,backgroundColor:'red'}}>ldkfldj</div> */}
          <ReactTooltip
            className={'iraq-map'}
            backgroundColor="#cdd4d9"
            getContent={function () {
              return (
                <Box sx={{
                  display: 'flex',
                  width: '100%',
                  paddingRight:'20px',
                  minWidth:'300px',
                  flexDirection: 'column'
                }}>
                  <Box sx={{
                    display: 'flex',
                    flex: 1,
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    alignItems: 'center'
                  }}>
                    <IconButton sx={{
                      flex: 0.2
                    }}>
                      <ApartmentIcon sx={{color:"#349cdd"}}  />
                    </IconButton>
                    <Typography
                      variant="h4"
                      sx={{
                        flex: 1,
                        color: '#000000',
                        fontFamily:"Cairo-Bold"
                      }}
                    >
                      {content?.name}
                    </Typography>
                  </Box>
                  <Box sx={{
                    display: 'flex',
                    flex: 1,
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    alignItems: 'center'
                  }}>
                    <IconButton sx={{
                      flex: 0.2
                    }}>
                      <PinDropIcon sx={{color:"#349cdd"}} />
                    </IconButton>
                    <Typography
                      variant="h4"
                      sx={{
                        flex: 1,
                        color: '#000000',
                        fontFamily:"Cairo"
                      }}
                    >
                      {content?.address?.address}
                    </Typography>
                  </Box>
                  <Box sx={{
                    display: 'flex',
                    flex: 1,
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    alignItems: 'center'
                  }}>
                    <IconButton sx={{
                      flex: 0.2
                    }}>
                      <ImTree color="#349cdd" />
                    </IconButton>
                    <Typography
                      variant="h4"
                      sx={{
                        flex: 1,
                        color: '#000000',
                        fontFamily:"Cairo"
                      }}
                    >
                      {content?.availableUnits}
                    </Typography>
                  </Box>

                </Box>
              )
            }}
          />
        </>
      }
    </div >
  );
}

export default IraqMap