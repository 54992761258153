import { Box } from '@mui/system'
import React from 'react'
import { useTranslation } from 'react-i18next'
import helpers from '../../../assets/js/helper'

export default function Movements(props) {
  const [t]=useTranslation('common')

  const handleGetUserImageUrl=(images)=>{
    return process.env.REACT_APP_API_URL_image+images[images?.length-1]?.image_url;
  }

  return (
    <Box
      sx={{
        minHeight:'100px',
        maxHeight:'500px',
        overflowY:'scroll',
        padding:'0 10px',
        marginTop:'10px',
      }}
    >
        <Box 
          sx={{
            display:'flex',
            justifyContent:'center',
            alignItems:'start',
            fontFamily:'Cairo',
            flexDirection:'column',
            margin:'20px 0'
          }}
        >
          {
            props?.data&&props?.data?.length?props?.data?.map((itm)=>(
           <Box
             sx={{
              width:'100%',
              height:'fit-content',
              minHeight:'100px',
              borderRadius:'5px',
              background: '#fafafa',
              boxShadow:  '5px 5px 14px #d7d7d7e0, -5px -5px 14px #ffffff',
              margin:'10px 0',
              display:'flex',
              alignItems:'start',
              justifyContent:'center',
              flexDirection:'column',
              padding:'10px'

             }}
           >

            <Box
              sx={{
                display:'flex',
                justifyContent:'start',
                alignItems:'start',
                flexDirection:'column',
                width:'100%'
              }}
            >
              <Box
               sx={{
                display:'flex',
                justifyContent:'space-between',
                alignItems:'center',
                width:'100%'
               }}
              >
                <Box
                  sx={{
                    display:'flex',
                  alignItems:'center'
                  }}
                >
                    <img 
                      src={itm?.user?.userable?.images?.length>0?handleGetUserImageUrl(itm?.user?.userable?.images):"https://cdn-icons-png.flaticon.com/512/6076/6076455.png"}
                      alt="" 
                      style={{
                        width:'60px',
                        height:'60px',
                        borderRadius:'50%',
                        border:'2px solid lightgray',
                        objectFit:'cover',
                        backgroundPosition:'center center'
                      }}
                    />
                    <span
                    style={{
                      fontFamily:'Cairo-Bold',
                      color:'#000',
                      margin:'0 10px'
                    }}
                    >{itm?.user?.full_name}</span>

                </Box>
                <Box
                  sx={{
                    width:'fit-content',
                    minWidth:'100px',
                    height:'27px',
                    backgroundColor:'#1e6a99',
                    color:'#fff',
                    fontFamily:'Cairo',
                    display:'flex',
                    justifyContent:'center',
                    alignItems:'center',
                    textAlign:'justify',
                    padding:'5px 10px',
                    borderRadius:'17px',
                    fontSize:'12px'
                  }}
                >
                   <span style={{
                    fontFamily:'Cairo',
                    margin:'0 5px'
                   }}>
                      تاريخ الأنشاء
                    </span> 
                    <span> {itm?.created_at?helpers.formatTime(itm?.created_at)+' '+helpers.formatDate(itm?.created_at):null}</span>
                </Box>
              </Box>
              <Box
                sx={{
                  display:'flex',
                  justifyContent:'start',
                  alignItems:'start',
                  flexDirection:'column',
                  margin:'10px 0'
                }}
              >
                <span
                  style={{
                    fontFamily:'Cairo-Medium',
                    fontSize:'14px'
                  }}
                >{itm?.activity_log_type?.name}</span>
                <span
                style={{
                  fontFamily:'Cairo-Light',
                  color:'#000',
                  textAlign:'justify',
                  wordBreak:'break-all',
                  fontSize:'12px'
                }}
                >{itm?.name}</span>
              </Box>
            </Box>
    

           </Box>)):
           <Box
             sx={{
              display:'flex',
              alignItems:'center',
              justifyContent:'center',
              fontFamily:'Cairo',
              color:'#000'
             }}
           >
            {t('GLOBAL.noData')}
           </Box>
}
        </Box>
    </Box>
  )
}
