import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { Box } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Popup(props) {
  const logintheme = useSelector((state) => state.themeData.logintheme);
  const closePopupFunction = () => {
    props?.setOpen(false);
  };
  return (
    <Box>
      <Dialog
        open={props?.open}
        TransitionComponent={Transition}
        keepMounted
        onClose={closePopupFunction}
        aria-describedby="alert-dialog-slide-description"
        fullWidth={true}
        maxWidth={props?.sizeType ? props?.sizeType : "lg"}
        PaperProps={{
          style: {
            width: props?.CustomeWidth ? props?.CustomeWidth : "100%",
            height: props.CustomeHeight ? props.CustomeHeight : "100%",
            borderRadius: "10px",
            backgroundColor: logintheme?.paperColor,
            color: logintheme?.primaryColor,
            boxShadow: "0px 3px 6px #0000003C",
            borderRadius: "10px",
            padding: "10px 20px",
            display: "flex",
            padding: "0 !important",
          },
        }}
        BackdropProps={{
          style: {
            background: "rgba(255, 255, 255, 0.1)",
            backdropFilter: "blur(3px)",
          },
        }}
        sx={{
          transition: "all 0.9s ease",
          "& .MuiDialogContent-root": {
            overflow: "hidden",
          },
        }}
      >
        {props?.hasTitle ? <DialogTitle>{props?.title}</DialogTitle> : null}
        <DialogContent>{props?.children}</DialogContent>
        <DialogActions>
          {props?.hasButton1 ? (
            <Button onClick={props.handlebutton1}>{props?.button1Title}</Button>
          ) : null}
          {props?.hasButton2 ? (
            <Button onClick={props?.handlebutton2}>
              {props?.button2Title}
            </Button>
          ) : null}
        </DialogActions>
      </Dialog>
    </Box>
  );
}
