import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import Typography from "@mui/material/Typography";
import { useSelector, useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { useTranslation } from "react-i18next";
import MapIcon from "@mui/icons-material/Map";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialAction from "@mui/material/SpeedDialAction";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import TelegramIcon from "@mui/icons-material/Telegram";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton, Tooltip } from "@mui/material";
import { setOpenAdvanceSearch } from "../../../reduxStore/SettingsReducer";

const StyledSpeedDial = styled(SpeedDial)(({ theme, gridtheme }) => ({
  // position: 'relative',
  position: "relative",
  // top:0,
  // left:'50px',
  // zIndex:3000,
  // display:'block',
  width: "50px",
  height: "36px",
  "& .muiltr-1i5alow-MuiButtonBase-root-MuiFab-root-MuiSpeedDial-fab": {
    width: 40,
    height: 40,
    backgroundColor: `${gridtheme?.primaryColor}44 !important`,
  },
  backgroundColor: `${gridtheme?.primaryColor}44 !important`,
  padding: 0,
  // '&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft': {
  //   bottom: 0,
  //   right:0,
  // },
  // '&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight': {
  //   top: 0,
  //   left: 0,
  //   backgroundColor:`${gridtheme?.primaryColor}44 !important`,
  // },
  "&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft": {
    // bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  "&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight": {
    // top: theme.spacing(2),
    left: theme.spacing(2),
  },
}));

export const CustomeActionButoon=(props)=>{
  const { t, i18n } = useTranslation("common");
  const sideMenuMode = useSelector((state) => state.settingsData.sideMenuMode);
  const SearchButtonTheme = useSelector(
    (state) => state.settingsData.SearchButtonTheme
  );
  const toastId = React.useRef(null);
  const FilterButtonHeight = useSelector(
    (state) => state.settingsData.FilterButtonHeight
  );
  const directions = useSelector((state) => state.settingsData.directions);
  const gridtheme = useSelector((state) => state.themeData.gridtheme);
  const screenwidth = useSelector((state) => state.settingsData.screenwidth);
  const open_advance_search = useSelector(
    (state) => state.settingsData.open_advance_search
  );
  const dispatch=useDispatch()

  const maintheme = useSelector((state) => state.themeData.maintheme);
  const [actions, setActions] = useState([]);
  const [hidden, setHidden] = React.useState(false);
  useEffect(() => {
    const emptyArray = [];
    if (props?.hasExport) {
      emptyArray.push({
        icon: (
          <FileDownloadIcon
            onClick={props?.exportFunction}
            sx={{ color: gridtheme?.tooltip_export_text + " !important" }}
          />
        ),
        color: gridtheme?.tooltip_export,
        name: t("TOOLTIP.EXPORT"),
      });
    }
    if (props?.hasCreate) {
      emptyArray.push({
        icon: (
          <AddCircleOutlineIcon
            onClick={props?.createFunction}
            sx={{ color: gridtheme?.tooltip_create_text + " !important" }}
          />
        ),
        color: gridtheme?.tooltip_create,
        name: t("TOOLTIP.CREATE"),
      });
    }
    if (props?.hasMaps) {
      emptyArray.push({
        icon: (
          <MapIcon
            onClick={props?.mapFunction}
            sx={{ color: gridtheme?.tooltip_map_text + " !important" }}
          />
        ),
        color: gridtheme?.tooltip_map,
        name: t("TOOLTIP.MAP"),
      });
    }
    if (props?.hasImport) {
      emptyArray.push({
        icon: (
          <FileUploadIcon
            onClick={props?.importFunction}
            sx={{ color: gridtheme?.tooltip_import_text + " !important" }}
          />
        ),
        color: gridtheme?.tooltip_import,
        name: t("TOOLTIP.IMPORT"),
      });
    }
    if (props?.hasTelegram) {
      emptyArray.push({
        icon: (
          <TelegramIcon
            onClick={props?.telegramFunction}
            sx={{ color: gridtheme?.tooltip_telegram_text + " !important" }}
          />
        ),
        color: gridtheme?.tooltip_telegram,
        name: t("TOOLTIP.SEND_TELEGRAM"),
      });
    }
    if (props?.hasView) {
      emptyArray.push({
        icon: (
          <RemoveRedEyeIcon
            onClick={props?.viewFunction}
            sx={{ color: gridtheme?.tooltip_view_text + " !important" }}
          />
        ),
        color: gridtheme?.tooltip_view,
        name: t("TOOLTIP.VIEW"),
      });
    }
    setActions(emptyArray);
  }, [props, SearchButtonTheme]);
  return(
    <Box
    sx={{
      position: "relative",
      "& .muiltr-1n0nj1r,& .muirtl-1n0nj1r,& .muiltr-1i5alow-MuiButtonBase-root-MuiFab-root-MuiSpeedDial-fab,& .muiltr-118zhtq-MuiButtonBase-root-MuiFab-root-MuiSpeedDial-fab":
        {
          width: "37px !important",
          height: "35px !important",
          minHeight: "35px",
          minWidth: "37px",
          border: "none !important",
          outline: "none !important",
          backgroundColor: `${
            screenwidth <= 1000 ? "#1e6a99" : gridtheme?.colorWhite
          } !important`,
        },
      "& .muiltr-1n0nj1r, & .muirtl-1n0nj1r,& .muirtl-1i5alow-MuiButtonBase-root-MuiFab-root-MuiSpeedDial-fab,& .muirtl-118zhtq-MuiButtonBase-root-MuiFab-root-MuiSpeedDial-fab":
        {
          width: "37px !important",
          height: "35px !important",
          minHeight: "35px",
          minWidth: "37px",
          border: "none !important",
          outline: "none !important",
          backgroundColor: `${
            screenwidth <= 1000 ? "#1e6a99" : gridtheme?.colorWhite
          } !important`,
        },
    }}
  >
    <StyledSpeedDial
      ariaLabel="SpeedDial playground example"
      hidden={hidden}
      icon={
        <MoreVertIcon
          sx={{
            color: screenwidth <= 1000 ? "#fff" : gridtheme?.lightblack,
          }}
        />
      }
      direction={props?.arrowDirction}
      sx={{
        "& #SpeedDialplaygroundexample-actions .MuiButtonBase-root:nth-child(1)":
          {
            // backgroundColor:'green !important',
            color: "#ffffff",
            position: "relative",
            // top:'-15px',
            left: props?.arrowDirction=='left'?"20px":"-20px",
            width: "37px !important",
            height: "35px !important",
            minHeight: "35px",
            minWidth: "37px",
          },
        "& #SpeedDialplaygroundexample-actions .MuiButtonBase-root:nth-child(2)":
          {
            // backgroundColor:'blue !important',
            color: "#ffffff",
            position: "relative",
            // top:'-25px',
            left: props?.arrowDirction=='left'?"30px":"-30px",
            width: "37px !important",
            height: "35px !important",
            minHeight: "35px",
            minWidth: "37px",
          },
        "& #SpeedDialplaygroundexample-actions .MuiButtonBase-root:nth-child(3)":
          {
            // backgroundColor:'#c40c3d !important',
            color: "#ffffff",
            position: "relative",
            // top:'-30px',
            left: props?.arrowDirction=='left'?"35px":"-35px",
            width: "37px !important",
            height: "35px !important",
            minHeight: "35px",
            minWidth: "37px",
          },
        "& #SpeedDialplaygroundexample-actions .MuiButtonBase-root:nth-child(4)":
          {
            // backgroundColor:'orange !important',
            color: "#ffffff",
            position: "relative",
            // top:'-35px',
            left: props?.arrowDirction=='left'?"40px":"-40px",
            width: "37px !important",
            height: "35px !important",
            minHeight: "35px",
            minWidth: "37px",
          },
      }}
    >
      {actions.map((action) => (
        <SpeedDialAction
          key={action.name}
          icon={action.icon}
          tooltipTitle={
            <span style={{ fontFamily: "Cairo-Medium" }}>
              {action.name}
            </span>
          }
          sx={{
            backgroundColor: action?.color ? action?.color : "#00f",
            "&:hover": {
              backgroundColor: action?.color
                ? action?.color
                : "#0000ff",
            },
          }}
        />
      ))}
    </StyledSpeedDial>
  </Box>
  )
}

export default function CustomeFilter(props) {
  const { t, i18n } = useTranslation("common");
  const sideMenuMode = useSelector((state) => state.settingsData.sideMenuMode);
  const SearchButtonTheme = useSelector(
    (state) => state.settingsData.SearchButtonTheme
  );
  const toastId = React.useRef(null);
  const FilterButtonHeight = useSelector(
    (state) => state.settingsData.FilterButtonHeight
  );
  const directions = useSelector((state) => state.settingsData.directions);
  const gridtheme = useSelector((state) => state.themeData.gridtheme);
  const screenwidth = useSelector((state) => state.settingsData.screenwidth);
  const open_advance_search = useSelector(
    (state) => state.settingsData.open_advance_search
  );
  const dispatch=useDispatch()

  const maintheme = useSelector((state) => state.themeData.maintheme);
  const [actions, setActions] = useState([]);
  const [hidden, setHidden] = React.useState(false);
  // const {search_button_color,clear_button_color,search_button_text_color,clear_button_text_color} =SearchButtonTheme



  return (
    <Box
      sx={{
        width: screenwidth <= 1000 ? "100% !imporant" : "280px !important",
        minWidth: screenwidth <= 1000 ? "100% !imporant" : "280px !important",
        maxWidth: screenwidth <= 1000 ? "100% !imporant" : "280px !important",
        minHeight: screenwidth <= 1000 ? "100vh" : "300px",
        maxHeight: screenwidth <= 1000 ? "100vh" :window.innerHeight - 40,
        backgroundColor: gridtheme?.paperColor,
        boxShadow:
          screenwidth <= 1000
            ? ` 0px 4px 7px -4px rgb(0 0 0 / 64%)`
            : ` 0px 2px 7px -4px rgb(0 0 0 / 64%)`,
        borderRadius: "5px",
        margin: "0px 10px 0 10px",
        // paddingTop:'10px',
        // paddingBottom:'20px',

        // padding: "0 10px",
        "& p , & span ,& .MuiInputLabel-root": {
          fontSize: "15px !important",
        },

        "& .MuiOutlinedInput-root": {
          borderColor: `1px solid ${gridtheme?.paperTextColor} !important`,
          color: gridtheme?.disabledGlobalText
            ? gridtheme.gloablTextColor
            : gridtheme?.paperTextColor,
        },
        "& .MuiInputLabel-root": {
          // backgroundColor:`${gridtheme?.paperTextColor+'22'} !important`,
          color: gridtheme?.disabledGlobalText
            ? gridtheme.gloablTextColor
            : gridtheme?.paperTextColor,
        },
        "& .MuiFormControl-root": {
          // border:`1px solid ${gridtheme?.paperTextColor} !important`,
          color: gridtheme?.disabledGlobalText
            ? gridtheme.gloablTextColor
            : gridtheme?.paperTextColor,
        },
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            borderColor: gridtheme?.disabledGlobalText
              ? gridtheme.gloablTextColor
              : gridtheme?.paperTextColor,
          },
          "&:hover fieldset": {
            borderColor: gridtheme?.disabledGlobalText
              ? gridtheme.gloablTextColor
              : gridtheme?.paperTextColor,
          },
          "&.Mui-focused fieldset": {
            borderColor: gridtheme?.disabledGlobalText
              ? gridtheme.gloablTextColor
              : gridtheme?.secondaryColor,
          },
        },
        "& .MuiOutlinedInput": {
          // borderColor:`1px solid ${maintheme?.paperTextColor} !important`,
        },
        "& .MuiOutlinedInput-notchedOutline": {
          // border: "0 none",
        },
        "& .iconeFilterSearch": {
          backgroundColor: `${maintheme.grid_search_icon} !important`,
          color: `${maintheme.grid_search_icon_text} !important`,
          padding: "0 40px !important",
        },
        "& .iconeFilterClear": {
          backgroundColor: `transparent `,
          borderColor: `${maintheme.grid_clear_icon} !important`,
          outlineColor: `${maintheme.grid_clear_icon} !important`,
          color: `${maintheme.grid_clear_icon_text} !important`,
          padding: "0 40px !important",
          // '&:hover':{
          //    backgroundColor:`${clear_button_color} !important`,
          // },
        },
        "& .muirtl-2jbvvl-MuiButtonBase-root-MuiButton-root": {
          border: ` 1px solid ${maintheme.grid_clear_icon} !important`,
          outlineColor: `${maintheme.grid_clear_icon} !important`,
        },
        "& .muirtl-1a1fmpi-MuiInputBase-root-MuiInput-root, & .muirtl-1ptx2yq-MuiInputBase-root-MuiInput-root":
          {
            paddingLeft: "5px",
          },
        "& .muirtl-1a1fmpi-MuiInputBase-root-MuiInput-root:before,& .muirtl-1ptx2yq-MuiInputBase-root-MuiInput-root:before":
          {
            borderColor: `${
              gridtheme?.disabledGlobalText
                ? gridtheme.gloablTextColor
                : gridtheme?.paperTextColor
            } !important`,
            outlineColor: `${
              gridtheme?.disabledGlobalText
                ? gridtheme.gloablTextColor
                : gridtheme?.paperTextColor
            } !important`,
          },
        "& .muirtl-a3l6o-MuiInputBase-root-MuiInput-root-MuiSelect-root:before,& .muiltr-1ptx2yq-MuiInputBase-root-MuiInput-root:before,& .muiltr-1a1fmpi-MuiInputBase-root-MuiInput-root:before,& .muiltr-a3l6o-MuiInputBase-root-MuiInput-root-MuiSelect-root:before,& .muirtl-1a1fmpi-MuiInputBase-root-MuiInput-root:hover:before, &.muirtl-1ptx2yq-MuiInputBase-root-MuiInput-root:hover:before":
          {
            borderColor: `${
              gridtheme?.disabledGlobalText
                ? gridtheme.gloablTextColor
                : gridtheme?.paperTextColor
            } !important`,
            outlineColor: `${
              gridtheme?.disabledGlobalText
                ? gridtheme.gloablTextColor
                : gridtheme?.paperTextColor
            } !important`,
          },
        "& .muirtl-1rxz5jq-MuiSelect-select-MuiInputBase-input-MuiInput-input.MuiSelect-select":
          {
            display: "flex",
            justifyContent: "start",
          },
        "&.muirtl-iqd1hh-MuiButtonBase-root-MuiChip-root .MuiChip-deleteIcon,& .muirtl-1ptx2yq-MuiInputBase-root-MuiInput-root,& .muirtl-a3l6o-MuiInputBase-root-MuiInput-root-MuiSelect-root,& .muiltr-a3l6o-MuiInputBase-root-MuiInput-root-MuiSelect-root,& svg,& span,& p,& .muiltr-1ptx2yq-MuiInputBase-root-MuiInput-root":
          {
            color: gridtheme?.disabledGlobalText
              ? gridtheme.gloablTextColor
              : gridtheme?.paperTextColor + " !important",
          },
        "& .muirtl-iqd1hh-MuiButtonBase-root-MuiChip-root,& .muiltr-iqd1hh-MuiButtonBase-root-MuiChip-root":
          {
            backgroundColor: gridtheme?.secondaryColor + "44 !important",
          },
        "& .muiltr-iqd1hh-MuiButtonBase-root-MuiChip-root svg,& .muirtl-iqd1hh-MuiButtonBase-root-MuiChip-root svg,& .muiltr-wpkdf9-MuiModal-root-MuiDialog-root svg":
          {
            color: gridtheme?.disabledGlobalText
              ? gridtheme.gloablTextColor
              : gridtheme?.paperTextColor + " !important",
          },

        "& .muirtl-1yf1g2m::before,& .muiltr-1yf1g2m::before,& .muiltr-1vv4lmi::before,& .muirtl-1vv4lmi::before":
          {
            borderColor: `${
              gridtheme?.disabledGlobalText
                ? gridtheme.gloablTextColor
                : gridtheme?.paperTextColor
            } !important`,
            outlineColor: `${
              gridtheme?.disabledGlobalText
                ? gridtheme.gloablTextColor
                : gridtheme?.paperTextColor
            } !important`,
          },
        "& .muiltr-m91o2i-MuiFormControlLabel-root .MuiFormControlLabel-label,& .muirtl-m91o2i-MuiFormControlLabel-root .MuiFormControlLabel-label,& .muiltr-19d186v-MuiFormControlLabel-root .MuiFormControlLabel-label , & .muirtl-19d186v-MuiFormControlLabel-root .MuiFormControlLabel-label , & input,& .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.muiltr-yi9xwg .MuiAutocomplete-inputRoot,& .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.muirtl-yi9xwg .MuiAutocomplete-inputRoot ,& .muiltr-1vv4lmi,& .muirtl-1vv4lmi,& .muirtl-o1tw8u .MuiFormControlLabel-label,& .muiltr-o1tw8u .MuiFormControlLabel-label,& .muirtl-3m5ia .MuiInput-root .MuiInput-input,& .muiltr-3m5ia .MuiInput-root .MuiInput-input,& span":
          {
            color: gridtheme?.disabledGlobalText
              ? gridtheme.gloablTextColor
              : gridtheme?.paperTextColor + " !important",
          },
        "& .muirtl-khgl9y .MuiFormControlLabel-label,& .muiltr-khgl9y .MuiFormControlLabel-label,& .muiltr-19d186v-MuiFormControlLabel-root .MuiFormControlLabel-label , & .muirtl-19d186v-MuiFormControlLabel-root .MuiFormControlLabel-label ,& input,& .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.muiltr-yi9xwg .MuiAutocomplete-inputRoot,& .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.muirtl-yi9xwg .MuiAutocomplete-inputRoot ,& .muiltr-1vv4lmi,& .muirtl-1vv4lmi,& .muirtl-o1tw8u .MuiFormControlLabel-label,& .muiltr-o1tw8u .MuiFormControlLabel-label,& .muirtl-3m5ia .MuiInput-root .MuiInput-input,& .muiltr-3m5ia .MuiInput-root .MuiInput-input,& span":
          {
            color: gridtheme?.disabledGlobalText
              ? gridtheme.gloablTextColor
              : gridtheme?.paperTextColor + " !important",
          },
      }}
      className={`${screenwidth <= 1000?"FilterSearchGrid" : ""} ${
        open_advance_search?"showFilterSearchGrid" : ""
      }`}
    >
      <Box
        sx={{
          display: "flex",
          width: "100% !important",
          position: "relative",
          backgroundColor:
            screenwidth <= 1000 ? "transparent" : gridtheme?.gridHeader,
          height: "56px",
          alignItems: "center",
          justifyContent: "space-between",
          overflow: "hidden !important",
          borderTopLeftRadius: screenwidth <= 1000 ? 0 : "5px",
          borderTopRightRadius: screenwidth <= 1000 ? 0 : "5px",
          marginTop: screenwidth <= 1000 ? "15px" : "0",
        }}
      >
       
        <Box
          sx={{display:'flex',
            justifyContent: 'center',
            alignItems:'center',
            padding:'0 5px'
        }}
        >
           {
           screenwidth<=1000?
          <Tooltip title={<span style={{fontFamily:'Cairo'}}>{t('GLOBAL.closeAdvanceSearchGrid')}</span>} arrow>
          <IconButton
            sx={{
              boxShadow: "0 0 10px -2px rgba(0,0,0,.5)",
              color: "#000",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "35px",
              height: "35px",
              borderRadius: "50%",
              cursor: "pointer",
               position:'relative',
               left:'5px',
              //  top:'5px',
              //  left:'5px',
            }}
            onClick={()=>{
              dispatch(setOpenAdvanceSearch(!open_advance_search))
            }}
          >
            <CloseIcon sx={{ color: "#000" }} size={25} />
          </IconButton>
          </Tooltip>:null}

          <CustomeActionButoon
             {...props}
          />
        </Box>

        <Box
          sx={{
            position: "absolute",
            // top:'20px',
            right: "10px !important",
            width: "fit-content",
            height: "30px",
            borderRadius: "5px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor:
              screenwidth <= 1000 ? "#1e6a99" : gridtheme?.colorWhite,
            color: `${gridtheme?.lightblack} !important`,
            padding: "2px 10px",
          }}
        >
          <Typography
            variant="p"
            sx={{
              fontSize: "13px !important",
              color: `${
                screenwidth <= 1000 ? "#fff" : gridtheme?.lightblack
              } !important`,
              fontFamily: "Cairo-Bold",
            }}
          >
            {t("GLOBAL.SEARCH_RESULT_OF_SEARCH")} {props?.resultLength}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          width: "100%",
          height: screenwidth <= 1000 ? window.innerHeight-(210) :"fit-content",
          // minHeight: screenwidth <= 1000 ? window.innerHeight-(210) :"350px",
          maxHeight: screenwidth <= 1000 ? window.innerHeight-(210) :"400px",
          overflowY: "scroll",
          overflowX: "hidden",
          // padding:'10px',
          margin: "auto",
          marginTop: "10px",
          // paddingTop:'20px',
          //TODO small screen scroll search container
          // boxShadow:'0 0 7px -5px #000 inset'
        }}
      >
        {props?.children}
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
          alignItems: "center",
          width: "100%",
          height:'60px'
        }}
        // className={`${screenwidth <= 1000 ? "filterActionButton" :''}`}
      >
        <Box display="flex">
          <Button
            variant="outlined"
            // spacing={2}
            sx={{
              width: "50% !important",
              // flexGrow: 1,
              minWidth: "80px !important",
              maxWidth: "80px !important",
              margin: 1,
              // backgroundColor:SearchButtonTheme?.clear_button_color,
              color: SearchButtonTheme?.clear_button_text_color,
              "&:hover": {
                backgroundColor: `${SearchButtonTheme?.clear_button_color}   !important`,
              },
              height: "35px",
              fontFamily: "Cairo-Bold",
            }}
            className="iconeFilterClear"
            color="secondary"
            onClick={() => props.clearFunction()}
          >
            {t("GLOBAL.SEARCH_CLEAR_BUTTON")}
          </Button>
          <Button
            variant="contained"
            // spacing={2}
            sx={{
              // flexGrow: 1,
              margin: 1,
              width: "80px !important",
              minWidth: "80px !important",
              maxWidth: "80px !important",
              backgroundColor: SearchButtonTheme?.search_button_color,
              color: SearchButtonTheme?.search_button_text_color,
              "&:hover": {
                backgroundColor: SearchButtonTheme?.search_button_color + "88",
              },
              height: "35px",
              fontFamily: "Cairo-Bold",
            }}
            className="iconeFilterSearch"
            onClick={() => props.searchFunction()}
          >
            {t("GLOBAL.SEARCH_SEARCH_BUTTON")}
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
