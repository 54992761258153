import callAPI from '../ApiConfig'
import store from '../../../app/store'

const call_reasons = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"call_reasons",
        method:"get",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
    
}
const call_reasonsList = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"call_reasons_list",
        method:"get",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
    
}
const addcall_reasons = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"call_reasons",
        method:"post",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        },
        data:props?.data,
    })
    
}
const updatecall_reasons = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"call_reasons/"+props?.data?.id,
        method:"put",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        },
        data:props?.data
    })
    
}
const Deletecall_reasons = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"call_reasons/"+props?.data?.id,
        method:"delete",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        },
    })
    
}
const call_reasonsAPI={
    call_reasons:call_reasons,
    call_reasonsList:call_reasonsList,
    addcall_reasons:addcall_reasons,
    updatecall_reasons:updatecall_reasons,
    Deletecall_reasons:Deletecall_reasons

}
export default call_reasonsAPI