import callAPI from '../ApiConfig'
import store from '../../../app/store'

const compounds = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"compounds",
        method:"get",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    
    })
    
}

const compound = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"compound",
        method:"get",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    
    })
    
}

const compoundsList = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"compounds_list",
        method:"get",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    
    })
    
}
const compoundsNested = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"compounds_nested",
        method:"get",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    
    })
    
}

const addCompound = async (props) => {
    const storeState = store.getState();

    var data = new FormData();
    
    console.log(props);
    data.append('name', props?.data?.name);
    data.append('description', props?.data?.description);
    props?.data?.images.forEach((image,index) => {
        data.append('images['+index+'][image]', image?.file);
        data.append('images['+index+'][image_type_id]', image?.image_type?.id);
    });


    // console.log("data",props?.data);
    // return 

    return await callAPI({
        route:"compounds",
        method:"post",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Content-Type": "multipart/form-data",
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        },
        data:data
    })
    
}
const updateCompound = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"compounds/"+props.data?.id,
        method:"post",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        },
        data:props.data,
            
    })
    
}
const deleteCompound = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"compounds/"+props.data?.id,
        method:"delete",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        },            
    })
    
}

const compoundAPI={
    compounds:compounds,
    compoundsList:compoundsList,
    compoundsNested:compoundsNested,
    addCompound:addCompound,
    updateCompound:updateCompound,
    deleteCompound:deleteCompound,
    compound:compound

}
export default compoundAPI