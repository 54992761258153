import React, { useState, useEffect } from "react";
import "devextreme/dist/css/dx.light.css";
import { Box, Typography, Divider, Button } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import * as AiIcons from "react-icons/ai";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import * as FaIcons from "react-icons/fa";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import BarCart from "./BarChart";
import PieChartDashboard from "./PieChartDashboard";
import RadialBar from "./RadialBar";
import StackedBarCgart from "./StackedBarCgart";
import LineColumnChart from "./LineColumnChart";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import * as GiIcons from "react-icons/gi";
import "../style.css";
import Filter from "./Filter";
import compoundAPI from "../../../Global/Network/Compound";
import { toast } from "react-toastify";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import CustomerOutStandingGrid from "./CustomerOutStandingGrid";
import { fontFamily } from "@mui/system";
import DelinquentCutomerGrid from "./DelinquentCutomerGrid";
import helpers from "../../../assets/js/helper";

export default function Finance(props) {
  const [t] = useTranslation("common");
  const dashboardtheme = useSelector((state) => state.themeData.dashboardtheme);
  const isopensidemenu = useSelector(
    (state) => state.settingsData.isopensidemenu
  );
  const screenwidth = useSelector((state) => state.settingsData.screenwidth);
  const [country, setCountry] = useState(1);
  const [isOutStandingOrDelinquent, setIsOutStandingOrDelinquent] =
    useState(false);
  const handleChangeCountry = (e) => {
    setCountry(e.target.value);
  };
  const payments_over_due = [
    {
      iqd_usd: 0,
      payments_over_due: 26000000,
    },
    {
      iqd_usd: 1,
      payments_over_due: 1200,
    },
  ];

  return (
    <Box className="row px-0 mx-0 py-1" sx={{ width: "100%", }}>
      <Box
        sx={{
          display:'flex',
          justifyContent:'start',
          alignItems:'center',
          flexWrap:'wrap',
          width:'100%',
          padding:'0'
        }}
      >
      {props?.dashboards?.finance?.customers_outstanding &&
        props?.dashboards?.finance?.customers_outstanding?.length > 0 &&
        props?.dashboards?.finance?.customers_outstanding?.map((itm) => (
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
                // padding:'0 10px',
                alignItems: "center",
                alignContent: "center",
                backgroundColor: dashboardtheme?.colorWhite,
                borderRadius: " 3px",
                boxShadow: "0px 0px 12px 1px #0000000A",
                opacity: 1,
                height: "100px",
                overflowX: "auto",
                overflowY: "hidden",
                width:'fit-content',
                minWidth:'310px',
                maxWidth:'400px',
                height:'fit-content',
                padding: '5px 10px',
                margin: '10px ',
                marginLeft:'0',
              }}
            >
              <Box style={{ margin: "0 -10px 0 30px" }}>
                {itm?.iqd_usd == 0 ? (
                  <GiIcons.GiMoneyStack
                    size={55}
                    color={dashboardtheme?.background}
                  />
                ) : (
                  <MonetizationOnIcon
                    size={55}
                    color={dashboardtheme?.background}
                    sx={{ color: dashboardtheme?.background, fontSize: "40px" }}
                  />
                )}
              </Box>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  alignContent: "center",
                  position: "relative",
                  top: "10px",
                }}
              >
                <span
                  style={{
                    textAlign: "left",
                    font: "normal normal 28px/48px Rajdhani-Bold",
                    letterSpacing: "1.19px",
                    color: "#333333",
                    opacity: 1,
                  }}
                >
                  {helpers?.getFormatedPrice(itm?.customers_outstanding_value,itm?.iqd_usd)}
                </span>
                <span
                  style={{
                    position: "relative",
                    textAlign: "left",
                    font: "normal normal  16px/45px Cairo-BOld",
                    letterSpacing: "0px",
                    color: "#7D8185",
                    opacity: "1",
                    top: "-15px",
                  }}
                >
                  {itm?.iqd_usd == 0
                    ? t("DASHBOARD.outstanding_IQD")
                    : t("DASHBOARD.outstanding_USD")}
                </span>
              </Box>
            </Box>
        ))}
      {props?.dashboards?.finance?.payments_over_due &&
        props?.dashboards?.finance?.payments_over_due?.length > 0 &&
        props?.dashboards?.finance?.payments_over_due?.map((itm) => (
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              // padding:'0 10px',
              alignItems: "center",
              alignContent: "center",
              backgroundColor: dashboardtheme?.colorWhite,
              borderRadius: " 3px",
              boxShadow: "0px 0px 12px 1px #0000000A",
              opacity: 1,
              height: "100px",
              overflowX: "auto",
              overflowY: "hidden",
              width:'fit-content',
              maxWidth:'400px',
              minWidth:'310px',
              height:'fit-content',
              padding: '5px 10px',
              margin: '10px',
            }}
          >
            <Box style={{ margin: "0 -10px 0 30px" }}>
              {itm?.iqd_usd == 0? (
                <GiIcons.GiMoneyStack
                  size={55}
                  color={dashboardtheme?.background}
                />
              ) : (
                <MonetizationOnIcon
                  size={55}
                  color={dashboardtheme?.background}
                  sx={{ color: dashboardtheme?.background, fontSize: "40px" }}
                />
              )}
            </Box>
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                alignContent: "center",
                position: "relative",
                top: "10px",
              }}
            >
              <span
                style={{
                  textAlign: "left",
                  font: "normal normal 28px/48px Rajdhani-Bold",
                  letterSpacing: "1.19px",
                  color: "#333333",
                  opacity: 1,
                }}
              >
                {helpers.getFormatedPrice(itm?.payments_over_due,itm?.iqd_usd)}
              </span>
              <span
                style={{
                  position: "relative",
                  textAlign: "left",
                  font: "normal normal  16px/45px Cairo-BOld",
                  letterSpacing: "0px",
                  color: "#7D8185",
                  opacity: "1",
                  top: "-15px",
                }}
              >
                {itm?.iqd_usd == 0
                  ? t("DASHBOARD.paymentOverDue_IQD")
                  : t("DASHBOARD.paymentOverDue_USD")}
              </span>
            </Box>
          </Box>
      ))}
     </Box>
      {/* grid for outStanding customers  */}
      <Box   
        className="col-12 col-md-12 col-lg-12   my-1 px-0"
        sx={{
          width:'100%',
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Button
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: !isOutStandingOrDelinquent
                ? "#1e6a99"
                : "#4a687a",
              color: "#fff",
              padding: "10px 20px",
              borderBottom:!isOutStandingOrDelinquent?'5px solid lightgray':'0',
              margin: "10px",
              fontFamily: "Cairo",
              "&:hover": {
                backgroundColor: !isOutStandingOrDelinquent
                  ? "#1e6a99"
                  : "#4a687a",
              },
            }}
            onClick={() => {
              setIsOutStandingOrDelinquent(false);
            }}
          >
            {t("DASHBOARD.CustomerOutStandingGrid")}
          </Button>
          <Button
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: isOutStandingOrDelinquent
                ? "#1e6a99"
                : "#4a687a",
              color: "#fff",
              padding: "10px 20px",
              margin: "10px",
              borderBottom:isOutStandingOrDelinquent?'5px solid lightgray':'0',
              fontFamily: "Cairo",
              "&:hover": {
                backgroundColor: isOutStandingOrDelinquent
                  ? "#1e6a99"
                  : "#4a687a",
                

              },
            }}
            onClick={() => {
              setIsOutStandingOrDelinquent(true);
            }}
          >
            {t("DASHBOARD.Customerdelinquent")}
          </Button>
        </Box>
        {!isOutStandingOrDelinquent ? (
          <CustomerOutStandingGrid dashboards={props?.dashboards} loadDataOfDashboards={props?.loadDataOfDashboards}
              pageNumber={props?.pageNumber}
              setPageNumber={props?.setPageNumber}
              pageSize={props?.pageSize}
              setPageSize={props?.setPageSize}
          />
        ) : (
          <DelinquentCutomerGrid 
          dashboards={props?.dashboards} loadDataOfDashboards={props?.loadDataOfDashboards}
          pageNumber={props?.pageNumber}
          setPageNumber={props?.setPageNumber}
          pageSize={props?.pageSize}
          setPageSize={props?.setPageSize}
          />
        )}
      </Box>

      {/* <Box className={`col-12 col-sm-12 col-md-6 ${isopensidemenu?screenwidth<1350?'col-lg-6':"col-lg-4":screenwidth<1350?'col-lg-4':"col-lg-3"} col-xlg-3 my-1`} >
              <Box style={{
                display:'flex',
                justifyContent:'center',
                // padding:'0 10px',
                alignItems:'center',
                alignContent:'center',
                backgroundColor:dashboardtheme?.colorWhite,
                borderRadius:' 3px',
                boxShadow: '0px 0px 12px 1px #0000000A',
                opacity: 1,
                height: '100px',
              }}>
                  <Box style={{margin:'0 -10px 0 30px'}}>
                       <FaIcons.FaChartBar  size={55} color={dashboardtheme?.background}/>
                  </Box>
                  <Box
                    style={{
                      display:'flex',
                      flexDirection:'column',
                      justifyContent:'center',
                      alignItems:'center',
                      alignContent:'center',
                      position:'relative',
                      top:'10px'
                    }}
                  >
                      <span style={{
                        textAlign: 'left',
                        font: 'normal normal 28px/48px Rajdhani-Bold',
                        letterSpacing: '1.19px',
                        color: '#333333',
                        opacity: 1,
                      }}>14.5%</span>
                      <span 
                        style={{
                          position:'relative',
                          textAlign: "left", 
                          font: "normal normal  16px/45px Cairo-BOld", 
                          letterSpacing: "0px", 
                          color: "#7D8185", opacity: "1",
                          top:'-15px'
                        }}
                      >{t('DASHBOARD.units_sold')}</span>
                  </Box>

              </Box>
          </Box>
          <Box className={`col-12 col-sm-12 col-md-6 ${isopensidemenu?screenwidth<1350?'col-lg-6':"col-lg-4":screenwidth<1350?'col-lg-4':"col-lg-3"} col-xlg-3 my-1`} >
              <Box style={{
                display:'flex',
                justifyContent:'center',
                // padding:'0 10px',
                alignItems:'center',
                alignContent:'center',
                backgroundColor:dashboardtheme?.colorWhite,
                borderRadius:' 3px',
                boxShadow: '0px 0px 12px 1px #0000000A',
                opacity: 1,
                height: '100px',
              }}>
                  <Box style={{margin:'0 -10px 0 30px'}}>
                       <FaIcons.FaChartBar  size={55} color={dashboardtheme?.background}/>
                  </Box>
                  <Box
                    style={{
                      display:'flex',
                      flexDirection:'column',
                      justifyContent:'center',
                      alignItems:'center',
                      alignContent:'center',
                      position:'relative',
                      top:'10px'
                    }}
                  >
                      <span style={{
                        textAlign: 'left',
                        font: 'normal normal 28px/48px Rajdhani-Bold',
                        letterSpacing: '1.19px',
                        color: '#333333',
                        opacity: 1,
                      }}>14.5%</span>
                      <span 
                        style={{
                          position:'relative',
                          textAlign: "left", 
                          font: "normal normal  16px/45px Cairo-BOld", 
                          letterSpacing: "0px", 
                          color: "#7D8185", opacity: "1",
                          top:'-15px'
                        }}
                      >{t('DASHBOARD.units_sold')}</span>
                  </Box>

              </Box>
          </Box> */}

      {/* {
          <div className="col-12 col-md-12 col-lg-12"></div>
            } */}
      {
        // <Box className={`col-12 col-sm-12 col-md-6 ${isopensidemenu?'col-lg-6':'col-lg-5'} col-xlg-5 my-lg-5 my-md-2  my-sm-2 my-2 `}>
        //    <Box
        //      sx={{
        //        backgroundColor:dashboardtheme?.colorWhite,
        //        height: "352px",
        //        background: "#FFFFFF 0% 0% no-repeat padding-box",
        //        boxShadow: "0px 2px 6px #0000000A",
        //        opacity: "1",
        //        width:'100%'
        //      }}
        //   >
        //     <Box
        //       style={{
        //         display:'flex',
        //         justifyContent:'space-between',
        //         alignItems:'center',
        //         padding:'20px'
        //       }}
        //     >
        //       <FormControl
        //         sx={{
        //           width: "110px",
        //           height: "32px",
        //           background: "#FFFFFF 0% 0% no-repeat padding-box",
        //           borderRadius: "4px",
        //           opacity: "1",
        //           '& .MuiInputBase-root':{
        //              height: "40px",
        //              boxShadow: '0px 2px 3px #0000000D;'
        //           }
        //         }}>
        //         {/* <InputLabel id="demo-simple-select-label">Country</InputLabel> */}
        //         <Select
        //           labelId="demo-simple-select-label"
        //           id="demo-simple-select"
        //           value={country}
        //           // label="Age"
        //           onChange={handleChangeCountry}
        //         >
        //           <MenuItem value={1}>Europe</MenuItem>
        //           <MenuItem value={2}>Asia</MenuItem>
        //           <MenuItem value={3}>South America</MenuItem>
        //         </Select>
        //       </FormControl>
        //       <span  style={{
        //         textAlign: "left",
        //         font: "normal normal normal 18px/13px Source Sans Pro",
        //         letterSpacing: "0px",
        //         color: "#4D4F5C",
        //         opacity: "1"
        //       }}>Overall Sales</span>
        //     </Box>
        //     <Box sx={{width:'100%',display:'flex',justifyContent:'flex-start',alignItems:'center',alignContent:'center',
        //      '& .apexcharts-bar-area':{
        //      },
        //      overflowY:'hidden',
        //      backgroundColor:'#fff',
        //   }}>
        //        <BarCart/>
        //     </Box>
        //    </Box>
        // </Box>
        // <Box className={`col-12 col-sm-12 col-md-6 ${isopensidemenu?'col-lg-6':'col-lg-5'} col-xlg-5 my-lg-5 my-md-2  my-sm-2 my-2 `}>
        //    <Box
        //      sx={{
        //        backgroundColor:dashboardtheme?.colorWhite,
        //        height: "380px",
        //        background: "#FFFFFF 0% 0% no-repeat padding-box",
        //        boxShadow: "0px 2px 6px #0000000A",
        //        opacity: "1",
        //        width:'100%'
        //      }}
        //   >
        //     <Box
        //       style={{
        //         display:'flex',
        //         justifyContent:'space-between',
        //         alignItems:'center',
        //         padding:'20px'
        //       }}
        //     >
        //       <FormControl
        //         sx={{
        //           width: "110px",
        //           height: "32px",
        //           background: "#FFFFFF 0% 0% no-repeat padding-box",
        //           borderRadius: "4px",
        //           opacity: "1",
        //           '& .MuiInputBase-root':{
        //              height: "40px",
        //              boxShadow: '0px 2px 3px #0000000D;'
        //           }
        //         }}>
        //         {/* <InputLabel id="demo-simple-select-label">Country</InputLabel> */}
        //         <Select
        //           labelId="demo-simple-select-label"
        //           id="demo-simple-select"
        //           value={country}
        //           // label="Age"
        //           onChange={handleChangeCountry}
        //         >
        //           <MenuItem value={1}>Europe</MenuItem>
        //           <MenuItem value={2}>Asia</MenuItem>
        //           <MenuItem value={3}>South America</MenuItem>
        //         </Select>
        //       </FormControl>
        //       <span  style={{
        //         textAlign: "left",
        //         font: "normal normal normal 18px/13px Source Sans Pro",
        //         letterSpacing: "0px",
        //         color: "#4D4F5C",
        //         opacity: "1"
        //       }}>Sales Distribution</span>
        //     </Box>
        //     <PieChartDashboard/>
        //    </Box>
        // </Box>
        // <Box className={`col-12 col-sm-12 col-md-6 ${isopensidemenu?'col-lg-3':'col-lg-2'} col-xlg-2 ${isopensidemenu?'my-lg-0':'my-lg-5'} my-md-2  my-sm-2 my-2`}>
        //    <Box
        //      sx={{
        //        backgroundColor:dashboardtheme?.colorWhite,
        //        height: "380px",
        //        background: "#FFFFFF 0% 0% no-repeat padding-box",
        //        boxShadow: "0px 2px 6px #0000000A",
        //        opacity: "1"
        //      }}
        //   >
        //     <Box sx={{width:'100%',display:'flex',justifyContent:'end',alignItems:'center',  padding:'20px',paddingBottom:'10px'}}>
        //         <span  style={{
        //            textAlign: "left",
        //            font: "normal normal normal 17px/12px Source Sans Pro",
        //            letterSpacing: "0px",
        //            color: "#4D4F5C",
        //            opacity: "1",
        //            marginTop:10,
        //         }}>Money</span>
        //     </Box>
        //     <Box sx={{width:'100%',display:'flex',justifyContent:'space-between',alignItems:'center',padding:'20px',paddingTop:'0px'}}>
        //           <span style={{
        //           font: "normal normal bold 14px/20px Source Sans Pro", letterSpacing: "0px", color: "#4AD991", opacity: "1"
        //           }}>$50,000</span>
        //           <span
        //            style={{
        //             font: "normal normal normal 14px/23px Source Sans Pro", letterSpacing: "0px", color: "#43425D", opacity: "1"
        //            }}
        //           >Total Budget</span>
        //     </Box>
        //      <p  style={{
        //        width:'83%',
        //        height:'1px',
        //        margin:'auto',
        //        position:'relative',
        //        top:'-20px',
        //        background: "#E8E9EC 0% 0% no-repeat padding-box"
        //      }}></p>
        //      <Box sx={{width:'100%',padding:'20px',paddingTop:'0px',width:'100%'}}>
        //         <RadialBar/>
        //     </Box>
        //     <p  style={{
        //        width:'83%',
        //        height:'1px',
        //        margin:'auto',
        //        position:'relative',
        //        top:'-20px',
        //        background: "#E8E9EC 0% 0% no-repeat padding-box"
        //      }}></p>
        //     <Box sx={{width:'100%',display:'flex',justifyContent:'center',alignItems:'center',padding:'20px',paddingTop:'0px'}}>
        //        <span style={{
        //         cursor:'pointer',
        //         textAlign: "left", font: "normal normal normal 12px/18px Source Sans Pro", letterSpacing: "0px", color: "#3B86FF", opacity: "1"
        //        }}>View Full Report</span>
        //     </Box>
        //    </Box>
        // </Box>
        // <Box className={`col-12 col-sm-12 col-md-6 ${isopensidemenu?'col-lg-6':'col-lg-5'} col-xlg-5 my-lg-0 my-md-0  my-sm-1 my-1`}>
        //    <Box
        //      sx={{
        //        backgroundColor:dashboardtheme?.colorWhite,
        //        height: "380px",
        //        background: "#FFFFFF 0% 0% no-repeat padding-box",
        //        boxShadow: "0px 2px 6px #0000000A",
        //        opacity: "1"
        //      }}
        //   >
        //     <Box sx={{width:'100%',display:'flex',justifyContent:'end',alignItems:'center',  padding:'20px',paddingBottom:'10px'}}>
        //         <span  style={{
        //           textAlign: "left", font: "normal normal normal 16px/12px Source Sans Pro", letterSpacing: "0px", color: "#4D4F5C", opacity: "1"
        //         }}>Stacked Bar Chart</span>
        //     </Box>
        //     <Box sx={{display:'flex',justifyContent:'center',alignItems:'center',
        //       overflowY:'hidden',
        //       backgroundColor:'#fff',
        //   }}>
        //       <StackedBarCgart/>
        //     </Box>
        //    </Box>
        // </Box>
        // <Box className={`col-12 col-sm-12 col-md-7 ${isopensidemenu?'col-lg-12':'col-lg-7'} col-xlg-7 ${isopensidemenu?' my-lg-2 my-md-2  my-sm-2 my-2':' my-lg-0 my-md-0  my-sm-2 my-0'}`}>
        //    <Box
        //      sx={{
        //        backgroundColor:dashboardtheme?.colorWhite,
        //        height: "380px",
        //        background: "#FFFFFF 0% 0% no-repeat padding-box",
        //        boxShadow: "0px 2px 6px #0000000A",
        //        opacity: "1"
        //      }}
        //   >
        //      <Box
        //       style={{
        //         display:'flex',
        //         justifyContent:'space-between',
        //         alignItems:'center',
        //         padding:'20px',
        //         paddingBottom:'0px',
        //       }}
        //     >
        //       <FormControl
        //         sx={{
        //           width: "170px",
        //           height: "32px",
        //           background: "#FFFFFF 0% 0% no-repeat padding-box",
        //           borderRadius: "4px",
        //           opacity: "1",
        //           '& .MuiInputBase-root':{
        //              height: "40px",
        //              boxShadow: '0px 2px 3px #0000000D;'
        //           }
        //         }}>
        //         {/* <InputLabel id="demo-simple-select-label">Country</InputLabel> */}
        //         <Select
        //           labelId="demo-simple-select-label"
        //           id="demo-simple-select"
        //           value={country}
        //           // label="Age"
        //           onChange={handleChangeCountry}
        //         >
        //           <MenuItem value={1}>Last 30 days</MenuItem>
        //           <MenuItem value={2}>last week</MenuItem>
        //           <MenuItem value={3}>Last Day</MenuItem>
        //         </Select>
        //       </FormControl>
        //       <span  style={{
        //         textAlign: "left",
        //         font: "normal normal normal 18px/13px Source Sans Pro",
        //         letterSpacing: "0px",
        //         color: "#4D4F5C",
        //         opacity: "1"
        //       }}>Trends</span>
        //     </Box>
        //     <Box
        //       sx={{
        //         display:'flex',
        //         justifyContent:'end',
        //         alignItems:'center',
        //         padding:'20px',
        //         paddingTop:'0px'
        //       }}
        //     >
        //       <Box sx={{display:'flex',alignItems:'center',cursor:'pointer'}}>
        //         <span style={{
        //           textAlign: "left", font: "normal normal normal 12px/12px Source Sans Pro", letterSpacing: "0px", color: "#4D4F5C", opacity: "1",
        //           margin:'0 10px'
        //         }}>Total Views</span>
        //                <span
        //         style={{
        //           width: "13px", height: "7px",
        //           background: "#F0F2F8  0% 0% no-repeat padding-box", borderRadius: "5px", opacity: "1"
        //         }}></span>
        //       </Box>
        //       <Box sx={{display:'flex',alignItems:'center',cursor:'pointer'}}>
        //         <span style={{
        //           textAlign: "left", font: "normal normal normal 12px/12px Source Sans Pro", letterSpacing: "0px", color: "#4D4F5C", opacity: "1",
        //           margin:'0 10px'
        //         }}>Products Sold</span>
        //         <span
        //         style={{
        //           width: "13px", height: "7px",
        //           background: "#8F8BFF 0% 0% no-repeat padding-box", borderRadius: "5px", opacity: "1"
        //         }}></span>
        //       </Box>
        //     </Box>
        //     <Box sx={{display:'flex',justifyContent:'center',alignItems:'center',width:'100%',justifySelf:'center',
        //       overflowY:'hidden',
        //       backgroundColor:'#fff',
        //   }}>
        //        <LineColumnChart/>
        //     </Box>
        //    </Box>
        // </Box>
      }
    </Box>
  );
}
