import { Box, Button, ListItemIcon, MenuItem, Typography } from '@mui/material'
import React, { useState, useEffect } from 'react'
import PopupForm from '../../Components/PopupForm'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import CustomTextField from '../../../General/CustomComponents/CustomTextField'
import Grid from '../../Grid/Grid'
import DropDownGrid from '../../Grid/Components/DropDownGrid'
import { AiTwotonePrinter } from 'react-icons/ai'
import helpers from '../../../assets/js/helper'
import PaymentsAPI from '../../../Global/Network/Payment'
import { toast } from 'react-toastify';
import PaymentPrintPopup from './PaymentPrintPopup'
import ObjectOfErrors, { checkErrors, resetErrors, restObject } from '../../../Global/Errors/Payment/Payment'
import RolesOfUserAPI from '../../../Global/Network/RoleUser'

export default function AddFormDailog(props) {
    const [t] = useTranslation('common');
    const controller = new AbortController();
    const gridtheme = useSelector(state => state.themeData.gridtheme)
    const [pageSize, setPageSize] = useState(5);
    const [pageNumber, setPageNumber] = useState(1);
    const [loading, setLoading] = useState(false)
    const [pageCount, setPageCount] = useState(0)
    const [rowsTotal, setRowsTotal] = useState(0)
    const [rows, setRows] = useState([])
    const [ErrorsObject, setErrorsObject] = useState(ObjectOfErrors)
    const [selectedObject, setSelectedObject] = useState(null)
    const [openPaymentPrint, setOpenPaymentPrint] = useState(false)
    const [totalBalance,setTotalBalance]=useState(null)

    const [userRolesHere, setUserRolesHere] = useState([
        {
          view: true,
          id: 182
        },
        {
          add: false,
          id: 183,
        },
        {
          update: false,
          id: 184,
        },
        {
          delete: false,
          id: 185,
        }, {
          export: false,
          id: 186
        },
        {
          print: false,
          id: 187
        }
    ])

    const [paidAmount, setPaidAmount] = useState({
        value: "",
        message: [],
        error: false
    })
    const columns = [
        { headerName: t('INVOICES.id'), field: 'id', flex: 0.5, minWidth: 50, maxWidth: 150, hide: false },
        {
            headerName: t('INVOICES.paid_amount'), field: 'paid_amount', flex: 2, minWidth: 250, maxWidth: 450, hide: false,
            renderCell: (rows) => (
                <Typography>
                    {(props?.object?.order?.unit?.iqd_usd == 0 ? "IQD" : "USD") + " " + rows?.row?.paid_amount?.format(props?.object?.order?.unit?.iqd_usd == 1 ? 2 : 0, 3, ',', '.')}
                </Typography>
            )
        },
        { headerName: t('INVOICES.number'), field: 'invoice_id', flex: 0.5, minWidth: 50, maxWidth: 150, hide: false },
        {
            headerName: t('INVOICES.created_at'), field: 'created_at', flex: 2, minWidth: 250, maxWidth: 450, hide: false,
            renderCell: (rows) => (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <span style={{ fontFamily: 'Cairo-Medium' }} className="gridHeaderItem">{rows?.row?.created_at ? helpers.formatDate(rows?.row?.created_at) : '0000-00-00'}</span>
                    <span style={{ fontFamily: 'Cairo-Medium' }} className="gridHeaderItem">{rows?.row?.created_at ? helpers.formatTime(rows?.row?.created_at) : '00:00:00'}</span>

                </Box>
            )
        },
        {
            headerName: t('INVOICES.actions'), field: 'Actions', flex: 0.4, minWidth: 100, maxWidth: 250, hide: false,
            renderCell: (rows) => (
                <DropDownGrid className="containerOFDropDownToolTipeGrid">
                    {
                     userRolesHere[5]?.print?
                    <MenuItem onClick={() => {
                        // setInvoice(rows?.row)
                        // setOpenInvoicePrint(true)
                        setSelectedObject(rows?.row)
                        setOpenPaymentPrint(true)

                    }} className="ActionIcons">
                        <ListItemIcon sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            margin: '0px 7px 0 0'
                        }}>
                            <AiTwotonePrinter style={{ fontSize: '20px', fill: "#1E6A99" }} />

                        </ListItemIcon>
                        {t('INVOICES.print')}
                    </MenuItem>:null
                    }

           {
            !userRolesHere[5]?.print?
              <Box
                sx={{
                  display:'flex',
                  justifyContent:'center',
                  alignItems:'center',
                  width:'100%',
                }}
              >
              <p style={{ fontFamily: 'Cairo', margin: '5px 20px' }}>{t('GLOBAL.emptyPoPup')}</p>
              </Box>: null
          }
                </DropDownGrid>
            ),
        },
    ]

    useEffect(() => {
        let abortController = new AbortController();
        loadData()
        getUserRoles()
        return () => {
            abortController.abort();
        }
    }, [pageNumber, pageSize, props?.addForm])

    const loadData = async (searchParams) => {
        setLoading(true)

        const result = await PaymentsAPI.payments({
            params: {
                page_size: pageSize,
                page: pageNumber,
                invoice_id: props?.object?.id,
                ...searchParams
            },
            signal: controller?.signal
        })

        setLoading(false)
        if (result.status) {
            setRows(result?.data?.data?.data)
            setRowsTotal(result?.data?.total)
            setPageCount(result?.data?.last_page)
            setTotalBalance(result?.data?.data?.total)
        }
        else {
            if (!result?.data?.error) {
                toast.error(t('NETWORKMESSAGE.messageError'))
                return
            }
        }
    }
    const submit = async () => {
        try {
            setLoading(true)
            let dumData = resetErrors(ErrorsObject)
            let data = {
                paid_amount: paidAmount?.value,
                invoice_id: props?.object?.id
            }
            let result = null;
            // if (selectedObject && selectedObject?.id) {
            //     data = {
            //         ...data,
            //         id: props?.object?.id,
            //         _method: 'put'
            //     }
            //     result = await PaymentsAPI.updatePayment({
            //         data: data
            //     });
            // } else {
                result = await PaymentsAPI.addPayment({
                    data: data
                });
            // }

            if (result?.status) {
                setLoading(false)
                toast.success(t('NETWORKMESSAGE.addCustomerMessageSuccess'))
                setPaidAmount({
                    value: "",
                    error: false,
                    message: [],

                })
                loadData()

            } else {

                if (typeof result.errorMessage === "object") {
                    let updatedObject = resetErrors(ErrorsObject)
                    setErrorsObject(checkErrors(result.errorMessage, updatedObject))
                }
                else if (result?.errorMessage) {
                    toast.error(t(result.errorMessage))
                }
                else {
                    toast.error(t('NETWORKMESSAGE.messageError'))
                }
                setLoading(false)

            }
            setLoading(false)
        } catch (error) {
            setLoading(false)
            toast.error(error?.message)
        }

    }
    const clearForm=()=>{
      setPaidAmount({
        value: "",
        message: [],
        error: false
      })
    }

    const getUserRoles = async () => {
        // setLoading(true)
        console.log('saldkjaskd', 2);
        try {
          let data = await RolesOfUserAPI.GetRolesOfUser();
          if (data && data?.status) {
            if (data?.data && data?.data?.length > 0) {
              let arr = [];
    
              let checkHasRole = data?.data?.includes(userRolesHere[0]?.id)
              if (checkHasRole) {
                arr.push(
                  {
                    view: true,
                    id: userRolesHere[0]?.id,
                  }
                )
              } else {
                arr.push(
                  {
                    view: false,
                    id: userRolesHere[0]?.id,
                  }
                )
              }
              console.log('sassssss', userRolesHere[1]?.id);
              checkHasRole = data?.data?.includes(userRolesHere[1]?.id)
              if (checkHasRole) {
                arr.push(
                  {
                    add: true,
                    id: userRolesHere[1]?.id,
                  }
                )
              } else {
                arr.push(
                  {
                    add: false,
                    id: userRolesHere[1]?.id,
                  }
                )
              }
              checkHasRole = data?.data?.includes(userRolesHere[2]?.id)
              if (checkHasRole) {
                arr.push(
                  {
                    update: true,
                    id: userRolesHere[2]?.id,
                  }
                )
              } else {
                arr.push(
                  {
                    update: false,
                    id: userRolesHere[2]?.id,
                  }
                )
              }
              checkHasRole = data?.data?.includes(userRolesHere[3]?.id)
              if (checkHasRole) {
                arr.push(
                  {
                    delete: true,
                    id: userRolesHere[3]?.id,
                  }
                )
              } else {
                arr.push(
                  {
                    delete: false,
                    id: userRolesHere[3]?.id,
                  }
                )
              }
              checkHasRole = data?.data?.includes(userRolesHere[4]?.id)
              if (checkHasRole) {
                arr.push(
                  {
                    export: true,
                    id: userRolesHere[4]?.id,
                  }
                )
              } else {
                arr.push(
                  {
                    export: false,
                    id: userRolesHere[4]?.id,
                  }
                )
              }
    
              checkHasRole = data?.data?.includes(userRolesHere[5]?.id)
              if (checkHasRole) {
                arr.push(
                  {
                    print: true,
                    id: userRolesHere[5]?.id,
                  }
                )
              } else {
                arr.push(
                  {
                    print: false,
                    id: userRolesHere[5]?.id,
                  }
                )
              }
    
    
              setUserRolesHere(arr)
            }
          }
          setLoading(false)
        } catch (err) {
          setLoading(false)
          console.log(err?.message);
    
        }
      }
    const getCurrentBalanceForThisInvoice=()=>{
      let countPaidedBalance=0
      rows&&rows?.length>0&&rows?.map((itm)=>{
        countPaidedBalance+=itm?.paid_amount
      })
      return props?.object?.total_price-countPaidedBalance;

    }

    return (
        <>
            

            <PopupForm
                open={props.addForm}
                setOpen={props.setAddForm}
                customeWidth={'80% !important'}
                title={t('INVOICES.add_payment')}
                content={(
                    <Box>
                       <Box
                         sx={{
                          display:'flex',
                          alignItems:'center',
                          justifyContent:'stretch',
                          width:'100%',
                          gap:'5px',
                          padding:'0 10px'
                         }}
                       >
                          {
                          userRolesHere[1]?.add?
                          <CustomTextField
                              type={'number'}
                              label={t('INVOICES.paid_amount')}
                              value={paidAmount?.value}
                              error={paidAmount?.error}
                              message={paidAmount?.message}
                              readOnly={false}
                              onChange={(e) => {
                                  setPaidAmount({
                                      value: e.target.value,
                                      error: false,
                                      message: []
                                  })
                              }}
                              onClearClick={() => {
                                  setPaidAmount({
                                      value: "",
                                      error: false,
                                      message: []
                                  })
                              }}
                          />:null}
                          <CustomTextField
                              type={'text'}
                              label={t('INVOICES.balnace')}
                              value={totalBalance}
                              readOnly={true}
                          />
                        </Box>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            padding: '20px'
                        }}
                        >
                            <Typography sx={{
                                display: 'flex',
                                fontFamily: 'Cairo',
                                fontSize: '13px'
                            }}>
                                المبلغ الكلي للفاتورة : {"(" + props?.object?.total_price?.format(props?.object?.order?.unit?.iqd_usd == 1 ? 2 : 0, 3, ',', '.') + ") " + helpers?.tafqeet(props?.object?.total_price, props?.object?.order?.unit?.iqd_usd == 0 ? "IQD" : "USD")}
                            </Typography>
                            {
                             userRolesHere[1]?.add?
                            <Button

                                variant="contained"
                                // spacing={2} 
                                sx={{
                                    // flexGrow: 1,
                                    margin: 1,
                                    width: '80px !important',
                                    minWidth: '80px !important',
                                    maxWidth: '80px !important',
                                    '&:hover': {
                                        //   backgroundColor:SearchButtonTheme?.search_button_color+'88',
                                    },
                                    height: '35px',
                                    fontFamily: 'Cairo-Bold'
                                }}
                                className="iconeFilterSearch"
                                onClick={() => {
                                    submit()
                                }}
                            >
                                {t('GLOBAL.Save')}
                            </Button>:null
                            }
                        </Box>
                        <Grid
                            rows={rows}
                            columns={columns}
                            hideFilter={true}
                            pageSize={100}
                            setPageSize={() => { }}
                            pageNumber={1}
                            setPageNumber={() => { }}
                            loading={loading}
                            pageCount={pageCount}
                            handlePageChange={() => { }}
                            rowsTotal={rowsTotal}
                        />
                    </Box>

                )}
                footer={
                    <Button variant="outlined"
                        // spacing={2}
                        sx={{
                            width: '50% !important',
                            // flexGrow: 1, 
                            minWidth: '80px !important',
                            maxWidth: '80px !important',
                            margin: 1,
                            backgroundColor: '#f7f7f7',
                            borderColor: gridtheme?.colorWhite,
                            color: gridtheme?.colorblack,
                            boxShadow: '0 0 7px -2px white',
                            //   color:SearchButtonTheme?.clear_button_text_color,
                            '&:hover': {
                                backgroundColor: '#f7f7f7',
                                borderColor: gridtheme?.colorWhite,
                                color: gridtheme?.colorblack,
                                boxShadow: '0 0 7px 1px white',
                                boxShadow: '0',


                            },
                            height: '35px',
                            fontFamily: 'Cairo-Bold'

                        }}
                        className="iconeFilterClear"
                        color="secondary"
                        onClick={() => {
                            props.setAddForm(false)
                            clearForm()
                        }}
                    >
                        {t('GLOBAL.close')}
                    </Button>}
            />
            <PaymentPrintPopup
                payment={selectedObject}
                open={openPaymentPrint}
                setOpen={setOpenPaymentPrint}
            />
        </>
    )
}
