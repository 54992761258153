import { Box, TextField, Button, Autocomplete, FormControlLabel, Checkbox, Typography } from '@mui/material'
import React, { useState, useEffect } from 'react'
import PopupForm from '../../../Components/PopupForm'
import { useTranslation } from 'react-i18next'
import { ContainerOfInputFields, ContainerOfSelectField } from '../../../RegisteredCustomers/ThemDesign'
import { useSelector } from 'react-redux'
import ObjectOfErrors, { checkErrors, resetErrors, resetModelObject,ObjectMerge } from '../../../../Global/Errors/CustomerContact/CustomerContact'
import CloseIcon from '@mui/icons-material/Close';
import { toast } from 'react-toastify'
import * as RiIcons from 'react-icons/ri'
import orderAPI from '../../../../Global/Network/Orders'
import RelationTypeAPI from '../../../../Global/Network/RelationType'
import CustomTextField from '../../../../General/CustomComponents/CustomTextField'
import CustomeSelectField from '../../../../General/CustomComponents/CustomeSelectField'
import customerContactAPI from '../../../../Global/Network/CustomerContact'
import CustomePhoneField from '../../../../General/CustomComponents/CustomePhoneField'
import helpers from '../../../../assets/js/helper'


const RenderContent = (props) => {
    const [t] = useTranslation('common')


     const handlePhoneChange = (name, e) => {
        let inputtxt = e;
        var phoneno = /^\d{11}$/;
  
           let object = {
              error: '',
              message: false,
           }
           if (isNaN(inputtxt)) {
              props?.setFirstPhoneMessageError({
                 message: 'يجب ان يكون الهاتف رقم',
                 error: true,
              })
              return false
           }
  
           if (e?.length == 11 && !isNaN(inputtxt)) {
              object = {
                 message: '',
                 error: false,
              }
           }
           else if (e?.length >= 11) {
              object = {
                 message: 'رقم الهاتف بصيغة غير صحيحة !',
                 phone: true,
              }
           } else {
              object = {
                 message: '',
                 phone: false,
              }
           }
           props?.setFirstPhoneMessageError({
              ...object,
           })
        
     }
    return (
        <Box className='Container-fluid'>
            <Box className="row my-3">
                <Box className="col-12 col-sm-12 col-md-6 col-lg-6 ">
                    <CustomeSelectField
                        label={t('CUSTOMER.relation_type')}
                        haswidth={true}
                        value={props?.customerContact?.relation_type_id?.value}
                        list={props?.relationTypes ? props?.relationTypes : []}
                        error={props?.customerContact?.relation_type_id.error}
                        message={props?.customerContact?.relation_type_id.message}
                        readOnly={false}
                        focused={true}
                        onChange={(e, newValue) => {

                            props?.setCustomerContact({
                                ...props?.customerContact,
                                relation_type_id:{
                                    value:newValue,
                                    error:false,
                                    message:[]
                                },
                            })
                        }}
                        onClearClick={() => {
                            
                            props?.setCustomerContact({
                                ...props?.customerContact,
                                relation_type_id:{
                                    value:{
                                        id:"",
                                        name:""
                                    },
                                    error:false,
                                    message:[]
                                },
                            })
                        }}
                    />
                     <Box sx={{margin:'10px 0'}}></Box>

                    <CustomTextField
                        label={t('CUSTOMER.name')}
                        haswidth={true}
                        value={props?.customerContact?.name?.value}
                        error={props?.customerContact?.name?.error}
                        message={props?.customerContact?.name?.message}
                        readOnly={false}
                        onChange={(e) => {
                            props?.setCustomerContact({
                                ...props?.customerContact,
                                name:{
                                    value: e.target.value,
                                    error:false,
                                    message:[]
                                },
                            })
                        }}
                        onClearClick={() => {
                            props?.setCustomerContact({
                                ...props?.customerContact,
                                name:{
                                    value:"",
                                    error:false,
                                    message:[]
                                },
                            })
                        }}
                    />

                </Box>

                <Box className="col-12 col-sm-12  col-md-6 col-lg-6 ">

                    <CustomePhoneField
                        value={props?.customerContact?.phone?.value}
                        title={t("CUSTOMER.phone")}
                        onChange={(e) => {
                           if (!isNaN(e?.target?.value)) {
                                props?.setCustomerContact({
                                    ...props?.customerContact,
                                    phone:{
                                        value: e.target.value,
                                        error:false,
                                        message:[]
                                    },
                                })
                                handlePhoneChange('first_phone',e?.target?.value)
                           }
                        }}
                        onClearClick={() => {
                            props?.setCustomerContact({
                                ...props?.customerContact,
                                phone:{
                                    value:"",
                                    error:false,
                                    message:[]
                                },
                            })
                           props?.setFirstPhoneMessageError({
                            message:'',
                            error:false
                           })

                           props?.setStateCode('')
                        }}
                        paddingHorizontal="0 11px"
                        noTopMargin={true}
                        haswidth={true}
                        stateCode={props?.stateCode}
                        setStateCode={props?.setStateCode}
                        messageStateCode={props?.firstPhoneMessageError?.message}
                        error={props?.firstPhoneMessageError?.error}
                        // message={customersErrorsObject?.address_second_phone?.message}
                     />
                     <Box sx={{margin:'10px 0'}}></Box>
                    <CustomTextField
                        label={t('CUSTOMER.address')}
                        haswidth={true}
                        value={props?.customerContact?.address?.value}
                        error={props?.customerContact?.address?.error}
                        message={props?.customerContact?.address?.message}
                        hasMultipleLine={true}
                        readOnly={ false}
                        onChange={(e) => {
                            props?.setCustomerContact({
                                ...props?.customerContact,
                                address:{
                                    value: e.target.value,
                                    error:false,
                                    message:[]
                                },
                            })
                        }}
                        onClearClick={() => {
                            props?.setCustomerContact({
                                ...props?.customerContact,
                                address:{
                                    value:"",
                                    error:false,
                                    message:[]
                                },
                            })
                        }}
                    />
                </Box>

            </Box>
        </Box>
    )
}

const RenderFooter = (props) => {
    const [t] = useTranslation('common')
    const gridtheme = useSelector(state => state.themeData.gridtheme)

    return (
        <Box sx={{
            display: 'flex',
            alignItems: 'center',
        }}>


            <Box sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center', width: '100%' }}>
                <Box display="flex" >

                    <Button variant="outlined"
                        // spacing={2}
                        sx={{
                            width: '50% !important',
                            // flexGrow: 1, 
                            minWidth: '80px !important',
                            maxWidth: '80px !important',
                            margin: 1,
                            backgroundColor: '#f7f7f7',
                            borderColor: gridtheme?.colorWhite,
                            color: gridtheme?.colorblack,
                            boxShadow: '0 0 7px -2px white',
                            //   color:SearchButtonTheme?.clear_button_text_color,
                            '&:hover': {
                                backgroundColor: '#f7f7f7',
                                borderColor: gridtheme?.colorWhite,
                                color: gridtheme?.colorblack,
                                boxShadow: '0 0 7px 1px white',
                                boxShadow: '0',


                            },
                            height: '35px',
                            fontFamily: 'Cairo-Bold'

                        }}
                        className="iconeFilterClear"
                        color="secondary"
                        onClick={() => {
                            props.setOpen(false)
                        }}
                    >
                        {t('GLOBAL.close')}
                    </Button>
                    <Button
                        variant="contained"
                        // spacing={2} 
                        sx={{
                            // flexGrow: 1,
                            margin: 1,
                            width: '80px !important',
                            minWidth: '80px !important',
                            maxWidth: '80px !important',
                            '&:hover': {
                                //   backgroundColor:SearchButtonTheme?.search_button_color+'88',
                            },
                            height: '35px',
                            fontFamily: 'Cairo-Bold'
                        }}
                        className="iconeFilterSearch"
                        onClick={() => {
                            props.submit()
                        }}
                    >
                        {t('GLOBAL.Save')}
                    </Button>
                </Box>
            </Box>
        </Box>
    )
}


export default function AddFormDailog(props) {
    const [t] = useTranslation('common');
    const [customerContact, setCustomerContact] = useState({
        ...ObjectOfErrors
    })
    const [loading, setLoading] = useState(false)
    const [relationTypes, setRelationTypes] = useState([])
    const [stateCode,setStateCode]=useState(null)

    const controller = new AbortController();
    const [firstPhoneMessageError, setFirstPhoneMessageError] = useState({
        error: false,
        message: ''
     })

    useEffect(() => {
        if (props?.object && props?.object?.id) {
            let updatedObject = ObjectMerge(props?.object)
            console.log("updatedObject",props?.object);
            let objectPhone=props?.object?.phone?helpers?.handleSplitPhoneWithStateCode(props?.object?.phone):null
            setCustomerContact({
                ...updatedObject,
                phone:{
                  ...updatedObject?.phone,
                  value:objectPhone?.phone
                }
            })
            setStateCode(objectPhone?.code)
          
        } else {
            let updatedObject = resetErrors(customerContact)
            setCustomerContact({
                ...updatedObject
            })
            clearForm()
        }
    }, [props?.object])
    useEffect(() => {
        let abortController = new AbortController();
        loadDataRelationType()
        return () => {
            abortController.abort();
        }
    }, [])

    const submit = async () => {

        let message=''
        let message2=''
        if(!customerContact?.phone?.value){
           message+='رقم الهاتف مطلوب'
        }
        else if(customerContact?.phone?.value&&(customerContact?.phone?.value?.length>11||customerContact?.phone?.value?.length<11)){
           message+='الهاتف يجب ان يساوي 11 رقم \n'
        }
        else if(customerContact?.phone?.value&&isNaN(customerContact?.phone?.value)){
           message+=' '+'رقم الهاتف يجب ان يكون رقم'
        }
        if(customerContact?.phone?.value&&!stateCode){
           message+='كود الدولة للرقم مطلوب'
        }
  
  
        if(message){
           setFirstPhoneMessageError({
              message:message,
              error:false
           })
        }

        if(message){
           return
        }



        try {
            setLoading(true)
            let updatedObject = resetErrors(customerContact)
            setCustomerContact({
                ...updatedObject
            })
             let phone =helpers.handleMergePhoneWithStateCode(customerContact?.phone?.value,stateCode)
            let data = {
                relation_type_id: customerContact?.relation_type_id?.value?.id,
                phone:phone,
                name:customerContact?.name?.value,
                address:customerContact?.address?.value,
                customer_id: props?.customer?.id,
               
            }
            let result = null;
            if (props?.object && props?.object?.id) {
                data = {
                    ...data,
                    id: props?.object?.id,
                    _method: 'put'
                }
                result = await customerContactAPI.updateCustomerContact({
                    data: data
                });
            } else {
                result = await customerContactAPI.addCustomerContact({
                    data: data
                });
            }

            if (result?.status) {
                setLoading(false)
                toast.success(t('NETWORKMESSAGE.addCustomerMessageSuccess'))
                clearForm()
                props.loadData()
                props.setAddForm(false)
            } else {

                if (typeof result.errorMessage === "object") {
                    let updatedObject = resetErrors(customerContact)
                    setCustomerContact(checkErrors(result.errorMessage, updatedObject))
                    toast.error(t('NETWORKMESSAGE.messageError'))
                }
                else {
                    toast.error(result?.errorMessage)
                }
                setLoading(false)

            }
            setLoading(false)
        } catch (error) {
            setLoading(false)
            toast.error(error?.message)
        }

    }



    const clearForm = () => {
        setCustomerContact({
            ...ObjectOfErrors
        })
    }

    useEffect(() => {
        if (!props?.object)
            clearForm()
    }, [props?.addForm, props?.object])

    const loadDataRelationType = async (searchParams) => {
        setLoading(true)

        const result = await RelationTypeAPI.RelationTypeList({
            params: {
                ...searchParams
            },
            signal: controller?.signal
        })

        if (result.status) {
            setRelationTypes(result?.data)
        }
        else {
            if (!result?.data?.error) {
                toast.error(t('NETWORKMESSAGE.messageError'))
                return
            }
        }

    }
    return (
        <PopupForm
            open={props.addForm}
            setOpen={props.setAddForm}
            customeWidth={'80% !important'}
            title={props?.object ? t('CUSTOMER.update_contact_title') : t('CUSTOMER.add_contact_title')}
            content={<RenderContent
                relationTypes={relationTypes}
                setCustomerContact={setCustomerContact}
                customerContact={customerContact}
                stateCode={stateCode}
                setStateCode={setStateCode}
                open={props.addForm}
                setOpen={props.setAddForm}
                firstPhoneMessageError={firstPhoneMessageError}
                setFirstPhoneMessageError={setFirstPhoneMessageError}
            />}
            footer={<RenderFooter
                open={props.addForm}
                setOpen={props.setAddForm}
                submit={submit}
            />}
        />
    )
}
