import React, { useState, useEffect } from 'react'
import Grid from '../../../Grid/Grid'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import DropDownGrid from '../../../Grid/Components/DropDownGrid'
import { Box, MenuItem, ListItemIcon, Typography, Divider, Autocomplete, TextField, Tooltip } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ViewWeekIcon from '@mui/icons-material/ViewWeek';
import AddEmployee from './AddEmployee';
import swal from 'sweetalert';
import SearchInput from '../../../Components/SearchInput';
import SearchDate from '../../../Components/SearchDate';
import EmployeeAPI from '../../../../Global/Network/Employee/index'
import { from } from 'stylis';
import helpers from '../../../../assets/js/helper';
import { useGlobalContext } from '../../../../context'
import * as BsIcons from 'react-icons/bs'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import UnpublishedIcon from '@mui/icons-material/Unpublished';
import ImagePreview from '../../../Components/ImagePreview';
import RolesOfUserAPI from '../../../../Global/Network/RoleUser';
import { useMemo } from 'react';
import LockIcon from '@mui/icons-material/Lock';
import AssignDepartmentsToUser from './AssignDepartmentsToUser';
import employeeImage from '../../../../assets/img/user.png'

const APP_CONSTANTS = {
  API_URL_IMG: `${process.env.REACT_APP_API_URL_image}`,
}

const RenderSearchContent = (props) => {
  const [t] = useTranslation('common')
  console.log('user', props?.users)
  const optionStatus = [
    {
      id: 1,
      name: 'نشط',
      value: 1,
    },
    {
      id: 2,
      name: 'غير نشط',
      value: 0,
    },
  ]
  return (
    <Box sx={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: 'column',
      width: '100% !important',
    }}>
      <SearchInput
        value={props?.username}
        setValue={props?.setUserName}
        title={t('EMPLOYEE.username')}
      />
      <SearchInput
        value={props?.fullName}
        setValue={props?.setFullName}
        title={t('EMPLOYEE.fullName')}
      />
      <SearchInput
        value={props?.phone}
        setValue={props?.setPhone}
        title={t('EMPLOYEE.phone')}
      />
      <SearchInput
        value={props?.email}
        setValue={props?.setEmail}
        title={t('EMPLOYEE.email')}
      />
      <Autocomplete
        sx={{
          ml: 1.5, mt: 1, mb: 1, width: '95%',
          '& .MuiFormLabel-root,& .MuiInputBase-input': {
            fontFamily: 'Cairo-Medium'
          }
        }}
        options={optionStatus ? optionStatus : []}
        getOptionLabel={(option) => option?.name || ""}
        id="disable-close-on-select"
        value={props?.status}
        onChange={(e, v, r) => { props.setStatus(v ? v : ''); }}
        renderInput={(params) => (
          <TextField {...params}
            placeholder={t('EMPLOYEE.status')}
            variant="standard" sx={{ fontFamily: 'Cairo-Medium' }} />
        )}
      />
      <Autocomplete
        sx={{
          ml: 1.5, mt: 1, mb: 1, width: '95%',
          '& .MuiFormLabel-root,& .MuiInputBase-input': {
            fontFamily: 'Cairo-Medium'
          }
        }}
        disablePortal
        options={props.users ? props.users : []}
        getOptionLabel={(option) => option?.user && option?.user?.full_name || ""}
        id="disable-close-on-select2"
        value={props?.createdBy}
        onChange={(e, v, r) => { props.setCreatedBy(v ? v : ''); }}
        renderInput={(params) => (
          <TextField {...params}
            placeholder={t('EMPLOYEE.SEARCH_USERS')}
            variant="standard" sx={{ fontFamily: 'Cairo-Medium' }} />
        )}
      />

      <SearchDate
        value={props?.fromDate}
        setValue={props?.setFromDate}
        title={t('EMPLOYEE.fromDate')}
        minDate={null}
        maxDate={props?.toDate}
      />
      <SearchDate
        value={props?.toDate}
        setValue={props?.setToDate}
        title={t('EMPLOYEE.toDate')}
        autocomplete="off"
        minDate={props?.fromDate}
        maxDate={null}

      />
      {/* empty space  */}
      <Box>

      </Box>

    </Box>
  )
}

export default function Employee() {
  const [t] = useTranslation("common");
  const [rows, setRows] = useState([])
  const [pageSize, setPageSize] = useState(5);
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(false)
  const [pageCount, setPageCount] = useState(0)
  const [openFilterColumn, setOpenFilterColumn] = useState(false)
  const [openFilterColumnValue, setOpenFilterColumnValue] = useState([])
  const [elementClicked, setElementClicked] = useState(null)
  const [addForm, setAddForm] = useState(false)
  const [selectedEmployee, setselectedEmployee] = useState(null)
  const [workTypeName, setWorkTypeName] = useState(null)
  const [createdBy, setCreatedBy] = useState(null)
  const [users, setUsers] = useState([])
  const { exportToCSV } = useGlobalContext()
  const directions = useSelector(state => state.settingsData.directions)
  const handlePageChange = (newPage) => {
    setPageNumber(newPage + 1);
  };
  const controller = new AbortController();
  const [rowsTotal, setRowsTotal] = useState(0)
  const screenwidth = useSelector(state => state.settingsData.screenwidth)
  const [selectedImage, setSelectedImage] = useState(null)
  const [openImagePrivew, setOpenImagePrivew] = useState(false)
  const [filterParams,setFilterParams]=useState({})
  const [username, setUserName] = useState('')
  const [fullName, setFullName] = useState('')
  const [phone, setPhone] = useState('')
  const [email, setEmail] = useState('')
  const [status, setStatus] = useState(null)
  const [fromDate, setFromDate] = useState(null)
  const [toDate, setToDate] = useState(null)
  const [showAssignRoles, setShowAssignRoles] = useState(false)
  const [userRolesHere, setUserRolesHere] = useState(
    [
      [
        {
          view: true,
          id: 30
        },
        {
          add: false,
          id: 40,
        },
        {
          update: false,
          id: 41,
        },
        {
          delete: false,
          id: 42,
        }, {
          export: false,
          id: 43
        }, {
          assignRole: false,
          id: 43
        }
        , {
          viewAllCompany: false,
          id: 196
        }
      ],
      [],
      [],
      [],
      [//image here...
        {
          view: true,
          id: 139
        },
        {
          add: false,
          id: 140,
        },
        {
          update: false,
          id: 141,
        },
        {
          delete: false,
          id: 142,
        }, {
          export: false,
          id: 143
        }
      ],//addresses here.
      [
        {
          view: true,
          id: 144
        },
        {
          add: false,
          id: 145,
        },
        {
          update: false,
          id: 146,
        },
        {
          delete: false,
          id: 147,
        }, {
          export: false,
          id: 148
        }
      ]
    ])

  const columns = useMemo(() => [
    {
      headerName: t('EMPLOYEE.ID'), field: 'id', flex: 0.2, minWidth: 50, maxWidth: 150, hide: false,
      renderCell: (rows) => (
        <Box  >
          <span> {rows?.row?.user && rows?.row?.user?.id}</span>
          {/* {rows?.row?.user&&rows?.row?.user?.active==1?<span style={{backgroundColor:'green',width:'7px',height:'110%',margin:'0 5px',position:'relative',top:'0px',bottom:0,right:'0px',zIndex:'345345345',}}></span>:<span style={{backgroundColor:'red',width:'7px',height:'110%',margin:'0 5px',position:'fixed',top:'0px',bottom:0,right:'-10px',zIndex:'345345345',}}></span>} */}
        </Box>
      )
    },
    {
      headerName: "", field: 'active', width: 35, maxWidth: 35, minWidth: 35, hide: false,
      renderCell: (rows) => (
        <Box
          sx={{
            display: 'flex',
            // flexDirection:'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >

          <Tooltip title={<span style={{ fontFamily: 'Cairo' }}>{rows?.row?.user && rows?.row?.user?.active == 1 ? t('GLOBAL.Active') : t('GLOBAL.unActive')}</span>} arrow>
            <Box
              sx={{
                width: '15px',
                height: '15px',
                borderRadius: '50%',
                backgroundColor: rows?.row?.user && rows?.row?.user?.active == 1 ? 'green' : 'red',
              }}
            >

            </Box>
          </Tooltip>


        </Box>
      )
    },
    {
      headerName: '', field: 'image', flex: 1, minWidth: 57, maxWidth: 60, hide: false,
      renderCell: (rows) => (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {

            rows?.row && rows?.row?.images?.length > 0 ?
              <img
                src={helpers.getImageProfile(rows?.row?.images)}
                style={{
                  width: '40px',
                  height: '40px',
                  borderRadius: '50%',
                  border: '2px solid lightgrey',
                  objectFit: 'fill',
                  backgroundPosition: 'center center',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  setSelectedImage(helpers.getImageProfile(rows?.row?.images))
                  setOpenImagePrivew(true)
                }}
              />
              :
              <img
                src={employeeImage}
                style={{
                  width: '40px',
                  height: '40px',
                  borderRadius: '50%',
                  border: '2px solid lightgrey',
                  objectFit: 'fill',
                  backgroundPosition: 'center center',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  setSelectedImage(employeeImage)
                  setOpenImagePrivew(true)
                }}
              />
          }

        </Box>
      )
    },
    {
      field: 'username', flex: 0.8, minWidth: 200, maxWidth: 250, hide: false,
      renderHeader: (rows) => (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'start',
            height: '69.5px',
          }}
          className='cutomeRenderHeader'
        >
          <span style={{ height: '18px', textAlign: 'left' }} className="gridHeaderItem">{t('EMPLOYEE.username')}</span>
          <span style={{ height: '18px', textAlign: 'left' }} className="gridHeaderItem">{t('EMPLOYEE.full_name')}</span>

        </Box>
      ),
      renderCell: (rows) => (
        <Box
          sx={{
            display: 'flex',
            // flexDirection:'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <span className="gridHeaderItem">{rows?.row?.user && rows?.row?.user?.username}</span>
            <span style={{ fontSize: '12px', color: 'grey' }} className="gridHeaderItem">{rows?.row?.user && rows?.row?.user?.full_name}</span>
          </Box>

          {/* <Box
              sx={{
                display:'flex',
                flexDirection:'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
                {rows?.row?.user&&rows?.row?.user?.active==1?<span style={{borderRadius:'50%',backgroundColor:'green',width:'17px',height:'17px',margin:'0 5px'}}></span>:<span style={{borderRadius:'50%',backgroundColor:'red',width:'17px',height:'17px',margin:'0 5px'}}></span>}
            </Box> */}
        </Box>
      )
    },
    // {headerName:t('EMPLOYEE.full_name'),field:'full_name',flex:2,minWidth:250,maxWidth:450,hide:false,
    // renderCell: (rows) => (
    //     <Box>
    //         {rows?.row?.user&&rows?.row?.user?.full_name}
    //     </Box>
    // )
    // },
    {
      field: 'phone', flex: 0.8, minWidth: 130, maxWidth: 250, hide: false,
      renderHeader: (rows) => (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'start',
            height: '69.5px',
          }}
          className='cutomeRenderHeader'
        >
          <span style={{ height: '18px' }} className="gridHeaderItem">{t('EMPLOYEE.phone')}</span>
          <span style={{ height: '18px' }} className="gridHeaderItem">{t('EMPLOYEE.email')}</span>

        </Box>
      ),
      renderCell: (rows) => (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <span className="gridHeaderItem">{rows?.row?.user && rows?.row?.user?.phone}</span>
          <span className="gridHeaderItem">{rows?.row?.user && rows?.row?.user?.email}</span>

        </Box>
      )
    },
    // {headerName:t('EMPLOYEE.email'),field:'email',flex:2,minWidth:250,maxWidth:450,hide:false,
    // renderCell: (rows) => (
    //     <Box>
    //         {rows?.row?.user&&rows?.row?.user?.email}
    //     </Box>
    // )
    // },
    {
      headerName: t('EMPLOYEE.last_login_datetime'), field: 'last_login_datetime', flex: 1, minWidth: 150, maxWidth: 250, hide: false,
      renderHeader: (rows) => (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'start',
            height: '69.5px',
          }}
          className='cutomeRenderHeader'
        >
          <span style={{ height: '18px' }} className="gridHeaderItem">{t('EMPLOYEE.last_login_datetime')}</span>
          <span style={{ height: '18px' }} className="gridHeaderItem">{t('EMPLOYEE.last_logout_datetime')}</span>

        </Box>
      ),
      renderCell: (rows) => (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <span className="gridHeaderItem">{rows?.row?.user && rows?.row?.user?.last_login_datetime ? helpers.formatDate(rows?.row?.user?.last_login_datetime) + ' ' + helpers.formatTime(rows?.row?.user?.last_login_datetime) : '0000-00-00 00:00:00'}</span>
          <span className="gridHeaderItem">{rows?.row?.user && rows?.row?.user?.last_logout_datetime ? helpers.formatDate(rows?.row?.user?.last_logout_datetime) + ' ' + helpers.formatTime(rows?.row?.user?.last_logout_datetime) : '0000-00-00 00:00:00'}</span>
        </Box>
      )
    },
    // {headerName:t('EMPLOYEE.last_logout_datetime'),field:'last_logout_datetime',flex:2,minWidth:250,maxWidth:450,hide:false,
    // renderCell: (rows) => (
    //     <Box>
    //     </Box>
    // )
    // },
    // {headerName:t('EMPLOYEE.active'),field:'active',flex:1,minWidth:120,hide:false,
    // renderCell: (rows) => (
    //     <Box>
    //         {rows?.row?.user&&rows?.row?.user?.active==1?
    //         <Box sx={{
    //           width:'fit-content',
    //           padding:'1px 4px',
    //           height:'30px',
    //           borderRadius:'20px',
    //           border:'1px solid green',
    //           backgroundColor:'rgba(0,255,0,.1)',
    //           display:'flex',
    //           justifyContent: 'space-evenly',
    //           alignItems: 'center',
    //         }}>

    //             <span>{t('EMPLOYEE.active')}</span>
    //              <CheckCircleIcon sx={{color:'green',fill:'green'}}/>
    //         </Box>:
    //         <Box sx={{
    //           width:'fit-content',
    //           padding:'1px 4px',
    //           height:'30px',
    //           borderRadius:'20px',
    //           border:'1px solid red',
    //           backgroundColor:'rgba(255,0,0,.1)',
    //           display:'flex',
    //           justifyContent: 'space-evenly',
    //           alignItems: 'center',
    //         }}>

    //             <span>{t('EMPLOYEE.unActive')}</span>
    //              <UnpublishedIcon sx={{color:'red',fill:'red'}}/>
    //         </Box>
    //         }
    //     </Box>
    // )
    // },
    {
      headerName: t('EMPLOYEE.company'), field: 'company', flex: 1, minWidth: 200, maxWidth: 450, hide: false,
      renderCell: (rows) => (
        <Box >
          <span className="gridHeaderItem">{rows?.row?.user && rows?.row?.user?.company && rows?.row?.user?.company?.name}</span>
        </Box>
      )
    },

    // {headerName:t('EMPLOYEE.created_at'),field:'created_at',flex:1,minWidth:120,maxWidth:450,hide:false,
    // renderCell: (rows) => (
    //     <Box 
    //     sx={{
    //       display:'flex',
    //       flexDirection:'column',
    //       alignItems: 'center',
    //       justifyContent: 'center',
    //   }}
    //     >
    //         <span className="gridHeaderItem">{rows?.row?.user&&rows?.row?.created_at?helpers.formatDate(rows?.row?.created_at):'0000-00-00'}</span>
    //         <span className="gridHeaderItem">{rows?.row?.user&&rows?.row?.created_at?helpers.formatTime(rows?.row?.created_at):'00:00:00'}</span>
    //     </Box>
    // )
    // },

    {
      headerName: t('EMPLOYEE.actions'), field: 'Actions', flex: 0.4, minWidth: 90, maxWidth: 250, hide: false,
      renderCell: (rows) => (
        <DropDownGrid className="containerOFDropDownToolTipeGrid">

          {
            userRolesHere[0][2]?.update ?
              <MenuItem onClick={() => { updateFunction(rows?.row) }} className="ActionIcons">
                <ListItemIcon sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  margin: '0px 7px 0 0'
                }}>
                  <EditIcon style={{ fill: "#1E6A99" }} />

                </ListItemIcon>
                {t('GLOBAL.action_edit')}
              </MenuItem> : null}

          {userRolesHere[0][5]?.assignRole ?
            <MenuItem onClick={() => { assignRole(rows?.row) }} className="ActionIcons">
              <ListItemIcon sx={{
                display: 'flex',
                justifyContent: 'center',
                margin: '0px 7px 0 0'
              }}>
                <LockIcon style={{ fill: "#1e6a99" }} />
              </ListItemIcon>
              {t('EMPLOYEE.AssignDepartmentsToUser')}

            </MenuItem> : null}

          {userRolesHere[0][3]?.delete ?
            <MenuItem onClick={() => { deleteFunction(rows?.row) }} className="ActionIcons">
              <ListItemIcon sx={{
                display: 'flex',
                justifyContent: 'center',
                margin: '0px 7px 0 0'
              }}>
                <DeleteIcon style={{ fill: "#f00" }} />
              </ListItemIcon>
              {t('GLOBAL.action_delete')}

            </MenuItem> : null}

          {
            userRolesHere[0][2]?.update == false && userRolesHere[0][3]?.delete == false && !userRolesHere[0][5]?.assignRole ?
              <p style={{ fontFamily: 'Cairo', margin: '5px 20px' }}>{t('GLOBAL.emptyPoPup')}</p>
              : null
          }

        </DropDownGrid>
      ),
    },
  ], userRolesHere)

  useEffect(() => {
    let abortController = new AbortController();

    loadData()
    return () => {
      abortController.abort();
    }
  }, [pageNumber, pageSize])

  useEffect(() => {
    let abortController = new AbortController();
    loadDataOfUsers()
    getUserRoles()
    return () => {
      abortController.abort();
    }
  }, [])


  const loadData = async (searchParams) => {
    setLoading(true)
    const filterParamsData={
      ...filterParams,
      ...searchParams
     }
    const result = await EmployeeAPI.Employee({
      params: {
        page_size: pageSize,
        page: pageNumber,
        ...filterParamsData
      },
      signal: controller?.signal
    })

    setLoading(false)
    if (result.status) {
      setRows(result?.data?.data)
      setRowsTotal(result?.data?.total)
      setPageCount(result?.data?.last_page)
    }
    else {
      // alert('3')

      if (!result?.data?.error) {
        toast.error(t('NETWORKMESSAGE.messageError'))
        return
      }
    }

  }
  const loadDataOfUsers = async (searchParams) => {
    setLoading(true)

    const result = await EmployeeAPI.EmployeeList({
      signal: controller?.signal
    })

    setLoading(false)
    if (result.status) {
      setUsers(result?.data)
    }
    else {
      if (!result?.data?.error) {
        toast.error(t('NETWORKMESSAGE.messageError'))
        return
      }
    }

  }
  const createFUnction = () => {
    setselectedEmployee(null)
    setAddForm(true)
  }
  const updateFunction = (obj) => {
    // alert(obj?.name)
    setselectedEmployee(obj)
    setAddForm(true)
  }
  const assignRole = (obj) => {
    // alert(obj?.name)
    setselectedEmployee(obj)
    setShowAssignRoles(true)
  }
  const deleteFunction = async (obj) => {
    swal({
      title: '?هل انت متأكد',
      text: "لن تتمكن من التراجع عن هذا!",
      icon: 'warning',
      buttons: ["الغاء!", "نعم!"],
      dangerMode: true,
    }).then(async (willDelete) => {
      console.log('1')
      if (willDelete) {
        console.log('2')

        try {
          setLoading(true)

          let result = null;
          if (obj && obj?.id) {
            console.log('3')

            result = await EmployeeAPI.DeleteEmployee({
              data: {
                id: obj.id,
              }
            });
          }

          if (result?.status) {
            setLoading(false)
            // toast.success(t('NETWORKMESSAGE.deleteSuccess'))
            swal(`${t('NETWORKMESSAGE.deleteSuccess')}`, {
              icon: "success",
            });
            loadData({
              page: pageNumber
            })
          } else {
            setLoading(false)
            if (result?.errorMessage) {
              toast.error(result?.errorMessage)
            } else
              toast.error(t('NETWORKMESSAGE.deleteErrorr'))
          }
          setLoading(false)
        } catch (error) {
          setLoading(false)
          toast.error(t(error?.message))
        }
      } else {
        // alert('canceled')
      }
    })
  }
  const clearFunction = () => {
    setUserName('')
    setFullName('')
    setPhone('')
    setEmail('')
    setStatus(null)
    setFromDate(null)
    setToDate(null)
    setCreatedBy(null)
    setFilterParams({})
  }
  const searchFunction = () => {
    let data = {};
    if (username) {
      data = {
        ...data,
        username: username
      }
    }
    if (fullName) {
      data = {
        ...data,
        full_name: fullName
      }
    }
    if (phone) {
      data = {
        ...data,
        phone: phone
      }
    }
    if (email) {
      data = {
        ...data,
        email: email
      }
    }
    if (status) {
      data = {
        ...data,
        active: status?.value
      }
    }
    if (fromDate) {
      data = {
        ...data,
        creation_date_from: helpers.formatDate(fromDate)
      }
    }
    if (toDate) {
      data = {
        ...data,
        creation_date_to: helpers.formatDate(toDate)
      }
    }
    if (createdBy && createdBy?.id) {
      data = {
        ...data,
        created_by_id: createdBy?.id
      }
    }
    setFilterParams(data)

    loadData(data)
  }
  const loadDataForExport = async (searchParams) => {
    let result = await EmployeeAPI.Employee({
      params: {
        page_size: rowsTotal,
        // page: pageNumber,
        ...searchParams
      },
      signal: controller?.signal
    })
    return result;
  }
  const ExportFunction = async () => {
    let filteredData = {};
    if (username) {
      filteredData = {
        ...filteredData,
        username: username
      }
    }
    if (fullName) {
      filteredData = {
        ...filteredData,
        full_name: fullName
      }
    }
    if (phone) {
      filteredData = {
        ...filteredData,
        phone: phone
      }
    }
    if (email) {
      filteredData = {
        ...filteredData,
        email: email
      }
    }
    if (status) {
      filteredData = {
        ...filteredData,
        active: status?.value
      }
    }
    if (fromDate) {
      filteredData = {
        ...filteredData,
        creation_date_from: helpers.formatDate(fromDate)
      }
    }
    if (toDate) {
      filteredData = {
        ...filteredData,
        creation_date_to: helpers.formatDate(toDate)
      }
    }
    if (createdBy && createdBy?.id) {
      filteredData = {
        ...filteredData,
        created_by_id: createdBy?.id
      }
    }

    const fetchedData = await loadDataForExport(filteredData);
    if (!fetchedData || !fetchedData?.status) {
      toast.error("لا يمكن استرجاع البيانات")
      return
    }
    let arr = [];
    fetchedData?.data?.data && fetchedData?.data?.data?.map((itm) => {
      arr.push({
        [`${t('EMPLOYEE.ID')}`]: itm?.user?.id,
        [`${t('EMPLOYEE.username')}`]: itm?.user?.user_name,
        [`${t('EMPLOYEE.full_name')}`]: itm?.user?.full_name,
        [`${t('EMPLOYEE.phone')}`]: itm?.user?.phone,
        [`${t('EMPLOYEE.email')}`]: itm?.user?.email,
        [`${t('EMPLOYEE.last_login_datetime')}`]: itm?.user?.last_login_datetime,
        [`${t('EMPLOYEE.last_logout_datetime')}`]: itm?.user?.last_logout_datetime,
        [`${t('EMPLOYEE.company')}`]: itm?.user?.company?.name,
        [`${t('EMPLOYEE.company')}`]: itm?.user?.company?.name,
        [`${t('EMPLOYEE.created_at')}`]: itm?.user?.created_at ? helpers.formatDate(itm?.user?.created_at) + " " + helpers.formatTime(itm?.user?.created_at) : '0000-00-00 00:00:00',
      })
    })
    exportToCSV(arr, 'Employee')
  }

  const getUserRoles = async () => {
    // setLoading(true)
    console.log('saldkjaskd', 2);
    try {
      let data = await RolesOfUserAPI.GetRolesOfUser();
      if (data && data?.status) {
        let mainArr = []
        let arr = [];
        // for(let i=0;i<5;i++){
        let checkHasRole = data?.data?.includes(userRolesHere[0][0]?.id)
        if (checkHasRole) {
          arr.push(
            {
              view: true,
              id: userRolesHere[0][0]?.id,
            }
          )
        } else {
          arr.push(
            {
              view: false,
              id: userRolesHere[0][0]?.id,
            }
          )
        }
        console.log('sassssss', userRolesHere[0][1]?.id);
        checkHasRole = data?.data?.includes(userRolesHere[0][1]?.id)
        if (checkHasRole) {
          arr.push(
            {
              add: true,
              id: userRolesHere[0][1]?.id,
            }
          )
        } else {
          arr.push(
            {
              add: false,
              id: userRolesHere[0][1]?.id,
            }
          )
        }
        checkHasRole = data?.data?.includes(userRolesHere[0][2]?.id)
        if (checkHasRole) {
          arr.push(
            {
              update: true,
              id: userRolesHere[0][2]?.id,
            }
          )
        } else {
          arr.push(
            {
              update: false,
              id: userRolesHere[0][2]?.id,
            }
          )
        }
        checkHasRole = data?.data?.includes(userRolesHere[0][3]?.id)
        if (checkHasRole) {
          arr.push(
            {
              delete: true,
              id: userRolesHere[0][3]?.id,
            }
          )
        } else {
          arr.push(
            {
              delete: false,
              id: userRolesHere[0][3]?.id,
            }
          )
        }
        checkHasRole = data?.data?.includes(userRolesHere[0][4]?.id)
        if (checkHasRole) {
          arr.push(
            {
              export: true,
              id: userRolesHere[0][4]?.id,
            }
          )
        } else {
          arr.push(
            {
              export: false,
              id: userRolesHere[0][4]?.id,
            }
          )
        }

        checkHasRole = data?.data?.includes(userRolesHere[0][5]?.id)
        if (checkHasRole) {
          arr.push(
            {
              assignRole: true,
              id: userRolesHere[0][5]?.id,
            }
          )
        } else {
          arr.push(
            {
              assignRole: false,
              id: userRolesHere[0][5]?.id,
            }
          )
        }

        checkHasRole = data?.data?.includes(userRolesHere[0][6]?.id)
        if (checkHasRole) {
          arr.push(
            {
              viewAllCompany: true,
              id: userRolesHere[0][6]?.id,
            }
          )
        } else {
          arr.push(
            {
              viewAllCompany: false,
              id: userRolesHere[0][6]?.id,
            }
          )
        }

        mainArr.push(arr)
        mainArr.push([])//index 1
        mainArr.push([])//index 2
        mainArr.push([])//index 3
        arr = []
        checkHasRole = data?.data?.includes(userRolesHere[4][0]?.id)
        if (checkHasRole) {
          arr.push(
            {
              view: true,
              id: userRolesHere[4][0]?.id,
            }
          )
        } else {
          arr.push(
            {
              view: false,
              id: userRolesHere[4][0]?.id,
            }
          )
        }

        checkHasRole = data?.data?.includes(userRolesHere[4][1]?.id)
        if (checkHasRole) {
          arr.push(
            {
              add: true,
              id: userRolesHere[4][1]?.id,
            }
          )
        } else {
          arr.push(
            {
              add: false,
              id: userRolesHere[4][1]?.id,
            }
          )
        }
        checkHasRole = data?.data?.includes(userRolesHere[4][2]?.id)
        if (checkHasRole) {
          arr.push(
            {
              update: true,
              id: userRolesHere[4][2]?.id,
            }
          )
        } else {
          arr.push(
            {
              update: false,
              id: userRolesHere[4][2]?.id,
            }
          )
        }
        checkHasRole = data?.data?.includes(userRolesHere[4][3]?.id)
        if (checkHasRole) {
          arr.push(
            {
              delete: true,
              id: userRolesHere[4][3]?.id,
            }
          )
        } else {
          arr.push(
            {
              delete: false,
              id: userRolesHere[4][3]?.id,
            }
          )
        }
        checkHasRole = data?.data?.includes(userRolesHere[4][4]?.id)
        if (checkHasRole) {
          arr.push(
            {
              export: true,
              id: userRolesHere[4][4]?.id,
            }
          )
        } else {
          arr.push(
            {
              export: false,
              id: userRolesHere[4][4]?.id,
            }
          )
        }
        mainArr.push(arr)

        arr = []
        checkHasRole = data?.data?.includes(userRolesHere[5][0]?.id)
        if (checkHasRole) {
          arr.push(
            {
              view: true,
              id: userRolesHere[5][0]?.id,
            }
          )
        } else {
          arr.push(
            {
              view: false,
              id: userRolesHere[5][0]?.id,
            }
          )
        }

        checkHasRole = data?.data?.includes(userRolesHere[5][1]?.id)
        if (checkHasRole) {
          arr.push(
            {
              add: true,
              id: userRolesHere[5][1]?.id,
            }
          )
        } else {
          arr.push(
            {
              add: false,
              id: userRolesHere[5][1]?.id,
            }
          )
        }
        checkHasRole = data?.data?.includes(userRolesHere[5][2]?.id)
        if (checkHasRole) {
          arr.push(
            {
              update: true,
              id: userRolesHere[5][2]?.id,
            }
          )
        } else {
          arr.push(
            {
              update: false,
              id: userRolesHere[5][2]?.id,
            }
          )
        }
        checkHasRole = data?.data?.includes(userRolesHere[5][3]?.id)
        if (checkHasRole) {
          arr.push(
            {
              delete: true,
              id: userRolesHere[5][3]?.id,
            }
          )
        } else {
          arr.push(
            {
              delete: false,
              id: userRolesHere[5][3]?.id,
            }
          )
        }
        checkHasRole = data?.data?.includes(userRolesHere[5][4]?.id)
        if (checkHasRole) {
          arr.push(
            {
              export: true,
              id: userRolesHere[5][4]?.id,
            }
          )
        } else {
          arr.push(
            {
              export: false,
              id: userRolesHere[5][4]?.id,
            }
          )
        }
        mainArr.push(arr)

        setUserRolesHere(mainArr)
        // }
      }
      setLoading(false)
    } catch (err) {
      setLoading(false)
      console.log(err?.message);

    }
  }


  return (
    <Box
      sx={{
        // padding: '10px',
        // paddingTop: '20px',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'start',
        overflowX: 'hidden',

      }}
      className=" pt-3 mt-1"

    >
      <AddEmployee employee={selectedEmployee} object={selectedEmployee} addForm={addForm} setAddForm={setAddForm} loadData={loadData} userRolesHere={userRolesHere} />

      {showAssignRoles ? <AssignDepartmentsToUser employee={selectedEmployee} object={selectedEmployee} addForm={showAssignRoles} setAddForm={setShowAssignRoles} loadData={loadData} userRolesHere={userRolesHere} /> : null}

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          height: '100%',
          justifyContent: 'center',
          alignItems: 'stretch',
          // backgroundColor: '#fff',
          position: 'relative',
          // top:'-25px'
        }}
      >
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Typography
            variant='h4'
            sx={{
              marginLeft: '10px',
              marginBottom: '10px',
              fontFamily: 'Cairo-Bold',
              fontSize: '16px'
            }}>
            {t('EMPLOYEE.title')}
          </Typography>
        </Box>

        <Box sx={{ marginRight: '20px', backgroundColor: '#1E6A99', color: '#FFFFFF', width: '42px', height: '40px', borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }}
          // onClick={(event)=>{
          //   setOpenFilterColumn(true)
          //   setElementClicked(event.currentTarget)
          // }}
          id="demo-customized-button"
          aria-controls={openFilterColumn ? 'demo-customized-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={openFilterColumn ? 'true' : undefined}
          variant="contained"
        >
          {/* <ViewWeekIcon sx={{color:'#fffff'}}/> */}
          <BsIcons.BsFillPeopleFill style={{
            color: '#fff',
            fontSize: '25px',
          }} />
        </Box>

      </Box>

      <Divider
        sx={{
          backgroundColor: '#EBF2F7',
          margin: '30px',
          marginTop: '10px',
          width: '100%'
        }}
        style={{
          height: '3px',

        }}
      />

      <Grid
        rows={rows}
        columns={columns}
        setColumns={null}
        pageSize={pageSize}
        setPageSize={setPageSize}
        pageNumber={pageNumber}
        setPageNumber={setPageNumber}
        loading={loading}
        handlePageChange={handlePageChange}
        rowsTotal={rowsTotal}
        checkScreenSize={screenwidth}
        pageCount={pageCount}
        openFilterColumn={openFilterColumn}
        elementClicked={elementClicked}
        setOpenFilterColumn={setOpenFilterColumn}
        setColumnFilterValue={setOpenFilterColumnValue}
        columFilterValue={openFilterColumnValue}
        clearFunction={clearFunction}
        searchFunction={searchFunction}
        creatFunction={createFUnction}
        ExportFunction={ExportFunction}
        importFunction={null}
        mapFunction={null}
        telegramFunction={null}
        hasCreate={userRolesHere[0][1]?.add}
        hasImport={false}
        hasExport={userRolesHere[0][4]?.export}
        hasMap={false}
        hasTetelgram={false}
        filterChilds={<RenderSearchContent
          username={username}
          setUserName={setUserName}
          fullName={fullName}
          setFullName={setFullName}
          phone={phone}
          setPhone={setPhone}
          email={email}
          setEmail={setEmail}
          status={status}
          setStatus={setStatus}

          fromDate={fromDate}
          setFromDate={setFromDate}
          toDate={toDate}
          setToDate={setToDate}
          createdBy={createdBy}
          setCreatedBy={setCreatedBy}
          users={users}
        />}
      />
      <ImagePreview
        open={openImagePrivew}
        setOpen={setOpenImagePrivew}
        src={selectedImage}
      />

    </Box>
  )
}
