import React, { useState, useRef } from "react";
import {
  ComposableMap,
  Geographies,
  Geography,
  ZoomableGroup,
  Marker
} from "react-simple-maps";
import * as IoIcons from 'react-icons/io'
import {Box} from '@mui/material'
import {useNavigate} from 'react-router'
import { useSelector } from "react-redux";
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

const MapChart = (props) => {
  const tooltipContainer = useRef(null)
  const screenwidth=useSelector(state=>state.settingsData.screenwidth)


  const [showGeo,setShowGeo]=useState(false)
  const navigate=useNavigate()


  return (

    <div ref={tooltipContainer} style={{
      width: '100%',
      height: '100%',
      position:'relative',
    }}>
      <ComposableMap 
        projection="geoMercator"
        projectionConfig={{
          rotate: [0.0, 0.0, 0],
          scale: 800,
        }}
        // preserveAspectRatio="xMidYMid slice"
        viewBox={`0 0 ${window.innerWidth-94} ${window.innerHeight-60}`}
      >
        <ZoomableGroup 
          center={[33.054321+((screenwidth>1500?screenwidth*0.0001:screenwidth>1200?screenwidth*0.0001:screenwidth>991?screenwidth*0.0039:screenwidth>700?screenwidth*0.013:screenwidth>500?screenwidth*0.02:screenwidth*0.035)), 32.299957+(screenwidth>1500?screenwidth*0.0001:screenwidth>1200?screenwidth*0.00005:screenwidth>991?screenwidth*0.00005:screenwidth>700?screenwidth*0.0009:screenwidth>500?screenwidth*0.0001:screenwidth*0.0009)]}
          maxZoom={100} 
          zoom={2}
         
          >
          <Geographies 
          geography={require('./iraq.svg.json')}
        
          >
            {({ geographies }) =>
              geographies.map((geo) => {
                let selected = props?.governoratesIds?.includes(geo?.properties?.db_id)
                
                return <Geography


                  key={geo.rsmKey}
                  geography={geo}
                  style={{
                    default: {
                      fill: !showGeo&&selected ? "#1e6a99" : "#7d8185",
                    },
                    hover: {
                      fill: "#1e6a99A0",
                    },
                    pressed: {
                      fill: "#1e6a99",
                    },
                  }}
                />
              })
            }
          </Geographies>
          {!showGeo&&props?.markers.map(({ type,type_id,address_id, name, coordinates, markerOffset, address, availableUnits }, index) => (
            < Marker
              onMouseLeave={() => {
                props?.setTooltipContent(null);

              }}

              onClick={() => {
                if(props?.tooltipContent){
                  
                  navigate('/admin/image-view',{
                    state:{
                      data:{
                        type_id:type_id,
                        type:type,
                        title:name
                      }
                    }
                  })
                }else{
                  props?.setTooltipContent({
                    address_id, name, coordinates, markerOffset, address, availableUnits,
                      anchor:`markerMap_${index}`
                    });
                }
              }}
              
              

              onMouseEnter={() => {
                props?.setTooltipContent({
                   address_id, name, coordinates, markerOffset, address, availableUnits,
                     anchor:`markerMap_${index}`
                   });
              }} key={index} coordinates={coordinates}>

              {/* <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" version="1.1"><path d="M 18.110 4.396 C 11.216 8.217, 11.447 20.604, 18.472 23.805 C 28.942 28.576, 38.576 18.942, 33.805 8.472 C 31.563 3.550, 23.441 1.441, 18.110 4.396 M 20 11 C 20 11.550, 20.450 12, 21 12 C 21.550 12, 22 11.550, 22 11 C 22 10.450, 21.550 10, 21 10 C 20.450 10, 20 10.450, 20 11" stroke="none" fill="#fcb404" fill-rule="evenodd"/><path d="M 20 11 C 20 11.550, 20.450 12, 21 12 C 21.550 12, 22 11.550, 22 11 C 22 10.450, 21.550 10, 21 10 C 20.450 10, 20 10.450, 20 11 M 22 35.500 C 22 45.333, 22.127 46, 24 46 C 25.873 46, 26 45.333, 26 35.500 C 26 25.667, 25.873 25, 24 25 C 22.127 25, 22 25.667, 22 35.500" stroke="none" fill="#fcf9f1" fill-rule="evenodd"/></svg> */}
              {/* <g
                    data-tip={""} 
                    fill={props?.tooltipContent?.address_id==address_id?"#2ace15":"#fcb404"}
                    stroke="#FF5533"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    transform="translate(-12, -24)"
                  >
                    <circle cx="12" cy="10" r="3" />
                    <path d="M12 21.7C17.3 17 20 13 20 10a8 8 0 1 0-16 0c0 3 2.7 6.9 8 11.7z" />
                  </g> */}
              <g
                data-tip={""}
                transform="translate(-9, -18)"
              >
                {/* <circle cx="12" cy="10" r="3" />
                    <path d="M12 21.7C17.3 17 20 13 20 10a8 8 0 1 0-16 0c0 3 2.7 6.9 8 11.7z" /> */}

                <path stroke="null" id="svg_2" fill-rule="evenodd" fill={props?.tooltipContent?.address_id == address_id ? "#2ace15" : "#fcb404"} d="m8.24471,0.81495c-2.97546,1.47842 -2.87576,6.27121 0.15624,7.50974c4.51887,1.846 8.67692,-1.88159 6.61775,-5.93265c-0.96765,-1.90442 -4.47312,-2.72044 -6.77399,-1.57709m0.81573,2.55522c0,0.21281 0.19422,0.38692 0.4316,0.38692c0.23738,0 0.4316,-0.17411 0.4316,-0.38692c0,-0.21281 -0.19422,-0.38692 -0.4316,-0.38692c-0.23738,0 -0.4316,0.17411 -0.4316,0.38692" />
                <path stroke="null" id="svg_3" fill-rule="evenodd" fill="#fcf9f1" d="m9.00021,3.02446c0,0.21962 0.20625,0.39932 0.45834,0.39932c0.25209,0 0.45834,-0.17969 0.45834,-0.39932c0,-0.21962 -0.20625,-0.39932 -0.45834,-0.39932c-0.25209,0 -0.45834,0.17969 -0.45834,0.39932m0.91669,9.78327c0,3.92649 0.05821,4.19283 0.91669,4.19283c0.85848,0 0.91669,-0.26634 0.91669,-4.19283c0,-3.92649 -0.05821,-4.19283 -0.91669,-4.19283c-0.85848,0 -0.91669,0.26634 -0.91669,4.19283" />

              </g>
              {/* <text
                    textAnchor="middle"
                    y={markerOffset}
                    style={{fontSize:8, fontFamily: "Cairo", fill: "#5D5A6D" }}
                  >
                    {name}
                  </text> */}

            </Marker>


          ))}
        </ZoomableGroup>
      </ComposableMap>
      {/* <Box sx={{
        position:'fixed',
        bottom:20,
        right:20,
        width:'50px',
        height:'50px',
        borderRadius:'50%',
        backgroundColor:showGeo?'#fff':'#1e6a99',
        boxShadow:'0 0 10px -2px #000',
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
      }}
       onClick={()=>{
        setShowGeo(!showGeo)
       }}
      >
        {
        !showGeo?
        <IoIcons.IoIosEye size={30}  style={{cursor:'pointer',color:'#fff',fill:'#fff'}}/>
        :
        <VisibilityOffIcon size={30}  sx={{cursor:'pointer',color:'#1e6a99',fill:'#1e6a99'}}/>
        }
      </Box> */}
    </div>

  );
};

export default MapChart;